import React from 'react';

import { Div, Text } from '~/components/Kiwistrap/Page';

import PageLayout from '~/pages/_layouts/hospital/PageLayout';

import NoneChoosenMobile from '../../NoneChoosenMobile';

import { DesktopContainer } from '~/components/UI/Sections/MobileVacancyAgenda';
import { DesktopAgendaLeft } from '~/components/UI/Sections';

import { ContractSvg } from './styles';

export default function GroupsPageNoneChoosen() {
  return (
    <>
      <NoneChoosenMobile />
      <DesktopContainer>
        <PageLayout
          disableDefaultButtons
          userInfoButtons={<DesktopAgendaLeft />}
        >
          <Div
            height="calc(100vh - 120px)"
            justifyContent="center"
            alignItems="center"
          >
            <ContractSvg />
            <Text size="24px" color="var(--primary)">
              Por favor selecione uma escala.
            </Text>
          </Div>
        </PageLayout>
      </DesktopContainer>
    </>
  );
}
