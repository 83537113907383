import React from 'react';

import {
  ThemeProvider as ChakraThemeProvider,
  theme,
  CSSReset,
} from '@chakra-ui/react';

import { AuthProvider } from './AuthContext';
import { MedicoProvider } from './Site/MedicoContext';
import { EmpresaProvider } from './Site/EmpresaContext';
import { ProfissionalProvider } from './Site/ProfissionalContext';
// Modal ViewSchedules
import { ViewSchedulesProvider } from './modals/ViewSchedules/useViewSchedules';
// /controle-de-horas
import { ColorsProvider } from './pages/ControleHoras/useColors';

const AppProvider = ({ children }) => {
  return (
    <ChakraThemeProvider theme={theme}>
      <CSSReset />
      <AuthProvider>
        <MedicoProvider>
          <EmpresaProvider>
            <ProfissionalProvider>
              <ColorsProvider>
                <ViewSchedulesProvider>{children}</ViewSchedulesProvider>
              </ColorsProvider>
            </ProfissionalProvider>
          </EmpresaProvider>
        </MedicoProvider>
      </AuthProvider>
    </ChakraThemeProvider>
  );
};

export default AppProvider;
