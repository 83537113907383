export default function onlyNumberHandleChangeCnpj(value) {
  const formatter = value
    .replace(/\D/g, '')
    .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
  const CnpjFormatted = !formatter[2]
    ? formatter[1]
    : formatter[1] +
      '.' +
      formatter[2] +
      '.' +
      formatter[3] +
      '/' +
      formatter[4] +
      (formatter[5] ? '-' + formatter[5] : '');

  return CnpjFormatted;
}
