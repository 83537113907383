import React, { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';
import { Flex, Text } from '@chakra-ui/react';
import { AiFillDelete } from 'react-icons/ai';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import EditScheduleGroupModal from './EditScheduleGroupModal';
import CreateScheduleGroupModal from './CreateScheduleGroupModal';

import { Button, Alert } from '~/components/ChakraComponents';
import { Modal, ModalContainer } from '~/components/UI/Modal';

export default function ScheduleGroupModal() {
  const [loading, setLoading] = useState(true);

  const [groups, setGroups] = useState([]);
  const [selected, setSelected] = useState();
  const [groupToDel, setGroupToDel] = useState();
  const [confirmDel, setConfirmDel] = useState(false);

  const { hospitalId } = getParam('hospitalId');

  const fetchData = useCallback(() => {
    api
      .get(`/companies/myhospitals/${hospitalId}/schedulesgroup`)
      .then(response => {
        setGroups(response.data.data);
        setLoading(false);
      });
  }, [hospitalId]);

  useEffect(() => {
    fetchData();
  }, []);

  const editGroupModal = useRef(null);
  const createGroupModal = useRef(null);

  const openEditGroup = useCallback(() => {
    editGroupModal.current.openModal();
  }, []);

  const openCreateGroup = useCallback(() => {
    createGroupModal.current.openModal();
  }, []);

  const deleteGroup = useCallback(() => {
    const url = `/companies/myhospitals/${hospitalId}/schedulesgroup/${groupToDel}`;

    api
      .delete(url)
      .then(() => {
        toast.success('Grupo deletado com sucesso!');

        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  }, [groupToDel, hospitalId]);

  return (
    <ModalContainer loading={loading}>
      <Flex w="100%" mb={4} justifyContent="center">
        <Button title="criar escala" onClick={openCreateGroup} />
      </Flex>
      {groups.map(group => (
        <Flex
          key={group.id}
          mt={2}
          p={2}
          alignItems="center"
          justifyContent="space-between"
          borderRadius="md"
          backgroundColor="var(--background-alt)"
        >
          <Text>{group.name}</Text>
          <Flex>
            <Text
              mr={3}
              cursor="pointer"
              fontSize="24px"
              color="var(--primary)"
              _hover={{ color: 'var(--hover-primary)' }}
              onClick={() => {
                setSelected(group);
                openEditGroup();
              }}
            >
              <FaEdit />
            </Text>
            <Text
              cursor="pointer"
              fontSize="24px"
              color="var(--red)"
              _hover={{ color: 'var(--hover-red)' }}
              onClick={() => {
                setGroupToDel(group.id);
                setConfirmDel(true);
              }}
            >
              <AiFillDelete />
            </Text>
          </Flex>
        </Flex>
      ))}
      <Modal
        title="Criar Escala"
        ref={createGroupModal}
        component={CreateScheduleGroupModal}
      />
      <Modal
        title="Editar Escala"
        ref={editGroupModal}
        component={EditScheduleGroupModal}
        selected={selected}
      />
      <Alert
        isOpen={confirmDel}
        onClose={() => setConfirmDel(false)}
        onConfirm={() => {
          setConfirmDel(false);
          deleteGroup();
        }}
        confirmTitle="excluír"
        confirmVariant="danger"
        header="Excluír Escala"
        body="Essa ação não poderá ser desfeita!"
      />
    </ModalContainer>
  );
}
