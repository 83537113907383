import React from 'react';
import { Flex } from '@chakra-ui/react';

import leaveHospital from '~/utils/leaveHospital';
import { getParam } from '~/utils/url';

import { Button } from '~/components/ChakraComponents/';

export default function Buttons({ children, disableDefaultButtons }) {
  const { hospitalId } = getParam('hospitalId');

  return (
    <Flex w="100%" flexDir="column" alignItems="center">
      {children}
      {!disableDefaultButtons && (
        <>
          <Button
            title="perfil"
            to={`/contractorhospital/profile/?hospitalId=${hospitalId}`}
            margin="0 0 20px"
          />
          <Button title="sair do hospital" onClick={leaveHospital} />
        </>
      )}
    </Flex>
  );
}
