import React from 'react';
import { Flex, Divider as ChakraDivider } from '@chakra-ui/react';

export default function Divider({ my = 2, ...rest }) {
  return (
    <Flex w="100%">
      <ChakraDivider my={my} color="gray.300" {...rest} />
    </Flex>
  );
}
