import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

export default function LinkButton({
  title = '',
  svg: Svg = <></>,
  color = 'var(--primary)',
  onClick,
  ...rest
}) {
  return (
    <Flex
      onClick={onClick}
      w="auto"
      cursor="pointer"
      align="center"
      color={color}
      _hover={{ textDecor: 'underline' }}
      {...rest}
    >
      <Svg />
      <Text ml={2} fontWeight="semibold" textTransform="capitalize">
        {title}
      </Text>
    </Flex>
  );
}
