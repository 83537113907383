import styled from 'styled-components';
import {
  FaTimes,
  FaClock,
  FaUserTimes,
  FaCheck,
  FaUserEdit,
  FaUserCheck,
} from 'react-icons/fa';

export const MonthDoctorContainer = styled.div`
  cursor: pointer;

  position: relative;

  width: 100%;

  padding: 0 4px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  font-size: 11px;
  font-weight: bold;

  color: var(--background-alt);

  border-left: ${props => props.borderLeft};
  background-color: ${props => props.color};
`;

export const MonthOpenContainer = styled.span`
  cursor: pointer;

  position: relative;

  width: 100%;

  padding: 2px 4px;

  display: flex;
  align-items: center;
  flex-direction: column;

  font-size: 11px;
  font-weight: bold;
  text-align: center;

  color: var(--background-alt);

  border-left: ${props => props.borderLeft};
  background-color: ${props => props.color};
`;

export const Avatar = styled.img`
  width: 35px;
  height: 35px;

  border-radius: 50%;

  object-fit: cover;
  flex-shrink: none;

  border: 2px solid var(--background);
  background-color: var(--background-alt);

  @media (max-width: 1600px) {
    width: 24.5px;
    height: 24.5px;
  }
`;

export const FloatCandidature = styled.div`
  position: absolute;
  top: -55%;
  right: -60%;
  width: 60px;
  height: 60px;

  padding: 25px 13px 0 0;

  border-radius: 50%;

  font-size: 24px;
  font-weight: 500;
  text-align: center;

  color: var(--background-alt);
  background: var(--red);
`;

export const IconContainer = styled.div`
  padding: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: none;

  height: 20px;

  margin-bottom: 3px;

  border-radius: 50%;
  border: 2px solid var(--background-alt);

  background-color: ${props => props.statusColor};

  svg {
    font-size: 12px;
    color: var(--background-alt);
  }
`;

export const RefusedSvg = styled(FaTimes)``;
export const PendingSvg = styled(FaClock)``;
export const ExpiredSvg = styled(FaUserTimes)``;
export const ConfirmedSvg = styled(FaCheck)``;
export const CheckinSvg = styled(FaUserEdit)``;
export const CheckoutSvg = styled(FaUserCheck)``;
export const PendingCheckinSvg = styled(FaUserEdit)``;
export const PendingCheckoutSvg = styled(FaUserCheck)``;
