import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  FaDesktop,
  FaRegChartBar,
  FaHospitalAlt,
  FaSearchPlus,
  FaBookOpen,
  FaNotesMedical,
  FaBookMedical,
} from 'react-icons/fa';
import { GoCalendar } from 'react-icons/go';

import api from '~/services/api';

import MeuPlantaoLogo from '~/assets/logo/LogoWhite.png';

import { SubTitle } from '~/components/Kiwistrap/Page';

import {
  Container,
  LogoLink,
  LogoImg,
  Navigation,
  PageLink,
  NotificationsSection,
  BellContainer,
  Bell,
  NotificationMenuBackground,
  NotificationsMenu,
  NotificationsMenuItem,
} from './styles';

const Nav = () => {
  const [notifications, setNotifications] = useState();
  const [unread, setUnread] = useState(0);
  const [openNotifications, setOpenNotifications] = useState(false);

  const fetchNotifications = async () => {
    const {
      data: { data },
    } = await api.get('/doctors/notifications?limit=40');

    let unreadNotifications = 0;

    data.forEach(notification => {
      if (!notification.read) unreadNotifications += 1;
    });

    setNotifications(data);
    setUnread(unreadNotifications);

    //setTimeout(() => fetchNotifications(), 300000);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const markAsRead = async id => {
    try {
      await api.patch(`/doctors/notifications/read/${id}`);
      return true;
    } catch (error) {
      return false;
    }
  };

  const toggleNotifications = async () => {
    setOpenNotifications(!openNotifications);

    const nonReadIds = [];

    notifications.forEach(notification => {
      if (notification.read === false) return nonReadIds.push(notification.id);
    });

    const promises = nonReadIds.map(async id => {
      return await api.patch(`/doctors/notifications/read/${id}`);
    });

    await Promise.all(promises);

    fetchNotifications();
  };

  const handleBackgroundOnClick = e => {
    if (e.target.id === 'notifications-background') {
      setOpenNotifications(false);
    }
  };

  return (
    <Container>
      <LogoLink to="/medico/dashboard">
        <LogoImg src={MeuPlantaoLogo} alt="meu-plantao" />
      </LogoLink>
      <Navigation>
        <PageLink
          text="Dashboard"
          to="/medico/dashboard"
          selected={window.location.pathname === '/medico/dashboard'}
        >
          <FaDesktop />
        </PageLink>
        <PageLink
          text="Controle de Horas"
          to="/medico/control"
          selected={window.location.pathname === '/medico/control'}
        >
          <FaRegChartBar />
        </PageLink>
        <PageLink
          text="Meus Plantões"
          to="/medico/myvacancies"
          selected={window.location.pathname === '/medico/myvacancies'}
        >
          <GoCalendar />
        </PageLink>
        <PageLink
          text="Meus Hospitais"
          to="/medico/myhospitals"
          selected={window.location.pathname === '/medico/myhospitals'}
        >
          <FaHospitalAlt />
        </PageLink>
        <PageLink
          text="Buscar Plantão"
          to="/medico/findvacancy"
          selected={window.location.pathname === '/medico/findvacancy'}
        >
          <FaSearchPlus />
        </PageLink>
        <PageLink
          text="Notícias"
          to="/medico/news"
          selected={window.location.pathname === '/medico/news'}
        >
          <FaBookOpen />
        </PageLink>
        <PageLink
          text="Protocolos"
          to="/medico/protocols"
          selected={window.location.pathname === '/medico/protocols'}
        >
          <FaNotesMedical />
        </PageLink>
        <PageLink
          text="Material Didatico"
          to="/medico/material"
          selected={window.location.pathname === '/medico/material'}
        >
          <FaBookMedical />
        </PageLink>
      </Navigation>
      <NotificationsSection>
        <BellContainer
          unread={unread > 0 && unread}
          onClick={toggleNotifications}
        >
          <Bell />
        </BellContainer>
        {openNotifications && (
          <NotificationMenuBackground
            id="notifications-background"
            onClick={handleBackgroundOnClick}
          >
            <NotificationsMenu>
              <SubTitle
                style={{
                  padding: 10,
                  borderBottom: '2px solid var(--secondary',
                }}
              >
                Notificações
              </SubTitle>
              {notifications &&
                notifications.map(notification => (
                  <NotificationsMenuItem key={notification.id}>
                    {notification.content}
                    <p>
                      {moment(new Date(notification.updatedAt)).format(
                        'HH:mm - DD/MM'
                      )}
                    </p>
                  </NotificationsMenuItem>
                ))}
            </NotificationsMenu>
          </NotificationMenuBackground>
        )}
      </NotificationsSection>
    </Container>
  );
};

export default Nav;
