import { getUser } from './auth';
import { getParam } from '~/utils/url';

const isUserAllowedToViewPage = (type, tag, homepage) => {
  const user = getUser();
  const { hospitalId } = getParam('hospitalId');

  if (user.allPermissions) {
    return true;
  }

  if (homepage && homepage === user.homepage.url) {
    return true;
  }

  if (type === 'hospitalPermissions') {
    const id = +hospitalId;

    const hospital = user.hospitalPermissions.find(hospital => {
      return hospital.id === id;
    });

    if (hospital.permissions.map(p => p.id).includes(tag)) {
      return true;
    }
  }

  if (
    type === 'companyPermissions' &&
    user[type] &&
    user[type].map(p => p.id).includes(tag)
  ) {
    return true;
  }

  return false;
};

export default isUserAllowedToViewPage;
