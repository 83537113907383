import React from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { BsPhone } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { FaStreetView } from 'react-icons/fa';

import api from '~/services/api';

import { Title } from '~/components/Kiwistrap/Page';
import { Button } from '~/components/ChakraComponents/';

import phoneMask from '~/utils/phoneMask';

import InfoSection from '../../InfoSection';

import { Avatar } from './styles';

export default function CompanyProfileModal({ company }) {
  const handleDesvinculate = async () => {
    try {
      await api.delete(`/doctors/companies/${company.id}`);
      window.location.reload();
    } catch (e) {
      toast.error('Houver um problema com nossos servidores. Tente novamente.');
    }
  };

  const onAvatarLoadError = e => {
    e.target.src =
      'https://verde-azul-imoveis.s3.amazonaws.com/b36f1999-aada-4f0b-9fe8-21bdf419fef7.png';
  };

  return (
    <Flex flexDir="column" w="400px">
      <Flex justify="center">
        <Avatar
          src={company.avatar}
          alt={company.name}
          onError={onAvatarLoadError}
        />
      </Flex>
      <Title center size={24} margin="10px 0 0">
        {company.name}
      </Title>
      <InfoSection icon={AiOutlineMail} title="E-mail" info={company.email} />
      <InfoSection
        icon={BsPhone}
        title="Telefone"
        info={`${phoneMask(company.phone1)} ${phoneMask(company.phone2)}`}
      />
      <InfoSection
        icon={FaStreetView}
        title="Endereço"
        info={`${company.uf} - ${company.city}, ${company.address}, ${company.street} ${company.number}`}
      />
      {!company.isPending && (
        <Flex justify="center">
          <Button
            title="desvincular-se"
            variant="danger"
            onClick={handleDesvinculate}
            margin="20px 0 0"
          />
        </Flex>
      )}
    </Flex>
  );
}
