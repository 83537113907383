import styled from 'styled-components';
import { RiBarChartHorizontalLine } from 'react-icons/ri';

export const Container = styled.div`
  cursor: pointer;

  padding: 10px;
  margin-top: 10px;

  display: flex;

  box-shadow: var(--shadow);
  background-color: var(--background-alt);

  svg {
    transition: all 200ms ease-in-out;
    color: #d1d1d1;
    font-size: 30px;
  }

  transition: all 200ms ease-in-out;

  &:hover {
    svg {
      color: var(--primary);
      text-shadow: 0px 8px 7px rgba(0, 0, 0, 0.4),
        0px 16px 24px rgba(0, 0, 0, 0.1), 0px 32px 40px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Avatar = styled.img`
  position: relative;

  width: 70px;
  height: 70px;

  padding: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--background-alt);

  border-radius: 25px;
  border: 3px solid var(--primary);

  object-fit: cover;
  flex-shrink: none;
  background-size: cover;
`;

export const Header = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--secondary);
  font-size: 20px;
  font-weight: bold;
`;

export const Text = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--dark-gray);
  font-weight: 500;
`;

export const ShowSvg = styled(RiBarChartHorizontalLine)``;
