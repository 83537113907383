import React from 'react';
import { Flex } from '@chakra-ui/react';

import history from '~/services/history';
import { getParam } from '~/utils/url';

import { Option } from './styles';

export default function SpecialtiesBtn() {
  const { hospitalId } = getParam('hospitalId');

  const navigateToGroup = () => {
    history.push(
      `/contractorhospital/vacancies/group/?hospitalId=${hospitalId}`
    );
  };

  const navigateToSpecialty = () => {
    history.push(
      `/contractorhospital/vacancies/specialty/?hospitalId=${hospitalId}`
    );
  };

  return (
    <Flex flexDir="column" px="4px">
      <Flex my="20px" flexDir="column" alignItems="center">
        <Option onClick={navigateToGroup}>Escalas</Option>
        <Option onClick={navigateToSpecialty}>Especialidades</Option>
      </Flex>
    </Flex>
  );
}
