import React from 'react';
import { Flex } from '@chakra-ui/react';
import {
  AiFillCheckSquare as CheckSvg,
  AiFillCloseSquare as CloseSvg,
} from 'react-icons/ai';

const svg = check => (check ? <CheckSvg /> : <CloseSvg />);
const color = check => (check ? 'green.400' : 'red.400');

export const renderCheckinCheckoutSvg = (checkin, checkout) => {
  return (
    <Flex maxW="130px" justifyContent="center" w="auto" fontSize="3xl">
      <Flex mr={9} w="auto" color={color(checkin)}>
        {svg(checkin)}
      </Flex>
      <Flex w="auto" color={color(checkout)}>
        {svg(checkout)}
      </Flex>
    </Flex>
  );
};
