import api from '~/services/api';

const createFunc = responses => {
  responses.forEach(response => {
    response.data.data.forEach(item => {
      item.isChoosen = false;
    });
  });

  return responses;
};

const editFunc = ([usersRes, hospitalsRes], group) => {
  const usersIds = [],
    hospitalsIds = [];

  group.userscontractors.forEach(user => {
    usersIds.push(user.id);
  });

  group.hospitals.forEach(hospital => {
    hospitalsIds.push(hospital.id);
  });

  usersRes.data.data.forEach(user => {
    if (usersIds.includes(user.usercontractor[0].id)) {
      user.isChoosen = true;
    } else {
      user.isChoosen = false;
    }
  });

  hospitalsRes.data.data.forEach(hospital => {
    if (hospitalsIds.includes(hospital.id)) {
      hospital.isChoosen = true;
    } else {
      hospital.isChoosen = false;
    }
  });

  return [usersRes, hospitalsRes];
};

export const fetchGroups = (callback, group) => {
  const fetches = [
    api.get('/contractors/users?isActive=true'),
    api.get('/contractors/myhospitals?isActive=true'),
  ];

  Promise.all(fetches).then(response => {
    let result;

    if (group) {
      result = editFunc(response, group);
    } else {
      result = createFunc(response);
    }

    return callback(result);
  });
};
