import React from 'react';
import styled from 'styled-components';
import { Flex, Avatar, Text } from '@chakra-ui/react';
import { FaHospitalAlt } from 'react-icons/fa';
import { AiFillSetting } from 'react-icons/ai';

import phoneMask from '~/utils/phoneMask';

import IsAllowed from '~/components/UI/IsAllowed';

const AnimationContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  border-radius: 4px;

  overflow: hidden;

  #logo-container {
    transition: var(--transition);
  }

  #footer-container,
  svg {
    transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  #logo-container {
    position: absolute;
    top: 20px;
    left: calc(50% - 50px);
    z-index: 10;

    width: 100px;
    height: 100px;

    border-radius: 50%;

    border: 3px solid var(--primary);

    div,
    img {
      border-radius: 50% !important;
    }

    img {
      padding: 2px;
    }
  }

  #footer-container {
    position: absolute;
    bottom: -200px;
    z-index: 20;

    padding: 0 7px;
  }

  svg {
    fill: #718096;
  }

  &:hover {
    #logo-container {
      width: 100%;
      height: 100%;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      border: none;
      border-radius: 0;

      filter: brightness(60%);

      img {
        padding: 0;
        border-radius: 0 !important;
      }
    }

    #footer-container {
      left: 3px;
      right: 3px;
      bottom: 10px;
    }

    svg {
      fill: white;

      transform: scale(1.2);

      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
        0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
        0 16px 16px rgba(0, 0, 0, 0.1);
    }
  }
`;

export default function HospitalCard({
  hospital,
  enterHospital,
  editHospital,
}) {
  return (
    <Flex w="100%" h="100%" justifyContent="center">
      <Flex
        w="200px"
        h="100%"
        minH="200px"
        flexDir="column"
        boxShadow="base"
        backgroundColor="white"
        borderRadius="base"
        fontWeight="600"
        color="gray.700"
        _hover={{
          boxShadow: 'md',
        }}
      >
        <AnimationContainer>
          <Flex h="120px" backgroundColor="var(--primary)">
            <Flex id="logo-container" backgroundColor="white">
              <Avatar
                w="100%"
                h="100%"
                backgroundColor="transparent"
                name={hospital.name}
                src={hospital.avatar}
              />
            </Flex>
          </Flex>
          <Flex
            h="100%"
            p="12px 7px"
            flexDir="column"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Text
              fontSize="1rem"
              fontWeight="bold"
              color="var(--chakra-colors-gray-600)"
              textAlign="center"
            >
              {hospital.name}
            </Text>
            <Text
              mt={1}
              fontSize="0.9rem"
              color="var(--chakra-colors-gray-600)"
              textAlign="center"
            >
              {phoneMask(hospital.phone1)}
            </Text>
          </Flex>
          <Flex
            id="footer-container"
            w="calc(100% - 6px)"
            h="100%"
            flexDir="column"
            alignItems="center"
            justifyContent="space-between"
            color="white"
            textShadow="0 1px 1px rgba(0,0,0,0.1),
            0 2px 2px rgba(0,0,0,0.1),
            0 4px 4px rgba(0,0,0,0.1),
            0 8px 8px rgba(0,0,0,0.1),
            0 16px 16px rgba(0,0,0,0.1)"
          >
            <Flex
              onClick={enterHospital}
              cursor="pointer"
              w="100%"
              h="100%"
              pb={7}
              flexDir="column"
              alignItems="center"
              justifyContent="flex-end"
              fontSize="1rem"
              textAlign="center"
            >
              <Text>{hospital.name}</Text>
              <Text mt={1} fontSize="0.85rem" fontWeight="semibold">
                {phoneMask(hospital.phone1)}
              </Text>
            </Flex>
            <Flex
              w="100%"
              justifyContent="space-between"
              fontSize="0.9rem"
              fontWeight="semibold"
            >
              <Flex
                onClick={enterHospital}
                cursor="pointer"
                alignItems="center"
                _hover={{ textDecor: 'underline', color: 'var(--secondary)' }}
              >
                <FaHospitalAlt style={{ marginRight: 7 }} />
                Entrar
              </Flex>
              <IsAllowed id={4}>
                <Flex
                  onClick={editHospital}
                  cursor="pointer"
                  alignItems="center"
                  _hover={{ textDecor: 'underline', color: 'cyan.300' }}
                >
                  Editar
                  <AiFillSetting style={{ marginLeft: 7 }} />
                </Flex>
              </IsAllowed>
            </Flex>
          </Flex>
        </AnimationContainer>
      </Flex>
    </Flex>
  );
}
