import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { IText, IMultiSelect } from '~/components/UI/Inputs';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider, Spinner } from '~/components/ChakraComponents';

export default function CreateConfigSchedule({
  closeModal,
  setConfigId,
  editable,
  fetchDataMain,
}) {
  const [multi, setMulti] = useState({});
  const [inputs, setInputs] = useState({
    checkingLagTime: 0,
    checkoutEarlyTime: 0,
  });
  const [loading, setLoading] = useState(false);
  const [doctor, setDoctor] = useState([]);

  const searchDoctor = e => {
    api
      .get(`/companies/mydoctors?limit=7&isActive=true&name=${e}`)
      .then(res => {
        setDoctor(res.data.data);
      });
  };

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const fetchData = () => {
    setLoading(true);
    api.get(`/companies/mydoctors?limit=7&isActive=true`).then(res => {
      setDoctor(res.data.data);
      setLoading(false);
    });
  };

  const convertToMilliseconds = t => {
    const timeArray = t.split(':');
    const milliseconds = timeArray[0] * 3600000 + timeArray[1] * 36000;
    return milliseconds;
  };

  const submitConfig = () => {
    if (!inputs.name) {
      return toast.error('Por favor selecione um nome.');
    }
    setLoading(true);
    const checkingLagTime = convertToMilliseconds(inputs.checkingLagTime);
    const checkoutEarlyTime = convertToMilliseconds(inputs.checkoutEarlyTime);

    const request = {
      name: inputs.name,
      checkingLagTime,
      checkoutEarlyTime,
      companyDoctors: multi.doctors,
    };
    return api.post(`/companies/config/schedule`, request).then(res => {
      toast.success('Configuração criada com sucesso!');
      setLoading(false);
      setConfigId([res.data]);
      editable(true);
      fetchDataMain();
      closeModal();
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ModalContainer w="500px" overflow={false}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Flex flexDirection="column">
            <IText
              label="Nome da configuração"
              placeholder="Ex: Configuração 1"
              name="name"
              onChange={handleChanges}
            />
            <Flex mt={5} mb={5}>
              <IText
                style={{ marginRight: 50 }}
                label="Checkin atrasado"
                placeholder="Ex: 01:10 h"
                name="checkingLagTime"
                useCleave
                options={{
                  time: true,
                  timePattern: ['h', 'm'],
                }}
                onChange={handleChanges}
              />
              <IText
                label="Checkout adiantado"
                placeholder="Ex: 00:20 h"
                name="checkoutEarlyTime"
                useCleave
                options={{
                  time: true,
                  timePattern: ['h', 'm'],
                }}
                onChange={handleChanges}
              />
            </Flex>
            <IMultiSelect
              label="Médicos pré-aprovados"
              placeholder="Ex: João, Maria, Antonio"
              options={doctor}
              id="doctor"
              showCheckbox
              closeOnSelect={false}
              onSelect={a => {
                setMulti({
                  ...multi,
                  doctors: a.map(doctors => doctors.companyDoctor.id),
                });
              }}
              onRemove={a => {
                setMulti({
                  ...multi,
                  doctors: a.map(doctors => doctors.companyDoctor.id),
                });
              }}
              onSearch={searchDoctor}
            />
          </Flex>
          <Divider />
          <Flex justify="space-between">
            <Button title="cancelar" variant="secondary" onClick={closeModal} />
            <Button title="criar" variant="success" onClick={submitConfig} />
          </Flex>
        </>
      )}
    </ModalContainer>
  );
}
