import React, { useState, useCallback, useEffect } from 'react';

import api from '~/services/api';
import convertBRL from '~/utils/convertBRL';

import PageLayout from '~/pages/_layouts/company/PageLayout';

import { Button } from '~/components/ChakraComponents/';

import { Table, Tr, Th, Td } from './styles';

export default function Monthly() {
  const [info, setInfo] = useState();
  const [loadingPage, setLoadingPage] = useState(true);

  const fetchData = useCallback(() => {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    const url = `/companies/reports/monthlyresumecost?month=${month}&year=${year}`;

    api.get(url).then(response => {
      const {
        data: { hospitalsData },
      } = response;

      hospitalsData.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });

      setInfo(hospitalsData);
      setLoadingPage(false);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageLayout loading={loadingPage}>
      <Button
        title="voltar"
        to="/company/spendcontrol"
        variant="secondary"
        height="40px"
        width="100px"
        margin="0 0 20px"
      />
      {info && (
        <Table>
          <thead>
            <Tr>
              <Th>Hospital</Th>
              {/* <Th>Faturamento Bruto</Th>
              <Th>Impostos</Th> */}
              <Th>Gastos Com Médicos</Th>
            </Tr>
          </thead>
          <tbody>
            {info.map(hospital => (
              <Tr>
                <Td>{hospital.name}</Td>
                {/* <Td>{convertBRL(hospital.grossBilling)}</Td>
                <Td>{convertBRL(hospital.taxDeduction)}</Td> */}
                <Td>{convertBRL(hospital.doctorsValue)}</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      )}
    </PageLayout>
  );
}
