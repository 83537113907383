import React, { useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Flex, HStack, useBoolean } from '@chakra-ui/react';

import { getParam } from '~/utils/url';
import { getToken } from '~/services/auth';

import { IDate, ICheck } from '~/components/UI/Inputs';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider } from '~/components/ChakraComponents';

const initialStartDate = moment()
  .startOf('month')
  .format('YYYY-MM-DD');

const initialEndDate = moment()
  .endOf('month')
  .format('YYYY-MM-DD');

export default function ExcelModal() {
  const { groupId } = useParams();

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [abbreviate, setAbbreviate] = useBoolean();

  const onSubmit = () => {
    const { hospitalId } = getParam();

    const token = getToken();

    const baseURL = process.env.REACT_APP_API_URL;

    return window.open(
      `${baseURL}/companies/myhospitals/${hospitalId}/schedulesgroup/${groupId}/vacancies/excel?startDate=${startDate}&endDate=${endDate}&token=${token}&abbreviate=${abbreviate}`
    );
  };

  return (
    <ModalContainer w="300px">
      <HStack>
        <IDate
          required
          label="data inicial"
          initialDate={initialStartDate}
          onChange={date => setStartDate(date)}
        />
        <IDate
          required
          label="data final"
          initialDate={initialEndDate}
          onChange={date => setEndDate(date)}
        />
      </HStack>
      <Divider />
      <HStack justify="left">
        <ICheck
          label="Abreviar Nomes"
          checked={abbreviate}
          onChange={() => {
            setAbbreviate.toggle();
          }}
        />
      </HStack>
      <Divider />
      <Flex>
        <Button width="100%" title="gerar excel" onClick={onSubmit} />
      </Flex>
    </ModalContainer>
  );
}
