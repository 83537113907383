import styled from 'styled-components';

export const Option = styled.div`
  cursor: pointer;

  width: 100%;

  padding: 20px 0;

  font-size: 16px;
  font-weight: bold;

  text-align: center;
  text-transform: uppercase;

  box-shadow: var(--shadow);

  border-radius: 4px;

  color: ${props =>
    props.isActive ? 'var(--background-alt)' : 'var(--secondary)'};
  background-color: ${props =>
    props.isActive ? 'var(--secondary)' : 'var(--background-alt)'};

  &:nth-child(2) {
    margin: 0 20px;
  }
`;
