import React, { useRef, useCallback, useMemo } from 'react';
import { FaCog, FaEdit, FaPowerOff } from 'react-icons/fa';
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
} from '@chakra-ui/react';

import EditContractModal from './EditContractModal';

import { Modal } from '~/components/UI/Modal';

import { handleContractIsActive } from './actions';

export default function ContractConfigButton({ group, contract }) {
  const editContractModalRef = useRef(null);

  const openEditContract = useCallback(() => {
    editContractModalRef.current.openModal();
  }, []);

  const isActiveColor = useMemo(() => {
    return contract.isActive ? 'var(--red)' : 'var(--green)';
  }, [contract]);

  return (
    <Flex align="center" justify="center">
      <Menu>
        <MenuButton>
          <FaCog size={16} color="var(--primary)" />
        </MenuButton>
        <MenuList borderColor="gray.200">
          <MenuItem onClick={openEditContract} icon={<FaEdit size={20} />}>
            Editar plantão
          </MenuItem>
          <MenuDivider />
          <MenuItem
            onClick={() => handleContractIsActive(contract)}
            icon={<FaPowerOff size={18} color={isActiveColor} />}
          >
            <Text color={isActiveColor}>
              {contract.isActive ? 'Desativar' : 'Ativar'} plantão
            </Text>
          </MenuItem>
        </MenuList>
        <Modal
          title="Editar Plantão"
          ref={editContractModalRef}
          component={EditContractModal}
          group={group}
          contract={contract}
        />
      </Menu>
    </Flex>
  );
}
