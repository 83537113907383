import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import { Flex, Text } from '@chakra-ui/react';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { Title } from '~/components/Kiwistrap/Page';
import { Button, Spinner } from '~/components/ChakraComponents/';

import { SelectCard } from './styles';

export default function ChooseRoutes({ user, onClose }) {
  const [loading, setLoading] = useState(false);

  const [allPermissions, setAllPermissions] = useState(false);

  const onSubmit = async () => {
    const permissionsList = {
      allPermissions,
    };

    api
      .patch(`/contractors/users/${user.id}`, permissionsList)
      .then(() => {
        toast.success('Usuário editado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  };

  return (
    <Flex
      w="600px"
      maxW="95vw"
      maxH="80vh"
      flexDir="column"
      overflowY="scroll"
      overflowX="hidden"
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Flex
            flexDir="column"
            alignItems="center"
            mt="20px"
            textAlign="center"
          >
            <Title center size={18} margin="0 0 5px">
              Acesso total
            </Title>
            <Text mb="20px">
              A conta poderá realizar quaisquer ação no sistema.
            </Text>
          </Flex>
          <Flex justifyContent="center" mb="20px">
            <SelectCard
              isActive={allPermissions}
              onClick={() => setAllPermissions(!allPermissions)}
            >
              Acesso Total
            </SelectCard>
          </Flex>
          <Flex mt="20px" justifyContent="space-between">
            <Button title="cancelar" variant="secondary" onClick={onClose} />
            <Button title="confirmar" variant="success" onClick={onSubmit} />
          </Flex>
        </>
      )}
    </Flex>
  );
}
