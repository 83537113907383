import React, { useCallback } from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';
import { AiFillFilePdf } from 'react-icons/ai';

import { Autogrid } from '~/components/Kiwistrap/Page';

export default function DocumentsModal({ documents }) {
  const documentNameDictionary = useCallback(documentName => {
    let name = {
      imgRG: 'RG / CNH',
      imgCRM: 'Carteirinha CRM',
      imgDegreeFront: 'Diploma (Frente)',
      imgDegreeBack: 'Diploma (Verso)',
      imgDegreeSpecialtyFront: 'Certificado de Especialização (Frente)',
      imgDegreeSpecialtyBack: 'Certificado de Especialização (Verso)',
      imgEthical: 'Certidão Ético-Profissional do Conselho Regional',
      imgProofOfAddress: 'Comprovante de Residência',
    }[documentName];

    if (!name) name = documentName;

    return name;
  }, []);

  return (
    <Flex
      w="1200px"
      maxW="95vw"
      maxH="85vh"
      flexDir="column"
      overflowX="scroll"
      overflowY="scroll"
    >
      <Flex justifyContent="center">
        <Text size="16px">Clique em um documento para abrir ele.</Text>
      </Flex>
      <Autogrid childrenSize="250px" mt={2}>
        {documents.map(document => (
          <Flex
            cursor="pointer"
            w="100%"
            h="250px"
            flexDir="column"
            borderRadius="4px"
            boxShadow="var(--shadow)"
            bgColor="var(--background-alt)"
            key={document.id}
            onClick={() => window.open(document.fileUrl)}
            _hover={{ color: 'var(--secondary)' }}
          >
            {document.bucketName.match(/.(jpg|jpeg|png)$/i) && (
              <Image
                w="100%"
                h="190px"
                objectFit="cover"
                flexShrink="none"
                borderTopRadius="4px"
                src={document.fileUrl}
                alt={document.name}
              />
            )}
            {document.bucketName.match(/.(pdf)$/i) && (
              <Flex
                w="100%"
                h="190px"
                align="center"
                justify="center"
                fontSize="80px"
                color="var(--red)"
              >
                <AiFillFilePdf />
              </Flex>
            )}
            <Flex
              w="100%"
              h="60px"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              borderTop="1px solid var(--dim-gray)"
              flexShrink="none"
            >
              <Text>{documentNameDictionary(document.name)}</Text>
            </Flex>
          </Flex>
        ))}
      </Autogrid>
    </Flex>
  );
}
