import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  grid-gap: 7px;
  padding-top: 24px;
`;

export const Header = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  font-weight: bold;
  color: var(--primary);
  text-transform: uppercase;
`;

export const Legend = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 18px;
`;

export const Color = styled.div`
  height: 18px;
  margin-right: 12px;

  width: ${props => (props.width ? props.width : '18px')};
  background-color: ${props =>
    props.color ? props.color : 'var(--background)'};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 0)};
`;

export const Text = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: var(--dark-gray);
`;
