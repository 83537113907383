import React, { useCallback } from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';

import { SubTitle } from '~/components/Kiwistrap/Page';

import { Container, Avatar, Header, Text, ShowSvg } from './styles';

const VacancyCard = ({ vacancy, openCandidate }) => {
  const handleOpenCandidate = useCallback(() => {
    openCandidate(vacancy);
  }, [openCandidate, vacancy]);

  const formatDate = useCallback(date => {
    return moment(new Date(date)).format('DD/MM/YYYY');
  }, []);

  const formatHour = useCallback(hour => {
    return `${moment(new Date(hour)).format('HH:mm')}h`;
  }, []);

  return (
    <Container onClick={handleOpenCandidate}>
      <Avatar
        src={vacancy.contract.hospital.avatar}
        alt={vacancy.contract.hospital.name}
      />
      <Flex w="100%" flexDir="column" ml="20px" justify="center">
        <Header>{`${vacancy.contract.hospital.name} - ${formatDate(
          vacancy.startHour
        )}`}</Header>
        <Flex flexDir="column">
          <Text>{`${vacancy.contract.hospital.city}, ${vacancy.contract.hospital.address}, ${vacancy.contract.hospital.street}`}</Text>
          <Text>{vacancy.contract.specialty.name}</Text>
        </Flex>
      </Flex>
      <Flex w="auto" flexDir="column" justify="center">
        <SubTitle>Início</SubTitle>
        <Text>{formatHour(vacancy.startHour)}</Text>
      </Flex>
      <Flex w="auto" mx="30px" flexDir="column" justify="center">
        <SubTitle>Fim</SubTitle>
        <Text>{formatHour(vacancy.endHour)}</Text>
      </Flex>
      <Flex w="auto" flexDir="column" justify="center">
        <ShowSvg />
      </Flex>
    </Container>
  );
};

export default VacancyCard;
