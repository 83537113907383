import {
  DoctorCourseware,
  DoctorDashboard,
  DoctorDocuments,
  DoctorEditAccount,
  DoctorFindVacancy,
  DoctorHourControl,
  DoctorMyHospitals,
  DoctorMyVacancies,
  DoctorNews,
  DoctorProfile,
  DoctorProtocols,
} from '~/pages/doctor';

export default [
  { path: '/medico/material', component: DoctorCourseware },
  { path: '/medico/dashboard', component: DoctorDashboard },
  { path: '/medico/documentos', component: DoctorDocuments },
  { path: '/medico/editar', component: DoctorEditAccount },
  { path: '/medico/findvacancy', component: DoctorFindVacancy },
  { path: '/medico/control', component: DoctorHourControl },
  { path: '/medico/myhospitals', component: DoctorMyHospitals },
  { path: '/medico/myvacancies', component: DoctorMyVacancies },
  { path: '/medico/news', component: DoctorNews },
  { path: '/medico/profile', component: DoctorProfile },
  { path: '/medico/protocols', component: DoctorProtocols },
];
