import React, { useState, useRef, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';

import SetupModal from './SetupModal';

import FindDoctor from '~/components/UI/FindDoctor';

import { Button } from '~/components/ChakraComponents';
import { Modal, ModalContainer } from '~/components/UI/Modal';

export default function CreateVacancyModal({ schedule }) {
  const [companyDoctor, setCompanyDoctor] = useState(null);

  const setupModalRef = useRef(null);

  const openSetup = useCallback(() => {
    setupModalRef.current.openModal();
  }, []);

  const generateUrl = (inputs, limit, offset) => {
    const { name, specialty, crm, crmUf } = inputs;

    const url = `/companies/mydoctors?sort=name&order=asc&name=${name}&specialty=${specialty}&crm=${crm}&crmUf=${crmUf}&limit=${limit}&offset=${offset}&isActive=true`;

    return url;
  };

  return (
    <ModalContainer w="1200px" h="1400px">
      <Flex justify="center">
        <Button
          title="criar vaga aberta"
          variant="success"
          onClick={openSetup}
        />
      </Flex>
      <FindDoctor
        getSearchUrl={generateUrl}
        extensions={[
          {
            title: 'criar com médico',
            bodyFunc: doctor => (
              <Button
                title="criar vaga"
                width="100%"
                height="40px"
                onClick={() => {
                  setCompanyDoctor(doctor.companyDoctor.id);
                  openSetup();
                }}
              />
            ),
          },
        ]}
      />
      <Modal
        title="Opções"
        ref={setupModalRef}
        component={SetupModal}
        schedule={schedule}
        companyDoctor={companyDoctor}
      />
    </ModalContainer>
  );
}
