import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Flex, Text, useBoolean } from '@chakra-ui/react';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import ProgressModal from '~/components/Dashboard/Modals/ProgressModal';
import { IText } from '~/components/UI/Inputs';
import { Button, Alert } from '~/components/ChakraComponents';
import { ModalContainer } from '~/components/UI/Modal';

export default function FixedCandidatureModal({
  vacancy,
  onSuccess,
  closeModal,
}) {
  const [loading, setLoading] = useBoolean();

  const [toDate, setToDate] = useState();
  const [isToEndSchedule, setIsToEndSchedule] = useBoolean();

  const [confirmToEnd, setConfirmToEnd] = useBoolean();
  const [confirmToDate, setConfirmToDate] = useBoolean();

  const onSubmit = () => {
    if (!toDate && !isToEndSchedule) {
      setLoading.off();
      return internalServerError();
    }

    const request = {
      type: 'default',
      vacancy: vacancy.id,
      countJump: vacancy.countJump,
    };

    if (toDate) {
      const formattedToDate = toDate
        .split('/')
        .reverse()
        .join('-');

      if (moment(new Date(formattedToDate)).diff(new Date(), 'days') < 0) {
        setLoading.off();
        return toast.error('Data digitada é menor que hoje.');
      }

      request.toDate = formattedToDate;
    }

    if (isToEndSchedule) {
      delete request.toDate;
      request.isToEndSchedule = isToEndSchedule;
    }

    api
      .post('/doctors/candidatures/v2', request)
      .then(() => {
        onSuccess();
        closeModal();
        setLoading.off();

        return toast.success('Candidatura realizada com sucesso!');
      })
      .catch(e => {
        setLoading.off();

        if (e?.response?.data?.error === 'concurrentvacancies') {
          return toast.error(
            'Você já possui um plantão/candidatura nesse horário.'
          );
        }

        return internalServerError();
      });
  };

  useEffect(() => {
    if (loading) onSubmit();
  }, [loading]);

  return (
    <ModalContainer w="350px">
      <Flex justify="center" textAlign="center">
        <Text>Candidatar-se até:</Text>
      </Flex>
      <Flex justify="space-between">
        <Button
          title="fim do contrato"
          margin="0 20px 0 0"
          onClick={setConfirmToEnd.toggle}
        />
        <Alert
          isOpen={confirmToEnd}
          onClose={setConfirmToEnd.toggle}
          onConfirm={() => {
            setLoading.on();
            setToDate(null);
            setIsToEndSchedule.on();
          }}
          header="Confirmar candidatura"
          body="Deseja se candidatar a esse plantão até o fim do contrato?"
        />
        <Button title="data específica" onClick={setConfirmToDate.toggle} />
        <Alert
          isOpen={confirmToDate}
          onClose={setConfirmToDate.toggle}
          onConfirm={() => {
            setLoading.on();
            setIsToEndSchedule.off();
          }}
          header="Confirmar candidatura"
          body={
            <IText
              label="Candidatar-se até a data:"
              placeholder={'Ex: ' + moment(new Date()).format('DD/MM/YYYY')}
              useCleave
              options={{
                date: true,
                delimiter: '/',
                datePattern: ['d', 'm', 'Y'],
              }}
              value={toDate}
              onChange={e => setToDate(e.target.value)}
            />
          }
        />
      </Flex>
      <ProgressModal
        loading={loading}
        text="Realizando candidaturas... Por favor aguarde, isso pode demorar alguns minutos."
      />
    </ModalContainer>
  );
}
