export const personalData = [
  {
    label: 'nome',
    name: 'name',
  },
  {
    type: 'email',
    label: 'email',
    name: 'email',
  },
  {
    type: 'password',
    label: 'senha',
    name: 'password',
  },
  {
    type: 'password',
    label: 'confirmar senha',
    name: 'password_confirm',
  },
  {
    label: 'telefone 01',
    name: 'phone1',
    useCleave: true,
    options: {
      phone: true,
      phoneRegionCode: 'BR',
    },
  },
  {
    required: false,
    label: 'telefone 02',
    name: 'phone2',
    useCleave: true,
    options: {
      phone: true,
      phoneRegionCode: 'BR',
    },
  },
];

export const adminData = [
  {
    label: 'nome da empresa',
    name: 'contractorName',
  },
  {
    label: 'razão social',
    name: 'contractorSocialReason',
  },
  {
    label: 'cnpj',
    name: 'contractorCnpj',
    useCleave: true,
    options: {
      delimiters: ['.', '.', '/', '-'],
      blocks: [2, 3, 3, 4, 2],
    },
  },
  {
    type: 'email',
    label: 'email',
    name: 'contractorEmail',
  },
  {
    label: 'telefone 01',
    name: 'contractorPhone1',
    useCleave: true,
    options: {
      phone: true,
      phoneRegionCode: 'BR',
    },
  },
  {
    required: false,
    label: 'telefone 02',
    name: 'contractorPhone2',
    useCleave: true,
    options: {
      phone: true,
      phoneRegionCode: 'BR',
    },
  },
];
