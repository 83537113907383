import React, { useEffect, useRef } from 'react';
import { FieldContainer, CleaveInput, DefaultInput } from './styles';
import { useField } from '../Form';

export default function Field({ name, label, useCleave, options, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FieldContainer>
      {label && <label htmlFor={fieldName}>{label}</label>}
      {useCleave ? (
        <CleaveInput
          htmlRef={hRef => (inputRef.current = hRef)}
          id={fieldName}
          options={options}
          {...rest}
        />
      ) : (
        <DefaultInput ref={inputRef} id={fieldName} {...rest} />
      )}
    </FieldContainer>
  );
}
