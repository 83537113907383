import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { StarFull, StarHalf, StarEmpty } from './styles';

export default function Stars({ rating, size = 24, children }) {
  function renderStars() {
    const ratingToNumber = Number(rating);
    const stars = Math.floor(ratingToNumber / 2);

    const starsArr = [];

    for (let i = 0; i < stars; i -= -1) {
      starsArr.push(<StarFull size={`${size}px`} />);
    }

    if (ratingToNumber % 2 === 1) {
      starsArr.push(<StarHalf size={`${size}px`} />);
    }

    const leftover = 5 - starsArr.length;

    for (let i = 0; i < leftover; i -= -1) {
      starsArr.push(<StarEmpty size={`${size}px`} />);
    }

    return starsArr;
  }

  return (
    <Flex
      w="100%"
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      {rating === null ? (
        <Text mt="3px" mb="3px" color="gray.500">
          Não possui avaliação
        </Text>
      ) : (
        renderStars()
      )}
      {children}
    </Flex>
  );
}
