import React, { useState, useRef, useEffect } from 'react';

import capitalizeString from '~/utils/capitalizeString';

import { Button } from '~/components/ChakraComponents/';

import { useAuth } from '~/hooks/AuthContext';

import api from '~/services/api';

import {
  UserAvatar,
  Container,
  BtnContainer,
  UserName,
  UserEmail,
  InputFile,
} from './styles';

const UserInfo = React.memo(() => {
  const { signOut } = useAuth();

  const [avatarFile, setAvatarFile] = useState(null);
  const [Avatar, setAvatar] = useState(
    'https://verde-azul-imoveis.s3.amazonaws.com/b36f1999-aada-4f0b-9fe8-21bdf419fef7.png'
  );
  const [Title, setTitle] = useState('');
  const [SubTitle, setSubTitle] = useState('');

  const inputFile = useRef(null);

  useEffect(() => {
    api.get(`/doctors/myprofile`).then(res => {
      setAvatar(res.data.user.avatar);
      setTitle(
        res.data.user.name
          .split(/\s+/)
          .slice(0, 2)
          .join(' ')
      );
      setSubTitle(res.data.user.email);
    });
  }, []);

  async function fileUpload() {
    const data = new FormData();
    data.append('file', avatarFile);

    const response = await api.post('/avatar/', data);

    return response.data[0].filename;
  }

  async function dataEff() {
    if (avatarFile) {
      const avatarName = await fileUpload();

      await api.patch('/doctors/myprofile', {
        avatar: avatarName,
      });

      window.location.reload();
    }
  }

  useEffect(() => {
    dataEff();
  }, [avatarFile]);

  return (
    <Container>
      <UserAvatar onClick={() => inputFile.current.click()}>
        <InputFile
          ref={inputFile}
          onChange={res => setAvatarFile(res.target.files[0])}
        />
        <img src={Avatar} alt="user" />
        <section>
          <span>
            Alterar
            <br />
            Avatar
          </span>
        </section>
      </UserAvatar>
      <UserName>{capitalizeString(Title)}</UserName>
      <UserEmail>{SubTitle}</UserEmail>
      <BtnContainer>
        <Button title="perfil" to="/medico/profile" />
        <Button title="sair" onClick={signOut} />
      </BtnContainer>
    </Container>
  );
});

export default UserInfo;
