import { useContext, useEffect, useMemo } from 'react';

import { FormContext } from '../Form/';

export default function useField(name) {
  const { initialData, registerField, unregisterField } = useContext(
    FormContext
  );

  if (!name) {
    throw new Error('You need to provide the "name" prop.');
  }

  const fieldName = useMemo(() => {
    return name;
  }, [name]);

  // const defaultValue = useMemo(() => {
  //   return initialData.findIndex(field => field.name === name);
  // }, []);

  useEffect(() => {
    unregisterField(fieldName);
  }, [fieldName, unregisterField]);

  return { fieldName, registerField };
}
