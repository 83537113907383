import React, { useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FaStreetView } from 'react-icons/fa';
import { Flex, useBoolean } from '@chakra-ui/react';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { GiSkeletalHand, GiMoneyStack } from 'react-icons/gi';

import api from '~/services/api';
import convertBRL from '~/utils/convertBRL';
import formatDate from '~/utils/formatDate';
import internalServerError from '~/utils/internalServerError';

import FixedCandidatureModal from './FixedCandidatureModal';
import { Modal } from '~/components/UI/Modal';
import { SubTitle } from '~/components/Kiwistrap/Page';
import { Button, Alert } from '~/components/ChakraComponents/';

import {
  Container,
  PurpleBackground,
  HospitalAvatar,
  HospitalName,
  InfoSection,
  Text,
} from './styles';

export default function CandidateModal({ vacancy, reloadSearch, closeModal }) {
  const [confirmException, setConfirmException] = useBoolean();

  const fixedCandidatureModalRef = useRef(null);

  const openFixedCandidature = useCallback(() => {
    fixedCandidatureModalRef.current.openModal();
  }, []);

  const renderTypeText = useCallback(type => {
    return {
      weekly: 'Plantão Semanal',
      biweekly: 'Plantão Quinzenal',
      cover: 'Vaga Adicional',
      exception: 'Cobertura',
    }[type];
  }, []);

  const handleConfirm = useCallback(() => {
    const url = '/doctors/candidatures';

    const request = {
      type: 'exception',
      vacancy: vacancy.id,
      countJump: vacancy.countJump,
    };

    api
      .post(url, request)
      .then(() => {
        toast.success('Candidatura realizada com sucesso!');
        reloadSearch();
        closeModal();
      })
      .catch(internalServerError);
  }, [vacancy]);

  return (
    <Container>
      <PurpleBackground>
        <HospitalAvatar
          src={vacancy.contract.hospital.avatar}
          alt={vacancy.contract.hospital.name}
        />
        <HospitalName>{vacancy.contract.hospital.name}</HospitalName>
      </PurpleBackground>
      <InfoSection>
        <AiOutlineFieldTime />
        <Flex flexDir="column">
          <SubTitle>Início</SubTitle>
          <Text>{formatDate(vacancy.startHour).hours()}h</Text>
        </Flex>
        <Flex flexDir="column" padding="0 40px">
          <SubTitle>Fim</SubTitle>
          <Text>{formatDate(vacancy.endHour).hours()}h</Text>
        </Flex>
        <Flex flexDir="column">
          <SubTitle>Data</SubTitle>
          <Text>{formatDate(vacancy.startHour).bars()}</Text>
        </Flex>
      </InfoSection>
      <InfoSection>
        <FaStreetView />
        <Flex flexDir="column">
          <SubTitle>Endereço</SubTitle>
          <Text>{`${vacancy.contract.hospital.uf} - ${vacancy.contract.hospital.city}, ${vacancy.contract.hospital.address}, ${vacancy.contract.hospital.street}`}</Text>
        </Flex>
      </InfoSection>
      <InfoSection>
        <GiSkeletalHand />
        <Flex flexDir="column">
          <SubTitle>Especialidade</SubTitle>
          <Text>{vacancy.contract.specialty.name}</Text>
        </Flex>
      </InfoSection>
      <InfoSection>
        <GiMoneyStack />
        <Flex flexDir="column">
          <SubTitle>Honorários</SubTitle>
          <Text>
            {convertBRL(vacancy.contract.schedule.defaultDoctorValue)}
          </Text>
        </Flex>
      </InfoSection>
      <Flex justify="space-around" pt="15px">
        <Button
          variant="warning"
          title={`somente\n${formatDate(vacancy.startHour).bars()}`}
          onClick={setConfirmException.toggle}
        />
        <Alert
          isOpen={confirmException}
          onClose={setConfirmException.toggle}
          onConfirm={handleConfirm}
          header="Confirmar candidatura"
          body="Deseja se candidatar a esse plantão?"
        />
        <Button
          title={renderTypeText(vacancy.type)}
          onClick={openFixedCandidature}
        />
      </Flex>
      <Modal
        title="Plantão Fixo"
        ref={fixedCandidatureModalRef}
        component={FixedCandidatureModal}
        vacancy={vacancy}
        onSuccess={() => {
          closeModal();
          reloadSearch();
        }}
      />
    </Container>
  );
}
