import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@chakra-ui/react';
import { BsGraphUp } from 'react-icons/bs';
import { FaDesktop, FaUsers, FaUserMd, FaHospitalAlt } from 'react-icons/fa';

import api from '~/services/api';
import MeuPlantaoLogo from '~/assets/logo/LogoWhite.png';
// import markCompanyNotificationAsRead from '~/utils/api/markCompanyNotificationAsRead';

import { getUser } from '~/services/auth';

import IsAllowed from '~/components/UI/IsAllowed';

import { SubTitle } from '~/components/Kiwistrap/Page';

import {
  LogoLink,
  LogoImg,
  Navigation,
  PageLink,
  NotificationsSection,
  BellContainer,
  Bell,
  NotificationMenuBackground,
  NotificationsMenu,
  NotificationsMenuItem,
} from './styles';

const Nav = () => {
  const user = getUser();

  const [unread, setUnread] = useState(0);
  const [notifications, setNotifications] = useState();
  const [openNotifications, setOpenNotifications] = useState(false);

  const fetchNotifications = useCallback(() => {
    api.get('/contractors/notifications?limit=50').then(response => {
      const { data } = response.data;

      let unread = 0;

      data.forEach(notification => {
        if (!notification.read) unread += 1;
      });

      setUnread(unread);
      setNotifications(data);

      //setTimeout(() => fetchNotifications(), 300000);
    });
  }, []);

  // useEffect(() => {
  //   fetchNotifications();
  // }, []);

  // const toggleNotifications = useCallback(() => {
  //   setOpenNotifications(!openNotifications);

  //   const nonReadIds = [];

  //   notifications.forEach(notification => {
  //     if (!notification.read) return nonReadIds.push(notification.id);
  //   });

  //   markCompanyNotificationAsRead(nonReadIds);

  //   fetchNotifications();
  // }, [openNotifications, notifications, fetchNotifications]);

  const handleBackgroundOnClick = useCallback(e => {
    if (e.target.id === 'notifications-background') {
      setOpenNotifications(false);
    }
  }, []);

  return (
    <Grid
      display={['none', 'none', 'none', 'grid']}
      w="100%"
      h="81px"
      backgroundColor="var(--primary)"
      templateColumns="170px auto 90px"
      alignItems="center"
    >
      <LogoLink to="/contractor/dashboard">
        <LogoImg src={MeuPlantaoLogo} alt="meu-plantao" />
      </LogoLink>
      <Navigation>
        <PageLink
          text="Dashboard"
          to="/contractor/dashboard"
          selected={window.location.pathname === '/contractor/dashboard'}
        >
          <FaDesktop />
        </PageLink>
        <PageLink
          text="Meus Hospitais"
          to="/contractor/myhospitals"
          selected={window.location.pathname === '/contractor/myhospitals'}
        >
          <FaHospitalAlt />
        </PageLink>
        {/* <PageLink
          text="Meus Médicos"
          to="/contractor/mydoctors"
          selected={window.location.pathname === '/contractor/mydoctors'}
        >
          <FaUserMd />
        </PageLink> */}
        {user.allPermissions && (
          <PageLink
            text="Usuários"
            to="/contractor/users"
            selected={window.location.pathname === '/contractor/users'}
          >
            <FaUsers />
          </PageLink>
        )}
        {/* <PageLink
          text="Controle de Gastos"
          to="/contractor/spendcontrol"
          selected={[
            '/contractor/spendcontrol',
            '/contractor/doctorspending',
            '/contractor/employeecontrol',
            '/contractor/expenses',
            '/contractor/expectedcosts',
            '/contractor/spendcontrol',
            '/contractor/reports/month',
            '/contractor/reports/year',
          ].includes(window.location.pathname)}
        >
          <BsGraphUp />
        </PageLink> */}
      </Navigation>
      {/* <NotificationsSection>
        <BellContainer
          unread={unread > 0 && unread}
          onClick={toggleNotifications}
        >
          <Bell />
        </BellContainer>
        {openNotifications && (
          <NotificationMenuBackground
            id="notifications-background"
            onClick={handleBackgroundOnClick}
          >
            <NotificationsMenu>
              <SubTitle
                style={{
                  padding: 10,
                  borderBottom: '2px solid var(--secondary',
                }}
              >
                Notificações
              </SubTitle>
              {notifications &&
                notifications.map(notification => (
                  <NotificationsMenuItem>
                    {notification.content}
                    <p>
                      {moment(new Date(notification.updatedAt)).format(
                        'HH:mm - DD/MM'
                      )}
                    </p>
                  </NotificationsMenuItem>
                ))}
            </NotificationsMenu>
          </NotificationMenuBackground>
        )}
      </NotificationsSection> */}
    </Grid>
  );
};

export default Nav;
