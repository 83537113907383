export const ufOptions = [
  { id: 'AC', value: 'AC', text: 'AC' },
  { id: 'AL', value: 'AL', text: 'AL' },
  { id: 'AM', value: 'AM', text: 'AM' },
  { id: 'AP', value: 'AP', text: 'AP' },
  { id: 'BA', value: 'BA', text: 'BA' },
  { id: 'CE', value: 'CE', text: 'CE' },
  { id: 'DF', value: 'DF', text: 'DF' },
  { id: 'ES', value: 'ES', text: 'ES' },
  { id: 'GO', value: 'GO', text: 'GO' },
  { id: 'MA', value: 'MA', text: 'MA' },
  { id: 'MT', value: 'MT', text: 'MT' },
  { id: 'MG', value: 'MG', text: 'MG' },
  { id: 'MS', value: 'MS', text: 'MS' },
  { id: 'PA', value: 'PA', text: 'PA' },
  { id: 'PB', value: 'PB', text: 'PB' },
  { id: 'PE', value: 'PE', text: 'PE' },
  { id: 'PI', value: 'PI', text: 'PI' },
  { id: 'PR', value: 'PR', text: 'PR' },
  { id: 'RJ', value: 'RJ', text: 'RJ' },
  { id: 'RN', value: 'RN', text: 'RN' },
  { id: 'RO', value: 'RO', text: 'RO' },
  { id: 'RR', value: 'RR', text: 'RR' },
  { id: 'RS', value: 'RS', text: 'RS' },
  { id: 'SC', value: 'SC', text: 'SC' },
  { id: 'SE', value: 'SE', text: 'SE' },
  { id: 'SP', value: 'SP', text: 'SP' },
  { id: 'TO', value: 'TO', text: 'TO' },
];

export default [
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MG',
  'MS',
  'PA',
  'PB',
  'PE',
  'PI',
  'PR',
  'RJ',
  'RN',
  'RO',
  'RR',
  'RS',
  'SC',
  'SE',
  'SP',
  'TO',
];
