import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { useAuth } from '~/hooks/AuthContext';

import { Row } from '~/components/Kiwistrap/Page';
import { Button } from '~/components/ChakraComponents/';

import { Container, WarningText } from './styles';

export default function DeleteAccount({ onClose }) {
  const { signOut } = useAuth();

  const handleDelete = useCallback(async () => {
    try {
      await api.patch('/deactivate');
    } catch (e) {
      toast.error('Houve um problema com nossos servidores, tente novamente.');
    }

    signOut();
  }, [signOut]);

  return (
    <Container>
      <WarningText>Atenção!</WarningText>
      <WarningText>Esta ação não poderá ser desfeita!</WarningText>
      <Row justifyContent="space-between" margin="40px 0 0 0">
        <Button title="cancelar" variant="secondary" onClick={onClose} />
        <Button title="excluir" variant="danger" onClick={handleDelete} />
      </Row>
    </Container>
  );
}
