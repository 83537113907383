import styled from 'styled-components';

export const SelectCard = styled.div`
  width: 140px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 8px 0;
  padding: 0 4px;

  border-radius: var(--br-sm);
  box-shadow: var(--shadow);

  text-align: center;

  font-size: 13px;
  font-weight: 500;

  cursor: pointer;

  margin-right: ${props => props.mRight || '0'};
  margin: ${props => props.margin};

  background-color: ${props =>
    props.isActive ? 'var(--green)' : 'var(--background-alt)'};

  color: ${props =>
    props.isActive ? 'var(--background-alt)' : 'var(--light-gray)'};
`;
