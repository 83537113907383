import styled from 'styled-components';
import { GoGear } from 'react-icons/go';
import { BsSun, BsMoon } from 'react-icons/bs';

export const GearSvg = styled(GoGear)`
  margin-bottom: 20px;

  font-size: 120px;

  color: var(--primary);
`;

export const SunSvg = styled(BsSun)`
  font-size: 24px;

  color: var(--yellow);
`;

export const MoonSvg = styled(BsMoon)`
  font-size: 24px;

  color: var(--dark-gray);
`;
