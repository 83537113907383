import styled from 'styled-components';

export const UserAvatar = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid var(--primary);

  img {
    width: 100%;
    height: 100%;

    padding: 3px;

    object-fit: cover;

    border-radius: 50%;

    background-color: var(--background-alt);

    flex-shrink: none;
  }

  section {
    display: none;
  }

  &:hover {
    section {
      cursor: pointer;
      position: absolute;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      color: #ffff;
      font-weight: bold;
      span {
        padding-top: 25%;
      }
    }
  }
`;

export const BtnContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, auto);
  grid-gap: 20px;
`;

export const InputFile = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;
