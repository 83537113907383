import styled from 'styled-components';
import {
  FaTimes,
  FaClock,
  FaHourglassEnd,
  FaCheck,
  FaUserEdit,
  FaUserCheck,
} from 'react-icons/fa';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    font-size: 18px;
    margin-right: 12px;
  }
`;

export const Color = styled.div`
  height: 18px;
  margin-right: 12px;

  width: ${props => (props.width ? props.width : '18px')};
  background-color: ${props =>
    props.color ? props.color : 'var(--background)'};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 0)};
`;

export const Info = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: var(--dark-gray);
`;

export const Candidature = styled.div`
  width: 18px;
  height: 18px;
  background-color: var(--red);
  color: var(--white);
  border-radius: 50%;
  font-size: 10px;
  margin-right: 12px;
  padding: 2px;
`;

export const RefusedSvg = styled(FaTimes)`
  color: var(--red);
`;

export const PendingSvg = styled(FaClock)`
  color: var(--yellow);
`;

export const ExpiredSvg = styled(FaHourglassEnd)`
  color: var(--red);
`;

export const ConfirmedSvg = styled(FaCheck)`
  color: var(--secondary);
`;

export const CheckinSvg = styled(FaUserEdit)`
  color: var(--secondary);
`;

export const CheckoutSvg = styled(FaUserCheck)`
  color: var(--green);
`;
