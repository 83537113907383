/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useCallback, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { BsPhone } from 'react-icons/bs';
import { FaStreetView } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { GiSkeletalHand } from 'react-icons/gi';
import { IoMdDocument } from 'react-icons/io';

import api from '~/services/api';

import phoneMask from '~/utils/phoneMask';
import getFirstLastName from '~/utils/getFirstLastName';
import internalServerError from '~/utils/internalServerError';

import InfoSection from './InfoSection';
import DocumentsModal from './DocumentsModal';
import IsAllowed from '~/components/UI/IsAllowed';

import { Modal } from '~/components/UI/Modal';
import { Button } from '~/components/ChakraComponents';

import {
  PurpleBackground,
  DoctorAvatar,
  DoctorName,
  DocumentComponent,
} from './styles';

export default function DoctorProfileModal({ doctor }) {
  const [confirmDesvinculate, setConfirmDesvinculate] = useState(false);

  const documentsModalRef = useRef(null);

  const openDocuments = useCallback(() => {
    documentsModalRef.current.openModal();
  }, []);

  const desvinculateDoctor = useCallback(() => {
    if (!confirmDesvinculate) return setConfirmDesvinculate(true);

    api
      .delete(`/companies/mydoctors/${doctor.doctor.id}`)
      .then(() => {
        window.location.reload();
      })
      .catch(internalServerError);
  }, [confirmDesvinculate, doctor.doctor.id]);

  const documentComponent = (
    <DocumentComponent onClick={() => openDocuments()} onKeyPress={() => {}}>
      Ver documentos
    </DocumentComponent>
  );

  const documentComponentDisabled = (
    <span>Este médico não possui documentos cadastrados</span>
  );

  return (
    <Flex maxW="400px" flexDir="column" overflowY="auto" overflowX="hidden">
      <PurpleBackground>
        <DoctorAvatar src={doctor.avatar} alt={getFirstLastName(doctor.name)} />
        <DoctorName>{doctor.name}</DoctorName>
      </PurpleBackground>
      <InfoSection
        icon={FaStreetView}
        title="Endereço"
        info={`${doctor.doctor.cep} - ${doctor.doctor.uf}, ${doctor.doctor.city}, ${doctor.doctor.address}, ${doctor.doctor.street}, ${doctor.doctor.number}`}
      />
      <InfoSection icon={AiOutlineMail} title="E-mail" info={doctor.email} />
      <InfoSection
        icon={BsPhone}
        title="Telefone"
        info={`${phoneMask(doctor.phone1)} ${phoneMask(doctor.phone2)}`}
      />
      <InfoSection
        icon={GiSkeletalHand}
        title="Especialidade"
        info={doctor.doctor.specialty.name}
      />
      <InfoSection
        icon={IoMdDocument}
        title="Documentos"
        info={
          doctor.doctor.documents.length > 0
            ? documentComponent
            : documentComponentDisabled
        }
      />
      <Flex justifyContent="space-between" mt="20px">
        <IsAllowed id={8}>
          {confirmDesvinculate ? (
            <Button
              title="confirmar desvinculação"
              variant="success"
              onClick={desvinculateDoctor}
              margin="0 auto"
            />
          ) : (
            <Button
              title="desvincular"
              variant="danger"
              onClick={desvinculateDoctor}
              margin="0 auto"
            />
          )}
        </IsAllowed>
      </Flex>
      <Modal
        title="Documentos"
        ref={documentsModalRef}
        component={DocumentsModal}
        documents={doctor.doctor.documents}
      />
    </Flex>
  );
}
