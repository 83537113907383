import { Dashboard as ContractorHospitalDashboard } from '~/components/UI/Pages';

import {
  ContractorHospitalProfile,
  ContractorHospitalVacanciesV2,
  ContractorHospitalVacanciesSpecialtiesNoneChoosen,
  ContractorHospitalVacanciesSpecialties,
  ContractorHospitalVacanciesGroupsNoneChoosen,
  ContractorHospitalVacanciesGroups,
} from '~/pages/contractorhospitals';

export default [
  {
    tag: 1,
    path: '/contractorhospital/dashboard',
    component: ContractorHospitalDashboard,
  },
  {
    tag: 1,
    path: '/contractorhospital/profile',
    component: ContractorHospitalProfile,
  },
  {
    tag: 6,
    path: '/contractorhospital/vacancies',
    component: ContractorHospitalVacanciesV2,
    exact: true,
  },
  {
    tag: 6,
    path: '/contractorhospital/vacancies/specialty/',
    component: ContractorHospitalVacanciesSpecialtiesNoneChoosen,
    exact: true,
  },
  {
    tag: 6,
    path:
      '/contractorhospital/vacancies/specialty/:specialtyId/:scheduleType/:vacancyDate',
    component: ContractorHospitalVacanciesSpecialties,
    exact: true,
  },
  {
    tag: 6,
    path: '/contractorhospital/vacancies/group/',
    component: ContractorHospitalVacanciesGroupsNoneChoosen,
    exact: true,
  },
  {
    tag: 6,
    path:
      '/contractorhospital/vacancies/group/:groupId/:scheduleType/:vacancyDate',
    component: ContractorHospitalVacanciesGroups,
    exact: true,
  },
];
