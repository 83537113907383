import React, { useState, useEffect } from 'react';

import isUserAllowed from '~/services/isUserAllowed';
import { getUser } from '~/services/auth';
import { getParam } from '~/utils/url';

export default function IsAllowed({ id, children }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const user = getUser();
    const { hospitalId } = getParam('hospitalId');

    let type = 'company';

    if (hospitalId) {
      type = 'hospital';
    }

    const permitted = isUserAllowed(id, `${type}Permissions`);

    if (user && permitted) {
      setShow(true);
    }
  }, []);

  if (!show) return null;

  return <>{children}</>;
}
