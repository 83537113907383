// ⣿⣷⡶⠚⠉⢀⣤⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⠠⣴⣿⣿⣿⣿⣶⣤⣤⣤
// ⠿⠥⢶⡏⣸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⢀⣴⣷⣌⢿⣿⣿⣿⣿⣿⣿⣿
// ⣍⡛⢷⣠⣿⣿⣿⣿⣿⣟⠻⣯⠽⣿⣿⠟⠁⣠⠿⠿⣿⣿⣎⠻⣿⣿⣿⡿⠟⣿
// ⣿⣿⣦⠙⣿⣿⣿⣿⣿⣿⣷⣏⡧⠙⠁⣀⢾⣧    ⠈⣿⡟  ⠙⣫⣵⣶⠇⣋
// ⣿⣿⣿⢀⣿⣿⣿⣿⣿⣿⣿⠟⠃⢀⣀⢻⣎⢻⣷⣤⣴⠟  ⣠⣾⣿⢟⣵⡆⢿
// ⣿⣯⣄⢘⢻⣿⣿⣿⣿⡟⠁⢀⣤⡙⢿⣴⣿⣷⡉⠉⢀  ⣴⣿⡿⣡⣿⣿⡿⢆
// ⠿⣿⣧⣤⡘⢿⣿⣿⠏  ⡔⠉⠉⢻⣦⠻⣿⣿⣶⣾⡟⣼⣿⣿⣱⣿⡿⢫⣾⣿
// ⣷⣮⣝⣛⣃⡉⣿⡏  ⣾⣧⡀    ⣿⡇⢘⣿⠋    ⠻⣿⣿⣿⢟⣵⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣌⢧⣴⣘⢿⣿⣶⣾⡿⠁⢠⠿⠁⠜    ⣿⣿⣿⣿⡿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣦⡙⣿⣷⣉⡛⠋    ⣰⣾⣦⣤⣤⣤⣿⢿⠟⢋⣴⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣌⢿⣿⣿⣿⣿⢰⡿⣻⣿⣿⣿⣿⣿⢃⣰⣫⣾⣿⣿⣿
// ⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡆⠿⠿⠿⠛⢰⣾⡿⢟⣭⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
import React, { useState, useEffect, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import removeMask from '~/utils/removeMask';
import internalServerError from '~/utils/internalServerError';

import { Label } from '~/components/UI/Text';
import { Button } from '~/components/ChakraComponents/';
import { ModalContainer } from '~/components/UI/Modal';
import { IRow, IText, IRadio, ICheck, ISelect } from '~/components/UI/Inputs';

export default function CreateUserModal({ closeModal }) {
  const [loading, setLoading] = useState(true);

  const [homes, setHomes] = useState([]);
  const [inputs, setInputs] = useState({
    homepage: 1,
    name: '',
    phone1: '',
    phone2: '',
    password: '',
  });
  const [groups, setGroups] = useState([
    {
      id: 0,
      value: 0,
      text: 'Selecione uma opção...',
    },
  ]);

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    api.get('/companies/group').then(groupRes => {
      const groupArr = groupRes.data.data.map(({ id, name: text }) => {
        return { id, value: id, text };
      });

      api.get('/companies/homepages').then(homeRes => {
        setHomes(homeRes.data.data);
        setGroups([...groups, ...groupArr]);
        setLoading(false);
      });
    });
  }, []);

  const handleSubmit = useCallback(() => {
    const request = { ...inputs };

    request.phone1 = removeMask(request.phone1);
    request.phone2 = removeMask(request.phone2);
    request.permissionsgroup = +request.permissionsgroup;

    api
      .post('/companies/users', request)
      .then(() => {
        toast.success('Usuário criado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(e => {
        if (e.response?.data?.error === 'Email already exist.') {
          return toast.error('Esse e-mail já está sendo utilizado no sistema.');
        }

        internalServerError();
      });
  }, [inputs]);

  const validateInputs = useCallback(() => {
    if (inputs.name.length < 4) {
      return toast.error(
        'Por favor insira um nome com pelo menos 4 caracteres.'
      );
    }

    if (!inputs.permissionsgroup || +inputs.permissionsgroup === 0) {
      return toast.error('O usuário precisa pertencer à um grupo.');
    }

    if (inputs.password.length < 8) {
      return toast.error('Por favor insira uma senha com 8 caracteres.');
    }

    if (inputs.phone1.length < 10) {
      return toast.error('Por favor insira telefone válido (Telefone 01).');
    }

    if (inputs.phone2.length >= 1 && inputs.phone2.length < 10) {
      return toast.error('Por favor insira telefone válido (Telefone 02).');
    }

    return handleSubmit(inputs);
  }, [inputs]);

  return (
    <ModalContainer loading={loading} h="370px">
      <Flex flexDir="column" alignItems="flex-start">
        <Label>Acesso total ao sistema</Label>
        <ICheck
          label="Conceder Acesso Total"
          checked={inputs.allPermissions}
          onChange={() =>
            setInputs({ ...inputs, allPermissions: !inputs.allPermissions })
          }
        />
      </Flex>
      <Flex flexDir="column">
        <Label>Página Inicial</Label>
        <IRow>
          {homes.map(home => (
            <IRadio
              key={home.id}
              label={home.name}
              checked={inputs.homepage === home.id}
              onChange={() => setInputs({ ...inputs, homepage: home.id })}
            />
          ))}
        </IRow>
      </Flex>
      <IRow>
        <IText
          required
          label="nome"
          name="name"
          value={inputs.name}
          onChange={handleChanges}
        />
        <ISelect
          required
          label="grupo de usuários"
          name="permissionsgroup"
          options={groups}
          value={inputs.permissionsgroup}
          onChange={handleChanges}
        />
      </IRow>
      <IRow>
        <IText
          required
          label="e-mail"
          name="email"
          value={inputs.email}
          onChange={handleChanges}
        />
        <IText
          required
          label="senha"
          name="password"
          type="password"
          value={inputs.password}
          onChange={handleChanges}
        />
      </IRow>
      <IRow>
        <IText
          required
          label="telefone 01"
          name="phone1"
          useCleave
          options={{
            phone: true,
            phoneRegionCode: 'BR',
          }}
          value={inputs.phone1}
          onChange={handleChanges}
        />
        <IText
          label="telefone 02"
          name="phone2"
          useCleave
          options={{
            phone: true,
            phoneRegionCode: 'BR',
          }}
          value={inputs.phone2}
          onChange={handleChanges}
        />
      </IRow>
      <Flex pt={3} justifyContent="space-between">
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <Button title="criar" variant="success" onClick={validateInputs} />
      </Flex>
    </ModalContainer>
  );
}
