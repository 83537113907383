import React, { useState, useEffect } from 'react';
import { AiFillRightSquare as NavigateSvg } from 'react-icons/ai';
import { Flex, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

import convertBRL from '~/utils/convertBRL';
import { toDateVacancyText } from '~/utils/toDate';

import Pagination from '~/components/Dashboard/Pagination';
import { Spinner } from '~/components/ChakraComponents';

import {
  renderTurn,
  renderDoctor,
  renderSituation,
  navigateToVacancy,
  fetchResumeDetails,
  renderCheckinCheckoutSvg,
} from './actions';

const limit = 50;

export default function ResumeDetailsModal({
  type,
  endDate,
  contract,
  startDate,
}) {
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [vacancies, setVacancies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true);
  }, [type, currentPage, contract]);

  useEffect(() => {
    if (loading) {
      fetchResumeDetails(
        response => {
          setTotal(response.data.total);
          setVacancies(response.data.data);
          setLoading(false);
        },
        { startDate, endDate, contract, currentPage, limit, type }
      );
    }
  }, [loading]);

  return (
    <Flex w="90vw" h="80vh" flexDir="column" alignItems="center">
      {toDateVacancyText(startDate, endDate)}
      <Flex w="100%" h="100%" overflowY="scroll">
        {loading ? (
          <Spinner />
        ) : (
          <Table>
            <Thead>
              <Tr>
                <Th>Data</Th>
                <Th>Hospital</Th>
                <Th>Médico</Th>
                <Th>Valor</Th>
                <Th>Situação</Th>
                <Th>Checkin / Checkout</Th>
                <Th>Ir para vaga</Th>
              </Tr>
            </Thead>
            <Tbody>
              {vacancies.map(vacancy => (
                <Tr key={vacancy.id}>
                  <Td>{renderTurn(vacancy)}</Td>
                  <Td>{vacancy.schedule.contract.hospital.name}</Td>
                  <Td>{renderDoctor(vacancy)}</Td>
                  <Td>{convertBRL(vacancy.value)}</Td>
                  <Td>{renderSituation(vacancy.situations)}</Td>
                  <Td>
                    {renderCheckinCheckoutSvg(
                      vacancy.checkin,
                      vacancy.checkout
                    )}
                  </Td>
                  <Td>
                    <Flex
                      onClick={() => navigateToVacancy(vacancy)}
                      w="auto"
                      pl={7}
                      cursor="pointer"
                      color="gray.500"
                      fontSize="3xl"
                      _hover={{
                        color: 'blue.500',
                      }}
                    >
                      <NavigateSvg />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Flex>
      <Pagination l={limit} t={total} onClick={setCurrentPage} />
    </Flex>
  );
}
