import React from 'react';
import { toast } from 'react-toastify';

import removeMask from '~/utils/removeMask';
import capitalizeString from '~/utils/capitalizeString';

import maritalStatusArr from './maritalStatusArr';

import { Button } from '~/components/ChakraComponents/';
import { Form, Field, Select } from '~/components/Kiwistrap/Form';

import { ResponsivePhaseContainer } from '../../styles';

export default function Phase4({ handlePhaseSubmit }) {
  const isOverEighteen = date => {
    const dateYear = date.split('/')[2];
    const currentYear = new Date().getFullYear();

    const age = +currentYear - +dateYear;

    if (age < 18) return false;

    return true;
  };

  const handleSubmit = data => {
    if (!data.rg) {
      return toast.error('Por favor insira um rg válido.');
    }

    if (!data.cpf || data.cpf.length < 14) {
      return toast.error('Por favor insira um cpf válido.');
    }

    if (
      !data.birthDate ||
      data.birthDate.length < 10 ||
      isOverEighteen(data.birthDate) === false
    ) {
      return toast.error('Por favor insira uma data de nascimento válida.');
    }

    if (!data.motherName) {
      return toast.error('Por favor insira o nome da mãe válido.');
    }

    if (!data.fatherName) {
      return toast.error('Por favor insira o nome do pai válido.');
    }

    if (!data.maritalStatus) {
      return toast.error('Por favor insira um estado civil válido.');
    }

    if (!data.nationality) {
      return toast.error('Por favor insira uma nacionalidade válida.');
    }

    data.rg = removeMask(data.rg).trim();
    data.cpf = removeMask(data.cpf);

    data.birthDate = data.birthDate
      .split('/')
      .reverse()
      .join('-');

    data.motherName = capitalizeString(data.motherName).trim();
    data.fatherName = capitalizeString(data.fatherName).trim();
    data.maritalStatus = capitalizeString(data.maritalStatus);
    data.nationality = capitalizeString(data.nationality).trim();

    return handlePhaseSubmit(data);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ResponsivePhaseContainer>
        <Field required label="rg" name="rg" />
        <Field
          required
          label="cpf"
          name="cpf"
          useCleave
          options={{
            blocks: [3, 3, 3, 2],
            delimiters: ['.', '.', '.', '-'],
          }}
        />
        <Field
          required
          label="data de nascimento"
          name="birthDate"
          useCleave
          options={{
            date: true,
            datePattern: ['d', 'm', 'Y'],
          }}
        />
        <Field required label="nome da mãe" name="motherName" />
        <Field required label="nome do pai" name="fatherName" />
        <Select
          required
          label="estado civil"
          name="maritalStatus"
          options={maritalStatusArr}
        />
        <Field required label="nacionalidade" name="nationality" />
        <Button
          variant="success"
          title="concluir cadastro"
          type="submit"
          width="100%"
          margin="12px 0 0"
        />
      </ResponsivePhaseContainer>
    </Form>
  );
}
