import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;

  width: 100%;
  height: 225px;

  margin: 10px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  text-align: center;

  box-shadow: var(--shadow);
  background-color: var(--background-alt);

  color: ${props => props.color};

  p {
    height: 40px;
    font-size: 20px;
    font-weight: 500;
  }

  svg {
    margin-bottom: 40px;
    font-size: 50px;
  }

  &:hover {
    color: var(--background-alt);
    background-color: ${props => props.color};
  }
`;
