import React, { useState, useCallback } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
} from 'react-icons/ai';

import { header, desc, box, arrow } from './styles';

export default function OptionsSection({
  title,
  description,
  items,
  setItems,
}) {
  const [selected, setSelected] = useState([]);

  const selectOne = useCallback(
    id => {
      const clone = [...selected];

      const i = clone.findIndex(cloneId => cloneId === id);

      if (i > -1) {
        clone.splice(i, 1);
      } else {
        clone.push(id);
      }

      setSelected(clone);
    },
    [selected]
  );

  const updateOne = useCallback(
    condition => {
      if (!selected) return;

      const clone = [...items];

      selected.forEach(id => {
        const i = clone.findIndex(item => item.id === id);

        clone[i].isChoosen = condition;
      });

      return setItems(clone);
    },
    [selected, items]
  );

  const updateAll = useCallback(
    condition => {
      const clone = [...items];

      clone.forEach(item => {
        item.isChoosen = condition;
      });

      setItems(clone);
      setSelected([]);
    },
    [items]
  );

  const renderBox = useCallback(
    condition => (
      <Flex {...box}>
        {items.map(item => {
          if (item.isChoosen === !condition) return null;

          return (
            <Flex
              key={item.id}
              cursor="pointer"
              py="8px"
              borderBottom="1px"
              borderBottomStyle="solid"
              borderBottomColor="var(--dim-gray)"
              onClick={() => selectOne(item.id)}
            >
              <Text color={selected.includes(item.id) ? 'cyan.400' : 'black'}>
                {item.name}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    ),
    [items, selected]
  );

  return (
    <Flex flexDir="column" my={2}>
      <Text {...header}>{title}</Text>
      <Text {...desc}>{description}</Text>
      <Flex>
        {renderBox(false)}
        <Flex
          flexDir="column"
          justifyContent="space-evenly"
          px={2}
          fontSize="2xl"
          fontWeight="bold"
          color="var(--background-alt)"
        >
          <Flex {...arrow} onClick={() => updateOne(true)}>
            <AiOutlineRight />
          </Flex>
          <Flex {...arrow} onClick={() => updateOne(false)}>
            <AiOutlineLeft />
          </Flex>
          <Flex {...arrow} onClick={() => updateAll(true)}>
            <AiOutlineDoubleRight />
          </Flex>
          <Flex {...arrow} onClick={() => updateAll(false)}>
            <AiOutlineDoubleLeft />
          </Flex>
        </Flex>
        {renderBox(true)}
      </Flex>
    </Flex>
  );
}
