import React from 'react';
import CurrencyInput from 'react-currency-masked-input';
import styled from 'styled-components';
import { Flex, Text } from '@chakra-ui/react';

import { Label } from '~/components/UI/Text';

const DefaultInput = styled.div`
  width: 100%;
  height: 40px;

  padding: 5px;

  display: flex;
  align-items: center;

  border-radius: var(--br-sm);

  border: 2px solid var(--black);

  background-color: var(--background-alt);
`;

export default function IMoney({ required, label, error, style, ...rest }) {
  return (
    <Flex flexDir="column" w="100%" style={style}>
      <Label>{required ? label + ' *' : label}</Label>
      <DefaultInput>
        <span style={{ marginRight: 4 }}>R$ </span>
        <CurrencyInput {...rest} />
      </DefaultInput>
      {error && (
        <Text mt="5px" color="var(--red)">
          {error}
        </Text>
      )}
    </Flex>
  );
}
