import moment from 'moment';

export const vacancyCardBackgroundColor = (key, isGray = false) => {
  if (isGray) {
    return '#D3D3D3';
  }

  return {
    fixed: '#CCA9DD', // 'var(--primary)',
    weekly: '#CCA9DD', // 'var(--primary)',
    biweekly: '#CCA9DD', // 'var(--primary)',
    cover: '#ABDEE6', // 'var(--secondary)',
    exception: '#FFC8A2', // 'var(--orange)',
    open: '#77DD77', // 'var(--green)',
    pendingDoctor: '#D3D3D3', // var(--pending-gray)',
  }[key];
};

export const statusText = key => {
  return {
    // Cards
    fixed: 'Plantão Fixo',
    weekly: 'Plantão Semanal',
    biweekly: 'Plantão Quinzenal',
    cover: 'Vaga Extra Solicitada Pela Unidade',
    exception: 'Plantão Cobertura',
    open: 'Vaga Aberta',
    pendingDoctor: 'Médico Pendente',
    // Status
    accepted: 'Plantão Checado',
    refused: 'Plantão Negado',
    pending: 'Plantão Aguardando Confirmação',
    expired: 'Plantão Não Coberto',
    checkin: 'Check-in Realizado',
    checkout: 'Check-out Realizado',
    pendingCheckin: 'Check-in Pendente',
    pendingCheckout: 'Check-out Pendente',
    observation: 'Observação',
  }[key];
};

export const getStatusCheckin = vacancy => {
  const { date, status, checkin, checkout, startHour, endHour } = vacancy;

  let key = 'none';

  const isExpired =
    moment(new Date(date.replaceAll('-', '/'))).diff(
      moment(new Date()),
      'days'
    ) < 0;

  if (isExpired) {
    key = 'expired';
  }

  const dateNow = new Date();

  const _startHour = new Date(startHour);
  const checkinDiff = dateNow - _startHour;

  if (status === 'accepted' && !isExpired) {
    const checkinAllowed = checkinDiff > 0;
    // const checkoutAllowed = checkoutDiff > 0;
    if (checkinAllowed && !checkin) key = 'pendingCheckin';
    if (checkin && !checkout) key = 'pendingCheckout';
  }

  // if (checkin === true && checkout === false && !isExpired) key = 'checkin';
  if (checkin && checkout === true) key = 'checkout';
  return key;
};

// Generate keys for vacancies status because our backend is lazy
export default vacancy => {
  const { status } = vacancy;

  let key = status;

  return key;
};
