import React from 'react';
import { Route } from 'react-router-dom';

import history from '~/services/history';
import { useAuth } from '~/hooks/AuthContext';
import { getParam } from '~/utils/url';

import DoctorLayout from '~/pages/_layouts/doctor';

export default function DoctorRouteWrapper({ component: Component, ...rest }) {
  const { user } = useAuth();
  const { hospitalId } = getParam('hospitalId');

  if (!user) {
    history.push('/');
    return window.location.reload();
  }

  if (user.type !== 'doctor') {
    if (!hospitalId) {
      history.push(`/company/${user.homepage.url}`);
      return window.location.reload();
    }

    history.push(`/hospital/dashboard/?hospitalId=${hospitalId}`);
    return window.location.reload();
  }

  return (
    <Route
      {...rest}
      render={props => (
        <DoctorLayout>
          <Component {...props} />
        </DoctorLayout>
      )}
    />
  );
}
