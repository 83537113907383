import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import {
  Container,
  Content,
  Form,
  NameInput,
  Title,
  SectionBtn,
  Footer,
  SectionUteis,
  TitleUteis,
  SectionList,
  ListAll,
  ListItem,
  SectionCopyright,
  TitleCopyright,
  TitleSociais,
  SectionSociais,
  FooterMobile,
  DivCenter,
} from './styles';

import api from '~/services/api';
import history from '~/services/history';

import Bg from '~/assets/home/doctors-screen.png';

import InputPrimary from '~/components/Site/Inputs/InputPrimary';
import BtnSend from '~/components/Site/Buttons/BtnSecondary';

export default function ForgotPassword() {
  const [email, setEmail] = useState();
  const [status, setStatus] = useState(true);

  useEffect(() => {
    async function checkIdAndKey() {
      const params = window.location.pathname.split('/');

      try {
        const userId = params[params.length - 2];
        const userKey = params[params.length - 1];

        const url = `/confirm-email/${userId}/${userKey}`;

        const response = await api.patch(url);

        setStatus(true);
        await toast.success('E-mail Confirmado com Sucesso!!!');
        history.push('/login');
      } catch (error) {
        setStatus(false);
      }
    }
    checkIdAndKey();
  }, []);

  const handleEmail = e => setEmail(e.target.value);

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      await api.post('/confirm-email', { email });
      toast.success('E-mail de confirmação enviado com sucesso!');
      history.push('/login');
    } catch (e) {
      return toast.error(
        'Falha ao enviar e-mail de confirmação! Tente novamente.'
      );
    }
  };

  return (
    <Container>
      {status ? (
        <Content bg={Bg}>
          <DivCenter>
            <Title>E-mail Confirmado com sucesso</Title>
          </DivCenter>
        </Content>
      ) : (
        <Content bg={Bg}>
          <Form onSubmit={handleSubmit}>
            <Title>Código de Verificação Inválido</Title>
            <NameInput>EMAIL</NameInput>
            <InputPrimary
              name="email"
              type="email"
              placeholder="EX: entrar@plantao.com.br"
              value={email}
              onChange={handleEmail}
            />
            <SectionBtn>
              <BtnSend
                title="Reenviar e-mail de Confirmação"
                type="submit"
                styles={{ width: '100%' }}
              />
            </SectionBtn>
          </Form>
        </Content>
      )}
      <Footer>
        <SectionUteis>
          <SectionList>
            <TitleUteis>Links Úteis</TitleUteis>
            <ListAll>
              <ListItem>Home</ListItem>
              <ListItem>Contato</ListItem>
              <ListItem>Produto</ListItem>
              <ListItem>Fale Conosco</ListItem>
            </ListAll>
          </SectionList>
          <SectionCopyright>
            <TitleCopyright>
              2020 - Meu Plantão - Todos os direitos reservados
            </TitleCopyright>
          </SectionCopyright>
          <SectionSociais>
            <a
              href="https://www.facebook.com/MeuPlantao"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a
              href="https://www.instagram.com/meuplantao/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a href="#" target="_blank" rel="noreferrer">
              <FaWhatsapp size={30} color="var(--background-alt)" />
            </a>
          </SectionSociais>
        </SectionUteis>
      </Footer>
      <FooterMobile>
        <TitleSociais>Siga-nos nas redes sociais</TitleSociais>
        <SectionSociais>
          <a
            href="https://www.facebook.com/MeuPlantao"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook
              size={30}
              color="var(--background-alt)"
              style={{ marginRight: 40 }}
            />
          </a>
          <a
            href="https://www.instagram.com/meuplantao/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              size={30}
              color="var(--background-alt)"
              style={{ marginRight: 40 }}
            />
          </a>
          <a href="#" target="_blank" rel="noreferrer">
            <FaWhatsapp size={30} color="var(--background-alt)" />
          </a>
        </SectionSociais>
      </FooterMobile>
    </Container>
  );
}
