import React, { useRef, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

import { Button } from '~/components/ChakraComponents/';

import { InputLabel, InputSearch } from './styles';

export default function FindDoctorInput({ setCrm, setName }) {
  const crmInputRef = useRef('');
  const nameInputRef = useRef('');

  const updateState = useCallback(() => {
    setCrm(crmInputRef.current.value);
    setName(nameInputRef.current.value);
  }, []);

  const onKeyPressEnter = useCallback(e => {
    if (e.key === 'Enter') updateState();
  }, []);

  const onSearchButtonClick = useCallback(() => {
    updateState();
  }, []);

  return (
    <>
      <Flex align="flex-end">
        <Flex flexDir="column">
          <InputLabel>Nome</InputLabel>
          <InputSearch
            autoFocus
            placeholder="Ex: Sérgio Guimarães"
            ref={nameInputRef}
            onKeyPress={onKeyPressEnter}
          />
        </Flex>
      </Flex>
      <Flex ml="10px" align="flex-end">
        <Flex flexDir="column">
          <InputLabel>Crm</InputLabel>
          <InputSearch
            autoFocus
            placeholder="Ex: 123457"
            ref={crmInputRef}
            onKeyPress={onKeyPressEnter}
          />
        </Flex>
        <Button
          width="auto"
          height="40px"
          margin="0 0 0 10px"
          icon2={<FaSearch size={24} />}
          onClick={onSearchButtonClick}
        />
      </Flex>
    </>
  );
}
