import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AiOutlinePlus, AiFillEdit } from 'react-icons/ai';
import { Flex, HStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { IMultiSelect } from '~/components/UI/Inputs';
import { Modal, ModalContainer } from '~/components/UI/Modal';
import IsAllowed from '~/components/UI/IsAllowed';
import { Button, Divider, Spinner } from '~/components/ChakraComponents';

import CreateConfigSchedule from '../CreateConfigSchedule';
import EditConfigSchedule from '../EditConfigSchedule';

export default function ConfigScheduleModal({
  closeModal,
  configSelected,
  setConfigSelected,
  editable = false,
}) {
  const [config, setConfig] = useState([]);
  const [configId, setConfigId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(editable);

  const createConfigScheduleModalRef = useRef(null);
  const editConfigScheduleModalRef = useRef(null);

  const fetchData = useCallback(() => {
    setLoading(true);
    api.get(`/companies/config/schedule`).then(res => {
      setConfig(res.data.data);
      setLoading(false);
      if (configSelected) {
        setConfigId(
          res.data.data.filter(oneConfig => oneConfig.id === configSelected)
        );
      }
    });
  }, [configSelected]);

  const setIdConfigSchedule = () => {
    toast.success('Configurações adicionadas com sucesso');
    setConfigSelected(configId.map(configs => configs.id).toString());
    closeModal();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ModalContainer w="400px" overflow={false}>
      {loading ? (
        <Spinner />
      ) : (
        <HStack align="center">
          <IMultiSelect
            label="Nome da Configuração"
            options={config}
            singleSelect
            selectedValues={configId}
            id="config"
            onSelect={a => {
              setConfigId(a);
              setEdit(true);
            }}
          />
          <IsAllowed id={27}>
            <Button
              width="40px"
              height="40px"
              icon={<AiOutlinePlus size={24} />}
              onClick={() => {
                createConfigScheduleModalRef.current.openModal();
              }}
            />
          </IsAllowed>
          <IsAllowed id={28}>
            {edit && (
              <Button
                width="40px"
                height="40px"
                icon={<AiFillEdit size={24} />}
                onClick={() => {
                  editConfigScheduleModalRef.current.openModal();
                }}
              />
            )}
          </IsAllowed>
        </HStack>
      )}
      <Divider />
      <Flex justify="space-between">
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <Button
          title="confirmar"
          variant="success"
          onClick={setIdConfigSchedule}
        />
      </Flex>
      <Modal
        title="Criar Aprovação Automática"
        ref={createConfigScheduleModalRef}
        component={CreateConfigSchedule}
        setConfigId={setConfigId}
        editable={setEdit}
        fetchDataMain={fetchData}
      />
      <Modal
        title="Editar Aprovação Automática"
        ref={editConfigScheduleModalRef}
        component={EditConfigSchedule}
        configId={configId.map(configs => configs.id).toString()}
        fetchDataMain={fetchData}
        setConfigId={setConfigId}
      />
    </ModalContainer>
  );
}
