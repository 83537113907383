import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { renderTimeSvg } from '~/pages/hospital/VacanciesV2/utils';

import Options from './Options';

export default function WeekSectorCard({ schedule }) {
  return (
    <Flex
      position="relative"
      mb="12px"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
      borderRadius="4px"
      boxShadow="var(--shadow)"
      borderTop="7px"
      borderTopStyle="solid"
      borderTopColor="var(--primary)"
      wordBreak="break-all"
    >
      <Flex
        mb={1}
        flexDir="column"
        textAlign="center"
        textTransform="uppercase"
        color="var(--primary)"
        fontWeight="bold"
        fontSize="12px"
      >
        <Options schedule={schedule} />
        <Text>{schedule.contract.sector}</Text>
        <Text>
          {`${schedule.contract.start.slice(
            0,
            -3
          )} - ${schedule.contract.end.slice(0, -3)}`}
        </Text>
      </Flex>
      {renderTimeSvg(schedule.contract.start)}
    </Flex>
  );
}
