import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import history from '~/services/history';
import { getParam } from '~/utils/url';

export default function NavItem({ closeDrawer, href, text, icon: Icon }) {
  const navigate = () => {
    const { hospitalId } = getParam();

    history.push(`${href}?hospitalId=${hospitalId}`);
    closeDrawer();
  };

  return (
    <Flex
      onClick={navigate}
      w="100%"
      py={4}
      px={2}
      alignItems="center"
      fontSize="md"
      borderRadius="md"
      color={
        window.location.pathname === href ? 'var(--primary)' : 'var(--disabled)'
      }
      backgroundColor={
        window.location.pathname === href ? 'gray.100' : 'transparent'
      }
    >
      <Icon size={20} />
      <Text fontSize="md" fontWeight="bold" ml={4}>
        {text}
      </Text>
    </Flex>
  );
}
