import React, { useState, useRef, useCallback, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import history from '~/services/history';
import { getParam } from '~/utils/url';

import Calendar from './components/Calendar';
import MenuLeft from './components/MenuLeft';

import ChooseOptionModal from '../../ChooseOption';

import PageLayout from '~/pages/_layouts/contractorhospital/PageLayout';

import { Modal } from '~/components/UI/Modal';
import {
  DesktopContainer,
  GroupsPage as SchedulesGroupPageMobile,
} from '~/components/UI/Sections/MobileVacancyAgenda';
import { DesktopVacancyNav, DesktopAgenda } from '~/components/UI/Sections';

export default function SpecialtiesPage() {
  const { hospitalId } = getParam('hospitalId');
  const { specialtyId, scheduleType, vacancyDate } = useParams();

  const [loading, setLoading] = useState(true);
  const [clickedVacancy, setClickedVacancy] = useState();

  const chooseOptionModalRef = useRef(null);

  const openChooseOption = useCallback(() => {
    chooseOptionModalRef.current.openModal();
  }, []);

  useEffect(() => {
    setLoading(true);
    localStorage.setItem('specialtyId', specialtyId);
  }, [specialtyId]);

  useEffect(() => {
    if (loading) setLoading(false);
  }, [loading]);

  const calendarValue = () => {
    const date = new Date(vacancyDate);

    date.setDate(date.getDate() + 2);

    return date;
  };

  const calendarOnChange = date => {
    const formattedDate = moment(date)
      .add(-1, 'days')
      .format('YYYY-MM-DD');

    const url = `/contractorhospital/vacancies/specialty/${specialtyId}/${scheduleType}/${formattedDate}/?hospitalId=${hospitalId}`;

    history.push(url);
  };

  const navigate = type => {
    const url = `/contractorhospital/vacancies/specialty/${specialtyId}/${type}/${vacancyDate}/?hospitalId=${hospitalId}`;

    history.push(url);
  };

  const cardOnClick = vacancy => {
    setClickedVacancy(vacancy);

    return openChooseOption();
  };

  return (
    <>
      {!loading && (
        <SchedulesGroupPageMobile
          calendar={Calendar}
          baseUrl={`/contractors/myhospitals/${hospitalId}/specialties/${specialtyId}/vacancies`}
        />
      )}
      <DesktopContainer>
        <PageLayout disableDefaultButtons userInfoButtons={<MenuLeft />}>
          <DesktopVacancyNav
            navigate={navigate}
            calendarValue={calendarValue()}
            calendarOnChange={calendarOnChange}
          />
          {!loading && (
            <DesktopAgenda
              baseUrl={`/contractors/myhospitals/${hospitalId}/specialties/${specialtyId}/vacancies`}
              baseUpdateUrl={`/contractors/myhospitals/${hospitalId}/specialties/${specialtyId}/lastupdate`}
              cardOnClick={cardOnClick}
            />
          )}
          <Modal
            title="Plantão"
            ref={chooseOptionModalRef}
            component={ChooseOptionModal}
            vacancy={clickedVacancy}
          />
        </PageLayout>
      </DesktopContainer>
    </>
  );
}
