import React, { useCallback } from 'react';
import { Tooltip } from '@chakra-ui/react';

import { statusText, getStatusCheckin } from '~/utils/genStatus';

import {
  IconContainer,
  CheckinSvg,
  CheckoutSvg,
  ExpiredSvg,
  PendingCheckinSvg,
  PendingCheckoutSvg,
} from './styles';

export default function IconStatusCheckin({ vacancy, isMonth = false }) {
  const renderIcon = useCallback(
    (statusColor, Component) => (
      <Tooltip hasArrow label={statusText(getStatusCheckin(vacancy))} bg="gray.600">
        <span>
          <IconContainer statusColor={statusColor} isMonth={isMonth}>
            <Component />
          </IconContainer>
        </span>
      </Tooltip>
    ),
    []
  );

  const iconDictionary = useCallback(() => {
    return {
      none: <></>,
      expired: renderIcon('var(--red)', ExpiredSvg),
      checkin: renderIcon('var(--secondary)', CheckinSvg),
      checkout: renderIcon('var(--green)', CheckoutSvg),
      pendingCheckin: renderIcon('var(--yellow)', PendingCheckinSvg),
      pendingCheckout: renderIcon('var(--yellow)', PendingCheckoutSvg),
    }[getStatusCheckin(vacancy)];
  }, [vacancy]);

  return <>{iconDictionary(vacancy)}</>;
}
