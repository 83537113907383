import React from 'react';
import moment from 'moment';

import { Div } from '~/components/Kiwistrap/Page';

import {
  Container,
  Text,
  Logo,
  SvgContainer,
  RefusedSvg,
  PendingSvg,
  ExpiredSvg,
  ConfirmedSvg,
  CheckinSvg,
  CheckoutSvg,
} from './styles';

export default function VacancyCard({ vacancy, onClick }) {
  const renderBoxColor = () =>
    ({
      weekly: 'var(--primary)',
      biweekly: 'var(--primary)',
      exception: 'var(--orange)',
      cover: 'var(--secondary)',
    }[vacancy.type]);

  const renderStatus = (statusColor, Component) => (
    <SvgContainer statusColor={statusColor}>
      <Component />
    </SvgContainer>
  );

  const statusFactory = () => {
    const { date, status, checkin, checkout } = vacancy;

    const isExpired =
      moment(new Date(date)).diff(moment(new Date()), 'days') < 0;

    let key = status;

    if (['pending', 'accepted'].includes(status) && isExpired) key = 'expired';
    if (checkin === true && checkout === false) key = 'checkin';
    if (checkout === true) key = 'checkout';

    return {
      refused: renderStatus('var(--red)', RefusedSvg),
      pending: renderStatus('var(--yellow)', PendingSvg),
      expired: renderStatus('var(--red)', ExpiredSvg),
      accepted: renderStatus('var(--secondary)', ConfirmedSvg),
      checkin: renderStatus('var(--secondary)', CheckinSvg),
      checkout: renderStatus('var(--green)', CheckoutSvg),
    }[key];
  };

  return (
    <Container boxColor={renderBoxColor()} onClick={onClick}>
      <Logo src={vacancy.schedule.contract.hospital.avatar} />
      <Div>
        <Text>
          {`${vacancy.schedule.contract.hospital.name} (${vacancy.schedule.contract.specialty.name})`}
        </Text>
        <Text>
          {`${moment(new Date(vacancy.startHour)).format('HH:mm')}h às ${moment(
            new Date(vacancy.endHour)
          ).format('HH:mm')}h`}
        </Text>
      </Div>
      {statusFactory()}
    </Container>
  );
}
