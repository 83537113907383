import React from 'react';

import { FaDesktop, FaUser, FaCalendarAlt } from 'react-icons/fa';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Text,
} from '@chakra-ui/react';

import leaveHospital from '~/utils/leaveHospital';

import NavItem from './NavItem';

export default function DrawerMenu({ isOpen, onClose, drawerButtonRef }) {
  return (
    <Drawer
      placement="right"
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={drawerButtonRef}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <Flex w="100%" flexDir="column">
              <NavItem
                closeDrawer={onClose}
                href={`/contractorhospital/profile`}
                text="Perfil"
                icon={FaUser}
              />
              <NavItem
                closeDrawer={onClose}
                href={`/contractorhospital/dashboard`}
                text="Dashboard"
                icon={FaDesktop}
              />
              <NavItem
                closeDrawer={onClose}
                href={`/contractorhospital/vacancies`}
                text="Escalas"
                icon={FaCalendarAlt}
              />
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Flex
              onClick={leaveHospital}
              as="button"
              w="148px"
              h="48px"
              px={2}
              alignItems="center"
              justifyContent="center"
              backgroundColor="var(--primary)"
              borderRadius="lg"
              _hover={{ backgroundColor: 'var(--hover-primary)' }}
            >
              <Text
                color="white"
                fontSize="12px"
                fontWeight="bold"
                textAlign="center"
                textTransform="uppercase"
              >
                Sair do hospital
              </Text>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
