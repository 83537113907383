import React, { useState, useMemo } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { Flex, Text, Tooltip } from '@chakra-ui/react';

import { Card } from '~/components/ChakraComponents';
import { Label } from '~/components/UI/Text';

export default function PendingDoctorUrl({ hash, w = '350px' }) {
  const [clicked, setClicked] = useState(false);

  const url = useMemo(
    () => process.env.REACT_APP_HOME_BASE_URL + '/convite/' + hash,
    [hash]
  );

  const onClick = () => {
    if (clicked) return;

    setClicked(true);

    navigator.clipboard.writeText(url);

    setTimeout(() => setClicked(false), 1200);
  };

  if (!hash) return null;

  return (
    <Flex w="100%" justify="center">
      <Card w={w} mb={2} justify="space-between" align="center" boxShadow="sm">
        <Flex flexShrink="none" w={`calc(${w} - 50px)`} flexDir="column">
          <Label
            color={clicked ? 'var(--green)' : 'var(--primary)'}
            transition="all 150ms ease-in-out"
          >
            {clicked ? 'Link copiado!' : 'Convidar o médico pendente'}
          </Label>
          <Tooltip hasArrow label="Clique para copiar" bg="gray.600">
            <span>
              <Text
                cursor="pointer"
                isTruncated
                color="var(--secondary)"
                userSelect="none"
                _hover={{ textDecor: 'underline' }}
                onClick={onClick}
              >
                {url}
              </Text>
            </span>
          </Tooltip>
        </Flex>
        <Flex
          cursor="pointer"
          flexShrink="none"
          w="50px"
          h="100%"
          justify="center"
          align="center"
          fontSize="26px"
          color="var(--light-gray)"
          _hover={{ color: 'var(--secondary)' }}
        >
          <Tooltip hasArrow label="Clique para copiar" bg="gray.600">
            <span>
              <AiOutlineCopy onClick={onClick} />
            </span>
          </Tooltip>
        </Flex>
      </Card>
    </Flex>
  );
}
