import { toast } from 'react-toastify';

import api from '~/services/api';
import { getParam } from '~/utils/url';

const apiContractsGroup = (method, url, request) => {
  api[method](url, request).then(() => {
    setTimeout(() => window.location.reload(), 1200);
  });
};

const generateRequest = inputs => {
  const { hospitalId } = getParam('hospitalId');

  const { name, type, contractorId, groupId } = inputs;

  let method = 'post';
  let url = `/companies/myhospitals/${hospitalId}/contracts-groups`;

  if (type === 'edit') {
    method = 'patch';
    url += `/${groupId}`;
  }

  const request = { name };

  if (contractorId !== -1) {
    request.contractor = contractorId;
  }

  apiContractsGroup(method, url, request);
};

export const validateInputs = inputs => {
  if (inputs.name === '') {
    toast.error('Por favor escreva um nome.');
    return false;
  }

  return generateRequest(inputs);
};
