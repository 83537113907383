import React, { useState, useEffect, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { IText, IMultiSelect } from '~/components/UI/Inputs';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider, Spinner } from '~/components/ChakraComponents';

export default function TaxDeductionsModal({
  closeModal,
  configId,
  fetchDataMain,
  setConfigId,
}) {
  const [multi, setMulti] = useState({});
  const [inputs, setInputs] = useState({
    checkingLagTime: 0,
    checkoutEarlyTime: 0,
  });
  const [loading, setLoading] = useState(false);
  const [doctor, setDoctor] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState([]);

  const searchDoctor = (e = undefined) => {
    api
      .get(`/companies/mydoctors`, {
        params: {
          limit: 7,
          isActive: true,
          name: e,
        },
      })
      .then(res => {
        setDoctor(
          res.data.data.map(oneDoctor => {
            return {
              id: oneDoctor.companyDoctor.id,
              name: oneDoctor.name,
              crm: oneDoctor.doctor.crm,
              crmUf: oneDoctor.doctor.crmUf,
            };
          })
        );
      });
  };

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  const convertToTimeInput = t => {
    const millisecToHour = 3600 * 1000;
    const milisecToMin = 60 * 1000;
    const hours = Math.floor(t / millisecToHour);
    const minutes = Math.floor((t % millisecToHour) / milisecToMin);

    return `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
  };

  const fetchConfig = useCallback(() => {
    setLoading(true);
    api.get(`/companies/config/schedule/${configId}`).then(res => {
      setInputs({
        ...res.data,
        checkingLagTime: convertToTimeInput(res.data.checkingLagTime),
        checkoutEarlyTime: convertToTimeInput(res.data.checkoutEarlyTime),
      });

      setSelectedDoctor(res.data.companyDoctors);
      setMulti(res.data.companyDoctors);
      setLoading(false);
    });

    searchDoctor();
  }, [configId]);

  const deleteModal = useCallback(() => {
    setLoading(true);
    api.delete(`/companies/config/schedule/${configId}`).then(() => {
      setLoading(false);
      fetchDataMain();
      toast.success('Configuração foi deletada com sucesso!');
      closeModal();
    });
  }, [closeModal, configId, fetchDataMain]);

  const convertToMilliseconds = t => {
    const milisecToHour = 3600 * 1000;
    const milisecToMin = 60 * 1000;
    const [hour, minute] = t.split(':');
    const milliseconds = hour * milisecToHour + minute * milisecToMin;
    return milliseconds;
  };

  const submitEditConfig = () => {
    if (!inputs.name) {
      return toast.error('Por favor selecione um nome.');
    }
    setLoading(true);
    const checkingLagTime = convertToMilliseconds(inputs.checkingLagTime);
    const checkoutEarlyTime = convertToMilliseconds(inputs.checkoutEarlyTime);

    const request = {
      name: inputs.name,
      checkingLagTime,
      checkoutEarlyTime,
      companyDoctors: multi.map(oneDoctor => oneDoctor.id),
    };

    return api
      .patch(`/companies/config/schedule/${configId}`, request)
      .then(res => {
        toast.info('Configuração foi editada!');
        setLoading(false);
        setConfigId([res.data]);
        fetchDataMain();
        closeModal();
      });
  };

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  return (
    <ModalContainer w="500px" overflow={false}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Flex flexDirection="column">
            <IText
              label="Nome da configuração"
              placeholder="Ex: Configuração 1"
              name="name"
              onChange={handleChanges}
              value={inputs.name}
            />
            <Flex mt={5} mb={5}>
              <IText
                style={{ marginRight: 50 }}
                label="Checkin atrasado"
                placeholder="Ex: 01:10 h"
                name="checkingLagTime"
                useCleave
                options={{
                  time: true,
                  timePattern: ['h', 'm'],
                }}
                onChange={handleChanges}
                value={inputs.checkingLagTime}
              />
              <IText
                label="Checkout adiantado"
                placeholder="Ex: 00:20 h"
                name="checkoutEarlyTime"
                useCleave
                options={{
                  time: true,
                  timePattern: ['h', 'm'],
                }}
                onChange={handleChanges}
                value={inputs.checkoutEarlyTime}
              />
            </Flex>
            <IMultiSelect
              label="Médicos pré-aprovados"
              placeholder="Ex: João, Maria, Antonio"
              id="doctor"
              options={doctor}
              showCheckbox
              selectedValues={selectedDoctor}
              closeOnSelect={false}
              onSelect={a => {
                setMulti(a);
              }}
              onRemove={a => {
                setMulti(a);
              }}
              onSearch={searchDoctor}
            />
          </Flex>
          <Divider />
          <Flex justify="space-between">
            <Button title="cancelar" variant="secondary" onClick={closeModal} />
            <Button title="deletar" variant="danger" onClick={deleteModal} />
            <Button
              title="confirmar"
              variant="success"
              onClick={submitEditConfig}
            />
          </Flex>
        </>
      )}
    </ModalContainer>
  );
}
