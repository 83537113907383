import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

import Button from './Button';

export default function Alert({
  isOpen,
  onClose,
  onConfirm,
  header = 'Confirmar',
  body = 'Por favor confirme para continuar.',
  confirmTitle = 'confirmar',
  confirmVariant = 'success',
}) {
  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {header}
          </AlertDialogHeader>
          <AlertDialogBody>{body}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              title="cancelar"
              variant="secondary"
              ref={cancelRef}
              onClick={onClose}
            />
            <Button
              title={confirmTitle}
              variant={confirmVariant}
              onClick={onConfirm}
              margin="0 0 0 20px"
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
