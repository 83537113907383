import styled from 'styled-components';
import Cleave from 'cleave.js/react';

export const CleaveInput = styled(Cleave)`
  width: 100%;
  height: 40px;

  padding: 5px;

  border: 2px solid var(--black);
  border-radius: var(--br-sm);
`;

export const DefaultInput = styled.input`
  width: 100%;
  height: 40px;

  padding: 5px;

  border: 2px solid var(--black);
  border-radius: var(--br-sm);
`;
