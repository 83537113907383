import styled from 'styled-components';
import { AiOutlineDownload } from 'react-icons/ai';

export const MaButton = styled.a`
  width: 148px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: var(--br-sm);

  font-size: 12px;

  text-align: center;
  font-weight: bold;
  text-transform: uppercase;

  color: var(--background-alt);
  background-color: var(--orange);
  box-shadow: var(--shadow);
  &:hover {
    background-color: var(--hover-orange);
  }
`;

export const Letter = styled.span`
  cursor: pointer;

  margin: 5px 10px;

  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;

  text-decoration: ${props => (props.isChoosen ? 'underline' : 'none')};
  color: ${props => (props.isChoosen ? 'var(--secondary)' : 'var(--primary)')};

  &:hover {
    text-decoration: underline;
  }
`;

export const DocumentList = styled.div`
  width: 100%;
  background-color: var(--background-alt);
  box-shadow: var(--shadow);
  padding: 0 10px;
`;

export const ItemList = styled.div`
  width: 100%;

  padding: 8px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #e7e7e7;

  &:last-child {
    border-bottom: none;
  }

  span {
    font-size: 15px;
    color: var(--light-gray);
  }

  a:first-child {
    margin-right: 10px;

    font-weight: bold;
    text-transform: uppercase;

    color: var(--primary);

    &:hover {
      text-decoration: underline;

      color: var(--hover-primary);
    }
  }
`;

export const DownloadSvg = styled(AiOutlineDownload)`
  font-size: 38px;

  color: var(--primary);

  &:hover {
    color: var(--hover-primary);
  }
`;
