import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e4e4e4;
  border: 1px solid blue;
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  width: 700px;
  /* height: 500px; */
  border-radius: var(--br-sm);
  background-color: var(--background-alt);
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.2);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;
export const FormSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  /* flex-grow: 1;
  flex-direction: row;
  flex-flow: row nowrap; */
  /* border: 1px solid red; */
`;

export const Input = styled.input`
  height: 40px;
  border: 3px solid #e4e4e4;
  border-radius: var(--br-sm);
  margin: 5px 0 5px 0;
  padding: 5px;
`;

export const Title = styled.div`
  margin-top: 20px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  /* border: 1px solid red; */
`;

export const BtnCadastro = styled.button`
  width: 50%;
  height: 40px;
  background-color: #0079bf;
  border-radius: var(--br-sm);
  margin: 10px 5px 10px 5px;

  color: var(--background-alt);
  font-size: 15px !important;
  text-transform: uppercase;
`;

export const BtnVoltar = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 40px;
  border: 1px solid #0079bf;
  border-radius: var(--br-sm);
  margin: 10px 5px 10px 5px;
  background: transparent;

  color: #0079bf;
  font-size: 15px;
  text-transform: uppercase;
`;
