import React from 'react';
import { Flex } from '@chakra-ui/react';
import { FaListUl as InfoSvg } from 'react-icons/fa';

import Legends from './Legends';
import { Pop, IconContainer } from '~/components/UI/Functionality';

const DesktopLegend = () => {
  return <Legends />;
};

export default React.memo(DesktopLegend);
