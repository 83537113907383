import React, { useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { HStack, Flex } from '@chakra-ui/react';

import { Button } from '~/components/ChakraComponents/';

export const InputLabel = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  color: var(--primary);
`;

export const InputSearch = styled.input`
  width: 100%;
  height: 40px;

  padding: 5px;
  margin-top: 5px;

  border-radius: var(--br-sm);
  border: 2px solid var(--black);
`;

export const InputSelect = styled.select`
  width: 100%;
  height: 40px;

  padding: 5px;
  margin-top: 5px;

  border-radius: var(--br-sm);
  border: 2px solid var(--black);
`;

export default function SearchUserInChargeInput({ setName }) {
  const nameInputRef = useRef('');

  const updateState = useCallback(() => {
    setName(nameInputRef.current.value);
  }, []);

  const onKeyPressEnter = useCallback(e => {
    if (e.key === 'Enter') updateState();
  }, []);

  const onSearchButtonClick = useCallback(() => {
    updateState();
  }, []);

  return (
    <HStack align="flex-end">
      <Flex flexDir="column">
        <InputLabel>Nome</InputLabel>
        <InputSearch
          autoFocus
          placeholder="Ex: Fernando"
          ref={nameInputRef}
          onKeyPress={onKeyPressEnter}
        />
      </Flex>
      <Button
        width="auto"
        height="40px"
        margin="0 0 0 10px"
        icon2={<FaSearch size={24} />}
        onClick={onSearchButtonClick}
      />
    </HStack>
  );
}
