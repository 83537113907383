import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Flex, Text } from '@chakra-ui/react';
import { Container, Row, Col } from 'react-bootstrap';
import { AiOutlineSearch, AiOutlineDownload } from 'react-icons/ai';

import api from '~/services/api';
import convertBRL from '~/utils/convertBRL';

import { getToken } from '~/services/auth';

import PageLayout from '~/pages/_layouts/company/PageLayout';

import Pagination from '~/components/Dashboard/Pagination';

import { Button, Spinner } from '~/components/ChakraComponents/';
import { Form, Field, SelectAlt as Select } from '~/components/Kiwistrap/Form';

const limit = 50;

export default function DoctorSpending() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [total, setTotal] = useState(0);
  const [endDate, setEndDate] = useState('');
  const [hospital, setHospital] = useState('');
  const [schedules, setSchedules] = useState();
  const [hospitals, setHospitals] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const isoStartDate = startDate
    .split('/')
    .reverse()
    .join('-');

  const isoEndDate = endDate
    .split('/')
    .reverse()
    .join('-');

  const fetchHospitals = useCallback(() => {
    api.get('/companies/myhospitals?sort=name').then(response => {
      const { data } = response.data;

      const hospitalsArr = [{ id: 0, value: '', text: 'Todos os hospitais' }];

      data.forEach(({ id, name: text }) => {
        hospitalsArr.push({ id, value: id, text });
      });

      setHospitals([...hospitalsArr]);
      setLoadingPage(false);
    });
  }, []);

  const fetchData = useCallback(() => {
    const offset = (currentPage - 1) * limit;

    const url = `/companies/reports/costs/schedules?startDate=${isoStartDate}&endDate=${isoEndDate}&hospital=${hospital}&limit=${limit}&offset=${offset}`;

    api.get(url).then(response => {
      const { data, total } = response.data;

      data.sort((a, b) => {
        if (a.Hospital < b.Hospital) return -1;
        if (a.Hospital > b.Hospital) return 1;
        return 0;
      });

      setTotal(total);
      setSchedules(data);
      setLoadingInfo(false);
    });
  }, [startDate, endDate, hospital, currentPage]);

  useEffect(() => {
    fetchHospitals();
  }, []);

  useEffect(() => {
    if (!loadingInfo) return;

    fetchData();
  }, [loadingInfo]);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const downloadFile = async () => {
    if (!schedules || schedules.length <= 0) return;

    const token = getToken();

    const baseURL = process.env.REACT_APP_API_URL;

    const url = `${baseURL}/companies/reports/costs/schedules/downloads?startDate=${isoStartDate}&endDate=${isoEndDate}&token=${token}&hospital=${hospital}`;

    return window.open(url);
  };

  const formatHour = hour => {
    return format(new Date(hour), 'H:mm');
  };

  const onSubmit = data => {
    const { startDate, endDate, hospital } = data;

    if (startDate === '' || endDate === '') {
      return toast.error('Por favor preencha todas as datas ');
    }

    const isoEnd = endDate
      .split('/')
      .reverse()
      .join('-');

    const isoStart = startDate
      .split('/')
      .reverse()
      .join('-');

    const dayDiff = moment(isoStart).diff(isoEnd, 'days');

    if (dayDiff > 0) {
      return toast.error('Data final não pode ser menor que inicial.');
    }

    setEndDate(endDate);
    setHospital(hospital);
    setStartDate(startDate);

    setLoadingInfo(true);
  };

  return (
    <PageLayout loading={loadingPage}>
      <Container>
        <Button
          title="voltar"
          to="/company/spendcontrol"
          variant="secondary"
          height="40px"
          width="100px"
          margin="0 0 20px"
        />
        <Form onSubmit={onSubmit}>
          <Flex flexDir={['column', 'column', 'row']}>
            <Flex mr={['0', '0', '20px']}>
              <Flex w="120px" mr="20px" flexShrink="none">
                <Field
                  required
                  label="data inicial"
                  placeholder="Ex: 13/09/2020"
                  name="startDate"
                  useCleave
                  options={{
                    date: true,
                    delimiter: '/',
                    datePattern: ['d', 'm', 'Y'],
                  }}
                />
              </Flex>
              <Flex w="120px" flexShrink="none">
                <Field
                  required
                  label="data final"
                  placeholder="Ex: 13/10/2020"
                  name="endDate"
                  useCleave
                  options={{
                    date: true,
                    delimiter: '/',
                    datePattern: ['d', 'm', 'Y'],
                  }}
                />
              </Flex>
            </Flex>
            <Flex>
              <Select
                label="hospital"
                name="hospital"
                options={hospitals}
                style={{ width: '100%', maxWidth: '300px' }}
              />
              <Button
                type="submit"
                width="40px"
                height="40px"
                mt="24px"
                ml="10px"
                icon2={<AiOutlineSearch size={24} />}
              />
            </Flex>
          </Flex>
        </Form>
        {!loadingInfo ? (
          <>
            {schedules && schedules.length > 0 ? (
              <>
                <Flex mt="40px" mb="12px" justifyContent="flex-end">
                  <Button
                    title="exportar dados"
                    height="40px"
                    width="170px"
                    icon2={<AiOutlineDownload size={24} />}
                    onClick={downloadFile}
                  />
                </Flex>
                {schedules.map(schedule => (
                  <>
                    <Flex
                      p={3}
                      mt={1}
                      flexDir="column"
                      borderRadius="4px"
                      backgroundColor="white"
                    >
                      <Row>
                        <Col md>
                          <Text fontWeight="bold" color="var(--primary)">
                            {schedule.date
                              .split('-')
                              .reverse()
                              .join('/')}
                            {' - '}
                            <Text
                              as="span"
                              fontWeight="semibold"
                              color="var(--primary)"
                            >
                              {`${formatHour(
                                schedule.StartHour
                              )}h às ${formatHour(schedule.EndHour)}h`}
                            </Text>
                          </Text>
                          <Text
                            mt={2}
                            mb={1}
                            fontWeight="bold"
                            fontSize="sm"
                            textTransform="uppercase"
                            color="var(--secondary)"
                          >
                            {schedule.Hospital}
                          </Text>
                          <Text>
                            <Text as="span" fontWeight="semibold">
                              {schedule.Specialty}
                            </Text>
                            , {schedule.Name}, CRM: {schedule.crm}
                          </Text>
                        </Col>
                        <Col md>
                          <Flex
                            h="100%"
                            mt={1}
                            alignItems="center"
                            justifyContent="space-evenly"
                          >
                            <Flex flexDir="column">
                              <Text
                                fontWeight="bold"
                                textTransform="uppercase"
                                color="var(--secondary)"
                              >
                                Valor Bruto
                              </Text>
                              <Text>{convertBRL(schedule.VB)}</Text>
                            </Flex>
                            <Flex flexDir="column">
                              <Text
                                fontWeight="bold"
                                textTransform="uppercase"
                                color="var(--secondary)"
                              >
                                Valor Líquido
                              </Text>
                              <Text>{convertBRL(schedule.VL)}</Text>
                            </Flex>
                          </Flex>
                        </Col>
                      </Row>
                    </Flex>
                  </>
                ))}
                <Pagination l={limit} t={total} onClick={setCurrentPage} />
              </>
            ) : (
              <Text mt={3}>Não há dados para serem exibidos.</Text>
            )}
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </PageLayout>
  );
}
