import React from 'react';

import {
  Container,
  Title,
  Description,
  SectionTitle,
  SectionDescription,
  SectionBtn,
} from './styles';
import BtnSelect from '../../Buttons/BtnSecondary';

export default function CardPlans({ title, description, tbtn, to }) {
  return (
    <Container>
      <SectionTitle>
        <Title>{title}</Title>
      </SectionTitle>
      <SectionDescription>
        <Description>{description}</Description>
      </SectionDescription>
      <SectionBtn>
        <BtnSelect title={tbtn} to={to} styles={{ width: '200px' }} />
      </SectionBtn>
    </Container>
  );
}
