import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useBoolean, HStack, Text } from '@chakra-ui/react';

import { statusText } from '~/utils/genStatus';

import { Label } from '~/components/UI/Text';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider } from '~/components/ChakraComponents';
import { IDate, ICheck, IRadio, IColor } from '~/components/UI/Inputs';

import {
  defaultInputs,
  sHeight,
  sFontSize,
  bHeight,
  bFontSize,
  defaultStyles,
  defaultBgColors,
  VacancyCard,
} from './constants';

export default function Phase01({ onSuccess }) {
  const [isSmall, setIsSmall] = useBoolean();
  const [canAdvance, setCanAdvance] = useBoolean();
  const [inputs, setInputs] = useState(defaultInputs);
  const [styles, setStyles] = useState(defaultStyles);
  const [bgColors, setBgColors] = useState(defaultBgColors);

  useEffect(() => {
    if (inputs.startDate?.length === 10 && inputs.endDate?.length === 10) {
      setCanAdvance.on();
    }
  }, [inputs]);

  useEffect(() => {
    if (isSmall) {
      setStyles({ ...styles, height: sHeight, fontSize: sFontSize });
    } else {
      setStyles({ ...styles, height: bHeight, fontSize: bFontSize });
    }
  }, [isSmall]);

  const onSubmit = () => {
    if (!canAdvance) return;

    if (
      moment(new Date(inputs.startDate)).diff(
        new Date(inputs.endDate),
        'days'
      ) > 0
    ) {
      return toast.error('Data final não pode ser menor que inicial.');
    }

    const clone = { ...styles };

    clone.fontSize = clone.fontSize - 4;
    clone.borderRadius = clone.borderRadius - 2;

    delete clone.width;
    delete clone.padding;
    delete clone.color;
    delete clone.fontWeight;
    delete clone.flexDirection;

    if (isSmall) {
      clone.marginBottom = 1;
      clone.paddingHorizontal = 1;
    } else {
      clone.marginBottom = 4;
      clone.padding = 4;
    }

    return onSuccess({
      inputs: { ...inputs, isSmall },
      styles: clone,
      colors: bgColors,
    });
  };

  return (
    <ModalContainer w="360px" align="flex-start">
      <HStack w="100%">
        <IDate
          label="data inicial"
          initialDate={moment().format('YYYY-MM-DD')}
          onChange={startDate => {
            setInputs({ ...inputs, startDate });
          }}
        />
        <IDate
          label="data final"
          initialDate={moment()
            .add(1, 'month')
            .format('YYYY-MM-DD')}
          onChange={endDate => {
            setInputs({ ...inputs, endDate });
          }}
        />
      </HStack>
      <Label>Incluir no pdf</Label>
      {/* <ICheck
        label="Logo da Empresa"
        checked={inputs.companyLogo}
        onChange={() => {
          setInputs({ ...inputs, companyLogo: !inputs.companyLogo });
        }}
      /> */}
      <HStack>
        <ICheck
          label={statusText('fixed')}
          checked={inputs.fixed}
          onChange={() => {
            setInputs({ ...inputs, fixed: !inputs.fixed });
          }}
        />
        {inputs.fixed && (
          <IColor
            value={bgColors.fixed}
            onChange={e => setBgColors({ ...bgColors, fixed: e.target.value })}
          />
        )}
      </HStack>
      <HStack>
        <ICheck
          label={statusText('exception')}
          checked={inputs.exception}
          onChange={() => {
            setInputs({ ...inputs, exception: !inputs.exception });
          }}
        />
        {inputs.exception && (
          <IColor
            value={bgColors.exception}
            onChange={e =>
              setBgColors({ ...bgColors, exception: e.target.value })
            }
          />
        )}
      </HStack>
      <HStack>
        <ICheck
          label={statusText('cover')}
          checked={inputs.cover}
          onChange={() => {
            setInputs({ ...inputs, cover: !inputs.cover });
          }}
        />
        {inputs.cover && (
          <IColor
            value={bgColors.cover}
            onChange={e => setBgColors({ ...bgColors, cover: e.target.value })}
          />
        )}
      </HStack>
      <HStack>
        <ICheck
          label={statusText('pendingDoctor')}
          checked={inputs.pending}
          onChange={() => {
            setInputs({ ...inputs, pending: !inputs.pending });
          }}
        />
        {inputs.pending && (
          <IColor
            value={bgColors.pending}
            onChange={e =>
              setBgColors({ ...bgColors, pending: e.target.value })
            }
          />
        )}
      </HStack>
      <HStack>
        <ICheck
          label={statusText('open')}
          checked={inputs.open}
          onChange={() => {
            setInputs({ ...inputs, open: !inputs.open });
          }}
        />
        {inputs.open && (
          <IColor
            value={bgColors.open}
            onChange={e => setBgColors({ ...bgColors, open: e.target.value })}
          />
        )}
      </HStack>
      <HStack align="center">
        <ICheck
          label="Cor Única"
          checked={inputs.singleColor}
          onChange={() => {
            setInputs({ ...inputs, singleColor: !inputs.singleColor });
          }}
        />
        {inputs.singleColor && (
          <IColor
            value={bgColors.all}
            onChange={e => setBgColors({ ...bgColors, all: e.target.value })}
          />
        )}
      </HStack>
      <Label>Orientação</Label>
      <IRadio
        label="Retrato (Vertical)"
        checked={!inputs.isLandscape}
        onChange={() => {
          setInputs({ ...inputs, isLandscape: !inputs.isLandscape });
        }}
      />
      <IRadio
        label="Paisagem (Horizontal)"
        checked={inputs.isLandscape}
        onChange={() => {
          setInputs({ ...inputs, isLandscape: !inputs.isLandscape });
        }}
      />
      <Label>Tamanho</Label>
      <HStack justify="center">
        <IRadio label="Grande" checked={!isSmall} onChange={setIsSmall.off} />
        <IRadio label="Pequeno" checked={isSmall} onChange={setIsSmall.on} />
      </HStack>
      <HStack w="100%" h={bHeight} align="center" justify="center">
        {inputs.fixed && (
          <VacancyCard
            styles={styles}
            backgroundColor={inputs.singleColor ? bgColors.all : bgColors.fixed}
          >
            <Text fontSize={styles.fontSize}>
              {isSmall ? 'Doutor B.' : 'Doutor Busca'}
            </Text>
            <Text fontSize={styles.fontSize - 2}>123457-RJ</Text>
          </VacancyCard>
        )}
        {inputs.exception && (
          <VacancyCard
            styles={styles}
            backgroundColor={
              inputs.singleColor ? bgColors.all : bgColors.exception
            }
          >
            <Text fontSize={styles.fontSize}>
              {isSmall ? 'Doutor B.' : 'Doutor Busca'}
            </Text>
            <Text fontSize={styles.fontSize - 2}>123457-SP</Text>
          </VacancyCard>
        )}
        {inputs.cover && (
          <VacancyCard
            styles={styles}
            backgroundColor={inputs.singleColor ? bgColors.all : bgColors.cover}
          >
            <Text fontSize={styles.fontSize}>
              {isSmall ? 'Doutor B.' : 'Doutor Busca'}
            </Text>
            <Text fontSize={styles.fontSize - 2}>123457-BH</Text>
          </VacancyCard>
        )}
      </HStack>
      <HStack w="100%" h={bHeight} align="center" justify="center">
        {inputs.pending && (
          <VacancyCard
            styles={styles}
            backgroundColor={
              inputs.singleColor ? bgColors.all : bgColors.pending
            }
          >
            <Text fontSize={styles.fontSize}>
              {isSmall ? 'Doutor B.' : 'Doutor Busca'}
            </Text>
            <Text fontSize={styles.fontSize - 2}>123457-AC</Text>
          </VacancyCard>
        )}
        {inputs.open && (
          <VacancyCard
            styles={styles}
            backgroundColor={inputs.singleColor ? bgColors.all : bgColors.open}
          >
            <Text fontSize={styles.fontSize}>Vaga Aberta</Text>
          </VacancyCard>
        )}
      </HStack>
      <Divider />
      <Button
        disabled={!canAdvance}
        title="avançar"
        width="100%"
        onClick={onSubmit}
      />
    </ModalContainer>
  );
}
