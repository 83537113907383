import React from 'react';
import { Flex } from '@chakra-ui/react';

export default function IconContainer({
  onClick = () => {},
  cursor = 'auto',
  fontSize = '1.5rem',
  fontWeight = '500',
  children,
}) {
  return (
    <Flex
      onClick={onClick}
      w="40px"
      h="40px"
      align="center"
      justify="center"
      textAlign="center"
      color="var(--background-alt)"
      bgColor="var(--primary)"
      borderRadius="lg"
      cursor={cursor}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {children}
    </Flex>
  );
}
