import React from 'react';
import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';

const RegisterHospitalSvg = styled(FaPlus)`
  font-size: 100px;
  margin-bottom: 24px;
`;

export default function RegisterHospitalCard({ onClick }) {
  return (
    <Flex w="100%" h="100%" justifyContent="center">
      <Flex
        onClick={onClick}
        cursor="pointer"
        w="200px"
        h="100%"
        minH="200px"
        p={1}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        boxShadow="base"
        backgroundColor="white"
        fontSize="18px"
        fontWeight="600"
        color="var(--chakra-colors-gray-600)"
        borderRadius="base"
        _hover={{
          color: 'green.400',
          textDecoration: 'underline',
          boxShadow: 'md',
        }}
      >
        <RegisterHospitalSvg /> Cadastrar Hospital
      </Flex>
    </Flex>
  );
}
