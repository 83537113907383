import React from 'react';
import { Flex } from '@chakra-ui/react';

export default function Card({
  p = 2,
  borderRadius = 'md',
  backgroundColor = 'var(--background-alt)',
  children,
  ...rest
}) {
  return (
    <Flex
      p={p}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      {...rest}
    >
      {children}
    </Flex>
  );
}
