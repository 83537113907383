import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 14px 0;

  display: flex;
  align-items: center;

  border-bottom: 1px solid var(--dim-gray);

  svg {
    margin-right: 20px;

    font-size: 50px;

    color: var(--primary);
  }

  &:last-child {
    border-bottom: none;
  }
`;
