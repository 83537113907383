import React, { useRef, useCallback } from 'react';
import {
  FaUserAlt as UserSvg,
  FaTimesCircle as RemoveUserSvg,
} from 'react-icons/fa';
import { HStack, Flex, Text } from '@chakra-ui/react';

import phoneMask from '~/utils/phoneMask';
import getFirstLastName from '~/utils/getFirstLastName';

import ChooseUserInChargeModal from './ChooseUserInChargeModal';

import { Label } from '~/components/UI/Text';
import { Modal } from '~/components/UI/Modal';
import { Avatar, LinkButton } from '~/components/ChakraComponents';

export default function ChooseUserInCharge({ userInCharge, setUserInCharge }) {
  const chooseUserInChargeModalRef = useRef(null);

  const openUserInCharge = useCallback(() => {
    chooseUserInChargeModalRef.current.openModal();
  }, []);

  return (
    <>
      <Label>Usuário Responsável</Label>
      {userInCharge?.name ? (
        <Flex align="center">
          <Avatar
            w="56px"
            h="56px"
            src={userInCharge.avatar}
            name={getFirstLastName(userInCharge.name)}
          />
          <Flex ml={2} flexDir="column">
            <Text color="gray.600" fontWeight="semibold">
              {userInCharge.name}
            </Text>
            <Text>{userInCharge.email}</Text>
            <Text>
              {phoneMask(userInCharge.phone1)} {phoneMask(userInCharge.phone2)}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Text color="gray.400" fontWeight="semibold">
          Nenhum escolhido
        </Text>
      )}
      <HStack>
        <LinkButton
          title="escolher usuário"
          onClick={openUserInCharge}
          svg={UserSvg}
        />
        {userInCharge && (
          <LinkButton
            title="remover usuário"
            color="var(--red)"
            onClick={() => setUserInCharge(null)}
            svg={RemoveUserSvg}
          />
        )}
      </HStack>
      <Modal
        title="Usuário Responsável"
        ref={chooseUserInChargeModalRef}
        component={ChooseUserInChargeModal}
        userInCharge={userInCharge}
        setUserInCharge={setUserInCharge}
      />
    </>
  );
}
