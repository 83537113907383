import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import PageLayout from '~/pages/_layouts/doctor/PageLayout';

import { Text } from '~/components/Kiwistrap/Page';
import { Button } from '~/components/ChakraComponents/';
import { SearchSvg } from '~/components/Kiwistrap/Svg';
import { IText, ISpec, IDate } from '~/components/UI/Inputs';

import VacancyList from './components/VacancyList';

export default function FindVacancy() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingInfo, setLoadinginfo] = useState(false);
  const [vacancies, setVacancies] = useState();
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    setLoadingPage(false);
  }, []);

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSpecialtyInput = id => {
    setInputs({ ...inputs, specialty: id });
  };

  const urlFactory = () => {
    const { startDate, endDate, uf, city, address, specialty } = inputs;

    const startDateFn = () => (startDate ? `?startDate=${startDate}` : '');
    const endDateFn = () => (endDate ? `&endDate=${endDate}` : '');
    const specialtyFn = () => (specialty ? `&specialty=${specialty}` : '');
    const ufFn = () => (uf ? `&uf=${uf}` : '');
    const cityFn = () => (city ? `&city=${city}` : '');
    const addressFn = () => (address ? `&address=${address}` : '');

    return `/doctors/vacancies/v2${startDateFn()}${endDateFn()}${specialtyFn()}${ufFn()}${cityFn()}${addressFn()}&limit=10`;
  };

  const findOrderlies = async () => {
    if (!inputs.startDate || inputs.startDate.length < 10) {
      return toast.error('Por favor insira uma data inicial válida.');
    }

    if (!inputs.endDate || inputs.endDate.length < 10) {
      return toast.error('Por favor insira uma data final válida.');
    }

    setLoadinginfo(true);

    try {
      const {
        data: { data },
      } = await api.get(urlFactory());

      const filteredVacancies = data.filter(vac => vac.candidate === false);

      filteredVacancies.sort((a, b) => {
        return moment(new Date(b.startHour)).diff(
          moment(new Date(a.startHour))
        );
      });

      setVacancies(filteredVacancies);
    } catch (e) {
      internalServerError();
    }

    return setLoadinginfo(false);
  };

  return (
    <PageLayout title="Buscar Plantão" loading={loadingPage}>
      <Flex>
        <Flex flexDir="column">
          <IDate
            label="data inicial"
            initialDate={moment(new Date())
              .startOf('month')
              .format('YYYY-MM-DD')}
            onChange={startDate => {
              setInputs({ ...inputs, startDate });
            }}
          />
          <IDate
            label="data final"
            initialDate={moment(new Date())
              .endOf('month')
              .format('YYYY-MM-DD')}
            onChange={endDate => {
              setInputs({ ...inputs, endDate });
            }}
          />
        </Flex>
        <Flex flexDir="column" margin="0 20px">
          <ISpec required setSpecialtyId={handleSpecialtyInput} />
          <IText
            required
            label="uf"
            placeholder="Ex: UF"
            name="uf"
            maxLength="2"
            value={inputs.uf}
            onChange={handleChanges}
          />
        </Flex>
        <Flex flexDir="column">
          <IText
            required
            label="cidade"
            placeholder="Ex: Vista"
            name="city"
            value={inputs.city}
            onChange={handleChanges}
          />
          <IText
            required
            label="bairro"
            placeholder="Ex: Caranã"
            name="address"
            value={inputs.address}
            onChange={handleChanges}
          />
        </Flex>
        <Flex flexDir="column" justifyContent="center" margin="0 0 0 20px">
          <Button
            title={loadingInfo ? 'carregando...' : 'buscar'}
            icon2={!loadingInfo && <SearchSvg />}
            onClick={findOrderlies}
          />
        </Flex>
      </Flex>
      {vacancies && (
        <Flex flexDir="column">
          {vacancies.length === 0 ? (
            <Text>
              Nenhuma vaga foi encontrada. Verifique os campos de busca e tente
              novamente.
            </Text>
          ) : (
            <VacancyList vacancies={vacancies} reloadSearch={findOrderlies} />
          )}
        </Flex>
      )}
    </PageLayout>
  );
}
