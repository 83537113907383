import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import IText from './IText';

export default function IPerc({ ...rest }) {
  return (
    <Flex w="100%" pos="relative">
      <Text pos="absolute" right="10px" bottom="10px" fontSize="18px">
        %
      </Text>
      <IText
        useCleave
        options={{ blocks: [2, 2], delimiters: ['.'] }}
        {...rest}
      />
    </Flex>
  );
}
