import styled from 'styled-components';
import Cleave from 'cleave.js/react';

export const Container = styled.input`
  display: flex;
  width: 100%;
  height: 40px;
  border: 2px solid var(--black);
  border-radius: var(--br-sm);
  margin: 5px 0 5px 0;
  padding: 5px 15px;
`;

export const CleaveContainer = styled(Cleave)`
  display: flex;
  width: 100%;
  height: 40px;
  border: 2px solid var(--black);
  border-radius: var(--br-sm);
  margin: 5px 0 5px 0;
  padding: 5px 15px;
`;
