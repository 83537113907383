import React from 'react';
import {
  FaCalendarDay,
  FaHospitalSymbol,
  FaStreetView,
  FaMoneyCheckAlt,
} from 'react-icons/fa';

import InfoSection, {
  turnText,
  hospitalText,
  addressText,
  valueText,
} from './InfoSection';

export default function ModalInfo({ vacancy }) {
  return (
    <>
      <InfoSection
        icon={FaCalendarDay}
        title="Turno"
        info={turnText(vacancy)}
      />
      <InfoSection
        icon={FaHospitalSymbol}
        title="Hospital"
        info={hospitalText(vacancy)}
      />
      <InfoSection
        icon={FaStreetView}
        title="Endereço"
        info={addressText(vacancy)}
      />
      <InfoSection
        icon={FaMoneyCheckAlt}
        title="Recebimento"
        info={valueText(vacancy)}
      />
    </>
  );
}
