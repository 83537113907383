import React, { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FaCog } from 'react-icons/fa';
import { Flex, HStack } from '@chakra-ui/react';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import IsAllowed from '~/components/UI/IsAllowed';
import ConfigScheduleModal from '~/components/Dashboard/Modals/MeusPlantoes/ConfigScheduleModal';
import { Label } from '~/components/UI/Text/';
import { Button, Divider } from '~/components/ChakraComponents';
import { ModalContainer, Modal } from '~/components/UI/Modal';
import { IText, IRadio, IArea, ICheck } from '~/components/UI/Inputs/';

export default function EditScheduleModal({ scheduleId, closeModal }) {
  const { hospitalId } = getParam('hospitalId');

  const [loading, setLoading] = useState(true);

  const [daysToAlert, setDaysToAlert] = useState();

  const [publicDescription, setPublicDescription] = useState();
  const [privateDescription, setPrivateDescription] = useState();

  const [availability, setAvailability] = useState();

  const [isPhotoCheckin, setIsPhotoCheckin] = useState(true);
  const [isPhotoCheckout, setIsPhotoCheckout] = useState(true);

  const [isCheckinLocalization, setIsCheckinLocalization] = useState(true);
  const [isCheckoutLocalization, setIsCheckoutLocalization] = useState(true);
  const [doctorChangeFlow, setDoctorChangeFlow] = useState(true);

  const [isAutoCheckout, setIsAutoCheckout] = useState(true);

  const [configAutoApprove, setConfigAutoApprove] = useState([]);

  const configScheduleModalRef = useRef(null);

  const openConfigSchedule = useCallback(() => {
    configScheduleModalRef.current.openModal();
  }, []);

  useEffect(() => {
    const url = `/companies/myhospitals/${hospitalId}/contracts/`;

    api.get(url).then(response => {
      const { schedule } = response.data.data.find(
        contract => contract.schedule && contract.schedule.id === +scheduleId
      );

      setDaysToAlert(schedule.daysToAlert);

      setPublicDescription(schedule.publicDescription);
      setPrivateDescription(schedule.privateDescription);

      setIsPhotoCheckin(schedule.isPhotoCheckin);
      setIsPhotoCheckout(schedule.isPhotoCheckout);

      setAvailability(schedule.availability);

      setIsCheckinLocalization(schedule.isCheckinLocalization);
      setIsCheckoutLocalization(schedule.isCheckoutLocalization);

      setIsAutoCheckout(schedule.isAutoCheckout);
      setDoctorChangeFlow(schedule.doctorChangeFlow);
      setConfigAutoApprove(schedule.configautoapprove);

      setLoading(false);
    });
  }, [scheduleId]);

  const editSchedule = () => {
    if (Number.isNaN(+daysToAlert)) {
      return toast.error('Dias até emergência inválido.');
    }

    if (daysToAlert < 1) {
      return toast.error(
        'Por favor insira um valor válido para dias até emergência'
      );
    }

    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}`;

    const request = {
      daysToAlert,
      availability,
      isAutoCheckout,
      isPhotoCheckin,
      isPhotoCheckout,
      isCheckinLocalization,
      isCheckoutLocalization,
      publicDescription,
      privateDescription,
      doctorChangeFlow,
      configautoapprove: configAutoApprove,
    };

    return api
      .patch(url, request)
      .then(() => {
        toast.success('Plantão editado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  };

  return (
    <ModalContainer loading={loading}>
      <Flex justify="center">
        <Flex w="170px">
          <IText
            required
            label="dias até emergência"
            placeholder="Ex: 7"
            maxLength={2}
            value={daysToAlert}
            onChange={e => setDaysToAlert(e.target.value)}
          />
        </Flex>
      </Flex>
      <IArea
        label="descrição pública"
        placeholder="Informe a descrição pública..."
        value={publicDescription}
        onChange={e => setPublicDescription(e.target.value)}
      />
      <IArea
        label="descrição administrativa"
        placeholder="Informe a descrição administrativa..."
        value={privateDescription}
        onChange={e => setPrivateDescription(e.target.value)}
      />
      <Divider />
      <Label>disponibilidade para candidaturas</Label>
      <HStack>
        <IRadio
          label="Público"
          checked={availability === 'public'}
          onChange={() => setAvailability('public')}
        />
        <IRadio
          label="Somente Vinculados"
          checked={availability === 'linked'}
          onChange={() => setAvailability('linked')}
        />
        <IRadio
          label="Fechada"
          checked={availability === 'private'}
          onChange={() => setAvailability('private')}
        />
      </HStack>
      <Divider />
      <Label>Opções Adicionais</Label>
      <Flex maxW="550px" justify="space-between">
        <Flex flexDir="column" align="flex-start">
          <ICheck
            label="Solicitar Localização no Checkin"
            checked={isCheckinLocalization}
            onChange={() => setIsCheckinLocalization(!isCheckinLocalization)}
          />
          <ICheck
            label="Solicitar Localização no Checkout"
            checked={isCheckoutLocalization}
            onChange={() => setIsCheckoutLocalization(!isCheckoutLocalization)}
          />
          <ICheck
            label="Realização de Checkout Automático"
            checked={isAutoCheckout}
            onChange={() => setIsAutoCheckout(!isAutoCheckout)}
          />
        </Flex>
        <Flex flexDir="column" align="flex-start">
          <ICheck
            label="Solicitar Foto no Checkin"
            checked={isPhotoCheckin}
            onChange={() => setIsPhotoCheckin(!isPhotoCheckin)}
          />
          <ICheck
            label="Solicitar Foto no Checkout"
            checked={isPhotoCheckout}
            onChange={() => setIsPhotoCheckout(!isPhotoCheckout)}
          />
          <ICheck
            label="Habilitar Troca de Médico"
            checked={doctorChangeFlow}
            onChange={() => setDoctorChangeFlow(!doctorChangeFlow)}
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between">
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <IsAllowed id={26}>
          <Button
            width="auto"
            height="48px"
            icon2={<FaCog size={24} />}
            onClick={openConfigSchedule}
          />
        </IsAllowed>
        <Button title="editar" variant="success" onClick={editSchedule} />
      </Flex>
      <Modal
        title="Configuração Aprovação automática"
        ref={configScheduleModalRef}
        component={ConfigScheduleModal}
        setConfigSelected={setConfigAutoApprove}
        configSelected={configAutoApprove}
        editable
      />
    </ModalContainer>
  );
}
