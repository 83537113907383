import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

export const renderDoctor = vacancy => {
  let isOpen = true;
  let topText = 'Vaga Aberta';

  if (vacancy.userDoctor) {
    isOpen = false;
    topText = vacancy.userDoctor;
  }

  return (
    <Flex minW="200px" flexDir="column" textAlign="center">
      <Text
        fontWeight="semibold"
        color={isOpen ? 'green.400' : 'blue.400'}
        isTruncated
      >
        {topText}
      </Text>
    </Flex>
  );
};
