import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Flex, Text } from '@chakra-ui/react';
import { AiOutlineUser } from 'react-icons/ai';

import getFirstLastName from '~/utils/getFirstLastName';
import { vacancyCardBackgroundColor } from '~/utils/genStatus';

const Container = styled.div`
  cursor: pointer;
  overflow: hidden;
  position: relative;

  width: 100%;
  height: 100%;

  padding: 4px 4px 4px 4px;

  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;

  border-radius: 4px;

  font-weight: 600;
  text-align: center;

  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  color: var(--background-alt);

  background-color: ${props => props.color};

  p {
    &:first-child {
      font-size: 16px;
    }

    &:last-child {
      font-size: 12px;
    }
  }

  @media (max-width: 1600px) {
    ${'' /* padding-top: 28px; */}
    ${'' /* padding-bottom: 0%; */}

    justify-content: center;

    p {
      &:first-child {
        font-size: 12px;
      }

      &:last-child {
        font-size: 10px;
      }
    }
  }
`;

const ColumnCenterItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1600px) {
    align-items: flex-start;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-shrink: none;
  font-size: 36px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  align-itens: flex-start;

  background-color: var(--background-alt);

  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border: 2px solid #6c757da6;
  align-items: center;

  @media (max-width: 1600px) {
    width: 24.5px;
    height: 24.5px;
    position: relative;
    ${'' /* top: 8px; */}
    left: calc(50% - 12px);

    font-size: 25px;
  }
`;

function getEmotionsByVacancy(vacancy) {
  let result = '';
  if (vacancy.inCash) {
    result += ' 👁️ ';
  }
  if (vacancy.requestAntecipe) {
    result += ' ➡️ ';
  }
  if (vacancy.requestIsPaid) {
    result += ' ✅ ';
  }
  if (vacancy.differentiatedValue) {
    result += ' 💲 ';
  }
  return result;
}

export default function PendingDoctorCard({
  vacancy,
  onClick,
  componentBarLateral,
  componentBarTop,
}) {
  const renderDoctorName = useCallback(() => {
    return getFirstLastName(vacancy.pendingDoctorName);
  }, []);

  const renderDoctorCrm = useCallback(() => {
    const crm = vacancy.pendingDoctorCrm;
    const crmUf = vacancy.pendingDoctorCrmUf;

    return `${crm}-${crmUf}`;
  }, []);

  return (
    <Container
      color={vacancyCardBackgroundColor(vacancy.type, !vacancy.belongsMyTeam)}
      onClick={onClick}
    >
      <Flex
        flexDir="column"
        marginRight="8px"
        paddingRight="4px"
        borderRight="1px #00000024 solid"
        height="100%"
      >
        {componentBarLateral}
      </Flex>
      <Flex justifyContent="center" width="100%">
        <ColumnCenterItems>
          <Flex width="100%" flexDir="column">
            {componentBarTop}
          </Flex>
          <StatusContainer color="black" backgroundColor="white">
            <AiOutlineUser />
          </StatusContainer>
          <Text width="100%" color="#505050" fontSize={10}>
            {renderDoctorName()}
          </Text>
          <Text width="100%" color="#505050" fontSize={10}>
            {renderDoctorCrm()}
          </Text>
          <Text width="100%" color="#505050">
            {getEmotionsByVacancy(vacancy)}
          </Text>
        </ColumnCenterItems>
      </Flex>
    </Container>
  );
}
