import React, { useCallback } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import history from '~/services/history';

export default function Button({
  disabled = false,
  type = 'button',
  to,
  onClick = () => {},
  variant = 'primary',
  width = '148px',
  height = '48px',
  margin,
  fontSize = '12px',
  title,
  icon,
  icon2,
  ...rest
}) {
  const handleClick = useCallback(
    event => {
      if (to) return history.push(to);
      if (type === 'submit') return;
      if (disabled) return;
      return onClick(event);
    },
    [to, onClick]
  );

  const renderBackgroundColor = useCallback(() => {
    return {
      primary: 'var(--primary)',
      secondary: 'transparent',
      success: 'var(--green)',
      warning: 'var(--orange)',
      danger: 'var(--red)',
      white: 'transparent',
    }[variant];
  }, [variant]);

  const renderHoverBackgroundColor = useCallback(() => {
    return {
      primary: 'var(--hover-primary)',
      secondary: 'var(--hover-secondary)',
      success: 'var(--hover-green)',
      warning: 'var(--hover-orange)',
      danger: 'var(--hover-red)',
      white: 'var(--hover-white)',
    }[variant];
  }, [variant]);

  const renderColor = useCallback(() => {
    return {
      primary: 'white',
      secondary: 'var(--secondary)',
      success: 'white',
      warning: 'white',
      danger: 'white',
      white: 'white',
    }[variant];
  }, [variant]);

  const renderBorder = useCallback(() => {
    return {
      primary: '0',
      secondary: '2px',
      success: '0',
      warning: '0',
      danger: '0',
      white: '2px',
    }[variant];
  }, [variant]);

  const renderBorderStyle = useCallback(() => {
    return {
      primary: 'none',
      secondary: 'solid',
      success: 'none',
      warning: 'none',
      danger: 'none',
      white: 'solid',
    }[variant];
  }, [variant]);

  const renderBorderColor = useCallback(() => {
    return {
      primary: 'transparent',
      secondary: 'var(--secondary)',
      success: 'transparent',
      warning: 'transparent',
      danger: 'transparent',
      white: 'var(--background-alt)',
    }[variant];
  }, [variant]);

  return (
    <Flex
      {...rest}
      as="button"
      type={type}
      onClick={handleClick}
      w={width}
      h={height}
      px={2}
      margin={margin}
      flexShrink="0"
      alignItems="center"
      justifyContent="center"
      backgroundColor={renderBackgroundColor()}
      borderRadius="sm"
      border={renderBorder()}
      borderStyle={renderBorderStyle()}
      borderColor={renderBorderColor()}
      _hover={{ backgroundColor: !disabled && renderHoverBackgroundColor() }}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      filter={disabled && 'var(--filter-disabled)'}
    >
      <Text
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent={icon || icon2 ? 'space-between' : 'center'}
        color={renderColor()}
        fontSize={fontSize}
        fontWeight="600"
        textAlign="center"
        textTransform="uppercase"
      >
        {icon}
        {title}
        {icon2}
      </Text>
    </Flex>
  );
}
