import api from '~/services/api';
import { getParam } from '~/utils/url';

export default (callback, options = {}) => {
  const { hospitalId } = getParam('hospitalId');

  if (!hospitalId) return null;

  api.get(`/companies/myhospitals/${hospitalId}/contracts/`).then(response => {
    let arr = response.data.data;

    if (options.activeOnly) {
      arr = arr.filter(contract => {
        return contract.schedule && contract.isActive;
      });
    }

    if (options.inactiveOnly) {
      arr = arr.filter(contract => {
        return !contract.schedule && !contract.isActive;
      });
    }

    const sorted = arr.sort((a, b) => {
      if (a.specialty.name < b.specialty.name) return -1;
      if (a.specialty.name > b.specialty.name) return 1;
      return 0;
    });

    sorted.forEach(contract => {
      const specialty = contract.specialty.name;
      const sector = contract.sector;
      const start = contract.start.slice(0, -3);
      const end = contract.end.slice(0, -3);

      contract.name = `${specialty} - ${sector} - ${start}h às ${end}h`;
    });

    callback(sorted);
  });
};
