import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Flex, HStack, Text } from '@chakra-ui/react';

import api from '~/services/api';
import capitalizeString from '~/utils/capitalizeString';
import { getUser } from '~/services/auth';
import { ufOptions } from '~/constants/uf';

import { Button, Divider } from '~/components/ChakraComponents';
import { ModalContainer } from '~/components/UI/Modal';
import { IText, ISelect, ICheck } from '~/components/UI/Inputs';

export default function VinculateModal({
  onSubmit,
  closeModal,
  noResultsCrm,
  noResultsCrmUf = getUser().uf,
}) {
  const [inputs, setInputs] = useState({
    crm: noResultsCrm,
    crmUf: noResultsCrmUf,
  });
  const [validating, setValidating] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [isNotMyTeam, setIsNotMyTeam] = useState(false);
  const [needPhone, setNeedPhone] = useState(false);
  const [myDoctor, setMyDoctor] = useState(null);
  const [phone, setPhone] = useState('');

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onReset = () => {
    const obj = { ...inputs };

    delete obj.name;
    delete obj.situation;
    obj.phone = '';

    setPhone(obj.phone);
    setInputs(obj);
    setMyDoctor(null);
  };

  const validateCrm = async () => {
    setInvalid(false);
    setMyDoctor(null);

    const { crm, crmUf } = inputs;

    if (!crm || crm.length < 4 || crm.length > 10) {
      setValidating(false);
      return toast.error('Crm inválido.');
    }

    const myDoctorsUrl = `/companies/mydoctors?limit=1&crm=${crm}&crmUf=${crmUf}`;

    let myDoctor = null;

    const response = await api.get(myDoctorsUrl);

    if (response?.data?.data?.length === 1) {
      myDoctor = response.data.data[0];
    }

    if (myDoctor) {
      setValidating(false);
      return setMyDoctor(myDoctor);
    }

    api
      .get(`/checkcrm-phone?crm=${crm}&uf=${crmUf}`)
      .then(response => {
        const {
          nome,
          situacao: situation,
          tipoInscricao,
        } = response.data.dadosMedico;

        const { telefone } = response.data;

        const name = capitalizeString(nome);

        const formattedPhone = telefone || '';

        if (
          ['E', 'F', 'G', 'I', 'J', 'L', 'M', 'N', 'O', 'S'].includes(
            situation
          ) ||
          tipoInscricao === 'E'
        ) {
          setInvalid(true);
        }

        setValidating(false);

        setPhone(formattedPhone);

        return setInputs({ ...inputs, name, situation, phone: formattedPhone });
      })
      .catch(error => {
        setValidating(false);

        if (error.response?.data?.error === 'not found') {
          return toast.error('Crm inválido.');
        }

        return toast.error(
          'Houve um erro com os servidores do CFM. Tente novamente.'
        );
      });
  };

  useEffect(() => {
    if (validating) validateCrm();
  }, [validating]);

  useEffect(() => {
    const hospitalData = JSON.parse(localStorage.getItem('hospitalData'));

    const { sendReminderToMyTeam, sendReminderToNotMyTeam } = hospitalData;

    console.log('sendReminderToMyTeam', sendReminderToMyTeam);
    console.log('sendReminderToNotMyTeam', sendReminderToNotMyTeam);
    console.log('isNotMyTeam', isNotMyTeam);

    if (sendReminderToMyTeam && !isNotMyTeam) {
      setNeedPhone(true);
      return;
    }

    if (sendReminderToNotMyTeam && isNotMyTeam) {
      setNeedPhone(true);
      return;
    }

    setNeedPhone(false);
  }, [isNotMyTeam]);

  const checkIsValidPhone = (phone = '') => {
    if (phone.length < 11) {
      return false;
    }

    if (phone.length > 13) {
      return false;
    }

    if (!phone.startsWith('55')) {
      return false;
    }

    const ddd = phone.substring(2, 4);

    const validsDDD = [
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '21',
      '22',
      '24',
      '27',
      '28',
      '31',
      '32',
      '33',
      '34',
      '35',
      '37',
      '38',
      '41',
      '42',
      '43',
      '44',
      '45',
      '46',
      '47',
      '48',
      '49',
      '51',
      '53',
      '54',
      '55',
      '61',
      '62',
      '63',
      '64',
      '65',
      '66',
      '67',
      '68',
      '69',
      '71',
      '73',
      '74',
      '75',
      '77',
      '79',
      '81',
      '82',
      '83',
      '84',
      '85',
      '86',
      '87',
      '88',
      '89',
      '91',
      '92',
      '93',
      '94',
      '95',
      '96',
      '97',
      '98',
      '99',
    ];

    if (!validsDDD.includes(ddd)) {
      return false;
    }

    return true;
  };

  const onChangePhone = e => {
    setPhone(e.target.value || '');
    setInputs({ ...inputs, [e.target.name]: e.target.value || '' });
  };

  const onChoosingPendingDoctor = () => {
    if (needPhone) {
      if (!checkIsValidPhone(phone)) {
        return toast.error('Telefone inválido. O Padrão é 5519997945996');
      }
    }
    onSubmit({ ...inputs, phone, isNotMyTeam });
    closeModal();
  };

  const onChoosingCompanyDoctor = () => {
    onSubmit(myDoctor);
    closeModal();
  };

  return (
    <ModalContainer w="400px">
      {!inputs.name && (
        <>
          <Flex justify="center">
            <Text textAlign="center">
              O CRM será válidado pelo sistema do CFM.
            </Text>
          </Flex>
          <HStack>
            <IText
              label="crm"
              type="number"
              name="crm"
              value={inputs.crm}
              onChange={handleChanges}
            />
            <ISelect
              label="uf"
              name="crmUf"
              options={ufOptions}
              value={inputs.crmUf}
              onChange={handleChanges}
            />
          </HStack>
          <ICheck
            label="Médico NÃO vinculado à Empresa"
            checked={isNotMyTeam}
            onChange={() => setIsNotMyTeam(!isNotMyTeam)}
          />
          <Divider />
        </>
      )}
      {myDoctor ? (
        <Flex flexDir="column" align="center" textAlign="center">
          <Text mb={2} fontWeight="semibold" color="var(--green)">
            Esse médico já está vinculado à empresa.
          </Text>
          <Text>Nome: {myDoctor.name}</Text>
          <Text my={2}>
            CRM: {myDoctor.doctor.crm}-{myDoctor.doctor.crmUf}
          </Text>
          <Flex w="100%" justify={invalid ? 'center' : 'space-between'}>
            <Button title="buscar outro" onClick={onReset} />
            <Button
              title="escolher"
              variant="success"
              onClick={onChoosingCompanyDoctor}
            />
          </Flex>
        </Flex>
      ) : (
        <Flex flexDir="column" align="center">
          {inputs.name ? (
            <>
              <Text>Nome: {inputs.name}</Text>
              <Text>
                CRM: {inputs.crm}-{inputs.crmUf}
              </Text>
              <Text
                my={2}
                fontWeight="semibold"
                color={invalid ? 'var(--red)' : 'var(--green)'}
              >
                Situação: {invalid ? 'Inválida' : 'Válida'}
              </Text>

              {!invalid && needPhone && (
                <IText
                  required
                  placeholder="Ex: 5515998955667"
                  type="number"
                  label="Telefone"
                  pattern="[0-9]*"
                  name="phone"
                  value={inputs.phone}
                  onChange={onChangePhone}
                  maxLength="13"
                  minLength="10"
                />
              )}

              <Flex
                w="100%"
                justify={invalid ? 'center' : 'space-between'}
                marginTop="15px"
              >
                <Button title="buscar outro" onClick={onReset} />
                {!invalid && (
                  <Button
                    title="vincular"
                    variant="success"
                    onClick={onChoosingPendingDoctor}
                  />
                )}
              </Flex>
            </>
          ) : (
            <Button
              title={validating ? 'validando...' : 'validar'}
              onClick={() => setValidating(true)}
            />
          )}
        </Flex>
      )}
    </ModalContainer>
  );
}
