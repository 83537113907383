import styled from 'styled-components';
import { GoCalendar } from 'react-icons/go';
import { RiMenuLine, RiFilter3Line } from 'react-icons/ri';
import {
  AiOutlineSearch,
  AiOutlineCheck,
  AiOutlineClose,
} from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';

export const CalendarSvg = styled(GoCalendar)`
  font-size: 24px;
  color: var(--background);
`;

export const SearchSvg = styled(AiOutlineSearch)`
  font-size: 24px;
  color: var(--background);
`;

export const AddSvg = styled(MdAdd)`
  font-size: 24px;
  color: var(--background);
`;

export const HamburgerOpenSvg = styled(RiMenuLine)`
  margin-bottom: 8px;
  margin-left: 15px;
  font-size: 24px;
  color: var(--secondary);
  cursor: pointer;
`;

export const HamburgerCloseSvg = styled(RiFilter3Line)`
  margin-bottom: 8px;
  margin-left: 15px;
  font-size: 24px;
  color: var(--secondary);
  cursor: pointer;
`;

export const ConfirmSvg = styled(AiOutlineCheck)`
  font-size: 24px;
  color: var(--green);
`;

export const NegateSvg = styled(AiOutlineClose)`
  font-size: 24px;
  color: var(--red);
`;
