import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import Stars from '../index.js';

import { Button, Spinner } from '~/components/ChakraComponents/';

import { RatingsGrid, Rating } from './styles';

export default function RateModal({ doctorId }) {
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const [mouseConfirm, setMouseConfirm] = useState(false);

  useEffect(() => {
    const url = `/companies/mydoctors/${doctorId}`;

    api.get(url).then(response => {
      setRating(+response.data.doctor.myCompanyRating);
      setLoading(false);
    });
  }, []);

  const confirm = () => {
    api
      .patch(`/companies/mydoctors/${doctorId}`, { rating })
      .then(() => {
        window.location.reload();
      })
      .catch(internalServerError);
  };

  return (
    <Flex w="240px" flexDir="column">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Stars rating={rating} size={40}>
            <RatingsGrid onMouseEnter={() => setMouseConfirm(false)}>
              <Rating
                onClick={() => setMouseConfirm(true)}
                onMouseOver={() => !mouseConfirm && setRating(1)}
              />
              <Rating
                onClick={() => setMouseConfirm(true)}
                onMouseOver={() => !mouseConfirm && setRating(2)}
              />
              <Rating
                onClick={() => setMouseConfirm(true)}
                onMouseOver={() => !mouseConfirm && setRating(3)}
              />
              <Rating
                onClick={() => setMouseConfirm(true)}
                onMouseOver={() => !mouseConfirm && setRating(4)}
              />
              <Rating
                onClick={() => setMouseConfirm(true)}
                onMouseOver={() => !mouseConfirm && setRating(5)}
              />
              <Rating
                onClick={() => setMouseConfirm(true)}
                onMouseOver={() => !mouseConfirm && setRating(6)}
              />
              <Rating
                onClick={() => setMouseConfirm(true)}
                onMouseOver={() => !mouseConfirm && setRating(7)}
              />
              <Rating
                onClick={() => setMouseConfirm(true)}
                onMouseOver={() => !mouseConfirm && setRating(8)}
              />
              <Rating
                onClick={() => setMouseConfirm(true)}
                onMouseOver={() => !mouseConfirm && setRating(9)}
              />
              <Rating
                onClick={() => setMouseConfirm(true)}
                onMouseOver={() => !mouseConfirm && setRating(10)}
              />
            </RatingsGrid>
          </Stars>
          <Flex mt="20px" justifyContent="center">
            <Button title="confirmar" variant="success" onClick={confirm} />
          </Flex>
        </>
      )}
    </Flex>
  );
}
