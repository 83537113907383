import React from 'react';
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';

import MeuPlantaoLogo from '~/assets/logo/logo-Recuperado.png';
import getFirstLastName, { abbreviattedName } from '~/utils/getFirstLastName';
import { weekDaysAlt } from '~/constants/weekDays';

const styles = StyleSheet.create({
  page: {
    margin: 8,
  },
  header: {
    paddingVertical: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#3D4043',
  },
  logo: {
    width: 70,
    objectFit: 'cover',
  },
  companyLogo: {
    width: 35,
    height: 35,
    objectFit: 'cover',
  },
  agenda: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  labelCol: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    paddingVertical: 4,
    width: '100%',
    color: '#5C69E1',
    fontWeight: 700,
    textAlign: 'center',
    fontSize: 12,
  },
  sectors: {
    display: 'flex',
  },
  sectorSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #e7e7e7',
  },
  sectorLabel: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: '#5C69E1',
    fontWeight: 'bold',
    fontSize: 10,
    workBreak: 'break-all',
    overflow: 'hidden',
  },
  vacanciesCol: {
    width: '100%',
    height: '100%',
    marginTop: 8,
    paddingHorizontal: 2,
    display: 'flex',
    alignItems: 'center',
  },
  vacancyCard: {
    width: '100%',
  },
});

// If you have to work on this file im sorry for you.
export default function PdfDocument({
  options,
  companyLogo,
  weekDates,
  schedules,
}) {
  const genCardStyles = vacancy => {
    let key = vacancy.type;

    if (vacancy.type === 'weekly' || vacancy.type === 'biweekly') {
      key = 'fixed';
    }

    if (vacancy.pendingDoctorCrm) {
      key = 'pending';
    }

    if (!vacancy.companyDoctor && !vacancy.pendingDoctorCrm) {
      key = 'open';
    }

    if (!options.inputs[key]) {
      return null;
    }

    const colors = {
      open: options.colors.open,
      cover: options.colors.cover,
      fixed: options.colors.fixed,
      exception: options.colors.exception,
      pending: options.colors.pending,
    };

    const style = { ...styles.vacancyCard, ...options.styles };

    if (options.inputs.singleColor) {
      style.backgroundColor = options.colors.all;
    } else {
      style.backgroundColor = colors[key];
    }

    return style;
  };

  const gambiarra = string => {
    const limit = 18;
    const finalString = [];

    string.split(' ').forEach((word, i) => {
      if (word.length <= limit) {
        return (finalString[i] = word);
      }

      const s1 = word.substr(0, limit);
      const s2 = word.substr(limit);

      return (finalString[i] = s1 + ' ' + s2);
    });

    return finalString.join(' ');
  };

  return (
    <Document>
      <Page
        size="A4"
        orientation={options.inputs.isLandscape ? 'landscape' : 'portrait'}
      >
        <View style={styles.page}>
          <View fixed style={styles.header}>
            <Image src={MeuPlantaoLogo} style={styles.logo} />
            <Text>{localStorage.groupName}</Text>
            {options.inputs.companyLogo && (
              <Image src={companyLogo} style={styles.logo} />
            )}
          </View>
          {weekDates.map((week, wi) => (
            <React.Fragment key={'w' + wi}>
              {/* Ideia do Carlos essa key morfética */}
              <View style={styles.agenda}>
                <View style={styles.labelCol}>
                  <Text style={styles.label}>Setor</Text>
                </View>
                {week.map((date, i) => (
                  <View key={date} style={styles.labelCol}>
                    <Text style={styles.label}>{weekDaysAlt[i]}</Text>
                    <Text style={styles.label}>
                      {date
                        .split('-')
                        .reverse()
                        .join('/')}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={styles.sectors}>
                {schedules?.map?.(schedule => (
                  <View
                    wrap={false}
                    key={schedule.id}
                    style={styles.sectorSection}
                  >
                    <View style={styles.sectorLabel}>
                      <Text>{gambiarra(schedule.contract.sector)}</Text>
                      <Text>
                        {`${schedule.contract.start.slice(
                          0,
                          -3
                        )} - ${schedule.contract.end.slice(0, -3)}`}
                      </Text>
                    </View>
                    {schedule.dates.map(date => (
                      <React.Fragment key={date.day}>
                        {week.includes(date.day) && (
                          <View style={styles.vacanciesCol}>
                            {date.vacancies.map(vacancy => (
                              <View
                                key={vacancy.id}
                                style={genCardStyles(vacancy)}
                              >
                                {
                                  <>
                                    {(options.inputs.fixed ||
                                      options.inputs.cover ||
                                      options.inputs.exception) &&
                                      vacancy.companyDoctor && (
                                        <>
                                          {options.inputs.isSmall ? (
                                            <Text
                                              style={{
                                                color: 'white',
                                                fontSize:
                                                  options.styles.fontSize,
                                              }}
                                            >
                                              {abbreviattedName(
                                                vacancy.doctor.user.name
                                              )}
                                            </Text>
                                          ) : (
                                            <>
                                              <Text
                                                style={{
                                                  color: 'white',
                                                  fontSize:
                                                    options.styles.fontSize,
                                                }}
                                              >
                                                {
                                                  getFirstLastName(
                                                    vacancy.doctor.user.name
                                                  ).split(' ')[0]
                                                }
                                              </Text>
                                              <Text
                                                style={{
                                                  color: 'white',
                                                  fontSize:
                                                    options.styles.fontSize,
                                                }}
                                              >
                                                {
                                                  getFirstLastName(
                                                    vacancy.doctor.user.name
                                                  ).split(' ')[1]
                                                }
                                              </Text>
                                            </>
                                          )}

                                          <Text
                                            style={{
                                              color: 'white',
                                              fontSize:
                                                options.styles.fontSize - 2,
                                            }}
                                          >
                                            {vacancy.doctor.crm}-
                                            {vacancy.doctor.crmUf}
                                          </Text>
                                        </>
                                      )}
                                    {options.inputs.pending &&
                                      vacancy.pendingDoctorCrm && (
                                        <>
                                          {options.inputs.isSmall ? (
                                            <Text
                                              style={{
                                                color: 'white',
                                                fontSize:
                                                  options.styles.fontSize,
                                              }}
                                            >
                                              {abbreviattedName(
                                                vacancy.pendingDoctorName
                                              )}
                                            </Text>
                                          ) : (
                                            <>
                                              <Text
                                                style={{
                                                  color: 'white',
                                                  fontSize:
                                                    options.styles.fontSize,
                                                }}
                                              >
                                                {
                                                  getFirstLastName(
                                                    vacancy.pendingDoctorName
                                                  ).split(' ')[0]
                                                }
                                              </Text>
                                              <Text
                                                style={{
                                                  color: 'white',
                                                  fontSize:
                                                    options.styles.fontSize,
                                                }}
                                              >
                                                {
                                                  getFirstLastName(
                                                    vacancy.pendingDoctorName
                                                  ).split(' ')[1]
                                                }
                                              </Text>
                                            </>
                                          )}

                                          <Text
                                            style={{
                                              color: 'white',
                                              fontSize:
                                                options.styles.fontSize - 2,
                                            }}
                                          >
                                            {vacancy.pendingDoctorCrm}-
                                            {vacancy.pendingDoctorCrmUf}
                                          </Text>
                                        </>
                                      )}
                                    {options.inputs.open &&
                                      !vacancy.companyDoctor &&
                                      !vacancy.pendingDoctorCrm && (
                                        <>
                                          <Text
                                            style={{
                                              color: 'white',
                                              fontSize: options.styles.fontSize,
                                            }}
                                          >
                                            Vaga
                                          </Text>
                                          <Text
                                            style={{
                                              color: 'white',
                                              fontSize: options.styles.fontSize,
                                            }}
                                          >
                                            Aberta
                                          </Text>
                                        </>
                                      )}
                                  </>
                                }
                              </View>
                            ))}
                          </View>
                        )}
                      </React.Fragment>
                    ))}
                  </View>
                ))}
              </View>
            </React.Fragment>
          ))}
        </View>
      </Page>
    </Document>
  );
}
