import React from 'react';
import moment from 'moment';
import { Flex, Text } from '@chakra-ui/react';

import genStatus, { statusText } from '~/utils/genStatus';

import IconStatus from '~/components/UI/Cards/VacancyCard/IconStatus';

import { Avatar } from '~/components/ChakraComponents';

export default function ChooseOption({ vacancy }) {
  const isNotOldVacancy = new Date(vacancy.endHour) > new Date();

  return (
    <Flex
      w="400px"
      maxW="95vw"
      maxH="80vh"
      flexDir="column"
      alignItems="center"
      overflowY="scroll"
      overflowX="hidden"
    >
      <Flex w="100%" mb={4} flexDir="column">
        <Flex
          w="100%"
          mb={2}
          justifyContent="space-between"
          fontSize="lg"
          fontWeight="semibold"
        >
          <Text color="var(--primary)">
            {moment(new Date(vacancy.startHour)).format('DD/MM/YYYY')}
          </Text>
        </Flex>
        {vacancy.pendingDoctorCrm && (
          <>
            <Flex
              mb={4}
              flexDir="column"
              alignItems="center"
              textAlign="center"
              fontSize="md"
              fontWeight="semibold"
            >
              <Text color="gray.600">Médico Pendente</Text>
              <Text fontWeight="bold" color="gray.600">
                {vacancy.pendingDoctorName}
              </Text>
              <Text color="gray.500">
                {vacancy.pendingDoctorCrm}-{vacancy.pendingDoctorCrmUf}
              </Text>
            </Flex>
          </>
        )}
        {vacancy.companyDoctor && (
          <>
            <Flex flexDir="column" alignItems="center" textAlign="center">
              <Avatar
                w="70px"
                h="70px"
                name={vacancy.doctor.user.name}
                src={vacancy.doctor.user.avatar}
              />
              <Text mt={3} fontSize="md" fontWeight="bold" color="gray.600">
                {vacancy.doctor.user.name}
              </Text>
              <Text fontSize="md" fontWeight="semibold" color="gray.500">
                {`${vacancy.doctor.crm}-${vacancy.doctor.crmUf}`}
              </Text>
            </Flex>
            <Flex mt={4} mb={2} alignItems="center">
              <IconStatus vacancy={vacancy} />
              <Text ml={3} fontWeight="semibold" fontSize="md" color="gray.600">
                {statusText(genStatus(vacancy))}
              </Text>
            </Flex>
            {['accepted', 'pending', 'expired', 'checkin', 'checkout'].includes(
              genStatus(vacancy)
            ) && (
              <Flex flexDir="column" color="gray.600" fontWeight="semibold">
                <Text>{`Checkin: ${
                  vacancy.checkin ? 'Realizado' : 'Não realizado'
                } ${
                  vacancy.checkin
                    ? `às ${moment(new Date(vacancy.checkinDate)).format(
                        'HH:mm'
                      )}h`
                    : ''
                }`}</Text>
                <Text>{`Checkout: ${
                  vacancy.checkout ? 'Realizado' : 'Não realizado'
                } ${
                  vacancy.checkout
                    ? `às ${moment(new Date(vacancy.checkoutDate)).format(
                        'HH:mm'
                      )}h`
                    : ''
                }`}</Text>
              </Flex>
            )}
          </>
        )}
        {!vacancy.companyDoctor && !vacancy.pendingDoctorCrm && (
          <Flex w="100%" justifyContent="center" textAlign="center">
            {isNotOldVacancy ? (
              <Text color="var(--green)" fontWeight="semibold" fontSize="lg">
                Vaga aberta.
              </Text>
            ) : (
              <Text color="var(--red)" fontWeight="semibold" fontSize="lg">
                Vaga aberta com falta.
              </Text>
            )}
          </Flex>
        )}
      </Flex>
      {vacancy.companyJustification && (
        <Flex w="100%" flexDir="column">
          <Flex
            alignItems="center"
            color="var(--red)"
            fontWeight="bold"
            textTransform="uppercase"
          >
            <Text>Justificativa</Text>
          </Flex>
          <Text>{vacancy.companyJustification}</Text>
        </Flex>
      )}
    </Flex>
  );
}
