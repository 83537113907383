import React, { useCallback } from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';

import { Row } from '~/components/Kiwistrap/Page';

import IconStatus from '../IconStatus';

import { Card, Avatar, Name, Specialty, Sector } from './styles';

export default function VacancyCard({ vacancy, onClick }) {
  const renderHourText = useCallback(() => {
    const { startHour, endHour } = vacancy;

    const formattedStart = moment(new Date(startHour)).format('HH:mm');
    const formattedEnd = moment(new Date(endHour)).format('HH:mm');

    return `${formattedStart}h às ${formattedEnd}h`;
  }, []);

  const renderDoctorName = useCallback(() => {
    const {
      doctor: {
        user: { name },
      },
    } = vacancy;

    const formattedName = name.split(/(\s).+\s/).join('');

    return formattedName;
  }, []);

  const renderDoctorCrm = useCallback(() => {
    const {
      doctor: { crm, crmUf },
    } = vacancy;

    return `${crm}-${crmUf}`;
  }, []);

  const cardBackgroundColor = useCallback(
    () =>
      ({
        weekly: 'var(--primary)',
        biweekly: 'var(--primary)',
        cover: 'var(--secondary)',
        exception: 'var(--orange)',
      }[vacancy.type]),
    []
  );

  const generateStatus = useCallback(() => {
    const { date, status, checkin, checkout } = vacancy;

    let key = status;

    const isExpired =
      moment(new Date(date)).diff(moment(new Date()), 'days') < 0;

    if (['pending', 'accepted'].includes(status) && isExpired) key = 'expired';
    if (checkin === true && checkout === false) key = 'checkin';
    if (checkout === true) key = 'checkout';

    return key;
  }, []);

  return (
    <Card color={cardBackgroundColor()} onClick={onClick}>
      <Flex flexDir="column" h="80px" align="center" justify="space-around">
        <Avatar
          src={vacancy.doctor.user.avatar}
          alt={vacancy.doctor.user.name}
        />
        <Name>{renderDoctorName()}</Name>
      </Flex>
      <Flex h="20px" justify="center" align="center">
        <Specialty>{renderDoctorCrm()}</Specialty>
      </Flex>
      <Flex
        h="40px"
        align="center"
        justify="space-between"
        padding="0 5px"
        backgroundColor="var(--background-alt)"
        borderBottomLeftRadius="4px"
        borderBottomRightRadius="4px"
      >
        <Flex flexDir="column">
          <Sector>{vacancy.schedule.contract.sector}</Sector>
          <Sector>{renderHourText()}</Sector>
        </Flex>
        <Flex
          w="auto"
          h="100%"
          flexDir="column"
          justify="center"
          align="center"
        >
          <IconStatus status={generateStatus()} />
        </Flex>
      </Flex>
    </Card>
  );
}
