import React from 'react';
import { Container } from './styles';
import { Label } from '../';

export default function InputContainer({
  title,
  titleColor,
  style,
  children,
  ...rest
}) {
  return (
    <Container style={style} {...rest}>
      <Label title={title} color={titleColor} />
      {children}
    </Container>
  );
}
