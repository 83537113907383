import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';

import api from '~/services/api';
import getFirstLastName from '~/utils/getFirstLastName';
import { getParam } from '~/utils/url';

import SearchUserInChargeInput from './SearchUserInChargeInput';

import Pagination from '~/components/Dashboard/Pagination';
import { Avatar } from '~/components/ChakraComponents';
import { RoundList } from '~/components/UI/Layouts';
import { ModalContainer } from '~/components/UI/Modal';

const limit = 50;

export default function ChooseUserInChargeModal({
  setUserInCharge,
  closeModal,
}) {
  const { groupId } = useParams();
  const { hospitalId } = getParam();

  const [name, setName] = useState('');
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchUsers = () => {
    const offset = (currentPage - 1) * limit;

    const url = `/companies/myhospitals/${hospitalId}/schedules/${groupId}/vacancies/userscompany?name=${name}&offset=${offset}&limit=${limit}&isActive=true`;

    api.get(url).then(response => {
      setTotal(response.data.total);
      setUsers(response.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, name]);

  return (
    <ModalContainer w="400px" loading={loading}>
      <SearchUserInChargeInput setName={setName} />
      <RoundList>
        {users.map(user => (
          <Flex
            onClick={() => {
              setUserInCharge(user);
              closeModal();
            }}
            cursor="pointer"
            p={2}
            align="center"
            _hover={{
              color: 'var(--secondary)',
              bgColor: 'var(--hover-secondary)',
            }}
          >
            <Avatar
              w="40px"
              h="40px"
              src={user.avatar}
              name={getFirstLastName(user.name)}
            />
            <Text w="100%" ml={2} isTruncated>
              {user.name}
            </Text>
          </Flex>
        ))}
      </RoundList>
      <Pagination l={limit} t={total} onClick={setCurrentPage} />
    </ModalContainer>
  );
}
