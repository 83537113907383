import { Dashboard as ContractorDashboard } from '~/components/UI/Pages';

import {
  ContractorMyHospitals,
  ContractorProfile,
  ContractorUsers,
} from '~/pages/contractor';

export default [
  {
    tag: 1,
    path: '/contractor/dashboard',
    homepage: 'dashboard',
    component: ContractorDashboard,
  },
  {
    tag: 2,
    path: '/contractor/myhospitals',
    homepage: 'myhospitals',
    component: ContractorMyHospitals,
  },
  {
    tag: 15,
    path: '/contractor/profile',
    component: ContractorProfile,
  },

  {
    tag: 9,
    path: '/contractor/users',
    component: ContractorUsers,
  },
];
