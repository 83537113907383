import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import {
  Container,
  Content,
  Form,
  NameInput,
  Title,
  Description,
  SectionBtn,
  Footer,
  SectionUteis,
  TitleUteis,
  SectionList,
  ListAll,
  ListItem,
  SectionCopyright,
  TitleCopyright,
  TitleSociais,
  SectionSociais,
  FooterMobile,
} from './styles';

import api from '~/services/api';
import history from '~/services/history';

import InputPrimary from '~/components/Site/Inputs/InputPrimary';
import BtnSend from '~/components/Site/Buttons/BtnSecondary';

export default function ForgotPassword() {
  const [email, setEmail] = useState();

  const handleEmail = e => setEmail(e.target.value);

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const url = '/recovery-password';
      const request = { email };

      await api.post(url, request);

      toast.success('Um e-mail de recuperação de senha acaba de ser enviado.');
      history.push('/login');
    } catch (error) {
      if (error.message === 'Request failed with status code 404') {
        toast.error('E-mail não cadastrado no sistema. Por Favor verificar');
      } else {
        toast.error(
          'Não foi possível realizar essa operação. Tente novamente.'
        );
      }
    }
  };

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Title>Esqueceu a senha?</Title>
          <Description>
            Insira seu e-mail de cadastro e enviaremos
            <br /> um link de recuperação para você!
          </Description>
          <NameInput>EMAIL</NameInput>
          <InputPrimary
            name="email"
            type="email"
            placeholder="EX: entrar@plantao.com.br"
            value={email}
            onChange={handleEmail}
          />
          <SectionBtn>
            <BtnSend title="ENVIAR" type="submit" styles={{ width: '100%' }} />
          </SectionBtn>
        </Form>
      </Content>
      <Footer>
        <SectionUteis>
          <SectionList>
            <TitleUteis>Links Úteis</TitleUteis>
            <ListAll>
              <ListItem>Home</ListItem>
              <ListItem>Contato</ListItem>
              <ListItem>Produto</ListItem>
              <ListItem>Fale Conosco</ListItem>
            </ListAll>
          </SectionList>
          <SectionCopyright>
            <TitleCopyright>
              2020 - Meu Plantão - Todos os direitos reservados
            </TitleCopyright>
          </SectionCopyright>
          <SectionSociais>
            <a
              href="https://www.facebook.com/MeuPlantao"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a
              href="https://www.instagram.com/meuplantao/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a href="#" target="_blank" rel="noreferrer">
              <FaWhatsapp size={30} color="var(--background-alt)" />
            </a>
          </SectionSociais>
        </SectionUteis>
      </Footer>
      <FooterMobile>
        <TitleSociais>Siga-nos nas redes sociais</TitleSociais>
        <SectionSociais>
          <a
            href="https://www.facebook.com/MeuPlantao"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook
              size={30}
              color="var(--background-alt)"
              style={{ marginRight: 40 }}
            />
          </a>
          <a
            href="https://www.instagram.com/meuplantao/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              size={30}
              color="var(--background-alt)"
              style={{ marginRight: 40 }}
            />
          </a>
          <a href="#" target="_blank" rel="noreferrer">
            <FaWhatsapp size={30} color="var(--background-alt)" />
          </a>
        </SectionSociais>
      </FooterMobile>
    </Container>
  );
}
