/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import ProfileShimmer from './shimmer';

import Pagination from '~/components/Dashboard/Pagination';

import { Button } from '~/components/ChakraComponents/';
import { Text, Title } from '~/components/Kiwistrap/Page';

import { Container, Hospital } from './styles';

const internalServerError =
  'Houve um problema com os nossos servidores. Tente novamente.';

const limit = 50;
export default function VinculateModal() {
  const [loading, setLoading] = useState(true);
  const [hospitals, setHospitals] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = useCallback(() => {
    const offset = (currentPage - 1) * limit;
    const url = `/doctors/hospitals?limit=${limit}&offset=${offset}`;

    api.get(url).then(response => {
      setTotal(response.data.total);
      setHospitals(response.data.data);
      setLoading(false);
    });
  }, [currentPage, hospitals, total]);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [currentPage]);

  const handleVinculate = async companyId => {
    try {
      const url = '/doctors/companies';
      const request = { company: companyId };

      await api.post(url, request);

      window.location.reload();
    } catch (e) {
      toast.error(internalServerError);
    }
  };

  const onAvatarLoadError = e => {
    e.target.src =
      'https://verde-azul-imoveis.s3.amazonaws.com/b36f1999-aada-4f0b-9fe8-21bdf419fef7.png';
  };

  return (
    <Container>
      {!loading ? (
        hospitals && hospitals.length > 0 ? (
          <>
            {hospitals.map(hospital => (
              <Hospital key={hospital.id}>
                <img
                  src={hospital.avatar}
                  alt={hospital.name}
                  onError={onAvatarLoadError}
                />
                <Flex flexDir="column">
                  <h1>{hospital.name}</h1>
                  <Text>{`${hospital.uf} - ${hospital.city}, ${hospital.address}, ${hospital.street} ${hospital.number}`}</Text>
                </Flex>
                <Flex w="auto" flexDir="column">
                  <Button
                    title="vincular-se"
                    variant="success"
                    onClick={() => handleVinculate(hospital.company.id)}
                  />
                </Flex>
              </Hospital>
            ))}
            <Pagination l={limit} t={total} onClick={setCurrentPage} />
          </>
        ) : (
          <Title center color="var(--light-gray)" margin="20vh 0 0">
            Nenhum hospital encontrado.
          </Title>
        )
      ) : (
        <ProfileShimmer />
      )}
    </Container>
  );
}
