import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus{
    outline: 0;
    box-shadow: none !important;
  }

  html, body, #root{
    height: 100%;
    width:100%;
    overflow-x:hidden;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  @media (min-width: 1023px) {
    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      border-radius: var(--br-sm);
      -webkit-border-radius: var(--br-sm);
    }

    ::-webkit-scrollbar-thumb {
      border-radius: var(--br-sm);
      -webkit-border-radius: var(--br-sm);
      background: var(--light-gray);
    }

    ::-webkit-scrollbar-thumb:window-inactive {
      background: var(--dim-gray);
    }
  }

  body, input, button, textarea{
    font: 14px 'Open Sans', sans-serif !important;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: var(--background-alt);
    }
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    overflow-x: hidden;
  }

  :root {

    ${'' /* --primary: #5C69E1; */}
    --primary: #4DA775;
    --secondary: #E780BE;

    --blue-300: #7D90FF;
    --cyan-500: #00b8fa;

    --dim-gray: #e7e7e7;
    --dark-gray: #3D4043;
    --black: #000000;
    --light-gray: #7f7f7f;
    --pending-gray: #6E7884;
    --background: #E0F8F3;
    --background-alt: #fff;

    --green: #00C875;
    --red: #E2445C;
    --orange: #fd6e36;
    --yellow: #fece00;
    --blue: #4274ff;
    --font-color: #323232;

    --disabled: #8e98e5;
    --hover-primary: #82D4A0;
    --hover-secondary: #ff9ae3;
    --hover-green: #15962e;
    --hover-orange: #d85c2f;
    --hover-red: #ba3741;
    --hover-white: #FFFFFF4a;

    --shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    --modal-background: rgba(0, 0, 0, 0.5);

    --transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --transform-scale: scale(1.1);

    --filter-disabled: saturate(80%) grayscale(20%);

    --br-sm: 4px;
  }

  .react-calendar {
    box-shadow: var(--shadow);
    border: none;
    border-top: 13px solid var(--primary);
    border-radius: 4px;
  }

  .react-calendar__navigation {
    height: 30px;
    margin-bottom: 0.5em;
  }

  .react-calendar__tile {
    color: var(--dark-gray);
    max-width: 100%;
    text-align: center;
    padding: 0.5em 0.15em;
    background: none;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--light-gray);
  }

  .react-calendar__month-view__days__day--weekend {
    color: var(--orange);
  }

  .react-calendar__tile--now {
    background: var(--primary);
    color: white;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: var(--hover-primary);
  }

  .react-calendar__tile--active {
    background: var(--blue);
    color: white;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: var(--blue);
  }
`;
