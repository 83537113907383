export default {
  animation: false,
  cornerRadius: 20,
  layout: { padding: 0 },
  legend: { display: false },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    xAxes: [
      {
        maxBarThickness: 30,
        barPercentage: 0.75,
        categoryPercentage: 0.75,
        ticks: {
          fontColor: '#656565',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: '#656565',
          beginAtZero: true,
          min: 0,
          callback(value) {
            return value.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            });
          },
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: '#e7e7e7',
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: '#e7e7e7',
        },
      },
    ],
  },
  tooltips: {
    backgroundColor: '#fff',
    bodyFontColor: '#656565',
    borderColor: '#7f7f7f',
    borderWidth: 1,
    enabled: true,
    footerFontColor: '#656565',
    intersect: false,
    mode: 'index',
    titleFontColor: '#656565',
  },
};
