import React, { useState, useRef, useCallback, useEffect } from 'react';

import { validateInputs } from './actions';

import ProgressModal from '~/components/Dashboard/Modals/ProgressModal';

import { Label } from '~/components/UI/Text';
import { IText } from '~/components/UI/Inputs';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider } from '~/components/ChakraComponents';

import ContractorsModal from './ContractorsModal';

import { Modal } from '~/components/UI/Modal';

const noContrator = { id: -1, name: 'Nenhum' };

export default function ContractGroupModal({ type, selectedGroup }) {
  const [name, setName] = useState(selectedGroup?.name || '');
  const [contractor, setContractor] = useState(
    selectedGroup?.contractor || noContrator
  );
  const [loading, setLoading] = useState(false);

  const contractorsModalRef = useRef(null);

  const openContractorsModal = useCallback(() => {
    contractorsModalRef.current.openModal();
  }, []);

  useEffect(() => {
    if (loading) {
      const isValid = validateInputs({
        name,
        type,
        groupId: selectedGroup?.id,
        contractorId: contractor.id,
      });

      if (!isValid) setLoading(false);
    }
  }, [loading]);

  const removeContractor = useCallback(() => {
    setContractor(noContrator);
  }, []);

  return (
    <ModalContainer w="240px">
      <IText
        required
        label="nome"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Divider />
      <Label>Contratante: {contractor.name}</Label>
      <Button
        title="escolher contratante"
        width="100%"
        onClick={openContractorsModal}
      />
      {contractor.id !== -1 && (
        <Button
          title="remover contratante"
          width="100%"
          variant="danger"
          onClick={removeContractor}
        />
      )}
      <Divider />
      <Button
        title="confirmar"
        width="100%"
        variant="success"
        onClick={() => setLoading(true)}
      />
      <Modal
        title="Contratantes"
        ref={contractorsModalRef}
        component={ContractorsModal}
        setContractor={setContractor}
      />
      <ProgressModal
        loading={loading}
        text="Por favor aguarde, isso pode demorar alguns minutos."
      />
    </ModalContainer>
  );
}
