import React from 'react';
import { HStack } from '@chakra-ui/react';

import moment from 'moment';
import ListLinks  from '~/components/ListLinks';

const startMonth = moment(new Date())
  .startOf('month')
  .format('YYYY-MM-DD');

const endMonth = moment(new Date())
  .endOf('month')
  .format('YYYY-MM-DD');

export default function RelatoryModal() {
  return (
    <HStack>
    <ListLinks
      options={
        [
          {
            title: 'relatório mensal',
            to: '/company/reports/month',
          },
          {
            title: 'relatório anual',
            to: '/company/reports/year',
          },
          {
            title: 'relatório médico',
            to: `/company/reports/doctorPay?offset=0&limit=10&startDate=${startMonth}&endDate=${endMonth}`,
          },
          {
            title: 'relatório médico Resumido',
            to: `/company/reports/resumeDoctorPay?offset=0&limit=10&startDate=${startMonth}&endDate=${endMonth}`,
          },
        ]
      }
    />
    </HStack>
  );
}
