import styled from 'styled-components';

export const ContainerWeb = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  background-color: var(--dark-gray);

  @media (min-width: 375px) and (max-width: 414px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SectionUteis = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SectionList = styled.div`
  display: flex;
  margin-right: 30px;

  @media (min-width: 768px) and (max-width: 800px) {
    display: block;
  }
`;

export const TitleUteis = styled.span`
  font-family: Montserrat;
  font-size: 22px;
  margin-right: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--background);

  @media (min-width: 768px) and (max-width: 800px) {
    font-size: 15px;
    text-align: left;
    padding-left: 20px;
    width: 100px;
  }
`;

export const ListAll = styled.ul`
  display: flex;
  align-items: center;
  text-decoration: none;

  @media (min-width: 768px) and (max-width: 800px) {
    width: 300px;
    padding-top: 10px;
    padding-left: 15px;
  }
`;

export const ListItem = styled.li`
  display: flex;
  padding: 0 5px;

  a {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: var(--background);
  }
`;

export const SectionCopyright = styled.div`
  display: flex;
  margin-right: 50px;
`;

export const TitleCopyright = styled.span`
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--background);
`;

export const ContainerMobile = styled.div`
  display: none;

  @media (min-width: 375px) and (max-width: 414px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: var(--dark-gray);
  }
`;

export const SectionSociais = styled.div`
  display: flex;
`;

export const TitleSociais = styled.span`
  @media (min-width: 375px) {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: var(--background);
    margin-bottom: 10px;
  }
`;
