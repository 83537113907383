import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from '@chakra-ui/react';

const AnimationContainer = styled.div`
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  transition: var(--transition);

  h1,
  p {
    color: #718096;
    transition: var(--transition);
  }

  &:hover {
    transform: var(--transform-scale);
    z-index: 50;

    box-shadow: var(--shadow);

    h1 {
      transform: var(--transform-scale);
    }

    p {
      transform: var(--transform-scale);
      text-decoration: underline;
    }

    h1,
    p {
      color: var(--secondary);
    }
  }
`;

export default function Info({ onClick, header, color, quantity }) {
  return (
    <Flex
      onClick={onClick}
      pos="relative"
      w="100%"
      h={['120px', '120px', '120px', '160px']}
    >
      <AnimationContainer>
        <Flex
          h="36px"
          alignItems="center"
          justifyContent="center"
          backgroundColor={color}
        >
          <Text
            as="h3"
            fontSize="xs"
            color="white"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {header}
          </Text>
        </Flex>
        <Flex
          h="100%"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          backgroundColor="white"
          fontWeight="bold"
        >
          <Text as="h1" fontSize="3xl">
            {quantity}
          </Text>
          <Text as="p" fontSize="sm" mt={2}>
            {quantity === 1 ? 'Plantão' : 'Plantões'}
          </Text>
        </Flex>
      </AnimationContainer>
    </Flex>
  );
}
