import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';

import api from '~/services/api';
import phoneMask from '~/utils/phoneMask';
import defaultAvatar from '~/constants/defaultAvatar';
import { getParam } from '~/utils/url';

import Buttons from './Buttons';

import { UserInfo } from '~/components/UI/Layouts/';

export default function PageLayout({
  style,
  disableDefaultButtons,
  userInfoButtons,
  children,
}) {
  const [title, setTitle] = useState('');
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [subtitle, setSubtitle] = useState('');

  const { hospitalId } = getParam('hospitalId');

  useEffect(() => {
    api.get(`/companies/myhospitals/${hospitalId}`).then(response => {
      setTitle(response.data.name);
      setAvatar(response.data.avatar);
      setSubtitle(phoneMask(response.data.phone1));

      localStorage.setItem('hospitalData', JSON.stringify(response.data));
    });
  }, []);

  return (
    <Flex
      overflowY="hidden"
      w="100%"
      h="100%"
      minH={[
        'calc(100vh - 40px)',
        'calc(100vh - 40px)',
        'calc(100vh - 40px)',
        'calc(100vh - 81px)',
      ]}
      p={3}
      style={style}
    >
      <Flex
        display={['none', 'none', 'none', 'flex']}
        w="350px"
        flexDir="column"
        flexShrink="none"
        alignItems="center"
      >
        <UserInfo
          title={title}
          avatar={avatar}
          subtitle={subtitle}
          avatarUploadUrl={`/companies/myhospitals/${hospitalId}`}
        />
        <Buttons disableDefaultButtons={disableDefaultButtons}>
          {userInfoButtons}
        </Buttons>
      </Flex>
      <Flex w="100%" h="100%" p={3} flexDir="column" overflowY="scroll">
        {children}
      </Flex>
    </Flex>
  );
}
