import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Autogrid, Shimmer } from '~/components/Kiwistrap/Page';

const quantity = [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

export default function ProfileShimmer() {
  return (
    <Flex flexDir="column">
      <Flex justify="center">
        <Shimmer width="320px" height="16px" />
      </Flex>
      <Autogrid childrenSize="200px" mt={2}>
        {quantity.map(val => (
          <Shimmer key={val} width="200px" height="200px" />
        ))}
      </Autogrid>
    </Flex>
  );
}
