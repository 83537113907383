export default phone => {
  if (!phone) return '';

  const phoneArr = phone.split('');

  phoneArr.splice(2, 0, ' ');
  phoneArr.splice(phoneArr.length - 4, 0, '-');
  phoneArr.splice(0, 0, '(');
  phoneArr.splice(3, 0, ')');

  return phoneArr.join('');
};
