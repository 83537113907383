import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@chakra-ui/react';
import { BsGraphUp } from 'react-icons/bs';
import { FaDesktop, FaUsers, FaUserMd, FaHospitalAlt } from 'react-icons/fa';

import api from '~/services/api';
import MeuPlantaoLogo from '~/assets/logo/LogoWhite.png';

import IsAllowed from '~/components/UI/IsAllowed';

import { SubTitle } from '~/components/Kiwistrap/Page';

import {
  LogoLink,
  LogoImg,
  Navigation,
  PageLink,
  NotificationsSection,
  BellContainer,
  Bell,
  NotificationMenuBackground,
  NotificationsMenu,
  NotificationsMenuItem,
} from './styles';

const Nav = () => {
  const [unread, setUnread] = useState(0);
  const [notifications, setNotifications] = useState();
  const [openNotifications, setOpenNotifications] = useState(false);

  const fetchNotifications = useCallback(() => {
    api.get('/companies/notifications?limit=50').then(response => {
      const { data } = response.data;

      let unread = 0;

      data.forEach(notification => {
        if (!notification.read) unread += 1;
      });

      setUnread(unread);
      setNotifications(data);

      //setTimeout(() => fetchNotifications(), 300000);
    });
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const toggleNotifications = useCallback(async () => {
    setOpenNotifications(!openNotifications);

    const nonReadIds = [];

    notifications.forEach(notification => {
      if (notification.read === false) return nonReadIds.push(notification.id);
    });

    const promises = nonReadIds.map(async id => {
      return await api.patch(`/companies/notifications/read/${id}`);
    });

    await Promise.all(promises);

    fetchNotifications();
  }, [openNotifications, notifications, fetchNotifications]);

  const handleBackgroundOnClick = useCallback(e => {
    if (e.target.id === 'notifications-background') {
      setOpenNotifications(false);
    }
  }, []);

  return (
    <Grid
      display={['none', 'none', 'none', 'grid']}
      w="100%"
      h="81px"
      backgroundColor="var(--background-alt)"
      templateColumns="170px auto 90px"
      alignItems="center"
    >
      <LogoLink to="/company/dashboard">
        <LogoImg src={MeuPlantaoLogo} alt="meu-plantao" />
      </LogoLink>
      <Navigation>
        <IsAllowed id={1}>
          <PageLink
            text="Dashboard"
            to="/company/dashboard"
            selected={window.location.pathname === '/company/dashboard'}
          >
            <FaDesktop />
          </PageLink>
        </IsAllowed>
        <IsAllowed id={2}>
          <PageLink
            text="Meus Hospitais"
            to="/company/myhospitals"
            selected={window.location.pathname === '/company/myhospitals'}
          >
            <FaHospitalAlt />
          </PageLink>
        </IsAllowed>
        <IsAllowed id={6}>
          <PageLink
            text="Meus Médicos"
            to="/company/mydoctors"
            selected={window.location.pathname === '/company/mydoctors'}
          >
            <FaUserMd />
          </PageLink>
        </IsAllowed>
        <IsAllowed id={9}>
          <PageLink
            text="Usuários"
            to="/company/users"
            selected={window.location.pathname === '/company/users'}
          >
            <FaUsers />
          </PageLink>
        </IsAllowed>
        <IsAllowed id={13}>
          <PageLink
            text="Controle de Gastos"
            to="/company/spendcontrol"
            selected={[
              '/company/spendcontrol',
              '/company/doctorspending',
              '/company/employeecontrol',
              '/company/expenses',
              '/company/expectedcosts',
              '/company/spendcontrol',
              '/company/reports/month',
              '/company/reports/year',
              '/company/aprovevacancy',
              '/company/reports/doctorPay',
            ].includes(window.location.pathname)}
          >
            <BsGraphUp />
          </PageLink>
        </IsAllowed>
      </Navigation>
      <NotificationsSection>
        <BellContainer
          unread={unread > 0 && unread}
          onClick={toggleNotifications}
        >
          <Bell />
        </BellContainer>
        {openNotifications && (
          <NotificationMenuBackground
            id="notifications-background"
            onClick={handleBackgroundOnClick}
          >
            <NotificationsMenu>
              <SubTitle
                style={{
                  padding: 10,
                  borderBottom: '2px solid var(--secondary',
                }}
              >
                Notificações
              </SubTitle>
              {notifications &&
                notifications.map(notification => (
                  <NotificationsMenuItem key={notification.id}>
                    {notification.content}
                    <p>
                      {moment(new Date(notification.updatedAt)).format(
                        'HH:mm - DD/MM'
                      )}
                    </p>
                  </NotificationsMenuItem>
                ))}
            </NotificationsMenu>
          </NotificationMenuBackground>
        )}
      </NotificationsSection>
    </Grid>
  );
};

export default Nav;
