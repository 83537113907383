import React from 'react';

import { Container, CleaveContainer } from './styles';

export default function InputPrimary({
  name,
  type,
  value,
  placeholder,
  onChange,
  onKeyDown,
  useCleave,
  options,
  required,
}) {
  return (
    <>
      {!useCleave ? (
        <Container
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
          onKeyDown={onKeyDown}
        />
      ) : (
        <CleaveContainer
          name={name}
          type={type}
          value={value}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
        />
      )}
    </>
  );
}
