import React from 'react';

import { Container, Content } from './styles';

export default function InputArea({ name, type, placeholder, rows, onChange }) {
  return (
    <Container>
      <Content
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        rows={rows}
      />
    </Container>
  );
}
