import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';

import api from '~/services/api';
import history from '~/services/history';
import removeMask from '~/utils/removeMask';
import internalServerError from '~/utils/internalServerError';
import onlyNumberHandleChangeCep from '~/utils/cepMaskWithAscii';
import onlyNumberHandleChangeCnpj from '~/utils/cnpjMaskWithAscii';

import PageLayout from '~/pages/_layouts/company/PageLayout';

import { Button } from '~/components/ChakraComponents/';
import { ITitle, IRow, ICol, IText } from '~/components/UI/Inputs';

export default function EditAccount() {
  const [inputs, setInputs] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);

  const handleChanges = e => {
    if (e.target.name === 'cep') {
      return setInputs({
        ...inputs,
        [e.target.name]: onlyNumberHandleChangeCep(e.target.value),
      });
    }
    if (e.target.name === 'cnpj') {
      return setInputs({
        ...inputs,
        [e.target.name]: onlyNumberHandleChangeCnpj(e.target.value),
      });
    }
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    api.get('/companies/companyprofile').then(response => {
      const {
        name,
        socialReason,
        cnpj,
        phone1,
        phone2,
        email,
        cep,
        uf,
        city,
        address,
        street,
        number,
        complement,
      } = response.data;

      setInputs({
        name,
        socialReason,
        cnpj,
        phone1,
        phone2,
        email,
        cep,
        uf,
        city,
        address,
        street,
        number,
        complement,
      });

      setLoadingPage(false);
    });
  }, []);

  const handleSubmit = () => {
    const request = { ...inputs };

    request.cep = removeMask(request.cep);
    request.cnpj = removeMask(request.cnpj);
    request.phone1 = removeMask(request.phone1);
    request.phone2 = removeMask(request.phone2);

    api
      .patch('/companies/companyprofile', request)
      .then(() => {
        history.push('/company/profile');
        toast.success('Perfil editado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  };

  return (
    <PageLayout loading={loadingPage}>
      <Container>
        <IRow>
          <ICol>
            <ITitle>Dados</ITitle>
            <IText
              label="nome"
              required
              name="name"
              value={inputs.name}
              placeholder="Ex: Empresa"
              onChange={handleChanges}
            />
            <IText
              label="e-mail"
              required
              type="email"
              name="email"
              value={inputs.email}
              onChange={handleChanges}
            />
            <IText
              label="razão social"
              required
              name="socialReason"
              value={inputs.socialReason}
              placeholder="Ex: Empresa SA"
              onChange={handleChanges}
            />
            <IText
              label="cnpj"
              required
              name="cnpj"
              value={onlyNumberHandleChangeCnpj(inputs.cnpj || '')}
              placeholder="Ex: 00.000.000/0000-00"
              onChange={handleChanges}
            />
            <IText
              label="telefone 01"
              required
              name="phone1"
              value={inputs.phone1}
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              placeholder="Ex: 11 99987 5542"
              onChange={handleChanges}
            />
            <IText
              label="telefone 02"
              name="phone2"
              value={inputs.phone2}
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              placeholder="Ex: 11 99987 5542"
              onChange={handleChanges}
            />
          </ICol>
          <ICol>
            <ITitle>Endereço</ITitle>
            <IText
              label="cep"
              required
              name="cep"
              value={onlyNumberHandleChangeCep(inputs.cep || '')}
              placeholder="Ex: 13097-123"
              onChange={handleChanges}
            />
            <IText
              label="rua"
              required
              name="street"
              value={inputs.street}
              placeholder="Ex: Rua Oliveira"
              onChange={handleChanges}
            />
            <IRow>
              <IText
                label="uf"
                required
                name="uf"
                maxLength="2"
                value={inputs.uf}
                placeholder="Ex: RJ"
                onChange={handleChanges}
              />

              <IText
                label="cidade"
                required
                name="city"
                value={inputs.city}
                onChange={handleChanges}
              />
            </IRow>
            <IRow>
              <IText
                label="bairro"
                required
                name="address"
                value={inputs.address}
                onChange={handleChanges}
              />
              <IText
                label="número"
                required
                type="number"
                name="number"
                value={inputs.number}
                onChange={handleChanges}
              />
            </IRow>
            <IText
              label="complemento"
              name="complement"
              value={inputs.complement}
              placeholder="Ex: Próx. ao parque"
              onChange={handleChanges}
            />
          </ICol>
        </IRow>
        <Flex mt={8} justifyContent="space-evenly">
          <Button title="cancelar" variant="secondary" to="/company/profile" />
          <Button title="editar" variant="success" onClick={handleSubmit} />
        </Flex>
      </Container>
    </PageLayout>
  );
}
