import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 10px 20px 10px 0;
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  font-size: 24px;
  font-weight: bold;

  color: var(--primary);

  img {
    width: 40px;
    height: 40px;

    margin-right: 16px;

    border: 3px solid var(--primary);
    border-radius: 50%;

    background-color: var(--background-alt);

    flex-shrink: none;
    object-fit: cover;
  }
`;

export const ViewMenu = styled.div`
  margin-top: 12px;

  display: flex;
  align-items: center;

  color: var(--secondary);

  font-weight: bold;
  text-transform: uppercase;

  cursor: pointer;

  svg {
    margin-right: 7px;
  }
`;
