import React from 'react';
import { Route } from 'react-router-dom';

import history from '~/services/history';
import leaveHospital from '~/utils/leaveHospital';
import isUserAllowedToViewPage from '~/services/isUserAllowedToViewPage';

import { getUser } from '~/services/auth';
import { getParam } from '~/utils/url';

import HospitalLayout from '~/pages/_layouts/contractorhospital';

export default function ContractorHospitalRouteWrapper({
  tag,
  component: Component,
  ...rest
}) {
  const user = getUser();
  const { hospitalId } = getParam('hospitalId');

  // if (!user) {
  //   history.push('/');
  //   return window.location.reload();
  // }

  // if (user.type === 'doctor') {
  //   history.push('/medico/dashboard');
  //   return window.location.reload();
  // }

  // if (hospitalId) {
  //   leaveHospital();
  //   return window.location.reload();
  // }

  // if (!isUserAllowedToViewPage('hospitalPermissions', tag)) {
  //   if (tag === 1) {
  //     leaveHospital();
  //     return window.location.reload();
  //   } else {
  //     history.push(`/hospital/dashboard/?hospitalId=${hospitalId}`);
  //   }
  // }

  return (
    <Route
      {...rest}
      render={props => (
        <HospitalLayout>
          <Component {...props} />
        </HospitalLayout>
      )}
    />
  );
}
