import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useBoolean, Flex, Text } from '@chakra-ui/react';

import api from '~/services/api';
import getContracts from '~/utils/api/getContracts';
import capitalizeString from '~/utils/capitalizeString';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import Legend from '../Legend';
import { IText } from '~/components/UI/Inputs';
import { DragNDrop } from '~/components/UI/Sections';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider } from '~/components/ChakraComponents';

export default function CreateScheduleGroupModal({ closeModal }) {
  const [name, setName] = useState('');
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useBoolean(true);

  const { hospitalId } = getParam('hospitalId');

  useEffect(() => {
    getContracts(
      contracts => {
        setLoading.off();

        setLists([
          {
            title: 'Plantões',
            cards: contracts,
          },
          {
            title: 'Selecionados',
            cards: [],
          },
        ]);
      },
      { activeOnly: true }
    );
  }, []);

  const createGroup = () => {
    if (name.length < 2) {
      return toast.error('Por favor digite um nome do grupo válido.');
    }

    if (lists[1].cards.length === 0) {
      return toast.error('Por favor escolha pelo menos 1 plantão.');
    }

    const request = {
      name: capitalizeString(name),
      schedules: lists[1].cards.map(schedule => schedule.schedule.id),
    };

    const url = `/companies/myhospitals/${hospitalId}/schedulesgroup`;

    api
      .post(url, request)
      .then(() => {
        toast.success('Grupo criado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  };

  return (
    <ModalContainer loading={loading}>
      <IText
        required
        label="nome do grupo"
        placeholder="Ex: UTI"
        maxLength="64"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Legend />
      <DragNDrop
        startingLists={lists}
        updateLists={setLists}
        card={(provided, snapshot, item) => (
          <Text
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            w="100%"
            p="0.5rem"
            mb="0.5rem"
            bgColor="var(--background-alt)"
            borderRadius="4px"
            userSelect="none"
            fontSize={['0.7rem', '0.7rem', '0.7rem', '0.8rem']}
            color={snapshot.isDragging ? 'gray' : 'black'}
            border={
              snapshot.isDragging
                ? '1px solid var(--secondary)'
                : '1px solid var(--dim-gray)'
            }
          >
            {item.name.split('-')[0]}
            <br />
            {item.name.split('-')[1]}
            <br />
            {item.name.split('-')[2]}
          </Text>
        )}
      />
      <Divider />
      <Flex justify="space-between">
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <Button title="criar" variant="success" onClick={createGroup} />
      </Flex>
    </ModalContainer>
  );
}
