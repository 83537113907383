import React, { useState, useEffect } from 'react';

import api from '~/services/api';

import PageLayout from '~/pages/_layouts/doctor/PageLayout';

import { Div, Row, Title } from '~/components/Kiwistrap/Page';

import alphabet from '~/constants/alphabet';

import {
  DocumentList,
  MaButton,
  ItemList,
  Letter,
  DownloadSvg,
} from './styles';

export default function Protocols() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [protocols, setProtocols] = useState([]);
  const [currentLetter, setCurrentLetter] = useState('');

  useEffect(() => {
    (async () => {
      const response = await api.get('/doctors/protocols?limit=8');

      setProtocols(response.data.data);
      setLoadingPage(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await api.get(
        `/doctors/protocols?limit=8&startName=${currentLetter}`
      );

      setProtocols(response.data.data);
    })();
  }, [currentLetter]);

  const handleSelectLetter = letter => {
    if (letter === currentLetter) {
      return setCurrentLetter('');
    }

    return setCurrentLetter(letter);
  };

  return (
    <PageLayout centerTitle title="Protocolos" loading={loadingPage}>
      <Div alignItems="center">
        <MaButton
          target="_blank"
          href="https://www.saude.gov.br/protocolos-e-diretrizes"
        >
          Protocolos e diretrizes
        </MaButton>
        <Row margin="20px 0" justifyContent="center">
          {alphabet.map(letter => (
            <Letter
              key={letter}
              isChoosen={currentLetter === letter}
              onClick={() => handleSelectLetter(letter)}
            >
              {letter}
            </Letter>
          ))}
        </Row>
      </Div>
      {protocols.length <= 0 && <Title center> Não há nenhum protocolo!</Title>}
      <DocumentList>
        {protocols.map(protocol => (
          <ItemList key={protocol.id}>
            <div className="item-details">
              <a
                href={`https://verde-azul-imoveis.s3.amazonaws.com/${protocol.bucketName}`}
              >
                {protocol.name}:
              </a>
              <span>{protocol.description}</span>
            </div>
            <a href={protocol.fileUrl} target="_blank">
              <DownloadSvg />
            </a>
          </ItemList>
        ))}
      </DocumentList>
    </PageLayout>
  );
}
