import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 92px);

  @media (min-width: 375px) and (max-width: 414px) {
    height: auto;
    margin-top: 40px;
  }

  /* border: 1px solid red; */
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  /* justify-content: center; */
  padding: 0 0 0 80px;

  @media (min-width: 375px) and (max-width: 414px) {
    flex-direction: column;
    padding: 0px 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 20px;
  }

  /* border: 1px solid blue; */
`;
export const SectionInformations = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 80px;

  @media (min-width: 375px) and (max-width: 414px) {
    /* border: 1px solid blue; */
    margin: 0px 0px 40px 0px;
  }
`;
export const TitleInformations = styled.span`
  font-family: Montserrat;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: var(--secondary);
  margin-bottom: 20px;

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 19px;
  }
`;
export const DescriptionInformation = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--dark-gray);
  margin-bottom: 40px;
`;
export const TitleHours = styled.span`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--primary);
  margin-bottom: 20px;
`;
export const DescriptionHours = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--dark-gray);
  margin-bottom: 20px;
`;
export const TitleAddress = styled.span`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--primary);
  margin-bottom: 20px;
`;
export const DescriptionAddress = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--dark-gray);
`;
export const SectionForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 720px;
  height: 500px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--background-alt);
  padding: 0 100px;

  @media (min-width: 375px) and (max-width: 414px) {
    width: 320px;
    padding: 0px 20px;
    margin-bottom: 50px;

    /* border: 1px solid red; */
  }

  /* border: 1px solid red; */
`;
export const TitleForm = styled.span`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: var(--secondary);
  margin-bottom: 20px;
`;
export const NameInput = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--primary);
  padding: 5px 0;
`;
export const SectionBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @media (min-width: 375px) and (max-width: 414px) {
    justify-content: center;
  }
`;
