import React, { useRef, useCallback } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import ContractGroupModal from '../../ContractGroupModal';
import CreateContractModal from './CreateContractModal';

import IsAllowed from '~/components/UI/IsAllowed';
import { Modal } from '~/components/UI/Modal';
import { Button, Popover } from '~/components/ChakraComponents/';

import { CreateSvg, EditSvg } from './styles';
import { handleContractGroupIsActive } from './actions';

export default function ContractsNav({ group }) {
  const contractGroupModalRef = useRef(null);
  const createContractModalRef = useRef(null);

  const openCreateContract = useCallback(() => {
    createContractModalRef.current.openModal();
  }, []);

  const openContractGroup = useCallback(() => {
    contractGroupModalRef.current.openModal();
  }, []);

  return (
    <Flex justify="center">
      <IsAllowed id={3}>
        <Flex
          onClick={openCreateContract}
          w="auto"
          cursor="pointer"
          align="center"
          color="var(--primary)"
          _hover={{ textDecor: 'underline' }}
        >
          <CreateSvg />
          <Text ml={2} fontWeight="semibold">
            Novo Plantão
          </Text>
        </Flex>
      </IsAllowed>
      <IsAllowed id={4}>
        <Flex
          onClick={openContractGroup}
          w="auto"
          mx={12}
          cursor="pointer"
          align="center"
          color="var(--primary)"
          _hover={{ textDecor: 'underline' }}
        >
          <EditSvg />
          <Text ml={2} fontWeight="semibold">
            Editar Contrato
          </Text>
        </Flex>
      </IsAllowed>
      <IsAllowed id={5}>
        <Popover
          trigger={
            <Flex
              w="auto"
              cursor="pointer"
              align="center"
              color={group.isActive ? 'var(--red)' : 'var(--green)'}
              _hover={{ textDecor: 'underline' }}
            >
              <Flex
                cursor="pointer"
                w="18px"
                h="18px"
                mt="2px"
                border="2px"
                borderStyle="solid"
                borderColor="var(--background)"
                borderRadius="full"
                backgroundColor={group.isActive ? 'var(--red)' : 'var(--green)'}
              />
              <Text ml={2} fontWeight="semibold">
                {group.isActive ? 'Desativar ' : 'Ativar '}
                Contrato
              </Text>
            </Flex>
          }
          body={
            <Button
              title={group.isActive ? 'desativar' : 'ativar'}
              variant={group.isActive ? 'danger' : 'success'}
              width="140px"
              height="40px"
              onClick={() => handleContractGroupIsActive(group)}
            />
          }
        />
      </IsAllowed>
      <Modal
        title="Editar Contrato"
        ref={contractGroupModalRef}
        component={ContractGroupModal}
        type="edit"
        selectedGroup={group}
      />
      <Modal
        title="Novo Plantão"
        ref={createContractModalRef}
        component={CreateContractModal}
        group={group}
      />
    </Flex>
  );
}
