import React, { useRef, useCallback } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FaPlusCircle as AddSvg } from 'react-icons/fa';

import ContractGroupModal from '../ContractGroupModal';

import IsAllowed from '~/components/UI/IsAllowed';
import { Modal } from '~/components/UI/Modal';

export default function CreateContractGroup() {
  const groupModalRef = useRef(null);

  const openGroup = useCallback(() => {
    groupModalRef.current.openModal();
  }, []);

  return (
    <IsAllowed id={3}>
      <Flex
        cursor="pointer"
        align="center"
        color="var(--primary)"
        fontWeight="bold"
        _hover={{ textDecor: 'underline' }}
        onClick={openGroup}
      >
        <AddSvg fontSize="24px" />
        <Text ml={2} fontSize="lg">
          Novo Contrato
        </Text>
      </Flex>
      <Modal
        title="Novo Contrato"
        ref={groupModalRef}
        component={ContractGroupModal}
        type="create"
        selectedGroup={null}
      />
    </IsAllowed>
  );
}
