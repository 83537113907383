import React from 'react';
import { useParams } from 'react-router-dom';

import Week from './Week';
import Month from './Month';

export default function DesktopAgenda({ baseUrl, baseUpdateUrl, cardOnClick }) {
  const { scheduleType } = useParams();

  return (
    <>
      {scheduleType === 'week' && (
        <Week
          baseUrl={baseUrl}
          baseUpdateUrl={baseUpdateUrl}
          cardOnClick={cardOnClick}
        />
      )}
      {scheduleType === 'month' && (
        <Month baseUrl={baseUrl} cardOnClick={cardOnClick} />
      )}
    </>
  );
}
