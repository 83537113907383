import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 4500;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 200px;
  background: var(--background-alt);
  border-radius: var(--br-sm);
  box-shadow: var(--shadow);
  padding: 20px;
`;

export const FileName = styled.span`
  font-weight: 500;
  text-align: center;
  margin: 20px 0;
`;

export const ProgressBar = styled.div`
  margin: 15px 0;
  .material-spinner {
    height: 50px;
    min-height: 50px;
    width: 50px;
    border-radius: 100px;
    border: 5px transparent solid;
    border-top: 5px #3f51b5 solid;
    animation: material-spinner 2s infinite;
  }

  @keyframes material-spinner {
    0% {
      transform: rotate(0deg);
      border-top: 5px #3f51b5 solid;
    }
    25% {
      transform: rotate(360deg);
      border-top: 5px #f44336 solid;
    }
    50% {
      transform: rotate(720deg);
      border-top: 5px #ffc107 solid;
    }
    75% {
      transform: rotate(1080deg);
      border-top: 5px #4caf50 solid;
    }
    100% {
      transform: rotate(1440deg);
      border-top: 5px #3f51b5 solid;
    }
  }
`;
