import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

export default (avatar, url) => {
  if (!avatar || !url) return;

  toast.success('Realizando upload...');

  const formData = new FormData();

  formData.append('file', avatar);

  api.post('/avatar/', formData).then(response => {
    const request = { avatar: response.data[0].filename };

    api
      .patch(url, request)
      .then(() => {
        toast.success('Upload realizado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  });
};
