import React from 'react';
import { VStack } from '@chakra-ui/react';

import { Divider } from '~/components/ChakraComponents';

export default function RoundList({ children }) {
  return (
    <VStack
      align="strech"
      border="1px solid var(--dim-gray)"
      borderRadius="md"
      bgColor="var(--background-alt)"
      divider={<Divider my={0} />}
      spacing={0}
    >
      {children}
    </VStack>
  );
}
