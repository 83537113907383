import userKey from '~/utils/userKey';

import {
  getCompanyPages,
  getContractorPages,
  getCompanyHospitalPages,
  getContractorHospitalPages,
} from './pages';

const setPages = () => {
  const key = userKey();

  return {
    company: () => getCompanyPages(),
    contractor: () => getContractorPages(),
    companyhospital: () => getCompanyHospitalPages(),
    contractorhospital: () => getContractorHospitalPages(),
  }[key]();
};
export const getPages = () => {
  const pages = setPages();

  return pages;
};
