import React from 'react';
import { Container, Header, Legend, Color, Text } from './styles';

function Legends() {
  return (
    <Container>
      <Header>Legendas</Header>
      <Legend>
        <Color width="24px" color="var(--green)" />
        <Text>Aberta</Text>
      </Legend>
      <Legend>
        <Color borderRadius="50%" color="var(--green)" />
        <Text>Confirmado</Text>
      </Legend>
      <Legend>
        <Color width="24px" color="var(--primary)" />
        <Text>Plantão Fixo</Text>
      </Legend>
      <Legend>
        <Color borderRadius="50%" color="var(--yellow)" />
        <Text>Pendente</Text>
      </Legend>
      <Legend>
        <Color width="24px" color="var(--orange)" />
        <Text>Cobertura</Text>
      </Legend>
      <Legend>
        <Color borderRadius="50%" color="var(--red)" />
        <Text>Expirado</Text>
      </Legend>
      <Legend>
        <Color width="24px" color="var(--secondary)" />
        <Text>Vaga Adicional</Text>
      </Legend>
    </Container>
  );
}

export default React.memo(Legends);
