import React from 'react';

import { Container } from './styles';

export default function InputSelect({
  name,
  type,
  options,
  onChange,
  required,
}) {
  return (
    <Container name={name} type={type} onChange={onChange} required={required}>
      <option value="">Selecione...</option>
      {options.map(opt => (
        <option key={opt.value} value={opt.value}>
          {opt.text}
        </option>
      ))}
    </Container>
  );
}
