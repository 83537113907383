import styled from 'styled-components';

export const Container = styled.div`
  width: 50vw;
  height: 50vh;

  overflow: auto;
`;

export const Hospital = styled.div`
  padding: 10px 0;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #e7e7e7;

  img {
    width: 50px;
    height: 50px;

    margin-right: 28px;

    border: 3px solid var(--primary);
    border-radius: 50%;

    flex-shrink: none;
    object-fit: cover;
  }

  h1 {
    color: var(--primary);
  }
`;
