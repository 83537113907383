import { Dashboard as HospitalDashboard } from '~/components/UI/Pages';

import {
  HospitalMyContracts,
  HospitalProfile,
  HospitalProtocols,
  HospitalVacanciesV2,
  HospitalVacanciesScheduleGroupNoneChoosen,
  HospitalVacanciesScheduleGroup,
} from '~/pages/hospital';

export default [
  {
    tag: 1,
    path: '/hospital/dashboard',
    component: HospitalDashboard,
  },
  {
    tag: 1,
    path: '/hospital/profile',
    component: HospitalProfile,
  },
  {
    tag: 2,
    path: '/hospital/mycontracts',
    component: HospitalMyContracts,
  },
  {
    tag: 14,
    path: '/hospital/protocols',
    component: HospitalProtocols,
  },
  {
    tag: 6,
    path: '/hospital/vacancies',
    component: HospitalVacanciesV2,
    exact: true,
  },
  {
    tag: 6,
    path: '/hospital/vacancies/schedulegroup/',
    component: HospitalVacanciesScheduleGroupNoneChoosen,
    exact: true,
  },
  {
    tag: 6,
    path:
      '/hospital/vacancies/schedulegroup/:groupId/:scheduleType/:vacancyDate',
    component: HospitalVacanciesScheduleGroup,
    exact: true,
  },
];
