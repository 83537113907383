import React, { useState, createContext, useContext } from 'react';
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addWeeks,
  addMonths,
} from 'date-fns';
/** Services */
import api from '../../../services/api';

const ViewSchedulesContext = createContext();

export function ViewSchedulesProvider({ children }) {
  const today = new Date();

  const [weekStart, setWeekStart] = useState(startOfWeek(today));
  const [weekEnd, setWeekEnd] = useState(endOfWeek(today));
  const [monthStart, setMonthStart] = useState(startOfMonth(today));
  const [monthEnd, setMonthEnd] = useState(endOfMonth(today));

  const [scheduleType, setScheduleType] = useState('week');
  const [vacanciesDays, setVacanciesDays] = useState([]);
  const [vacanciesDaysNames, setVacanciesDaysNames] = useState([]);

  const handleDates = (val = 0, specialtyId, hospitalId, type) => {
    if (type === 'week') {
      const newWeekStart = startOfWeek(addWeeks(weekStart, val));
      const newWeekEnd = endOfWeek(addWeeks(weekEnd, val));

      setWeekStart(newWeekStart);
      setWeekEnd(newWeekEnd);

      renderVacancies(specialtyId, hospitalId, newWeekStart, newWeekEnd);
    }

    if (type === 'month') {
      const newMonthStart = startOfMonth(addMonths(monthStart, val));
      const newMonthEnd = endOfMonth(addMonths(monthEnd, val));

      setMonthStart(newMonthStart);
      setMonthEnd(newMonthEnd);

      renderVacancies(specialtyId, hospitalId, newMonthStart, newMonthEnd);
    }
  };

  const handleNowDate = (specialityId, hospitalId, type) => {
    return type === 'week'
      ? renderVacancies(
          specialityId,
          hospitalId,
          startOfWeek(today),
          endOfWeek(today)
        )
      : renderVacancies(
          specialityId,
          hospitalId,
          startOfMonth(today),
          endOfMonth(today)
        );
  };

  const renderVacancies = async (
    specialtyId,
    hospitalId,
    startDate,
    endDate
  ) => {
    const dateStart = format(startDate, 'yyyy-MM-dd');
    const dateEnd = format(endDate, 'yyyy-MM-dd');

    const url = `/doctors/hospital/${hospitalId}/vacancies/specialty/${specialtyId}?startDate=${dateStart}&endDate=${dateEnd}`;

    const res = await api.get(url);

    const vacancies = res.data;

    const datesArr = Object.keys(vacancies);

    datesArr.map(
      day =>
        (vacancies[day] = vacancies[day].sort((a, b) => {
          const startDateA = new Date(a.startHour);
          const startDateB = new Date(a.startHour);
          const endDateA = new Date(b.endHour);
          const endDateB = new Date(b.endHour);

          if (startDateA > startDateB) return 1;
          if (startDateA < startDateB) return -1;
          if (endDateA > endDateB) return 1;
          if (endDateA < endDateB) return -1;

          return 0;
        }))
    );

    setVacanciesDays(vacancies);
    setVacanciesDaysNames(datesArr);
  };

  return (
    <ViewSchedulesContext.Provider
      value={{
        scheduleType,
        setScheduleType,
        vacanciesDays,
        vacanciesDaysNames,
        handleDates,
        handleNowDate,
      }}
    >
      {children}
    </ViewSchedulesContext.Provider>
  );
}

export function useViewSchedules() {
  const context = useContext(ViewSchedulesContext);

  if (!context)
    throw new Error(
      'useViewSchedules must be used within a ViewSchedulesProvider'
    );

  const {
    scheduleType,
    setScheduleType,
    vacanciesDays,
    vacanciesDaysNames,
    handleDates,
    handleNowDate,
  } = context;

  return {
    scheduleType,
    setScheduleType,
    vacanciesDays,
    vacanciesDaysNames,
    handleDates,
    handleNowDate,
  };
}
