import { toast } from 'react-toastify';

import history from '~/services/history';
import userKey from '~/utils/userKey';
import formatDate from '~/utils/formatDate';
import { getUser } from '~/services/auth';
import { getParam } from '~/utils/url';

const fromCompany = vacancy => {
  const user = getUser();

  const { id: groupId } = vacancy.schedule.schedulesgroup;
  const { id: hospitalId } = vacancy.schedule.contract.hospital;

  const hospital = user.hospitalPermissions.find(h => h.id === hospitalId);

  const vacancyDate = formatDate(vacancy.date).iso();

  const url = `/hospital/vacancies/schedulegroup/${groupId}/week/${vacancyDate}/?hospitalId=${hospitalId}`;

  if (user.allPermissions) {
    return url;
  }

  if (hospital) {
    const isAllowedToViewSchedule = hospital.permissions.find(p => p.id === 6);

    if (isAllowedToViewSchedule) {
      return url;
    } else {
      return toast.error(
        'O usuário não possui autorização para ver as escalas deste hospital.'
      );
    }
  }

  return toast.error(
    'O usuário não possui autorização para ver esse hospital.'
  );
};

const fromContractor = vacancy => {
  const { id: hospitalId } = vacancy.schedule.contract.hospital;
  const { id: specialtyId } = vacancy.schedule.contract.specialty;

  const vacancyDate = formatDate(vacancy.date).iso();

  return `/contractorhospital/vacancies/specialty/${specialtyId}/week/${vacancyDate}/?hospitalId=${hospitalId}`;
};

const fromCompanyHospital = (vacancy, hospitalId) => {
  const { id: groupId } = vacancy.schedule.schedulesgroup;

  const vacancyDate = formatDate(vacancy.date).iso();

  return `/hospital/vacancies/schedulegroup/${groupId}/week/${vacancyDate}/?hospitalId=${hospitalId}`;
};

const fromContractorHospital = (vacancy, hospitalId) => {
  const { id: specialtyId } = vacancy.schedule.contract.specialty;

  const vacancyDate = formatDate(vacancy.date).iso();

  return `/contractorhospital/vacancies/specialty/${specialtyId}/week/${vacancyDate}/?hospitalId=${hospitalId}`;
};

export const navigateToVacancy = vacancy => {
  const { hospitalId } = getParam();

  const url = {
    company: () => fromCompany(vacancy),
    contractor: () => fromContractor(vacancy),
    companyhospital: () => fromCompanyHospital(vacancy, hospitalId),
    contractorhospital: () => fromContractorHospital(vacancy, hospitalId),
  }[userKey()]();

  history.push(url);
};
