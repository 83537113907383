import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { IArea } from '~/components/UI/Inputs';
import { Button } from '~/components/ChakraComponents';
import { ModalContainer } from '~/components/UI/Modal';

export default function CreateAlertModal({ doctor, closeModal }) {
  const [content, setContent] = useState('');

  const handleSubmit = () => {
    if (content.length < 7) {
      return toast.error('Por favor digite uma mensagem válida');
    }

    const url = `/companies/mydoctors/${doctor.id}/alerts`;

    return api
      .post(url, { content })
      .then(() => {
        toast.success('Alerta criado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  };

  return (
    <ModalContainer h="260px">
      <IArea
        required
        label="novo alerta"
        value={content}
        onChange={e => setContent(e.target.value)}
      />
      <Flex pt={4} justifyContent="space-between">
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <Button title="confirmar" variant="success" onClick={handleSubmit} />
      </Flex>
    </ModalContainer>
  );
}
