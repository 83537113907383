import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #e4e4e4;

  @media (min-width: 375px) and (max-width: 414px) {
    margin: 0px;
  }

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

export const ListAll = styled.ul`
  display: flex;
  align-items: center;
  text-decoration: none;

  @media screen and (min-width: 501px) {
    width: 150px;
    display: -webkit-box;
  }

  @media screen and (min-width: 748px) {
    width: 300px;
    padding-top: 0px;
  }

  @media screen and (min-width: 1020px) {
    width: 260px;
    padding-top: 0px;
  }
`;

export const SectionList = styled.div`
  display: flex;
  margin-right: 30px;

  @media screen and (min-width: 501px) {
    display: block;
    margin-right: 100px;
    margin-left: 5px;
  }
  @media screen and (min-width: 748px) {
    display: block;
    margin-right: 15px;
    margin-left: 0px;
  }
  @media screen and (min-width: 1020px) {
    display: flex;
    margin-right: 75px;
  }
`;

export const SectionSociais = styled.div`
  display: flex;

  svg {
    margin-right: 40px;
    font-size: 30px;

    @media screen and (min-width: 501px) {
      margin-right: 20px;
      font-size: 22px;
    }

    @media screen and (min-width: 748px) {
      margin-right: 25px;
      font-size: 26px;
    }

    @media screen and (min-width: 1020px) {
      margin-right: 40px;
      font-size: 30px;
    }
  }
`;

export const TitleCopyright = styled.span`
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--background);

  @media screen and (min-width: 501px) {
    width: 150px;
    font-size: 10px;
  }

  @media screen and (min-width: 748px) {
    width: 178px;
    font-size: 12px;
  }

  @media screen and (min-width: 1020px) {
    font-size: 15px;
    width: 100%;
  }
`;

export const TitleUteis = styled.span`
  font-family: Montserrat;
  margin-right: 15px;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--background);

  @media screen and (min-width: 500px) {
    font-size: 14px;
    text-align: left;
    padding-left: 5px;
    width: 100px;
  }

  @media screen and (min-width: 748px) {
    font-size: 15px;
    text-align: left;
    padding-left: 5px;
    width: 100px;
  }

  @media screen and (min-width: 1020px) {
    font-size: 22px;
    line-height: 1.23;
    width: 135px;
  }
`;

export const SectionUteis = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 500px) {
    padding: 0px;
    margin: 0px;
  }

  @media screen and (min-width: 601px) {
    padding: 0px 20px;
  }
`;

export const SectionCopyright = styled.div`
  display: flex;
  padding-right: 50px;

  @media screen and (min-width: 500px) {
    width: 235px;
    margin-right: -60px;
  }

  @media screen and (min-width: 601px) {
    width: 245px;
    margin-right: 3px;
  }

  @media screen and (min-width: 1020px) {
    width: 460px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Bg = styled.div`
  display: flex;
  padding: 30px;
  width: 100%;
  height: 170px;
  background-color: var(--primary);
  opacity: 95%;

  @media (min-width: 300px) and (max-width: 768px) {
    padding: 30px;
    height: 200px;
  }
`;

export const BannerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 170px;
  background: url(${props => props.bg}) center center no-repeat;
  position: relative;

  @media (min-width: 300px) and (max-width: 768px) {
    height: 200px;
  }
`;

export const SectionInfos = styled.div`
  display: flex;
  position: absolute;

  @media (min-width: 300px) and (max-width: 768px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 40px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 0 50px;
    width: 92%;
    display: inline-block;
  }
`;

export const TitleBanner = styled.span`
  font-family: Montserrat;
  width: 550px;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: var(--background);

  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 15px;
    line-height: 1.4;
    width: 295px;
  }
`;

export const DescriptionBanner = styled.p`
  font-family: Montserrat;
  width: 340px;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--background);
  margin-left: 200px;

  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 12px;
    margin: 10px 0 0 0;
    width: 300px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 500px;
    margin: 20px 0 0 0;
  }
`;

export const SectionCards = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 300px);
  gap: 30px;
  width: 100%;
  height: 400px;
  background-color: var(--background);

  @media screen and (min-width: 320px) {
    display: flex;
    flex-direction: column;
    height: 970px;
  }

  @media screen and (min-width: 500px) {
    display: flex;
    flex-direction: column;
    height: 1000px;
  }

  @media screen and (min-width: 748px) {
    display: flex;
    flex-direction: column;
    height: 1070px;
  }

  @media screen and (min-width: 1025px) {
    display: grid;
    flex-direction: unset;
    width: 100%;
    height: calc(100vh - 378px);
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  background-color: var(--dark-gray);

  @media (min-width: 320px) and (max-width: 1024px) {
    display: none;
  }
`;

// export const ContentFooter = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   width: 100%;
//   padding: 0 140px 0 140px;

//   @media (min-width: 375px) and (max-width: 414px) {
//     padding: 0 5px;
//   }

//   @media (max-width: 768px) {
//     width: 100%;
//     padding: 0px 30px;
//     justify-content: center;
//   }
// `;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 65px;

  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

export const TitleLink = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: var(--background);
  margin-bottom: 20px;

  @media (min-width: 375px) and (max-width: 414px) {
    text-align: right;
    font-size: 14px;
  }
`;

export const ListLinks = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  padding: 0 5px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--background);

  @media screen and (min-width: 501px) {
    font-size: 10px;
  }

  @media screen and (min-width: 601px) {
    font-size: 11px;
  }

  @media screen and (min-width: 1020px) {
    font-size: 14px;
  }
`;

export const FooterSociais = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 65px;
`;

export const TitleSociais = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: var(--background);
  margin-bottom: 20px;

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 16px;
  }
`;
export const MidiasSociais = styled.div`
  display: flex;

  @media (min-width: 375px) and (max-width: 414px) {
    margin-top: 20px;
  }
`;

// export const FooterDescription = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding-top: 65px;
// `;

export const TitleDescription = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: var(--background);
  margin-bottom: 20px;

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 14px;
  }
`;
export const SubTitleDescription = styled.p`
  font-size: 15px;
  color: var(--background-alt);
`;

/** Mobiles */

export const FooterMobile = styled.div`
  display: none;

  @media (min-width: 300px) and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: var(--dark-gray);
  }
`;
