import { Dashboard as CompanyDashboard } from '~/components/UI/Pages';

import {
  CompanyEditAccount,
  CompanyMyDoctors,
  CompanyMyHospitals,
  CompanyProfile,
  CompanySpendControl,
  CompanyDoctorSpending,
  CompanyMonthly,
  CompanyYearly,
  CompanyUsers,
  CompanyAproveVacancy,
  CompanyDoctorReports,
  CompanyResumeDoctorReports,
} from '~/pages/company';

export default [
  {
    tag: 1,
    path: '/company/dashboard',
    homepage: 'dashboard',
    component: CompanyDashboard,
  },
  { tag: 15, path: '/company/editaccount', component: CompanyEditAccount },
  {
    tag: 6,
    path: '/company/mydoctors',
    component: CompanyMyDoctors,
    exact: true,
  },
  {
    tag: 2,
    path: '/company/myhospitals',
    homepage: 'myhospitals',
    component: CompanyMyHospitals,
  },
  {
    tag: 15,
    path: '/company/profile',
    component: CompanyProfile,
  },
  {
    tag: 13,
    path: '/company/spendcontrol',
    homepage: 'spendcontrol',
    component: CompanySpendControl,
  },
  {
    tag: 22,
    path: '/company/approvevacancy',
    component: CompanyAproveVacancy,
  },
  {
    tag: 14,
    path: '/company/doctorspending',
    component: CompanyDoctorSpending,
  },
  { tag: 14, path: '/company/reports/month', component: CompanyMonthly },
  { tag: 14, path: '/company/reports/year', component: CompanyYearly },
  {
    tag: 14,
    path: '/company/reports/doctorPay',
    component: CompanyDoctorReports,
  },
  {
    tag: 14,
    path: '/company/reports/resumeDoctorPay',
    component: CompanyResumeDoctorReports,
  },
  {
    tag: 9,
    path: '/company/users',
    component: CompanyUsers,
  },
];
