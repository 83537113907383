import React from 'react';
import { Container } from 'react-bootstrap';
import { Flex, Skeleton } from '@chakra-ui/react';

import { Shimmer } from '~/components/Kiwistrap/Page';

const ShimmerInfoSection = () => (
  <Flex px={3} alignItems="center">
    <Flex mr={2}>
      <Skeleton w="50px" h="50px" />
    </Flex>
    <Flex h="80px" flexDir="column" justifyContent="center">
      <Skeleton w="80px" h="16px" mb={0.5} />
      <Skeleton w="180px" h="16px" />
    </Flex>
  </Flex>
);

export default function ProfileShimmer() {
  return (
    <Container>
      <Flex flexDir="column">
        <ShimmerInfoSection />
        <ShimmerInfoSection />
        <ShimmerInfoSection />
        <Flex mt={7} justifyContent="space-evenly">
          <Shimmer width="148px" height="48px" radius="10px" />
          <Shimmer width="148px" height="48px" radius="10px" />
        </Flex>
      </Flex>
    </Container>
  );
}
