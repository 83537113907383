import React from 'react';

import { Row, Column, SubTitle } from '~/components/Kiwistrap/Page';

import {
  Candidature,
  Container,
  Color,
  Info,
  RefusedSvg,
  PendingSvg,
  ExpiredSvg,
  ConfirmedSvg,
  CheckinSvg,
  CheckoutSvg,
} from './styles';

const Legends = React.memo(() => (
  <Container>
    <SubTitle color="var(--primary)">Legenda dos plantões</SubTitle>
    <Row>
      <Column height="auto">
        <Row>
          <Color width="30px" color="var(--primary)" />
          <Info>Plantão Fixo</Info>
        </Row>
        <Row margin="10px 0">
          <Color width="30px" color="var(--orange)" />
          <Info>Cobertura</Info>
        </Row>
        <Row>
          <Color width="30px" color="var(--secondary)" />
          <Info>Vaga Adicional</Info>
        </Row>
      </Column>
      <Column height="auto" margin="0 20px">
        <Row>
          <PendingSvg />
          <Info>Vaga Pendente</Info>
        </Row>
        <Row margin="10px 0">
          <ExpiredSvg />
          <Info>Data Expirada</Info>
        </Row>
        <Row>
          <RefusedSvg />
          <Info>Plantão Recusado</Info>
        </Row>
      </Column>
      <Column height="auto" margin="0 20px">
        <Row>
          <ConfirmedSvg />
          <Info>Plantão Aceito</Info>
        </Row>
        <Row margin="10px 0">
          <CheckinSvg />
          <Info>Check-in Realizado</Info>
        </Row>
        <Row>
          <CheckoutSvg />
          <Info>Check-out Realizado</Info>
        </Row>
      </Column>
    </Row>
  </Container>
));

export default Legends;
