import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaInfoCircle } from 'react-icons/fa';
import { Container, Row, Col } from 'react-bootstrap';
import { Flex, Text, VStack, HStack } from '@chakra-ui/react';

import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import {
  Footer,
  SectionUteis,
  TitleUteis,
  SectionList,
  ListAll,
  ListItem,
  SectionCopyright,
  TitleCopyright,
  SectionSociais,
  FooterMobile,
  TitleSociais,
} from '../styles';
import api from '~/services/api';
import history from '~/services/history';
import removeMask from '~/utils/removeMask';
import internalServerError from '~/utils/internalServerError';
import onlyNumberHandleChangeCep from '~/utils/cepMaskWithAscii';
import onlyNumberHandleChangeCnpj from '~/utils/cnpjMaskWithAscii';

import { IText } from '~/components/UI/Inputs';
import { Button } from '~/components/ChakraComponents';

import { personalData, adminData } from './inputs';

const header = {
  color: 'var(--secondary)',
  fontSize: ['md', 'md', 'md', 'xl'],
  fontWeight: 'bold',
  marginBottom: '20px',
};

export default function Contractor(props) {
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const { contractorCep } = inputs;

    if (!contractorCep || contractorCep.length !== 9) return;

    const url = `https://viacep.com.br/ws/${contractorCep || ''}/json/`;

    axios.get(url).then(response => {
      const {
        bairro: contractorAddress,
        localidade: contractorCity,
        logradouro: contractorStreet,
        uf: contractorUf,
      } = response.data;

      setInputs({
        ...inputs,
        contractorAddress,
        contractorCity,
        contractorStreet,
        contractorUf,
      });
    });
  }, [inputs.contractorCep]);

  const handleChanges = e => {
    if (e.target.name === 'contractorCnpj') {
      return setInputs({
        ...inputs,
        contractorCnpj: onlyNumberHandleChangeCnpj(e.target.value),
      });
    }
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleChangesCep = e => {
    setInputs({
      ...inputs,
      contractorCep: onlyNumberHandleChangeCep(e.target.value),
    });
  };

  const onSubmit = () => {
    const request = { ...inputs };

    if (inputs.password.length < 8) {
      return toast.error('Digite uma senha com pelo menos 8 caracteres.');
    }

    if (inputs.password !== inputs.password_confirm) {
      return toast.error('As senhas digitadas estão diferentes.');
    }

    if (inputs.phone1.length < 10) {
      return toast.error(
        'Por favor insira telefone 01 válido em dados pessoais.'
      );
    }

    if (inputs.phone2.length >= 1 && inputs.phone2.length < 10) {
      return toast.error(
        'Por favor insira telefone 02 válido em dados pessoais.'
      );
    }

    if (inputs.contractorCnpj.length < 18) {
      return toast.error('Por favor insira um cnpj válido.');
    }

    if (inputs.contractorPhone1.length < 10) {
      return toast.error(
        'Por favor insira telefone 01 válido em dados administrativos.'
      );
    }

    if (
      inputs.contractorPhone2.length >= 1 &&
      inputs.contractorPhone2.length < 10
    ) {
      return toast.error(
        'Por favor insira telefone 02 válido em dados administrativos.'
      );
    }

    request.phone1 = removeMask(request.phone1);
    request.phone2 = removeMask(request.phone2);
    request.contractorCnpj = removeMask(request.contractorCnpj);
    request.contractorPhone1 = removeMask(request.contractorPhone1);
    request.contractorPhone2 = removeMask(request.contractorPhone2);
    request.contractorCep = removeMask(request.contractorCep);

    delete request.password_confirm;

    api
      .post('/contractors', request)
      .then(() => {
        toast.success('Cadastro realizado com sucesso!');
        toast.success('Um e-mail de confirmação foi enviado.');
        history.push('/login');
      })
      .catch(internalServerError);
  };
  const { pathname } = props.location;
  return (
    <Flex h="100%" backgroundColor="var(--background)">
      <Container>
        <Flex
          flexDir="column"
          w="100%"
          my={8}
          justifyContent="center"
          textAlign="center"
        >
          <Text mb={4} color="var(--primary)" fontWeight="bold" fontSize="2xl">
            Contratante
          </Text>
          <Flex>
            <Flex flexShrink="none" fontSize="24px" color="#2db7f8">
              <FaInfoCircle />
            </Flex>
            <Text color="var(--primary)" fontWeight="bold">
              O e-mail informado em "Dados Pessoais" será utilizado para acessar
              a nossa plataforma e o e-mail em "Dados Administrativos" será
              utilizado para contato.
            </Text>
          </Flex>
        </Flex>
        <Row>
          <Col md>
            <Text {...header}>Dados Pessoais</Text>
            <VStack spacing={4}>
              {personalData.map(input => (
                <IText
                  key={input.name}
                  required={input.required || true}
                  type={input.type || 'text'}
                  label={input.label}
                  name={input.name}
                  value={inputs[input.name]}
                  onChange={handleChanges}
                  useCleave={input.useCleave}
                  options={input.options}
                />
              ))}
            </VStack>
          </Col>
          <Col md>
            <Text {...header}>Dados Administrativos</Text>
            <VStack spacing={4}>
              {adminData.map(input => (
                <IText
                  key={input.name}
                  required={input.required || true}
                  type={input.type || 'text'}
                  label={input.label}
                  name={input.name}
                  value={inputs[input.name]}
                  onChange={handleChanges}
                  options={input.options}
                />
              ))}
            </VStack>
          </Col>
          <Col md>
            <Text {...header}>Endereço</Text>
            <VStack spacing={4}>
              <IText
                required
                label="cep"
                name="contractorCep"
                value={inputs.contractorCep}
                onChange={handleChangesCep}
              />
              <IText
                required
                label="rua / avenida / travessa"
                name="contractorStreet"
                value={inputs.contractorStreet}
                onChange={handleChanges}
              />
              <HStack>
                <IText
                  required
                  label="número"
                  name="contractorNumber"
                  value={inputs.contractorNumber}
                  onChange={handleChanges}
                />
                <IText
                  required
                  label="bairro"
                  name="contractorAddress"
                  value={inputs.contractorAddress}
                  onChange={handleChanges}
                />
              </HStack>
              <IText
                label="complemento"
                name="contractorComplement"
                value={inputs.contractorComplement}
                onChange={handleChanges}
              />
              <HStack>
                <IText
                  required
                  label="uf"
                  name="contractorUf"
                  value={inputs.contractorUf}
                  onChange={handleChanges}
                  maxLength="2"
                />
                <IText
                  required
                  label="cidade"
                  name="contractorCity"
                  value={inputs.contractorCity}
                  onChange={handleChanges}
                />
              </HStack>
            </VStack>
          </Col>
        </Row>
        <Flex my="24px" justifyContent="center">
          <Button
            title="concluir"
            width="100%"
            maxW="500px"
            onClick={onSubmit}
          />
        </Flex>
        <Footer>
          <SectionUteis>
            <SectionList>
              <TitleUteis>Links Úteis</TitleUteis>
              <ListAll>
                <ListItem>
                  <a href={pathname === '/sou-empresa' ? '/#home' : ''}>Home</a>
                </ListItem>
                <ListItem>
                  <a href={pathname === '/sou-empresa' ? '/#produto' : ''}>
                    Produto
                  </a>
                </ListItem>
                <ListItem>
                  <a href={pathname === '/sou-empresa' ? '/#contato' : ''}>
                    Contato
                  </a>
                </ListItem>
                <ListItem>
                  <Link to="/contato">Fale Conosco</Link>
                </ListItem>
              </ListAll>
            </SectionList>
            <SectionCopyright>
              <TitleCopyright>
                2020 - Meu Plantão - Todos os direitos reservados
              </TitleCopyright>
            </SectionCopyright>
            <SectionSociais>
              <a href="https://www.facebook.com/MeuPlantao" target="_blank">
                <FaFacebook
                  size={30}
                  color="var(--background-alt)"
                  style={{ marginRight: 40 }}
                />
              </a>
              <a href="https://www.instagram.com/meuplantao/" target="_blank">
                <FaInstagram
                  size={30}
                  color="var(--background-alt)"
                  style={{ marginRight: 40 }}
                />
              </a>
              <a href="#" target="_blank">
                <FaWhatsapp size={30} color="var(--background-alt)" />
              </a>
            </SectionSociais>
          </SectionUteis>
        </Footer>
        <FooterMobile>
          <TitleSociais>Siga-nos nas redes sociais</TitleSociais>
          <SectionSociais>
            <a href="https://www.facebook.com/MeuPlantao" target="_blank">
              <FaFacebook
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a href="https://www.instagram.com/meuplantao/" target="_blank">
              <FaInstagram
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a href="#" target="_blank">
              <FaWhatsapp size={30} color="var(--background-alt)" />
            </a>
          </SectionSociais>
        </FooterMobile>
      </Container>
    </Flex>
  );
}
