import React, { useRef, useCallback, forwardRef } from 'react';
import FormContext from './FormContext';

function FormProvider({ initialData = {}, onSubmit, children }) {
  const fields = useRef({});

  const registerField = useCallback(field => {
    fields.current[field.name] = field.ref;
  }, []);

  const unregisterField = useCallback(fieldName => {
    if (fields.current[fieldName] !== undefined) {
      delete fields.current[fieldName];
    }
  }, []);

  const getData = useCallback(() => {
    const data = {};

    for (const field in fields.current) {
      data[field] = fields.current[field].value;
    }

    return data;
  }, []);

  const handleSubmit = useCallback(e => {
    if (e) e.preventDefault();

    const data = getData();

    onSubmit(data);
  }, []);

  return (
    <FormContext.Provider
      value={{ initialData, registerField, unregisterField, handleSubmit }}
    >
      {children}
    </FormContext.Provider>
  );
}

export default forwardRef(FormProvider);
