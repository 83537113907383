import styled from 'styled-components';

export const Option = styled.span`
  cursor: pointer;

  font-size: 22px;
  font-weight: 600;

  text-align: center;

  color: ${props => (props.active ? 'var(--primary)' : 'var(--disabled)')};
`;
