import React, { useState, useRef, useCallback } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import VinculateModal from './VinculateModal';

import FindDoctor from '~/components/UI/FindDoctor';

import { Modal, ModalContainer } from '~/components/UI/Modal';
import { Button } from '~/components/ChakraComponents';

export default function ChooseDoctor({
  setDoctor,
  setPendingDoctor,
  setBelongsMyTeam,
  closeModal,
  vacancy,
}) {
  const [noResultsCrm, setNoResultsCrm] = useState();
  const [noResultsCrmUf, setNoResultsCrmUf] = useState();

  const generateUrl = (inputs, limit, offset) => {
    const { name, crm, crmUf, specialty } = inputs;

    setNoResultsCrm(crm);
    setNoResultsCrmUf(crmUf);

    const url = `/companies/mydoctors?vacancyId=${vacancy.id}&limit=${limit}&offset=${offset}&sort=name&order=asc&name=${name}&crm=${crm}&crmUf=${crmUf}&specialty=${specialty}&isActive=true`;

    return url;
  };

  const vinculateModalRef = useRef(null);

  const openVinculate = useCallback(() => {
    vinculateModalRef.current.openModal();
  }, []);

  const onVinculate = data => {
    if (data.situation) {
      setDoctor('Nenhum escolhido');
      setPendingDoctor(data);

      if (data.isNotMyTeam) {
        setBelongsMyTeam(false);
      }
    } else {
      setDoctor(data);
      setPendingDoctor(null);
    }
    closeModal();
  };

  return (
    <ModalContainer h="80vh" w="1200px">
      <FindDoctor
        getSearchUrl={generateUrl}
        extensions={[
          {
            title: '',
            bodyFunc: doctor => (
              <>
                {doctor.doctor.haveConcurrentVacancy && (
                  <Text color="var(--red)" textAlign="center">
                    Você já alocou este médico para uma vaga neste mesmo
                    horário.
                  </Text>
                )}
                <Button
                  width="100%"
                  height="40px"
                  title="escolher"
                  variant="success"
                  onClick={() => {
                    setDoctor(doctor);
                    setPendingDoctor(null);
                    closeModal();
                  }}
                />
              </>
            ),
          },
        ]}
        noResultsDiv={
          <Flex mt={2} flexDir="column" align="center" textAlign="center">
            <Text>Nenhum resultado encontrado.</Text>
            <Text mt={1} mb={2}>
              Deseja inserir médico mesmo assim?
            </Text>
            <Button
              title="vincular médico"
              width="140px"
              height="40px"
              onClick={openVinculate}
            />
          </Flex>
        }
      />
      <Modal
        title="Vincular Médico"
        ref={vinculateModalRef}
        component={VinculateModal}
        onSubmit={onVinculate}
        noResultsCrm={noResultsCrm}
        noResultsCrmUf={noResultsCrmUf}
      />
    </ModalContainer>
  );
}
