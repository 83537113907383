import React from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Container,
  Content,
  Bg,
  BannerHeader,
  SectionInfos,
  TitleBanner,
  DescriptionBanner,
  SectionCards,
  Footer,
  SectionUteis,
  TitleUteis,
  SectionList,
  ListAll,
  ListItem,
  SectionCopyright,
  TitleCopyright,
  SectionSociais,
  FooterMobile,
  TitleSociais,
} from './styles';

import BPlans from '~/assets/home/banner-planos.png';
import CardPlans from '~/components/Site/Cards/CardPlans';

export default function SignUp(props) {
  const { pathname } = props.location;

  const listPlans = [
    {
      id: '00',
      title: 'Contratante',
      description:
        'Gerenciamento de empresas, protocolos, escalas de plantão e controle financeiro.',
      btn: 'SELECIONE',
      to: '/signup/contractor',
    },
    {
      id: '01',
      title: 'Empresa',
      description:
        'Gerenciamento de equipes, protocolos, escalas de plantão e controle financeiro.',
      btn: 'SELECIONE',
      to: '/sou-empresa',
    },
    {
      id: '02',
      title: 'Médico',
      description:
        'Visualização de escalas, busca por vagas e gerenciador de tempo.',
      btn: 'SELECIONE',
      to: '/sou-medico',
    },
    // {
    //   id: '03',
    //   title: 'Contador',
    //   description:
    //     'Acesso à dados financeiros, controles de escala e dados de contratos.',
    //   btn: 'SELECIONE',
    //   to: '/sou-profissional',
    // },
  ];

  return (
    <Container>
      <Content>
        <BannerHeader bg={BPlans}>
          <Bg />
          <SectionInfos>
            <TitleBanner>
              Para começar o seu cadastro, selecione qual opção se <b>adequa</b>{' '}
              mais ao <b>seu Perfil</b>.
            </TitleBanner>
            <DescriptionBanner>
              Cada opção leva você a diferentes funções, selecione a mais
              adequada e comece a usar!
            </DescriptionBanner>
          </SectionInfos>
        </BannerHeader>
        <SectionCards>
          {listPlans.map(plans => (
            <CardPlans
              key={plans.id}
              title={plans.title}
              description={plans.description}
              tbtn={plans.btn}
              to={plans.to}
            />
          ))}
        </SectionCards>
        <Footer>
          <SectionUteis>
            <SectionList>
              <TitleUteis>Links Úteis</TitleUteis>
              <ListAll>
                <ListItem>
                  <a href={pathname === '/sou-empresa' ? '/#home' : ''}>Home</a>
                </ListItem>
                <ListItem>
                  <a href={pathname === '/sou-empresa' ? '/#produto' : ''}>
                    Produto
                  </a>
                </ListItem>
                <ListItem>
                  <a href={pathname === '/sou-empresa' ? '/#contato' : ''}>
                    Contato
                  </a>
                </ListItem>
                <ListItem>
                  <Link to="/contato">Fale Conosco</Link>
                </ListItem>
              </ListAll>
            </SectionList>
            <SectionCopyright>
              <TitleCopyright>
                2020 - Meu Plantão - Todos os direitos reservados
              </TitleCopyright>
            </SectionCopyright>
            <SectionSociais>
              <a href="https://www.facebook.com/MeuPlantao" target="_blank">
                <FaFacebook color="var(--background-alt)" />
              </a>
              <a href="https://www.instagram.com/meuplantao/" target="_blank">
                <FaInstagram color="var(--background-alt)" />
              </a>
              <a href="#" target="_blank">
                <FaWhatsapp color="var(--background-alt)" />
              </a>
            </SectionSociais>
          </SectionUteis>
        </Footer>
        <FooterMobile>
          <TitleSociais>Siga-nos nas redes sociais</TitleSociais>
          <SectionSociais>
            <a href="https://www.facebook.com/MeuPlantao" target="_blank">
              <FaFacebook color="var(--background-alt)" />
            </a>
            <a href="https://www.instagram.com/meuplantao/" target="_blank">
              <FaInstagram color="var(--background-alt)" />
            </a>
            <a href="#" target="_blank">
              <FaWhatsapp color="var(--background-alt)" />
            </a>
          </SectionSociais>
        </FooterMobile>
      </Content>
    </Container>
  );
}
