import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 93px;

  @media (min-width: 375px) and (max-width: 414px) {
    margin: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentHome = styled.div`
  background: no-repeat;
  background-position: center center;
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  background-size: cover;

  @media (min-width: 375px) and (max-width: 414px) {
  }
`;

export const TitleHome = styled.span`
  font-family: Montserrat;
  font-size: 50px;
  font-weight: 600;
  width: 81vw;
  line-height: 1.2;
  text-align: center;
  color: white;

  @media (max-width: 320px) {
    font-size: 30px !important;
  }

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    margin-bottom: 70px;
  }

  /* border: 1px solid black; */
`;

export const BtnCadastrarNow = styled.a`
  @media (max-width: 320px) {
    width: 250px;
  }
`;

export const ContentBg = styled.div`
  display: flex;
  flex-direction: column;

  /* border: 1px solid black; */
`;
export const BgOne = styled.div`
  width: 300px;
  height: 200px;
  opacity: 0.1;
  border-radius: 278px 0 0 278px;
  border: solid 1px var(--light-gray);
  background-color: var(--primary);

  /* margin-right: 200px; */
`;
export const BgTwo = styled.div`
  width: 300px;
  height: 200px;
  opacity: 0.1;
  border-radius: 278px 0 0 278px;
  border: solid 1px var(--light-gray);
  background-color: var(--secondary);
`;
export const BgTree = styled.div`
  /* width: 920px; */
  height: 200px;
  opacity: 0.1;
  border-radius: 278px 0 0 278px;
  border: solid 1px var(--light-gray);
  background-color: var(--secondary);
`;

export const ContentProduct = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 375px) and (max-width: 414px) {
    margin: 0px;
  }

  @media (max-width: 768px) {
    margin: 0px;
    /* border: 1px solid blue; */
  }

  /* border: 1px solid red; */
`;
export const ContentOtimization = styled.div`
  display: flex;
  width: 100%;
  height: 300px;

  @media (min-width: 375px) and (max-width: 414px) {
    flex-direction: column;
    align-items: center;
    /* border: 1px solid blue; */
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  /* border: 1px solid red; */
`;
export const ContentImgOtimization = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  padding-left: 80px;
  /* justify-content: center; */

  @media (min-width: 375px) and (max-width: 414px) {
    /* display: flex; */
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 30px;
    /* border: 1px solid red; */
  }

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    padding: 0px;
  }

  /* border: 1px solid blue; */
`;
export const ImgOtimization = styled.img`
  width: 400px;
  height: 200px;

  @media (min-width: 375px) and (max-width: 414px) {
    width: 330px;
    height: 200px;
    /* border: 1px solid blue; */
  }

  @media (max-width: 768px) {
    width: 500px;
    height: 250px;
  }

  @media (max-width: 320px) {
    width: 344px;
    height: 172px;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 350px;
    height: 185px;
  }

  /* border: 1px solid black; */
`;

export const ContentDescriptionOtimization = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  padding-left: 30px;

  @media (min-width: 375px) and (max-width: 414px) {
    width: 100%;
    padding: 10px 20px;
    /* border: 1px solid green; */
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    align-items: center;
    width: 100%;
    padding: 10px 25px;
    /* border: 1px solid red; */
  }

  @media (max-width: 320px) {
    padding: 35px 25px;
  }
`;
export const TitleOtimization = styled.span`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary);

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 20px;
  }

  @media (max-width: 320px) {
    font-size: 22px;
  }
`;
export const DescriptionOtimization = styled.p`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-gray);
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 20px;
    text-align: none;
  }
`;

export const ContentControl = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: 375px) and (max-width: 414px) {
    flex-direction: column;
    margin-top: 160px;
    /* border: 1px solid red; */
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    margin-top: 230px;
  }

  @media (max-width: 320px) {
    margin-top: 170px;
  }
`;
export const ContentControlDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  padding-left: 80px;

  @media (min-width: 375px) and (max-width: 414px) {
    width: 100%;
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    align-items: center;
    width: 100%;
    padding: 50px 25px;
    /* border: 1px solid blue; */
  }

  /* border: 1px solid red; */
`;
export const TitleControl = styled.span`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary);

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 20px;
  }

  @media (max-width: 320px) {
    font-size: 22px;
  }
`;
export const SubTitleControl = styled.p`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-gray);
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 20px;
    text-align: none;
  }
`;
export const ContentImgControl = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;

  @media (min-width: 375px) and (max-width: 414px) {
    /* display: flex; */
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0px;
    /* border: 1px solid red; */
  }

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    padding: 0px;
  }

  /* border: 1px solid blue; */
`;
export const ImgControl = styled.img`
  width: 450px;
  height: 400px;
  padding-left: 5px;

  @media (min-width: 375px) and (max-width: 414px) {
    width: 200px;
    height: 200px;
    /* border: 1px solid blue; */
  }

  @media (max-width: 768px) {
    width: 330px;
    height: 250px;
  }

  @media (max-width: 320px) {
    width: 250px;
    height: 195px;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 320px;
    height: 270px;
  }

  /* border: 1px solid red; */
`;

export const ContentPartners = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 375px) and (max-width: 414px) {
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }

  /* border: 1px solid red; */
`;
export const ContentListPartners = styled.div`
  display: grid;
  width: 50%;
  grid-template-columns: repeat(3, 150px);
  gap: 10px;
  padding-left: 80px;

  @media (min-width: 375px) and (max-width: 414px) {
    width: 100%;
    justify-content: center;
    grid-template-columns: repeat(3, 80px);
    padding: 0px;
    margin-top: 20px;

    /* border: 1px solid red; */
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    grid-template-columns: repeat(3, 120px);
    padding: 0px;
    margin-top: 20px;
  }

  @media (max-width: 320px) {
    margin-left: 40px;
  }

  /* border: 1px solid blue; */
`;
export const ImgPartners = styled.img`
  width: 150px;
  height: 150px;

  @media (min-width: 375px) and (max-width: 414px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 320px) {
    width: 80px;
    height: 80px;
  }
`;
export const ContentDescriptionPartners = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding-left: 50px;

  @media (min-width: 375px) and (max-width: 414px) {
    width: 100%;
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    padding: 50px 25px;
  }

  /* border: 1px solid red; */
`;
export const TitlePartners = styled.span`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary);

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 20px;
  }

  @media (max-width: 320px) {
    font-size: 22px;
  }
`;
export const DescriptionPartners = styled.p`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-gray);
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  background-color: var(--dark-gray);

  @media (max-width: 768px) {
    height: 345px;
  }
`;

export const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 140px 0 140px;

  @media (min-width: 375px) and (max-width: 414px) {
    padding: 10px 20px;
    align-items: center;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px 20px;
    justify-content: center;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 65px;

  @media (min-width: 375px) and (max-width: 414px) {
    padding-top: 0px;
    height: 250px;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

export const TitleLink = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: var(--background);
  margin-bottom: 20px;

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 15px;
    text-align: left;
  }

  @media (max-width: 320px) {
    font-size: 15px;
    text-align: left;
    padding-left: 12px;
  }
`;
export const ListLinks = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const ListItem = styled.li`
  a {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: var(--background);
  }
  padding: 10px 0;

  @media (max-width: 320px) {
    a {
      font-size: 12px;
    }
    padding-left: 12px;
  }
`;

export const ContentContact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 700px;
  background: url(${props => props.bg}) center center no-repeat;
  position: relative;

  @media (min-width: 375px) and (max-width: 414px) {
    height: 500px;
  }

  /* border: 1px solid red; */
`;

export const Bg = styled.div`
  display: flex;
  padding: 30px;
  width: 100%;
  height: 700px;
  background-color: var(--primary);
  opacity: 95%;

  @media (min-width: 375px) and (max-width: 414px) {
    height: 500px;
  }
`;

export const ContentBgContact = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  padding: 0 80px;

  @media (min-width: 375px) and (max-width: 414px) {
    padding: 10px 20px;
  }

  @media (max-width: 320px) {
    padding: 10px 20px;
  }
`;

export const ImgBg = styled.img`
  width: 100%;
  height: 500px;
  opacity: 60%;
`;
export const TitleContact = styled.span`
  font-family: Montserrat;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: var(--background);
  margin-bottom: 10px;

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 25px;
  }
`;
export const DescriptionContact = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--background);
  margin-bottom: 30px;

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 14px;
  }
`;
export const BtnContactMe = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 48px;
  border-radius: var(--br-sm);
  box-shadow: 0 3px 6px 0 rgba(93, 107, 217, 0.35);
  background-color: var(--secondary);

  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: var(--background);
`;

export const FooterSociais = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 65px;

  @media (min-width: 375px) and (max-width: 414px) {
    padding: 0px;
    height: 250px;
    margin-right: 10px;
    /* border: 1px solid blue; */
  }

  /* border: 1px solid red; */
`;
export const TitleSociais = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: var(--background);
  margin-bottom: 20px;

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 15px;
    text-align: left;
  }

  @media (max-width: 320px) {
    font-size: 15px;
    text-align: left;
  }
`;
export const MidiasSociais = styled.div`
  @media (min-width: 375px) and (max-width: 414px) {
    display: none;
  }

  @media (max-width: 768px) {
    margin-right: 20px;
  }

  @media (max-width: 320px) {
    margin-right: 0px;
    padding-left: 20px;
    align-items: center;
  }
`;

export const MidiasSociaisMobile = styled.div`
  display: none;

  @media (min-width: 375px) and (max-width: 414px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 65px;

  @media (min-width: 375px) and (max-width: 414px) {
    padding: 0px;
    height: 250px;
    /* border: 1px solid blue; */
  }

  /* border: 1px solid red; */
`;
export const TitleDescription = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: var(--background);
  margin-bottom: 20px;

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 15px;
    text-align: left;
  }

  @media (max-width: 320px) {
    font-size: 15px;
    text-align: left;
    padding-right: 12px;
  }
`;
export const SubTitleDescription = styled.p`
  font-size: 15px;
  padding: 6px 0px;
  color: var(--background-alt);

  @media (max-width: 768px) {
    padding: 6px 0px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }
`;
