import React from 'react';

import { Container } from './styles';

export default function Row({
  wrap,
  basis,
  grow,
  justifyContent,
  alignItems,
  overflow,
  height,
  width,
  padding,
  margin,
  backgroundColor,
  style,
  onClick,
  children,
}) {
  return (
    <Container
      wrap={wrap}
      basis={basis}
      grow={grow}
      justifyContent={justifyContent}
      alignItems={alignItems}
      overflow={overflow}
      height={height}
      width={width}
      padding={padding}
      margin={margin}
      backgroundColor={backgroundColor}
      style={style}
      onClick={onClick}
    >
      {children}
    </Container>
  );
}
