import React, { useState, useEffect } from 'react';
import moment from 'moment';

import history from '~/services/history';
import { getParam } from '~/utils/url';

import NoneChoosenMobile from './NoneChoosenMobile';

import PageLayout from '~/pages/_layouts/hospital/PageLayout';
import { Div, Text } from '~/components/Kiwistrap/Page';
import { DesktopContainer } from '~/components/UI/Sections/MobileVacancyAgenda';
import { DesktopAgendaLeft } from '~/components/UI/Sections';

import { GearSvg } from './styles';

export default function VacanciesNoneChoosen() {
  const { hospitalId } = getParam('hospitalId');
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const { groupId, scheduleType, vacancyDate } = localStorage;

    let date = vacancyDate;
    let type = scheduleType;

    if (!date || date === 'undefined') {
      date = moment(new Date()).format('YYYY-MM-DD');
    }

    if (!type || type === 'undefined') {
      type = 'week';
    }

    if (groupId && groupId !== 'undefined') {
      const url = `/hospital/vacancies/schedulegroup/${groupId}/${type}/${date}/?hospitalId=${hospitalId}`;

      return history.push(url);
    }

    return setValidated(true);
  }, []);

  return (
    <>
      {validated && (
        <>
          <NoneChoosenMobile />
          <DesktopContainer>
            <PageLayout
              disableDefaultButtons
              userInfoButtons={<DesktopAgendaLeft />}
            >
              <Div
                height="calc(100vh - 120px)"
                justifyContent="center"
                alignItems="center"
              >
                <GearSvg />
                <Text size="24px" color="var(--primary)">
                  Por favor selecione uma opção na esquerda.
                </Text>
              </Div>
            </PageLayout>
          </DesktopContainer>
        </>
      )}
    </>
  );
}
