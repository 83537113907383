import { toast } from 'react-toastify';

import api from '~/services/api';
import capitalizeString from '~/utils/capitalizeString';
import internalServerError from '~/utils/internalServerError';

export const sendRequest = (url, requestType, toastMessage, states) => {
  const [
    name,
    users,
    hospitals,
    companyPermissions,
    hospitalPermissions,
  ] = states;

  const request = {
    name: capitalizeString(name),
    usersCompanies: [],
    hospitals: [],
    companyPermissions: [],
    hospitalPermissions: [],
  };

  users.forEach(user => {
    if (user.isChoosen) request.usersCompanies.push(user.usercompany[0].id);
  });

  hospitals.forEach(hospital => {
    if (hospital.isChoosen) request.hospitals.push(hospital.id);
  });

  companyPermissions.forEach(permission => {
    if (permission.isChoosen) request.companyPermissions.push(permission.id);
  });

  hospitalPermissions.forEach(permission => {
    if (permission.isChoosen) request.hospitalPermissions.push(permission.id);
  });

  api[requestType](url, request)
    .then(() => {
      toast.success(toastMessage);
      setTimeout(() => window.location.reload(), 1200);
    })
    .catch(internalServerError);
};
