import React, { useState, useRef, useEffect } from 'react';
import { Flex, Text, Divider } from '@chakra-ui/react';

import api from '~/services/api';
import capitalizeString from '~/utils/capitalizeString';

import { useAuth } from '~/hooks/AuthContext';

import { Button } from '~/components/ChakraComponents/';

import { UserAvatar, BtnContainer, InputFile } from './styles';

const UserInfo = React.memo(() => {
  const { signOut } = useAuth();

  const [avatarFile, setAvatarFile] = useState(null);
  const [Avatar, setAvatar] = useState(
    'https://verde-azul-imoveis.s3.amazonaws.com/b36f1999-aada-4f0b-9fe8-21bdf419fef7.png'
  );
  const [Title, setTitle] = useState('');
  const [SubTitle, setSubTitle] = useState('');

  const inputFile = useRef(null);

  useEffect(() => {
    api.get('/contractors/contractorprofile').then(res => {
      setAvatar(res.data.avatar);
      setTitle(res.data.name);
      setSubTitle(res.data.email);
    });
  }, []);

  async function fileUpload() {
    const data = new FormData();
    data.append('file', avatarFile);

    const response = await api.post('/avatar/', data);

    return response.data[0].filename;
  }

  async function dataEff() {
    if (avatarFile) {
      const avatarName = await fileUpload();

      await api.patch('/contractors/contractorprofile', {
        avatar: avatarName,
      });

      window.location.reload();
    }
  }

  useEffect(() => {
    dataEff();
  }, [avatarFile]);

  return (
    <Flex
      display={['none', 'none', 'none', 'flex']}
      p="50px 40px"
      flexDir="column"
      alignItems="center"
    >
      <UserAvatar onClick={() => inputFile.current.click()}>
        <InputFile
          ref={inputFile}
          onChange={res => setAvatarFile(res.target.files[0])}
        />
        <img src={Avatar} alt="user" />
        <section>
          <span>
            Alterar
            <br />
            Avatar
          </span>
        </section>
      </UserAvatar>
      <Text
        mt={8}
        mb={2}
        fontWeight="semibold"
        fontSize="18px"
        color="gray.600"
        textAlign="center"
      >
        {capitalizeString(Title)}
      </Text>
      <Text
        fontSize="16px"
        fontWeight="600"
        color="gray.500"
        textAlign="center"
      >
        {SubTitle}
      </Text>
      <Divider my={10} color="gray.300" />
      <BtnContainer>
        <Button title="perfil" to="/contractor/profile" />
        <Button title="sair" onClick={signOut} />
      </BtnContainer>
    </Flex>
  );
});

export default UserInfo;
