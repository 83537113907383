import React, { useState } from 'react';
import { toast } from 'react-toastify';

import history from '~/services/history';

import InputPrimary from '~/components/Site/Inputs/InputPrimary';
import InputArea from '~/components/Site/Inputs/InputArea';
import BtnSend from '~/components/Site/Buttons/BtnSecondary';

import {
  Container,
  Content,
  SectionInformations,
  TitleInformations,
  DescriptionInformation,
  TitleHours,
  DescriptionHours,
  TitleAddress,
  DescriptionAddress,
  SectionForm,
  TitleForm,
  NameInput,
  SectionBtn,
} from './styles';

export default function Contact() {
  const [inputs, setInputs] = useState([]);

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const { name, email, message } = inputs;

    if (!message || message.length < 10) {
      return toast.error(
        'Mensagem curta detectada. Por favor insira uma mensagem.'
      );
    }

    const msgFinish = [
      `Nome: ${name} | E-mail: ${email} | Mensagem: ${message}`,
    ];

    window.location.href = `https://wa.me/${551940421028}?text=${msgFinish}`;

    return setTimeout(() => history.push('/'), 1000);
  };

  return (
    <Container>
      <Content>
        <SectionInformations>
          <TitleInformations>
            Quer agendar a sua demonstração?
            <br /> Ou ficou com alguma dúvida?
          </TitleInformations>
          <DescriptionInformation>
            Entra em contato, ficaremos felizes em
            <br /> ajuda-lo como for possível.
          </DescriptionInformation>
          <TitleHours>Horário de funcionamento</TitleHours>
          <DescriptionHours>
            Segunda à Sexta-feira: 08:00 às 17:00
            <br />
            Sábado à Domingo: Fechado
          </DescriptionHours>
        </SectionInformations>
        <SectionForm onSubmit={handleSubmit}>
          <TitleForm>Contato</TitleForm>
          <NameInput>NOME</NameInput>
          <InputPrimary
            required
            name="name"
            type="text"
            value={inputs.name}
            placeholder="Ex: José da Silva"
            onChange={handleChanges}
          />
          <NameInput>EMAIL</NameInput>
          <InputPrimary
            required
            name="email"
            type="email"
            value={inputs.email}
            placeholder="Ex: entra@plantao.com.br"
            onChange={handleChanges}
          />
          <NameInput>MENSAGEM</NameInput>
          <InputArea
            required
            name="message"
            type="text"
            rows="8"
            value={inputs.message}
            placeholder="Informe a sua mensagem..."
            onChange={handleChanges}
          />
          <SectionBtn>
            <BtnSend title="ENVIAR" type="submit" />
          </SectionBtn>
        </SectionForm>
      </Content>
    </Container>
  );
}
