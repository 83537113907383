import React from 'react';
import { Flex, HStack } from '@chakra-ui/react';

import Legends from './Legends';
import Calendar from './Calendar';
import ScheduleType from './ScheduleType';

export default function Visualization({
  navigate,
  calendarValue,
  calendarOnChange,
  extensions,
}) {
  return (
    <Flex flexDir="column" align="center">
      <Legends />
      <HStack justify="center">
        <Calendar value={calendarValue} onChange={calendarOnChange} />
        <ScheduleType navigate={navigate} />
        {extensions}
      </HStack>
    </Flex>
  );
}
