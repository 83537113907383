import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { Container } from 'react-bootstrap';

import MyDoctorsPanel from './panels/MyDoctorsPanel';
import PendingDoctorsPanel from './panels/PendingDoctorsPanel';
import DoctorsContactPanel from './panels/DoctorsContactPanel';

import IsAllowed from '~/components/UI/IsAllowed';
import PageLayout from '~/pages/_layouts/company/PageLayout';

export default function MyDoctors() {
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    setLoadingPage(false);
  }, []);

  const setTab = useCallback(index => {
    localStorage.setItem('myDoctorsTab', index);
  }, []);

  return (
    <PageLayout loading={loadingPage}>
      <Container>
        <Tabs
          defaultIndex={+localStorage.myDoctorsTab || 0}
          onChange={setTab}
          isFitted
          colorScheme="blue"
          variant="solid-rounded"
        >
          <TabList
            borderBottom="1px"
            borderBottomStyle="solid"
            borderBottomColor="gray.400"
          >
            <IsAllowed id={7}>
              <Tab
                borderRadius="sm"
                borderBottomRadius="none"
                _selected={{
                  backgroundColor: 'var(--primary)',
                  color: 'white',
                }}
              >
                Meus Médicos
              </Tab>
              <Tab
                borderRadius="sm"
                borderBottomRadius="none"
                _selected={{
                  backgroundColor: 'var(--primary)',
                  color: 'white',
                }}
              >
                Médicos Pendentes
              </Tab>
              <Tab
                borderRadius="sm"
                borderBottomRadius="none"
                _selected={{
                  backgroundColor: 'var(--primary)',
                  color: 'white',
                }}
              >
                Contatos Médicos
              </Tab>
            </IsAllowed>
          </TabList>
          <TabPanels>
            <TabPanel>
              <MyDoctorsPanel />
            </TabPanel>
            <TabPanel>
              <PendingDoctorsPanel />
            </TabPanel>
            <TabPanel>
              <DoctorsContactPanel />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </PageLayout>
  );
}
