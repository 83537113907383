import React, { useRef, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { BsPhone } from 'react-icons/bs';
import { FaStreetView } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

import api from '~/services/api';
import phoneMask from '~/utils/phoneMask';

import { Modal } from '~/components/UI/Modal';
import { Title } from '~/components/Kiwistrap/Page';
import { Button } from '~/components/ChakraComponents/';
import { CalendarSvg } from '~/components/Kiwistrap/Svg';

import InfoSection from '../../InfoSection';
import ViewSchedulesModal from './ViewSchedulesModal';

import { Avatar } from './styles';

const internalServerError =
  'Houve um problema com os nossos servidores. Tente novamente.';

export default function HospitalProfileModal({ hospital }) {
  const viewSchedulesModalRef = useRef(null);

  const openViewSchedules = useCallback(() => {
    viewSchedulesModalRef.current.openModal();
  }, []);

  const onAvatarLoadError = useCallback(e => {
    e.target.src =
      'https://verde-azul-imoveis.s3.amazonaws.com/b36f1999-aada-4f0b-9fe8-21bdf419fef7.png';
  }, []);

  const acceptHospital = async () => {
    try {
      await api.put('/doctors/hospitals/pending', {
        companyId: hospital.company.id,
      });
      window.location.reload();
    } catch (e) {
      toast.error(internalServerError);
    }
  };

  return (
    <Flex flexDir="column" w="400px">
      <Flex justify="center">
        <Avatar
          src={hospital.avatar}
          alt={hospital.name}
          onError={onAvatarLoadError}
        />
      </Flex>
      <Title center size={24} margin="10px 0 0">
        {hospital.name}
      </Title>
      <InfoSection icon={AiOutlineMail} title="E-mail" info={hospital.email} />
      <InfoSection
        icon={BsPhone}
        title="Telefone"
        info={`${phoneMask(hospital.phone1)} ${phoneMask(hospital.phone2)}`}
      />
      <InfoSection
        icon={FaStreetView}
        title="Endereço"
        info={`${hospital.uf} - ${hospital.city}, ${hospital.address}, ${hospital.street} ${hospital.number}`}
      />
      <Flex justify="center">
        {hospital.company.isPending ? (
          <Button
            title="vincular-se"
            variant="success"
            margin="20px 0 0"
            onClick={acceptHospital}
          />
        ) : (
          <Button
            title="ver plantões"
            margin="20px 0 0"
            icon={<CalendarSvg />}
            onClick={openViewSchedules}
          />
        )}
      </Flex>
      <Modal
        title="Plantões"
        ref={viewSchedulesModalRef}
        component={ViewSchedulesModal}
        hospital={hospital}
      />
    </Flex>
  );
}
