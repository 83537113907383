import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Flex, SimpleGrid, Image, Text, useDisclosure } from '@chakra-ui/react';
import { FiFilter } from 'react-icons/fi';

import ApproveModal from './ApproveModal';
import DetailsModal from './DetailsModal';
import api from '~/services/api';
import history from '~/services/history';
import { Modal } from '~/components/UI/Modal';
import Pagination from '~/components/Dashboard/Pagination';
import { Spinner, Button } from '~/components/ChakraComponents';
import formatDate from '~/utils/formatDate';
import getQueryParams from '~/utils/getJsonQueryParams';
import getFirstLastName from '~/utils/getFirstLastName';
import queryParamsToURL from '~/utils/queryParamsToURL';
import DrawerFilter from '~/components/UI/DrawerFilter';
import { vacancyCardBackgroundColor } from '~/utils/genStatus';

export default function AproveVacancy({ location }) {
  const queryParams = getQueryParams(location.search);

  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [vacancy, setVacancy] = useState({});
  const [total, setTotal] = useState(0);
  const [currentParams, setCurrentParams] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();

  const approveModalRef = useRef(null);
  const detailsModalRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(
    queryParams.offset ? queryParams.offset / queryParams.limit + 1 : 1
  );

  const sendData = useCallback(
    (queryData = null) => {
      setLoading(true);
      let queryResult;
      if (queryData) {
        queryResult = queryData;
      } else {
        queryResult = queryParams;
      }

      const objIsApproved = {};
      if (
        queryResult.isApproved !== undefined &&
        queryResult.isApproved !== ''
      ) {
        objIsApproved.isApproved = queryResult.isApproved;
      }

      const offset = (currentPage - 1) * queryResult.limit || 0;

      const params = {
        ...queryResult,
        ...objIsApproved,
        limit: 8,
        offset,
      };

      setCurrentParams(params);

      const url = `/companies/valueapproval`;

      api.get(url, { params }).then(response => {
        setLoading(false);
        setData(response.data.data);
        setTotal(response.data.total);
      });
    },
    [currentPage, data]
  );

  const renewData = async () => {
    const urlResult = new URLSearchParams(
      new FormData(document.search)
    ).toString();

    history.push(`?${urlResult}`);

    sendData(getQueryParams(`?${urlResult}`));
    return false;
  };

  const handleApprove = useCallback(() => {
    approveModalRef.current.openModal();
  }, []);

  const handleDetails = useCallback(() => {
    detailsModalRef.current.openModal();
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      currentParams.offset = (currentPage - 1) * currentParams.limit;
      const newParams = queryParamsToURL(currentParams);
      window.history.replaceState(null, '', `${location.pathname}${newParams}`);
    }
    setFirstLoad(false);
    sendData();
  }, [currentPage]);

  return (
    <div style={{ width: '100%', overflow: 'auto' }}>
      <Flex
        flexDir="column"
        justify="center"
        width="100%"
        pt={3}
        pr={5}
        pl={5}
        overflow="auto"
        overflowX="hidden"
      >
        <Flex pr={10} align="center">
          <span>
            <Text fontWeight="bold" fontSize={20} color="var(--primary)">
              Aprovação das vagas
            </Text>
            <br />
            {total !== 0 && <span>Total: {total}</span>}
          </span>
          <div style={{ marginLeft: 'auto' }}>
            <Pagination
              l={queryParams.limit}
              t={total}
              onClick={setCurrentPage}
              initialCurrentPage={currentPage}
            />
          </div>

          <FiFilter
            size={40}
            color="var(--primary)"
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={onOpen}
          />
        </Flex>
        <SimpleGrid minChildWidth="300px" min h="100%" spacing={6}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {data.map(res => (
                <Flex
                  flexDir="column"
                  align="flex-start"
                  bg={vacancyCardBackgroundColor(res.type, !res.belongsMyTeam)}
                  color="var(--background-alt)"
                  borderRadius={15}
                  padding={4}
                  height="355px"
                  justify="space-between"
                  maxWidth="400px"
                  boxShadow="md"
                >
                  <Flex align="center">
                    <Image
                      borderRadius="full"
                      objectFit="cover"
                      boxSize="50px"
                      mr={2}
                      ml={2}
                      border="2px solid var(--background)"
                      src={res.companyDoctor.doctor.user.avatar}
                      alt={res.companyDoctor.doctor.user.name}
                    />
                    <Text fontSize="2xl">
                      {getFirstLastName(res.companyDoctor.doctor.user.name)}
                      <br />
                      <span
                        style={{
                          fontSize: 14,
                          marginTop: -3,
                          display: 'block',
                        }}
                      >
                        {res.companyDoctor.doctor.crm}
                      </span>
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    width="100%"
                  >
                    <Image
                      borderRadius="full"
                      boxSize="50px"
                      objectFit="cover"
                      mr={2}
                      ml={2}
                      border="2px solid var(--background)"
                      src={res.schedule.contract.hospital.avatar}
                      alt={res.schedule.contract.hospital.name}
                    />
                    <Text
                      fontSize="xl"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      w="75%"
                      whiteSpace="nowrap"
                    >
                      {res.schedule.contract.hospital.name}
                    </Text>
                  </Flex>
                  <Flex justify="space-around" align="flex-start" w="100%">
                    <Flex flexDir="column">
                      <Text>Data</Text>
                      <Text>Hora do plantao</Text>
                      <Text>Hora executada</Text>
                      <Text>Valor</Text>
                      <Text>Valor Proporcional</Text>
                      <Text>Pagamento</Text>
                    </Flex>
                    <Flex flexDir="column" align="flex-end">
                      <Text>{formatDate(res.date).bars()}</Text>
                      <Text>
                        {formatDate(res.startHour).hours()} -{' '}
                        {formatDate(res.endHour).hours()}
                      </Text>
                      <Text>
                        {formatDate(res.checkinDate).hours()} -{' '}
                        {formatDate(res.checkoutDate).hours()}
                      </Text>
                      <Text>R${res.value}</Text>
                      <Text>R${res.effectiveValue}</Text>
                      <Text>{data.inCash ? 'À Vista' : 'Normal'}</Text>
                    </Flex>
                  </Flex>
                  <Flex w="100%" justify="center">
                    <Button
                      mr={5}
                      color="primary"
                      variant="white"
                      width="35%"
                      onClick={() => {
                        setVacancy(res);
                        handleDetails();
                      }}
                      title="Detalhes"
                    />
                    <Button
                      color="primary"
                      variant="success"
                      width="35%"
                      onClick={() => {
                        setVacancy(res);
                        handleApprove();
                      }}
                      background="var(--background-alt)"
                      title="Aprovar"
                    />
                  </Flex>
                </Flex>
              ))}
              {data.length === 0 && (
                <Flex
                  w="100%"
                  textAlign="center"
                  flexDirection="column"
                  h="100px"
                  justifyContent="space-between"
                  mt={10}
                >
                  <Text>Não achamos plantões nessa pesquisa</Text>
                  <Button
                    color="primary"
                    title="Limpar Busca"
                    margin="0 auto"
                    onClick={() => {
                      history.push(
                        '/company/approvevacancy?isApproved=&limit=8&offset=0'
                      );
                      sendData(getQueryParams('?offset=0&limit=8'));
                    }}
                  />
                </Flex>
              )}
            </>
          )}
        </SimpleGrid>
        <Modal
          title="Aprovar Pagamento"
          ref={approveModalRef}
          component={ApproveModal}
          data={vacancy}
          updateFunction={sendData}
        />
        <Modal
          title="Detalhes da vaga"
          ref={detailsModalRef}
          component={DetailsModal}
          data={vacancy}
        />
        <DrawerFilter
          onClose={onClose}
          isOpen={isOpen}
          location={location}
          currentParams={currentParams}
          renewData={renewData}
          inputsPermitted={[
            'startDate',
            'endDate',
            'specialties',
            'crm',
            'crmUf',
            'startValue',
            'endValue',
            'doctors',
            'hospitals',
            'cities',
            'inCash',
            'isApproved',
          ]}
        />
      </Flex>
    </div>
  );
}
