import React, { useMemo } from 'react';

import { Container } from './styles';

/**
 * :cat: Automatic Responsive Grid Docs :cat:
 * :cat: Any questions ask Rafael Uchôa :cat:
 *
 * |> Obrigatory Props :
 * childrenSize
 *  ? grid direction === horizontal => Utilizes children's current width for calculation.
 *  : grid direction === vertical   => Utilizes children's current height for calculation.
 *
 * |> Optional Props :
 * vertical => if {true} will turn grid to vertical, otherwise it stays horizontal
 *  ? hz => vertical === false => grid-template-rows
 *  : vt => vertical === true  => grid-template-columns
 *
 * m  =>  margin        (sent string)
 * mt =>  margin-top    (sent number * 10)
 * mr =>  margin-right  (sent number * 10)
 * mb =>  margin-bottom (sent number * 10)
 * ml =>  margin-left   (sent number * 10)
 */
const Autogrid = React.memo(
  ({
    childrenSize = '0',
    vertical = false,
    m = 0,
    mt = 0,
    mr = 0,
    mb = 0,
    ml = 0,
    children,
  }) => {
    const autoGridGenerator = useMemo(() => {
      return `repeat(auto-fit, minmax(${childrenSize}, max-content))`;
    }, [childrenSize]);

    return (
      <Container
        hz={!vertical ? autoGridGenerator : 'none'}
        vt={vertical ? autoGridGenerator : 'none'}
        m={m}
        mt={`${mt * 10}px`}
        mr={`${mr * 10}px`}
        mb={`${mb * 10}px`}
        ml={`${ml * 10}px`}
      >
        {children}
      </Container>
    );
  }
);

export default Autogrid;
