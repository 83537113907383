import React, { createContext, useContext, useCallback } from 'react';

import { toast } from 'react-toastify';
import api from '../../services/api';
import history from '../../services/history';

const ProfissionalContext = createContext();

const ProfissionalProvider = ({ children }) => {
  const addProfissional = useCallback(
    async ({
      name,
      email,
      password,
      password_confirmed,
      phone1,
      cep,
      logradouro,
      number,
      bairro,
      complement,
      uf,
      localidade,
    }) => {
      try {
        if (password !== password_confirmed) {
          toast.error(
            'As senhas não conferem. Por gentileza, verifique e informe novamente!'
          );
        } else {
          cep = cep.replace('-', '');

          const { data } = await api.post('accountants', {
            name,
            email,
            password,
            password_confirmed,
            phone1,
            cep,
            street: logradouro,
            number,
            address: bairro,
            complement,
            uf,
            city: localidade,
          });

          if (data) {
            toast.success('Cadastro realizado com sucesso!');
            history.push('/login');
          }
        }
      } catch (error) {
        toast.error(error.response.data.error);
        history.push('/sou-profissional');
      }
    },
    []
  );

  return (
    <ProfissionalContext.Provider value={{ addProfissional }}>
      {children}
    </ProfissionalContext.Provider>
  );
};

function useProfissional() {
  const context = useContext(ProfissionalContext);

  if (!context) {
    throw new Error('useEmpresa must be used within an EmpresaProvider');
  }

  return context;
}

export { ProfissionalProvider, useProfissional };
