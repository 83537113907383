import React, { useState, useEffect } from 'react';
import { HStack, VStack, Flex } from '@chakra-ui/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Label } from '~/components/UI/Text';
import { arr, move } from '~/utils/lambda';

export default function Dnd({ startingLists, updateLists, card }) {
  const [lists, setLists] = useState(startingLists);
  const [preventUpdate, setPreventUpdate] = useState(false);

  useEffect(() => {
    if (arr(startingLists)) {
      setPreventUpdate(false);
    }

    if (!preventUpdate) {
      setLists(startingLists);
    }
  }, [startingLists]);

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) return;

    const clone = [...lists];

    move(
      clone[source.droppableId].cards,
      clone[destination.droppableId].cards,
      source.index,
      destination.index
    );

    setLists(clone);
    updateLists(clone);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <HStack w="100%" h="100%">
        {lists.map((list, listIndex) => (
          <VStack key={list.title} w="100%" h="100%" align="flexStart">
            <Droppable droppableId={listIndex + ''}>
              {(provided, snapshot) => (
                <>
                  <Label>{list.title}</Label>
                  <Flex
                    ref={provided.innerRef}
                    flexDir="column"
                    w="100%"
                    h="100%"
                    minH="400px"
                    maxH="20rem"
                    p="0.5rem"
                    pb="0"
                    borderRadius="4px"
                    overflowY="scroll"
                    wordBreak="break-all"
                    border={
                      snapshot.isDraggingOver
                        ? '1px solid var(--secondary)'
                        : '1px solid var(--dim-gray)'
                    }
                  >
                    {list.cards.map((item, itemIndex) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id + ''}
                        index={itemIndex}
                      >
                        {(provided, snapshot) => (
                          <>{card(provided, snapshot, item)}</>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Flex>
                </>
              )}
            </Droppable>
          </VStack>
        ))}
      </HStack>
    </DragDropContext>
  );
}
