import React from 'react';
import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';

import { SubTitle, Text } from '~/components/Kiwistrap/Page';

const Container = styled.div`
  width: 100%;

  padding: 14px 0;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #e7e7e7;

  svg {
    margin-right: 20px;
    font-size: 50px;
    color: var(--primary);
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export default function InfoSection({ icon: Icon, title, info }) {
  return (
    <Container>
      <Flex flexShrink="none">
        <Icon />
      </Flex>
      <Flex flexDir="column">
        <SubTitle>{title}</SubTitle>
        <Text>{info}</Text>
      </Flex>
    </Container>
  );
}
