import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Multiselect } from 'multiselect-react-dropdown';

import { Label } from '~/components/UI/Text';

const styleDefault = {
  searchBox: {
    border: 'none',
  },
};

export default function IMultiSelect({
  label,
  placeholder = '',
  emptyRecordMsg = 'Não achamos',
  id = '',
  displayValue = 'name',
  options = {},
  icon,
  ...rest
}) {
  return (
    <Flex mb="12px" flexDirection="column" w="100%">
      {label && <Label>{label}</Label>}

      <Flex
        w="100%"
        minH="40px"
        padding="5px"
        borderRadius="10px"
        border="2px solid var(--black)"
      >
        <Multiselect
          emptyRecordMsg={emptyRecordMsg}
          placeholder={placeholder}
          options={options}
          displayValue={displayValue}
          id={id}
          style={styleDefault}
          {...rest}
        />
        {icon}
      </Flex>
    </Flex>
  );
}
