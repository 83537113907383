import styled from 'styled-components';

export const DayCard = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border-top: 7px solid var(--primary);
  background-color: var(--background-alt);
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DayCardText = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: var(--primary);

  @media (max-width: 1600px) {
    font-size: 12px;
  }
`;
