import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AppProvider from './hooks/IndexContext';

import history from './services/history';
import Routes from './routes';

import { GlobalStyle } from './styles/global';

ReactDOM.render(
  <Router history={history}>
    <AppProvider>
      <Routes />
      <GlobalStyle />
      <ToastContainer />
    </AppProvider>
  </Router>,
  document.getElementById('root')
);
