import React, { useState, useRef, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Tooltip } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { FaFilePdf as PdfSvg } from 'react-icons/fa';
import { RiFileExcel2Fill as ExcelSvg } from 'react-icons/ri';

import history from '~/services/history';
import { getParam } from '~/utils/url';

import Calendar from './components/Calendar';
import PdfModal from './components/PdfModalV2';
import ExcelModal from './components/ExcelModal';

import PageLayout from '~/pages/_layouts/hospital/PageLayout';
import ChooseOptionModal from '~/components/Dashboard/Modals/MeusPlantoes/ChooseOption';

import { Modal } from '~/components/UI/Modal';
import { IconContainer } from '~/components/UI/Functionality';
import {
  DesktopContainer,
  GroupsPage as SchedulesGroupPageMobile,
} from '~/components/UI/Sections/MobileVacancyAgenda';

import {
  DesktopAgenda,
  DesktopAgendaLeft,
  DesktopVacancyNav,
} from '~/components/UI/Sections';

export default function GroupsPage() {
  const { hospitalId } = getParam();
  const { groupId, scheduleType, vacancyDate } = useParams();

  const [loading, setLoading] = useState(true);

  const [scheduleId, setScheduleId] = useState();
  const [clickedVacancy, setClickedVacancy] = useState();

  const pdfModalRef = useRef(null);
  const excelModalRef = useRef(null);
  const chooseOptionModalRef = useRef(null);

  const openPdf = useCallback(() => {
    pdfModalRef.current.openModal();
  }, []);

  const openExcel = useCallback(() => {
    excelModalRef.current.openModal();
  }, []);

  const openChooseOption = useCallback(() => {
    chooseOptionModalRef.current.openModal();
  }, []);

  useEffect(() => {
    setLoading(true);
    localStorage.setItem('groupId', groupId);
  }, [groupId]);

  useEffect(() => {
    if (loading) setLoading(false);
  }, [loading]);

  const calendarValue = () => {
    const date = new Date(vacancyDate);

    date.setDate(date.getDate() + 2);

    return date;
  };

  const calendarOnChange = date => {
    const formattedDate = moment(date)
      .add(-1, 'days')
      .format('YYYY-MM-DD');

    const url = `/hospital/vacancies/schedulegroup/${groupId}/${scheduleType}/${formattedDate}/?hospitalId=${hospitalId}`;

    history.push(url);
  };

  const navigate = type => {
    const url = `/hospital/vacancies/schedulegroup/${groupId}/${type}/${vacancyDate}/?hospitalId=${hospitalId}`;

    history.push(url);
  };

  const cardOnClick = vacancy => {
    setScheduleId(vacancy.schedule.id);
    setClickedVacancy(vacancy);

    return openChooseOption();
  };

  return (
    <>
      {!loading && (
        <SchedulesGroupPageMobile
          calendar={Calendar}
          baseUrl={`/companies/myhospitals/${hospitalId}/schedulesgroup/${groupId}/vacancies`}
        />
      )}
      <DesktopContainer>
        <PageLayout
          disableDefaultButtons
          userInfoButtons={<DesktopAgendaLeft />}
        >
          <DesktopVacancyNav
            navigate={navigate}
            scheduleType={scheduleType}
            calendarValue={calendarValue()}
            calendarOnChange={calendarOnChange}
            extensions={
              <>
                <Tooltip hasArrow label="Gerar PDF" bg="gray.600">
                  <span>
                    <IconContainer cursor="pointer" onClick={openPdf}>
                      <PdfSvg />
                    </IconContainer>
                  </span>
                </Tooltip>
                <Tooltip hasArrow label="Gerar Excel" bg="gray.600">
                  <span>
                    <IconContainer cursor="pointer" onClick={openExcel}>
                      <ExcelSvg />
                    </IconContainer>
                  </span>
                </Tooltip>
              </>
            }
          />
          {!loading && (
            <DesktopAgenda
              baseUrl={`/companies/myhospitals/${hospitalId}/schedulesgroup/${groupId}/vacancies`}
              baseUpdateUrl={`/companies/myhospitals/${hospitalId}/schedulesgroup/${groupId}/lastupdate`}
              cardOnClick={cardOnClick}
            />
          )}
          <Modal
            title="Plantão"
            ref={chooseOptionModalRef}
            component={ChooseOptionModal}
            vacancy={clickedVacancy}
            scheduleId={scheduleId}
            resetVacancies={() => setLoading(true)}
          />
          <Modal title="Gerar Pdf" ref={pdfModalRef} component={PdfModal} />
          <Modal
            title="Gerar Excel"
            ref={excelModalRef}
            component={ExcelModal}
          />
        </PageLayout>
      </DesktopContainer>
    </>
  );
}
