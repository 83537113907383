import React from 'react';

import { P } from './styles';

export default function SubTitle({
  size,
  weight,
  lowercase,
  color,
  width,
  padding,
  margin,
  style,
  onClick,
  children,
}) {
  return (
    <P
      size={size}
      weight={weight}
      lowercase={lowercase}
      color={color}
      width={width}
      padding={padding}
      margin={margin}
      style={style}
      onClick={onClick}
    >
      {children}
    </P>
  );
}
