import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { AiOutlineCopy } from 'react-icons/ai';
import { Flex, Text, Tooltip } from '@chakra-ui/react';

import { Label } from '~/components/UI/Text';

export default function DoctorContact({ doctor }) {
  const renderInviteHeader = () => {
    const date = moment(doctor.createdAt).format('DD/MM/YYYY HH:mm');
    const info = `CRM ${doctor.crm}-${doctor.crmuf}`;

    return `${date} | ${info}`;
  };

  if (!doctor.phone) {
    return null;
  }

  return (
    <Flex w="100%" py={2} justify="space-between" align="center">
      <Flex pl={4} flexShrink="none" w={`calc(100% - 50px)`} flexDir="column">
        <Label pb={1} color="var(--primary)" transition="all 150ms ease-in-out">
          {renderInviteHeader()}
        </Label>
        <span>
          <Text
            cursor="pointer"
            isTruncated
            color="var(--secondary)"
            userSelect="none"
            _hover={{ textDecor: 'underline' }}
          >
            +{doctor.phone}
          </Text>
        </span>
      </Flex>
      <Flex
        cursor="pointer"
        flexShrink="none"
        w="50px"
        h="100%"
        justify="center"
        align="center"
        fontSize="26px"
        color="var(--light-gray)"
        _hover={{ color: 'var(--secondary)' }}
      ></Flex>
    </Flex>
  );
}
