import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Text } from '~/components/Kiwistrap/Page';
import { Input } from '~/components/Kiwistrap/Inputs';
import { Button } from '~/components/ChakraComponents/';

import { ResponsivePhaseContainer } from '../../styles';

import { AddressCard } from './styles';

export default function Phase3({ handlePhaseSubmit }) {
  const [inputs, setInputs] = useState({});

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    (async () => {
      const { cep } = inputs;

      if (cep && cep.length === 9) {
        try {
          const response = await axios.get(
            `https://viacep.com.br/ws/${cep || ''}/json/`
          );

          if (response.data.erro) {
            return toast.error('ViaCEP: Cep inválido.');
          }

          const {
            uf,
            localidade: city,
            bairro: address,
            logradouro: street,
          } = response.data;

          setInputs({ ...inputs, uf, city, address, street });
        } catch (error) {
          toast.error(
            'Os servidores do ViaCEP estão indisponíveis no momento.'
          );
        }
      }
    })();
  }, [inputs.cep]);

  const handleSubmit = async () => {
    if (!inputs.cep || inputs.cep.length !== 9) {
      return toast.error('Por favor insira um cep válido.');
    }

    if (!inputs.number) {
      return toast.error('Por favor insira o número.');
    }

    if (!inputs.street) {
      return toast.error('Por favor insira a rua.');
    }

    if (!inputs.address) {
      return toast.error('Por favor insira o bairro.');
    }

    const data = { ...inputs };

    data.cep = data.cep.replace('-', '');

    return handlePhaseSubmit({ ...data });
  };

  return (
    <ResponsivePhaseContainer>
      <Text padding="0 0 20px" style={{ textAlign: 'center' }}>
        Seu CEP será válidado pelo sistema do ViaCEP.
      </Text>
      <Input
        required
        title="cep"
        placeholder="Ex: 11111-222"
        name="cep"
        value={inputs.cep}
        useCleave
        options={{
          delimiters: ['-'],
          blocks: [5, 3],
          uppercase: true,
        }}
        onChange={handleChanges}
      />
      {inputs.city && (
        <>
          <Input
            required
            title="UF"
            placeholder="Ex: SP"
            name="uf"
            value={inputs.uf}
            onChange={handleChanges}
          />
          <Input
            required
            title="Cidade"
            placeholder="Ex: São Paulo"
            name="city"
            value={inputs.city}
            onChange={handleChanges}
          />
          <Input
            required
            title="Bairro"
            placeholder="Ex: Morumbi"
            name="address"
            value={inputs.address}
            onChange={handleChanges}
          />
          <Input
            required
            title="Rua"
            placeholder="Ex: Rua Jose Yazigi"
            name="street"
            value={inputs.street}
            onChange={handleChanges}
          />
          <Input
            required
            title="número"
            placeholder="Ex: 423"
            name="number"
            value={inputs.number}
            onChange={handleChanges}
          />
          <Input
            title="complemento"
            placeholder="Ex: Apto 222"
            name="complement"
            value={inputs.complement}
            onChange={handleChanges}
          />
        </>
      )}
      {inputs.cep && inputs.number && (
        <Button
          title="avançar"
          width="100%"
          margin="12px 0 0"
          onClick={handleSubmit}
        />
      )}
    </ResponsivePhaseContainer>
  );
}
