import styled from 'styled-components';

export const Page = styled.div`
  cursor: pointer;

  width: 40px;
  height: 40px;

  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--dark-gray);

  flex-shrink: none;

  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;

  ${props =>
    props.isSelect && {
      border: '2px solid var(--secondary)',
      borderRadius: '10px',
    }}
`;

export const NavButton = styled.div`
  cursor: pointer;

  width: 40px;
  height: 40px;

  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: var(--br-sm);

  color: var(--background-alt);

  flex-shrink: none;

  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;

  ${props =>
    props.isRight
      ? {
          background: 'var(--primary)',
          marginLeft: '10px',
        }
      : {
          background: 'var(--secondary)',
          marginRight: '10px',
        }}
`;
