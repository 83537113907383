import styled from 'styled-components';
import { RiMenuLine, RiFilter3Line } from 'react-icons/ri';

export const SectionTable = styled.div`
  width: 100%;
  margin: 20px 0;
  flex-direction: column;

  display: ${props => props.display};
`;

export const OpenSvg = styled(RiMenuLine)`
  margin-bottom: 8px;
  margin-left: 15px;
  font-size: 24px;
  color: var(--secondary);
  cursor: pointer;
`;

export const CloseSvg = styled(RiFilter3Line)`
  margin-bottom: 8px;
  margin-left: 15px;
  font-size: 24px;
  color: var(--secondary);
  cursor: pointer;
`;

export const Table = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-height: 400px;

  /* Width */
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--background);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  border-collapse: separate;
`;

export const Tr = styled.tr`
  font-size: 14px;
  width: 100%;
`;

export const Th = styled.th`
  padding: 0 40px 1em 0;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  color: var(--secondary);
`;

export const Td = styled.td`
  padding: 0 40px 1em 0;
  font-weight: 500;
  color: var(--light-gray);
`;
