import React, { memo } from 'react';
import styled from 'styled-components';
import { Flex, VStack } from '@chakra-ui/react';
import {
  FaTimes,
  FaClock,
  FaUserTimes,
  FaCheck,
  FaUserEdit,
  FaUserCheck,
  FaRetweet,
} from 'react-icons/fa';
// import { BsSun, BsMoon } from 'react-icons/bs';

import { vacancyCardBackgroundColor, statusText } from '~/utils/genStatus';

import { Label } from '~/components/UI/Text';
import { Divider } from '~/components/ChakraComponents';

const Container = styled.div`
  max-height: 100vh;

  display: flex;
  flex-direction: column;

  overflow-y: auto;

  svg {
    font-size: 18px;
    margin-right: 10px;
  }
`;

const Color = styled.div`
  width: 30px;
  height: 18px;

  margin-right: 10px;

  border-radius: 2px;

  background-color: ${props =>
    props.color ? props.color : 'var(--background)'};
`;

const Info = styled.span`
  width: 140px;

  font-size: 14px;

  color: var(--dark-gray);
`;

const Candidature = styled.div`
  width: 18px;
  height: 18px;

  padding: 2px;
  margin-right: 10px;

  font-size: 10px;

  border-radius: 50%;

  color: var(--white);
  background-color: var(--red);
`;

const RefusedSvg = styled(FaTimes)`
  color: var(--red);
`;

const PendingSvg = styled(FaClock)`
  color: var(--yellow);
`;

const ExpiredSvg = styled(FaUserTimes)`
  color: var(--red);
`;

const ConfirmedSvg = styled(FaCheck)`
  color: var(--blue);
`;

const CheckingSvg = styled(FaUserEdit)`
  color: var(--secondary);
`;

const CheckoutSvg = styled(FaUserCheck)`
  color: var(--green);
`;

// const SunSvg = styled(BsSun)`
//   color: var(--yellow);
// `;

// const MoonSvg = styled(BsMoon)`
//   color: var(--dark-gray);
// `;

const PendingCheckingSvg = styled(FaUserEdit)`
  color: var(--yellow);
`;

const PendingCheckoutSvg = styled(FaUserCheck)`
  color: var(--yellow);
`;

const OpenChangeSvg = styled(FaRetweet)`
  color: var(--yellow);
`;

const ChangeSvg = styled(FaRetweet)`
  color: var(--green);
`;

const MobileLegends = memo(() => (
  <Container>
    <VStack align="flex-start" divider={<Divider />}>
      <VStack align="flex-start">
        <Label color="var(--primary)">Vagas</Label>
        <Flex>
          <Color color={vacancyCardBackgroundColor('open')} />
          <Info>{statusText('open')}</Info>
        </Flex>
        <Flex>
          <Color color={vacancyCardBackgroundColor('weekly')} />
          <Info>{statusText('weekly')}</Info>
        </Flex>
        <Flex>
          <Color color={vacancyCardBackgroundColor('exception')} />
          <Info>{statusText('exception')}</Info>
        </Flex>
        <Flex>
          <Color color={vacancyCardBackgroundColor('pendingDoctor')} />
          <Info>{statusText('pendingDoctor')}</Info>
        </Flex>
        <Flex alignItems="center">
          <Color color={vacancyCardBackgroundColor('cover')} />
          <Info>{statusText('cover')}</Info>
        </Flex>
      </VStack>
      <VStack align="flex-start">
        <Label color="var(--primary)">Status Do Check-in</Label>
        <Flex>
          <CheckingSvg />
          <Info>{statusText('checkin')}</Info>
        </Flex>
        <Flex>
          <CheckoutSvg />
          <Info>{statusText('checkout')}</Info>
        </Flex>
        <Flex>
          <PendingCheckingSvg />
          <Info>{statusText('pendingCheckin')}</Info>
        </Flex>
        <Flex>
          <PendingCheckoutSvg />
          <Info>{statusText('pendingCheckout')}</Info>
        </Flex>
        <Flex>
          <ExpiredSvg />
          <Info>{statusText('expired')}</Info>
        </Flex>
      </VStack>
      <VStack align="flex-start">
        <Label color="var(--primary)">Checagem Pelo Médico</Label>
        <Flex alignItems="center">
          <PendingSvg />
          <Info>
            Plantão Aguardando
            <br />
            Confirmação
          </Info>
        </Flex>
        <Flex>
          <RefusedSvg />
          <Info>Plantão Negado</Info>
        </Flex>
        <Flex>
          <ConfirmedSvg />
          <Info>Plantão Checado</Info>
        </Flex>
      </VStack>
      <VStack align="flex-start">
        <Label color="var(--primary)">Trocas</Label>
        <Flex alignItems="center">
          <Candidature>+9</Candidature>
          <Info>
            Manifestação de
            <br />
            Interesse
          </Info>
        </Flex>
        <Flex>
          <OpenChangeSvg />
          <Info>Aberto Para Troca</Info>
        </Flex>
        <Flex>
          <ChangeSvg />
          <Info>Plantão Trocado</Info>
        </Flex>
      </VStack>
      {/* <VStack align="flex-start">
        <Label color="var(--primary)">Situação</Label>
        <Flex>
          <SunSvg />
          <Info>Plantão Diurno</Info>
        </Flex>
        <Flex>
          <MoonSvg />
          <Info>Plantão Noturno</Info>
        </Flex>
      </VStack> */}
    </VStack>
  </Container>
));

export default MobileLegends;
