import React, { useState, useEffect, useRef, useCallback } from 'react';
import { format } from 'date-fns';

import api from '~/services/api';

import PageLayout from '~/pages/_layouts/doctor/PageLayout';

import { Button } from '~/components/ChakraComponents/';
import { Modal } from '~/components/UI/Modal';
import { Column, Row, SubTitle, Text } from '~/components/Kiwistrap/Page';

import InputSelect from '~/components/Site/Inputs/InputSelect';

import HourBars from './Graphs/HourBars';
import HourDoughnut from './Graphs/HourDoughnut';
import PaymentBars from './Graphs/PaymentBars';
import ReportsModal from './ReportsModal';

import monthNames from '~/constants/monthNames';

const yearsArr = [
  { text: '2020', value: 2020 },
  { text: '2021', value: 2021 },
];

const monthArr = [
  { text: 'Janeiro', value: 1 },
  { text: 'Feveiro', value: 2 },
  { text: 'Março', value: 3 },
  { text: 'Abril', value: 4 },
  { text: 'Maio', value: 5 },
  { text: 'Junho', value: 6 },
  { text: 'Julho', value: 7 },
  { text: 'Agosto', value: 8 },
  { text: 'Setembro', value: 9 },
  { text: 'Outubro', value: 10 },
  { text: 'Novembro', value: 11 },
  { text: 'Dezembro', value: 12 },
];

const getDate = () => {
  const date = format(new Date(), 'yyyy-MM-dd').split('-');
  const yearNumber = parseInt(date[0], 10);
  const monthNumber = parseInt(date[1], 10);
  return [monthNumber, yearNumber];
};

export default function HourControl() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [month, setMonth] = useState(getDate()[0]);
  const [year, setYear] = useState(getDate()[1]);
  const [totalPayment, setTotalPayment] = useState('Carregando...');

  const reportsModalRef = useRef(null);

  const openReports = useCallback(() => {
    reportsModalRef.current.openModal();
  }, []);

  const getTotalPayment = async () => {
    const url = `/doctors/reports/payments?year=${year}&month=${month}`;

    const response = await api.get(url);

    setTotalPayment(
      Math.abs(response.data.total).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
    );
  };

  useEffect(() => {
    setLoadingPage(false);
    getTotalPayment();
  }, []);

  useEffect(() => {
    getTotalPayment();
  }, [year, month]);

  return (
    <PageLayout title="Controle de Horas e Valores" loading={loadingPage}>
      <Row justifyContent="center" alignItems="flex-end">
        <Row width="auto">
          <Column width="110px">
            <SubTitle>Ano</SubTitle>
            <InputSelect
              type="select"
              options={yearsArr}
              onChange={e => {
                setYear(Number(e.target.value));
              }}
            />
          </Column>
          <Column width="110px" margin="0 20px">
            <SubTitle>Mês</SubTitle>
            <InputSelect
              type="select"
              options={monthArr}
              onChange={e => {
                setMonth(Number(e.target.value));
              }}
            />
          </Column>
        </Row>
        <Row width="auto" justifyContent="center" alignItems="center">
          <Column width="250px" alignItems="center">
            <SubTitle size="22px" color="var(--primary)" padding="0">{`${
              monthNames[month - 1]
            } ${year}`}</SubTitle>
          </Column>
          {/* <Button
            title="ver relatório"
            margin="0 0 5px 20px"
            onClick={openReports}
          /> */}
        </Row>
        {/* <Column justifyContent="center" margin="0 0 0 70px">
          <Text
            padding="15px 0 0"
            size="24px"
            weight="bold"
            color="var(--secondary)"
          >{`Total: ${totalPayment}`}</Text>
        </Column> */}
      </Row>
      <Row justifyContent="center">
        <Row width="auto" margin="20px 0">
          <HourBars year={year} month={month} />
          <HourDoughnut year={year} month={month} />
          {/* <PaymentBars year={year} month={month} /> */}
        </Row>
      </Row>
      <Modal
        ref={reportsModalRef}
        title="Relatório Mensal"
        component={ReportsModal}
      />
    </PageLayout>
  );
}
