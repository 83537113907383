import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import api from '~/services/api';
import history from '~/services/history';
import { getParam } from '~/utils/url';

import { Option, ChooseButton } from './styles';

export default function SpecialtiesBtn() {
  const { hospitalId } = getParam('hospitalId');
  const { groupId, scheduleType, vacancyDate } = useParams();

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const url = `/contractors/myhospitals/${hospitalId}/contracts/groups?isActive=true`;

    api.get(url).then(response => {
      setGroups(response.data.data);
    });
  }, []);

  const getParams = () => {
    let type = scheduleType;

    if (!type || type === 'undefined') {
      type = 'week';
    }

    let date = vacancyDate;

    if (!date || date === 'undefined') {
      date = moment(new Date()).format('YYYY-MM-DD');
    }

    return [type, date];
  };

  const navigateToGroup = id => {
    const [type, date] = getParams();

    history.push(
      `/contractorhospital/vacancies/group/${id}/${type}/${date}/?hospitalId=${hospitalId}`
    );
  };

  const navigateToSpecialty = () => {
    const { specialtyId } = localStorage;

    let url = `/contractorhospital/vacancies/specialty/?hospitalId=${hospitalId}`;

    if (specialtyId && specialtyId !== 'undefined') {
      const [type, date] = getParams();

      url = `/contractorhospital/vacancies/specialty/${specialtyId}/${type}/${date}/?hospitalId=${hospitalId}`;
    }

    history.push(url);
  };

  return (
    <Flex flexDir="column" px="4px">
      <Flex my="20px" flexDir="column" alignItems="center">
        <Option active>Escalas</Option>
        <Option onClick={navigateToSpecialty}>Especialidades</Option>
      </Flex>
      {groups.map(group => (
        <ChooseButton
          key={group.id}
          backgroundColor={
            +groupId === group.id ? 'var(--secondary)' : 'var(--background)'
          }
          textColor={
            +groupId === group.id ? 'var(--background)' : 'var(--secondary)'
          }
          onClick={() => navigateToGroup(group.id)}
        >
          {group.name}
        </ChooseButton>
      ))}
    </Flex>
  );
}
