import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { getUser } from '~/services/auth';
import { ufOptions } from '~/constants/uf';

import PendingDoctorUrl from '~/components/UI/Sections/PendingDoctorUrl';
import { IText, ISelect } from '~/components/UI/Inputs';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider } from '~/components/ChakraComponents';

export default function CreateContactButton() {
  const [inputs, setInputs] = useState({
    doctorCrm: '',
    doctorCrmUf: getUser().uf,
    phone: '',
  });
  const [loading, setLoading] = useState(false);

  const checkIsValidPhone = (phone = '') => {
    if (phone.length < 11) {
      return false;
    }

    if (phone.length > 13) {
      return false;
    }

    if (!phone.startsWith('55')) {
      return false;
    }

    const ddd = phone.substring(2, 4);

    const validsDDD = [
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '21',
      '22',
      '24',
      '27',
      '28',
      '31',
      '32',
      '33',
      '34',
      '35',
      '37',
      '38',
      '41',
      '42',
      '43',
      '44',
      '45',
      '46',
      '47',
      '48',
      '49',
      '51',
      '53',
      '54',
      '55',
      '61',
      '62',
      '63',
      '64',
      '65',
      '66',
      '67',
      '68',
      '69',
      '71',
      '73',
      '74',
      '75',
      '77',
      '79',
      '81',
      '82',
      '83',
      '84',
      '85',
      '86',
      '87',
      '88',
      '89',
      '91',
      '92',
      '93',
      '94',
      '95',
      '96',
      '97',
      '98',
      '99',
    ];

    if (!validsDDD.includes(ddd)) {
      return false;
    }

    return true;
  };

  const onChange = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    if (inputs.doctorCrm === '') {
      setLoading(false);
      return toast.error('Crm inválido.');
    }

    if (inputs.phone === '') {
      setLoading(false);
      return toast.error('Telefone inválido.');
    }

    if (!checkIsValidPhone(inputs.phone)) {
      setLoading(false);
      return toast.error('Telefone inválido. O Padrão é 5519997945996');
    }

    api
      .post('/companies/mydoctors/contact', {
        crm: inputs.doctorCrm,
        crmUf: inputs.doctorCrmUf,
        phone: inputs.phone,
      })
      .then(response => {
        setLoading(false);
        return toast.success('Telefone cadastrado/atualizado com sucesso.');
      })
      .catch(e => {
        setLoading(false);
        return toast.error('Falha ao cadastrar/atualizar novo telefone');
      });
  };

  useEffect(() => {
    if (loading) onSubmit();
  }, [loading]);

  return (
    <ModalContainer w="250px" loading={loading}>
      <IText
        required
        placeholder="Ex: 12345"
        type="number"
        label="Crm"
        pattern="[0-9]*"
        name="doctorCrm"
        value={inputs.doctorCrm}
        onChange={onChange}
        maxLength="12"
      />
      <ISelect
        required
        label="CrmUf"
        name="doctorCrmUf"
        options={ufOptions}
        value={inputs.doctorCrmUf}
        onChange={onChange}
      />
      <IText
        required
        placeholder="Ex: 5515998955667"
        type="number"
        label="Telefone"
        pattern="[0-9]*"
        name="phone"
        value={inputs.phone}
        onChange={onChange}
        maxLength="13"
        minLength="10"
      />
      <Divider />
      <Button
        title="Criar Contato Médico"
        width="100%"
        onClick={() => setLoading(true)}
      />
    </ModalContainer>
  );
}
