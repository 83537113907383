import styled from 'styled-components';

export const AddressCard = styled.div`
  width: 100%;

  margin: 5px 0 10px;
  padding: 4px 8px;

  display: flex;
  flex-direction: column;

  border-radius: 4px;

  box-shadow: var(--shadow);
  background-color: var(--background-alt);
`;
