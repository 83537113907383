import React from 'react';

import ShimmerEffect from './styles';

const Shimmer = ({ width, height, radius, margin, padding }) => (
  <ShimmerEffect
    width={width}
    height={height}
    radius={radius}
    margin={margin}
    padding={padding}
  />
);

export default React.memo(Shimmer);
