import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { GiExitDoor } from 'react-icons/gi';
import { BsFillBellFill } from 'react-icons/bs';

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoImg = styled.img`
  width: 140px;
`;

export const Navigation = styled.nav`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageLink = styled(Link)`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  text-align: center;


  padding-top: ${props => (props.selected ? '4px' : '0')};
  border-bottom: ${props =>
    props.selected ? '4px solid var(--primary)' : '4px solid transparent'};

  svg {
    width: 30px;
    height: 30px;

    position: absolute;
    top: 15px;
    z-index: 2;

    fill: var(--primary);
  }

  ::after {
    content: '${props => props.text}';

    display: inline;

    padding-bottom: 8px;
    heigth: 1px;

    font-size: 10px;
    font-weight: 600;

    color: var(--black);
  }
`;

export const ExitSvg = styled(GiExitDoor)`
  top: 10px !important;
  width: 40px !important;
  height: 40px !important;
`;

export const NotificationsSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const BellContainer = styled.div`
  cursor: pointer;

  position: relative;

  padding: 8px;

  ::before {
    content: '${props => props.unread || ''}';

    display: ${props => (props.unread ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;

    width: 20px;
    height: 20px;

    border-radius: 50%;

    font-size: 12px;
    font-weight: bold;

    color: var(--background-alt);
    background-color: var(--red);
  }
`;

export const Bell = styled(BsFillBellFill)`
  font-size: 28px;

  color: var(--background-alt);
`;

export const NotificationMenuBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotificationsMenu = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #88888840;
    border-radius: var(--br-sm);
    margin: 10px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: #88888880;
    border-radius: var(--br-sm);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #66666680;
  }

  position: absolute;
  top: 81px;
  right: 20px;
  z-index: 1500;

  width: 360px;
  height: 500px;

  box-shadow: var(--shadow);
  background-color: var(--background-alt);
`;

export const NotificationsMenuItem = styled.div`
  padding: 10px;

  color: var(--dark-gray);

  border-bottom: 1px solid #e7e7e7;

  &:last-child {
    padding-bottom: 0;

    border-bottom: none;
  }

  &:hover {
    background-color: var(--dim-gray);
  }

  > p {
    font-size: 14px;
    margin-top: 10px;
    color: var(--light-gray);
    font-weight: 600;
  }
`;
