import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;

  margin-top: 10px;

  border: none;
  border-collapse: collapse;
`;

export const Tr = styled.tr`
  font-size: 13px;

  border-bottom: 1px solid var(--dim-gray);

  &:hover {
    background-color: var(--hover-secondary);

    td {
      border-top: 1px solid var(--hover-secondary);
      border-bottom: 1px solid var(--hover-secondary);
    }
  }

  &:last-child {
    border-bottom: 1px solid transparent;
  }
`;

export const Th = styled.th`
  position: sticky;
  top: 0;

  padding: 4px;

  text-align: left;
  text-transform: uppercase;
  font-weight: bold;

  color: var(--secondary);
  background-color: var(--background);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const Td = styled.td`
  padding: 4px;

  font-weight: 500;

  color: var(--dark-gray);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const NoContractsText = styled.div`
  padding: 4px 0;
  color: var(--dark-gray);
`;
