import React, { useEffect } from 'react';
import { Container } from './styles';

export default function Home(props) {
  useEffect(() => {
    window.location.href = '/login';
  }, []);

  return <Container />;
}
