import React from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { useDisclosure, Flex, Text } from '@chakra-ui/react';

import history from '~/services/history';

import MeuPlantaoLogo from '~/assets/logo/LogoWhiteSmall.png';

import Drawer from './Drawer';

export default function NavMobile() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const drawerButtonRef = React.useRef();

  const renderPageName = () => {
    let pageName;

    switch (window.location.pathname) {
      case '/contractor/profile':
        pageName = 'Perfil';
        break;

      case '/contractor/editaccount':
        pageName = 'Editar Conta';
        break;

      case '/contractor/dashboard':
        pageName = 'Dashboard';
        break;

      case '/contractor/myhospitals':
        pageName = 'Meus Hospitais';
        break;

      case '/contractor/mydoctors':
        pageName = 'Meus Médicos';
        break;

      case '/contractor/users':
        pageName = 'Usuários';
        break;

      case '/contractor/editaccount':
        pageName = 'Editar Conta';
        break;

      default:
        pageName = 'Controle de Gastos';
        break;
    }

    return pageName;
  };

  return (
    <Flex
      display={['flex', 'flex', 'flex', 'none']}
      position="relative"
      w="100%"
      h="40px"
      backgroundColor="var(--primary)"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        onClick={() => history.push('/contractor/dashboard')}
        h="30px"
        w="34px"
        cursor="pointer"
        position="absolute"
        left="10px"
      >
        <img src={MeuPlantaoLogo} alt="Meu Plantão" />
      </Flex>
      <Text color="white" fontSize="20px" fontWeight="bold" textAlign="center">
        {renderPageName()}
      </Text>
      <Flex
        cursor="pointer"
        position="absolute"
        right="10px"
        color="white"
        fontSize="30px"
      >
        <AiOutlineMenu
          ref={drawerButtonRef}
          colorScheme="teal"
          onClick={onOpen}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={drawerButtonRef}
      />
    </Flex>
  );
}
