import styled from 'styled-components';

export const Container = styled.div`
  /* Dont touch this... */
  /* width must be 99% otherwise Autogrid breaks! */
  width: 99%;

  display: grid;

  grid-gap: 20px;
  grid-template-rows: ${props => props.vt};
  grid-template-columns: ${props => props.hz};

  align-items: center;
  justify-content: center;

  margin: ${props => props.m};
  margin-top: ${props => props.mt};
  margin-right: ${props => props.mr};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.ml};
`;
