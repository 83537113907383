import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { BiLeftArrowAlt } from 'react-icons/bi';

import {
  BtnCadastrar,
  BtnEntrar,
  Container,
  Content,
  ImgLogo,
  ListBtns,
  Logo,
  Modal,
  Nav,
  NavMobile,
  Sign,
  Spacing,
} from './styles';

import ImgLogoMarca from '../../../assets/logo/LogoWhite.png';

/**
 * Btns
 */

export default function Auth({ children }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const winW = window.innerWidth;
  const [data, setData] = useState(false);

  return (
    <Container>
      <Nav>
        <Content>
          <Logo to="/">
            <ImgLogo src={ImgLogoMarca} alt="Meu Plantão" />
          </Logo>
          <ListBtns>
            <BtnCadastrar to="/cadastro">CADASTRAR</BtnCadastrar>
            <BtnEntrar to="/login">ENTRAR</BtnEntrar>
          </ListBtns>
        </Content>
      </Nav>
      <NavMobile>
        <Content>
          <Logo to="/">
            <ImgLogo src={ImgLogoMarca} alt="" />
          </Logo>
          <FaBars
            size={30}
            color="var(--background)"
            onClick={() => setData(!data)}
          />
        </Content>
      </NavMobile>
      <Spacing size={winW} />
      {data === true && (
        <Modal>
          <div onMouseLeave={() => setData(!data)}>
            <span onClick={() => setData(!data)} role="button">
              <BiLeftArrowAlt color="#FFFFFF" size={40} />
            </span>
            <Link to="/cadastro" style={{ marginBottom: '2rem' }}>
              <Sign>Cadastrar</Sign>
            </Link>
            <Link to="/login">
              <Sign>Entrar</Sign>
            </Link>
          </div>
        </Modal>
      )}
      {children}
    </Container>
  );
}
