import styled from 'styled-components';
import { GiStethoscope } from 'react-icons/gi';

export const StethoscopeSvg = styled(GiStethoscope)`
  margin-bottom: 20px;

  font-size: 120px;

  color: var(--primary);
`;
