import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link).attrs(props => ({
  as: props.type === 'submit' ? 'button' : Link,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => (props.styles.width ? props.styles.width : '200px')};
  height: ${props => (props.styles.height ? props.styles.height : '48px')};
  border-radius: var(--br-sm);
  box-shadow: 0 3px 6px 0 rgba(93, 107, 217, 0.35);
  background-color: var(--primary);
`;
export const Title = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: var(--background);
`;
