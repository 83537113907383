import React, { createContext, useContext, useCallback } from 'react';

import { toast } from 'react-toastify';
import api from '../../services/api';
import history from '../../services/history';
import internalServerError from '~/utils/internalServerError';

const EmpresaContext = createContext();

const EmpresaProvider = ({ children }) => {
  const addEmpresa = useCallback(
    async ({
      name,
      email,
      password,
      password_confirmed,
      phone1,
      companyName,
      companySocialReason,
      companyCnpj,
      companyPhone1,
      companyEmail,
      companyCep,
      logradouro,
      companyNumber,
      bairro,
      companyComplement,
      uf,
      localidade,
    }) => {
      try {
        if (password !== password_confirmed) {
          toast.error(
            'As senhas não conferem. Por gentileza, verifique e informe novamente!'
          );
        } else {
          companyCep = companyCep.replace('-', '');

          const { data } = await api.post('companies', {
            name,
            email,
            password,
            phone1,
            companyName,
            companySocialReason,
            companyCnpj,
            companyPhone1,
            companyEmail,
            companyCep,
            companyStreet: logradouro,
            companyNumber,
            companyAddress: bairro,
            companyComplement,
            companyUf: uf,
            companyCity: localidade,
          });

          if (data) {
            toast.success('Cadastro realizado com sucesso!');
            history.push('/login');
          }
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data.error === 'Email already exist.'
        ) {
          return toast.error('Esse e-mail já está sendo utilizado');
        }

        return internalServerError();
      }
    },
    []
  );

  return (
    <EmpresaContext.Provider value={{ addEmpresa }}>
      {children}
    </EmpresaContext.Provider>
  );
};

function useEmpresa() {
  const context = useContext(EmpresaContext);

  if (!context) {
    throw new Error('useEmpresa must be used within an EmpresaProvider');
  }
  return context;
}

export { EmpresaProvider, useEmpresa };
