import React from 'react';
import {
  Popover as ChakraPopover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';

export default function Popover({ trigger, header = 'Confirmar Ação', body }) {
  return (
    <ChakraPopover>
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent borderColor="gray.200">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader borderColor="gray.200">{header}</PopoverHeader>
        <PopoverBody>{body}</PopoverBody>
      </PopoverContent>
    </ChakraPopover>
  );
}
