import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import { Flex, Text } from '@chakra-ui/react';
import { FaPlusCircle } from 'react-icons/fa';

import api from '~/services/api';
import getCep from '~/services/cep';
import capitalizeString from '~/utils/capitalizeString';

import { IText, ICheck } from '~/components/UI/Inputs';
import { Button, Divider } from '~/components/ChakraComponents/';

import onlyNumberHandleChangeCep from '~/utils/cepMaskWithAscii';
import onlyNumberHandleChangeCnpj from '~/utils/cnpjMaskWithAscii';

import { UploadAvatarBtn } from './styles';

export default function VinculateHospitalModal({ onClose }) {
  const [values, setValues] = useState([]);
  const [avatarName, setAvatarName] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [sendReminderToMyTeam, setSendReminderToMyTeam] = useState(true);
  const [sendReminderToNotMyTeam, setSendReminderToNotMyTeam] = useState(true);

  const handleChanges = e => {
    if (e.target.name === 'cnpj') {
      return setValues({
        ...values,
        [e.target.name]: onlyNumberHandleChangeCnpj(e.target.value),
      });
    }
    if (e.target.name === 'cep') {
      return setValues({
        ...values,
        [e.target.name]: onlyNumberHandleChangeCep(e.target.value),
      });
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  async function handleUploadAvatar(avatar) {
    const data = new FormData();
    data.append('file', avatar);

    const res = await api.post('/avatar', data);
    setAvatarName(res.data[0].filename);
  }

  useEffect(() => {
    if (avatarName !== null) {
      setValues({ ...values, avatar: avatarName });
      toast.success('Upload realizado com sucesso!');
    }
  }, [avatarName]);

  const removeMask = v => v.replace(/\D/g, '');

  const handleSubmit = async e => {
    e.preventDefault();

    setLoadingSubmit(true);

    const request = { ...values };

    if (!request.numberOfEmployees || request.numberOfEmployees === '') {
      request.numberOfEmployees = 0;
    }

    if (request.cnpj) request.cnpj = removeMask(request.cnpj);
    if (request.phone1) request.phone1 = removeMask(request.phone1);
    if (request.phone2) request.phone2 = removeMask(request.phone2);
    if (request.cep) request.cep = removeMask(request.cep);
    if (request.name) request.name = capitalizeString(request.name);

    request.sendReminderToMyTeam = sendReminderToMyTeam;
    request.sendReminderToNotMyTeam = sendReminderToNotMyTeam;

    try {
      await api.post('/companies/myhospitals', request);
    } catch (error) {
      setLoadingSubmit(false);

      return toast.error(
        'Houve um problema nos nossos servidores, tente novamente.'
      );
    }

    toast.success('Hospital criado com sucesso!');

    setLoadingSubmit(false);

    return setTimeout(() => window.location.reload(), 1000);
  };

  const getAddressInputs = async cep => {
    if (!cep) return;
    if (cep.length !== 9) return;

    const data = await getCep(cep);

    if (!data) {
      return toast.error('Não foi possível localizar o cep digitado.');
    }

    const { bairro, complemento, localidade, logradouro, uf } = data;

    const newValues = { ...values };

    newValues.address = bairro;
    newValues.complement = complemento;
    newValues.city = localidade;
    newValues.street = logradouro;
    newValues.uf = uf;

    setValues(newValues);
  };

  useEffect(() => {
    getAddressInputs(values.cep);
  }, [values.cep]);

  return (
    <form onSubmit={handleSubmit}>
      <Flex maxH="95vh" flexDir="column" overflowY="auto" overflowX="hidden">
        <Row>
          <Col md>
            <Text
              mb={3}
              fontSize="lg"
              fontWeight="bold"
              color="var(--secondary)"
            >
              Dados do hospital
            </Text>
            <IText
              style={{ marginBottom: 10 }}
              label="nome"
              required
              name="name"
              placeholder="Ex: Hospital Saúde"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="razão social"
              name="socialReason"
              placeholder="Ex: Hospital Saúde"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="cnpj"
              name="cnpj"
              placeholder="Ex: 00.000.000/0000-00"
              value={values.cnpj}
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="e-mail"
              required
              name="email"
              type="email"
              placeholder="Ex: hospital@contato.com"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="telefone"
              required
              name="phone1"
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              placeholder="Ex: 11 99987 5542"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="telefone 2"
              name="phone2"
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              placeholder="Ex: 11 99987 5542"
              onChange={handleChanges}
            />
          </Col>
          <Col md>
            <Text
              mb={3}
              fontSize="lg"
              fontWeight="bold"
              color="var(--secondary)"
            >
              Endereço
            </Text>
            <IText
              style={{ marginBottom: 10 }}
              label="cep"
              required
              name="cep"
              placeholder="Ex: 13097-123"
              value={values.cep}
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="rua"
              required
              name="street"
              value={values.street}
              placeholder="Ex: Rua Oliveira"
              onChange={handleChanges}
            />
            <Row>
              <Col md>
                <IText
                  style={{ marginBottom: 10 }}
                  label="bairro"
                  required
                  name="address"
                  value={values.address}
                  placeholder="Ex: Jardim das Flores"
                  onChange={handleChanges}
                />
                <IText
                  style={{ marginBottom: 10 }}
                  label="cidade"
                  required
                  name="city"
                  value={values.city}
                  placeholder="Ex: Rio das Ostras"
                  onChange={handleChanges}
                />
              </Col>
              <Col md>
                <IText
                  style={{ marginBottom: 10 }}
                  label="número"
                  required
                  name="number"
                  placeholder="Ex: 432"
                  onChange={handleChanges}
                />
                <IText
                  style={{ marginBottom: 10 }}
                  label="uf"
                  required
                  name="uf"
                  value={values.uf}
                  placeholder="Ex: SP"
                  onChange={handleChanges}
                />
              </Col>
            </Row>
            <IText
              style={{ marginBottom: 10 }}
              label="complemento"
              name="complement"
              value={values.complement}
              placeholder="Ex: Próx. ao parque"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="link waze"
              name="wazeLink"
              value={values.wazeLink}
              placeholder="Ex: https://waze.com/ul/h6vhcn3sry"
              onChange={handleChanges}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col md>
            <IText
              style={{ marginBottom: 10 }}
              label="gerência geral"
              name="superintendent"
              placeholder="Ex: José Silva"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="total de funcionários"
              name="numberOfEmployees"
              placeholder="Ex: 5000"
              onChange={handleChanges}
            />
          </Col>
          <Col md>
            <IText
              style={{ marginBottom: 10 }}
              label="coordenação técnica"
              name="director"
              placeholder="Ex: Marcos Felipe"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="Perfil"
              name="profile"
              placeholder="Ex: Hospital Universitário"
              onChange={handleChanges}
            />
          </Col>
          <Col md>
            <UploadAvatarBtn>
              <label htmlFor="upload-avatar">
                <FaPlusCircle size="35" color="#5d6bd9" />
                <span>Adicionar Avatar</span>
              </label>
              <input
                name="avatar"
                type="file"
                id="upload-avatar"
                accept="image/png, image/jpeg, image/svg"
                onChange={res => handleUploadAvatar(res.target.files[0])}
              />
            </UploadAvatarBtn>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col md>
            <Text
              mb={3}
              fontSize="lg"
              fontWeight="bold"
              color="var(--secondary)"
            >
              Configuração Lembrete
            </Text>
          </Col>
        </Row>
        <Row>
          <Col alignItems="center" md className="d-flex align-items-center">
            <ICheck
              style={{ marginBottom: 10 }}
              label="Enviar lembrete para médicos vinculados"
              checked={sendReminderToMyTeam}
              onChange={() => setSendReminderToMyTeam(!sendReminderToMyTeam)}
            />
          </Col>
          <Col md className="d-flex align-items-center">
            <ICheck
              style={{ marginBottom: 10 }}
              label="Enviar lembrete para médicos NÃO vinculados"
              checked={sendReminderToNotMyTeam}
              onChange={() =>
                setSendReminderToNotMyTeam(!sendReminderToNotMyTeam)
              }
            />
          </Col>
        </Row>
        <Divider />
        <Flex w="100%" mt={2} justifyContent="space-between">
          <Button title="cancelar" variant="secondary" onClick={onClose} />
          <Button
            variant="success"
            title={loadingSubmit ? 'carregando...' : 'cadastrar'}
            type="submit"
          />
        </Flex>
      </Flex>
    </form>
  );
}
