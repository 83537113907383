import styled from 'styled-components';

export const UserAvatar = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 5px solid var(--primary);
  object-fit: cover;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
  }

  section {
    display: none;
  }

  &:hover {
    section {
      cursor: pointer;
      position: absolute;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      color: #ffff;
      font-weight: bold;
      span {
        padding-top: 90px;
      }
    }
  }
`;
export const InputFile = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;
