import React from 'react';

export default function IColor({ w = 16, h = 16, name, value, onChange }) {
  return (
    <input
      type="color"
      name={name}
      value={value}
      style={{
        cursor: 'pointer',
        width: w,
        height: h,
        padding: 0,
        backgroundColor: 'var(--background)',
      }}
      onChange={onChange}
    />
  );
}
