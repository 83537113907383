import React from 'react';
import { HStack, Flex, Text } from '@chakra-ui/react';

export default function ContractGroupHeader({ group }) {
  return (
    <HStack ml={4} flex="1" alignItems="center" spacing={2}>
      <Flex
        cursor="pointer"
        w="18px"
        h="18px"
        mt="2px"
        border="2px"
        borderStyle="solid"
        borderColor="var(--background)"
        borderRadius="full"
        backgroundColor={group.isActive ? 'var(--green)' : 'var(--red)'}
      />
      <Text pt="2px" fontWeight="600" fontSize="16px">
        {group.name}
      </Text>
    </HStack>
  );
}
