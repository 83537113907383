import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';

import OptionsSection from '../OptionsSection';

import { fetchGroups } from '../utils/fetchGroups';
import { sendRequest } from '../utils/sendRequest';

import { IText } from '~/components/UI/Inputs';
import { Button, Spinner } from '~/components/ChakraComponents';

export default function CreateGroupModal({ onClose }) {
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState('');
  const [users, setUsers] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [companyPermissions, setCompanyPermissions] = useState([]);
  const [hospitalPermissions, setHospitalPermissions] = useState([]);

  useEffect(() => {
    fetchGroups(responses => {
      setUsers(responses[0].data.data);
      setHospitals(responses[1].data.data);
      setCompanyPermissions(responses[2].data.data);
      setHospitalPermissions(responses[3].data.data);

      setLoading(false);
    });
  }, []);

  const onConfirm = () => {
    if (name.length < 3) {
      return toast.error('Por favor digite um nome do grupo válido.');
    }

    sendRequest('/companies/group', 'post', 'Grupo criado com sucesso!', [
      name,
      users,
      hospitals,
      companyPermissions,
      hospitalPermissions,
    ]);
  };

  return (
    <Flex flexDir="column" w="700px" mw="95px" h="85vh">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Flex
            flexDir="column"
            h="100%"
            px={2}
            overflowY="auto"
            overflowX="hidden"
          >
            <IText
              required
              label="nome do grupo"
              placeholder="Ex: Diretor"
              maxLength="18"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <OptionsSection
              title="Usuários"
              description="Usuários do sistema que fazem parte desse grupo"
              items={users}
              setItems={setUsers}
            />
            <OptionsSection
              title="Hospitais"
              description="Hospitais que os usuários do grupo poderão acessar"
              items={hospitals}
              setItems={setHospitals}
            />
            <OptionsSection
              title="Permissões na empresa"
              description="Ações que os usuários do grupo poderão realizar na empresa"
              items={companyPermissions}
              setItems={setCompanyPermissions}
            />
            <OptionsSection
              title="Permissões no hospital"
              description="Ações que os usuários do grupo poderão realizar no hospital"
              items={hospitalPermissions}
              setItems={setHospitalPermissions}
            />
          </Flex>
          <Flex mt={3} justifyContent="space-evenly">
            <Button title="cancelar" variant="secondary" onClick={onClose} />
            <Button title="confirmar" variant="success" onClick={onConfirm} />
          </Flex>
        </>
      )}
    </Flex>
  );
}
