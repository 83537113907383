import axios from 'axios';

import history from './history';

import { getToken } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  e => {
    if (e.response?.data?.error === 'Dont have permission') {
      localStorage.clear();

      history.push('/');

      alert(
        'Você não possui autorização para acessar essa página, por favor contate o responsável pela empresa.'
      );
    }

    return Promise.reject(e);
  }
);

export default api;
