import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Container,
  Content,
  Form,
  FormSection,
  Input,
  Title,
  Buttons,
  BtnCadastro,
  BtnVoltar,
} from './styles';
import api from '~/services/api';

export default function ImHospital() {
  const [dados, setDados] = useState([]);
  const [end, setEnd] = useState([]);

  const handleChanges = e => {
    setDados({ ...dados, [e.target.name]: e.target.value });
  };

  const {
    name,
    email,
    password,
    password_confirmed,
    phone,
    cep,
    cnpj,
    number_address,
    complement,
  } = dados;
  const { logradouro, bairro, localidade, uf } = end;

  useEffect(() => {
    const loadEnd = async () => {
      if (cep) {
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep || ''}/json/`
        );
        setEnd(data);
      }
    };
    loadEnd();
  }, [cep]);

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      if (password !== password_confirmed) {
        toast.error(
          'As senhas não conferem. Por gentileza, verifique e informe novamente!'
        );
      } else {
        const { data } = await api.post('user', {
          name,
          email,
          password,
          phone,
          type: 'hospital',
          cep,
          cnpj,
          street: logradouro,
          address: bairro,
          number_address,
          complement,
          uf,
          city: localidade,
        });

        if (data) {
          toast.success('Ok...sucesso!');
        }
      }
    } catch (error) {}
  };

  return (
    <Container>
      <Content>
        <Title>
          <h1>Sou Hospital</h1>
        </Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="name"
            placeholder="Informe o seu nome.."
            onChange={handleChanges}
            required
          />
          <Input
            type="email"
            name="email"
            placeholder="Informe o seu e-mail.."
            marginLeft={3}
            onChange={handleChanges}
            required
          />
          <FormSection>
            <Input
              type="password"
              name="password"
              placeholder="Informe a sua senha.."
              onChange={handleChanges}
              required
            />
            <Input
              type="password"
              name="password_confirmed"
              placeholder="Confirme a sua senha.."
              onChange={handleChanges}
              required
            />
          </FormSection>
          <FormSection>
            <Input
              type="text"
              name="phone"
              placeholder="Informe o seu telefone.."
              onChange={handleChanges}
              required
            />
            <Input
              type="text"
              name="cnpj"
              placeholder="Informe o seu CNPJ.."
              onChange={handleChanges}
              required
            />
          </FormSection>
          <FormSection>
            <Input
              type="text"
              name="cep"
              placeholder="Informe o seu cep.."
              onBlur={handleChanges}
              required
            />
            <Input
              type="text"
              name="address"
              placeholder="Informe o seu endereço.."
              onChange={handleChanges}
              value={end ? end.logradouro : ''}
              required
            />
          </FormSection>
          <FormSection>
            <Input
              type="text"
              name="address"
              placeholder="Informe o seu bairro.."
              value={end ? end.bairro : ''}
              onChange={handleChanges}
              required
            />
            <Input
              type="text"
              name="complement"
              placeholder="Informe o complemento do endereço.."
              onChange={handleChanges}
            />
          </FormSection>
          <Input
            type="text"
            name="number-address"
            placeholder="Informe o nº do hospital.."
            onChange={handleChanges}
            required
          />
          <FormSection>
            <Input
              type="text"
              name="city"
              placeholder="Informe o sua cidade.."
              value={end ? end.localidade : ''}
              onChange={handleChanges}
              required
            />
            <Input
              type="text"
              name="uf"
              placeholder="Informe o seu estado.."
              value={end ? end.uf : ''}
              onChange={handleChanges}
              required
            />
          </FormSection>

          <Buttons>
            <BtnCadastro type="submit">finalizar cadastro</BtnCadastro>
            <BtnVoltar to="/cadastro">voltar</BtnVoltar>
          </Buttons>
        </Form>
      </Content>
    </Container>
  );
}
