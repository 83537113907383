import React, { useRef, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { AiOutlinePlus } from 'react-icons/ai';

import CreateContactModal from './CreateContactModal';

import { Modal } from '~/components/UI/Modal';
import { Button } from '~/components/ChakraComponents';

export default function CreateInviteButton({ fetchData }) {
  const createContactModalRef = useRef(null);

  const openCreateInvite = useCallback(() => {
    createContactModalRef.current.openModal();
  }, []);

  return (
    <Flex w={['100%', '100%', '100%', '135px']}>
      <Button
        title="Criar Contato Médico"
        width="100%"
        height="40px"
        onClick={openCreateInvite}
        icon2={<AiOutlinePlus size={24} />}
      />
      <Modal
        title="Criar Contato Médico"
        ref={createContactModalRef}
        component={CreateContactModal}
        closeModalCallback={fetchData}
      />
    </Flex>
  );
}
