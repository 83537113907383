import React, { useCallback } from 'react';
import { Switch } from 'react-router-dom';

import {
  PublicRoute,
  CompanyRoute,
  ContractorRoute,
  ContractorHospitalRoute,
  HospitalRoute,
  DoctorRoute,
} from './middlewares';

import {
  landingPages,
  companyPages,
  contractorPages,
  contractorHospitalPages,
  hospitalPages,
  doctorPages,
} from './pages';

const Routes = () => {
  const renderLandingPages = useCallback(page => {
    return <PublicRoute {...page} />;
  }, []);

  const renderCompanyPages = useCallback(page => {
    return <CompanyRoute {...page} />;
  }, []);

  const renderContractorPages = useCallback(page => {
    return <ContractorRoute {...page} />;
  }, []);

  const renderContractorHospitalPages = useCallback(page => {
    return <ContractorHospitalRoute {...page} />;
  }, []);

  const renderHospitalPages = useCallback(page => {
    return <HospitalRoute {...page} />;
  }, []);

  const renderDoctorPages = useCallback(page => {
    return <DoctorRoute {...page} />;
  }, []);

  return (
    <Switch>
      {landingPages.map(renderLandingPages)}
      {companyPages.map(renderCompanyPages)}
      {contractorPages.map(renderContractorPages)}
      {contractorHospitalPages.map(renderContractorHospitalPages)}
      {hospitalPages.map(renderHospitalPages)}
      {doctorPages.map(renderDoctorPages)}
    </Switch>
  );
};

export default React.memo(Routes);
