import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { getUser } from '~/services/auth';

import { IText } from '~/components/UI/Inputs';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider } from '~/components/ChakraComponents/';

export default function EditPasswordModal({ onClose }) {
  const user = getUser();

  const [inputs, setInputs] = useState({});

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!inputs.password) {
      return toast.error('Por favor digite uma nova senha.');
    }

    if (!inputs.password_confirm) {
      return toast.error('Por favor confirme a senha nova.');
    }

    if (inputs.password !== inputs.password_confirm) {
      return toast.error('As duas senhas estão digitadas diferentes.');
    }

    const request = { password: inputs.password };

    api
      .patch(`/companies/users/${user.id}`, request)
      .then(() => {
        toast.success('Senha alterada com sucesso!');
        return onClose();
      })
      .catch(internalServerError);
  };

  return (
    <ModalContainer w="320px">
      <IText
        required
        label="nova senha"
        name="password"
        type="password"
        onChange={handleChanges}
      />
      <IText
        required
        label="confirmar senha"
        name="password_confirm"
        type="password"
        onChange={handleChanges}
      />
      <Divider />
      <Flex justify="space-between">
        <Button
          title="cancelar"
          mr="20px"
          variant="secondary"
          onClick={onClose}
        />
        <Button title="editar" variant="success" onClick={handleSubmit} />
      </Flex>
    </ModalContainer>
  );
}
