import React from 'react';
import { Flex, Table, Thead, Tbody, Tr, Td, Th, Text } from '@chakra-ui/react';

import convertBRL from '~/utils/convertBRL';
import formatDate from '~/utils/formatDate';
import genStatus, { statusText } from '~/utils/genStatus';

import { ModalContainer } from '~/components/UI/Modal';
import { Button } from '~/components/ChakraComponents';

import { renderDoctor } from './actions';

export default function ForceEditModal({
  data,
  onSubmit,
  closeModal,
  closeFatherModal,
}) {
  return (
    <ModalContainer w="1000px" h="85vh">
      <Flex h="1rem" align="center" justify="center" textAlign="center">
        <Text>Esta alteração afetará as seguintes vagas</Text>
      </Flex>
      <Flex h="calc(85vh - 6rem)" overflow="auto">
        <Table>
          <Thead position="sticky" top="0" bgColor="var(--background)">
            <Tr>
              <Th>Data</Th>
              <Th>Tipo Da Vaga</Th>
              <Th>
                <Text textAlign="center">Médico</Text>
              </Th>
              <Th>
                <Text textAlign="center">Status</Text>
              </Th>
              <Th>Valor</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map(vacancy => (
              <Tr key={vacancy.id}>
                <Td>
                  <Flex alignItems="center">
                    <Text fontWeight="semibold">
                      {formatDate(vacancy.date).bars()}
                    </Text>
                  </Flex>
                </Td>
                <Td>{statusText(vacancy.type)}</Td>
                <Td>{renderDoctor(vacancy)}</Td>
                <Td>{statusText(genStatus(vacancy))}</Td>
                <Td>{convertBRL(vacancy.value)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
      <Flex
        py={2}
        align="center"
        justify="space-between"
        bgColor="var(--background)"
      >
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <Button
          title="confirmar"
          variant="success"
          onClick={() => {
            onSubmit();
            closeModal();
            closeFatherModal();
          }}
        />
      </Flex>
    </ModalContainer>
  );
}
