import React from 'react';

import childrenWithProps from '~/utils/childrenWithProps';

import { getUser } from '~/services/auth';

import Nav from './Nav';
import NavMobile from './NavMobile';

import { Container, Content } from './styles';

const HospitalLayout = ({ children }) => (
  <Container>
    <Nav />
    <NavMobile />
    <Content>{childrenWithProps(children, { user: getUser() })}</Content>
  </Container>
);

export default HospitalLayout;
