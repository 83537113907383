import styled from 'styled-components';

export const Card = styled.div`
  cursor: pointer;

  width: 100%;

  margin-top: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  border-radius: 4px;

  box-shadow: var(--shadow);
  background-color: var(--green);
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 600;

  text-align: center;
  text-transform: uppercase;

  color: var(--background-alt);
`;

export const Sector = styled.p`
  font-size: 14px;
  font-weight: 600;

  text-align: center;
  text-overflow: ellipsis;

  overflow: hidden;
  white-space: nowrap;

  color: var(--dark-gray);
`;
