import React, { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';
import { Flex, Text } from '@chakra-ui/react';
import { AiFillDelete } from 'react-icons/ai';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import EditGroupModal from './EditGroupModal';
import CreateGroupModal from './CreateGroupModal';

import IsAllowed from '~/components/UI/IsAllowed';

import { Button, Spinner, Alert } from '~/components/ChakraComponents';
import { Modal } from '~/components/UI/Modal';

export default function GroupModal() {
  const [loading, setLoading] = useState(true);

  const [groups, setGroups] = useState([]);
  const [selected, setSelected] = useState();
  const [groupToDel, setGroupToDel] = useState();
  const [confirmDel, setConfirmDel] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);

    api.get('/companies/group').then(response => {
      setGroups(response.data.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const editGroupModalRef = useRef(null);
  const createGroupModalRef = useRef(null);

  const openEditGroup = useCallback(() => {
    editGroupModalRef.current.openModal();
  }, []);

  const closeEditGroup = useCallback(() => {
    editGroupModalRef.current.closeModal();
  }, []);

  const openCreateGroup = useCallback(() => {
    createGroupModalRef.current.openModal();
  }, []);

  const closeCreateGroup = useCallback(() => {
    createGroupModalRef.current.closeModal();
  }, []);

  const deleteGroup = useCallback(() => {
    api
      .delete(`/companies/group/${groupToDel}`)
      .then(() => {
        fetchData();
        toast.success('Grupo deletado com sucesso!');
      })
      .catch(internalServerError);
  }, [groupToDel]);

  return (
    <Flex
      flexDir="column"
      w="700px"
      mw="95px"
      h="85vh"
      overflowY="auto"
      overflowX="hidden"
    >
      {loading ? (
        <Spinner />
      ) : (
        <Flex flexDir="column" h="100%">
          <IsAllowed id={10}>
            <Flex w="100%" mb={4} justifyContent="center">
              <Button title="criar grupo" onClick={openCreateGroup} />
            </Flex>
          </IsAllowed>
          <Flex flexDir="column" h="100%" overflowY="scroll" overflowX="hidden">
            {groups.map(group => (
              <Flex
                key={group.id}
                mt={2}
                p={2}
                alignItems="center"
                justifyContent="space-between"
                borderRadius="md"
                backgroundColor="var(--background-alt)"
              >
                <Text>{group.name}</Text>
                <Flex>
                  <IsAllowed id={11}>
                    <Text
                      mr={3}
                      cursor="pointer"
                      fontSize="24px"
                      color="var(--primary)"
                      _hover={{ color: 'var(--hover-primary)' }}
                      onClick={() => {
                        setSelected(group);
                        openEditGroup();
                      }}
                    >
                      <FaEdit />
                    </Text>
                  </IsAllowed>
                  <IsAllowed id={12}>
                    <Text
                      cursor="pointer"
                      fontSize="24px"
                      color="var(--red)"
                      _hover={{ color: 'var(--hover-red)' }}
                      onClick={() => {
                        setGroupToDel(group.id);
                        setConfirmDel(true);
                      }}
                    >
                      <AiFillDelete />
                    </Text>
                  </IsAllowed>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
      <Alert
        isOpen={confirmDel}
        onClose={() => setConfirmDel(false)}
        onConfirm={() => {
          setConfirmDel(false);
          deleteGroup();
        }}
        confirmTitle="excluír"
        confirmVariant="danger"
        header="Excluír Grupo"
        body="Essa ação não poderá ser desfeita!"
      />
      <Modal
        title="Criar Grupo"
        ref={createGroupModalRef}
        component={CreateGroupModal}
        onClose={closeCreateGroup}
      />
      <Modal
        title="Editar Grupo"
        ref={editGroupModalRef}
        component={EditGroupModal}
        onClose={closeEditGroup}
        group={selected}
      />
    </Flex>
  );
}
