import React, { forwardRef } from 'react';

import FormContext from './FormContext';
import FormProvider from './FormProvider';

function Form({ initialData = {}, onSubmit, children, ...rest }, formRef) {
  return (
    <FormProvider ref={formRef} initialData={initialData} onSubmit={onSubmit}>
      <FormContext.Consumer>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} {...rest}>
            {children}
          </form>
        )}
      </FormContext.Consumer>
    </FormProvider>
  );
}

export default forwardRef(Form);
