import styled from 'styled-components';

export const SelectContract = styled.select`
  width: 100%;

  padding: 5px;
  margin-right: 1rem;

  border-radius: var(--br-sm);
  border: 2px solid var(--black);

  background: transparent;
`;

export const Option = styled.div`
  cursor: pointer;

  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;

  color: var(--font-color);
  text-decoration: ${props => (props.color ? 'underline' : 'none')};

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 540px) {
    font-size: 18px;
  }
`;
