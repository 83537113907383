import React, { useState, useEffect } from 'react';

import Phase01 from './Phase01';
import PhaseFinal from './PhaseFinal';

export default function PdfModalV2() {
  const [phase, setPhase] = useState(1);
  const [options, setOptions] = useState({});

  return (
    <>
      {phase === 1 && (
        <Phase01
          onSuccess={data => {
            setPhase(100);
            setOptions(data);
          }}
        />
      )}
      {phase === 100 && <PhaseFinal options={options} />}
    </>
  );
}
