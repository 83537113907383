import React, { useState, useRef, useCallback } from 'react';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';

import onAvatarLoadError from '~/utils/onAvatarLoadError';

import { Modal } from '~/components/UI/Modal';

import CompanyProfileModal from './CompanyProfileModal';
import Hospital from '../Hospital';

import { Container, Header, ListMenu } from './styles';

export default function Company({ company, hospitals }) {
  const [viewHospitals, setViewHospitals] = useState(false);

  const companyProfileModalRef = useRef(null);

  const openCompanyProfile = useCallback(() => {
    companyProfileModalRef.current.openModal();
  }, []);

  return (
    <>
      <Container>
        <Header onClick={openCompanyProfile}>
          <img
            src={company.avatar}
            alt={company.name}
            onError={onAvatarLoadError}
          />
          {company.name}
        </Header>
        <ListMenu onClick={() => setViewHospitals(!viewHospitals)}>
          {viewHospitals ? (
            <>
              <GoArrowUp /> Ocultar Hospitais
            </>
          ) : (
            <>
              <GoArrowDown /> Ver Hospitais
            </>
          )}
        </ListMenu>
        {viewHospitals &&
          hospitals.map(hospital => (
            <>
              {hospital.company.name === company.name && (
                <Hospital key={hospital.id} hospital={hospital} />
              )}
            </>
          ))}
      </Container>
      <Modal
        title="Empresa"
        ref={companyProfileModalRef}
        component={CompanyProfileModal}
        company={company}
      />
    </>
  );
}
