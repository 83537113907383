import React, { useState } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import { Button } from '~/components/ChakraComponents/';
import { Title, Text, Row } from '~/components/Kiwistrap/Page';

import {
  ResponsiveContainer,
  DocumentCard,
  NotConfimed,
  Confirmed,
  Upload,
} from './styles';

export default function NoDocuments({ status }) {
  const [documents, setDocuments] = useState([
    {
      description: 'RG ou CNH.',
      name: 'imgRG',
      bucketName: '',
      success: false,
    },
    {
      description: 'Carteirinha CRM.',
      name: 'imgCRM',
      bucketName: '',
      success: false,
    },
    {
      description: 'Diploma (Frente).',
      name: 'imgDegreeFront',
      bucketName: '',
      success: false,
    },
    {
      description: 'Diploma (Verso).',
      name: 'imgDegreeBack',
      bucketName: '',
      success: false,
    },
    {
      description: 'Certificado de especialização (Frente).',
      name: 'imgDegreeSpecialtyFront',
      bucketName: '',
      success: false,
    },
    {
      description: 'Certificado de especialização (Verso).',
      name: 'imgDegreeSpecialtyBack',
      bucketName: '',
      success: false,
    },
    {
      description: 'Certidão ético-profissional do conselho regional.',
      name: 'imgEthical',
      bucketName: '',
      success: false,
    },
    {
      description: 'Comprovante de residência.',
      name: 'imgProofOfAddress',
      bucketName: '',
      success: false,
    },
  ]);

  const uploadDocument = async (name, file) => {
    try {
      const data = new FormData();

      data.append('file', file);

      if (!file.name.match(/.(jpg|jpeg|png|pdf)$/i)) {
        return toast.error('Arquivo enviado não é uma imagem/pdf.');
      }

      const response = await api.post('/doctors/document/file', data);

      const awsFile = response.data[0].filename;

      const documentsToUpdate = [...documents];

      const docIndex = documentsToUpdate.findIndex(document => {
        return document.name === name;
      });

      documentsToUpdate[docIndex].success = true;
      documentsToUpdate[docIndex].bucketName = awsFile;

      return setDocuments(documentsToUpdate);
    } catch (error) {
      return toast.error('Erro ao fazer upload, tente novamente.');
    }
  };

  const handleChange = e => {
    const docName = e.target.name;
    const docFile = e.target.files[0];

    uploadDocument(docName, docFile);
  };

  const handleSubmit = async () => {
    const forgotAny = documents.find(document => document.success === false);

    if (forgotAny) {
      return toast.error('Todos os documentos são obrigatórios.');
    }

    const filteredDocuments = [...documents];

    filteredDocuments.forEach(document => delete document.success);

    const apiData = { documents: [...filteredDocuments] };

    try {
      await api.post('/doctors/document', apiData);

      toast.success('Documentação enviada com sucesso!');

      return setTimeout(() => history.push('/medico/profile'), 1000);
    } catch (error) {
      return toast.error(
        'Houve um problema com os nossos servidores. Tente novamente.'
      );
    }
  };

  return (
    <>
      <Title center>Documentos</Title>
      {status === 'refused' && (
        <Text color="var(--red)">
          Sua documentação foi recusada. Envie novamente.
        </Text>
      )}
      <Text padding="5px 0" size="15px" style={{ textAlign: 'center' }}>
        Por favor envie uma foto sua segurando em mãos:
      </Text>
      <ResponsiveContainer>
        {documents.map(document => (
          <label htmlFor={document.name} style={{ width: '100%' }}>
            <DocumentCard>
              {document.success ? <Confirmed /> : <NotConfimed />}
              <Text width="100%">{document.description}</Text>
              <Upload />
              <input
                type="file"
                id={document.name}
                name={document.name}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            </DocumentCard>
          </label>
        ))}
        <Row margin="20px 0 0" justifyContent="space-between">
          <Button
            title="cancelar"
            variant="secondary"
            margin="0 20px 0 0"
            onClick={() => history.push('/medico/profile')}
          />
          <Button title="enviar" onClick={handleSubmit} />
        </Row>
      </ResponsiveContainer>
    </>
  );
}
