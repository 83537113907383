import React, { useState, useCallback } from 'react';

import api from '~/services/api';

import { Input } from '..';

import { Results, Result } from './styles';

function InputSpecialties({
  setSpecialtyId,
  style,
  titleColor,
  nameInput = 'specialty',
}) {
  const [specialty, setSpecialty] = useState('');
  const [specialties, setSpecialties] = useState('');
  const [openResults, setOpenResults] = useState(false);

  const getSpecialties = useCallback(name => {
    const url = `/allspecialties?name=${name}&limit=7`;

    api.get(url).then(res => {
      const { data } = res;
      setSpecialties(data);
    });
  }, []);

  const handleChanges = useCallback(
    e => {
      getSpecialties(e.target.value);
      setSpecialty(e.target.value);
      setOpenResults(true);
    },
    [getSpecialties]
  );

  const resultOnClick = useCallback(
    id => {
      setSpecialtyId(id);

      const input = specialties.filter(x => x.id === id);

      setSpecialty(input[0].name);
      setOpenResults(false);
    },
    [specialties, setSpecialtyId]
  );

  const renderResults = useCallback(
    results => {
      if (results.length === 0) return null;

      return (
        <Results>
          {specialties.map(spc => (
            <Result key={spc.id} onClick={() => resultOnClick(spc.id)}>
              {spc.name}
            </Result>
          ))}
        </Results>
      );
    },
    [specialties, resultOnClick]
  );

  return (
    <Input
      title="especialidade"
      name={nameInput}
      titleColor={titleColor}
      style={style}
      placeholder="Ex: Clínica Médica"
      value={specialty}
      onChange={handleChanges}
    >
      {openResults && specialties && renderResults(specialties)}
    </Input>
  );
}

export default React.memo(InputSpecialties);
