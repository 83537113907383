import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';
import { Container } from 'react-bootstrap';
import { SimpleGrid } from '@chakra-ui/react';

import api from '~/services/api';
import history from '~/services/history';
import internalServerError from '~/utils/internalServerError';

import MyHospitalsShimmer from './shimmer';

import EditHospitalModal from './components/EditHospitalModal';
import CreateHospitalModal from './components/CreateHospitalModal';

import HospitalCard from './HospitalCard';
import RegisterHospitalCard from './RegisterHospitalCard';

import IsAllowed from '~/components/UI/IsAllowed';
import PageLayout from '~/pages/_layouts/company/PageLayout';

import { Modal } from '~/components/UI/Modal';
import { Button } from '~/components/ChakraComponents/';

import { InputLabel, InputSearch } from './styles';

export default function MyHospitals({ user }) {
  const [loadingPage, setLoadingPage] = useState(true);

  const [name, setName] = useState('');
  const [hospitals, setHospitals] = useState([]);
  const [permissionsHospitalIds, setPermissionsHospitalIds] = useState([]);
  const [clickedHospital, setClickedHospital] = useState('');

  useEffect(() => {
    api.get('/companies/myhospitals?sort=name&order=asc').then(response => {
      const hospIds = user.hospitalPermissions.map(h => h.id);

      setHospitals(response.data.data);
      setPermissionsHospitalIds(hospIds);

      setLoadingPage(false);
    });
  }, []);

  const editHospitalModalRef = useRef(null);
  const createHospitalModalRef = useRef(null);
  const searchInputRef = useRef();

  const openEditHospital = useCallback(() => {
    editHospitalModalRef.current.openModal();
  }, []);

  const closeEditHospital = useCallback(() => {
    editHospitalModalRef.current.closeModal();
  }, []);

  const openCreateHospital = useCallback(() => {
    createHospitalModalRef.current.openModal();
  }, []);

  const closeCreateHospital = useCallback(() => {
    createHospitalModalRef.current.closeModal();
  }, []);

  const handleEditHospital = useCallback(id => {
    setClickedHospital(id);
    openEditHospital();
  }, []);

  const handleCreateHospital = useCallback(() => {
    openCreateHospital();
  }, []);

  const fetchData = useCallback(() => {
    const url = `/companies/myhospitals?name=${name}`;

    api
      .get(url)
      .then(response => setHospitals(response.data.data))
      .catch(internalServerError);
  }, [name, hospitals]);

  useEffect(() => {
    fetchData();
  }, [name]);

  return (
    <PageLayout loading={loadingPage} shimmer={MyHospitalsShimmer}>
      <Container>
        <Flex margin="0 0 20px" justify="center">
          <Flex align="flex-end" justify="center">
            <Flex flexDir="column">
              <InputLabel>buscar hospital</InputLabel>
              <InputSearch
                autoFocus
                placeholder="Ex: Hospital Coração"
                ref={searchInputRef}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    setName(searchInputRef.current.value);
                  }
                }}
              />
            </Flex>
            <Button
              width="auto"
              height="40px"
              margin="0 0 0 10px"
              icon2={<FaSearch size={24} />}
              onClick={() => setName(searchInputRef.current.value)}
            />
          </Flex>
        </Flex>
        <SimpleGrid minChildWidth="200px" spacing={4}>
          <IsAllowed id={3}>
            <RegisterHospitalCard onClick={handleCreateHospital} />
          </IsAllowed>
          {hospitals.map(hosp => (
            <Fragment key={hosp.id}>
              {permissionsHospitalIds.includes(hosp.id) &&
              !user.allPermissions ? (
                <HospitalCard
                  hospital={hosp}
                  editHospital={() => handleEditHospital(hosp.id)}
                  enterHospital={() => {
                    if (user.allPermissions) {
                      return history.push(
                        `/hospital/dashboard/?hospitalId=${hosp.id}`
                      );
                    }

                    const findHospital = user.hospitalPermissions.find(
                      h => h.id === hosp.id
                    );

                    const hospitalPermissionsIds = findHospital.permissions.map(
                      p => p.id
                    );

                    if (hospitalPermissionsIds.includes(1)) {
                      history.push(
                        `/hospital/dashboard/?hospitalId=${hosp.id}`
                      );
                    } else {
                      toast.error(
                        'Esta conta não possui autorização para entrar no hospital.'
                      );
                    }
                  }}
                />
              ) : (
                <HospitalCard
                  hospital={hosp}
                  editHospital={() => handleEditHospital(hosp.id)}
                  enterHospital={() => {
                    if (user.allPermissions) {
                      return history.push(
                        `/hospital/dashboard/?hospitalId=${hosp.id}`
                      );
                    }

                    const findHospital = user.hospitalPermissions.find(
                      h => h.id === hosp.id
                    );

                    const hospitalPermissionsIds = findHospital.permissions.map(
                      p => p.id
                    );

                    if (hospitalPermissionsIds.includes(1)) {
                      history.push(
                        `/hospital/dashboard/?hospitalId=${hosp.id}`
                      );
                    } else {
                      toast.error(
                        'Esta conta não possui autorização para entrar no hospital.'
                      );
                    }
                  }}
                />
              )}
            </Fragment>
          ))}
        </SimpleGrid>
        <Modal
          title="Cadastrar Hospital"
          ref={createHospitalModalRef}
          component={CreateHospitalModal}
          onClose={closeCreateHospital}
        />
        <Modal
          title="Editar Hospital"
          ref={editHospitalModalRef}
          component={EditHospitalModal}
          hospitalId={clickedHospital}
          onClose={closeEditHospital}
        />
      </Container>
    </PageLayout>
  );
}
