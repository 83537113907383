import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { ModalContainer } from '~/components/UI/Modal';

import { Button } from '~/components/ChakraComponents';
import formatDate from '~/utils/formatDate';

export default function ApproveModal({ closeModal, data }) {
  const initialData = formatDate(data.checkinDate).bars();
  const endData = formatDate(data.checkoutDate).bars();
  const date =
    initialData === endData ? initialData : `${initialData} - ${endData}`;

  const typeDictionary = type => {
    return {
      weekly: 'Semanal',
      biweekly: 'Quinzenal',
      cover: 'Cobertura',
      exception: 'Exceção',
    }[type];
  };

  return (
    <ModalContainer w="450px">
      <Flex flexDirection="column" align="flex-start" w="100%">
        <Text pb={2} borderBottom="1px solid var(--dim-gray)" w="100%">
          <span style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
            Valor:{' '}
          </span>{' '}
          R$
          {data.value}
        </Text>
        <Text pt={2} pb={2} borderBottom="1px solid var(--dim-gray)" w="100%">
          <span style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
            Valor Proporcional a hora:
          </span>{' '}
          R${data.effectiveValue}
        </Text>
        <Text pt={2} pb={2} borderBottom="1px solid var(--dim-gray)" w="100%">
          <span style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
            Data:{' '}
          </span>
          {date}
        </Text>
        {data.checkinJustification && (
          <Text pt={2} pb={2} borderBottom="1px solid var(--dim-gray)" w="100%">
            <span style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
              Justificativa do check-in:
            </span>{' '}
            {data.checkinJustification}
          </Text>
        )}
        {data.checkoutJustification && (
          <Text pt={2} pb={2} borderBottom="1px solid var(--dim-gray)" w="100%">
            <span style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
              Justificativa do checkout:
            </span>{' '}
            {data.checkoutJustification}
          </Text>
        )}
        <Text pt={2} pb={2} borderBottom="1px solid var(--dim-gray)" w="100%">
          <span style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
            Horário:
          </span>{' '}
          {formatDate(data.startHour).hours()} -
          {formatDate(data.endHour).hours()}
        </Text>
        <Text pt={2} pb={2} borderBottom="1px solid var(--dim-gray)" w="100%">
          <span style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
            Tipo:
          </span>{' '}
          {typeDictionary(data.type)}
        </Text>
        <Text pt={2} pb={2} borderBottom="1px solid var(--dim-gray)" w="100%">
          <span style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
            Pagamento:
          </span>{' '}
          {data.inCash ? 'À Vista' : 'Normal'}
        </Text>
        {data.companyJustification && (
          <Text pt={2} pb={2} borderBottom="1px solid var(--dim-gray)" w="100%">
            <span style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
              Justificativa da empresa:
            </span>{' '}
            {data.companyJustification}
          </Text>
        )}
        <Flex
          pt={2}
          pb={2}
          borderBottom="1px solid var(--dim-gray)"
          w="100%"
          direction="column"
          justify="space-between"
          mb={5}
        >
          <Text color="var(--secondary)" fontWeight="bold">
            Observações:
          </Text>
          <Flex direction="column">
            {data.observations.map(res => (
              <Text>
                <b>{res.usercompany.user.name}:</b> {res.observation}
              </Text>
            ))}
          </Flex>
        </Flex>
        <Button
          title="voltar"
          variant="secondary"
          m="0 auto"
          onClick={closeModal}
        />
      </Flex>
    </ModalContainer>
  );
}
