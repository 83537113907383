import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import api from '~/services/api';
import convertBRL from '~/utils/convertBRL';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import ProgressModal from '~/components/Dashboard/Modals/ProgressModal';

import { Label } from '~/components/UI/Text';
import { ModalContainer } from '~/components/UI/Modal';
import { Divider, Button, Alert } from '~/components/ChakraComponents';
import { IText, IDate, IRadio, ICheck } from '~/components/UI/Inputs';

export default function SetupModal({ schedule, companyDoctor }) {
  const { hospitalId } = getParam('hospitalId');
  const { vacancyDate } = useParams();

  const dateInitialDate = () => {
    return moment(new Date(vacancyDate))
      .add(2, 'days')
      .format('YYYY-MM-DD');
  };

  const toDateInitialDate = () => {
    return moment(new Date(vacancyDate))
      .add(2, 'days')
      .add(1, 'month')
      .format('YYYY-MM-DD');
  };

  const [loading, setLoading] = useState(false);

  const [request, setRequest] = useState({});
  const [confirm, setConfirm] = useState(false);

  const [inputs, setInputs] = useState({
    value: schedule.defaultDoctorValue,
    type: 'cover',
    companyDoctor: null,
    isToEndSchedule: false,
    date: dateInitialDate(),
    toDate: toDateInitialDate(),
  });

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleRequest = () => {
    if (!inputs.date) {
      setConfirm(false);
      return toast.error('Por favor selecione a data.');
    }

    const finalRequest = {
      date: inputs.date,
      type: inputs.type,
      value: inputs.value,
      toDate: inputs.date,
      status: 'pending',
    };

    if (companyDoctor) {
      finalRequest.companyDoctor = companyDoctor;
    }

    if (['weekly', 'biweekly'].includes(finalRequest.type)) {
      if (inputs.isToEndSchedule) {
        delete finalRequest.toDate;
        finalRequest.isToEndSchedule = inputs.isToEndSchedule;
      } else {
        if (!inputs.toDate) {
          setConfirm(false);
          return toast.error('Por favor selecione a data final.');
        }

        finalRequest.toDate = inputs.toDate;
      }
    }

    setRequest(finalRequest);

    setLoading(true);
  };

  const createVacancies = () => {
    const url = `/companies/myhospitals/${hospitalId}/schedules/${schedule.id}/vacancies`;

    api
      .post(url, request)
      .then(() => {
        toast.success('Criado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(e => {
        setLoading(false);

        if (e.response) {
          if (e.response.data.error === 'inputs date less than today') {
            return toast.error('Não é possível criar vagas em datas antigas.');
          }

          if (
            e.response.data.error === 'to date greater than schedule endDate'
          ) {
            return toast.error('Data digitada passa do limite da escala.');
          }
        }

        internalServerError();
      });
  };

  useEffect(() => {
    if (loading) {
      setConfirm(false);
      createVacancies();
    }
  }, [loading]);

  const inputToDateLabel = () => {
    let title = 'Data Inicial';

    if (['cover', 'exception'].includes(inputs.type)) {
      title = 'Data';
    }

    return title;
  };

  return (
    <ModalContainer w="220px" align="flex-start">
      <IText
        required
        type="number"
        label={`valor (padrão: ${convertBRL(schedule.defaultDoctorValue)})`}
        placeholder={`Ex: ${convertBRL(schedule.defaultDoctorValue)}`}
        name="value"
        value={inputs.value}
        onChange={handleChanges}
      />
      <Divider />
      <Label>Tipo de vaga</Label>
      <IRadio
        label="Extra"
        checked={inputs.type === 'cover'}
        onChange={() => setInputs({ ...inputs, type: 'cover' })}
      />
      <IRadio
        label="Cobertura"
        checked={inputs.type === 'exception'}
        onChange={() => setInputs({ ...inputs, type: 'exception' })}
      />
      <IRadio
        label="Semanal"
        checked={inputs.type === 'weekly'}
        onChange={() => setInputs({ ...inputs, type: 'weekly' })}
      />
      <IRadio
        label="Quinzenal"
        checked={inputs.type === 'biweekly'}
        onChange={() => setInputs({ ...inputs, type: 'biweekly' })}
      />
      <Divider />
      <IDate
        initialDate={dateInitialDate()}
        label={inputToDateLabel()}
        onChange={date => setInputs({ ...inputs, date })}
      />
      {['weekly', 'biweekly'].includes(inputs.type) && (
        <>
          {!inputs.isToEndSchedule && (
            <IDate
              initialDate={toDateInitialDate()}
              label="Data Final"
              onChange={toDate => setInputs({ ...inputs, toDate })}
            />
          )}
          <Flex>
            <ICheck
              label="Criar até fim da escala"
              checked={inputs.isToEndSchedule}
              onChange={() =>
                setInputs({
                  ...inputs,
                  isToEndSchedule: !inputs.isToEndSchedule,
                })
              }
            />
          </Flex>
        </>
      )}
      <Divider />
      <Button title="criar" width="100%" onClick={() => setConfirm(true)} />
      <Alert
        isOpen={confirm}
        onClose={() => setConfirm(false)}
        onConfirm={handleRequest}
      />
      <ProgressModal
        loading={loading}
        text="Criando vagas... Por favor aguarde, isso pode demorar alguns minutos."
      />
    </ModalContainer>
  );
}
