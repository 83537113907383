import api from '~/services/api';
import { getParam } from '~/utils/url';

export const fetchContracts = (callback, pagination) => {
  const { hospitalId } = getParam();

  const [page, limit] = pagination;

  const offset = (page - 1) * limit;

  const url = `/companies/myhospitals/${hospitalId}/contracts-groups?limit=${limit}&offset=${offset}&sort=name&order=asc`;

  api.get(url).then(callback);
};
