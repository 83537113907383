import React, { useState, useRef, useEffect, useCallback } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import api from '~/services/api';
import weekDays from '~/constants/weekDays';
import internalServerError from '~/utils/internalServerError';

import PageLayout from '~/pages/_layouts/doctor/PageLayout';

import { Row, Column, Text } from '~/components/Kiwistrap/Page';
import { Modal } from '~/components/UI/Modal';

import {
  CandidaturesBtn,
  Calendar,
  Legends,
  VacancyCard,
  CheckinModal,
  CheckoutModal,
  ConfirmModal,
  InfoModal,
} from './components';

import { Agenda, AgendaRow, Day, Vacancies } from './styles';

export default function MyVacancies() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [vacancies, setVacancies] = useState();
  const [vacanciesDates, setVacanciesDates] = useState();
  const [clickedCard, setClickedCard] = useState();

  const infoModalRef = useRef(null);
  const confirmModalRef = useRef(null);
  const checkinModalRef = useRef(null);
  const checkoutModalRef = useRef(null);

  const openInfo = useCallback(() => {
    infoModalRef.current.openModal();
  }, []);

  const openConfirm = useCallback(() => {
    confirmModalRef.current.openModal();
  }, []);

  const openCheckin = useCallback(() => {
    checkinModalRef.current.openModal();
  }, []);

  const openCheckout = useCallback(() => {
    checkoutModalRef.current.openModal();
  }, []);

  const getVacancies = useCallback(async (date, val = 0) => {
    setLoadingInfo(true);

    const startDate = moment(`${date} 00:03:00`)
      .startOf('week')
      .add(val, 'week')
      .format('YYYY-MM-DD');

    const endDate = moment(`${date} 00:03:00`)
      .endOf('week')
      .add(val, 'week')
      .format('YYYY-MM-DD');

    try {
      const { data } = await api.get(
        `/doctors/myvacancies?startDate=${startDate}&endDate=${endDate}`
      );

      setVacancies(data);
      setVacanciesDates(Object.keys(data));
    } catch (e) {
      internalServerError();
    }

    setLoadingInfo(false);
  }, []);

  useEffect(() => {
    getVacancies(moment(new Date()).format('YYYY-MM-DD'));
    setLoadingPage(false);
  }, []);

  const handleVacancyOnClick = useCallback(clickedVacancy => {
    setClickedCard(clickedVacancy);

    const {
      date,
      status,
      checkin,
      checkout,
      startHour,
      endHour,
    } = clickedVacancy;

    const today = moment(new Date());
    const startDate = moment(new Date(startHour));
    const baseEndDate = moment(new Date(endHour));
    const endDate =
      baseEndDate <= startDate ? baseEndDate.add(1, 'days') : baseEndDate;

    const isExpired = moment(new Date(date)).diff(today, 'days') < 0;
    const isBetween = today.isBetween(startDate, endDate);
    const startDifference = today.diff(startDate, 'minutes');

    const canCheckin =
      isBetween || (startDifference >= -15 && startDifference < 0);

    let key = status;

    if (['pending', 'accepted'].includes(status) && isExpired) key = 'expired';
    if (status === 'accepted' && canCheckin) key = 'canCheckin';
    if (checkin === true && checkout === false) key = 'checkin';
    if (checkout === true) key = 'checkout';

    return {
      refused: () => toast.error('Você recusou esta vaga.'),
      pending: () => openConfirm(),
      expired: () => toast.error('Data limite atingida.'),
      accepted: () => openInfo(),
      canCheckin: () => openCheckin(),
      checkin: () => openCheckout(),
      checkout: () => toast.error('Check-out deste plantão já foi realizado.'),
    }[key]();
  }, []);

  const calcRowHeight = useCallback(({ length } = {}) => {
    return `${length ? length * 75 - 5 : 75}px`;
  }, []);

  const renderDateText = useCallback(() => {
    const startDate = moment(vacanciesDates[0]).format('DD/MM/YYYY');
    const endDate = moment(vacanciesDates[6]).format('DD/MM/YYYY');

    return `${startDate} - ${endDate}`;
  }, [vacanciesDates]);

  return (
    <PageLayout title="Meus Plantões" loading={loadingPage}>
      <Row justifyContent="center">
        <Agenda>
          <Row justifyContent="space-between">
            <Calendar getVacancies={getVacancies} />
            <Column height="auto">
              <Legends />
              <Column height="80px" justifyContent="center">
                {vacanciesDates ? (
                  <Text size="16px">
                    Exibindo plantões de <strong>{renderDateText()}</strong>.
                  </Text>
                ) : (
                  <Text size="16px">Nenhum plantão encontrado.</Text>
                )}
                <Text size="16px" padding="5px 0 0">
                  {loadingInfo
                    ? 'Carregando...'
                    : 'Clique no calendário para escolher uma semana.'}
                </Text>
              </Column>
              <CandidaturesBtn />
            </Column>
          </Row>
          <Column>
            {vacanciesDates?.map?.((day, i) => (
              <AgendaRow key={day} height={() => calcRowHeight(vacancies[day])}>
                <Day>
                  {weekDays[i]} <br /> {moment(day).format('DD/MM/YYYY')}
                </Day>
                <Vacancies>
                  {!loadingInfo &&
                    vacancies[day]?.map?.(vacancy => (
                      <VacancyCard
                        key={vacancy.id}
                        vacancy={vacancy}
                        onClick={() => handleVacancyOnClick(vacancy)}
                      />
                    ))}
                </Vacancies>
              </AgendaRow>
            ))}
          </Column>
        </Agenda>
      </Row>
      <Modal
        title="Plantão Aceito"
        ref={infoModalRef}
        component={InfoModal}
        vacancy={clickedCard}
      />
      <Modal
        title="Pendente"
        ref={confirmModalRef}
        component={ConfirmModal}
        vacancy={clickedCard}
      />
      <Modal
        title="Check-in"
        ref={checkinModalRef}
        component={CheckinModal}
        vacancy={clickedCard}
      />
      <Modal
        title="Check-out"
        ref={checkoutModalRef}
        component={CheckoutModal}
        vacancy={clickedCard}
      />
    </PageLayout>
  );
}
