import styled from 'styled-components';

export const Results = styled.div`
  overflow: hidden;

  width: 100%;

  position: absolute;
  top: 62px;
  right: 0;
  z-index: 10;

  display: grid;
  grid-template-rows: 40px;
  align-items: center;
  justify-items: center;

  background-color: var(--background);
  border: 2px solid var(--black);
  border-radius: var(--br-sm);
`;

export const Result = styled.span`
  cursor: pointer;

  width: 100%;
  height: 100%;

  padding: 4px;

  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: var(--dark-gray);
  background-color: var(--background);

  &:nth-child(odd) {
    background-color: rgba(45, 183, 248, 0.1);
  }
`;
