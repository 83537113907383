import React from 'react';
import moment from 'moment';
import { FaLayerGroup as AllSvg } from 'react-icons/fa';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  HStack,
  Flex,
  Text,
  Grid,
} from '@chakra-ui/react';

import { weekDaysAlt } from '~/constants/weekDays';
import { renderTimeSvg } from '~/pages/hospital/VacanciesV2/utils';

import { Spinner } from '~/components/ChakraComponents';
import { VacancyCard } from '~/components/UI/Cards';

// Title
const T = ({ children }) => (
  <Flex
    w="100%"
    minH="28px"
    align="center"
    justify="center"
    bgColor="gray.600"
    color="white"
    fontWeight="semibold"
  >
    <Text>{children}</Text>
  </Flex>
);

export default function MobileMonthAgenda({ dates, schedules, cardOnClick }) {
  if (!schedules) {
    return (
      <Flex w="100%" h="100%" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Accordion
      w="100%"
      minW="1200px"
      allowMultiple
      borderColor="gray.100"
      _focus={{ boxShadow: 'none' }}
    >
      <AccordionItem>
        <AccordionButton
          color="var(--primary)"
          _expanded={{ bg: 'var(--primary)', color: 'white' }}
        >
          <AccordionIcon />
          <HStack
            ml={4}
            flex="1"
            alignItems="center"
            spacing={2}
            fontWeight="semibold"
          >
            <AllSvg size={24} />
            <Text>Todos os Setores</Text>
          </HStack>
        </AccordionButton>
        <AccordionPanel p={0} mt="1px">
          {dates
            .reduce((all, one, i) => {
              const ch = Math.floor(i / 7);
              all[ch] = [].concat(all[ch] || [], one);
              return all;
            }, [])
            .map(week => (
              <React.Fragment key={Math.random(Math.random())}>
                <Grid
                  w="100%"
                  mb="1px"
                  gridGap="1px"
                  templateColumns="120px repeat(7, 1fr)"
                >
                  <T>Setor</T>
                  {week.map((weekDay, i) => (
                    <T key={weekDay}>
                      <Flex justify="center" textAlign="center">
                        {weekDaysAlt[new Date(weekDay).getDay()]}
                      </Flex>
                      <Flex justify="center" textAlign="center">
                        {moment(weekDay).format('DD/MM/YYYY')}
                      </Flex>
                    </T>
                  ))}
                  {[...new Array(7 - week.length)].map(missingCol => (
                    <Flex key={missingCol} w="100%" h="100%" />
                  ))}
                </Grid>
                <Grid
                  w="100%"
                  mb="1px"
                  gridGap="1px"
                  templateColumns="120px repeat(7, 1fr)"
                >
                  {schedules.map(schedule => (
                    <React.Fragment key={schedule.id}>
                      <Flex
                        w="120px"
                        h="100%"
                        px="4px"
                        align="center"
                        justify="center"
                        textAlign="center"
                        color="white"
                        bgColor="gray.500"
                        fontWeight="semibold"
                        wordBreak="break-all"
                        lineHeight="1"
                      >
                        {schedule.contract.sector}
                      </Flex>
                      {schedule.dates.map(date => (
                        <React.Fragment key={date.day}>
                          {week.includes(date.day) && (
                            <VStack spacing="1px">
                              {date.vacancies.map(vacancy => (
                                <VacancyCard
                                  isMonth
                                  key={vacancy.id}
                                  vacancy={vacancy}
                                  onClick={() => cardOnClick(vacancy)}
                                />
                              ))}
                            </VStack>
                          )}
                          {date.day === dates[dates.length - 1] &&
                            [...new Array(7 - week.length)].map(missingCol => (
                              <Flex key={missingCol} w="100%" h="100%" />
                            ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </Grid>
              </React.Fragment>
            ))}
        </AccordionPanel>
      </AccordionItem>
      {schedules.map(schedule => (
        <AccordionItem key={schedule.id}>
          <AccordionButton
            color="var(--primary)"
            _expanded={{ bg: 'var(--primary)', color: 'white' }}
          >
            <AccordionIcon />
            <HStack
              ml={4}
              flex="1"
              alignItems="center"
              spacing={2}
              fontWeight="semibold"
            >
              {renderTimeSvg(schedule.contract.start)}
              <Text>{schedule.contract.sector}</Text>
              <Text>
                {`${schedule.contract.start.slice(
                  0,
                  -3
                )} às ${schedule.contract.end.slice(0, -3)}`}
              </Text>
            </HStack>
          </AccordionButton>
          <AccordionPanel p={0} mt="1px">
            <Grid w="100%" gridGap="1px" templateColumns="repeat(7, 1fr)">
              {schedule.dates.map(date => (
                <VStack key={date.day} spacing="1px">
                  <T>{moment(date.day).format('DD/MM/YYYY')}</T>
                  {date.vacancies.map(vacancy => (
                    <VacancyCard
                      isMonth
                      key={vacancy.id}
                      vacancy={vacancy}
                      onClick={() => cardOnClick(vacancy)}
                    />
                  ))}
                </VStack>
              ))}
            </Grid>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}
