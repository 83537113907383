import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 12px;
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  /* align-items: center; */

  border-radius: 4px;

  box-shadow: var(--shadow);
  background-color: var(--background-alt);
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  font-size: 20px;
  font-weight: bold;

  color: var(--primary);

  img {
    width: 36px;
    height: 36px;

    margin-right: 12px;

    border: 3px solid var(--primary);
    border-radius: var(--br-sm);

    background-color: var(--background-alt);

    flex-shrink: none;
    object-fit: cover;
  }
`;
