import React, { useCallback } from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';

import { Card, Text, Sector } from './styles';

export default function OpenVacancyCard({ vacancy, onClick }) {
  const renderHourText = useCallback(() => {
    const { startHour, endHour } = vacancy;

    const formattedStart = moment(new Date(startHour)).format('HH:mm');
    const formattedEnd = moment(new Date(endHour)).format('HH:mm');

    return `${formattedStart}h às ${formattedEnd}h`;
  }, []);

  return (
    <Card onClick={onClick}>
      <Flex flexDir="column" h="100px" align="center" justify="center">
        <Text>vaga aberta</Text>
      </Flex>
      <Flex
        h="40px"
        px="5px"
        flexDir="column"
        backgroundColor="var(--background-alt)"
        borderBottomLeftRadius="4px"
        borderBottomRightRadius="4px"
      >
        <Sector>{vacancy.schedule.contract.sector}</Sector>
        <Sector>{renderHourText()}</Sector>
      </Flex>
    </Card>
  );
}
