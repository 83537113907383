import React from 'react';
import { Text } from '@chakra-ui/react';

const Legend = React.memo(() => (
  <>
    <Text as="span">
      * Segure e arraste para
      <Text
        as="span"
        mx="0.5ch"
        fontWeight="bold"
        color="var(--primary)"
        textTransform="uppercase"
      >
        direita
      </Text>
      os plantões que vão pertencer ao grupo.
    </Text>
    <Text as="span">
      * A ordem na visualização da escala será baseada na ordem dos cartões em
      <Text
        as="span"
        ml="0.5ch"
        fontWeight="bold"
        color="var(--primary)"
        textTransform="uppercase"
      >
        selecionados
      </Text>
      .
    </Text>
  </>
));

export default Legend;
