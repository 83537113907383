import React from 'react';

import childrenWithProps from '~/utils/childrenWithProps';

import { getUser } from '~/services/auth';

import Nav from './Nav';
import NavMobile from './NavMobile';
import UserInfo from './UserInfo';

import { Container, Content } from './styles';

const CompanyLayout = ({ children }) => (
  <Container>
    <Nav />
    <NavMobile />
    <Content>
      <UserInfo />
      {childrenWithProps(children, { user: getUser() })}
    </Content>
  </Container>
);

export default CompanyLayout;
