import styled from 'styled-components';

export const Title = styled.h1`
  margin: 20px 0;

  font-size: 22px;
  font-weight: bold;

  color: var(--secondary);
`;
