import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

export const renderDoctor = vacancy => {
  let isOpen = true;
  let topText = 'Vaga Aberta';

  if (vacancy.doctor.name) {
    isOpen = false;
    topText = vacancy.doctor.name;
  }

  return (
    <Flex flexDir="column">
      <Text fontWeight="semibold" color={isOpen ? 'green.400' : 'blue.400'}>
        {topText}
      </Text>
      <Text mt={1}>{vacancy.schedule.contract.specialty.name}</Text>
    </Flex>
  );
};
