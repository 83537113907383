import React, { useState, useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import { Flex, Text } from '@chakra-ui/react';

import api from '~/services/api';

import { Spinner, Button } from '~/components/ChakraComponents';
import { Modal } from '~/components/UI/Modal';
import CreateAlertModal from '~/components/Dashboard/Modals/CreateAlertModal';

export default function AlertModal({ doctorId }) {
  const [loading, setLoading] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const createAlertModalRef = useRef(null);

  const doctor = { id: doctorId };

  const openCreateAlert = useCallback(() => {
    return createAlertModalRef.current.openModal();
  }, []);

  const fetchData = useCallback(() => {
    const url = `/companies/mydoctors/${doctorId}/alerts`;

    api.get(url).then(response => {
      setLoading(false);
      setAlerts(response.data.data);
    });
  }, [doctorId]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Flex
      minW="200px"
      maxW="400px"
      maxH="80vh"
      flexDir="column"
      alignItems="center"
      overflowY="auto"
      overflowX="hidden"
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {alerts.length > 0 ? (
            alerts.map(alert => (
              <Flex
                key={alert.id}
                w="100%"
                p={2}
                mb={2}
                flexDir="column"
                borderRadius="4px"
                backgroundColor="white"
              >
                <Text mb={1} color="var(--primary)" fontWeight="bold">
                  {moment(new Date(alert.createdAt)).format('DD/MM/YYYY HH:mm')}
                </Text>
                <Text fontSize="md" fontWeight="semibold" color="gray.500">
                  {alert.content}
                </Text>
              </Flex>
            ))
          ) : (
            <Text
              textAlign="center"
              fontSize="md"
              fontWeight="semibold"
              color="gray.500"
            >
              Esse médico não possui alertas.
            </Text>
          )}
          <Flex justifyContent="space-between" mt="20px">
            <Button
              title="adicionar alertas"
              variant="warning"
              onClick={openCreateAlert}
            />
          </Flex>
        </>
      )}

      <Modal
        ref={createAlertModalRef}
        title="Criar Alerta"
        component={CreateAlertModal}
        doctor={doctor}
      />
    </Flex>
  );
}
