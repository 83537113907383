import React from 'react';
import {
  Container,
  Content,
  WrapperLabel,
  Label,
  WrapperError,
  TextError,
} from './styles';

export default function InputArea({
  name,
  type,
  placeholder,
  rows,
  onChange,
  label,
  value,
  error,
  style,
}) {
  return (
    <Container style={style}>
      {label && (
        <WrapperLabel>
          <Label>{label}</Label>
        </WrapperLabel>
      )}
      <Content
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        rows={rows}
        value={value}
      />
      <WrapperError>{error && <TextError>{error}</TextError>}</WrapperError>
    </Container>
  );
}
