import { Children, cloneElement } from 'react';

// Adds props to {children}
export default (children, props) => {
  const newChildren = Children.map(children, child => {
    const clone = cloneElement(child, props);

    return clone;
  });

  return newChildren;
};
