import React, { useState, useEffect } from 'react';

import { getUser } from '~/services/auth';

import Analyzing from './components/Analyzing';
import NoDocuments from './components/NoDocuments';

import { PageContainer } from './styles';

export default function Profile() {
  const [status, setStatus] = useState();

  useEffect(() => {
    const user = getUser();

    if (user.documentStatus) setStatus(user.documentStatus);
  }, []);

  const renderComponent = () => {
    let component = null;

    switch (status) {
      case 'analyzing':
        component = <Analyzing />;
        break;

      case 'noDocuments':
        component = <NoDocuments status={status} />;
        break;

      case 'refused':
        component = <NoDocuments status={status} />;
        break;

      default:
        break;
    }

    return component;
  };

  return <PageContainer>{renderComponent()}</PageContainer>;
}
