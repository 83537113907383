import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from '@chakra-ui/react';
import { FaFileMedicalAlt } from 'react-icons/fa';

import MenuLeft from './components/MenuLeft';

import PageLayout from '~/pages/_layouts/contractorhospital/PageLayout';

const ContractSvg = styled(FaFileMedicalAlt)`
  margin-bottom: 20px;

  font-size: 120px;

  color: var(--primary);
`;

export default function GroupsPageNoneChoosen() {
  return (
    <PageLayout disableDefaultButtons userInfoButtons={<MenuLeft />}>
      <Flex
        h="calc(100vh - 120px)"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <ContractSvg />
        <Text fontSize="24px" color="var(--primary)">
          Por favor selecione um contrato.
        </Text>
      </Flex>
    </PageLayout>
  );
}
