import React from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Button } from '~/components/ChakraComponents/';
import { Title, Text } from '~/components/Kiwistrap/Page';

import { SuccessSvg } from './styles';

import { ResponsivePhaseContainer } from '../../styles';

export default function Success({ email }) {
  const sendEmail = async () => {
    try {
      await api.post('/confirm-email', { email });
      toast.success('E-mail de confirmação enviado com sucesso!');
    } catch (error) {
      toast.error('Falha ao enviar e-mail de confirmação. Tente novamente.');
    }
  };

  return (
    <ResponsivePhaseContainer>
      <SuccessSvg />
      <Title center margin="10px 0" size={18} color="var(--green)">
        Cadastro realizado com sucesso!
      </Title>
      <Text>Por favor verifique seu e-mail para continuar.</Text>
      <Text padding="40px 0 10px">Não recebeu e-mail de confirmação?</Text>
      <Button title="enviar novamente" onClick={sendEmail} width="200px" />
      <Button
        title="entrar no site"
        to="/login"
        width="200px"
        margin="20px 0 0"
      />
    </ResponsivePhaseContainer>
  );
}
