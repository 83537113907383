import React, { useState, useRef, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { differenceInDays } from 'date-fns';

import api from '~/services/api';
import weekDays from '~/constants/weekDays';
import internalServerError from '~/utils/internalServerError';

import { useViewSchedules } from '~/hooks/modals/ViewSchedules/useViewSchedules';

import { Button } from '~/components/ChakraComponents/';
import { Title, Div, SubTitle } from '~/components/Kiwistrap/Page';
import { Modal } from '~/components/UI/Modal';

import Info from './Info';
import Legends from './Legends';
import CandidateModal from './CandidateModal';
import OpenVacancyCard from './OpenVacancyCard';
import VacancyCard from './VacancyCard';

import {
  ModalContainer,
  Select,
  SectionVisualization,
  VisualizationTitle,
  VisualizationContainer,
  VisualizationBtns,
  Agenda,
  DayCard,
  DayCardText,
} from './styles';

export default function ViewSchedules({ hospital }) {
  const {
    scheduleType,
    vacanciesDays,
    vacanciesDaysNames,
    handleDates,
    handleNowDate,
  } = useViewSchedules();

  const [specialties, setSpecialties] = useState();
  const [selectedSpecialty, setSelectedSpecialty] = useState('');
  const [info, setInfo] = useState();
  const [modalVacancy, setModalVacancy] = useState();

  const candidateModalRef = useRef(null);

  const openCandidate = useCallback(() => {
    candidateModalRef.current.openModal();
  }, []);

  useEffect(() => {
    api
      .get(`/doctors/hospital/${hospital.id}/specialties`)
      .then(response => {
        const {
          data: { data },
        } = response;

        const sortedData = data.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        setSpecialties(sortedData);
      })
      .catch(internalServerError);
  }, []);

  useEffect(() => {
    if (selectedSpecialty === '') return;

    api
      .get(
        `/doctors/hospital/${hospital.id}/specialties/${selectedSpecialty}/schedules`
      )
      .then(response => {
        const {
          data: { data },
        } = response;

        setInfo(data);
      })
      .catch(internalServerError);

    handleDates(0, selectedSpecialty, hospital.id, scheduleType);
  }, [selectedSpecialty]);

  const handleNow = () => {
    handleNowDate(selectedSpecialty, hospital.id, scheduleType);
  };

  const openVacancyCardOnClick = vacancy => {
    if (differenceInDays(new Date(vacancy.startHour), new Date()) < 0) {
      return toast.error('Não é possível se candidatar a uma vaga antiga.');
    }

    setModalVacancy(vacancy);

    return openCandidate();
  };

  return (
    <ModalContainer>
      <SubTitle color="var(--primary)" size="16px">
        Selecione a especialidade
      </SubTitle>
      <Select onChange={e => setSelectedSpecialty(e.target.value)}>
        {selectedSpecialty === '' && <option value="">Selecione...</option>}
        {specialties &&
          specialties.map(specialty => (
            <option key={specialty.id} value={specialty.id}>
              {specialty.name}
            </option>
          ))}
      </Select>
      {selectedSpecialty !== '' ? (
        <>
          <Info data={info} />
          <SectionVisualization>
            <VisualizationTitle>visualização</VisualizationTitle>
            <VisualizationContainer>
              <VisualizationBtns>
                <Button
                  height="40px"
                  title="voltar"
                  onClick={() =>
                    handleDates(
                      -1,
                      selectedSpecialty,
                      hospital.id,
                      scheduleType
                    )
                  }
                />
                <Button
                  height="40px"
                  title={scheduleType === 'week' ? 'Semana Atual' : 'Mês atual'}
                  onClick={handleNow}
                />
                <Button
                  height="40px"
                  title="avançar"
                  onClick={() =>
                    handleDates(1, selectedSpecialty, hospital.id, scheduleType)
                  }
                />
              </VisualizationBtns>
              <Legends />
            </VisualizationContainer>
          </SectionVisualization>
          <Agenda>
            {vacanciesDaysNames.map((day, i) => (
              <Div key={day}>
                <DayCard>
                  <DayCardText>{`${weekDays[i]}`}</DayCardText>
                  <DayCardText>{`${day
                    .split('-')
                    .reverse()
                    .join('/')}`}</DayCardText>
                </DayCard>
                {(vacanciesDays[day] || []).map(vacancy => (
                  <React.Fragment key={vacancy.id}>
                    {vacancy.companyDoctor ? (
                      <VacancyCard
                        vacancy={vacancy}
                        onClick={() =>
                          toast.error('Essa vaga já possui médico.')
                        }
                      />
                    ) : (
                      <OpenVacancyCard
                        vacancy={vacancy}
                        onClick={() => openVacancyCardOnClick(vacancy)}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Div>
            ))}
          </Agenda>
        </>
      ) : (
        <Div justifyContent="center" alignItems="center">
          <Title center>Nenhuma especialidade selecionada</Title>
        </Div>
      )}
      <Modal
        title="Candidatar-se"
        ref={candidateModalRef}
        component={CandidateModal}
        vacancy={modalVacancy}
      />
    </ModalContainer>
  );
}
