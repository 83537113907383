import styled from 'styled-components';
import { AiFillClockCircle } from 'react-icons/ai';

export const Card = styled.div`
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  box-shadow: var(--shadow);
  background-color: var(--background-alt);
`;

export const Pending = styled(AiFillClockCircle)`
  margin-bottom: 20px;

  font-size: 80px;

  color: var(--primary);
`;
