import styled from 'styled-components';

export const H1 = styled.h1`
  font-weight: bold;

  width: ${props => props.width || '100%'};
  margin: ${props => props.margin || '18px 0 28px 0'};

  color: ${props => props.color || 'var(--primary)'};
  font-size: ${props => (props.size ? `${props.size}px` : '32px')};
  text-align: ${props => (props.textAlign ? 'center' : 'left')};
`;
