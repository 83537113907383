import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 92px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: var(--background);

  @media (min-width: 375px) and (max-width: 414px) {
    margin-top: 10px;
  }
`;

export const ResponsivePhaseContainer = styled.div`
  width: 500px;

  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100vw;
  }
`;
