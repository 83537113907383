import styled from 'styled-components';
import {
  FaTimes,
  FaClock,
  FaHourglassEnd,
  FaCheck,
  FaUserEdit,
  FaUserCheck,
} from 'react-icons/fa';

export const Container = styled.div`
  cursor: pointer;

  width: 100%;

  display: flex;
  align-items: center;

  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);

  background-color: ${props => props.boxColor};

  transition: all 50ms ease-in-out;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    margin-left: 10px;
  }
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;

  margin: 0 20px;

  background-color: var(--background-alt);

  border-radius: 50%;
  border: 2px solid var(--background-alt);

  object-fit: cover;
  flex-shrink: none;
`;

export const TextContainer = styled.div`
  display: flex;
`;

export const Text = styled.span`
  width: 100%;

  padding: 2px 0;

  font-size: 15px;
  font-weight: 600;

  color: var(--background);
`;

export const SvgContainer = styled.div`
  padding: 8px;

  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: none;

  border-radius: 50%;

  border: 2px solid var(--background-alt);

  background-color: ${props => props.statusColor};

  svg {
    font-size: 20px;

    color: var(--background-alt);
  }
`;

export const RefusedSvg = styled(FaTimes)``;
export const PendingSvg = styled(FaClock)``;
export const ExpiredSvg = styled(FaHourglassEnd)``;
export const ConfirmedSvg = styled(FaCheck)``;
export const CheckinSvg = styled(FaUserEdit)``;
export const CheckoutSvg = styled(FaUserCheck)``;
