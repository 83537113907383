import React, { useState, useRef, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Flex, HStack, useBoolean } from '@chakra-ui/react';

import api from '~/services/api';
import { getParam } from '~/utils/url';

import TaxDeductionsModal from './TaxDeductionsModal';

import { Modal, ModalContainer } from '~/components/UI/Modal';

import { ISpec, IText, IMoney } from '~/components/UI/Inputs';
import { Button, Alert, Divider } from '~/components/ChakraComponents';

export default function CreateContractModal({ group, closeModal }) {
  const [loading, setLoading] = useBoolean();

  const [inputs, setInputs] = useState({});
  const [taxDeductions, setTaxDeductions] = useState([]);
  const [confirmSubmit, setConfirmSubmit] = useBoolean();

  const { hospitalId } = getParam('hospitalId');

  const taxDeductionsModalRef = useRef(null);

  const openTaxDeductions = useCallback(() => {
    taxDeductionsModalRef.current.openModal();
  }, []);

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleChangesSpec = specialty => {
    setInputs({ ...inputs, specialty });
  };

  const onSubmit = () => {
    if (!inputs.sector || !inputs.sector === '') {
      setLoading.off();
      return toast.error('O setor é obrigatorio');
    }

    if (!inputs.start || !inputs.start === '') {
      setLoading.off();
      return toast.error('A hora inicial é obrigatoria');
    }

    if (!inputs.end || !inputs.end === '') {
      setLoading.off();
      return toast.error('A hora final é obrigatoria');
    }

    if (inputs.start.length < 5) {
      setLoading.off();
      return toast.error('Hora inicial inválida.');
    }

    if (inputs.end.length < 5) {
      setLoading.off();
      return toast.error('Hora final inválida.');
    }

    if (inputs.start === inputs.end) {
      setLoading.off();
      return toast.error('Hora inicial e final não podem ser iguais.');
    }

    if (!inputs.value || !inputs.value === '') {
      setLoading.off();
      return toast.error('O valor é obrigatorio');
    }

    if (!inputs.closeVacancyDay || !inputs.closeVacancyDay === '') {
      setLoading.off();
      return toast.error('O dia de fechamento é obrigatorio');
    }

    if (!inputs.closeFinanceDay || !inputs.closeFinanceDay === '') {
      setLoading.off();
      return toast.error('O dia de pagamento é obrigatorio');
    }

    const request = { ...inputs };

    request.group = group.id;
    request.value = +request.value;

    const contractUrl = `/companies/myhospitals/${hospitalId}/contracts`;

    api.post(contractUrl, request).then(response => {
      const taxUrl = `/companies/myhospitals/${hospitalId}/contracts/${response.data.id}/taxdeductions`;

      api.post(taxUrl, { taxDeductions }).then(() => {
        toast.success('Plantão criado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      });
    });
  };

  useEffect(() => {
    if (loading) {
      setConfirmSubmit.off();
      onSubmit();
    }
  }, [loading]);

  return (
    <ModalContainer loading={loading}>
      <Flex pb={4} justify="center">
        <Button title="Deduções Fiscais" onClick={openTaxDeductions} />
      </Flex>
      <HStack>
        <ISpec required setSpecialtyId={handleChangesSpec} />
        <IText
          required
          label="setor"
          placeholder="Ex: Emergência"
          name="sector"
          onChange={handleChanges}
        />
      </HStack>
      <HStack>
        <IText
          required
          label="horário inicial"
          placeholder="Ex: 07:00"
          name="start"
          useCleave
          options={{
            time: true,
            timePattern: ['h', 'm'],
          }}
          onChange={handleChanges}
        />
        <IText
          required
          label="horário final"
          placeholder="Ex: 19:00"
          name="end"
          useCleave
          options={{
            time: true,
            timePattern: ['h', 'm'],
          }}
          onChange={handleChanges}
        />
      </HStack>
      <HStack>
        <IMoney
          required
          label="valor por plantão"
          placeholder="Ex: 1200"
          name="value"
          value={inputs.value}
          onChange={handleChanges}
        />
        <IText
          label="pagador"
          placeholder="Ex: Organização LTDA"
          name="payer"
          onChange={handleChanges}
        />
      </HStack>
      <HStack>
        <IText
          required
          label="dia de fechamento"
          placeholder="Ex: 15"
          name="closeVacancyDay"
          maxLength={2}
          onChange={handleChanges}
        />
        <IText
          required
          label="dia de pagamento"
          placeholder="Ex: 17"
          name="closeFinanceDay"
          maxLength={2}
          onChange={handleChanges}
        />
      </HStack>
      <Divider />
      <Flex justify="space-between">
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <Button
          title="confirmar"
          variant="success"
          onClick={setConfirmSubmit.on}
        />
      </Flex>
      <Alert
        isOpen={confirmSubmit}
        onClose={setConfirmSubmit.off}
        onConfirm={setLoading.on}
      />
      <Modal
        title="Deduções Fiscais"
        ref={taxDeductionsModalRef}
        component={TaxDeductionsModal}
        taxDeductions={taxDeductions}
        setTaxDeductions={setTaxDeductions}
      />
    </ModalContainer>
  );
}
