import styled from 'styled-components';
import { AiFillDelete, AiOutlineDownload } from 'react-icons/ai';

export const Letter = styled.span`
  cursor: pointer;

  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;

  text-decoration: ${props => (props.isChoosen ? 'underline' : 'none')};
  color: ${props => (props.isChoosen ? 'var(--secondary)' : 'var(--primary)')};

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 1180px) {
    font-size: 12px;
  }
`;

export const ItemList = styled.div`
  width: 100%;

  padding: 8px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #e7e7e7;

  &:last-child {
    border-bottom: none;
  }

  span {
    font-size: 15px;
    color: var(--dark-gray);
  }

  a:first-child {
    margin-right: 10px;

    font-weight: 600;
    text-transform: capitalize;

    color: var(--primary);

    &:hover {
      text-decoration: underline;

      color: var(--hover-primary);
    }
  }
`;

export const DownloadSvg = styled(AiOutlineDownload)`
  font-size: 38px;

  color: var(--primary);

  &:hover {
    color: var(--hover-primary);
  }

  @media screen and (max-width: 1180px) {
    font-size: 18px;
  }
`;

export const TrashSvg = styled(AiFillDelete)`
  cursor: pointer;

  font-size: 38px;

  color: var(--primary);

  &:hover {
    color: var(--red);
  }

  @media screen and (max-width: 1180px) {
    font-size: 18px;
  }
`;

export const Icon = styled.img`
  margin-top: -5px;
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 0 10px;
`;

export const NameLink = styled.a`
  text-decoration: none;
  color: var(--light-gray);
`;
