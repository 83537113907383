import React, { useState, useCallback, useEffect } from 'react';
import { Text } from '@chakra-ui/react';
import { Container, Row, Col } from 'react-bootstrap';

import api from '~/services/api';
import convertBRL from '~/utils/convertBRL';
import capitalizeString from '~/utils/capitalizeString';

import BarsGraph from './BarsGraph';

import PageLayout from '~/pages/_layouts/company/PageLayout';

import { Button } from '~/components/ChakraComponents/';

export default function Yearly() {
  const [data, setData] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  const fetchData = useCallback(() => {
    const url = `/companies/reports/yearlyresumecost?year=${new Date().getFullYear()}`;

    api.get(url).then(response => {
      const {
        data: { monthsData },
      } = response;

      monthsData.forEach(month => {
        month.month = capitalizeString(month.month);
      });

      setData(monthsData);
      setLoadingPage(false);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageLayout loading={loadingPage}>
      <Container>
        <Button
          title="voltar"
          to="/company/spendcontrol"
          variant="secondary"
          height="40px"
          width="100px"
          margin="0 0 20px"
        />
        <BarsGraph data={data} />
        <Row>
          {data.map(month => (
            <Col md key={month.month}>
              <Text
                mt={3}
                mb={2}
                size="2xl"
                fontWeight="bold"
                color="var(--primary)"
              >
                {month.month}
              </Text>
              <Text
                size="md"
                color={month.doctorsValue === 0 ? 'gray.500' : 'black'}
              >
                {convertBRL(month.doctorsValue)}
              </Text>
            </Col>
          ))}
        </Row>
      </Container>
    </PageLayout>
  );
}
