import React from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Button } from '~/components/ChakraComponents/';
import { Div, Row } from '~/components/Kiwistrap/Page';
import { Form, Field } from '~/components/Kiwistrap/Form';

export default function EditPasswordModal({ onClose }) {
  const handleSubmit = async data => {
    if (!data.password_current) {
      return toast.error('Por favor digite sua senha atual.');
    }

    if (!data.password) {
      return toast.error('Por favor digite uma nova senha.');
    }

    if (!data.password_confirm) {
      return toast.error('Por favor confirme a senha nova.');
    }

    if (data.password !== data.password_confirm) {
      return toast.error('As senhas digitadas são diferentes.');
    }

    const request = {
      password: data.password,
      previousPassword: data.password_current,
    };

    try {
      await api.patch('/doctors/myprofile', request);
    } catch (e) {
      if (e.response) {
        if (e.response.data.error === 'unauthorized') {
          return toast.error('Senha atual digitada é invalida.');
        }
      }

      return toast.error(
        'Houve um problema com nossos servidores, tente novamente.'
      );
    }

    toast.success('Senha alterada com sucesso!');

    return onClose();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Div alignItems="center" justifyContent="center" width="400px">
        <Field label="senha atual" name="password_current" type="password" />
        <Field label="nova senha" name="password" type="password" />
        <Field
          label="confirmar senha"
          name="password_confirm"
          type="password"
        />
        <Row justifyContent="space-between" margin="20px 0 0">
          <Button
            title="cancelar"
            variant="secondary"
            type="button"
            onClick={onClose}
          />
          <Button title="editar" variant="success" type="submit" />
        </Row>
      </Div>
    </Form>
  );
}
