import styled from 'styled-components';
import Cleave from 'cleave.js/react';

export const CleaveInput = styled(Cleave)`
  width: 100%;
  height: 40px;
  border: 2px solid var(--black);
  border-radius: var(--br-sm);
  background-color: #fff;
  padding: 10px;

  :valid {
    background-color: var(--background);
  }
`;

export const Container = styled.div`
  .input {
    width: 100%;
    height: 40px;
    border: 2px solid var(--black);
    border-radius: var(--br-sm);
    background-color: #fff;
    padding: 10px;

    :valid {
      background-color: var(--background);
    }
  }
`;
