import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

export default function ICheck({
  onChange,
  checked,
  label,
  isDisabled = false,
}) {
  return (
    <Flex
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      onClick={!isDisabled && onChange}
    >
      <input
        type="checkbox"
        checked={checked}
        style={{ cursor: 'pointer' }}
        disabled={isDisabled}
      />
      <Text
        ml="8px"
        userSelect="none"
        fontWeight="semibold"
        color={checked ? 'var(--blue)' : 'var(--light-gray)'}
        checked={checked}
      >
        {label}
      </Text>
    </Flex>
  );
}
