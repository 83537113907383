import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';

import api from '~/services/api';
import capitalizeString from '~/utils/capitalizeString';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import Legend from '../Legend';
import { IText } from '~/components/UI/Inputs';
import { DragNDrop } from '~/components/UI/Sections';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider } from '~/components/ChakraComponents';

export default function EditScheduleGroupModal({ selected, closeModal }) {
  const [name, setName] = useState(selected.name);
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);

  const { hospitalId } = getParam('hospitalId');

  useEffect(() => {
    const url = `/companies/myhospitals/${hospitalId}/contracts/`;

    api.get(url).then(response => {
      const isChoosenIds = selected.schedules.map(schedule => schedule.id);

      const contracts = response.data.data.filter(contract => {
        const specialty = contract.specialty.name;
        const sector = contract.sector;
        const start = contract.start.slice(0, -3);
        const end = contract.end.slice(0, -3);

        contract.name = `${specialty} - ${sector} - ${start}h às ${end}h`;

        return contract.schedule && contract.isActive;
      });

      const finalNotChoosen = contracts
        .filter(contract => !isChoosenIds.includes(contract.schedule.id))
        .sort((a, b) => {
          if (a.specialty.name < b.specialty.name) return -1;
          if (a.specialty.name > b.specialty.name) return 1;
          return 0;
        });

      const choosen = contracts.filter(contract =>
        isChoosenIds.includes(contract.schedule.id)
      );

      const finalChoosen = [];

      selected.schedules.forEach(schedule => {
        const item = choosen.find(
          contract => contract.id === schedule.contract
        );

        if (item) finalChoosen.push(item);
      });

      setLists([
        {
          title: 'Plantões',
          cards: finalNotChoosen,
        },
        {
          title: 'Selecionados',
          cards: finalChoosen,
        },
      ]);

      setLoading(false);
    });
  }, []);

  const editGroup = () => {
    if (name.length < 2) {
      return toast.error('Por favor digite um nome do grupo válido.');
    }

    if (lists[1].cards.length === 0) {
      return toast.error('Por favor escolha pelo menos 1 plantão.');
    }

    const request = {
      name: capitalizeString(name),
      schedules: lists[1].cards.map(schedule => schedule.schedule.id),
    };

    const url = `/companies/myhospitals/${hospitalId}/schedulesgroup/${selected.id}`;

    api
      .patch(url, request)
      .then(() => {
        toast.success('Grupo editado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  };

  return (
    <ModalContainer loading={loading}>
      <IText
        required
        label="nome do grupo"
        placeholder="Ex: UTI"
        maxLength="64"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Legend />
      <DragNDrop
        startingLists={lists}
        updateLists={setLists}
        card={(provided, snapshot, item) => (
          <Text
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            w="100%"
            p="0.5rem"
            mb="0.5rem"
            bgColor="var(--background-alt)"
            borderRadius="4px"
            userSelect="none"
            fontSize="0.7rem"
            color={snapshot.isDragging ? 'gray' : 'black'}
            border={
              snapshot.isDragging
                ? '1px solid var(--secondary)'
                : '1px solid var(--dim-gray)'
            }
          >
            {item.name.split('-')[0]}
            <br />
            {item.name.split('-')[1]}
            <br />
            {item.name.split('-')[2]}
          </Text>
        )}
      />
      <Divider />
      <Flex justifyContent="space-between">
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <Button title="editar" variant="success" onClick={editGroup} />
      </Flex>
    </ModalContainer>
  );
}
