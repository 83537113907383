import styled from 'styled-components';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';

export const StarFull = styled(BsStarFill)`
  padding: 0 1%;
  color: var(--yellow);

  font-size: ${props => props.size};
`;

export const StarHalf = styled(BsStarHalf)`
  padding: 0 1%;
  color: var(--yellow);

  font-size: ${props => props.size};
`;

export const StarEmpty = styled(BsStar)`
  padding: 0 1%;
  color: var(--yellow);

  font-size: ${props => props.size};
`;

export const NoRatingText = styled.p`
  width: 100%;

  text-align: center;
  font-size: 14px;
  color: var(--light-gray);
`;
