import React from 'react';

import userKey from '~/utils/userKey';

import CompanyPageLayout from '~/pages/_layouts/company/PageLayout';
import ContractorPageLayout from '~/pages/_layouts/contractor/PageLayout';
import CompanyHospitalPageLayout from '~/pages/_layouts/hospital/PageLayout';
import ContractorHospitalPageLayout from '~/pages/_layouts/contractorhospital/PageLayout';

export const dashboardBuilder = children => {
  const PageLayout = {
    company: CompanyPageLayout,
    contractor: ContractorPageLayout,
    companyhospital: CompanyHospitalPageLayout,
    contractorhospital: ContractorHospitalPageLayout,
  }[userKey()];

  return <PageLayout>{children}</PageLayout>;
};
