import React, { useState } from 'react';
import { Flex, Text, HStack } from '@chakra-ui/react';

import api from '~/services/api';
import cnpjMask from '~/utils/cnpjMask';
import onlyNumberHandleChangeCnpj from '~/utils/cnpjMaskWithAscii';

import { getUser } from '~/services/auth';
import { ufOptions } from '~/constants/uf';

import { Avatar, Button, Card } from '~/components/ChakraComponents/';
import { IText, ISelect } from '~/components/UI/Inputs';
import { ModalContainer } from '~/components/UI/Modal';

export default function ContractorsModal({ setContractor, closeModal }) {
  const user = getUser();

  const [inputs, setInputs] = useState({ uf: user.uf });
  const [contractorsList, setContractorsList] = useState([]);

  const handleChanges = e => {
    if (e.target.name === 'cnpj') {
      return setInputs({
        ...inputs,
        [e.target.name]: onlyNumberHandleChangeCnpj(e.target.value),
      });
    }
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSearch = () => {
    const { name, cnpj, uf, city } = inputs;

    const url = `/companies/contractors?name=${name || ''}&cnpj=${cnpj ||
      ''}&uf=${uf || ''}&city=${city || ''}`;

    api.get(url).then(response => {
      setContractorsList(response.data.data);
    });
  };

  return (
    <ModalContainer w="1200px">
      <HStack align="flex-end">
        <IText
          label="nome"
          name="name"
          value={inputs.name}
          onChange={handleChanges}
        />
        <IText
          type="string"
          label="cnpj"
          name="cnpj"
          value={inputs.cnpj}
          onChange={handleChanges}
        />
        <ISelect
          label="uf"
          name="uf"
          options={ufOptions}
          value={inputs.uf}
          onChange={handleChanges}
        />
        <IText
          label="cidade"
          name="city"
          value={inputs.city}
          onChange={handleChanges}
        />
        <Flex h="100%" alignItems="flex-end">
          <Button
            title="buscar"
            height="40px"
            onClick={onSearch}
            onChange={handleChanges}
          />
        </Flex>
      </HStack>
      <Flex flexDir="column" w="100%" h="100%" overflowY="auto">
        {contractorsList.map(contractor => (
          <Card mb={3} _hover={{ backgroundColor: 'var(--hover-secondary)' }}>
            <Flex w="100%" h="100%" alignItems="center">
              <Flex mr={3}>
                <Avatar src={contractor.avatar} name={contractor.name} />
              </Flex>
              <Flex flexDir="column" w="100%">
                <Text fontWeight="semibold">{contractor.name}</Text>
                <Text>{cnpjMask(contractor.cnpj)}</Text>
              </Flex>
              <Flex>
                <Button
                  title="escolher"
                  variant="success"
                  onClick={() => {
                    setContractor(contractor);
                    closeModal();
                  }}
                />
              </Flex>
            </Flex>
          </Card>
        ))}
      </Flex>
    </ModalContainer>
  );
}
