import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import Footer from './components/Footer';
import Phase1 from './components/Phase1';
import Phase2 from './components/Phase2';
import Phase3 from './components/Phase3';
import Phase4 from './components/Phase4';
import Success from './components/Success';

import { Title } from '~/components/Kiwistrap/Page';

import { Container } from './styles';

export default function ImDoctor() {
  const [inputs, setInputs] = useState({});
  const [currentPhase, setCurrentPhase] = useState(1);

  const handleCreateAccount = () => {
    api
      .post('/doctors', inputs)
      .then(() => {
        setCurrentPhase(10);
      })
      .catch(error => {
        if (error.response?.data?.error === 'Email already exist.') {
          return toast.error('Esse e-mail já está sendo utilizado.');
        }

        if (error.response?.data?.error === 'CRM already exist.') {
          return toast.error('Esse CRM já está sendo utilizado.');
        }

        return internalServerError();
      });
  };

  const handlePhaseSubmit = data => {
    setInputs({ ...inputs, ...data });

    setCurrentPhase(currentPhase + 1);
  };

  useEffect(() => {
    if (currentPhase === 5) handleCreateAccount();
  }, [currentPhase]);

  return (
    <Container>
      <Flex w="100%" flexDir="column" align="center">
        <Title center margin="120px 0 0">
          Cadastro Médico
        </Title>
        {currentPhase === 1 && <Phase1 handlePhaseSubmit={handlePhaseSubmit} />}
        {currentPhase === 2 && <Phase2 handlePhaseSubmit={handlePhaseSubmit} />}
        {currentPhase === 3 && <Phase3 handlePhaseSubmit={handlePhaseSubmit} />}
        {(currentPhase === 4 || currentPhase === 5) && (
          <Phase4 handlePhaseSubmit={handlePhaseSubmit} />
        )}
        {currentPhase === 10 && <Success email={inputs.email} />}
      </Flex>
      <Footer />
    </Container>
  );
}
