import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: var(--background);
`;

export const Title = styled.span`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: var(--primary);
  margin-top: 20px;
`;

export const ContentPrevious = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 20px 50px;
`;

export const TextPrevious = styled(Link)`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--primary);
  border-bottom: 2px solid var(--primary);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ContentForm = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 400px);
  gap: 20px;
  margin-top: 30px;
`;

export const NameInput = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--primary);
  padding: 5px 0;
`;

export const InputGroup = styled.div`
  display: grid;
  grid-template-columns: ${props => {
    const v = props.type;

    if (v === 'normal') {
      return '2fr 1fr';
    }
    return '1fr 2fr';
  }};
  gap: 18px;
`;

export const FormInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionDadosPessoais = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleDadosPessoais = styled.span`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--secondary);
  margin-bottom: 15px;
`;

export const SectionDadosProfissionais = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleDadosProfissionais = styled.span`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--secondary);
  margin-bottom: 15px;
`;

export const SectionEndereco = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleEndereco = styled.span`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--secondary);
  margin-bottom: 15px;
`;

export const SectionBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  background-color: var(--dark-gray);

  @media screen and (min-width: 320px) {
    display: none;
  }
`;

export const SectionUteis = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 500px) {
    padding: 0px;
    margin: 0px;
  }

  @media screen and (min-width: 601px) {
    padding: 0px 20px;
  }
`;

export const SectionList = styled.div`
  display: flex;
  margin-right: 30px;

  @media screen and (min-width: 501px) {
    display: block;
    margin-right: 100px;
    margin-left: 5px;
  }
  @media screen and (min-width: 748px) {
    display: block;
    margin-right: 15px;
    margin-left: 0px;
  }
  @media screen and (min-width: 1020px) {
    display: flex;
    margin-right: 75px;
  }
`;

export const TitleUteis = styled.span`
  font-family: Montserrat;
  margin-right: 15px;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--background);

  @media screen and (min-width: 500px) {
    font-size: 14px;
    text-align: left;
    padding-left: 5px;
    width: 100px;
  }

  @media screen and (min-width: 748px) {
    font-size: 15px;
    text-align: left;
    padding-left: 5px;
    width: 100px;
  }

  @media screen and (min-width: 1020px) {
    font-size: 22px;
    line-height: 1.23;
    width: 135px;
  }
`;

export const ListAll = styled.ul`
  display: flex;
  align-items: center;
  text-decoration: none;

  @media screen and (min-width: 501px) {
    width: 150px;
    display: -webkit-box;
  }

  @media screen and (min-width: 748px) {
    width: 300px;
    padding-top: 0px;
  }

  @media screen and (min-width: 1020px) {
    width: 260px;
    padding-top: 0px;
  }
`;

export const ListItem = styled.li`
  display: flex;
  padding: 0 5px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--background);

  @media screen and (min-width: 501px) {
    font-size: 10px;
  }

  @media screen and (min-width: 601px) {
    font-size: 11px;
  }

  @media screen and (min-width: 1020px) {
    font-size: 14px;
  }
`;

export const SectionCopyright = styled.div`
  display: flex;
  padding-right: 50px;

  @media screen and (min-width: 500px) {
    width: 235px;
    margin-right: -60px;
  }

  @media screen and (min-width: 601px) {
    width: 245px;
    margin-right: 3px;
  }

  @media screen and (min-width: 1020px) {
    width: 460px;
  }
`;

export const TitleCopyright = styled.span`
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--background);

  @media screen and (min-width: 501px) {
    width: 150px;
    font-size: 10px;
  }

  @media screen and (min-width: 748px) {
    width: 178px;
    font-size: 12px;
  }

  @media screen and (min-width: 1020px) {
    font-size: 15px;
    width: 100%;
  }
`;

export const SectionSociais = styled.div`
  display: flex;

  svg {
    margin-right: 40px;
    font-size: 30px;

    @media screen and (min-width: 501px) {
      margin-right: 20px;
      font-size: 22px;
    }

    @media screen and (min-width: 748px) {
      margin-right: 25px;
      font-size: 26px;
    }

    @media screen and (min-width: 1020px) {
      margin-right: 40px;
      font-size: 30px;
    }
  }
`;

export const FooterMobile = styled.div`
  display: none;

  @media (min-width: 375px) and (max-width: 414px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: var(--dark-gray);
  }

  @media screen and (min-width: 320px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: var(--dark-gray);
  }
`;

export const TitleSociais = styled.span`
  @media (min-width: 375px) {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: var(--background);
    margin-bottom: 10px;
  }

  @media (min-width: 320px) {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: var(--background);
    margin-bottom: 10px;
  }
`;
