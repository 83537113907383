import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaEdit } from 'react-icons/fa';
import { Container } from 'react-bootstrap';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { AiOutlinePlus, AiFillDelete } from 'react-icons/ai';

import api from '~/services/api';
import phoneMask from '~/utils/phoneMask';
import useBeforeMount from '~/utils/useBeforeMount';
import getFirstLastName from '~/utils/getFirstLastName';
import internalServerError from '~/utils/internalServerError';

import GroupModal from './components/GroupModal';
import FindUserInput from './components/FindUserInput';
import EditUserModal from './components/EditUserModal';
import CreateUserModal from './components/CreateUserModal';
import DeleteUserModal from './components/DeleteUserModal';

import IsAllowed from '~/components/UI/IsAllowed';
import Pagination from '~/components/Dashboard/Pagination';
import PageLayout from '~/pages/_layouts/company/PageLayout';

import { Modal } from '~/components/UI/Modal';
import { Avatar } from '~/components/ChakraComponents';
import { Button, Card } from '~/components/ChakraComponents/';

const limit = 12;

export default function Users() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [users, setUsers] = useState([]);
  const [name, setName] = useState('');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState();

  const editModalRef = useRef(null);
  const groupModalRef = useRef(null);
  const createModalRef = useRef(null);
  const deleteModalRef = useRef(null);

  const handleOpenEditModal = useCallback(() => {
    editModalRef.current.openModal();
  }, []);

  const handleOpenGroupModal = useCallback(() => {
    groupModalRef.current.openModal();
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    createModalRef.current.openModal();
  }, []);

  const handleCloseCreateModal = useCallback(() => {
    createModalRef.current.closeModal();
  }, []);

  const handleOpenDeleteModal = useCallback(() => {
    deleteModalRef.current.openModal();
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    deleteModalRef.current.closeModal();
  }, []);

  const fetchData = useCallback(
    callback => {
      const offset = (currentPage - 1) * limit;

      const url = `/contractors/users?name=${name}&offset=${offset}&limit=${limit}&isActive=true`;

      api
        .get(url)
        .then(response => {
          setTotal(response.data.total);
          setUsers(response.data.data);
        })
        .catch(internalServerError);

      if (callback) callback();
    },
    [currentPage, name]
  );

  useBeforeMount(() => {
    fetchData(() => setLoadingPage(false));
  });

  useEffect(() => {
    fetchData();
  }, [currentPage, name]);

  return (
    <PageLayout loading={loadingPage}>
      <Container>
        <Flex flexDir="column" alignItems="center">
          <Flex
            flexDir={['column', 'column', 'column', 'row']}
            display={['column', 'column', 'column', 'row']}
            w="100%"
            maxW="700px"
            mb={2}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <FindUserInput setName={setName} />
            <IsAllowed id={10}>
              <Flex mt={3}>
                <Button
                  title="novo usuário"
                  height="40px"
                  width="50%"
                  mr={3}
                  icon2={<AiOutlinePlus size={24} />}
                  onClick={handleOpenCreateModal}
                />
                <IsAllowed id={11}>
                  <Button
                    title="grupos de usuários"
                    height="40px"
                    width="48%"
                    onClick={handleOpenGroupModal}
                  />
                </IsAllowed>
              </Flex>
            </IsAllowed>
          </Flex>
          {users.map(user => (
            <Card
              key={user.id}
              w="100%"
              display={['inline-block', 'inline-block', 'inline-block', 'flex']}
              maxW="700px"
              mb={2}
            >
              <Flex flexDir="column" w="100%">
                <Flex mb={3} alignItems="center">
                  <Avatar
                    display={['none', 'none', 'inline']}
                    mr={2}
                    name={getFirstLastName(user.name)}
                    src={user.avatar}
                  />
                  <Text>{user.name}</Text>
                </Flex>
                <Text
                  color="blue.600"
                  fontWeight="semibold"
                  fontSize={['12px', '14px', '16px', '16px']}
                >
                  {user.email}
                </Text>
                <Text
                  color="blue.500"
                  fontWeight="500"
                  fontSize={['12px', '14px', '16px', '16px']}
                  mb="10px"
                >
                  {`${phoneMask(user.phone1)} ${phoneMask(user.phone2)}`}
                </Text>
              </Flex>
              <Flex w="auto" alignItems="center">
                <IsAllowed id={11}>
                  <Flex mr={5}>
                    <Tooltip hasArrow label="Editar Usuário" bg="gray.600">
                      <span>
                        <Flex
                          justifyContent="center"
                          cursor="pointer"
                          fontSize="24px"
                          color="var(--primary)"
                          _hover={{ color: 'var(--hover-primary)' }}
                        >
                          <FaEdit
                            onClick={() => {
                              setSelectedUser(user);
                              handleOpenEditModal();
                            }}
                          />
                        </Flex>
                      </span>
                    </Tooltip>
                  </Flex>
                </IsAllowed>
                <IsAllowed id={12}>
                  <Flex>
                    <Tooltip hasArrow label="Excluír Usuário" bg="gray.600">
                      <span>
                        <Flex
                          justifyContent="center"
                          cursor="pointer"
                          fontSize="26px"
                          color="var(--red)"
                          _hover={{ color: 'var(--hover-red)' }}
                        >
                          <AiFillDelete
                            onClick={() => {
                              setSelectedUser(user);
                              handleOpenDeleteModal();
                            }}
                          />
                        </Flex>
                      </span>
                    </Tooltip>
                  </Flex>
                </IsAllowed>
              </Flex>
            </Card>
          ))}
          <Pagination l={limit} t={total} onClick={setCurrentPage} />
          <Modal
            title="Criar Usuário"
            ref={createModalRef}
            component={CreateUserModal}
            onClose={handleCloseCreateModal}
          />
          <Modal
            title="Editar Usuário"
            ref={editModalRef}
            component={EditUserModal}
            user={selectedUser}
          />
          <Modal
            title="Grupos de Usuários"
            ref={groupModalRef}
            component={GroupModal}
          />
          <Modal
            title="Excluír"
            ref={deleteModalRef}
            component={DeleteUserModal}
            onClose={handleCloseDeleteModal}
            user={selectedUser}
          />
        </Flex>
      </Container>
    </PageLayout>
  );
}
