import styled from 'styled-components';

export const Table = styled.div`
  width: 100%;

  display: table;

  border: none;
  border-collapse: collapse;
`;

export const Tr = styled.tr`
  font-size: 14px;
  width: 100%;

  border-bottom: 1px solid var(--dim-gray);

  &:last-child {
    border-bottom: none;
  }
`;

export const Th = styled.th`
  padding: 0 20px;

  text-align: left;
  text-transform: uppercase;
  font-weight: bold;

  color: var(--secondary);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const Td = styled.td`
  padding: 10px 20px;
  font-weight: 500;
  color: var(--dark-gray);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;
