import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import PageLayout from '~/pages/_layouts/doctor/PageLayout';

import { Div } from '~/components/Kiwistrap/Page';

import NewsItem from './components/NewsItem';

import { InputLabel, InputSearch } from './styles';

const internalServerError =
  'Houve um problema com os nossos servidores. Tente novamente.';

export default function News() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [news, setNews] = useState();

  const fetchNews = useCallback(async (title = '') => {
    const url = `/userdoctor/news?title=${title}`;

    let response;

    try {
      response = await api.get(url);
      setNews(response.data.data);
    } catch (error) {
      toast.error(internalServerError);
    }
  }, []);

  useEffect(() => {
    (async () => {
      fetchNews();
      setLoadingPage(false);
    })();
  }, []);

  return (
    <PageLayout title="Notícias" loading={loadingPage}>
      <InputLabel>Buscar Notícia</InputLabel>
      <InputSearch
        placeholder="Ex: Casos de covid 19"
        onChange={e => fetchNews(e.target.value)}
      />
      <Div>
        {news &&
          news.length > 0 &&
          news.map(newsItem => (
            <NewsItem key={newsItem.id} newsItem={newsItem} />
          ))}
      </Div>
    </PageLayout>
  );
}
