import styled from 'styled-components';
import {
  FaTimes,
  FaClock,
  FaUserTimes,
  FaCheck,
  FaUserEdit,
  FaUserCheck,
  FaRetweet,
} from 'react-icons/fa';
import { IoMdAlert } from 'react-icons/io';
import { BsSun, BsMoon } from 'react-icons/bs';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    font-size: 18px;
    margin-right: 10px;
  }
`;

export const Color = styled.div`
  width: 30px;
  height: 18px;

  margin-right: 10px;

  border-radius: 2px;

  background-color: ${props =>
    props.color ? props.color : 'var(--background)'};
`;

export const Info = styled.span`
  width: 140px;

  font-size: 0.75rem;

  color: var(--dark-gray);
`;

export const Candidature = styled.div`
  width: 18px;
  height: 18px;

  padding: 2px;
  margin-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 10px;

  border-radius: 50%;

  color: var(--white);
  background-color: var(--red);
`;

export const RefusedSvg = styled(FaTimes)`
  color: var(--red);
`;

export const PendingSvg = styled(FaClock)`
  color: var(--yellow);
`;

export const ExpiredSvg = styled(FaUserTimes)`
  color: var(--red);
`;

export const ConfirmedSvg = styled(FaCheck)`
  color: var(--blue);
`;

export const CheckingSvg = styled(FaUserEdit)`
  color: var(--secondary);
`;

export const CheckoutSvg = styled(FaUserCheck)`
  color: var(--green);
`;

export const SunSvg = styled(BsSun)`
  color: var(--yellow);
`;

export const MoonSvg = styled(BsMoon)`
  color: var(--dark-gray);
`;

export const PendingCheckingSvg = styled(FaUserEdit)`
  color: var(--yellow);
`;

export const PendingCheckoutSvg = styled(FaUserCheck)`
  color: var(--yellow);
`;

export const OpenChangeSvg = styled(FaRetweet)`
  color: var(--yellow);
`;

export const ChangeSvg = styled(FaRetweet)`
  color: var(--green);
`;

export const ObservationSvg = styled(IoMdAlert)`
  color: var(--orange);
`;
