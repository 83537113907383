import styled from 'styled-components';

export const PageLayout = styled.div`
  width: 100%;
  min-height: calc(100vh - 81px);

  padding: 0 20px 20px;

  display: flex;
  flex-direction: column;

  background-color: var(--background);
`;

export const CardsContainer = styled.div`
  width: 1000px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
