import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

import ContractGroupHeader from './ContractGroupHeader';
import ContractsNav from './ContractsNav';
import ContractsTable from './ContractsTable';

import Pagination from '~/components/Dashboard/Pagination';

import { fetchContracts } from './actions';

const limit = 5;

export default function AccordionMenu() {
  const [total, setTotal] = useState(0);
  const [groups, setGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchContracts(
      response => {
        setTotal(response.data.total);
        setGroups(response.data.data);
      },
      [currentPage, limit]
    );
  }, [currentPage]);

  return (
    <>
      <Accordion
        allowMultiple
        mt={4}
        borderColor="gray.100"
        _focus={{ boxShadow: 'none' }}
      >
        {groups.map(group => (
          <AccordionItem key={group.id}>
            <AccordionButton
              color="var(--primary)"
              _expanded={{ bg: 'var(--primary)', color: 'white' }}
            >
              <AccordionIcon />
              <ContractGroupHeader group={group} />
            </AccordionButton>
            <AccordionPanel>
              <ContractsNav group={group} />
              <ContractsTable group={group} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Pagination l={limit} t={total} onClick={setCurrentPage} />
    </>
  );
}
