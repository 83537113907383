import React, { useCallback } from 'react';
import moment from 'moment';
import { Text } from '@chakra-ui/react';

import { statusText, vacancyCardBackgroundColor } from '~/utils/genStatus';

import { MonthOpenContainer } from './styles';

export default function MonthOpenCard({ vacancy, onClick }) {
  const renderText = useCallback(() => {
    const { startHour, endHour } = vacancy;

    const formattedStart = moment(new Date(startHour)).format('HH:mm');
    const formattedEnd = moment(new Date(endHour)).format('HH:mm');

    return `${formattedStart}h - ${formattedEnd}h`;
  }, []);

  return (
    <MonthOpenContainer
      color={vacancyCardBackgroundColor('open')}
      onClick={onClick}
    >
      <Text color="#505050">{statusText('open')}</Text>
      <Text color="#505050">{renderText()}</Text>
    </MonthOpenContainer>
  );
}
