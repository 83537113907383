import styled from 'styled-components';

export const Container = styled.div`
  color: var(--primary);
  * {
    font-family: 'Roboto', sans-serif !important;
  }
`;

export const SignUp = styled.div`
  height: 100vh;
  color: #ffffff;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-top: 170px;
    text-align: center;
    font-weight: 400;
    font-size: 42px;
    line-height: 38px;
    strong {
      display: block;
      font-size: 45px;
      margin-top: 20px;
    }
  }
`;

export const Description = styled.div``;

export const Knowledge = styled.div``;

export const Download = styled.div``;
