import React from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { getUser } from '~/services/auth';

import { Button } from '~/components/ChakraComponents/';
import { Form, Field } from '~/components/Kiwistrap/Form';

export default function EditPasswordModal({ onClose }) {
  const handleSubmit = async data => {
    if (!data.password) {
      return toast.error('Por favor digite uma nova senha.');
    }

    if (!data.password_confirm) {
      return toast.error('Por favor confirme a senha nova.');
    }

    if (data.password !== data.password_confirm) {
      return toast.error('As senhas digitadas são diferentes.');
    }

    const request = {
      password: data.password,
    };

    try {
      const { id } = getUser();
      await api.patch(`/contractors/users/${id}`, request);
    } catch (e) {
      return internalServerError();
    }

    toast.success('Senha alterada com sucesso!');

    return onClose();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Flex flexDir="column" alignItems="center" justifyContent="center">
        <Field label="nova senha" name="password" type="password" />
        <Field
          label="confirmar senha"
          name="password_confirm"
          type="password"
        />
        <Flex justifyContent="space-between">
          <Button
            title="cancelar"
            mr="20px"
            variant="secondary"
            onClick={onClose}
          />
          <Button title="editar" variant="success" type="submit" />
        </Flex>
      </Flex>
    </Form>
  );
}
