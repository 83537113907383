import React from 'react';
import { Flex } from '@chakra-ui/react';

import convertBRL from '~/utils/convertBRL';

import ContractConfigButton from './ContractConfigButton';

import IsAllowed from '~/components/UI/IsAllowed';
import { ConfirmSvg, NegateSvg } from '~/components/Kiwistrap/Svg';

import { Table, Tr, Th, Td, NoContractsText } from './styles';

export default function ContractsTable({ group }) {
  return (
    <>
      {group.contracts.length > 0 ? (
        <Table>
          <thead>
            <Tr>
              <IsAllowed id={4}>
                <Th />
              </IsAllowed>
              <Th>
                <Flex w="100%" justify="center">
                  ativo
                </Flex>
              </Th>
              <Th>especialidade</Th>
              <Th>setor</Th>
              <Th>turno</Th>
              <Th>valor</Th>
              <Th>deduções fiscais</Th>
              <Th>fechamento</Th>
              <Th>pagamento</Th>
              <Th>pagador</Th>
              <Th>organização social</Th>
            </Tr>
          </thead>
          <tbody>
            {group.contracts.map(contract => (
              <Tr key={contract.id}>
                <IsAllowed id={4}>
                  <Td>
                    <ContractConfigButton group={group} contract={contract} />
                  </Td>
                </IsAllowed>
                <Td>
                  <Flex w="100%" justify="center">
                    {contract.isActive ? <ConfirmSvg /> : <NegateSvg />}
                  </Flex>
                </Td>
                <Td>{contract.specialty.name}</Td>
                <Td>{contract.sector}</Td>
                <Td>{`${contract.start.slice(0, -3)} - ${contract.end.slice(
                  0,
                  -3
                )}`}</Td>
                <Td>{convertBRL(contract.value)}</Td>
                <Td>{convertBRL(contract.totalWithTaxDeduction)}</Td>
                <Td>{`Dia ${contract.closeVacancyDay}`}</Td>
                <Td>{`Dia ${contract.closeFinanceDay}`}</Td>
                <Td>{contract.payer}</Td>
                <Td>{contract.socialOrganization}</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Flex justify="center" textAlign="center">
          <NoContractsText>
            Não existem plantões nesse contrato ainda.
          </NoContractsText>
        </Flex>
      )}
    </>
  );
}
