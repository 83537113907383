const situationTranslated = situation => {
  return {
    notConfirmed: 'Pendente',
    confirmed: 'Confirmado',
    change: 'Cobertura',
    open: 'Aberta',
    lack: 'Falta',
  }[situation];
};

export const renderSituation = situations => {
  const situationsArr = [...situations];

  for (let i = 0; i < situationsArr.length; i += 1) {
    situationsArr[i] = situationTranslated(situationsArr[i]);
  }

  return situationsArr.join(' / ');
};
