import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
  margin-top: 30px;
`;

export const Tr = styled.tr`
  height: 40px;

  border-bottom: 1px solid var(--dim-gray);

  &:last-child {
    border-bottom: none;
  }
`;

export const Td = styled.td`
  padding: 15px 10px;
  font-size: 18px;
  font-weight: normal;
  color: var(--light-gray);
  text-align: center;

  section {
    display: none;
  }

  svg {
    cursor: pointer;
  }

  &:nth-child(1) {
    padding-left: 30px;
  }

  &:nth-child(2) {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const DoctorAvatarContainer = styled.div`
  background-color: var(--background);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DoctorAvatar = styled.img`
  border: 5px solid var(--primary);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
`;
