import React from 'react';
import { Flex, Avatar as ChakraAvatar } from '@chakra-ui/react';

import getFirstLastName from '~/utils/getFirstLastName';

export default function Avatar({
  w = '40px',
  h = '40px',
  name,
  src,
  border = '3px',
  borderColor = 'var(--primary)',
  ...rest
}) {
  return (
    <Flex
      {...rest}
      w={w}
      h={h}
      border={border}
      borderColor={borderColor}
      borderStyle="solid"
      borderRadius="full"
      flexShrink="0"
    >
      <ChakraAvatar
        w="100%"
        h="100%"
        p={`calc(${border} - 1px)`}
        borderRadius="full"
        backgroundColor="white"
        name={getFirstLastName(name)}
        src={src}
      />
    </Flex>
  );
}
