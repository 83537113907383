import React from 'react';
import moment from 'moment';
import { Flex, Text } from '@chakra-ui/react';
import { BsSun as SunSvg, BsMoon as MoonSvg } from 'react-icons/bs';

import formatDate from '~/utils/formatDate';

export const isStartHourNight = startHour => {
  let isNight = false;

  const startNightStart = moment(new Date(`1996-09-13 00:00:01`));
  const startNightEnd = moment(new Date(`1996-09-13 05:59:59`));

  const endNightStart = moment(new Date(`1996-09-13 17:59:59`));
  const endNightEnd = moment(new Date(`1996-09-13 23:59:59`));

  if (
    moment(new Date(`1996-09-13 ${startHour}`)).isBetween(
      startNightStart,
      startNightEnd
    ) ||
    moment(new Date(`1996-09-13 ${startHour}`)).isBetween(
      endNightStart,
      endNightEnd
    )
  ) {
    isNight = true;
  }

  return isNight;
};

export const renderTurn = vacancy => {
  return (
    <Flex alignItems="center">
      <Text fontWeight="semibold">{formatDate(vacancy.date).bars()}</Text>
    </Flex>
  );
};
