import React, { useState, useEffect, useCallback } from 'react';

import {
  Drawer,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react';
import { BsSearch } from 'react-icons/bs';

import api from '~/services/api';
import { IText, IMoney, ISelect, IMultiSelect } from '~/components/UI/Inputs';
import getQueryParams from '~/utils/getJsonQueryParams';
import { Button } from '~/components/ChakraComponents';

export default function DrawerFilter({
  onClose,
  isOpen,
  location,
  currentParams,
  renewData,
  inputsPermitted = [],
}) {
  const queryParams = getQueryParams(location.search);

  const [multi, setMulti] = useState({});
  const [inputs, setInputs] = useState({
    ...queryParams,
    startDate:
      queryParams.startDate &&
      queryParams.startDate
        .split('-')
        .reverse()
        .join('/'),
    endDate:
      queryParams.endDate &&
      queryParams.endDate
        .split('-')
        .reverse()
        .join('/'),
  });

  const [specialty, setSpecialty] = useState([]);
  const [hospital, setHospital] = useState([]);
  const [city, setCity] = useState([]);
  const [doctor, setDoctor] = useState([]);

  const approved = [
    { id: 2, value: '', text: 'Todos' },
    { id: 1, value: 0, text: 'Não aprovado' },
    { id: 3, value: 1, text: 'Aprovado' },
  ];

  const approvedY = [
    { id: 3, value: 1, text: 'Aprovado' },
    { id: 2, value: '', text: 'Todos' },
    { id: 1, value: 0, text: 'Não aprovado' },
  ];

  const approvedN = [
    { id: 1, value: 0, text: 'Não aprovado' },
    { id: 2, value: '', text: 'Todos' },
    { id: 3, value: 1, text: 'Aprovado' },
  ];

  const checkin = [
    { id: 2, value: '', text: 'Todos' },
    { id: 1, value: 1, text: 'Com Check-in' },
    { id: 3, value: 0, text: 'Sem Check-in' },
  ];

  const checkinY = [
    { id: 1, value: 1, text: 'Com Check-in' },
    { id: 2, value: '', text: 'Todos' },
    { id: 3, value: 0, text: 'Sem Check-in' },
  ];

  const checkinN = [
    { id: 3, value: 0, text: 'Sem Check-in' },
    { id: 2, value: '', text: 'Todos' },
    { id: 1, value: 1, text: 'Com Check-in' },
  ];

  const checkout = [
    { id: 2, value: '', text: 'Todos' },
    { id: 1, value: 1, text: 'Com Check-out' },
    { id: 3, value: 0, text: 'Sem Check-out' },
  ];

  const checkoutY = [
    { id: 1, value: 1, text: 'Com Check-out' },
    { id: 2, value: '', text: 'Todos' },
    { id: 3, value: 0, text: 'Sem Check-out' },
  ];

  const checkoutN = [
    { id: 3, value: 0, text: 'Sem Check-out' },
    { id: 2, value: '', text: 'Todos' },
    { id: 1, value: 1, text: 'Com Check-out' },
  ];

  const flow = [
    { id: 2, value: '', text: 'Todos' },
    { id: 1, value: 0, text: 'Normal' },
    { id: 3, value: 1, text: 'À vista' },
  ];

  const flowY = [
    { id: 3, value: 1, text: 'À vista' },
    { id: 2, value: '', text: 'Todos' },
    { id: 1, value: 0, text: 'Normal' },
  ];

  const flowN = [
    { id: 1, value: 0, text: 'Normal' },
    { id: 2, value: '', text: 'Todos' },
    { id: 3, value: 1, text: 'À vista' },
  ];

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const invertData = e => {
    if (e && queryParams[e] !== '' && queryParams[e]) {
      e.split('-')
        .reverse()
        .join('/');
      return e;
    }
    return e;
  };

  const searchSpecialty = e => {
    api.get(`/allspecialties?limit=7&name=${e}`).then(res => {
      setSpecialty(res.data);
    });
  };

  const searchCities = e => {
    api.get(`/companies/myhospitals/cities?name=${e}`).then(res => {
      setCity(res.data.data);
    });
  };

  const searchHospital = e => {
    api.get(`/companies/myhospitals?name=${e}`).then(res => {
      setHospital(res.data.data);
    });
  };

  const searchDoctor = e => {
    api
      .get(`/companies/mydoctors?limit=7&isActive=true&name=${e}`)
      .then(res => {
        setDoctor(res.data.data);
      });
  };

  const getMultiSelect = useCallback(() => {
    api.get(`/allspecialties?limit=7`).then(res => {
      setSpecialty(res.data);
    });

    api.get(`/companies/myhospitals`).then(res => {
      setHospital(res.data.data);
    });

    api.get(`/companies/myhospitals/cities`).then(res => {
      setCity(res.data.data);
    });

    api.get(`/companies/mydoctors?limit=7&isActive=true`).then(res => {
      setDoctor(res.data.data);
    });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    const { hospitals, specialties, cities, doctors } = multi;

    document.search.startDate.value = document.search.startDate.value
      .split('/')
      .reverse()
      .join('-');
    document.search.endDate.value = document.search.endDate.value
      .split('/')
      .reverse()
      .join('-');
    document.search.hospitals.value = hospitals
      ? hospitals.map(h => h.id).toString()
      : '';
    document.search.specialties.value = specialties
      ? specialties.map(s => s.id).toString()
      : '';
    document.search.cities.value = cities
      ? cities.map(c => c.name).toString()
      : '';
    document.search.doctors.value = doctors
      ? doctors.map(d => d.doctor.id).toString()
      : '';
    document.search.startValue.value = document.search.startValue.value
      ? Number(document.search.startValue.value.replace('.', ''))
      : '';
    document.search.endValue.value = document.search.endValue.value
      ? Number(document.search.endValue.value.replace('.', ''))
      : '';

    renewData();

    return false;
  };

  useEffect(() => {
    getMultiSelect();
  }, []);

  const allInputs = {
    startDate: (
      <IText
        label="Dia inicial"
        placeholder="Ex: 10/10/2010"
        name="startDate"
        style={{ marginBottom: 12 }}
        onChange={handleChanges}
        value={
          queryParams.startDate !== ''
            ? inputs.startDate
            : invertData(inputs.startDate)
        }
        useCleave
        options={{
          date: true,
          datePattern: ['d', 'm', 'Y'],
        }}
      />
    ),
    endDate: (
      <IText
        label="Dia final"
        placeholder="Ex: 10/11/2010"
        name="endDate"
        style={{ marginBottom: 12 }}
        onChange={handleChanges}
        value={
          queryParams.endDate !== ''
            ? inputs.endDate
            : invertData(inputs.endDate)
        }
        useCleave
        options={{
          date: true,
          datePattern: ['d', 'm', 'Y'],
        }}
      />
    ),
    specialties: (
      <>
        <IMultiSelect
          label="Especialidade"
          placeholder="Ex: Pediatria"
          options={specialty}
          id="specialty"
          showCheckbox
          selectedValues={multi.specialties}
          onSelect={a => {
            setMulti({ ...multi, specialties: a });
          }}
          onRemove={a => {
            setMulti({ ...multi, specialties: a });
          }}
          onSearch={searchSpecialty}
        />
        <input type="hidden" name="specialties" value={multi.specialties} />
      </>
    ),
    crm: (
      <IText
        label="CRM"
        placeholder="Ex: 1928708"
        name="crm"
        onChange={handleChanges}
        value={inputs.crm}
        type="number"
        style={{ marginBottom: 12 }}
      />
    ),
    crmUf: (
      <IText
        label="crm uf"
        placeholder="Ex: SP"
        name="crmUf"
        style={{ marginBottom: 12 }}
        value={inputs.crmUf}
        onChange={handleChanges}
        useCleave
        options={{
          blocks: [2],
          uppercase: true,
        }}
      />
    ),
    startValue: (
      <IMoney
        label="Preço minimo"
        placeholder="100,00"
        name="startValue"
        value={inputs.startValue}
        style={{ marginBottom: 12 }}
        onChange={handleChanges}
      />
    ),
    endValue: (
      <IMoney
        label="Preço máxima"
        placeholder="5.000,00"
        name="endValue"
        value={inputs.endValue}
        style={{ marginBottom: 12 }}
        onChange={handleChanges}
      />
    ),
    doctors: (
      <>
        <IMultiSelect
          label="Doutores"
          placeholder="Ex: João"
          options={doctor}
          id="doctor"
          showCheckbox
          selectedValues={multi.doctors}
          onSelect={a => {
            setMulti({ ...multi, doctors: a });
          }}
          onRemove={a => {
            setMulti({ ...multi, doctors: a });
          }}
          onSearch={searchDoctor}
        />
        <input type="hidden" name="doctors" value={multi.doctors} />
      </>
    ),
    hospitals: (
      <>
        <IMultiSelect
          label="Hospitais"
          placeholder="Ex: Hospital Saúde"
          options={hospital}
          id="hospital"
          showCheckbox
          selectedValues={multi.hospitals}
          onSelect={a => {
            setMulti({ ...multi, hospitals: a });
          }}
          onRemove={a => {
            setMulti({ ...multi, hospitals: a });
          }}
          onSearch={searchHospital}
        />
        <input type="hidden" name="hospitals" value={multi.hospitals} />
      </>
    ),
    cities: (
      <>
        <IMultiSelect
          label="Cidades"
          placeholder="Ex: São Paulo"
          options={city}
          id="city"
          showCheckbox
          selectedValues={multi.cities}
          onSelect={a => {
            setMulti({ ...multi, cities: a });
          }}
          onRemove={a => {
            setMulti({ ...multi, cities: a });
          }}
          onSearch={searchCities}
        />
        <input type="hidden" name="cities" value={multi.cities} />
      </>
    ),
    checkin: (
      <ISelect
        label="Check-in"
        name="checkin"
        options={checkin}
        onChange={handleChanges}
        value={inputs.checkin}
        style={{ marginBottom: 12 }}
      />
    ),
    checkinY: (
      <ISelect
        label="Check-in"
        name="checkin"
        options={checkinY}
        onChange={handleChanges}
        value={inputs.checkin}
        style={{ marginBottom: 12 }}
      />
    ),
    checkinN: (
      <ISelect
        label="Check-in"
        name="checkin"
        options={checkinN}
        onChange={handleChanges}
        value={inputs.checkin}
        style={{ marginBottom: 12 }}
      />
    ),

    checkout: (
      <ISelect
        label="Check-out"
        name="checkout"
        options={checkout}
        onChange={handleChanges}
        value={inputs.checkout}
        style={{ marginBottom: 12 }}
      />
    ),
    checkoutY: (
      <ISelect
        label="Check-out"
        name="checkout"
        options={checkoutY}
        onChange={handleChanges}
        value={inputs.checkout}
        style={{ marginBottom: 12 }}
      />
    ),
    checkoutN: (
      <ISelect
        label="Check-out"
        name="checkout"
        options={checkoutN}
        onChange={handleChanges}
        value={inputs.checkout}
        style={{ marginBottom: 12 }}
      />
    ),
    inCash: (
      <ISelect
        label="Fluxo"
        name="inCash"
        options={flow}
        onChange={handleChanges}
        value={inputs.inCash}
        style={{ marginBottom: 12 }}
      />
    ),
    inCashY: (
      <ISelect
        label="Fluxo"
        name="inCash"
        options={flowY}
        onChange={handleChanges}
        value={inputs.inCash}
        style={{ marginBottom: 12 }}
      />
    ),
    inCashN: (
      <ISelect
        label="Fluxo"
        name="inCash"
        options={flowN}
        onChange={handleChanges}
        value={inputs.inCash}
        style={{ marginBottom: 12 }}
      />
    ),
    isApproved: (
      <ISelect
        label="Aprovado"
        name="isApproved"
        options={approved}
        onChange={handleChanges}
        value={inputs.isApproved}
        style={{ marginBottom: 90 }}
      />
    ),
    isApprovedY: (
      <ISelect
        label="Aprovado"
        name="isApproved"
        options={approvedY}
        onChange={handleChanges}
        value={inputs.isApproved}
        style={{ marginBottom: 90 }}
      />
    ),
    isApprovedN: (
      <ISelect
        label="Aprovado"
        name="isApproved"
        options={approvedN}
        onChange={handleChanges}
        value={inputs.isApproved}
        style={{ marginBottom: 90 }}
      />
    ),
  };

  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          color="var(--primary)"
          bg="#FFFFFF"
          boxShadow="rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px"
        >
          Busca avançada
        </DrawerHeader>
        <DrawerBody>
          <form name="search" method="get" onSubmit={handleSubmit}>
            {inputsPermitted.map(input => allInputs[input])}
            <input
              type="hidden"
              name="limit"
              value={`${currentParams.limit}`}
            />
            <Flex
              position="absolute"
              h="65px"
              bottom="0"
              left="0"
              right="0"
              display="flex"
              align="center"
              bg="#FFFFFF"
              boxShadow="rgba(0, 0, 0, 0.15) 0px -5px 25px, rgba(0, 0, 0, 0.05) 0px -5px 10px"
            >
              <Button
                color="primary"
                title="Buscar"
                margin="0 auto"
                type="submit"
                icon2={<BsSearch size={20} />}
              />
            </Flex>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
