import React, { useState, useRef, useEffect } from 'react';
import { InputFile, UserAvatar } from './style';
import api from '~/services/api';

export default function EditAvatar({ userId, edit, getAvatarRef, avatarRef }) {
  const isEdit = !!edit;
  const [avatarFile, setAvatarFile] = useState(null);
  const [Avatar, setAvatar] = useState(
    'https://verde-azul-imoveis.s3.amazonaws.com/b36f1999-aada-4f0b-9fe8-21bdf419fef7.png'
  );

  const inputFile = useRef(null);

  useEffect(() => {
    if (isEdit === true) {
      api.get(`/companies/users/${userId}`).then(res => {
        setAvatar(res.data.avatar);
      });
    }
  }, []);

  async function fileUpload() {
    const data = new FormData();
    data.append('file', avatarFile);

    const response = await api.post('/avatar/', data);
    return response.data[0].filename;
  }

  async function dataEff() {
    if (avatarFile) {
      const avatarName = await fileUpload();
      setAvatar(avatarName);

      if (isEdit === true) {
        await api.patch(`/companies/users/${userId}`, {
          avatar: avatarName,
        });
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    dataEff();
  }, [avatarFile]);

  return (
    <UserAvatar onClick={() => inputFile.current.click()}>
      <InputFile
        ref={inputFile}
        onChange={res => {
          setAvatarFile(res.target.files[0]);
        }}
      />
      <img src={Avatar} ref={avatarRef} alt="user" />
      <section>
        <span>
          Alterar
          <br />
          Avatar
        </span>
      </section>
    </UserAvatar>
  );
}
