import React, { useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import { Flex, Text } from '@chakra-ui/react';
import { RiListSettingsLine } from 'react-icons/ri';
import { BsListUl, BsCalendar, BsInfo } from 'react-icons/bs';

import { getUser } from '~/services/auth';

import Groups from '~/components/Dashboard/Modals/MeusPlantoes/ScheduleGroupModal';
import Legends from '~/components/UI/Sections/VacancyLegends/MobileLegends';
import CreateSchedule from '~/components/Dashboard/Modals/MeusPlantoes/CreateScheduleModal';
import HospitalSchedules from './Schedules';
import ContractorSchedules from './ContractorSchedules';

import IsAllowed from '~/components/UI/IsAllowed';
import { Modal } from '~/components/UI/Modal';

const SvgButton = styled.div`
  width: 100%;
  height: 100%;

  padding: 5px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border-radius: 4px;

  text-align: center;

  color: var(--background-alt);
  background-color: var(--primary);

  svg {
    font-size: 24px;
  }

  &:nth-child(odd) {
    margin: 0 5px;
  }
`;

const SvgText = styled(Text)`
  margin-top: 5px;

  line-height: 1;

  font-size: 10px;
  font-weight: 600;
`;

export default function Header({ calendar }) {
  const user = getUser();

  const createScheduleModalRef = useRef(null);
  const groupsModalRef = useRef(null);
  const calendarModalRef = useRef(null);
  const legendsModalRef = useRef(null);
  const schedulesModalRef = useRef(null);

  const openCreateSchedule = useCallback(() => {
    createScheduleModalRef.current.openModal();
  }, []);

  const openGroups = useCallback(() => {
    groupsModalRef.current.openModal();
  }, []);

  const openCalendar = useCallback(() => {
    calendarModalRef.current.openModal();
  }, []);

  const openLegends = useCallback(() => {
    legendsModalRef.current.openModal();
  }, []);

  const openSchedules = useCallback(() => {
    schedulesModalRef.current.openModal();
  }, []);

  const schedulesModalDictionary = useCallback(() => {
    return {
      company: HospitalSchedules,
      contractor: ContractorSchedules,
    }[user.type];
  }, [user]);

  return (
    <Flex
      h="70px"
      py="5px"
      justify="space-evenly"
      pos="fixed"
      bottom="0"
      left="0"
      right="0"
      backgroundColor="var(--background)"
    >
      {user.type === 'company' && (
        <IsAllowed id={7}>
          <SvgButton onClick={openCreateSchedule}>
            <FaPlus />
            <SvgText>Criar Plantão</SvgText>
          </SvgButton>
          <SvgButton onClick={openGroups}>
            <RiListSettingsLine />
            <SvgText>Gerenciar Escalas</SvgText>
          </SvgButton>
        </IsAllowed>
      )}
      <SvgButton onClick={openSchedules}>
        <BsListUl />
        <SvgText>Escalas</SvgText>
      </SvgButton>
      {(/week/.test(window.location.href) ||
        /month/.test(window.location.href)) && (
        <>
          <SvgButton onClick={openCalendar}>
            <BsCalendar />
            <SvgText>Calendário</SvgText>
          </SvgButton>
          <SvgButton onClick={openLegends}>
            <BsInfo />
            <SvgText>Legendas</SvgText>
          </SvgButton>
        </>
      )}
      <Modal
        title="Criar Plantão"
        ref={createScheduleModalRef}
        component={CreateSchedule}
      />
      <Modal title="Escalas" ref={groupsModalRef} component={Groups} />
      <Modal title="Navegar" ref={calendarModalRef} component={calendar} />
      <Modal title="Legendas" ref={legendsModalRef} component={Legends} />
      <Modal
        title="Escalas"
        ref={schedulesModalRef}
        component={schedulesModalDictionary()}
      />
    </Flex>
  );
}
