import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 14px 0;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #e7e7e7;

  svg {
    margin-right: 14px;
    font-size: 30px;
    color: var(--primary);
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;
