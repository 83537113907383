import styled from 'styled-components';

export const InputSearch = styled.input`
  width: 100%;
  height: 40px;

  padding: 5px;
  margin-top: 5px;

  border-radius: var(--br-sm);
  border: 2px solid var(--black);
`;

export const InputLabel = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  color: var(--primary);
`;
