import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Spinner } from '~/components/ChakraComponents/';

const PageLayout = ({ loading = false, shimmer = <Spinner />, children }) => (
  <Flex
    overflowY="scroll"
    w="100%"
    h="100%"
    minH={[
      'calc(100vh - 40px)',
      'calc(100vh - 40px)',
      'calc(100vh - 40px)',
      'calc(100vh - 81px)',
    ]}
    p={3}
    flexDir="column"
  >
    {loading ? <>{shimmer}</> : <>{children}</>}
  </Flex>
);

export default PageLayout;
