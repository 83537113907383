import { toast } from 'react-toastify';

import api from '~/services/api';
import { getParam } from '~/utils/url';

export const handleContractGroupIsActive = group => {
  const { hospitalId } = getParam();

  const { id, isActive } = group;

  const url = `/companies/myhospitals/${hospitalId}/contracts-groups/${id}`;

  const request = {
    isActive: !isActive,
  };

  api.patch(url, request).then(() => {
    toast.success(`${isActive ? 'Desativado' : 'Ativado'} com sucesso!`);
    setTimeout(() => window.location.reload(), 1200);
  });
};
