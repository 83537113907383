import api from '~/services/api';

const createFunc = responses => {
  responses.forEach(response => {
    response.data.data.forEach(item => {
      item.isChoosen = false;
    });
  });

  return responses;
};

const editFunc = (
  [usersRes, hospitalsRes, companyPermissionsRes, hospitalPermissionsRes],
  group
) => {
  const usersIds = [],
    hospitalsIds = [],
    companyPermissionsIds = [],
    hospitalPermissionsIds = [];

  group.userscompanies.forEach(user => {
    usersIds.push(user.id);
  });

  group.hospitals.forEach(hospital => {
    hospitalsIds.push(hospital.id);
  });

  group.companypermissions.forEach(permission => {
    companyPermissionsIds.push(permission.id);
  });

  group.hospitalpermissions.forEach(permission => {
    hospitalPermissionsIds.push(permission.id);
  });

  usersRes.data.data.forEach(user => {
    if (usersIds.includes(user.usercompany[0].id)) {
      user.isChoosen = true;
    } else {
      user.isChoosen = false;
    }
  });

  hospitalsRes.data.data.forEach(hospital => {
    if (hospitalsIds.includes(hospital.id)) {
      hospital.isChoosen = true;
    } else {
      hospital.isChoosen = false;
    }
  });

  companyPermissionsRes.data.data.forEach(permission => {
    if (companyPermissionsIds.includes(permission.id)) {
      permission.isChoosen = true;
    } else {
      permission.isChoosen = false;
    }
  });

  hospitalPermissionsRes.data.data.forEach(permission => {
    if (hospitalPermissionsIds.includes(permission.id)) {
      permission.isChoosen = true;
    } else {
      permission.isChoosen = false;
    }
  });

  return [
    usersRes,
    hospitalsRes,
    companyPermissionsRes,
    hospitalPermissionsRes,
  ];
};

export const fetchGroups = (callback, group) => {
  const fetches = [
    api.get('/companies/users?isActive=true'),
    api.get('/companies/myhospitals?isActive=true'),
    api.get('/companies/companypermissions'),
    api.get('/companies/hospitalpermissions'),
  ];

  Promise.all(fetches).then(response => {
    let result;

    if (group) {
      result = editFunc(response, group);
    } else {
      result = createFunc(response);
    }

    return callback(result);
  });
};
