const yearsArr = [
  { text: '2020', value: 2020 },
  { text: '2021', value: 2021 },
];

const monthsArr = [
  { text: 'Janeiro', value: 1 },
  { text: 'Feveiro', value: 2 },
  { text: 'Março', value: 3 },
  { text: 'Abril', value: 4 },
  { text: 'Maio', value: 5 },
  { text: 'Junho', value: 6 },
  { text: 'Julho', value: 7 },
  { text: 'Agosto', value: 8 },
  { text: 'Setembro', value: 9 },
  { text: 'Outubro', value: 10 },
  { text: 'Novembro', value: 11 },
  { text: 'Dezembro', value: 12 },
];

export { yearsArr, monthsArr };
