import React, { useState } from 'react';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';

import Hospital from './Hospital';

import { Container, Header, ViewMenu } from './styles';

const defaultAvatar =
  'https://verde-azul-imoveis.s3.amazonaws.com/b36f1999-aada-4f0b-9fe8-21bdf419fef7.png';

export default function Company({ company, hospitals }) {
  const [viewHospitals, setViewHospitals] = useState(false);

  const toggleView = () => setViewHospitals(!viewHospitals);

  const renderHeader = () => {
    const { avatar, name, total } = company;

    const formattedValue = total.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

    return (
      <Header>
        <img src={avatar || defaultAvatar} alt={name} />
        <span>
          {name}:
          <strong style={{ color: 'var(--green)' }}>
            {` ${formattedValue}`}
          </strong>
        </span>
      </Header>
    );
  };

  return (
    <Container>
      {renderHeader()}
      <ViewMenu onClick={toggleView}>
        {viewHospitals ? (
          <>
            <GoArrowUp /> Ocultar pagamentos
          </>
        ) : (
          <>
            <GoArrowDown /> Ver pagamentos
          </>
        )}
      </ViewMenu>
      {viewHospitals &&
        hospitals.map(hospital => (
          <React.Fragment key={hospital.id}>
            {hospital.hospital.company.name === company.name && (
              <Hospital hospital={hospital} />
            )}
          </React.Fragment>
        ))}
    </Container>
  );
}
