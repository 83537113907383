import React, { useState, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import api from '~/services/api';
// import googleGeocode from '~/services/googleGeocode';

import onlyNumberHandleChangeCep from '~/utils/cepMaskWithAscii';
import onlyNumberHandleChangeCnpj from '~/utils/cnpjMaskWithAscii';

import { IText, ICheck } from '~/components/UI/Inputs';
import { Button, Alert, Divider } from '~/components/ChakraComponents/';

import IsAllowed from '~/components/UI/IsAllowed';
import ProgressModal from '~/components/Dashboard/Modals/ProgressModal';

export default function EditHospital({ hospitalId, onClose }) {
  const [values, setValues] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [confirmException, setConfirmException] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hospital, setHospital] = useState('');
  const [sendReminderToMyTeam, setSendReminderToMyTeam] = useState(false);
  const [sendReminderToNotMyTeam, setSendReminderToNotMyTeam] = useState(false);

  const handleChanges = e => {
    if (e.target.name === 'cnpj') {
      return setValues({
        ...values,
        [e.target.name]: onlyNumberHandleChangeCnpj(e.target.value),
      });
    }
    if (e.target.name === 'cep') {
      return setValues({
        ...values,
        [e.target.name]: onlyNumberHandleChangeCep(e.target.value),
      });
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const removeMask = v => v.replace(/\D/g, '');

  const handleSubmit = async e => {
    e.preventDefault();

    setLoadingSubmit(true);

    const request = { ...values };

    if (!request.numberOfEmployees || request.numberOfEmployees === '') {
      request.numberOfEmployees = 0;
    }

    if (request.cnpj) request.cnpj = removeMask(request.cnpj);
    if (request.phone1) request.phone1 = removeMask(request.phone1);
    if (request.phone2) request.phone2 = removeMask(request.phone2);
    if (request.cep) request.cep = removeMask(request.cep);
    if (request.bankCpfCnpj) {
      request.bankCpfCnpj = removeMask(request.bankCpfCnpj);
    }

    request.sendReminderToMyTeam = sendReminderToMyTeam;
    request.sendReminderToNotMyTeam = sendReminderToNotMyTeam;

    // const gAddress = request.address && `${request.address}`;
    // const gComplement = request.complement && `+${request.complement}`;
    // const gCity = request.city && `+${request.city}`;
    // const gStreet = request.street && `+${request.street}`;
    // const gUf = request.uf && `+${request.uf}`;

    // const gRequest = gAddress + gComplement + gCity + gStreet + gUf;

    // const geocodeResponse = await googleGeocode(gRequest);

    // if (
    //   !geocodeResponse ||
    //   !geocodeResponse.data ||
    //   !geocodeResponse.data.results[0] ||
    //   !geocodeResponse.data.results[0].geometry
    // ) {
    //   setLoadingSubmit(false);

    //   return toast.error(
    //     'Houve um problema com os servidores Google. Tente novamente.'
    //   );
    // }

    // request.latitude = geocodeResponse.data.results[0].geometry.location.lat;
    // request.longitude = geocodeResponse.data.results[0].geometry.location.lng;

    try {
      await api.patch(`/companies/myhospitals/${hospitalId}`, request);
    } catch (error) {
      setLoadingSubmit(false);

      return toast.error(
        'Houve um problema nos nossos servidores, tente novamente.'
      );
    }

    toast.success('Hospital editado com sucesso!');

    setLoadingSubmit(false);

    return setTimeout(() => window.location.reload(), 1000);
  };

  const submit = () => {
    if (hospital === values.name) {
      toast.success('Hospital desativado com sucesso!');
      api.delete(`/companies/myhospitals/${hospitalId}`);
      setLoading(true);
      setTimeout(() => window.location.reload(), 1200);
    } else {
      toast.error('Nome do hospital inválido');
    }
  };

  useEffect(() => {
    api.get(`/companies/myhospitals/${hospitalId}`).then(res => {
      const {
        data: {
          address,
          avatar,
          banks,
          cep,
          city,
          cnpj,
          company,
          complement,
          wazeLink,
          director,
          email,
          name,
          number,
          numberOfEmployees,
          phone1,
          phone2,
          profile,
          socialReason,
          street,
          superintendent,
          uf,
          sendReminderToMyTeam: dataSendReminderToMyTeam,
          sendReminderToNotMyTeam: dataSendReminderToNotMyTeam,
        },
      } = res;

      const {
        account: bankAccount,
        agency: bankAgency,
        bank: bankBank,
        cpfCnpj: bankCpfCnpj,
        holder: bankHolder,
        operation: bankOperation,
      } = banks[0] || {};

      const valArr = {
        address,
        avatar,
        cep,
        city,
        cnpj,
        company,
        complement,
        wazeLink,
        director,
        email,
        name,
        number,
        numberOfEmployees,
        phone1,
        phone2,
        profile,
        socialReason,
        street,
        superintendent,
        uf,
        bankAccount,
        bankAgency,
        bankBank,
        bankCpfCnpj,
        bankHolder,
        bankOperation,
      };

      setValues(valArr);
      setSendReminderToMyTeam(dataSendReminderToMyTeam);
      setSendReminderToNotMyTeam(dataSendReminderToNotMyTeam);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Flex maxH="95vh" flexDir="column" overflowY="auto" overflowX="hidden">
        <Row>
          <Col md>
            <Text
              mb={3}
              fontSize="lg"
              fontWeight="bold"
              color="var(--secondary)"
            >
              Dados do hospital
            </Text>
            <IText
              style={{ marginBottom: 10 }}
              label="nome"
              required
              name="name"
              value={values.name}
              placeholder="Ex: Hospital Saúde"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="razão social"
              name="socialReason"
              value={values.socialReason}
              placeholder="Ex: Hospital Saúde"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="cnpj"
              name="cnpj"
              value={onlyNumberHandleChangeCnpj(values.cnpj || '')}
              placeholder="Ex: 00.000.000/0000-00"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="e-mail"
              required
              name="email"
              value={values.email}
              type="email"
              placeholder="Ex: hospital@contato.com"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="telefone"
              required
              name="phone1"
              value={values.phone1}
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              placeholder="Ex: 11 99987 5542"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="telefone 2"
              name="phone2"
              value={values.phone2}
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              placeholder="Ex: 11 99987 5542"
              onChange={handleChanges}
            />
          </Col>
          <Col>
            <Text
              mb={3}
              fontSize="lg"
              fontWeight="bold"
              color="var(--secondary)"
            >
              Endereço
            </Text>
            <IText
              style={{ marginBottom: 10 }}
              label="cep"
              required
              name="cep"
              value={onlyNumberHandleChangeCep(values.cep || '')}
              placeholder="Ex: 13097-123"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="rua"
              required
              name="street"
              value={values.street}
              placeholder="Ex: Rua Oliveira"
              onChange={handleChanges}
            />
            <Row>
              <Col md>
                <IText
                  style={{ marginBottom: 10 }}
                  label="bairro"
                  required
                  name="address"
                  value={values.address}
                  placeholder="Ex: Jardim das Flores"
                  onChange={handleChanges}
                />
                <IText
                  style={{ marginBottom: 10 }}
                  label="cidade"
                  required
                  name="city"
                  value={values.city}
                  placeholder="Ex: Rio das Ostras"
                  onChange={handleChanges}
                />
              </Col>
              <Col md>
                <IText
                  style={{ marginBottom: 10 }}
                  label="número"
                  required
                  name="number"
                  value={values.number}
                  placeholder="Ex: 432"
                  onChange={handleChanges}
                />
                <IText
                  style={{ marginBottom: 10 }}
                  label="uf"
                  required
                  name="uf"
                  value={values.uf}
                  placeholder="Ex: SP"
                  onChange={handleChanges}
                />
              </Col>
            </Row>
            <IText
              style={{ marginBottom: 10 }}
              label="complemento"
              name="complement"
              value={values.complement}
              placeholder="Ex: Próx. ao parque"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="link waze"
              name="wazeLink"
              value={values.wazeLink}
              placeholder="Ex: https://waze.com/ul/h6vhcn3sry"
              onChange={handleChanges}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col md>
            <IText
              style={{ marginBottom: 10 }}
              label="gerência geral"
              name="superintendent"
              value={values.superintendent}
              placeholder="Ex: José Silva"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="total de funcionários"
              name="numberOfEmployees"
              value={values.numberOfEmployees}
              type="number"
              placeholder="Ex: 5000"
              onChange={handleChanges}
            />
          </Col>
          <Col md>
            <IText
              style={{ marginBottom: 10 }}
              label="coordenação técnica"
              name="director"
              value={values.director}
              placeholder="Ex: Marcos Felipe"
              onChange={handleChanges}
            />
            <IText
              style={{ marginBottom: 10 }}
              label="Perfil"
              name="profile"
              value={values.profile}
              placeholder="Ex: Hospital Universitário"
              onChange={handleChanges}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col md>
            <Text
              mb={3}
              fontSize="lg"
              fontWeight="bold"
              color="var(--secondary)"
            >
              Configuração Lembrete
            </Text>
          </Col>
        </Row>
        <Row>
          <Col alignItems="center" md className="d-flex align-items-center">
            <ICheck
              style={{ marginBottom: 10 }}
              label="Enviar lembrete para médicos vinculados"
              checked={sendReminderToMyTeam}
              onChange={() => setSendReminderToMyTeam(!sendReminderToMyTeam)}
            />
          </Col>
          <Col md className="d-flex align-items-center">
            <ICheck
              style={{ marginBottom: 10 }}
              label="Enviar lembrete para médicos NÃO vinculados"
              checked={sendReminderToNotMyTeam}
              onChange={() =>
                setSendReminderToNotMyTeam(!sendReminderToNotMyTeam)
              }
            />
          </Col>
        </Row>
        <Divider />
        <Flex w="100%" mt={2} justifyContent="space-between">
          <Button title="cancelar" variant="secondary" onClick={onClose} />
          <IsAllowed id={5}>
            <Button
              title="Desativar"
              variant="danger"
              onClick={() => setConfirmException(true)}
            />
            <Alert
              isOpen={confirmException}
              onClose={() => setConfirmException(false)}
              onConfirm={() => {
                setConfirmException(false);
                submit();
              }}
              header="Digite o nome do  hospital para confirmar"
              body={
                <IText
                  style={{ marginBottom: 10 }}
                  required
                  value={hospital}
                  onChange={e => setHospital(e.target.value)}
                />
              }
            />
          </IsAllowed>
          <Button
            variant="success"
            title={loadingSubmit ? 'carregando...' : 'editar'}
            type="submit"
          />
        </Flex>
      </Flex>
      <ProgressModal
        loading={loading}
        text="Desativando hospital... Por favor aguarde, isso pode demorar alguns minutos."
      />
    </form>
  );
}
