import styled from 'styled-components';

export const ScheduleGroupButton = styled.div`
  cursor: pointer;

  min-height: 48px;

  padding: 0 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-radius: var(--br-sm);

  border: 2px solid var(--black);

  font-size: 12px;
  font-weight: 700;

  text-align: center;
  text-transform: uppercase;

  color: ${props => props.textColor};
  background: ${props => props.backgroundColor};

  word-break: break-all;

  &:hover {
    filter: opacity(80%);
    text-decoration: underline;
  }
`;
