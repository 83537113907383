import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Row, Column } from './style'

export default function ListLinks({ options }) {
  // Exemple data for options
  // [
  //   {
  //     title: 'relatório mensal',
  //     to: '/company/reports/month',
  //   },
  //    ...more itens
  // ]
  return (
    <Column align="start" justify="start" direction="column">
      {
        options.map((option, index) => {
          return <Row> <Link to={option.to}>  <FiExternalLink /> {option.title}</Link> </Row>
        })
      }

    </Column>
  );
}
