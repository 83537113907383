import React from 'react';
import { FaDesktop, FaCalendarAlt } from 'react-icons/fa';

import leaveHospital from '~/utils/leaveHospital';
import MeuPlantaoLogo from '~/assets/logo/LogoWhite.png';
import { getParam } from '~/utils/url';

import {
  Container,
  LogoLink,
  LogoImg,
  Navigation,
  PageLink,
  ExitSvg,
} from './styles';

const Nav = () => {
  const { hospitalId } = getParam('hospitalId');

  return (
    <Container>
      <LogoLink to={`/contractorhospital/dashboard/?hospitalId=${hospitalId}`}>
        <LogoImg src={MeuPlantaoLogo} alt="meu-plantao" />
      </LogoLink>
      <Navigation>
        <PageLink
          text="Dashboard"
          to={`/contractorhospital/dashboard/?hospitalId=${hospitalId}`}
          selected={window.location.pathname.includes('dashboard')}
        >
          <FaDesktop />
        </PageLink>
        <PageLink
          text="Escalas"
          to={`/contractorhospital/vacancies/?hospitalId=${hospitalId}`}
          selected={window.location.pathname.includes('vacancies')}
        >
          <FaCalendarAlt />
        </PageLink>
        <PageLink text="Sair do Hospital" onClick={leaveHospital}>
          <ExitSvg />
        </PageLink>
      </Navigation>
    </Container>
  );
};

export default Nav;
