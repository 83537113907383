import React, { useState, useRef, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Flex, HStack } from '@chakra-ui/react';

import { getParam } from '~/utils/url';
import { toDateVacancyText } from '~/utils/toDate';

import Info from './Info';
import DateModal from './DateModal';
import ResumeDetailsModal from './ResumeDetailsModal';

import IsAllowed from '~/components/UI/IsAllowed';
import { Modal } from '~/components/UI/Modal';
import { Spinner } from '~/components/ChakraComponents';

import { SelectContract, Option } from './styles';
import {
  fetchResume,
  fetchContracts,
  resumeModalTitle,
  selectOptionText,
} from './actions';

export default function Resume() {
  const [endDate, setEndDate] = useState(
    moment(new Date())
      .endOf('day')
      .format('YYYY-MM-DD')
  );
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf('day')
      .format('YYYY-MM-DD')
  );
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [resume, setResume] = useState({
    schedules: 0,
    open: 0,
    confirmed: 0,
    notConfirmed: 0,
    lacks: 0,
  });
  const [type, setType] = useState('schedule');
  const [schedule, setSchedule] = useState('day');
  const [contracts, setContracts] = useState();
  const [selectedContract, setSelectedContract] = useState('');

  const { hospitalId } = getParam();

  const dateModalRef = useRef(null);
  const resumeDetailsModalRef = useRef(null);

  const openDate = useCallback(() => {
    dateModalRef.current.openModal();
  }, []);

  const openResumeDetails = useCallback(type => {
    setType(type);
    resumeDetailsModalRef.current.openModal();
  }, []);

  useEffect(() => {
    if (hospitalId) fetchContracts(data => setContracts(data));
  }, []);

  useEffect(() => {
    setLoadingInfo(true);
  }, [schedule, selectedContract]);

  useEffect(() => {
    if (loadingInfo) {
      fetchResume(
        (response, newStartDate, newEndDate) => {
          setResume(response.data);
          setEndDate(newEndDate);
          setStartDate(newStartDate);
          setLoadingInfo(false);
        },
        { startDate, endDate, schedule, selectedContract }
      );
    }
  }, [loadingInfo]);

  return (
    <IsAllowed id={6}>
      {toDateVacancyText(startDate, endDate)}
      <Flex mb={3} alignItems="center" justifyContent="space-between">
        {hospitalId && (
          <SelectContract
            type="select"
            name="contracts"
            onChange={e => setSelectedContract(e.target.value)}
          >
            <option value="">Todos as escalas</option>
            {contracts?.map?.(contract => (
              <option key={contract.id} value={contract.id}>
                {selectOptionText(contract)}
              </option>
            ))}
          </SelectContract>
        )}
        <HStack justifyContent="flex-end" spacing={3}>
          <Option color={schedule === 'day'} onClick={() => setSchedule('day')}>
            diário
          </Option>
          <Option
            color={schedule === 'week'}
            onClick={() => setSchedule('week')}
          >
            semanal
          </Option>
          <Option
            color={schedule === 'month'}
            onClick={() => setSchedule('month')}
          >
            mensal
          </Option>
          <Option color={schedule === 'toDate'} onClick={openDate}>
            data
          </Option>
        </HStack>
      </Flex>
      <Flex
        position="relative"
        flexDir={['column', 'column', 'row', 'row']}
        boxShadow="var(--shadow)"
      >
        <Flex
          position="absolute"
          display={loadingInfo ? 'flex' : 'none'}
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="20"
          alignItems="center"
          justifyContent="center"
          backgroundColor="white"
        >
          <Spinner />
        </Flex>
        <Flex w={['100%', '100%', '20%', '20%']}>
          <Info
            onClick={() => openResumeDetails('schedule')}
            header="total"
            color="blue.400"
            quantity={resume.schedules}
          />
        </Flex>
        <Flex w={['100%', '100%', '40%', '40%']}>
          <Info
            onClick={() => openResumeDetails('open')}
            header="abertos"
            color="orange.400"
            quantity={resume.open}
          />
          <Info
            onClick={() => openResumeDetails('confirmed')}
            header="confirmados"
            color="green.400"
            quantity={resume.confirmed}
          />
        </Flex>
        <Flex w={['100%', '100%', '40%', '40%']}>
          <Info
            onClick={() => openResumeDetails('notConfirmed')}
            header="pendentes"
            color="yellow.400"
            quantity={resume.notConfirmed}
          />
          <Info
            onClick={() => openResumeDetails('lack')}
            header="faltas"
            color="red.400"
            quantity={resume.lacks}
          />
        </Flex>
      </Flex>
      <Modal
        title={resumeModalTitle(type)}
        ref={resumeDetailsModalRef}
        component={ResumeDetailsModal}
        type={type}
        schedule={schedule}
        contract={selectedContract}
        startDate={startDate}
        endDate={endDate}
      />
      <Modal
        title="data"
        ref={dateModalRef}
        component={DateModal}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        setLoadingInfo={setLoadingInfo}
        setResumeStatus={setSchedule}
      />
    </IsAllowed>
  );
}
