import styled from 'styled-components';
import { FaFileMedicalAlt } from 'react-icons/fa';

export const ContractSvg = styled(FaFileMedicalAlt)`
  margin-bottom: 20px;

  font-size: 120px;

  color: var(--primary);
`;
