import React from 'react';
import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';

import { Label } from '~/components/UI/Text';

export const Select = styled.select`
  width: 100%;
  height: 40px;

  padding: 5px;

  border-radius: var(--br-sm);

  border: 2px solid var(--black);
  background-color: var(--background-alt);
`;

export default function ISelect({
  required,
  label = '',
  options,
  style,
  ...rest
}) {
  return (
    <Flex flexDir="column" w="100%" style={style}>
      <Label>{required ? label + ' *' : label}</Label>
      <Select required={required} {...rest}>
        {options.map(opt => (
          <option key={opt.id} value={opt.value}>
            {opt.text}
          </option>
        ))}
      </Select>
    </Flex>
  );
}
