import React from 'react';
import ReactCalendar from 'react-calendar';
import { Flex } from '@chakra-ui/layout';
import { FaRegCalendarAlt as CalendarSvg } from 'react-icons/fa';

import { Pop, IconContainer } from '~/components/UI/Functionality';

export default function Calendar({ value, onChange }) {
  return (
    <Pop
      trigger={
        <IconContainer>
          <CalendarSvg />
        </IconContainer>
      }
      menu={
        <Flex borderRadius="4px" boxShadow="var(--shadow)">
          <ReactCalendar value={value} onChange={onChange} calendarType="US" />
        </Flex>
      }
    />
  );
}
