import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;
  border-bottom: 1px solid #e7e7e7;

  svg {
    margin-right: 20px;
    font-size: 50px;
    color: var(--primary);
  }
`;
