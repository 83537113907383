import React, { useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Button } from '~/components/ChakraComponents/';
import { Form, Field } from '~/components/Kiwistrap/Form';

export default function ChangePassword({ userId, onClose }) {
  const handleSubmit = useCallback(
    async data => {
      if (data.password.length < 6) {
        return toast.error('Por favor insira uma senha com 8 caracteres.');
      }

      if (data.password !== data.password2) {
        return toast.error('Senhas digitadas são diferentes.');
      }

      const { password } = data;

      await api.patch(`/contractors/users/${userId}`, { password });

      return window.location.reload();
    },
    [userId]
  );

  return (
    <Flex flexDir="column">
      <Form onSubmit={handleSubmit}>
        <Field required type="password" label="senha nova" name="password" />
        <Field
          required
          type="password"
          label="confirmar senha"
          name="password2"
        />
        <Flex mt="20px">
          <Button
            title="cancelar"
            variant="secondary"
            margin="0 20px 0 0"
            onClick={onClose}
          />
          <Button title="confirmar" variant="success" type="submit" />
        </Flex>
      </Form>
    </Flex>
  );
}
