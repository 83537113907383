import React from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';

export const defaultInputs = {
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment()
    .add(1, 'month')
    .format('YYYY-MM-DD'),
  companyLogo: false,
  open: true,
  fixed: true,
  exception: true,
  cover: true,
  pending: true,
  isLandscape: false,
  singleColor: false,
};

export const sHeight = '20px';
export const sFontSize = 12;

export const bHeight = '2cm';
export const bFontSize = 16;

export const defaultStyles = {
  width: '3cm',
  height: bHeight,
  padding: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 4,
  color: 'white',
  fontSize: bFontSize,
  fontWeight: 600,
};

export const defaultBgColors = {
  open: '#00C875',
  fixed: '#5C69E1',
  exception: '#fd6e36',
  cover: '#2db7f8',
  pending: '#6E7884',
  all: '#5C69E1',
};

export const VacancyCard = ({ styles, backgroundColor, children }) => {
  return (
    <Flex
      w={styles.width}
      h={styles.height}
      p={styles.padding}
      align={styles.alignItems}
      justify={styles.justifyContent}
      flexDir={styles.flexDirection}
      borderRadius={styles.borderRadius}
      color={styles.color}
      fontWeight={styles.fontWeight}
      backgroundColor={backgroundColor}
      textAlign="center"
    >
      {children}
    </Flex>
  );
};
