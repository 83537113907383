import axios from 'axios';

export default async cep => {
  if (!cep) return false;
  if (cep.length !== 9) return false;

  try {
    const url = `https://viacep.com.br/ws/${cep.replace('-', '')}/json/`;

    const { data } = await axios.get(url);

    if (data.erro) return false;

    return data;
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else {
      console.log('Error', error.message);
    }
    console.log(error);
  }
};
