import React from 'react';
import { Text } from '@chakra-ui/react';

export default function Label({
  color = 'var(--secondary)',
  fontSize = 'xl',
  fontWeight = 'bold',
  children,
  ...rest
}) {
  return (
    <Text {...rest} color={color} fontSize={fontSize} fontWeight={fontWeight}>
      {children}
    </Text>
  );
}
