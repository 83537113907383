import React from 'react';
import { VStack } from '@chakra-ui/react';

import { Label } from '~/components/UI/Text';

export default function Section({ header = '', children }) {
  return (
    <VStack align="flex-start">
      <Label color="var(--primary)" fontSize="0.75rem">
        {header}
      </Label>
      {children}
    </VStack>
  );
}
