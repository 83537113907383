import React from 'react';
import { Title } from './styles';

export default function FormTitle({ title, children }) {
  return (
    <Title>
      {title} {children}
    </Title>
  );
}
