import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { BsPhone } from 'react-icons/bs';
import { Container } from 'react-bootstrap';
import { FaStreetView } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

import api from '~/services/api';
import history from '~/services/history';
import phoneMask from '~/utils/phoneMask';

import InfoSection from './components/InfoSection';
import EditPasswordModal from './components/EditPasswordModal';

import IsAllowed from '~/components/UI/IsAllowed';
import PageLayout from '~/pages/_layouts/company/PageLayout';

import { Modal } from '~/components/UI/Modal';
import { Button } from '~/components/ChakraComponents/';

export default function Profile() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [profileData, setProfileData] = useState();

  const fetchData = useCallback(() => {
    api.get('/companies/companyprofile').then(response => {
      setProfileData(response.data);
      setLoadingPage(false);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const editPasswordRef = useRef(null);

  const openEditPassword = useCallback(() => {
    return editPasswordRef.current.openModal();
  }, []);

  const closeEditPassword = useCallback(() => {
    return editPasswordRef.current.closeModal();
  }, []);

  const navigateToEditAccount = useCallback(() => {
    history.push('/company/editaccount');
  }, []);

  return (
    <PageLayout loading={loadingPage}>
      {profileData && (
        <Container>
          <InfoSection
            icon={FaStreetView}
            title="Endereço"
            info={`${profileData.uf} - ${profileData.city}, ${profileData.address}, ${profileData.street} ${profileData.number}`}
          />
          <InfoSection
            icon={AiOutlineMail}
            title="E-mail"
            info={profileData.email}
          />
          <InfoSection
            icon={BsPhone}
            title="Telefone"
            info={`${phoneMask(profileData.phone1)} ${phoneMask(
              profileData.phone2
            )}`}
          />
          <Flex mt={7} justifyContent="space-evenly">
            <IsAllowed id={16}>
              <Button title="editar conta" onClick={navigateToEditAccount} />
            </IsAllowed>
            <Button title="editar senha" onClick={openEditPassword} />
          </Flex>
          <Modal
            ref={editPasswordRef}
            title="Editar Senha"
            component={EditPasswordModal}
            onClose={closeEditPassword}
          />
        </Container>
      )}
    </PageLayout>
  );
}
