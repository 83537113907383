import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import { Flex, Text } from '@chakra-ui/react';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { Title } from '~/components/Kiwistrap/Page';
import { Button, Spinner } from '~/components/ChakraComponents/';

import { SelectCard } from './styles';

export default function ChooseRoutes({ user, onClose }) {
  const [loading, setLoading] = useState(true);

  const [homepages, setHomepages] = useState();
  const [allPermissions, setAllPermissions] = useState(false);

  // Fetch data
  useEffect(() => {
    api.get('/companies/homepages').then(response => {
      const { data } = response.data;

      data.forEach(page => {
        page.isActive = false;

        if (page.id === user.usercompany[0].homepage.id) {
          page.isActive = true;
        }
      });

      if (user.usercompany[0].allPermissions) {
        setAllPermissions(true);
      }

      setHomepages(data);
      setLoading(false);
    });
  }, []);

  const chooseHome = i => {
    const clone = [...homepages];

    clone.forEach(page => {
      page.isActive = false;
    });

    clone[i].isActive = true;

    setHomepages(clone);
  };

  const onSubmit = async () => {
    const { id: homepage } = homepages.find(p => p.isActive);

    const permissionsList = {
      homepage,
      allPermissions,
    };

    api
      .patch(`/companies/users/${user.id}`, permissionsList)
      .then(() => {
        toast.success('Usuário editado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  };

  return (
    <Flex
      w="600px"
      maxW="95vw"
      maxH="80vh"
      flexDir="column"
      overflowY="scroll"
      overflowX="hidden"
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Flex flexDir="column" alignItems="center">
            <Title center size={18} margin="0 0 5px">
              Escolha a página inicial
            </Title>
            <Text mb="20px">Ao entrar a conta iniciará nesta página.</Text>
            <Row>
              {homepages &&
                homepages.map((page, i) => (
                  <Col md key={page.name}>
                    <SelectCard
                      onClick={() => chooseHome(i)}
                      isActive={page.isActive}
                    >
                      {page.name}
                    </SelectCard>
                  </Col>
                ))}
            </Row>
          </Flex>
          <Flex
            flexDir="column"
            alignItems="center"
            mt="20px"
            textAlign="center"
          >
            <Title center size={18} margin="0 0 5px">
              Acesso total
            </Title>
            <Text mb="20px">
              A conta poderá realizar quaisquer ação no sistema.
            </Text>
          </Flex>
          <Flex justifyContent="center" mb="20px">
            <SelectCard
              isActive={allPermissions}
              onClick={() => setAllPermissions(!allPermissions)}
            >
              Acesso Total
            </SelectCard>
          </Flex>
          <Flex mt="20px" justifyContent="space-between">
            <Button title="cancelar" variant="secondary" onClick={onClose} />
            <Button title="confirmar" variant="success" onClick={onSubmit} />
          </Flex>
        </>
      )}
    </Flex>
  );
}
