import React from 'react';
import styled from 'styled-components';
import { Flex } from '@chakra-ui/layout';

const Container = styled(Flex)`
  position: relative;

  section {
    display: none;

    position: absolute;
    top: ${props => props.t};
    z-index: 500;

    left: ${props => (props.center ? '50%' : '0')};
    transform: ${props =>
      props.center ? 'translate(-50%, 0)' : 'translate(0, 0)'};
  }

  &:hover {
    section {
      display: flex;
    }
  }
`;

export default function Pop({ trigger, menu, t = '1.5rem', center = true }) {
  return (
    <Container t={t} center={center}>
      {trigger}
      <section>{menu}</section>
    </Container>
  );
}
