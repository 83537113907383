export default {
  animation: false,
  cornerRadius: 20,
  layout: { padding: 0 },
  legend: { display: false },
  maintainAspectRatio: false,
  responsive: true,
  cutoutPercentage: 75,
  tooltips: {
    backgroundColor: '#fff',
    bodyFontColor: '#656565',
    borderColor: '#7f7f7f',
    borderWidth: 1,
    enabled: true,
    footerFontColor: '#656565',
    intersect: false,
    mode: 'index',
    titleFontColor: '#656565',
  },
};
