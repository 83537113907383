import React, { useState, useCallback, useEffect } from 'react';
import { Stack } from '@chakra-ui/react';
import { GrMail } from 'react-icons/gr';
import { Container } from 'react-bootstrap';
import { AiFillPhone, AiFillBank } from 'react-icons/ai';
import {
  FaHospitalAlt,
  FaAddressCard,
  FaStreetView,
  FaUserFriends,
  FaRegCreditCard,
  FaClipboard,
  FaUserTie,
} from 'react-icons/fa';

import api from '~/services/api';
import { getParam } from '~/utils/url';

import cnpjMask from '~/utils/cnpjMask';
import phoneMask from '~/utils/phoneMask';

import InfoSection from './components/InfoSection';

import { Title } from '~/components/Kiwistrap/Page';
import { Spinner } from '~/components/ChakraComponents';

import PageLayout from '~/pages/_layouts/hospital/PageLayout';

export default function Profile() {
  const [loadingPage, setLoadingPage] = useState(true);

  const [hospital, setHospital] = useState({});
  const [bank, setBank] = useState();

  const { hospitalId } = getParam('hospitalId');

  const fetchData = useCallback(() => {
    const url = `/companies/myhospitals/${hospitalId}`;

    api.get(url).then(response => {
      const { data } = response;

      setHospital(data);

      if (data.banks) {
        setBank(data.banks[0]);
      }

      setLoadingPage(false);
    });
  }, [hospitalId]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageLayout title="Meu Perfil">
      <Container>
        {loadingPage ? (
          <Spinner />
        ) : (
          <>
            <Title margin="0" size={24}>
              Dados
            </Title>
            <Stack
              w="100%"
              spacing={[0, 0, 0, 8]}
              direction={['column', 'column', 'column', 'row']}
            >
              <Stack w="100%">
                <InfoSection
                  icon={FaHospitalAlt}
                  title="Razão social - CNPJ"
                  info={`${hospital.socialReason} - ${cnpjMask(hospital.cnpj)}`}
                />
                <InfoSection
                  icon={FaStreetView}
                  title="Endereço"
                  info={`${hospital.uf} - ${hospital.city}, ${hospital.address}, ${hospital.street} ${hospital.number}`}
                />
                <InfoSection
                  icon={GrMail}
                  title="E-mail"
                  info={hospital.email}
                />
                <InfoSection
                  icon={AiFillPhone}
                  title="Telefone"
                  info={`${phoneMask(hospital.phone1)} ${phoneMask(
                    hospital.phone2
                  )}`}
                />
              </Stack>
              <Stack w="100%">
                <InfoSection
                  icon={FaUserTie}
                  title="Gerência Geral"
                  info={hospital.superintendent}
                />
                <InfoSection
                  icon={FaUserTie}
                  title="Coordenação Técnica"
                  info={hospital.director}
                />
                <InfoSection
                  icon={FaUserFriends}
                  title="Número de funcionários"
                  info={hospital.numberOfEmployees}
                />
                <InfoSection
                  icon={FaClipboard}
                  title="Perfil"
                  info={hospital.profile}
                />
              </Stack>
              {/* {bank && (
                <Stack w="100%">
                  <InfoSection
                    icon={FaAddressCard}
                    title="Titular da conta - cpf/cnpj"
                    info={`${bank.holder} - ${bank.cpfCnpj}`}
                  />
                  <InfoSection
                    icon={AiFillBank}
                    title="Banco - Agência"
                    info={`${bank.bank} - ${bank.agency}`}
                  />
                  <InfoSection
                    icon={FaRegCreditCard}
                    title="Conta - Operação"
                    info={`${bank.account} - ${bank.operation}`}
                  />
                </Stack>
              )} */}
            </Stack>
          </>
        )}
      </Container>
    </PageLayout>
  );
}
