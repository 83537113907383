import React, { useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

import { Button } from '~/components/ChakraComponents/';

import { InputLabel, InputSearch } from './styles';

export default function FindUserInput({ setName }) {
  const searchInputRef = useRef(null);

  return (
    <Flex alignItems="flex-end">
      <Flex flexDir="column" w={['100%', '100%', '100%', 'row']}>
        <InputLabel>buscar usuário</InputLabel>
        <InputSearch
          placeholder="Ex: Cláudio Duarte"
          ref={searchInputRef}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              setName(searchInputRef.current.value);
            }
          }}
        />
      </Flex>
      <Button
        width="auto"
        height="40px"
        margin="0 0 0 10px"
        icon2={<FaSearch size={24} />}
        onClick={() => setName(searchInputRef.current.value)}
      />
    </Flex>
  );
}
