import React from 'react';
import { Text } from '@chakra-ui/react';

import convertBRL from '~/utils/convertBRL';
import { statusText } from '~/utils/genStatus';

import { Card } from '~/components/ChakraComponents';
import { Label } from '~/components/UI/Text';

export default function Section({
  doctor,
  pendingDoctor,
  value,
  type,
  confirmed,
  justification,
}) {
  return (
    <Card flexDir="column" w="100%" h="100%">
      <Label mb="4px">Médico</Label>
      <Text>
        {doctor && doctor.doctor[0].user.name}
        {pendingDoctor && `${pendingDoctor} (Médico Pendente)`}
        {!doctor && !pendingDoctor && 'Nenhum'}
      </Text>
      <Label m="16px 0 4px">Valor</Label>
      <Text>{convertBRL(value)}</Text>
      <Label m="16px 0 4px">Tipo</Label>
      <Text>{statusText(type)}</Text>
      <Label m="16px 0 4px">Confirmado</Label>
      <Text>{confirmed ? 'Sim' : 'Não'}</Text>
      <Label m="16px 0 4px">Justificativa</Label>
      <Text>{justification || 'Nenhuma'}</Text>
    </Card>
  );
}
