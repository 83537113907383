import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { IconContainer } from '~/components/UI/Functionality';

export default function DesktopScheduleType({ navigate }) {
  const { scheduleType } = useParams();

  return (
    <Tooltip
      hasArrow
      label={scheduleType === 'week' ? 'Ver Mensal' : 'Ver Semanal'}
      bg="gray.600"
    >
      <span>
        <IconContainer
          cursor="pointer"
          fontSize="1.8rem"
          fontWeight="semibold"
          onClick={() => navigate(scheduleType === 'week' ? 'month' : 'week')}
        >
          {scheduleType === 'week' ? '7' : '30'}
        </IconContainer>
      </span>
    </Tooltip>
  );
}
