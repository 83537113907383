import React from 'react';

import { Span } from './styles';

export default function Text({
  size,
  weight,
  uppercase,
  color,
  width,
  padding,
  margin,
  style,
  children,
}) {
  return (
    <Span
      size={size}
      weight={weight}
      uppercase={uppercase}
      color={color}
      width={width}
      padding={padding}
      margin={margin}
      style={style}
    >
      {children}
    </Span>
  );
}
