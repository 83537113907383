import React from 'react';

import history from '~/services/history';

import { Container } from './styles';

export default function Card({ icon: Icon, title, color, href }) {
  return (
    <Container color={color} onClick={() => history.push(href)}>
      <Icon />
      <p>{title}</p>
    </Container>
  );
}
