import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import {
  BsChevronDoubleLeft,
  BsChevronLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from 'react-icons/bs';

import { Page, NavButton } from './styles';

export default function Pagination({ l, t, onClick, initialCurrentPage = 1 }) {
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(0);
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);

  useEffect(() => {
    setTotal(t);
    setLimit(l);

    const totalPages = Math.ceil(total / limit);

    const arrayPages = [];

    for (let i = 1; i <= totalPages; i += 1) {
      arrayPages.push(i);
    }

    setPages(arrayPages);
  }, [l, limit, t, total]);

  const renderPages = () => {
    if (pages.length <= 5) {
      return pages.map(page => (
        <Page
          key={page}
          onClick={() => {
            onClick(page);
            setCurrentPage(page);
          }}
          isSelect={page === currentPage}
        >
          {page}
        </Page>
      ));
    }

    let firstPage = currentPage - 2;
    if (firstPage < 1) firstPage = 1;

    let secondPage = firstPage + 1;

    let thirdPage = currentPage;
    if (thirdPage < 3) thirdPage = 3;

    let fourthPage = thirdPage + 1;

    let fifthPage = thirdPage + 2;

    if (fifthPage > pages.length) {
      firstPage = firstPage - (fifthPage - pages.length);
      secondPage = secondPage - (fifthPage - pages.length);
      thirdPage = thirdPage - (fifthPage - pages.length);
      fourthPage = fourthPage - (fifthPage - pages.length);
      fifthPage = fifthPage - (fifthPage - pages.length);
    }

    let pagesArr = [
      pages[firstPage - 1],
      pages[secondPage - 1],
      pages[thirdPage - 1],
      pages[fourthPage - 1],
      pages[fifthPage - 1],
    ];

    return pagesArr.map(page => (
      <Page
        key={page}
        onClick={() => {
          onClick(page);
          setCurrentPage(page);
        }}
        isSelect={page === currentPage}
      >
        {page}
      </Page>
    ));
  };

  if (pages.length <= 1) {
    return null;
  }

  return (
    <Flex py={4} justify="center" flexShrink="none">
      {pages.length > 5 && (
        <NavButton
          onClick={() => {
            onClick(1);
            setCurrentPage(1);
          }}
        >
          <BsChevronDoubleLeft />
        </NavButton>
      )}
      <NavButton
        onClick={() => {
          let pageToSet = currentPage - 1;

          if (pageToSet < 1) pageToSet = 1;

          onClick(pageToSet);
          setCurrentPage(pageToSet);
        }}
      >
        <BsChevronLeft />
      </NavButton>
      {renderPages()}
      <NavButton
        isRight
        onClick={() => {
          let pageToSet = currentPage + 1;

          if (pageToSet > pages.length) pageToSet = pages.length;

          onClick(pageToSet);
          setCurrentPage(pageToSet);
        }}
      >
        <BsChevronRight />
      </NavButton>
      {pages.length > 5 && (
        <NavButton
          isRight
          onClick={() => {
            onClick(pages.length);
            setCurrentPage(pages.length);
          }}
        >
          <BsChevronDoubleRight />
        </NavButton>
      )}
    </Flex>
  );
}
