import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 20px;
  margin-top: 20px;

  border-radius: 4px;

  word-wrap: break-all;
  overflow-wrap: break-all;

  box-shadow: var(--shadow);
  background-color: var(--background-alt);

  a {
    text-decoration: underline;
  }
`;

export const AuthorAvatar = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 50%;
  border: 3px solid var(--primary);

  object-fit: cover;
  flex-shrink: none;
`;

export const NewsTitle = styled.p`
  font-size: 24px;
  font-weight: bold;

  color: var(--primary);
`;
