import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { GiExitDoor } from 'react-icons/gi';
import { BsFillBellFill } from 'react-icons/bs';

export const Container = styled.div`
  width: 100%;
  height: 81px;

  background-color: var(--primary);

  display: none;
  align-items: center;
  grid-template-columns: 170px auto 90px;

  @media screen and (min-width: 1180px) {
    display: grid;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoImg = styled.img`
  width: 140px;
`;

export const Navigation = styled.nav`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageLink = styled(Link)`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  text-align: center;

  border-left: 1px solid var(--hover-primary);

  background-color: ${props =>
    props.selected ? 'var(--hover-primary)' : 'transparent'};
  padding-top: ${props => (props.selected ? '4px' : '0')};
  border-bottom: ${props =>
    props.selected ? '4px solid var(--secondary)' : '4px solid transparent'};

  &:last-child {
    border-right: 1px solid var(--hover-primary);
  }

  &:hover {
    background-color: var(--hover-primary);
  }

  svg {
    width: 30px;
    height: 30px;

    position: absolute;
    top: 15px;
    z-index: 2;

    fill: #f4f4f4;
  }

  ::after {
    content: '${props => props.text}';

    display: inline;

    padding-bottom: 14px;

    font-size: 10px;
    font-weight: 600;

    color: var(--background-alt);
  }
`;

export const ExitSvg = styled(GiExitDoor)`
  top: 10px !important;
  width: 40px !important;
  height: 40px !important;
`;
