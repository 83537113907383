import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import { AiOutlinePlus } from 'react-icons/ai';
import { Flex, Text, HStack } from '@chakra-ui/react';

import { IText, IPerc } from '~/components/UI/Inputs';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Card, Divider } from '~/components/ChakraComponents';

export default function TaxDeductionsModal({
  taxDeductions,
  setTaxDeductions,
  closeModal,
}) {
  const [inputs, setInputs] = useState({ name: '', value: '' });
  const [currentTaxes, setCurrentTaxes] = useState(taxDeductions);

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onAdd = () => {
    if (inputs.name === '' || inputs.value === '') {
      return toast.error('Todos os campos são obrigatórios');
    }

    if (isNaN(+inputs.value)) {
      return toast.error('Valor inválido.');
    }

    setInputs({ name: '', value: '' });
    setCurrentTaxes([
      ...currentTaxes,
      { name: inputs.name, value: inputs.value / 100, type: 'percent' },
    ]);
  };

  const onRemove = i => {
    const arr = [...currentTaxes];

    arr.splice(i, 1);

    setCurrentTaxes(arr);
  };

  const onSubmit = () => {
    setTaxDeductions(currentTaxes);
    closeModal();
  };

  return (
    <ModalContainer w="400px">
      <HStack align="flex-end">
        <IText
          required
          label="nome"
          name="name"
          value={inputs.name}
          onChange={handleChanges}
        />
        <IPerc
          required
          label="valor"
          name="value"
          value={inputs.value}
          onChange={handleChanges}
        />
        <Button
          width="40px"
          height="40px"
          icon={<AiOutlinePlus size={24} />}
          onClick={onAdd}
        />
      </HStack>
      {currentTaxes.map((tax, i) => (
        <Card
          key={Math.random(Math.random())}
          justify="space-between"
          align="center"
        >
          <Text>{tax.name}</Text>
          <HStack align="center">
            <Text>{`${(tax.value * 100).toFixed(2)}%`}</Text>
            <FaTrash
              size={20}
              color="var(--red)"
              onClick={() => onRemove(i)}
              style={{ cursor: 'pointer' }}
            />
          </HStack>
        </Card>
      ))}
      <Divider />
      <Flex justify="space-between">
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <Button title="confirmar" variant="success" onClick={onSubmit} />
      </Flex>
    </ModalContainer>
  );
}
