import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';

import history from '~/services/history';
import { getParam } from '~/utils/url';

import MenuLeft from './MenuLeft';
import NoneChoosenMobile from './NoneChoosenMobile';

import PageLayout from '~/pages/_layouts/contractorhospital/PageLayout';
import { Text } from '~/components/Kiwistrap/Page';
import { DesktopContainer } from '~/components/UI/Sections/MobileVacancyAgenda';

import { GearSvg } from './styles';

export default function VacanciesNoneChoosen() {
  const { hospitalId } = getParam('hospitalId');
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const { groupId, specialtyId, scheduleType, vacancyDate } = localStorage;

    let date = vacancyDate;
    let type = scheduleType;

    if (!date || date === 'undefined') {
      date = moment(new Date()).format('YYYY-MM-DD');
    }

    if (!type || type === 'undefined') {
      type = 'week';
    }

    if (groupId && groupId !== 'undefined') {
      const url = `/contractorhospital/vacancies/group/${groupId}/${type}/${date}/?hospitalId=${hospitalId}`;

      return history.push(url);
    }

    if (specialtyId && specialtyId !== 'undefined') {
      const url = `/contractorhospital/vacancies/specialty/${specialtyId}/${type}/${date}/?hospitalId=${hospitalId}`;

      return history.push(url);
    }

    return setValidated(true);
  }, []);

  return (
    <>
      <NoneChoosenMobile />
      <DesktopContainer>
        {validated && (
          <PageLayout disableDefaultButtons userInfoButtons={<MenuLeft />}>
            <Flex
              h="calc(100vh - 120px)"
              flexDir="column"
              justify="center"
              align="center"
            >
              <GearSvg />
              <Text size="24px" color="var(--primary)">
                Por favor selecione uma opção na esquerda.
              </Text>
            </Flex>
          </PageLayout>
        )}
      </DesktopContainer>
    </>
  );
}
