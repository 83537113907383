import React, { useState, useCallback, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import api from '~/services/api';

import { Spinner } from '~/components/ChakraComponents';

export default function HoursModal({ doctorId }) {
  const [loading, setLoading] = useState(true);
  const [hourString, setHourString] = useState('');

  const fetchData = useCallback(() => {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    const url = `/companies/doctors/${doctorId}/hours?month=${month}&year=${year}`;

    api.get(url).then(response => {
      const { hours, minutes } = response.data.total;

      let formattedHour;
      let formattedMinutes;

      formattedHour = String(hours);
      formattedMinutes = String(minutes);

      if (hours < 10) {
        formattedHour = `0${hours}`;
      }

      if (minutes < 10) {
        formattedMinutes = `0${minutes}`;
      }

      setLoading(false);
      setHourString(`${formattedHour}:${formattedMinutes}h`);
    });
  }, [doctorId]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Flex
      w="200px"
      flexDir="column"
      alignItems="center"
      fontSize="md"
      fontWeight="bold"
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Text mb={1} color="var(--primary)">
            Total de horas:
          </Text>
          <Text color="var(--secondary)" textTransform="uppercase">
            {hourString}
          </Text>
        </>
      )}
    </Flex>
  );
}
