import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Flex, Text } from '@chakra-ui/react';

import getFirstLastName from '~/utils/getFirstLastName';
import { vacancyCardBackgroundColor } from '~/utils/genStatus';

import { Avatar } from './styles';

const Container = styled.div`
  cursor: pointer;
  overflow: hidden;
  position: relative;

  width: 100%;
  height: 100%;

  padding: 8px 4px 4px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;

  border-radius: 4px;

  font-weight: 600;
  text-align: center;

  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  /*var(--shadow);*/

  color: var(--background-alt);

  background-color: ${props => props.color};

  ${'' /* p {
    &:first-child {
      font-size: 16px;
    }

    &:last-child {
      font-size: 12px;
    }
  }

  @media (max-width: 1600px) {
    p {
      &:first-child {
        font-size: 12px;
      }

      &:last-child {
        font-size: 10px;
      }
    }
  } */}
`;

function getEmotionsByVacancy(vacancy) {
  let result = '';
  if (vacancy.inCash) {
    result += ' 👁️ ';
  }
  if (vacancy.requestAntecipe) {
    result += ' ➡️ ';
  }
  if (vacancy.requestIsPaid) {
    result += ' ✅ ';
  }
  if (vacancy.differentiatedValue) {
    result += ' 💲 ';
  }
  return result;
}

export default function VacancyCard({
  vacancy,
  onClick,
  componentBarLateral,
  componentBarTop,
}) {
  const renderDoctorName = useCallback(() => {
    return getFirstLastName(vacancy.doctor.user.name);
  }, []);

  const renderDoctorCrm = useCallback(() => {
    const { crm, crmUf } = vacancy.doctor;

    return `${crm}-${crmUf}`;
  }, []);

  return (
    <Container
      color={vacancyCardBackgroundColor(vacancy.type, !vacancy.belongsMyTeam)}
      onClick={onClick}
    >
      <Flex
        flexDir="column"
        paddingRight="4px"
        borderRight="1px #00000024 solid"
        height="100%"
      >
        {componentBarLateral}
      </Flex>
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Flex width="100%" flexDir="column">
          {componentBarTop}
        </Flex>
        <Avatar
          src={vacancy.doctor.user.avatar}
          alt={vacancy.doctor.user.name}
        />
        <Flex flexDir="column">
          <Text color="#505050" fontSize="10px">
            {renderDoctorName()}
          </Text>
          <Text color="#505050" fontSize="10px">
            {renderDoctorCrm()}
          </Text>
          <Text color="#505050" fontSize="12px">
            {getEmotionsByVacancy(vacancy)}
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
}
