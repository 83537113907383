import history from '~/services/history';

import { getUser } from '~/services/auth';

const storages = [
  'groupId',
  'hospitalId',
  'scheduleId',
  'specialtyId',
  'scheduleType',
  'vacancyDate',
];

export default () => {
  storages.forEach(storage => {
    return localStorage.removeItem(storage);
  });

  const user = getUser();

  if (user.type === 'contractor') {
    return history.push('/contractor/dashboard');
  }

  history.push(`/company/${user.homepage.url}`);
};
