import React from 'react';
import { Text, Flex, Divider } from '@chakra-ui/react';
import { SiGooglemaps } from 'react-icons/si';
import { ModalContainer } from '~/components/UI/Modal';

export default function Details({ data }) {
  return (
    <ModalContainer w="600px">
      <Flex justifyContent="space-between" height="100%">
        <Flex flexDir="column" maxW="270px">
          <Text mb={3}>
            <b>Contrato:</b> {data.contractName}
          </Text>
          <Text mb={3}>
            <b>Setor:</b> {data.contractSector}
          </Text>
          <Text mb={3}>
            <b>Data da vaga:</b> {data.vacancyDate}
          </Text>
          <Text mb={3}>
            <b>Horário da vaga:</b> {data.vacancyStartHour}/
            {data.vacancyEndHour}
          </Text>
          <Text mb={3}>
            <b>Horário trabalhado:</b> {data.vacancyCheckinDate}/
            {data.vacancyCheckoutDate}
          </Text>
          <Text mb={3}>
            <b>Valor da vaga:</b> {data.vacancyValue}
          </Text>
          <Text mb={3}>
            <b>Valor Aprovado:</b> {data.vacancyApprovedValue}
          </Text>
          <Text mb={3}>
            <b>Fluxo:</b> {data.vacancyFlow}
          </Text>
          <Text mb={3}>
            {data.vacancyCheckinNoAppJustification && (
              <>
                <b>Justificativa checkin:</b>{' '}
                {data.vacancyCheckinNoAppJustification}
              </>
            )}
          </Text>
          <Text>
            {data.vacancyCheckinAppJustification && (
              <>
                <b>Justificativa checkin:</b>{' '}
                {data.vacancyCheckinAppJustification}
              </>
            )}
          </Text>
        </Flex>
        <Divider orientation="vertical" />
        <Flex flexDir="column" maxW="270px">
          <Text mb={3}>
            <b>Hospital:</b> {data.hospitalName}
          </Text>
          <Text mb={3}>
            <b>Nome:</b> {data.doctorName}
          </Text>
          <Text mb={3}>
            <b>CRM:</b> {data.doctorCrm}
          </Text>
          <Text mb={3}>
            <b>Especialidade:</b> {data.contractSpecialty}
          </Text>
          <Flex align="center" mb={3}>
            <b>Checkin:</b>
            {data.vacancyCheckinPositionLink && (
              <a
                href={data.vacancyCheckinPositionLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: 10 }}
              >
                <SiGooglemaps />
              </a>
            )}
          </Flex>
          <Flex align="center" mb={3}>
            <b>Checkout:</b>
            {data.vacancyCheckoutPositionLink && (
              <a
                href={data.vacancyCheckoutPositionLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: 10 }}
              >
                <SiGooglemaps />
              </a>
            )}
          </Flex>
          <Text mb={3}>
            <b>Horas previstas:</b> {data.expectedWorkHours}
          </Text>
          <Text mb={3}>
            <b>Estado do valor:</b> {data.valueStatus}
          </Text>
          <Text mb={3}>
            {data.vacancyCompanyJustification && (
              <>
                <b>Justificação da empresa:</b>{' '}
                {data.vacancyCompanyJustification}
              </>
            )}
          </Text>
          <Text mb={3}>
            {data.vacancyCheckoutNoAppJustification && (
              <>
                <b>Justificativa checkout:</b>{' '}
                {data.vacancyCheckoutNoAppJustification}
              </>
            )}
          </Text>
          <Text>
            {data.vacancyCheckoutAppJustification && (
              <>
                <b>Justificativa checkout:</b>{' '}
                {data.vacancyCheckoutAppJustification}
              </>
            )}
          </Text>
        </Flex>
      </Flex>
    </ModalContainer>
  );
}
