import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import api from '~/services/api';

import InputSelect from '~/components/Site/Inputs/InputSelect';

import { Div, Row, Column, SubTitle, Text } from '~/components/Kiwistrap/Page';

import { yearsArr, monthsArr } from '../dateArrays';

import Company from './Company';

const internalServerError =
  'Houve um problema com os nossos servidores. Tente novamente.';

const getDate = () => {
  const date = format(new Date(), 'yyyy-MM-dd').split('-');
  const yearNumber = parseInt(date[0], 10);
  const monthNumber = parseInt(date[1], 10);
  return [monthNumber, yearNumber];
};

export default function ReportsModal() {
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [year, setYear] = useState(getDate()[1]);
  const [month, setMonth] = useState(getDate()[0]);
  const [companies, setCompanies] = useState([]);
  const [hospitals, setHospitals] = useState([]);

  const fetchData = async () => {
    setLoadingInfo(true);

    const url = `/doctors/reports/payments?year=${year}&month=${month}`;

    try {
      const response = await api.get(url);

      const paymentsData = response.data.data;

      const companiesArr = [];
      const hospitalsArr = [];

      paymentsData.forEach(hospital => {
        const pay = Math.abs(hospital.total);

        if (pay > 0) {
          const hospitalClone = { ...hospital };

          hospitalClone.total = pay;
          hospitalsArr.push(hospitalClone);

          const companyName = hospital.hospital.company.name;

          const isIncluded = companiesArr.find(
            company => company.name === companyName
          );

          if (!isIncluded) {
            companiesArr.push({ name: companyName, total: pay });
          } else {
            const i = companiesArr.findIndex(
              company => company.name === companyName
            );
            companiesArr[i].total += pay;
          }
        }
      });

      setCompanies(companiesArr);
      setHospitals(hospitalsArr);
    } catch (error) {
      toast.error(internalServerError);
    }

    setLoadingInfo(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [year, month]);

  const renderHeader = () => {
    const yearText = yearsArr.find(y => y.value === year).text;
    const monthText = monthsArr.find(m => m.value === month).text;

    return (
      <Row justifyContent="center">
        <Text size="16px" padding="10px 0 0">
          Exibindo pagamentos por{' '}
          <strong style={{ color: 'var(--secondary)' }}>empresa</strong> de
          <strong style={{ color: 'var(--primary)' }}>
            {` ${monthText} de ${yearText}`}
          </strong>
          .
        </Text>
      </Row>
    );
  };

  return (
    <Div width="70vw" height="75vh" style={{ overflowY: 'scroll' }}>
      <Row justifyContent="center">
        <Column width="110px">
          <SubTitle>Mês</SubTitle>
          <InputSelect
            type="select"
            options={monthsArr}
            onChange={e => {
              setMonth(Number(e.target.value));
            }}
          />
        </Column>
        <Column width="110px" margin="0 0 0 20px">
          <SubTitle>Ano</SubTitle>
          <InputSelect
            type="select"
            options={yearsArr}
            onChange={e => {
              setYear(Number(e.target.value));
            }}
          />
        </Column>
      </Row>
      {renderHeader()}
      {!loadingInfo &&
        companies.map(company => (
          <Company key={company.id} company={company} hospitals={hospitals} />
        ))}
    </Div>
  );
}
