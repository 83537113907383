import styled from 'styled-components';

export const P = styled.p`
  font-size: ${props => props.size || '14px'};
  font-weight: ${props => props.weight || 'bold'};
  text-transform: ${props => (props.lowercase ? 'none' : 'uppercase')};
  color: ${props => props.color || 'var(--secondary)'};

  width: ${props => props.width || 'auto'};
  padding: ${props => props.padding || '0 0 5px 0'};
  margin: ${props => props.margin || '0'};
`;
