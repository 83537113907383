import React from 'react';
import { Flex } from '@chakra-ui/react';

import { SubTitle, Text } from '~/components/Kiwistrap/Page';

import { Container } from './styles';

export default function InfoSection({ icon: Icon, title, info }) {
  return (
    <Container>
      <Icon />
      <Flex flexDir="column">
        <SubTitle>{title}</SubTitle>
        <Text>{info}</Text>
      </Flex>
    </Container>
  );
}
