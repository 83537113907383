export default function queryParamsToURL(objQueryParams = {}) {
  let resultString = '';

  const keys = Object.keys(objQueryParams);

  if (!keys.length) {
    return '';
  }

  resultString += '?';
  keys.forEach(key => {
    resultString += `${key}=${objQueryParams[key]}&`;
  });

  return resultString.slice(0, resultString.length - 1);
}
