import React from 'react';
import PropTypes from 'prop-types';

import { Div, SubTitle, Text } from '~/components/Kiwistrap/Page';

import { Container } from './styles';

export default function InfoSection({ icon: Icon, title, info }) {
  return (
    <Container>
      <Icon />
      <Div>
        <SubTitle>{title}</SubTitle>
        <Text>{info}</Text>
      </Div>
    </Container>
  );
}

InfoSection.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};
