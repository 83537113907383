import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  FaDesktop,
  FaUser,
  FaCalendarAlt,
  FaNotesMedical,
  FaFileMedicalAlt,
} from 'react-icons/fa';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Avatar,
  Text,
} from '@chakra-ui/react';

import api from '~/services/api';
import phoneMask from '~/utils/phoneMask';
import leaveHospital from '~/utils/leaveHospital';
import getFirstLastName from '~/utils/getFirstLastName';
import { getParam } from '~/utils/url';

import NavItem from './NavItem';
import IsAllowed from '~/components/UI/IsAllowed';

const AvatarUploadInput = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

export default function DrawerMenu({ isOpen, onClose, drawerButtonRef }) {
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(
    'https://verde-azul-imoveis.s3.amazonaws.com/b36f1999-aada-4f0b-9fe8-21bdf419fef7.png'
  );
  const [hospitalName, setHospitalName] = useState('');
  const [hospitalPhone, setHospitalPhone] = useState('');

  const inputFile = React.useRef(null);
  const { hospitalId } = getParam();

  useEffect(() => {
    api.get(`/companies/myhospitals/${hospitalId}`).then(res => {
      setHospitalName(res.data.name);
      setAvatarSrc(res.data.avatar);
      setHospitalPhone(phoneMask(res.data.phone1));
    });
  }, []);

  async function fileUpload() {
    const data = new FormData();
    data.append('file', avatarFile);

    const response = await api.post('/avatar/', data);

    return response.data[0].filename;
  }

  async function dataEff() {
    if (avatarFile) {
      const avatarName = await fileUpload();

      await api.patch('/companies/companyprofile', {
        avatar: avatarName,
      });

      window.location.reload();
    }
  }

  useEffect(() => {
    dataEff();
  }, [avatarFile]);

  return (
    <Drawer
      placement="right"
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={drawerButtonRef}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <AvatarUploadInput
              ref={inputFile}
              onChange={res => setAvatarFile(res.target.files[0])}
            />
            <Flex w="100%" flexDir="column" alignItems="center">
              <Flex
                onClick={() => inputFile.current.click()}
                w="150px"
                h="150px"
                mt={3}
                border="5px"
                borderStyle="solid"
                borderColor="var(--primary)"
                borderRadius="full"
              >
                <Avatar
                  w="100%"
                  h="100%"
                  p="3px"
                  backgroundColor="white"
                  name={getFirstLastName(hospitalName)}
                  src={avatarSrc}
                />
              </Flex>
              <Text
                mt={3}
                mb={2}
                fontSize="xl"
                fontWeight="bold"
                textAlign="center"
                color="gray.600"
              >
                {hospitalName}
              </Text>
              <Text
                fontSize="md"
                fontWeight="bold"
                textAlign="center"
                color="gray.500"
              >
                {hospitalPhone}
              </Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Flex w="100%" flexDir="column">
              <IsAllowed id={15}>
                <NavItem
                  closeDrawer={onClose}
                  href="/hospital/profile"
                  text="Perfil"
                  icon={FaUser}
                />
              </IsAllowed>
              <IsAllowed id={1}>
                <NavItem
                  closeDrawer={onClose}
                  href="/hospital/dashboard"
                  text="Dashboard"
                  icon={FaDesktop}
                />
              </IsAllowed>
              <IsAllowed id={2}>
                <NavItem
                  closeDrawer={onClose}
                  href="/hospital/mycontracts"
                  text="Meus Contratos"
                  icon={FaFileMedicalAlt}
                />
              </IsAllowed>
              <IsAllowed id={14}>
                <NavItem
                  closeDrawer={onClose}
                  href="/hospital/protocols"
                  text="Protocolos"
                  icon={FaNotesMedical}
                />
              </IsAllowed>
              <IsAllowed id={6}>
                <NavItem
                  closeDrawer={onClose}
                  href={`/hospital/vacancies/schedulegroup`}
                  text="Escalas"
                  icon={FaCalendarAlt}
                />
              </IsAllowed>
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Flex
              onClick={leaveHospital}
              as="button"
              w="148px"
              h="48px"
              px={2}
              alignItems="center"
              justifyContent="center"
              backgroundColor="var(--primary)"
              borderRadius="lg"
              _hover={{ backgroundColor: 'var(--hover-primary)' }}
            >
              <Text
                color="white"
                fontSize="12px"
                fontWeight="bold"
                textAlign="center"
                textTransform="uppercase"
              >
                Sair do hospital
              </Text>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
