import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  height: 280px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--background);

  /* @media (max-width: 375px) {
    margin: 50px 70px;
  } */
`;

export const SectionTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  /* border: 1px solid blue; */
`;

export const Title = styled.span`
  font-family: Montserrat;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: var(--primary);
  text-align: center;

  /* border: 1px solid red; */
`;

export const SectionDescription = styled.div`
  display: flex;
  /* align-items: center; */
  height: 100px;
  padding: 10px;
  margin-bottom: 5px;

  /* border: 1px solid red; */
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-gray);

  /* border: 1px solid yellow; */
`;

export const SectionBtn = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  /* border: 1px solid black; */
`;
