import React, { useRef, useCallback } from 'react';

import onAvatarLoadError from '~/utils/onAvatarLoadError';

import { Text } from '~/components/Kiwistrap/Page';
import { Modal } from '~/components/UI/Modal';

import HospitalProfileModal from './HospitalProfileModal';

import { Container } from './styles';

export default function Hospital({ hospital }) {
  const hospitalProfileModalRef = useRef(null);

  const openHospitalProfile = useCallback(() => {
    hospitalProfileModalRef.current.openModal();
  }, []);

  return (
    <>
      <Container onClick={openHospitalProfile}>
        <img
          src={hospital.avatar}
          alt={hospital.name}
          onError={onAvatarLoadError}
        />
        <Text>{hospital.name}</Text>
      </Container>
      <Modal
        title="Hospital"
        ref={hospitalProfileModalRef}
        component={HospitalProfileModal}
        hospital={hospital}
      />
    </>
  );
}
