import React, { useRef, useCallback } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { Button } from '~/components/ChakraComponents/';
import { Div, Row } from '~/components/Kiwistrap/Page';
import { Modal } from '~/components/UI/Modal';

import ModalInfo from '../ModalInfo';

import ConditionalTerm from './ConditionalTerm';

export default function ConfirmModal({ vacancy }) {
  const conditionalTermModalRef = useRef(null);

  const openConditionalTerm = useCallback(() => {
    conditionalTermModalRef.current.openModal();
  }, []);

  const closeConditionalTerm = useCallback(() => {
    toast.error(
      'Você precisa aceitar os termos de condições para realizar um plantão.'
    );

    conditionalTermModalRef.current.closeModal();
  }, []);

  const handleClick = async btn => {
    const { id, countJump } = vacancy;

    const url = `/doctors/myvacancies/${id}/${countJump}`;

    const request = { status: btn };

    try {
      await api.put(url, request);

      return setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      if (error.response) {
        if (error.response.data.error === 'concurrent vacancies') {
          return toast.error('Você já tem uma vaga confirmada neste horário.');
        }
      }

      return internalServerError();
    }
  };

  return (
    <Div width="400px">
      <ModalInfo vacancy={vacancy} />
      <Row justifyContent="space-between" margin="20px 0 0">
        <Button
          title="recusar"
          variant="danger"
          onClick={() => handleClick('refused')}
        />
        <Button
          title="aceitar"
          variant="success"
          onClick={openConditionalTerm}
        />
      </Row>
      <Modal
        title="Termos de Condições"
        ref={conditionalTermModalRef}
        component={ConditionalTerm}
        vacancy={vacancy}
        onRefuse={closeConditionalTerm}
        onAccept={() => handleClick('accepted')}
      />
    </Div>
  );
}
