import React from 'react';
import moment from 'moment';

import { Div, Row, Text } from '~/components/Kiwistrap/Page';

import ModalInfo from '../ModalInfo';

export default function CheckinModal({ vacancy }) {
  const calcDate = () => {
    const today = moment(new Date()).format('DD/MM/YYYY');
    const vacancyDate = moment(new Date(vacancy.startHour)).format(
      'DD/MM/YYYY'
    );

    if (today === vacancyDate) return 'hoje';

    return `dia ${vacancyDate}`;
  };

  const calcStartHour = () => {
    const formattedStart = moment(new Date(vacancy.startHour))
      .add(-15, 'minutes')
      .format('HH:mm');

    return formattedStart;
  };

  return (
    <Div width="400px">
      <ModalInfo vacancy={vacancy} />
      <Row justifyContent="center">
        <Text
          padding="20px 0 0"
          color="var(--red)"
          style={{ textAlign: 'center' }}
        >
          O check-in deste plantão só ficara disponível {calcDate()} às{' '}
          {calcStartHour()}h.
        </Text>
      </Row>
    </Div>
  );
}
