import React from 'react';
import { Col } from 'react-bootstrap';
import { Flex, Text } from '@chakra-ui/react';

export default function Card({ onClick, color, icon: Icon, text }) {
  return (
    <Col md>
      <Flex
        cursor="pointer"
        w="100%"
        h="150px"
        mt={3}
        alignItems="center"
        justifyContent="center"
        boxShadow="base"
        backgroundColor="white"
        color={`${color}.400`}
        fontWeight="semibold"
        _hover={{
          boxShadow: 'md',
          backgroundColor: `${color}.400`,
          color: 'white',
        }}
        onClick={onClick}
      >
        <Icon size={40} />
        <Text ml={4} fontSize="md">
          {text}
        </Text>
      </Flex>
    </Col>
  );
}
