import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import ReactCalendar from 'react-calendar';
import { Flex, Text } from '@chakra-ui/react';

import { Label } from '~/components/UI/Text';
import { Modal } from '~/components/UI/Modal';

const CalendarModal = ({
  date = moment(new Date()).format('YYYY-MM-DD'),
  setDate,
  onChange,
  closeModal,
}) => {
  const onClick = useCallback(calendarDate => {
    const formattedDate = moment(calendarDate).format('YYYY-MM-DD');

    setDate(formattedDate);
    onChange(formattedDate);
    closeModal();
  }, []);

  const getDate = useCallback(() => {
    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() + 1);

    return newDate;
  }, [date]);

  return (
    <Flex>
      <ReactCalendar value={getDate()} onChange={onClick} calendarType="US" />
    </Flex>
  );
};

export default function IDate({
  required,
  w = '100%',
  label = 'Data',
  onChange = () => {},
  initialDate,
  ...rest
}) {
  const [date, setDate] = useState(initialDate);

  const calendarModalRef = useRef(null);

  const openCalendar = useCallback(() => {
    calendarModalRef.current.openModal();
  }, []);

  useEffect(() => {
    setDate(initialDate);
  }, [initialDate]);

  return (
    <Flex w={w} flexDir="column" {...rest}>
      <Label>{required ? `${label} *` : label}</Label>
      <Flex
        w="100%"
        h="40px"
        p="5px"
        align="center"
        border="2px"
        borderStyle="solid"
        borderColor="var(--black)"
        borderRadius="10px"
        backgroundColor="var(--background-alt)"
        onClick={openCalendar}
      >
        <Text color={date ? 'black' : 'var(--light-gray)'}>
          {date
            ? date
                .split('-')
                .reverse()
                .join('/')
            : 'Selecione...'}
        </Text>
      </Flex>
      <Modal
        title={label}
        ref={calendarModalRef}
        component={CalendarModal}
        date={date}
        setDate={setDate}
        onChange={onChange}
      />
    </Flex>
  );
}
