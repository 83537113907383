import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';
import internalServerError from '~/utils/internalServerError';
import onlyNumberHandleChangeCep from '~/utils/cepMaskWithAscii';

import PageLayout from '~/pages/_layouts/doctor/PageLayout';

import { Button } from '~/components/ChakraComponents/';
import { Row, Column } from '~/components/Kiwistrap/Page';
import { FormTitle, Input } from '~/components/Kiwistrap/Inputs/';

export default function EditAccount() {
  const [inputs, setInputs] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);

  const fetchData = useCallback(() => {
    api.get('/doctors/myprofile').then(response => {
      const {
        data: {
          user: { phone1, phone2 },
          cep,
          city,
          uf,
          address,
          street,
          number,
          complement,
        },
      } = response;

      const initialState = {
        phone1,
        phone2,
        cep,
        city,
        uf,
        address,
        street,
        number,
        complement,
      };

      setInputs(initialState);
      setLoadingPage(false);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleChanges = e => {
    if (e.target.name === 'cep') {
      return setInputs({
        ...inputs,
        [e.target.name]: onlyNumberHandleChangeCep(e.target.value),
      });
    }
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const request = { ...inputs };

    const removeMask = v => v.replace(/\D/g, '');

    request.phone1 = removeMask(request.phone1);
    request.phone2 = removeMask(request.phone2);
    request.cep = removeMask(request.cep);

    api
      .patch('/doctors/myprofile', request)
      .then(() => {
        toast.success('Perfil editado com sucesso!');
        history.push('/medico/profile');
        setTimeout(() => window.location.reload(), 1500);
      })
      .catch(internalServerError);
  };

  return (
    <PageLayout loading={loadingPage}>
      {inputs && (
        <>
          <Row width="auto" margin="0 0 20px 0">
            <Column width="300px" margin="0 20px 0 0">
              <FormTitle title="Endereço" />
              <Input
                title="cep"
                required
                name="cep"
                value={onlyNumberHandleChangeCep(inputs.cep || '')}
                placeholder="Ex: 13097-123"
                onChange={handleChanges}
              />
              <Input
                title="rua"
                required
                name="street"
                value={inputs.street}
                placeholder="Ex: Rua Oliveira"
                onChange={handleChanges}
              />
              <Row>
                <Column margin="0 20px 0 0">
                  <Input
                    title="uf"
                    required
                    name="uf"
                    value={inputs.uf}
                    placeholder="Ex: RJ"
                    onChange={handleChanges}
                  />
                </Column>
                <Column>
                  <Input
                    title="cidade"
                    required
                    name="city"
                    value={inputs.city}
                    onChange={handleChanges}
                  />
                </Column>
              </Row>
              <Row>
                <Column margin="0 20px 0 0">
                  <Input
                    title="bairro"
                    required
                    name="address"
                    value={inputs.address}
                    onChange={handleChanges}
                  />
                </Column>
                <Column>
                  <Input
                    title="número"
                    required
                    type="number"
                    name="number"
                    value={inputs.number}
                    onChange={handleChanges}
                  />
                </Column>
              </Row>
              <Input
                title="complemento"
                name="complement"
                value={inputs.complement}
                placeholder="Ex: Próx. ao parque"
                onChange={handleChanges}
              />
            </Column>
            <Column width="300px">
              <FormTitle title="Dados" />
              <Input
                title="telefone 1"
                required
                name="phone1"
                value={inputs.phone1}
                useCleave
                options={{
                  phone: true,
                  phoneRegionCode: 'BR',
                }}
                placeholder="Ex: 11 99987 5542"
                onChange={handleChanges}
              />
              <Input
                title="telefone 2"
                name="phone2"
                value={inputs.phone2}
                useCleave
                options={{
                  phone: true,
                  phoneRegionCode: 'BR',
                }}
                placeholder="Ex: 11 99987 5542"
                onChange={handleChanges}
              />
            </Column>
          </Row>
          <Row justifyContent="space-around" width="640px">
            <Button title="cancelar" variant="secondary" to="/medico/profile" />
            <Button title="editar" variant="success" onClick={handleSubmit} />
          </Row>
        </>
      )}
    </PageLayout>
  );
}
