import styled from 'styled-components';

export const Card = styled.div`
  cursor: pointer;

  width: 100%;

  margin-top: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  border-radius: 4px;

  box-shadow: var(--shadow);

  background-color: ${props => props.color};
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 50%;

  object-fit: cover;
  flex-shrink: none;

  border: 2px solid var(--background);
  background-color: var(--background-alt);
`;

export const Name = styled.p`
  font-size: 18px;
  font-weight: 600;

  text-align: center;

  color: var(--background-alt);
`;

export const Specialty = styled.p`
  font-size: 12px;
  font-weight: 600;

  text-align: center;

  color: var(--background-alt);
`;

export const Sector = styled.p`
  font-size: 14px;
  font-weight: 600;

  text-align: center;
  text-overflow: ellipsis;

  overflow: hidden;
  white-space: nowrap;

  color: var(--dark-gray);
`;
