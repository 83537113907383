import React, { createContext, useCallback, useContext } from 'react';

import { toast } from 'react-toastify';
import api from '../../services/api';
import history from '../../services/history';

const MedicoContext = createContext();

const MedicoProvider = ({ children }) => {
  const addMedico = useCallback(
    async ({
      name,
      email,
      password,
      password_confirmed,
      phone1,
      crm,
      crmUf,
      specialty,
      cep,
      logradouro,
      bairro,
      number,
      complement,
      uf,
      localidade,
    }) => {
      try {
        if (password !== password_confirmed) {
          toast.error(
            'As senhas não conferem. Por gentileza, verifique e informe novamente!'
          );

          return false;
        }

        cep = cep.replace('-', '');

        const { data } = await api.post('doctors', {
          name,
          email,
          password,
          phone1,
          crm,
          crmUf,
          cep,
          city: localidade,
          uf,
          address: bairro,
          street: logradouro,
          number,
          complement,
          specialty,
        });

        if (data) {
          toast.success('Cadastro realizado com sucesso!');
          return true;
        }

        return false;
      } catch (error) {
        if (error.response.data.error === 'Email already exist.') {
          return toast.error('Esse e-mail já está sendo utilizado.');
        }

        if (error.response.data.error === 'CRM already exist.') {
          return toast.error('Esse CRM já está sendo utilizado.');
        }

        toast.error(
          'Serviço indisponível, por favor tente novamente mais tarde.'
        );

        history.push('/sou-medico');
        return false;
      }
    },
    []
  );

  return (
    <MedicoContext.Provider value={{ addMedico }}>
      {children}
    </MedicoContext.Provider>
  );
};

function useMedico() {
  const context = useContext(MedicoContext);

  if (!context) {
    throw new Error('useMedico must be used within an MedicoProvider');
  }
  return context;
}

export { MedicoProvider, useMedico };
