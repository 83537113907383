import React from 'react';
import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';

import MeuPlantaoLogo from '~/assets/logo/logo-Recuperado.png';
import childrenWithProps from '~/utils/childrenWithProps';

const Container = styled.div`
  display: none;

  @media print {
    /* @page {
      margin: 0;
      size: landscape;
    } */

    background-color: var(--background);
    visibility: visible;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    display: block;

    img {
      width: 100px;
    }
  }
`;

export default function Printable({ children, ...rest }) {
  return (
    <Container layout="landscape">
      <Flex p={2}>
        <img src={MeuPlantaoLogo} alt="meu-plantao"></img>
      </Flex>
      {children && childrenWithProps(children, { ...rest })}
    </Container>
  );
}
