import axios from 'axios';

// ! DON'T DELETE !
// 'https://maps.googleapis.com/maps/api/geocode/json?address=Via+do+conhecimento,km1,+Pato+Branco,PR&key=AIzaSyA2IyVETbKnDu2L1_yBpWfXbEfzQEeAdG4'

const API_KEY = 'AIzaSyA2IyVETbKnDu2L1_yBpWfXbEfzQEeAdG4';

export default async address => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`;

  const response = await axios.get(url);

  return response;
};
