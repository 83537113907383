import React from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { Button } from '~/components/ChakraComponents/';
import { Text, Title, Column, SubTitle } from '~/components/Kiwistrap/Page';

export default function CandidateModal({ vacancy }) {
  const renderTypeText = type => {
    if (type === 'weekly') return 'Plantão Semanal';
    if (type === 'biweekly') return 'Plantão Quinzenal';
    if (type === 'cover') return 'Vaga Adicional';
    if (type === 'exception') return 'Cobertura';
  };

  const handleConfirm = async type => {
    const url = '/doctors/candidatures';

    const request = {
      vacancy: vacancy.id,
      countJump: vacancy.countJump,
      type,
    };

    try {
      await api.post(url, request);

      return window.location.reload();
    } catch (e) {
      if (e.response) {
        if (e.response.data.error === 'concurrent vacancies') {
          return toast.error('Você já se candidatou neste horário.');
        }
      }

      return internalServerError();
    }
  };

  return (
    <Flex flexDir="column" w="500px">
      <Title size={22} center>
        {vacancy.candidate
          ? 'Você já se candidatou a essa vaga.'
          : 'Você deseja se candidatar a essa vaga?'}
      </Title>
      <Flex justify="space-around">
        <Column>
          <SubTitle>Horário Inícial</SubTitle>
          <Text>{`${moment(new Date(vacancy.startHour)).format(
            'HH:mm'
          )}h`}</Text>
        </Column>
        <Column>
          <SubTitle>Horário Final</SubTitle>
          <Text>{`${moment(new Date(vacancy.endHour)).format('HH:mm')}h`}</Text>
        </Column>
        <Column>
          <SubTitle>Valor</SubTitle>
          <Text>
            {vacancy.schedule.contract.value.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Text>
        </Column>
      </Flex>
      {!vacancy.candidate && (
        <>
          <Title center size={22}>
            Escolha o tipo de candidatura:
          </Title>
          <Flex justify="space-evenly">
            <Button
              title={`somente\n${moment(vacancy.startHour).format(
                'DD/MM/YYYY'
              )}`}
              variant="warning"
              onClick={() => handleConfirm('exception')}
            />
            <Button
              title={renderTypeText(vacancy.type)}
              variant="success"
              onClick={() => handleConfirm('default')}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
}
