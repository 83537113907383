import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import api from '~/services/api';
import history from '~/services/history';
import { getParam } from '~/utils/url';

import { Option, ChooseButton } from './styles';

export default function SpecialtiesBtn() {
  const { hospitalId } = getParam('hospitalId');
  const { specialtyId, scheduleType, vacancyDate } = useParams();

  const [specialties, setSpecialties] = useState([]);

  const getSpecialties = async () => {
    const res = await api.get(
      `/contractors/myhospitals/${hospitalId}/specialties`
    );

    const sortedSpecialties = res.data.data.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    setSpecialties(sortedSpecialties);
  };

  useEffect(() => {
    getSpecialties();
  }, []);

  const getParams = () => {
    let type = scheduleType;

    if (!type || type === 'undefined') {
      type = 'week';
    }

    let date = vacancyDate;

    if (!date || date === 'undefined') {
      date = moment(new Date()).format('YYYY-MM-DD');
    }

    return [type, date];
  };

  const navigateToSpecialty = id => {
    const [type, date] = getParams();

    history.push(
      `/contractorhospital/vacancies/specialty/${id}/${type}/${date}/?hospitalId=${hospitalId}`
    );
  };

  const navigateToGroup = () => {
    const { groupId } = localStorage;

    let url = `/contractorhospital/vacancies/group/?hospitalId=${hospitalId}`;

    if (groupId && groupId !== 'undefined') {
      const [type, date] = getParams();

      url = `/contractorhospital/vacancies/group/${groupId}/${type}/${date}/?hospitalId=${hospitalId}`;
    }

    history.push(url);
  };

  return (
    <Flex flexDir="column" px="4px">
      <Flex my="20px" flexDir="column" alignItems="center">
        <Option onClick={navigateToGroup}>Escalas</Option>
        <Option active>Especialidades</Option>
      </Flex>
      {specialties.map(specialty => (
        <ChooseButton
          key={specialty.id}
          backgroundColor={
            +specialtyId === specialty.id
              ? 'var(--secondary)'
              : 'var(--background)'
          }
          textColor={
            +specialtyId === specialty.id
              ? 'var(--background)'
              : 'var(--secondary)'
          }
          onClick={() => navigateToSpecialty(specialty.id)}
        >
          {specialty.name}
        </ChooseButton>
      ))}
    </Flex>
  );
}
