import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Flex, Text } from '@chakra-ui/react';

import convertBRL from '~/utils/convertBRL';

import options from './options';

export default function PaymentBars({ data }) {
  const [total, setTotal] = useState();
  const [graphData, setGraphData] = useState();

  useEffect(() => {
    let value = 0;

    const bars = [];
    const labels = [];

    data.forEach(month => {
      value += month.doctorsValue;

      bars.push(month.doctorsValue);
      labels.push(month.month);
    });

    // Final react-chartjs-2 object
    const graphDataObj = {
      datasets: [
        {
          data: bars,
          label: 'Valor',
          backgroundColor: '#2bcc6e',
        },
      ],
      labels,
    };

    setTotal(value);
    setGraphData(graphDataObj);
  }, []);

  return (
    <>
      <Text
        mb={3}
        textTransform="uppercase"
        fontSize="sm"
        fontWeight="bold"
        color="var(--primary)"
      >
        Gastos com médicos{total && ` total: ${convertBRL(total)}`}
      </Text>
      <Flex h="300px">
        <Bar data={graphData} options={options} />
      </Flex>
    </>
  );
}
