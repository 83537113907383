import React, { useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FaUserMd } from 'react-icons/fa';
import { Flex, Divider, Tooltip, Text, VStack } from '@chakra-ui/react';
import { AiFillWarning, AiFillClockCircle } from 'react-icons/ai';

import Stars from './Stars';

import DoctorHoursModal from '~/components/Dashboard/Modals/DoctorHoursModal';
import DoctorAlertsModal from '~/components/Dashboard/Modals/DoctorAlertsModal';
import DoctorProfileModal from '~/components/Dashboard/Modals/DoctorProfileModal';

import { Avatar } from '~/components/ChakraComponents/';
import { Modal } from '~/components/UI/Modal';

const SvgStyles = styled.div`
  width: 100%;

  svg {
    cursor: pointer;

    &:hover {
      fill: var(--primary);
    }
  }
`;

export default function DoctorCard({
  doctor,
  disableProfile = false,
  disableHours = false,
  disableAlerts = false,
  extensions,
}) {
  const doctorHoursModalRef = useRef(null);
  const doctorAlertsModalRef = useRef(null);
  const doctorProfileModalRef = useRef(null);

  const openDoctorHours = useCallback(() => {
    doctorHoursModalRef.current.openModal();
  }, []);

  const openDoctorAlerts = useCallback(() => {
    doctorAlertsModalRef.current.openModal();
  }, []);

  const openDoctorProfile = useCallback(() => {
    doctorProfileModalRef.current.openModal();
  }, []);

  const renderDoctorName = useCallback(() => {
    const formattedName = doctor.name.split(/(\s).+\s/).join('');

    return formattedName;
  }, []);

  return (
    <Flex w="100%" h="100%" justifyContent="center">
      <Flex
        w="240px"
        h="100%"
        p={3}
        flexDir="column"
        alignItems="center"
        boxShadow="base"
        backgroundColor="white"
        borderRadius="base"
        textAlign="center"
      >
        <Avatar
          w="100px"
          h="100px"
          mb={3}
          border="4px"
          name={renderDoctorName(doctor.name)}
          src={doctor.avatar}
        />
        <Text fontSize="md" fontWeight="bold" color="gray.600">
          {renderDoctorName(doctor.name)}
        </Text>
        <Text height="40px" fontWeight="600" color="gray.500">
          {doctor.doctor.specialty.name}
        </Text>
        <Flex w="100%" mt={3} justify="center">
          <Stars rating={doctor.doctor.rating} />
        </Flex>
        <SvgStyles>
          <Flex
            w="100%"
            mt={6}
            justifyContent="space-evenly"
            fontSize="3xl"
            color="gray.500"
          >
            {!disableProfile && (
              <Tooltip hasArrow label="Ver Perfil" bg="gray.600">
                <span>
                  <FaUserMd onClick={openDoctorProfile} />
                </span>
              </Tooltip>
            )}
            {!disableHours && (
              <Tooltip hasArrow label="Ver Horas" bg="gray.600">
                <span>
                  <AiFillClockCircle onClick={openDoctorHours} />
                </span>
              </Tooltip>
            )}{' '}
            {!disableAlerts && (
              <Tooltip hasArrow label="Ver Alertas" bg="gray.600">
                <span>
                  <AiFillWarning onClick={openDoctorAlerts} />
                </span>
              </Tooltip>
            )}
          </Flex>
        </SvgStyles>
        <VStack w="100%" mt={3} align="center">
          {extensions.map(extension => (
            <React.Fragment key={extension.title}>
              {extension.bodyFunc(doctor)}
            </React.Fragment>
          ))}
        </VStack>
        <Divider mt={3} mb={3} color="gray.400" />
        <Text fontWeight="600" color="gray.500">
          {`${doctor.doctor.crm}-${doctor.doctor.crmUf}`}
        </Text>
        <Modal
          title="Perfil"
          ref={doctorProfileModalRef}
          component={DoctorProfileModal}
          doctor={doctor}
        />
        <Modal
          title="Horas"
          ref={doctorHoursModalRef}
          component={DoctorHoursModal}
          doctorId={doctor.doctor.id}
        />
        <Modal
          title="Alertas"
          ref={doctorAlertsModalRef}
          component={DoctorAlertsModal}
          doctorId={doctor.doctor.id}
        />
      </Flex>
    </Flex>
  );
}
