import styled from 'styled-components';

export const Option = styled.span`
  cursor: pointer;

  font-size: 22px;
  font-weight: 600;

  text-align: center;

  color: ${props => (props.active ? 'var(--primary)' : 'var(--disabled)')};
`;

export const ChooseButton = styled.div`
  cursor: pointer;

  width: 100%;
  min-height: 50px;

  padding: 0 4px;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-radius: var(--br-sm);

  border: 2px solid var(--black);

  font-size: 12px;
  font-weight: 700;

  text-align: center;
  text-transform: uppercase;

  color: ${props => props.textColor};
  background: ${props => props.backgroundColor};
`;
