import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaInfoCircle,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Container,
  Title,
  ContentPrevious,
  TextPrevious,
  Form,
  ContentForm,
  NameInput,
  InputGroup,
  FormInput,
  SectionDadosPessoais,
  TitleDadosPessoais,
  SectionDadosProfissionais,
  TitleDadosProfissionais,
  SectionEndereco,
  TitleEndereco,
  SectionBtn,
  Footer,
  SectionUteis,
  TitleUteis,
  SectionList,
  ListAll,
  ListItem,
  SectionCopyright,
  TitleCopyright,
  SectionSociais,
  ContentInfo,
  TextInfo,
  ContentInfoMobile,
  FooterMobile,
  TitleSociais,
} from './styles';
import InputPrimary from '~/components/Site/Inputs/InputPrimary';
import BtnCadastrar from '~/components/Site/Buttons/BtnSecondary';
/** Hook */
import { useEmpresa } from '~/hooks/Site/EmpresaContext';
/** Services */

require('cleave.js/dist/addons/cleave-phone.br');

export default function ImCompany(props) {
  const [dados, setDados] = useState({});
  const [end, setEnd] = useState({});

  const { pathname } = props.location;

  const handleChanges = e => {
    setDados({ ...dados, [e.target.name]: e.target.value });
  };

  const onlyNumberHandleChange = e => {
    const formatter = e.target.value
      .replace(/\D/g, '')
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    const companyCnpjFormatted = !formatter[2]
      ? formatter[1]
      : formatter[1] +
        '.' +
        formatter[2] +
        '.' +
        formatter[3] +
        '/' +
        formatter[4] +
        (formatter[5] ? '-' + formatter[5] : '');

    setDados({ ...dados, companyCnpj: companyCnpjFormatted });
  };

  const maskCep = e => {
    let result = Array.from(e.target.value)
      .filter(char => char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57)
      .join('');

    result = result.slice(0, 8);

    if (result.length > 5) {
      result = result.substring(0, 5) + '-' + result.substring(5);
    }

    e.target.value = result;

    setDados({ ...dados, companyCep: result });
  };

  const HandleChangeCompanyNumber = e => {
    const result = Array.from(e.target.value)
      .filter(char => char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57)
      .join('');

    e.target.value = result;

    setDados({ ...dados, companyNumber: result });
  };

  const {
    name,
    email,
    password,
    password_confirmed,
    phone1,
    companyName,
    companySocialReason,
    companyCnpj,
    companyPhone1,
    companyEmail,
    companyCep,
    companyNumber,
    companyComplement,
  } = dados;

  const { logradouro, bairro, localidade, uf } = end;

  useEffect(() => {
    (async () => {
      if (!companyCep) return;
      if (companyCep.length !== 9) return;

      const url = `https://viacep.com.br/ws/${companyCep || ''}/json/`;

      const response = await axios.get(url);

      setEnd(response.data);
    })();
  }, [companyCep]);

  const { addEmpresa } = useEmpresa();

  const handleSubmit = async e => {
    e.preventDefault();

    addEmpresa({
      name,
      email,
      password,
      password_confirmed,
      phone1,
      companyName,
      companySocialReason,
      companyCnpj,
      companyPhone1,
      companyEmail,
      companyCep,
      logradouro,
      companyNumber,
      bairro,
      companyComplement,
      uf,
      localidade,
    });
  };

  return (
    <Container>
      <Title>
        <h1>Empresa</h1>
      </Title>
      <ContentPrevious>
        <TextPrevious to="/cadastro">Voltar</TextPrevious>
      </ContentPrevious>
      <Form onSubmit={handleSubmit}>
        <ContentForm>
          <SectionDadosPessoais>
            <TitleDadosPessoais>Dados Pessoais</TitleDadosPessoais>
            <NameInput>NOME</NameInput>
            <InputPrimary
              name="name"
              type="text"
              value={dados.name}
              placeholder="EX: Empresa LTDA"
              onChange={handleChanges}
              required
            />
            <NameInput>EMAIL</NameInput>
            <InputPrimary
              name="email"
              type="email"
              value={dados.email}
              placeholder="EX: entrar@plantao.com.br"
              onChange={handleChanges}
              required
            />
            <NameInput>SENHA</NameInput>
            <InputPrimary
              name="password"
              type="password"
              value={dados.password}
              placeholder="EX: ***"
              onChange={handleChanges}
              required
            />
            <NameInput>CONFIRMAÇÃO DE SENHA</NameInput>
            <InputPrimary
              name="password_confirmed"
              type="password"
              value={dados.password_confirmed}
              placeholder="EX: ***"
              onChange={handleChanges}
              required
            />
            <NameInput>TELEFONE</NameInput>
            <InputPrimary
              name="phone1"
              type="text"
              value={dados.phone1}
              placeholder="EX: (00) 00000-0000"
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              onChange={handleChanges}
              required
            />
          </SectionDadosPessoais>
          <SectionDadosProfissionais>
            <TitleDadosProfissionais>
              Dados Administrativos
            </TitleDadosProfissionais>
            <NameInput>NOME EMPRESA</NameInput>
            <InputPrimary
              name="companyName"
              type="text"
              value={dados.companyName}
              placeholder="EX: Consult DOC EIRELI"
              onChange={handleChanges}
              required
            />
            <NameInput>RAZÃO SOCIAL</NameInput>
            <InputPrimary
              name="companySocialReason"
              type="text"
              value={dados.companySocialReason}
              placeholder="EX: Consult DOC EIRELI - ME"
              onChange={handleChanges}
              required
            />
            <NameInput>CNPJ</NameInput>
            {dados && (
              <InputPrimary
                name="companyCnpj"
                type="text"
                value={dados.companyCnpj}
                placeholder="EX: 00.000.000/0000-00"
                onChange={onlyNumberHandleChange}
                required
              />
            )}
            <NameInput>TELEFONE</NameInput>
            <InputPrimary
              name="companyPhone1"
              type="text"
              value={dados.companyPhone1}
              placeholder="EX: (00) 00000-0000"
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              onChange={handleChanges}
              required
            />
            <NameInput>EMAIL</NameInput>
            <InputPrimary
              name="companyEmail"
              type="email"
              value={dados.companyEmail}
              placeholder="EX: entrar@plantao.com.br"
              onChange={handleChanges}
              required
            />
          </SectionDadosProfissionais>
          <SectionEndereco>
            <TitleEndereco>Endereço</TitleEndereco>

            <NameInput>CEP</NameInput>
            <InputPrimary
              name="companyCep"
              type="text"
              value={dados.companyCep}
              placeholder="EX: 22273-098"
              // useCleave
              // options={{
              //   delimiters: ['-'],
              //   blocks: [5, 3],
              //   uppercase: true,
              // }}
              onChange={maskCep}
              required
            />

            <NameInput>RUA / AVENIDA / TRAVESSA</NameInput>
            <InputPrimary
              name="street"
              type="text"
              value={logradouro || ''}
              placeholder="EX: Rua martins oliveira"
              onChange={handleChanges}
              required
            />
            <InputGroup>
              <FormInput>
                <NameInput>NÚMERO</NameInput>
                <InputPrimary
                  name="companyNumber"
                  type="text"
                  value={dados.companyNumber}
                  placeholder="EX: 432"
                  onChange={handleChanges}
                  required
                />
              </FormInput>
              <FormInput>
                <NameInput>BAIRRO</NameInput>
                <InputPrimary
                  name="address"
                  type="text"
                  value={bairro || ''}
                  placeholder="EX: Jardim das Flores"
                  onChange={handleChanges}
                  required
                />
              </FormInput>
            </InputGroup>
            <NameInput>COMPLEMENTO</NameInput>
            <InputPrimary
              name="companyComplement"
              type="text"
              value={dados.companyComplement}
              placeholder="EX: Próx. ao parque"
              onChange={handleChanges}
            />
            <InputGroup>
              <FormInput>
                <NameInput>UF</NameInput>
                <InputPrimary
                  name="uf"
                  type="text"
                  value={uf || ''}
                  placeholder="UF"
                  onChange={handleChanges}
                  required
                />
              </FormInput>
              <FormInput>
                <NameInput>CIDADE</NameInput>
                <InputPrimary
                  name="city"
                  type="text"
                  value={localidade || ''}
                  placeholder="EX: Belo Horizonte"
                  onChange={handleChanges}
                  required
                />
              </FormInput>
            </InputGroup>
          </SectionEndereco>
        </ContentForm>
        <ContentInfo>
          <FaInfoCircle size={20} color="#2db7f8" />
          <TextInfo>
            O e-mail informado em "Dados Pessoais" será utilizado para realizar
            o acesso a nossa Plataforma e o e-mail em "Dados Administrativos"
            será utilizado para contato.
          </TextInfo>
        </ContentInfo>
        <ContentInfoMobile>
          {/* <FaInfoCircle size={50} color="#2db7f8" /> */}
          <TextInfo>
            O e-mail informado em "Dados Pessoais" será utilizado para realizar
            o acesso a nossa Plataforma e o e-mail em "Dados Administrativos"
            será utilizado para contato.
          </TextInfo>
        </ContentInfoMobile>
        <SectionBtn>
          <BtnCadastrar
            title="CONCLUIR"
            type="submit"
            styles={{ width: '700px' }}
          />
        </SectionBtn>
      </Form>

      <Footer>
        <SectionUteis>
          <SectionList>
            <TitleUteis>Links Úteis</TitleUteis>
            <ListAll>
              <ListItem>
                <a href={pathname === '/sou-empresa' ? '/#home' : ''}>Home</a>
              </ListItem>
              <ListItem>
                <a href={pathname === '/sou-empresa' ? '/#produto' : ''}>
                  Produto
                </a>
              </ListItem>
              <ListItem>
                <a href={pathname === '/sou-empresa' ? '/#contato' : ''}>
                  Contato
                </a>
              </ListItem>
              <ListItem>
                <Link to="/contato">Fale Conosco</Link>
              </ListItem>
            </ListAll>
          </SectionList>
          <SectionCopyright>
            <TitleCopyright>
              2020 - Meu Plantão - Todos os direitos reservados
            </TitleCopyright>
          </SectionCopyright>
          <SectionSociais>
            <a href="https://www.facebook.com/MeuPlantao" target="_blank">
              <FaFacebook
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a href="https://www.instagram.com/meuplantao/" target="_blank">
              <FaInstagram
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a href="#" target="_blank">
              <FaWhatsapp size={30} color="var(--background-alt)" />
            </a>
          </SectionSociais>
        </SectionUteis>
      </Footer>
      <FooterMobile>
        <TitleSociais>Siga-nos nas redes sociais</TitleSociais>
        <SectionSociais>
          <a href="https://www.facebook.com/MeuPlantao" target="_blank">
            <FaFacebook
              size={30}
              color="var(--background-alt)"
              style={{ marginRight: 40 }}
            />
          </a>
          <a href="https://www.instagram.com/meuplantao/" target="_blank">
            <FaInstagram
              size={30}
              color="var(--background-alt)"
              style={{ marginRight: 40 }}
            />
          </a>
          <a href="#" target="_blank">
            <FaWhatsapp size={30} color="var(--background-alt)" />
          </a>
        </SectionSociais>
      </FooterMobile>
    </Container>
  );
}
