import React, { useCallback } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import history from '~/services/history';
import { getUser } from '~/services/auth';

import IsAllowed from '~/components/UI/IsAllowed';

export default function NavCard({
  permissionId,
  icon: Icon,
  title,
  color,
  href,
}) {
  const user = getUser();

  const navigate = useCallback(() => {
    history.push(href);
  }, [href]);

  const renderCard = () => (
    <Flex
      onClick={navigate}
      w="100%"
      h={['120px', '120px', '120px', '180px']}
      mt={4}
      p="0.5rem 1rem"
      align="center"
      justify={['center', 'center', 'flex-end', 'flex-end']}
      flexDir={['row', 'row', 'column', 'column']}
      boxShadow="base"
      backgroundColor="white"
      color={color}
      cursor="pointer"
      _hover={{ boxShadow: 'md', color: 'white', backgroundColor: color }}
    >
      <Icon size={50} />
      <Flex
        h="40px"
        mt={[0, 0, 2, 8]}
        ml={[8, 8, 0, 0]}
        align="center"
        justify="center"
      >
        <Text
          fontSize={['16px', '16px', '12px', '18px']}
          fontWeight="semibold"
          textAlign="center"
        >
          {title}
        </Text>
      </Flex>
    </Flex>
  );

  if (user.type === 'contractor') {
    return renderCard();
  }

  return <IsAllowed id={permissionId}>{renderCard()}</IsAllowed>;
}
