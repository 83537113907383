import { toast } from 'react-toastify';

import api from '~/services/api';
import capitalizeString from '~/utils/capitalizeString';
import internalServerError from '~/utils/internalServerError';

export const sendRequest = (url, requestType, toastMessage, states) => {
  const [name, users, hospitals] = states;

  const request = {
    name: capitalizeString(name),
    usersContractors: [],
    hospitals: [],
  };

  users.forEach(user => {
    if (user.isChoosen)
      request.usersContractors.push(user.usercontractor[0].id);
  });

  hospitals.forEach(hospital => {
    if (hospital.isChoosen) request.hospitals.push(hospital.id);
  });

  api[requestType](url, request)
    .then(() => {
      toast.success(toastMessage);
      setTimeout(() => window.location.reload(), 1200);
    })
    .catch(internalServerError);
};
