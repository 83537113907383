import React, { useState, useRef, useCallback, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { BsClock } from 'react-icons/bs';
import { FiAlertTriangle } from 'react-icons/fi';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import ChooseDateModal from './ChooseDateModal';

import ProgressModal from '~/components/Dashboard/Modals/ProgressModal';

import { Button } from '~/components/ChakraComponents/';
import { Div, Title } from '~/components/Kiwistrap/Page';
import { Modal } from '~/components/UI/Modal';

import AlertModal from '~/components/Dashboard/Modals/DoctorAlertsModal';
import HoursModal from '~/components/Dashboard/Modals/DoctorHoursModal';
import DoctorProfileModal from '~/components/Dashboard/Modals/DoctorProfileModal';

import { Table, Tr, Td, DoctorAvatarContainer, DoctorAvatar } from './styles';

export default function CandidaturesModal({ vacancy }) {
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [clickedCandidature, setClickedCandidature] = useState();
  const [candidatures, setCandidatures] = useState();
  const [profileDoctor, setProfileDoctor] = useState();

  const { hospitalId } = getParam('hospitalId');

  const alertModalRef = useRef(null);
  const hoursModalRef = useRef(null);
  const profileModalRef = useRef(null);
  const chooseDateModalRef = useRef(null);

  const openAlert = useCallback(() => {
    alertModalRef.current.openModal();
  }, []);

  const openHours = useCallback(() => {
    hoursModalRef.current.openModal();
  }, []);

  const openProfile = useCallback(() => {
    profileModalRef.current.openModal();
  }, []);

  const openChooseDate = useCallback(() => {
    chooseDateModalRef.current.openModal();
  }, []);

  const closeChooseDate = useCallback(() => {
    chooseDateModalRef.current.closeModal();
  }, []);

  useEffect(() => {
    const url = `/companies/myhospitals/${hospitalId}/schedules/${vacancy.schedule.id}/vacancies/${vacancy.id}/${vacancy.countJump}/candidatures`;

    api
      .get(url)
      .then(response => {
        setCandidatures(response.data.data);
      })
      .catch(internalServerError);
  }, []);

  const handleAdd = (candidature, endDate) => {
    setLoading(true);

    const { id } = candidature;

    const url = `/companies/myhospitals/${hospitalId}/schedules/${vacancy.schedule.id}/vacancies/${vacancy.id}/${vacancy.countJump}/candidatures/${id}/accept`;

    const request = {};

    if (endDate) {
      request.toDate = endDate;
    } else {
      request.isToEndSchedule = true;
    }

    api
      .post(url, request)
      .then(() => {
        toast.success('Candidato aceito com sucesso!');
        setTimeout(() => window.location.reload(), 1500);
      })
      .catch(internalServerError);

    setLoading(false);
  };

  const handleAlerts = id => {
    const url = `/companies/mydoctors/${id}/alerts`;

    api
      .get(url)
      .then(response => {
        setAlerts(response.data.data);
        openAlert();
      })
      .catch(internalServerError);
  };

  const handleHours = id => {
    const dateArr = moment(new Date(vacancy.startHour))
      .format('YYYY-MM-DD')
      .split('-');

    const url = `/companies/doctors/${id}/hours?month=${dateArr[1]}&year=${dateArr[0]}`;

    api
      .get(url)
      .then(response => {
        setHours(response.data.total);
        openHours();
      })
      .catch(internalServerError);
  };

  return (
    <Div height="80vh" style={{ overflowY: 'auto' }}>
      {candidatures?.length === 0 ? (
        <Title center margin="35vh 0 0" padding="0 20px">
          Não há candidatos para essa vaga
        </Title>
      ) : (
        <Table>
          <tbody>
            {candidatures?.map?.(candidature => (
              <Tr key={candidature.id}>
                <Td>
                  <DoctorAvatarContainer>
                    <DoctorAvatar
                      src={candidature.companyDoctor.doctor.user.avatar}
                    />
                  </DoctorAvatarContainer>
                </Td>
                <Td>{candidature.companyDoctor.doctor.user.name}</Td>
                <Td>{`${candidature.companyDoctor.doctor.crm}-${candidature.companyDoctor.doctor.crmUf}`}</Td>
                <Td>{candidature.companyDoctor.doctor.specialty.name}</Td>
                <Td>
                  <BsClock
                    size={28}
                    onClick={() =>
                      handleHours(candidature.companyDoctor.doctor.id)
                    }
                  />
                </Td>
                <Td>
                  <FiAlertTriangle
                    size={28}
                    onClick={() =>
                      handleAlerts(candidature.companyDoctor.doctor.id)
                    }
                  />
                </Td>
                <Td>
                  <Button
                    variant="secondary"
                    width="auto"
                    height="40px"
                    title="perfil"
                    onClick={() => {
                      setProfileDoctor({
                        ...candidature.companyDoctor.doctor.user,
                        doctor: candidature.companyDoctor.doctor,
                      });
                      openProfile();
                    }}
                  />
                </Td>
                <Td>
                  {candidature.type === 'exception' && (
                    <Button
                      variant="warning"
                      width="auto"
                      height="40px"
                      title={`somente ${moment(
                        new Date(vacancy.startHour)
                      ).format('DD/MM/YYYY')}`}
                      onClick={() => handleAdd(candidature)}
                    />
                  )}
                  {candidature.type === 'weekly' && (
                    <Button
                      width="auto"
                      height="40px"
                      title="plantão semanal"
                      onClick={() => {
                        setClickedCandidature(candidature);
                        openChooseDate();
                      }}
                    />
                  )}
                  {candidature.type === 'biweekly' && (
                    <Button
                      width="auto"
                      height="40px"
                      title="plantão quinzenal"
                      onClick={() => {
                        setClickedCandidature(candidature);
                        openChooseDate();
                      }}
                    />
                  )}
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      )}
      <Modal
        title="Alertas"
        ref={alertModalRef}
        component={AlertModal}
        data={alerts}
      />
      <Modal
        title="Horas"
        ref={hoursModalRef}
        component={HoursModal}
        data={hours}
      />
      <Modal
        title="Perfil"
        ref={profileModalRef}
        component={DoctorProfileModal}
        doctor={profileDoctor}
      />
      <Modal
        title="Escolher Data"
        ref={chooseDateModalRef}
        component={ChooseDateModal}
        candidature={clickedCandidature}
        handleAdd={handleAdd}
        onClose={closeChooseDate}
      />
      <ProgressModal
        loading={loading}
        text="Editando plantões... Por favor aguarde, isso pode demorar alguns minutos."
      />
    </Div>
  );
}
