import React, { useState, useRef, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AiOutlinePlus } from 'react-icons/ai';
import { Flex, SimpleGrid } from '@chakra-ui/react';

import api from '~/services/api';
import alphabet from '~/constants/alphabet';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import IsAllowed from '~/components/UI/IsAllowed';
import PageLayout from '~/pages/_layouts/hospital/PageLayout';
import { Title } from '~/components/Kiwistrap/Page';
import { Modal } from '~/components/UI/Modal';
import { Button, Alert } from '~/components/ChakraComponents/';

import CreateProtocolModal from './CreateProtocolModal';

import { ItemList, Letter, NameLink, TrashSvg, DownloadSvg } from './styles';

export default function Protocols() {
  const [protocols, setProtocols] = useState([]);
  const [confirmDel, setConfirmDel] = useState(false);
  const [currentLetter, setCurrentLetter] = useState('');
  const [protocolToDelete, setProtocolToDelete] = useState();

  const { hospitalId } = getParam('hospitalId');

  const createProtocolModalRef = useRef(null);

  const openCreateProtocol = useCallback(() => {
    createProtocolModalRef.current.openModal();
  }, []);

  const fetchData = () => {
    const url = `/companies/myhospitals/${hospitalId}/protocols?limit=8&startName=${currentLetter}`;

    api.get(url).then(response => setProtocols(response.data.data));
  };

  useEffect(() => {
    fetchData();
  }, [currentLetter]);

  const searchByLetter = letter => {
    if (letter === currentLetter) {
      return setCurrentLetter('');
    }

    return setCurrentLetter(letter);
  };

  const deleteProtocol = () => {
    const url = `/companies/myhospitals/${hospitalId}/protocols/${protocolToDelete}`;

    api
      .delete(url)
      .then(() => {
        toast.success('Protocolo excluído com sucesso!');
        fetchData();
      })
      .catch(internalServerError);
  };

  return (
    <PageLayout centerTitle title="Protocolos">
      <Flex flexDir="column" alignItems="center">
        <IsAllowed id={15}>
          <Button
            title="Novo Protocolo"
            width="180px"
            icon2={<AiOutlinePlus size={24} />}
            onClick={openCreateProtocol}
          />
        </IsAllowed>
        <SimpleGrid
          w="100%"
          maxW="1100px"
          my={4}
          minChildWidth="10px"
          spacing={1}
        >
          {alphabet.map(letter => (
            <Letter
              key={letter}
              isChoosen={currentLetter === letter}
              onClick={() => searchByLetter(letter)}
            >
              {letter}
            </Letter>
          ))}
        </SimpleGrid>
      </Flex>
      {protocols.length <= 0 && <Title center> Não há nenhum protocolo!</Title>}
      <Flex
        width="100%"
        px="10px"
        flexDir="column"
        boxShadow="var(--shadow)"
        backgroundColor="var(--background-alt)"
      >
        {protocols.map(protocol => (
          <ItemList key={protocol.id}>
            <div className="item-details">
              <span>
                <NameLink
                  href={`https://verde-azul-imoveis.s3.amazonaws.com/${protocol.bucketName}`}
                >
                  {protocol.name}:
                </NameLink>
              </span>
              <span>{protocol.description}</span>
            </div>
            <Flex>
              <a href={protocol.fileUrl} target="_blank">
                <DownloadSvg />
              </a>
              <IsAllowed id={17}>
                <TrashSvg
                  onClick={() => {
                    setConfirmDel(true);
                    setProtocolToDelete(protocol.id);
                  }}
                />
              </IsAllowed>
            </Flex>
          </ItemList>
        ))}
      </Flex>
      <Modal
        title="Criar Protocolo"
        ref={createProtocolModalRef}
        component={CreateProtocolModal}
      />
      <Alert
        isOpen={confirmDel}
        onClose={() => setConfirmDel(false)}
        onConfirm={() => {
          setConfirmDel(false);
          deleteProtocol();
        }}
        confirmTitle="excluír"
        confirmVariant="danger"
        header="Excluír Protocolo"
        body="Essa ação não poderá ser desfeita!"
      />
    </PageLayout>
  );
}
