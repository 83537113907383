import styled from 'styled-components';

export const UploadAvatarBtn = styled.div`
  margin-top: 28px;

  input[type='file'] {
    display: none;
  }

  label {
    cursor: pointer;
    text-transform: uppercase;
    color: var(--secondary);
    font-size: 15px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-left: 25px;
    }
  }
`;
