import styled from 'styled-components';

export const Agenda = styled.div`
  width: 100%;
  max-width: 1000px;

  display: flex;
  flex-direction: column;

  strong {
    color: var(--primary);
  }
`;

export const AgendaRow = styled.div`
  width: 100%;

  margin-top: 10px;

  display: flex;
  justify-content: flex-start;

  height: ${props => props.height};
`;

export const Day = styled.div`
  width: 140px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.65;

  border-radius: 4px;

  box-shadow: var(--shadow);
  border-top: 13px solid var(--primary);
  background-color: var(--background-alt);

  color: var(--primary);
`;

export const Vacancies = styled.div`
  width: 100%;

  margin-left: 20px;

  display: grid;
  grid-gap: 5px;
  grid-template-rows: repeat(auto-fill, 70px);
`;
