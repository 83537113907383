import styled from 'styled-components';

export const CandidaturesList = styled.div`
  width: 1000px;
  height: 70vh;

  padding-right: 20px;

  overflow-y: scroll;
`;

export const Candidature = styled.div`
  width: 100%;

  padding: 14px 0;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #e7e7e7;

  &:last-child {
    border-bottom: none;
  }
`;

export const HospitalAvatar = styled.div`
  position: relative;

  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  ::before {
    content: '';

    width: 70px;
    height: 70px;

    border-radius: 25px;
    border: 4px solid var(--primary);

    background: ${props => props.backgroundImage};
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  ::after {
    content: '';

    width: 18px;
    height: 18px;

    position: absolute;
    top: -3px;
    right: -3px;
    z-index: 10;

    background-color: ${props => props.statusColor};

    border-radius: 50%;
    border: 3px solid var(--background-alt);
  }
`;

export const HospitalName = styled.span`
  margin-right: 20px;

  font-size: 24px;
  font-weight: bold;

  color: var(--primary);
`;
