import React from 'react';
import { Text } from '@chakra-ui/react';

export default function Label({
  color = 'var(--primary)',
  fontWeight = 'bold',
  textTransform = 'uppercase',
  children,
  ...rest
}) {
  return (
    <Text
      {...rest}
      color={color}
      fontWeight={fontWeight}
      textTransform={textTransform}
    >
      {children}
    </Text>
  );
}
