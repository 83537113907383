import React, { useState, useEffect } from 'react';
import moment from 'moment';

import api from '~/services/api';
import monthNames from '~/constants/monthNames';

import { Button } from '~/components/ChakraComponents/';
import { Div, Text, Row } from '~/components/Kiwistrap/Page';

export default function ConditionalTerm({ vacancy, onAccept, onRefuse }) {
  const [medicInfo, setMedicInfo] = useState({});
  const [date, setDate] = useState('');

  useEffect(() => {
    (async () => {
      const {
        data: { user, crm },
      } = await api.get('/doctors/myprofile');

      setMedicInfo({ ...user, crm });

      const dateVac = moment(new Date(vacancy.startHour))
        .format('YYYY-MM-DD')
        .split('-')
        .reverse();

      setDate(
        `${dateVac[0]} de ${monthNames[dateVac[1] - 1]} de ${dateVac[2]}`
      );
    })();
  }, []);

  return (
    <Div width="500px">
      <Text size="16px">
        Eu, médico <b>{medicInfo.name}</b>, crm <b>{medicInfo.crm}</b>, declaro
        que assumo o plantão do dia {date} de{' '}
        {moment(new Date(vacancy.startHour)).format('HH:mm')}h a{' '}
        {moment(new Date(vacancy.endHour)).format('HH:mm')}h, e deste modo todas
        as responsabilidades contidas no código de ética médico
        concomitantemente ao código civil e penal.
      </Text>
      <Text size="16px" margin="20px 0">
        Em caso de impossibilidade de fazer o plantão, me responsabilizo
        a: Comunicar ao responsável pela escala e ao diretor tecnico, em tempo
        hábil, para que estes possam buscar outro profissional.  Me comprometo
        ainda, a fazer o possível para <b>INDICAR OUTRO PROFISSIONAL</b>, com as
        qualificações técnicas condizente à vaga por mim assumida.
      </Text>
      <Row justifyContent="space-between" alignItems="center">
        <Button title="recusar termos" variant="danger" onClick={onRefuse} />
        <Button title="aceitar termos" variant="success" onClick={onAccept} />
      </Row>
    </Div>
  );
}
