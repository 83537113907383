import React, { useState } from 'react';
import styled from 'styled-components';
import { BsGraphUp } from 'react-icons/bs';
import {
  FaDesktop,
  FaUserCog,
  FaUsers,
  FaUserMd,
  FaHospitalAlt,
} from 'react-icons/fa';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Avatar,
  Text,
} from '@chakra-ui/react';

import api from '~/services/api';
import getFirstLastName from '~/utils/getFirstLastName';

import { useAuth } from '~/hooks/AuthContext';

import NavItem from './NavItem';

import IsAllowed from '~/components/UI/IsAllowed';

const AvatarUploadInput = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

export default function DrawerMenu({ isOpen, onClose, drawerButtonRef }) {
  const { signOut } = useAuth();

  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(
    'https://verde-azul-imoveis.s3.amazonaws.com/b36f1999-aada-4f0b-9fe8-21bdf419fef7.png'
  );
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');

  const inputFile = React.useRef(null);

  React.useEffect(() => {
    api.get('/companies/companyprofile').then(res => {
      setAvatarSrc(res.data.avatar);
      setCompanyName(res.data.name);
      setCompanyEmail(res.data.email);
    });
  }, []);

  async function fileUpload() {
    const data = new FormData();
    data.append('file', avatarFile);

    const response = await api.post('/avatar/', data);

    return response.data[0].filename;
  }

  async function dataEff() {
    if (avatarFile) {
      const avatarName = await fileUpload();

      await api.patch('/companies/companyprofile', {
        avatar: avatarName,
      });

      window.location.reload();
    }
  }

  React.useEffect(() => {
    dataEff();
  }, [avatarFile]);

  return (
    <Drawer
      placement="right"
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={drawerButtonRef}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <AvatarUploadInput
              ref={inputFile}
              onChange={res => setAvatarFile(res.target.files[0])}
            />
            <Flex w="100%" flexDir="column" alignItems="center">
              <Flex
                onClick={() => inputFile.current.click()}
                w="150px"
                h="150px"
                mt={3}
                border="5px"
                borderStyle="solid"
                borderColor="var(--primary)"
                borderRadius="full"
              >
                <Avatar
                  w="100%"
                  h="100%"
                  p="3px"
                  backgroundColor="white"
                  name={getFirstLastName(companyName)}
                  src={avatarSrc}
                />
              </Flex>
              <Text
                mt={3}
                mb={2}
                fontSize="xl"
                fontWeight="bold"
                textAlign="center"
                color="gray.600"
              >
                {companyName}
              </Text>
              <Text
                fontSize="md"
                fontWeight="bold"
                textAlign="center"
                color="gray.500"
              >
                {companyEmail}
              </Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Flex w="100%" flexDir="column">
              <IsAllowed id={15}>
                <NavItem
                  closeDrawer={onClose}
                  href="/company/profile"
                  text="Perfil"
                  icon={FaUserCog}
                />
              </IsAllowed>
              <IsAllowed id={1}>
                <NavItem
                  closeDrawer={onClose}
                  href="/company/dashboard"
                  text="Dashboard"
                  icon={FaDesktop}
                />
              </IsAllowed>
              <IsAllowed id={2}>
                <NavItem
                  closeDrawer={onClose}
                  href="/company/myhospitals"
                  text="Meus Hospitais"
                  icon={FaHospitalAlt}
                />
              </IsAllowed>
              <IsAllowed id={6}>
                <NavItem
                  closeDrawer={onClose}
                  href="/company/mydoctors"
                  text="Meus Médicos"
                  icon={FaUserMd}
                />
              </IsAllowed>
              <IsAllowed id={9}>
                <NavItem
                  closeDrawer={onClose}
                  href="/company/users"
                  text="Usuários"
                  icon={FaUsers}
                />
              </IsAllowed>
              <IsAllowed id={13}>
                <NavItem
                  closeDrawer={onClose}
                  href="/company/spendcontrol"
                  text="Controle de Gastos"
                  icon={BsGraphUp}
                />
              </IsAllowed>
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Flex
              onClick={signOut}
              as="button"
              w="148px"
              h="48px"
              px={2}
              alignItems="center"
              justifyContent="center"
              backgroundColor="var(--primary)"
              borderRadius="lg"
              _hover={{ backgroundColor: 'var(--hover-primary)' }}
            >
              <Text
                color="white"
                fontSize="12px"
                fontWeight="bold"
                textAlign="center"
                textTransform="uppercase"
              >
                Sair do site
              </Text>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
