import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
`;

export const PurpleBackground = styled.div`
  width: 100%;
  height: 215px;

  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  border-radius: 15px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  background: rgb(93, 107, 217);
  background: -moz-linear-gradient(
    144deg,
    rgba(93, 107, 217, 1) 45%,
    rgba(45, 111, 217, 1) 100%
  );
  background: -webkit-linear-gradient(
    144deg,
    rgba(93, 107, 217, 1) 45%,
    rgba(45, 111, 217, 1) 100%
  );
  background: linear-gradient(
    144deg,
    rgba(93, 107, 217, 1) 45%,
    rgba(45, 111, 217, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5d6bd9",endColorstr="#2d6fd9",GradientType=1);
`;

export const HospitalAvatar = styled.img`
  width: 140px;
  height: 140px;

  border-radius: 50%;

  object-fit: cover;

  border: 4px solid var(--secondary);
  background-color: var(--background-alt);
`;

export const HospitalName = styled.div`
  margin: 0 12px;

  font-size: 24px;
  font-weight: bold;
  text-align: center;

  color: var(--background-alt);
`;

export const InfoSection = styled.div`
  padding: 14px 0;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #e7e7e7;

  svg {
    margin-right: 20px;

    font-size: 50px;

    color: var(--primary);
  }
`;

export const Text = styled.p`
  font-weight: 500;

  color: var(--light-gray);
`;
