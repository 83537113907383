import React from 'react';
import moment from 'moment';

import api from '~/services/api';
import { keys, map } from '~/utils/lambda';

import { SunSvg, MoonSvg } from './styles';

export const getDates = (date, schedule) => {
  let startDate = moment(`${date} 00:03:00`).startOf(schedule);
  let endDate = moment(`${date} 00:03:00`).endOf(schedule);

  if (schedule === 'week') {
    startDate = startDate.add(1, 'days');
    endDate = endDate.add(1, 'days');
  }

  if (schedule === 'month') {
    startDate = startDate.startOf('week');
    endDate = endDate.endOf('week');
  }

  const formattedStart = startDate.format('YYYY-MM-DD');
  const formattedEnd = endDate.format('YYYY-MM-DD');

  return [formattedStart, formattedEnd];
};

export const fetchSchedules = (url, callback, options = {}) => {
  api.get(url).then(response => {
    const allVacancies = response.data;
    const dates = keys(allVacancies);

    const schedulesArr = [];

    map(allVacancies, vacanciesInDay => {
      vacanciesInDay
        .sort((a, b) => a.id - b.id)
        .forEach(vacancy =>
          // Generates schedules sections on the main agenda
          {
            const schedule = schedulesArr.find(
              sched => sched.id === vacancy.schedule.id
            );

            if (!schedule) {
              schedulesArr.push({ ...vacancy.schedule, dates: [] });
            }
          }
        );
    });

    // Generates the sub agenda for each schedule
    schedulesArr.forEach(schedule => {
      dates.forEach(day => {
        const vacancies = [];

        allVacancies[day].forEach(vacancy => {
          return vacancy.schedule.id === schedule.id && vacancies.push(vacancy);
        });

        schedule.dates.push({ day, vacancies });
      });
    });

    if (options.isGroup) {
      // Sort each schedule by schedule group order
      schedulesArr.sort((a, b) => a.order - b.order);
    } else {
      // Sort each schedule by hour, from earlier to later
      schedulesArr.sort((a, b) =>
        moment(new Date(`1996-09-13 ${a.contract.start}`)).diff(
          moment(new Date(`1996-09-13 ${b.contract.start}`))
        )
      );
    }

    callback(dates, schedulesArr);
  });
};

export const renderTimeSvg = startHour => {
  let component = <SunSvg />;

  const hourDate = moment(new Date(`1996-09-13 ${startHour}`));

  const startNightStart = moment(new Date(`1996-09-13 00:00:01`));
  const startNightEnd = moment(new Date(`1996-09-13 05:59:59`));

  const endNightStart = moment(new Date(`1996-09-13 17:59:59`));
  const endNightEnd = moment(new Date(`1996-09-13 23:59:59`));

  if (
    hourDate.isBetween(startNightStart, startNightEnd) ||
    hourDate.isBetween(endNightStart, endNightEnd)
  ) {
    component = <MoonSvg />;
  }

  return component;
};
