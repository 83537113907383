import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { Button } from '~/components/ChakraComponents/';
import { Text, Title } from '~/components/Kiwistrap/Page';

import {
  CandidaturesList,
  Candidature,
  HospitalAvatar,
  HospitalName,
} from './styles';

export default function CandidaturesModal() {
  const [candidatures, setCandidatures] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/doctors/candidatures');

        setCandidatures(response.data.data);
      } catch (e) {
        internalServerError();
      }
    })();
  }, []);

  const handleCancel = async candidatureId => {
    try {
      await api.delete(`/doctors/candidatures/${candidatureId}`);

      toast.success('Candidatura cancelada com sucesso!');

      setTimeout(() => window.location.reload(), 1000);
    } catch (e) {
      internalServerError();
    }
  };

  const formatDate = date => {
    return moment(new Date(date)).format('DD/MM/YYYY');
  };

  const renderStatusColor = status => {
    if (status === 'accepted') {
      return 'var(--green)';
    }

    if (status === 'pending') {
      return 'var(--yellow)';
    }

    return 'var(--red)';
  };

  return (
    <CandidaturesList>
      {candidatures && candidatures.length > 0 ? (
        candidatures.map(candidature => (
          <Candidature key={candidature.id}>
            <HospitalAvatar
              backgroundImage={`url(${candidature.hospital.avatar})`}
              statusColor={renderStatusColor(candidature.status)}
            />
            <Flex flexDir="column">
              <HospitalName>{`${formatDate(candidature.vacancy.startHour)} - ${
                candidature.hospital.name
              }`}</HospitalName>
              <Text>{`${candidature.hospital.uf} - ${candidature.hospital.city}, ${candidature.hospital.address}, ${candidature.hospital.street} ${candidature.hospital.number}`}</Text>
            </Flex>
            <Flex w="auto" flexDir="column">
              <Button
                title="cancelar"
                variant="danger"
                onClick={() => handleCancel(candidature.id)}
              />
            </Flex>
          </Candidature>
        ))
      ) : (
        <>
          <Title center color="var(--light-gray)" margin="30% 0 20px">
            Você não possui candidaturas.
          </Title>
          <Flex justify="center">
            <Button title="buscar plantão ?" to="/medico/findvacancy" />
          </Flex>
        </>
      )}
    </CandidaturesList>
  );
}
