import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

export default function IRadio({
  m = 0,
  mx = 0,
  my = 0,
  value,
  onChange,
  checked,
  label,
  ...rest
}) {
  return (
    <Flex
      cursor="pointer"
      m={m}
      mx={mx}
      my={my}
      alignItems="center"
      justifyContent="center"
    >
      <input
        {...rest}
        type="radio"
        checked={checked}
        onClick={onChange}
        style={{ cursor: 'pointer' }}
      />
      <Text
        ml="8px"
        userSelect="none"
        fontWeight="semibold"
        color={checked ? 'var(--blue)' : 'var(--light-gray)'}
        checked={checked}
        onClick={(params) => {
          if(!rest.disabled){
            onChange(params)
          }
        }}
      >
        {label}
      </Text>
    </Flex>
  );
}
