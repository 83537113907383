import api from '~/services/api';
import { getParam } from '~/utils/url';

export const fetchContracts = callback => {
  const { hospitalId } = getParam();

  const url = `/companies/myhospitals/${hospitalId}/contracts/partialinfo`;

  api.get(url).then(response => {
    const onlyActive = response.data.data.filter(contract => {
      return contract.schedule && contract.isActive;
    });

    const sortedData = onlyActive.sort((a, b) => {
      if (a.specialty.name < b.specialty.name) return -1;
      if (a.specialty.name > b.specialty.name) return 1;
      return 0;
    });

    callback(sortedData);
  });
};
