import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { getUser } from '~/services/auth';
import { ufOptions } from '~/constants/uf';

import PendingDoctorUrl from '~/components/UI/Sections/PendingDoctorUrl';
import { IText, ISelect } from '~/components/UI/Inputs';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider } from '~/components/ChakraComponents';

export default function CreateInviteButton() {
  const [hash, setHash] = useState();
  const [inputs, setInputs] = useState({
    pendingDoctorCrm: '',
    pendingDoctorCrmUf: getUser().uf,
  });
  const [loading, setLoading] = useState(false);

  const onChange = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    if (inputs.pendingDoctorCrm === '') {
      setLoading(false);
      return toast.error('Crm inválido.');
    }

    api.post('/companies/mydoctors/invitelink', inputs).then(response => {
      setHash(response.data.hash);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (loading) onSubmit();
  }, [loading]);

  return (
    <ModalContainer w={hash ? '370px' : '250px'} loading={loading}>
      {hash ? (
        <PendingDoctorUrl hash={hash} />
      ) : (
        <>
          <IText
            required
            placeholder="Ex: 12345"
            type="number"
            label="Crm"
            pattern="[0-9]*"
            name="pendingDoctorCrm"
            value={inputs.pendingDoctorCrm}
            onChange={onChange}
            maxLength="12"
          />
          <ISelect
            required
            label="CrmUf"
            name="pendingDoctorCrmUf"
            options={ufOptions}
            value={inputs.pendingDoctorCrmUf}
            onChange={onChange}
          />
          <Divider />
          <Button
            title="criar convite"
            width="100%"
            onClick={() => setLoading(true)}
          />
        </>
      )}
    </ModalContainer>
  );
}
