import ReactDOM from 'react-dom';

import React, {
  useState,
  useCallback,
  useImperativeHandle,
  useEffect,
  forwardRef,
} from 'react';

import { Flex } from '@chakra-ui/react';

import { Container, ModalContainer, Header, Title, CloseSvg } from './styles';

const Modal = (
  {
    component: Component,
    title,
    openModalCallback,
    closeModalCallback,
    ...rest
  },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [modalId, setModalId] = useState();

  const ESC = useCallback(e => {
    const { id } = document.getElementById('root').lastChild;

    if (e.keyCode === 27 && id === ref.current?.modalId) {
      ref.current.closeModal();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('blockKeyboard', 'false');

    document.addEventListener('keyup', ESC, false);

    setModalId(Math.random().toString(36));
  }, []);

  const openModal = useCallback(() => {
    const node = document.createElement('div');
    const root = document.getElementById('root');

    node.setAttribute('id', ref.current.modalId);
    root.appendChild(node);

    setOpen(true);

    if (openModalCallback) openModalCallback();
  }, []);

  const closeModal = useCallback(() => {
    if (localStorage.blockKeyboard === 'true') return null;

    document.getElementById(ref.current.modalId).remove();

    setOpen(false);

    if (closeModalCallback) closeModalCallback();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      modalId,
      openModal,
      closeModal,
    };
  });

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <Container>
      <ModalContainer>
        <Header>
          <Title>{title}</Title>
          <CloseSvg onClick={closeModal} />
        </Header>
        <Flex p="16px">
          <Component {...rest} closeModal={closeModal} />
        </Flex>
      </ModalContainer>
    </Container>,
    document.getElementById('modal')
  );
};

export default forwardRef(Modal);
