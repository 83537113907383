export default function onlyNumberHandleChangeCep(value) {
  let result = Array.from(value)
    .filter(char => char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57)
    .join('');

  result = result.slice(0, 8);

  if (result.length > 5) {
    result = result.substring(0, 5) + '-' + result.substring(5);
  }

  return result;
}
