import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 81px);

  padding: 20px;

  display: flex;
  flex-direction: column;

  background-color: var(--background);
`;
