import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

export const handleContractIsActive = contract => {
  const { hospitalId } = getParam();

  const url = `/companies/myhospitals/${hospitalId}/contracts/${contract.id}`;
  const request = { isActive: !contract.isActive };

  api
    .patch(url, request)
    .then(() => {
      toast.success(
        `Plantão ${contract.isActive ? 'desativado' : 'ativado'} com sucesso!`
      );
      return setTimeout(() => window.location.reload(), 1200);
    })
    .catch(internalServerError);
};
