import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;
export const Content = styled.textarea`
  width: 100%;
  border: 2px solid var(--black);
  border-radius: var(--br-sm);
  resize: none;
  margin: 5px 0 5px 0;
  padding: 10px 15px;
`;
