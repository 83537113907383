import api from '~/services/api';

export const fetchUsers = (callback, options) => {
  const { name, currentPage, limit } = options;

  const offset = (currentPage - 1) * limit;

  const url = `/companies/users?name=${name}&offset=${offset}&limit=${limit}&isActive=true`;

  api.get(url).then(callback);
};
