import React, { useState } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { Input } from '~/components/Kiwistrap/Inputs';
import { Button } from '~/components/ChakraComponents/';
import { Div, Row } from '~/components/Kiwistrap/Page';

import ModalInfo from '../ModalInfo';

export default function CheckoutModal({ vacancy }) {
  const [justification, setJustification] = useState('');

  const handleClick = async () => {
    if (justification.length < 5) {
      return toast.error('Por favor escreva uma justificativa válida.');
    }

    try {
      const url = `/doctors/myvacancies/${vacancy.id}/checkout`;

      await api.put(url, { justification });

      toast.success('Check-out realizado com sucesso!');

      return setTimeout(() => window.location.reload(), 1500);
    } catch (error) {
      return internalServerError();
    }
  };

  return (
    <Div width="400px">
      <ModalInfo vacancy={vacancy} />
      <Row justifyContent="center" margin="20px 0 8px">
        <Input
          required
          title="porque não usou nosso aplicativo?"
          placeholder="Ex: Acabou bateria do celular."
          value={justification}
          onChange={e => setJustification(e.target.value)}
        />
      </Row>
      <Row justifyContent="center">
        <Button
          title="fazer check-out"
          variant="success"
          onClick={handleClick}
        />
      </Row>
    </Div>
  );
}
