import React from 'react';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';

import removeMask from '~/utils/removeMask';

import { initialValues, validationSchema } from './schema';

import { Text } from '~/components/Kiwistrap/Page';
import { Input } from '~/components/Kiwistrap/Inputs';
import { Button } from '~/components/ChakraComponents/';

import { ResponsivePhaseContainer } from '../../styles';

export default function Phase1({ handlePhaseSubmit }) {
  const onSubmit = inputs => {
    if (inputs.password !== inputs.password_confirm) {
      return toast.error('As duas senhas digitas estão diferentes.');
    }

    delete inputs.password_confirm;

    inputs.phone1 = removeMask(inputs.phone1);

    return handlePhaseSubmit(inputs);
  };

  return (
    <ResponsivePhaseContainer>
      <Text padding="0 0 20px" style={{ textAlign: 'center' }}>
        Seu e-mail será utilizado para entrar no nosso site e aplicativo.
      </Text>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange }) => (
          <Form style={{ width: '100%' }}>
            <Input
              title="e-mail"
              name="email"
              onChange={email => {
                email.target.value = email.target.value.trim();
                handleChange(email);
              }}
              error={errors.email}
              value={values.email}
            />
            <Input
              title="telefone"
              name="phone1"
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              onChange={handleChange}
              error={errors.phone1}
              value={values.phone1}
            />
            <Input
              title="senha"
              type="password"
              name="password"
              onChange={handleChange}
              error={errors.password}
              value={values.password}
            />
            <Input
              title="confirmar senha"
              type="password"
              name="password_confirm"
              onChange={handleChange}
              error={errors.password_confirm}
              value={values.password_confirm}
            />
            <Button
              title="próximo passo"
              type="submit"
              width="100%"
              margin="12px 0 0"
            />
          </Form>
        )}
      </Formik>
    </ResponsivePhaseContainer>
  );
}
