import React, { useCallback } from 'react';

import moment from 'moment';

import onAvatarLoadError from '~/utils/onAvatarLoadError';

import { Row, Div, Title, Text, SubTitle } from '~/components/Kiwistrap/Page';

import { Container, AuthorAvatar } from './styles';

export default function NewsItem({ newsItem }) {
  const renderDate = useCallback(() => {
    let newsDate = moment(newsItem.date).format('DD/MM/YYYY');

    if (newsDate === 'Invalid date') {
      newsDate = 'Sem data';
    }

    return newsDate;
  }, []);

  return (
    <Container>
      <Row justifyContent="space-between">
        <Row alignItems="center">
          <AuthorAvatar
            src={newsItem.useradmin.avatar}
            alt={newsItem.useradmin.name}
            onError={onAvatarLoadError}
          />
          <Text margin="0 0 0 20px">{newsItem.useradmin.name}</Text>
        </Row>
        <SubTitle>{renderDate()}</SubTitle>
      </Row>
      <Div margin="20px 0 0">
        <Title size={24} margin="0 0 10px">
          {newsItem.title}
        </Title>
        <SubTitle color="var(--primary)">{newsItem.subtitle}</SubTitle>
        <Text margin="20px 0 0">{newsItem.description}</Text>
        {newsItem.attachmentnews.length > 0 && (
          <SubTitle margin="20px 0 0">Anexos:</SubTitle>
        )}
        {newsItem.attachmentnews.map(attachment => (
          <a href={attachment.fileUrl} target="_blank">
            {attachment.name}
          </a>
        ))}
      </Div>
    </Container>
  );
}
