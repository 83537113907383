import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { IText } from '~/components/UI/Inputs';
import { Button } from '~/components/ChakraComponents/';

export default function ChangePassword({ userId, onClose }) {
  const [inputs, setInputs] = useState({});

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (inputs.password.length < 8) {
      return toast.error('Por favor insira uma senha com 8 caracteres.');
    }

    if (inputs.password !== inputs.password_confirm) {
      return toast.error('Senhas digitadas são diferentes.');
    }

    const request = { password: inputs.password };

    api
      .patch(`/companies/users/${userId}`, request)
      .then(() => {
        toast.success('Senha alterada com sucesso!');
        onClose();
      })
      .catch(internalServerError);
  };

  return (
    <Flex flexDir="column">
      <IText
        required
        label="senha nova"
        type="password"
        name="password"
        onChange={handleChanges}
      />
      <IText
        required
        label="confirmar senha"
        type="password"
        name="password_confirm"
        onChange={handleChanges}
      />
      <Flex mt="20px">
        <Button
          title="cancelar"
          variant="secondary"
          margin="0 20px 0 0"
          onClick={onClose}
        />
        <Button title="confirmar" variant="success" onClick={handleSubmit} />
      </Flex>
    </Flex>
  );
}
