import React from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Container,
  Content,
  ContentHome,
  TitleHome,
  ContentProduct,
  ContentOtimization,
  ContentImgOtimization,
  ImgOtimization,
  TitleOtimization,
  ContentDescriptionOtimization,
  DescriptionOtimization,
  ContentControl,
  ContentControlDescription,
  TitleControl,
  SubTitleControl,
  ContentImgControl,
  ImgControl,
  ContentPartners,
  ContentListPartners,
  ImgPartners,
  ContentDescriptionPartners,
  TitlePartners,
  DescriptionPartners,
  ContentContact,
  Footer,
  ContentFooter,
  FooterLinks,
  TitleLink,
  ListLinks,
  ListItem,
  ContentBgContact,
  Bg,
  TitleContact,
  DescriptionContact,
  FooterSociais,
  TitleSociais,
  MidiasSociais,
  MidiasSociaisMobile,
  FooterDescription,
  TitleDescription,
  SubTitleDescription,
} from './styles';

import geocode from '~/services/geocode';

import BtnCadastrarNow from '~/components/Site/Buttons/BtnSecondary';
import BtnContactMe from '~/components/Site/Buttons/BtnPrimary';

import ControlScreen from '~/assets/home/controle-screen.png';
import ContactScreen from '~/assets/home/doctors-screen.png';

/** partners */

import P01 from '~/assets/home/partners/01.png';
import P02 from '~/assets/home/partners/02.png';
import P03 from '~/assets/home/partners/03.png';
import P04 from '~/assets/home/partners/04.png';
import P05 from '~/assets/home/partners/05.png';
import P06 from '~/assets/home/partners/06.png';

export default function Home(props) {
  const { hash } = props.location;
  const removeHash = '#';
  const idR = hash.split(removeHash);

  const listPartners = [
    {
      img: P01,
    },
    {
      img: P02,
    },
    {
      img: P03,
    },
    {
      img: P04,
    },
    {
      img: P05,
    },
    {
      img: P06,
    },
  ];

  return (
    <Container>
      <Content>
        <ContentProduct id={idR[1] === 'produto' ? 'produto' : ''}>
          <ContentOtimization>
            <ContentDescriptionOtimization>
              <TitleOtimization>Otimização de Equipes</TitleOtimization>
              <DescriptionOtimization>
                Selecioner profissionais é uma tarefa difícil mas pode ser feita
                de
                <br /> maneira mais ágil, prática e acima de tudo simples.
                <br /> Gerencie diferentes especialidade e tenha controle sobre
                alertas e<br /> avaliações sobre profissionais.
              </DescriptionOtimization>
            </ContentDescriptionOtimization>
          </ContentOtimization>
          <ContentControl>
            <ContentControlDescription>
              <TitleControl>Controle Total</TitleControl>
              <SubTitleControl>
                Como profissional é de extrema importância gerenciar bem o<br />{' '}
                tempo, com a nossa ferramenta você tem tudo isso à sua mão.
              </SubTitleControl>
            </ContentControlDescription>
            <ContentImgControl>
              <ImgControl src={ControlScreen} alt="controle" />
            </ContentImgControl>
          </ContentControl>
        </ContentProduct>
        <ContentPartners>
          <ContentListPartners>
            {listPartners.map(part => (
              <ImgPartners
                key={Math.random(Math.random())}
                src={part.img}
                alt=""
              />
            ))}
          </ContentListPartners>
          <ContentDescriptionPartners>
            <TitlePartners>Nossos Parceiros</TitlePartners>
            <DescriptionPartners>
              Nossos clientes parceiros já começaram a revolução
              <br /> organizacional, você também pode começar a sua!
            </DescriptionPartners>
          </ContentDescriptionPartners>
        </ContentPartners>
        <ContentContact
          bg={ContactScreen}
          id={idR[1] === 'contato' ? 'contato' : ''}
        >
          <Bg />
          <ContentBgContact>
            <TitleContact>Comece já!</TitleContact>
            <DescriptionContact>
              Estamos a sua disposição para tirar todas as suas dúvidas.
              <br /> Agende a sua demonstração com a nossa equipe.
            </DescriptionContact>
            <BtnContactMe
              title="FALE CONOSCO"
              to="/contato"
              styles={{ width: '260px', height: '48px' }}
            />
          </ContentBgContact>
        </ContentContact>

        {/* <div>
          <PieChart graphData={data} />
        </div> */}

        <Footer>
          <ContentFooter>
            <FooterLinks>
              <TitleLink>Links Úteis</TitleLink>
              <ListLinks>
                <ListItem>
                  <a href="#home">Home</a>
                </ListItem>
                <ListItem>
                  <a href="#produto">Produto</a>
                </ListItem>
                <ListItem>
                  <a href="#contato">Contato</a>
                </ListItem>
                <ListItem>
                  <Link to="/contato">Fale Conosco</Link>
                </ListItem>
              </ListLinks>
            </FooterLinks>
            <FooterSociais>
              <TitleSociais>Siga-nos nas redes sociais</TitleSociais>
              <MidiasSociais>
                <a href="https://www.facebook.com/MeuPlantao" target="_blank">
                  <FaFacebook
                    size={30}
                    color="var(--background-alt)"
                    style={{ marginRight: 40 }}
                  />
                </a>
                <a href="https://www.instagram.com/meuplantao/" target="_blank">
                  <FaInstagram
                    size={30}
                    color="var(--background-alt)"
                    style={{ marginRight: 40 }}
                  />
                </a>
                <a href="#" target="_blank">
                  <FaWhatsapp size={30} color="var(--background-alt)" />
                </a>
              </MidiasSociais>
              <MidiasSociaisMobile>
                <a href="https://www.facebook.com/MeuPlantao" target="_blank">
                  <FaFacebook
                    size={30}
                    color="var(--background-alt)"
                    style={{ marginBottom: 10 }}
                  />
                </a>
                <a href="https://www.instagram.com/meuplantao/" target="_blank">
                  <FaInstagram
                    size={30}
                    color="var(--background-alt)"
                    style={{ marginBottom: 10 }}
                  />
                </a>
                <a href="#" target="_blank">
                  <FaWhatsapp
                    size={30}
                    color="var(--background-alt)"
                    style={{ marginBottom: 10 }}
                  />
                </a>
              </MidiasSociaisMobile>
            </FooterSociais>
            <FooterDescription>
              <TitleDescription>Horário de Funcionamento</TitleDescription>
              <SubTitleDescription>
                Segunda à Sexta-Feira: 08:00 às 17:00
              </SubTitleDescription>
              <SubTitleDescription>
                Sábado à Domingo: Fechado
              </SubTitleDescription>
            </FooterDescription>
          </ContentFooter>
        </Footer>
      </Content>
    </Container>
  );
}
