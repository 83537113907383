import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Flex, SimpleGrid } from '@chakra-ui/react';

import api from '~/services/api';

import { Modal } from '~/components/UI/Modal';
import { Spinner } from '~/components/ChakraComponents/';

import DoctorCard from './DoctorCard';

import EditDoctorModal from '../../components/EditDoctorModal';
import FindDoctorInput from '../../components/FindDoctorInput';

import Pagination from '~/components/Dashboard/Pagination';
import DoctorProfileModal from '~/components/Dashboard/Modals/DoctorProfileModal';

const limit = 12;

export default function MyDoctorsPanel() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [crm, setCrm] = useState('');
  const [name, setName] = useState('');
  const [total, setTotal] = useState(0);
  const [doctors, setDoctors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [clickedDoctor, setClickedDoctor] = useState();

  const editDoctorModalRef = useRef(null);
  const doctorProfileModalRef = useRef(null);

  const openEditDoctor = useCallback(doctor => {
    setClickedDoctor(doctor);
    editDoctorModalRef.current.openModal();
  }, []);

  const openDoctorProfile = useCallback(doctor => {
    setClickedDoctor(doctor);
    doctorProfileModalRef.current.openModal();
  }, []);

  const fetchData = useCallback(() => {
    const offset = (currentPage - 1) * limit;

    const url = `/companies/mydoctors?limit=${limit}&offset=${offset}&name=${name}&crm=${crm}&isActive=true`;

    api.get(url).then(response => {
      setTotal(response.data.total);
      setDoctors(response.data.data);
      setLoadingPage(false);
    });
  }, [currentPage, name, crm, limit]);

  useEffect(() => {
    fetchData();
  }, [currentPage, name, crm, fetchData]);

  return (
    <>
      {loadingPage ? (
        <Spinner />
      ) : (
        <>
          <Flex justify="center">
            <FindDoctorInput setCrm={setCrm} setName={setName} />
          </Flex>
          <SimpleGrid minChildWidth="240px" spacing={4} mt={4} mb={4}>
            {doctors.map(doctor => (
              <DoctorCard
                key={doctor.id}
                doctor={doctor}
                openProfile={() => openDoctorProfile(doctor)}
                openEdit={() => openEditDoctor(doctor)}
              />
            ))}
          </SimpleGrid>
          <Pagination l={limit} t={total} onClick={setCurrentPage} />
          <Modal
            title="Ver Perfil"
            ref={doctorProfileModalRef}
            component={DoctorProfileModal}
            doctor={clickedDoctor}
          />
          <Modal
            title="Plantões do médico"
            ref={editDoctorModalRef}
            component={EditDoctorModal}
            doctor={clickedDoctor}
          />
        </>
      )}
    </>
  );
}
