import React, { useState, useRef, useCallback } from 'react';

import { Modal } from '~/components/UI/Modal';

import VacancyCard from '../VacancyCard';
import CandidateModal from '../CandidateModal';

import { Container } from './styles';

const VacancyList = ({ vacancies, reloadSearch }) => {
  const [clickedVacancy, setClickedVacancy] = useState();

  const candidateModalRef = useRef(null);

  const openCandidate = useCallback(vacancy => {
    setClickedVacancy(vacancy);
    candidateModalRef.current.openModal();
  }, []);

  return (
    <Container>
      {vacancies.map(vacancy => (
        <VacancyCard
          key={vacancy.id}
          vacancy={vacancy}
          openCandidate={openCandidate}
        />
      ))}
      <Modal
        title="Candidatar"
        ref={candidateModalRef}
        vacancy={clickedVacancy}
        component={CandidateModal}
        reloadSearch={reloadSearch}
      />
    </Container>
  );
};

export default VacancyList;
