import React, { useRef, useCallback } from 'react';

import CandidaturesModal from '../CandidaturesModal';

import { Button } from '~/components/ChakraComponents/';
import { Modal } from '~/components/UI/Modal';

const CandidaturesBtn = React.memo(() => {
  const candidaturesModalRef = useRef(null);

  const openCandidatures = useCallback(() => {
    candidaturesModalRef.current.openModal();
  }, []);

  return (
    <>
      <Button
        title="minhas candidaturas"
        width="400px"
        onClick={openCandidatures}
      />
      <Modal
        ref={candidaturesModalRef}
        title="Minhas Candidaturas"
        component={CandidaturesModal}
      />
    </>
  );
});

export default CandidaturesBtn;
