import React, { useCallback } from 'react';
import moment from 'moment';
import { Flex, Text } from '@chakra-ui/react';
import { AiFillQuestionCircle } from 'react-icons/ai';

import getFirstLastName from '~/utils/getFirstLastName';
import { vacancyCardBackgroundColor } from '~/utils/genStatus';

import { MonthDoctorContainer } from './styles';

export default function MonthPendingDoctorCard({ vacancy, onClick }) {
  const renderDoctorName = useCallback(() => {
    return getFirstLastName(vacancy.pendingDoctorName);
  }, []);

  const renderHourText = useCallback(() => {
    const { startHour, endHour } = vacancy;

    const formattedStart = moment(new Date(startHour)).format('HH:mm');
    const formattedEnd = moment(new Date(endHour)).format('HH:mm');

    return `${formattedStart}h às ${formattedEnd}h`;
  }, []);

  return (
    <MonthDoctorContainer
      color={vacancyCardBackgroundColor(
        'pendingDoctor',
        !vacancy.belongsMyTeam
      )}
      onClick={onClick}
    >
      <Text color="#505050">{renderDoctorName()}</Text>
      <Text color="#505050">{renderHourText()}</Text>
      <Flex pos="absolute" right="4px">
        <Flex
          fontSize="26px"
          color={vacancyCardBackgroundColor(
            vacancy.type,
            !vacancy.belongsMyTeam
          )}
          background="white"
          borderRadius="full"
        >
          <AiFillQuestionCircle />
        </Flex>
      </Flex>
    </MonthDoctorContainer>
  );
}
