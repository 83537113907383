import styled from 'styled-components';

export const RatingsGrid = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

export const Rating = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;
