import React from 'react';
import { FaMoneyBillWave, FaStreetView } from 'react-icons/fa';
import { AiFillMessage } from 'react-icons/ai';

import phoneMask from '~/utils/phoneMask';

import InfoSection from './InfoSection';

import { Container, Header } from './styles';

const defaultAvatar =
  'https://verde-azul-imoveis.s3.amazonaws.com/b36f1999-aada-4f0b-9fe8-21bdf419fef7.png';

export default function Hospital({ hospital }) {
  const renderHospitalTotal = () => {
    const formattedValue = hospital.total.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

    return (
      <strong style={{ color: 'var(--green)', fontWeight: 600 }}>
        {formattedValue}
      </strong>
    );
  };

  return (
    <Container>
      <Header>
        <img
          src={hospital.hospital.avatar || defaultAvatar}
          alt={hospital.hospital.name}
        />
        {`${hospital.hospital.name} (${hospital.financeDate
          .split('-')
          .reverse()
          .join('/')})`}
      </Header>
      <InfoSection
        icon={FaMoneyBillWave}
        title="Valor Total" // Mudar título baseado no mês
        info={renderHospitalTotal()}
      />
      <InfoSection
        icon={FaStreetView}
        title="Endereço"
        info={`${hospital.hospital.uf} - ${hospital.hospital.city}, ${hospital.hospital.address}, ${hospital.hospital.street}, ${hospital.hospital.number}`}
      />
      <InfoSection
        icon={AiFillMessage}
        title="Contato"
        info={`E-mail: ${hospital.hospital.email} | Telefone: ${phoneMask(
          hospital.hospital.phone1
        )} ${phoneMask(hospital.hospital.phone2)}`}
      />
    </Container>
  );
}
