import styled from 'styled-components';

export const FieldContainer = styled.div`
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;

  margin-bottom: 12px;

  > label {
    margin-bottom: 5px;

    text-transform: uppercase;
    text-align: left;

    font-size: 15px;
    font-weight: bold;

    color: var(--primary);
  }

  > select {
    height: 40px;

    padding: 5px;

    border: 2px solid var(--black);
    border-radius: var(--br-sm);

    background-color: var(--background-alt);
  }
`;
