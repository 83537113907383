import React, { useRef, useCallback } from 'react';
import { GoReport } from 'react-icons/go';
import { Container, Row } from 'react-bootstrap';
import { FaFileInvoiceDollar, FaChartLine } from 'react-icons/fa';

import history from '~/services/history';

import Card from './Card';

import RelatoryModal from './Relatories/RelatoryModal';

import IsAllowed from '~/components/UI/IsAllowed';
import PageLayout from '~/pages/_layouts/company/PageLayout';

import { Modal } from '~/components/UI/Modal';

export default function SpendControl() {
  const relatoryModalRef = useRef(null);

  const openRelatory = useCallback(() => {
    relatoryModalRef.current.openModal();
  }, []);

  const navigate = () => history.push('/company/doctorspending');

  const openVacancy = () =>
    history.push('/company/approvevacancy?isApproved=0&limit=8&offset=0');

  return (
    <PageLayout>
      <Container>
        <Row>
          <IsAllowed id={13}>
            <Card
              onClick={navigate}
              color="purple"
              icon={FaFileInvoiceDollar}
              text="Gastos com Médicos"
            />
          </IsAllowed>
          <IsAllowed id={14}>
            <Card
              onClick={openRelatory}
              color="orange"
              icon={GoReport}
              text="Relatórios"
            />
          </IsAllowed>
        </Row>
        <Row>
          <IsAllowed id={15}>
            <Card
              onClick={openVacancy}
              color="red"
              icon={FaChartLine}
              text="Valores Aprovação Plantões"
            />
          </IsAllowed>
        </Row>
      </Container>
      <Modal
        title="Relatórios"
        ref={relatoryModalRef}
        component={RelatoryModal}
      />
    </PageLayout>
  );
}
