import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { AiOutlineCopy } from 'react-icons/ai';
import { Flex, Text, Tooltip } from '@chakra-ui/react';

import { Label } from '~/components/UI/Text';

export default function PendingDoctorUrl({ doctor }) {
  const [clicked, setClicked] = useState(false);

  const url = useMemo(
    () => process.env.REACT_APP_HOME_BASE_URL + '/convite/' + doctor.hash,
    [doctor]
  );

  const onClick = () => {
    if (clicked) return;

    setClicked(true);

    navigator.clipboard.writeText(url);

    setTimeout(() => setClicked(false), 1200);
  };

  const renderInviteHeader = () => {
    const date = moment(doctor.createdAt).format('DD/MM/YYYY HH:mm');
    const info = `CRM ${doctor.pendingCrm}-${doctor.pendingCrmUf}`;

    return `${date} | ${info}`;
  };

  if (!doctor.hash) {
    return null;
  }

  return (
    <Flex w="100%" py={2} justify="space-between" align="center">
      <Flex pl={4} flexShrink="none" w={`calc(100% - 50px)`} flexDir="column">
        <Label
          pb={1}
          color={clicked ? 'var(--green)' : 'var(--primary)'}
          transition="all 150ms ease-in-out"
        >
          {clicked ? 'Link Copiado!' : renderInviteHeader()}
        </Label>
        <Tooltip hasArrow label="Clique para copiar" bg="gray.600">
          <span>
            <Text
              cursor="pointer"
              isTruncated
              color="var(--secondary)"
              userSelect="none"
              _hover={{ textDecor: 'underline' }}
              onClick={onClick}
            >
              {url}
            </Text>
          </span>
        </Tooltip>
      </Flex>
      <Flex
        cursor="pointer"
        flexShrink="none"
        w="50px"
        h="100%"
        justify="center"
        align="center"
        fontSize="26px"
        color="var(--light-gray)"
        _hover={{ color: 'var(--secondary)' }}
      >
        <Tooltip hasArrow label="Clique para copiar" bg="gray.600">
          <span>
            <AiOutlineCopy onClick={onClick} />
          </span>
        </Tooltip>
      </Flex>
    </Flex>
  );
}
