import React from 'react';

import { CleaveInput, Container } from './styles';

// https://nosir.github.io/cleave.js/ For Options

export default function InputText({ useCleave, options, style, ...rest }) {
  return useCleave === 'true' ? (
    <CleaveInput style={style} options={options} {...rest} />
  ) : (
    <Container style={style}>
      <input className="input" {...rest} />
    </Container>
  );
}
