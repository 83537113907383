import React, {
  createContext,
  useMemo,
  useCallback,
  useState,
  useContext,
} from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import history from '../services/history';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [usuario, setUsuario] = useState(() => {
    const token = localStorage.getItem('@Plantao:token');
    const user = localStorage.getItem('@Plantao:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const error = useMemo(() => {
    return {
      shortPassword: () => {
        return toast.error(
          'A senha possui o mínimo de 6 caracteres! Por gentileza, verifique.'
        );
      },
      internalServerError: () => {
        return toast.error(
          'Houve um problema com nosso servidores! Tente novamente mais tarde.'
        );
      },
      emailNotConfirmed: () => {
        return toast.error(
          'E-mail não confirmado! Um e-mail de confirmação será enviado para você.'
        );
      },
      failToSendEmail: () => {
        return toast.error(
          'Falha ao enviar e-mail de confirmação! Tente novamente.'
        );
      },
      deactivatedAccount: () => {
        return toast.error(
          'Essa conta foi desativada, entre em contato com o Time do Meu Plantão'
        );
      },
      invalidUser: () => {
        return toast.error(
          'Usuário inválido! Verifique o e-mail e a senha e tente novamente.'
        );
      },
      invalidPassword: () => {
        return toast.error(
          'A senha informada está incorreta! Por gentileza, verifique e informe novamente.'
        );
      },
      notAllowed: () => {
        return toast.error('Você não tem autorização para realizar essa ação.');
      },
    };
  }, []);

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        if (password.length < 6) {
          return error.shortPassword();
        }

        const { data } = await api.post('login', {
          email,
          password,
        });

        if (!data) {
          return error.internalServerError();
        }

        const { token, user } = data;
        const { firstLogin } = user;

        if (user.confirmed === false) {
          error.emailNotConfirmed();

          try {
            await api.post('/confirm-email', { email });
            return toast.success('E-mail de confirmação enviado com sucesso!');
          } catch (e) {
            return error.failToSendEmail();
          }
        }

        localStorage.clear();
        localStorage.setItem('firstLogin', firstLogin);
        localStorage.setItem('@Plantao:token', token);
        localStorage.setItem('@Plantao:user', JSON.stringify(user));

        setUsuario({ token, user });

        if (user.type === 'doctor') {
          return history.push('/medico/dashboard');
        }

        if (user.type === 'company') {
          return history.push(`/company/${user.homepage.url}`);
        }

        if (user.type === 'contractor') {
          return history.push(`/contractor/dashboard`);
        }
      } catch (e) {
        if (e.response) {
          if (e.response.data.error === 'not found') {
            return error.invalidUser();
          }

          if (e.response.data.error === 'Password does not match') {
            return error.invalidPassword();
          }

          if (e.response.data.error === 'deactivated') {
            return error.deactivatedAccount();
          }
        }

        return error.internalServerError();
      }
    },
    [error]
  );

  const signOut = useCallback(() => {
    localStorage.clear();
    setUsuario({});
    history.push('/');
  }, []);

  return (
    <AuthContext.Provider value={{ user: usuario.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
