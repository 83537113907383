import React, { useState, useEffect } from 'react';

import api from '~/services/api';

import downloadIcon from '~/assets/icons/download.svg';

import PageLayout from '~/pages/_layouts/doctor/PageLayout';

import { Title } from '~/components/Kiwistrap/Page';

import Pagination from '~/components/Dashboard/Pagination';
import InputText from '~/components/Dashboard/Inputs/InputText';

import {
  PaginationContainer,
  ContainerEspecialidades,
  DocumentList,
  Icon,
  IconsContainer,
  ItemList,
  ListContainer,
  Result,
  Results,
} from './styles';

export default function Courseware() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [materials, setMaterials] = useState([]);
  const [inputSpecialty, setInputSpecialty] = useState('');
  const [inputSpecialties, setInputSpecialties] = useState('');
  const [inputSpecialtyId, setInputSpecialtyId] = useState('');
  const [openResults, setOpenResults] = useState(false);

  useEffect(() => {
    async function listMaterials() {
      const response = await api.get('/doctors/coursewares?limit=8');

      setMaterials(response.data.data);
    }

    listMaterials();

    setLoadingPage(false);
  }, []);

  async function sortBySpecialty(specialtyId) {
    const response = await api.get(
      `/doctors/coursewares?limit=8&specialty=${specialtyId}`
    );

    setMaterials(response.data.data);
  }

  const resultOnClick = id => {
    setInputSpecialtyId(id);
    setOpenResults(false);
    sortBySpecialty(id);

    const input = inputSpecialties.filter(x => x.id === id);
    setInputSpecialty(input[0].name);
  };

  const handleSpecialty = async e => {
    const url = `/allspecialties?name=${e}&limit=7`;
    const res = await api.get(url);
    setInputSpecialties(res.data);
  };

  const renderResults = arr => {
    if (arr.length === 0) return null;

    if (arr.length !== 0)
      return (
        <Results>
          {inputSpecialties.map(specialty => (
            <Result
              key={specialty.id}
              onClick={() => {
                resultOnClick(specialty.id);
              }}
            >
              {specialty.name}
            </Result>
          ))}
        </Results>
      );
    return inputSpecialtyId;
  };

  return (
    <PageLayout title="Material Didático" loading={loadingPage}>
      <div className="content">
        <ContainerEspecialidades>
          <p>Especialidade</p>
          <InputText
            required
            placeholder="Ex: Clínica Médica"
            value={inputSpecialty}
            onChange={e => {
              handleSpecialty(e.target.value);
              setInputSpecialty(e.target.value);
              setOpenResults(true);
            }}
          />
          {openResults && inputSpecialties && renderResults(inputSpecialties)}
        </ContainerEspecialidades>
        <ListContainer>
          {materials.length <= 0 && (
            <Title center>Não há nenhum material didático!</Title>
          )}
          <DocumentList>
            {materials.map(material => (
              <ItemList key={material.id}>
                <span>{material.name}</span>
                <IconsContainer>
                  <a
                    href={`https://verde-azul-imoveis.s3.amazonaws.com/${material.bucketName}`}
                    target="_blank"
                    download={material.bucketName}
                    rel="noopener noreferrer"
                  >
                    <Icon src={downloadIcon} alt="Download" />
                  </a>
                </IconsContainer>
              </ItemList>
            ))}
          </DocumentList>
        </ListContainer>
        <PaginationContainer>
          <Pagination l={8} t={materials.length} />
        </PaginationContainer>
      </div>
    </PageLayout>
  );
}
