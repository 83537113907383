import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Text } from '~/components/Kiwistrap/Page';

import PageLayout from '~/pages/_layouts/contractorhospital/PageLayout';

import MenuLeft from './components/MenuLeft';

import { StethoscopeSvg } from './styles';

export default function SpecialtiesPageNoneChoosen() {
  return (
    <PageLayout
      title="Escalas"
      disableDefaultButtons
      userInfoButtons={<MenuLeft />}
    >
      <Flex
        h="calc(100vh - 120px)"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <StethoscopeSvg />
        <Text size="24px" color="var(--primary)">
          Por favor selecione uma especialidade.
        </Text>
      </Flex>
    </PageLayout>
  );
}
