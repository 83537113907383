import React from 'react';
import styled from 'styled-components';
import { Text } from '@chakra-ui/react';
import { IoMdAlert } from 'react-icons/io';

import { vacancyCardBackgroundColor } from '~/utils/genStatus';

import { FloatCandidature } from './styles';

const Container = styled.div`
  cursor: pointer;
  overflow: hidden;
  position: relative;

  width: 100%;
  height: 100%;

  padding: 0 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;

  text-align: center;

  border-radius: 4px;

  color: var(--background-alt);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  background-color: ${props => props.color};

  @media (max-width: 1600px) {
    font-size: 12px;
  }
`;

const ObservationSvg = styled(IoMdAlert)`
  height: 20px;
  width: 20px;

  border-radius: 50%;

  color: var(--orange);
  background-color: var(--background-alt);
`;

const TopLeftTooltip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.25rem;
`;

export default function OpenCard({ vacancy, onClick }) {
  return (
    <Container color={vacancyCardBackgroundColor('open')} onClick={onClick}>
      {vacancy.hasObservation && (
        <TopLeftTooltip
          hasArrow
          label={`${vacancy.countObservation} Observaç${
            vacancy.countObservation === 1 ? 'ão' : 'ões'
          }`}
          bg="gray.600"
        >
          <span>
            <ObservationSvg />
          </span>
        </TopLeftTooltip>
      )}
      {vacancy.countCandidature !== 0 && (
        <FloatCandidature>
          {vacancy.countCandidature > 9 ? '9+' : vacancy.countCandidature}
        </FloatCandidature>
      )}
      <Text color="#505050">vaga aberta</Text>
    </Container>
  );
}
