import React from 'react';
import { Route } from 'react-router-dom';

import history from '~/services/history';
import isUserAllowedToViewPage from '~/services/isUserAllowedToViewPage';
import { getUser } from '~/services/auth';
import { getParam } from '~/utils/url';

import CompanyLayout from '~/pages/_layouts/company';

export default function CompanyRouteWrapper({
  tag,
  homepage,
  component: Component,
  ...rest
}) {
  const user = getUser();
  const { hospitalId } = getParam('hospitalId');

  if (!user) {
    history.push('/');
    return window.location.reload();
  }

  if (user.type === 'doctor') {
    history.push('/medico/dashboard');
    return window.location.reload();
  }

  if (hospitalId) {
    history.push(`/hospital/dashboard/?hospitalId=${hospitalId}`);
    return window.location.reload();
  }

  if (!isUserAllowedToViewPage('companyPermissions', tag, homepage)) {
    history.push(`/company/${user.homepage.url}`);
  }

  return (
    <Route
      {...rest}
      render={props => (
        <CompanyLayout>
          <Component {...props} />
        </CompanyLayout>
      )}
    />
  );
}
