export const header = {
  color: 'var(--primary)',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const desc = {
  mb: 3,
  color: 'gray.600',
  fontWeight: '500',
};

export const box = {
  flexDir: 'column',
  w: '100%',
  h: '300px',
  padding: 2,
  borderRadius: 'md',
  backgroundColor: 'var(--background-alt)',
  overflowY: 'scroll',
};

export const arrow = {
  cursor: 'pointer',
  w: '40px',
  h: '40px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'full',
  backgroundColor: 'var(--primary)',
  boxShadow: 'base',
  _hover: {
    boxShadow: 'md',
    backgroundColor: 'var(--hover-primary)',
  },
};
