import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { VStack, Flex, Text, Grid } from '@chakra-ui/react';

import { weekDaysAlt } from '~/constants/weekDays';
import { getDates, fetchSchedules } from '~/pages/hospital/VacanciesV2/utils';

import { Spinner } from '~/components/ChakraComponents';
import { VacancyCard } from '~/components/UI/Cards';

// Title
const T = ({ children }) => (
  <Flex
    w="100%"
    minH="28px"
    flexDir="column"
    align="center"
    justify="center"
    bgColor="gray.600"
    color="white"
    fontWeight="semibold"
  >
    <Text>{children}</Text>
  </Flex>
);

export default function DesktopMonthAgenda({ baseUrl, cardOnClick }) {
  const { scheduleType, vacancyDate } = useParams();

  const [dates, setDates] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    const [startDate, endDate] = getDates(vacancyDate, scheduleType);

    const url = `${baseUrl}?startDate=${startDate}&endDate=${endDate}`;

    fetchSchedules(
      url,
      (newDates, newSchedules) => {
        setDates(newDates);
        setSchedules(newSchedules);
        setLoading(false);
      },
      { isGroup: true }
    );
  };

  useEffect(() => {
    if (loading) fetchData();
  }, [loading]);

  useEffect(() => {
    setLoading(true);

    localStorage.setItem('vacancyDate', vacancyDate);
    localStorage.setItem('scheduleType', scheduleType);
  }, [scheduleType, vacancyDate]);

  // Clock => Fetches vacancies every 2 minutes
  const clockRef = useRef(fetchData);

  clockRef.current = fetchData;

  const clock = () =>
    setTimeout(() => {
      clockRef.current();

      if (window.location.pathname.includes(baseUrl)) {
        clock();
      }
    }, 120000);

  useEffect(() => {
    clock();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Flex flexDir="column" h="100%" mt="1rem">
          {dates
            .reduce((all, one, i) => {
              const ch = Math.floor(i / 7);
              all[ch] = [].concat(all[ch] || [], one);
              return all;
            }, [])
            .map(week => (
              <React.Fragment key={Math.random(Math.random())}>
                <Grid
                  mb="1px"
                  gridGap="1px"
                  templateColumns="0.75fr repeat(7, 1fr)"
                >
                  <T>Setor</T>
                  {week.map(weekDay => (
                    <T key={weekDay}>
                      <Flex justify="center" textAlign="center">
                        {weekDaysAlt[new Date(weekDay).getDay()]}
                      </Flex>
                      <Flex justify="center" textAlign="center">
                        {moment(weekDay).format('DD/MM/YYYY')}
                      </Flex>
                    </T>
                  ))}
                  {[...new Array(7 - week.length)].map(() => (
                    <Flex key={Math.random(Math.random())} h="100%" />
                  ))}
                </Grid>
                <Grid
                  mb="1px"
                  gridGap="1px"
                  templateColumns="0.75fr repeat(7, 1fr)"
                >
                  {schedules.map(schedule => (
                    <React.Fragment key={schedule.id}>
                      <Flex
                        h="100%"
                        px="4px"
                        align="center"
                        justify="center"
                        textAlign="center"
                        color="white"
                        bgColor="gray.500"
                        fontWeight="semibold"
                        wordBreak="break-all"
                        lineHeight="1"
                      >
                        {schedule.contract.sector}
                      </Flex>
                      {schedule.dates.map(date => (
                        <React.Fragment key={date.day}>
                          {week.includes(date.day) && (
                            <VStack spacing="1px">
                              {date.vacancies.map(vacancy => (
                                <VacancyCard
                                  isMonth
                                  key={vacancy.id}
                                  vacancy={vacancy}
                                  onClick={() => cardOnClick(vacancy)}
                                />
                              ))}
                            </VStack>
                          )}
                          {date.day === dates[dates.length - 1] &&
                            [...new Array(7 - week.length)].map(() => (
                              <Flex key={Math.random(Math.random())} h="100%" />
                            ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </Grid>
              </React.Fragment>
            ))}
        </Flex>
      )}
    </>
  );
}
