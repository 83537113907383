import React, { useState, useRef, useCallback, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Flex, Text, HStack, useBoolean, ButtonGroup } from '@chakra-ui/react';
import {
  FaUserMd as DoctorSvg,
  FaTimesCircle as RemoveDoctorSvg,
} from 'react-icons/fa';

import api from '~/services/api';
import wait from '~/utils/wait';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import ChooseType from './ChooseType';
import ChooseValue from './ChooseValue';
import ChooseDoctor from './ChooseDoctor';
import ForceEdit from './ForceEdit/ForceEditModal';
import ChooseUserInCharge from './ChooseUserInCharge';
import ChooseWorkedHours from './ChooseWorkedHours/index';

import ProgressModal from '~/components/Dashboard/Modals/ProgressModal';
import { Label } from '~/components/UI/Text';
import { PendingDoctorUrl } from '~/components/UI/Sections';
import { Modal, ModalContainer } from '~/components/UI/Modal';
import { ICheck, IText } from '~/components/UI/Inputs';
import {
  Alert,
  Button,
  Divider,
  LinkButton,
} from '~/components/ChakraComponents';

export default function EditVacancy({ vacancy, scheduleId, closeModal }) {
  const [loading, setLoading] = useState(false);
  const [doctor, setDoctor] = useState(
    vacancy.doctor ? vacancy.doctor.user : 'Nenhum escolhido'
  );
  const [pendingDoctor, setPendingDoctor] = useState(
    vacancy.pendingDoctorName
      ? {
          name: vacancy.pendingDoctorName,
          crm: vacancy.pendingDoctorCrm,
          crmUf: vacancy.pendingDoctorCrmUf,
          hash: vacancy.pendingDoctorHash,
        }
      : null
  );
  const [userInCharge, setUserInCharge] = useState(
    vacancy.userInCharge
      ? {
          ...vacancy.userInCharge.user,
          usercompany: [{ ...vacancy.userInCharge }],
        }
      : null
  );
  const [type, setType] = useState(vacancy.type);
  const [value, setValue] = useState(`${vacancy.value}`);
  const [disableEditOptions, setDisableEditOptions] = useState(true);
  const [inCash, setInCash] = useState(vacancy.inCash);
  const [requestAntecipe, setRequestAntecipe] = useState(
    vacancy.requestAntecipe
  );
  const [requestIsPaid, setRequestIsPaid] = useState(vacancy.requestIsPaid);

  const [differentiatedValue, setDifferentiatedValue] = useState(
    vacancy.differentiatedValue
  );
  const [belongsMyTeam, setBelongsMyTeam] = useState(vacancy.belongsMyTeam);
  const [dataVacancyError, setDataVacancyError] = useState([]);
  const [canAutoApprove, setCanAutoApprove] = useState(!vacancy.canAutoApprove);
  const [finalDate, setFinalDate] = useState('');
  const [confirmToEnd, setConfirmToEnd] = useBoolean();
  const [confirmToDate, setConfirmToDate] = useBoolean();
  const [confirmException, setConfirmException] = useBoolean();
  const [isToEnd, setIsToEnd] = useState(false);
  const [checkinAllowed, setCheckinAllowed] = useState(false);
  const [disableChooseWorkedHours, setDisableChooseWorkedHours] = useState(
    true
  );
  const [disableChooseValue, setDisableChooseValue] = useState(true);

  const { hospitalId } = getParam();

  const forceEditModalRef = useRef(null);
  const chooseDoctorModalRef = useRef(null);

  const openChooseDoctor = useCallback(() => {
    chooseDoctorModalRef.current.openModal();
  }, []);

  const openForceEdit = useCallback(() => {
    forceEditModalRef.current.openModal();
  }, []);

  const submit = async (toEnd = false, force = false) => {
    if (value === '' || value.length < 2) {
      return toast.error('Por favor insira um valor válido.');
    }

    setLoading(true);

    setIsToEnd(toEnd);
    await wait(1200);

    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancy.id}/${vacancy.countJump}?forceEdit=${force}`;

    const request = {
      type,
      value: +value.replaceAll(',', ''),
      status: 'pending',
      inCash,
      belongsMyTeam,
      requestAntecipe,
      userInCharge: userInCharge?.usercompany[0]?.id || null,
      canAutoApprove: !canAutoApprove,
      pendingDoctorName: null,
      pendingDoctorCrm: null,
      pendingDoctorCrmUf: null,
    };

    if (!toEnd) {
      const formattedFinalDate = finalDate
        .split('/')
        .reverse()
        .join('-');

      request.toDate = formattedFinalDate;
    } else {
      request.isToEndSchedule = true;
    }

    if (type === 'cover') {
      delete request.toEnd;
      delete request.isToEndSchedule;
    }

    if (vacancy.companyDoctor) {
      request.companyDoctor = vacancy.companyDoctor.id;
    }

    if (doctor.companyDoctor) {
      request.companyDoctor = doctor.companyDoctor.id;
    }

    if (doctor === 'Nenhum') {
      request.companyDoctor = 0;
    }

    if (pendingDoctor) {
      request.companyDoctor = 0;
      request.pendingDoctorName = pendingDoctor.name;
      request.pendingDoctorCrm = pendingDoctor.crm;
      request.pendingDoctorCrmUf = pendingDoctor.crmUf;
      request.pendingDoctorPhone = pendingDoctor.phone;
    }

    api
      .patch(url, {
        companyDoctor: 0,
        ...request,
      })
      .then(response => {
        toast.success('Plantão editado com sucesso!');

        if (pendingDoctor) {
          setPendingDoctor({
            ...pendingDoctor,
            hash: response.data.pendingDoctorHash,
          });
        } else if (!force) {
          closeModal();
        }

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.response.data.data && error.response.status === 409) {
          setDataVacancyError(error.response.data.data);
          openForceEdit();
        } else {
          internalServerError();
        }
      });
  };

  const submitOptions = async () => {
    setLoading(true);

    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancy.id}/option`;

    const request = {
      inCash,
      requestAntecipe,
      differentiatedValue,
      canAutoApprove: !canAutoApprove,
    };

    api
      .patch(url, {
        ...request,
      })
      .then(() => {
        toast.success('Plantão editado com sucesso!');

        setLoading(false);
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(() => {
        setLoading(false);
        internalServerError();
      });
  };

  useEffect(() => {
    const dateNowLess5min = new Date();
    dateNowLess5min.setMinutes(dateNowLess5min.getMinutes() - 5);
    const startHour = new Date(vacancy.startHour);
    if (dateNowLess5min > startHour) {
      setCheckinAllowed(true);
    } else {
      setCheckinAllowed(false);
    }
  }, []);

  return (
    <ModalContainer w="400px" align="flex-start">
      {/* <CheckinAlert vacancy={vacancy} /> */}
      <ChooseUserInCharge
        userInCharge={userInCharge}
        setUserInCharge={setUserInCharge}
      />
      <Divider />
      <Label>Médico</Label>
      {pendingDoctor && (
        <HStack>
          <Text color="gray.600" fontWeight="semibold">
            {pendingDoctor.name}
          </Text>
          <Text color="var(--orange)" fontWeight="semibold">
            (Médico Pendente)
          </Text>
        </HStack>
      )}
      {doctor.name &&
        ((
          <Text color="gray.600" fontWeight="semibold">
            {doctor.name}
          </Text>
        ) ||
          (vacancy.doctor && vacancy.doctor.user.name) || (
            <Text color="gray.400" fontWeight="semibold">
              Nenhum escolhido
            </Text>
          ))}
      {!pendingDoctor && !doctor.name && (
        <Text color="gray.400" fontWeight="semibold">
          Nenhum escolhido
        </Text>
      )}
      {/* {!vacancy.checkin && ( */}
      <HStack>
        <LinkButton
          title="escolher médico"
          onClick={openChooseDoctor}
          svg={DoctorSvg}
        />
        {(doctor.name || pendingDoctor) && (
          <LinkButton
            title="remover médico"
            color="var(--red)"
            onClick={() => {
              setDoctor('Nenhum');
              setPendingDoctor(null);
            }}
            svg={RemoveDoctorSvg}
          />
        )}
      </HStack>
      {/* )} */}
      <PendingDoctorUrl w="100%" hash={pendingDoctor?.hash} />
      <ICheck
        label="Médico Vinculado à Empresa"
        checked={belongsMyTeam}
        onChange={() => setBelongsMyTeam(!belongsMyTeam)}
      />
      <Divider />
      <ChooseValue
        checkinAllowed={checkinAllowed}
        differentiatedValue={differentiatedValue}
        disableChooseWorkedHours={disableChooseWorkedHours}
        setDifferentiatedValue={setDifferentiatedValue}
        disableChooseValue={disableChooseValue}
        setDisableChooseValue={setDisableChooseValue}
        value={value}
        defaultValue={vacancy.value}
        setValue={setValue}
        setLoading={setLoading}
        vacancy={vacancy}
      />
      <Divider />
      <ChooseWorkedHours
        value={value}
        checkinAllowed={checkinAllowed}
        disableChooseWorkedHours={disableChooseWorkedHours}
        setDisableChooseWorkedHours={setDisableChooseWorkedHours}
        differentiatedValue={differentiatedValue}
        setLoading={setLoading}
        vacancy={vacancy}
      />

      {vacancy.type !== 'cover' && (
        <>
          <Divider />
          <ChooseType type={type} setType={setType} />
        </>
      )}
      <Divider />
      <Label>Tipo de Pagamento</Label>
      <ICheck
        label="Pagamento Será Feito à Vista 👁️"
        checked={inCash}
        onChange={() => setInCash(!inCash)}
        isDisabled={disableEditOptions || requestAntecipe}
      />
      <ICheck
        label="Antecipação Solicitada ➡️ "
        checked={requestAntecipe}
        onChange={() => setRequestAntecipe(!requestAntecipe)}
        isDisabled={disableEditOptions || (inCash && !requestIsPaid)}
        // isDisabled={true}
      />
      <ICheck
        label="Antecipação Paga ✅"
        checked={requestIsPaid}
        // onChange={() => setRequestIsPaid(!requestIsPaid)}
        // isDisabled={disableEditOptions || inCash}
        isDisabled={true}
      />
      <ICheck
        label="Valor Diferenciado 💲"
        checked={differentiatedValue}
        onChange={() => setDifferentiatedValue(!differentiatedValue)}
        isDisabled={disableEditOptions}
      />
      {/* <ICheck
        label="Desabilitar Aprovação Automática"
        checked={canAutoApprove}
        onChange={() => setCanAutoApprove(!canAutoApprove)}
        isDisabled={disableEditOptions}
      /> */}
      <div style={{ marginTop: '15px' }}>
        <Button
          title={disableEditOptions ? 'Editar Opções' : 'Salvar Opções'}
          variant={disableEditOptions ? 'secondary' : 'success'}
          onClick={() => {
            if (disableEditOptions) {
              setDisableEditOptions(false);
            } else {
              submitOptions();
            }
          }}
        />
      </div>
      <Divider />
      {/* {!vacancy.checkin && ( */}
      <Flex w="100%" mt={7} justify="center">
        {type === 'exception' && (
          <>
            <Button
              title={`somente ${moment(new Date(vacancy.startHour)).format(
                'DD/MM/YYYY'
              )}`}
              variant="warning"
              onClick={() => {
                setFinalDate(
                  moment(new Date(vacancy.startHour)).format('DD/MM/YYYY')
                );
                setConfirmException.toggle();
              }}
            />
            <Alert
              isOpen={confirmException}
              onClose={setConfirmException.toggle}
              onConfirm={() => {
                setConfirmException.toggle();
                submit();
              }}
              header="Editar vaga"
            />
          </>
        )}
        {type === 'cover' && (
          <>
            <Button
              title={`somente ${moment(new Date(vacancy.startHour)).format(
                'DD/MM/YYYY'
              )}`}
              onClick={() => {
                setFinalDate(
                  moment(new Date(vacancy.startHour)).format('DD/MM/YYYY')
                );
                setConfirmException.toggle();
              }}
            />
            <Alert
              isOpen={confirmException}
              onClose={setConfirmException.toggle}
              onConfirm={() => {
                setConfirmException.toggle();
                submit();
              }}
              header="Editar vaga"
            />
          </>
        )}
        {['weekly', 'biweekly'].includes(type) && (
          <ButtonGroup w="100%" spacing={3} justifyContent="space-between">
            <Button
              title="editar até fim da escala"
              variant="success"
              onClick={setConfirmToEnd.toggle}
            />
            <Alert
              isOpen={confirmToEnd}
              onClose={setConfirmToEnd.toggle}
              onConfirm={() => {
                setConfirmToEnd.toggle();
                submit(true);
              }}
              header="Editar vaga"
              body="Editar todas as vagas até o fim da escala?"
            />
            <Button
              title="editar até data específica"
              onClick={setConfirmToDate.toggle}
            />
            <Alert
              isOpen={confirmToDate}
              onClose={setConfirmToDate.toggle}
              onConfirm={() => {
                if (finalDate.length < 10) {
                  return toast.error('Por favor insira uma data final válida.');
                }

                const formattedFinalDate = finalDate
                  .split('/')
                  .reverse()
                  .join('-');

                const diff = moment(new Date(vacancy.startHour)).diff(
                  moment(new Date(formattedFinalDate)),
                  'days'
                );

                if (diff > 0) {
                  return toast.error(
                    'Data de edição não pode ser menor que a data atual da vaga.'
                  );
                }

                setConfirmToDate.toggle();
                submit();
              }}
              header="Editar vaga"
              body={
                <IText
                  label="editar plantões até"
                  placeholder="Ex: 13/09/2021"
                  useCleave
                  options={{
                    date: true,
                    delimiter: '/',
                    datePattern: ['d', 'm', 'Y'],
                  }}
                  style={{ width: '330px' }}
                  value={finalDate}
                  onChange={e => setFinalDate(e.target.value)}
                />
              }
            />
          </ButtonGroup>
        )}
      </Flex>
      {/* )} */}
      <Modal
        title="Escolher Médico"
        ref={chooseDoctorModalRef}
        component={ChooseDoctor}
        vacancy={vacancy}
        setDoctor={setDoctor}
        setPendingDoctor={setPendingDoctor}
        setBelongsMyTeam={setBelongsMyTeam}
      />
      <ProgressModal
        loading={loading}
        text="Editando plantões... Por favor aguarde, isso pode demorar alguns minutos."
      />
      <Modal
        title="Confirmar Alteração"
        ref={forceEditModalRef}
        component={ForceEdit}
        data={dataVacancyError}
        onSubmit={() => submit(isToEnd, true)}
        closeFatherModal={closeModal}
      />
    </ModalContainer>
  );
}
