import { getUser } from './auth';
import { getParam } from '~/utils/url';

/**
 *
 * DONT DELETE !!!
 *
 * COMMENTED ARE ALREADY DONE
 * TODO UNCOMMENTED
 */

const companyPermissions = [
  // {
  //   id: 20,
  //   name: 'Receber Email de Novo Documento Inserido Pelo Médico',
  // },
  // {
  //   id: 19,
  //   name: 'Receber Notificação de Novo documento Inserido Pelo Médico',
  // },
  // {
  //   id: 18,
  //   name: 'Receber Email de Novo Médico Vinculado',
  // },
  // {
  //   id: 17,
  //   name: 'Receber Notificação De novo Médico Vinculado',
  // },
  // {
  //   id: 16,
  //   name: 'Editar Perfil da Empresa',
  // },
  // {
  //   id: 15,
  //   name: 'Visualizar Perfil da Empresa',
  // },
  // {
  //   id: 14,
  //   name: 'Visualizar Relatório',
  // },
  // {
  //   id: 13,
  //   name: 'Visualizar Controle de Gastos',
  // },
  // {
  //   id: 12,
  //   name: 'Excluir Usuário',
  // },
  // {
  //   id: 11,
  //   name: 'Editar Usuário',
  // },
  // {
  //   id: 10,
  //   name: 'Adicionar Usuários',
  // },
  // {
  //   id: 9,
  //   name: 'Visualizar Usuários',
  // },
  // {
  //   id: 8,
  //   name: 'Remover Médicos',
  // },
  // {
  //   id: 7,
  //   name: 'Adicionar Médico',
  // },
  // {
  //   id: 6,
  //   name: 'Visualizar Meus Médicos',
  // },
  // {
  //   id: 5,
  //   name: 'Remover Meus Hospitais',
  // },
  // {
  //   id: 4,
  //   name: 'Editar Meus Hospitais',
  // },
  // {
  //   id: 3,
  //   name: 'Adicionar Meus Hospitais',
  // },
  // {
  //   id: 2,
  //   name: 'Visualizar Meus Hospitais',
  // },
  // {
  //   id: 1,
  //   name: 'Visualizar Dashboard',
  // },
];

const hospitalPermissions = [
  {
    id: 26,
    name: 'Editar Escala',
  },
  // {
  //   id: 25,
  //   name: 'Receber Notificação de Vaga Aberta 1 Dia Antes',
  // },
  // {
  //   id: 24,
  //   name: 'Receber Email de Vaga Aberta 1 Dia Antes',
  // },
  // {
  //   id: 23,
  //   name: 'Receber Notificação de Faulta Médica',
  // },
  // {
  //   id: 22,
  //   name: 'Receber Email de Faulta Médica',
  // },
  // {
  //   id: 21,
  //   name: 'Receber Noificação de Faulta de Checkout',
  // },
  // {
  //   id: 20,
  //   name: 'Receber Email de Faulta de Checkout',
  // },
  // {
  //   id: 19,
  //   name: 'Receber Notificação de falta de Checkin',
  // },
  // {
  //   id: 18,
  //   name: 'Receber Email de falta de Checkin',
  // },
  // {
  //   id: 17,
  //   name: 'Apagar Protocolos',
  // },
  // {
  //   id: 16,
  //   name: 'Editar Protocolos',
  // },
  // {
  //   id: 15,
  //   name: 'Criar Protocolos',
  // },
  // {
  //   id: 14,
  //   name: 'Visualizar Protocolos',
  // },
  // {
  //   id: 13,
  //   name: 'Editar Justificativa',
  // },
  // {
  //   id: 12,
  //   name: 'Criar Justificativa',
  // },
  {
    id: 11,
    name: 'Deletar Vaga',
  },
  {
    id: 10,
    name: 'Solitar edição do Valor do Plantão',
  },
  // {
  //   id: 9,
  //   name: 'Editar Valor do Plantão',
  // },
  // {
  //   id: 8,
  //   name: 'Editar Vaga',
  // },
  // {
  //   id: 7,
  //   name: 'Criar Escala',
  // },
  // {
  //   id: 6,
  //   name: 'Visualizar Escala',
  // },
  // {
  //   id: 5,
  //   name: 'Desativar Contrato',
  // },
  // {
  //   id: 4,
  //   name: 'Editar Contrato',
  // },
  // {
  //   id: 3,
  //   name: 'Criar Contrato',
  // },
  // {
  //   id: 2,
  //   name: 'Visualizar Contratos',
  // },
  // {
  //   id: 1,
  //   name: 'Visualizar Dashboard',
  // },
];

const isUserAllowed = (permissionId, type) => {
  const user = getUser();
  const { hospitalId } = getParam('hospitalId');

  if (user.allPermissions) {
    return true;
  }

  if (type === 'hospitalPermissions') {
    const id = +hospitalId;

    const hospital = user.hospitalPermissions.find(hospital => {
      return hospital.id === id;
    });

    if (hospital.permissions.map(p => p.id).includes(permissionId)) {
      return true;
    }
  }

  if (
    type === 'companyPermissions' &&
    user[type] &&
    user[type].map(p => p.id).includes(permissionId)
  ) {
    return true;
  }

  return false;
};

export default isUserAllowed;
