import React, { useState } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import capitalizeString from '~/utils/capitalizeString';

import { Button } from '~/components/ChakraComponents/';
import { Text, Row, Div } from '~/components/Kiwistrap/Page';
import { InputSpecialties, Input } from '~/components/Kiwistrap/Inputs';

import InputSelect from '~/components/Site/Inputs/InputSelect';

import ufArr from './ufArr';

import { ResponsivePhaseContainer } from '../../styles';

import { NameInput, DoctorNameCard } from './styles';

const alerts = {
  invalidSpecialty: () => {
    toast.error('Por favor insira uma especialidade válida');
  },
  noCrm: () => {
    toast.error('Por favor insira seu crm.');
  },
  invalidCrm: () => {
    toast.error('CFM: Crm inválido. Por favor verifique e tente novamente');
  },
  noUf: () => {
    toast.error('Por favor selecione o UF do seu crm.');
  },
  notFound: () => {
    toast.error('Crm não encontrado.');
  },
  cfmIsDown: () => {
    toast.error('Houve um erro com os servidores do CFM. Tente novamente.');
  },
  invalidSituation: () => {
    toast.error('Cadastro não permitido, situação atual do médico inválida.');
  },
};

export default function Phase2({ handlePhaseSubmit }) {
  const [inputs, setInputs] = useState({});
  const [verifying, setVerifying] = useState(false);
  const [doctorName, setDoctorName] = useState();
  const [submitData, setSubmitData] = useState();
  const [completeValidation, setCompleteValidation] = useState(false);

  const updateInputsSpecialty = id => {
    setInputs({ ...inputs, specialty: id });
  };

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setVerifying(true);

    const { specialty, crm, crmUf } = inputs;

    if (!specialty) {
      setVerifying(false);
      return alerts.invalidSpecialty();
    }

    if (!crm) {
      setVerifying(false);
      return alerts.noCrm();
    }

    if (crm.length < 4 || crm.length > 10) {
      setVerifying(false);
      return alerts.invalidCrm();
    }

    if (!crmUf || crmUf.length !== 2) {
      setVerifying(false);
      return alerts.noUf();
    }

    const url = `/checkcrm?crm=${crm}&uf=${crmUf}`;

    let doctorData;

    try {
      const response = await api.get(url);
      doctorData = response.data.dadosMedico;
    } catch (error) {
      setVerifying(false);

      if (error.response && error.response.data.error === 'not found') {
        return alerts.notFound();
      }

      return alerts.cfmIsDown();
    }

    const { nome, situacao: situation, tipoInscricao } = doctorData;

    const name = capitalizeString(nome);

    setDoctorName(name);

    if (
      situation === 'E' ||
      situation === 'F' ||
      situation === 'G' ||
      situation === 'I' ||
      situation === 'J' ||
      situation === 'L' ||
      situation === 'M' ||
      situation === 'N' ||
      situation === 'O' ||
      situation === 'S'
    ) {
      return alerts.invalidSituation();
    }

    if (tipoInscricao === 'E') {
      return toast.error(
        'Cadastro não permitido, tipo de inscrição do médico inválida.'
      );
    }

    setVerifying(false);
    setCompleteValidation(true);

    setSubmitData({ ...inputs, name, situation });
  };

  return (
    <ResponsivePhaseContainer>
      <Text padding="0 0 20px" style={{ textAlign: 'center' }}>
        Seu CRM será válidado pelo sistema do CFM.
      </Text>
      <InputSpecialties setSpecialtyId={updateInputsSpecialty} />
      <Row>
        <Input
          required
          type="number"
          title="crm"
          name="crm"
          placeholder="Ex: 1928708"
          onChange={handleChanges}
        />
        <Div margin="0 0 0 20px">
          <NameInput>CRM - UF</NameInput>
          <InputSelect
            required
            name="crmUf"
            type="select"
            options={ufArr}
            onChange={handleChanges}
          />
        </Div>
      </Row>
      {doctorName && (
        <DoctorNameCard>
          <Text color="var(--dark-gray)">{`Nome: ${doctorName}`}</Text>
        </DoctorNameCard>
      )}
      {completeValidation ? (
        <Button
          title="confirmo que sou esse médico"
          variant="success"
          width="100%"
          margin="12px 0 0"
          onClick={() => handlePhaseSubmit(submitData)}
        />
      ) : (
        <Button
          title={verifying ? 'verificando...' : 'validar crm'}
          width="100%"
          margin="12px 0 0"
          onClick={handleSubmit}
        />
      )}
    </ResponsivePhaseContainer>
  );
}
