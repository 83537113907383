import styled from 'styled-components';
import {
  AiOutlineCloseCircle,
  AiOutlineCheckCircle,
  AiOutlineUpload,
} from 'react-icons/ai';

export const ResponsiveContainer = styled.div`
  width: 500px;

  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100vw;
  }
`;

export const DocumentCard = styled.div`
  cursor: pointer;

  width: 100%;

  margin: 5px 0;
  padding: 4px 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 4px;

  box-shadow: var(--shadow);
  background-color: var(--background-alt);
`;

export const NotConfimed = styled(AiOutlineCloseCircle)`
  margin-right: 20px;

  font-size: 25px;

  color: var(--red);
`;

export const Confirmed = styled(AiOutlineCheckCircle)`
  margin-right: 20px;

  font-size: 25px;

  color: var(--green);
`;

export const Upload = styled(AiOutlineUpload)`
  font-size: 25px;

  color: var(--dark-gray);
`;
