import React, { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Text } from '@chakra-ui/react';

import api from '~/services/api';

import PageLayout from '~/pages/_layouts/doctor/PageLayout';

import { Row } from '~/components/Kiwistrap/Page';
import { Modal } from '~/components/UI/Modal';

import Company from './components/Company';
import VinculateModal from './components/VinculateModal';

import { Option } from './styles';

const internalServerError =
  'Houve um problema com os nossos servidores. Tente novamente.';

export default function MyCompanies() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingInfo, setloadingInfo] = useState(true);
  const [showPendingHospitals, setShowPendingHospitals] = useState(false);
  const [hospitals, setHospitals] = useState();
  const [companies, setCompanies] = useState();

  const vinculateModalRef = useRef(null);

  const openVinculate = useCallback(() => {
    vinculateModalRef.current.openModal();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/doctors/myhospitals/v2');

        const companiesArr = [];

        response.data.data.forEach(hospital => {
          const isIncluded = companiesArr.find(
            company => company.id === hospital.company.id
          );

          if (!isIncluded) {
            companiesArr.push({ ...hospital.company });
          }
        });

        setHospitals(response.data.data);
        setCompanies(companiesArr);
      } catch (error) {
        toast.error(internalServerError);
      }

      setLoadingPage(false);
      setloadingInfo(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setloadingInfo(true);

      try {
        let response;

        if (showPendingHospitals) {
          const pendingHospitals = await api.get('/doctors/hospitals/pending');

          response = pendingHospitals.data.data;
        } else {
          const myHospitals = await api.get('/doctors/myhospitals/v2');

          response = myHospitals.data.data;
        }

        const companiesArr = [];

        response.forEach(hospital => {
          if (showPendingHospitals) {
            hospital.company.isPending = true;
          }

          const isIncluded = companiesArr.find(
            company => company.id === hospital.company.id
          );

          if (!isIncluded) {
            companiesArr.push({ ...hospital.company });
          }
        });

        setHospitals(response);
        setCompanies(companiesArr);
      } catch (error) {
        toast.error(internalServerError);
      }

      setloadingInfo(false);
    })();
  }, [showPendingHospitals]);

  return (
    <PageLayout loading={loadingPage}>
      {/* <Row margin="0 0 20px">
        <Option
          isActive={!showPendingHospitals}
          onClick={() => setShowPendingHospitals(false)}
        >
          Minhas Empresas
        </Option>
        <Option
          isActive={showPendingHospitals}
          onClick={() => setShowPendingHospitals(true)}
        >
          Empresas Pendentes
        </Option>
        <Option onClick={openVinculate}>Vincular-se à Hospitais</Option>
      </Row> */}
      {!loadingInfo && companies && companies.length > 0 ? (
        <>
          <Text size="16px">
            Listando empresas e seus respectivos hospitais
          </Text>
          {companies.map(company => (
            <Company key={company.id} company={company} hospitals={hospitals} />
          ))}
        </>
      ) : (
        <Text size="16px">Nenhuma empresa encontrada.</Text>
      )}
      {/* <Modal
        title="Vincular-se à Hospitais"
        ref={vinculateModalRef}
        component={VinculateModal}
      /> */}
    </PageLayout>
  );
}
