import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background);
  width: 100%;
  height: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 386px;
  background-color: var(--background-alt);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);

  @media (min-width: 300px) and (max-width: 768px) {
    width: 320px;
  }
`;

export const FormControl = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 295px;
    height: 78px;
  }
`;

export const SpanEmail = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const SpanPassword = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);

  @media (min-width: 375px) and (max-width: 414px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const TitleLogin = styled.span`
  font-family: Montserrat;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: var(--black);
  padding: 20px 0 20px 0;
`;

export const SectionForgot = styled.div`
  display: flex;
  width: 500px;
  justify-content: flex-end;

  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 10px;
    width: 250px;
  }
`;

export const ForgotPass = styled(Link)`
  color: var(--primary);
  border-bottom: 1px solid var(--primary);

  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const SectionBtn = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const BtnLogin = styled.button`
  width: 260px;
  height: 48px;
  background-color: var(--primary);
  border-radius: var(--br-sm);
  margin: 20px 5px 10px 5px;

  color: var(--background-alt);
  text-transform: uppercase;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  background-color: var(--dark-gray);

  @media (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
`;

export const SectionUteis = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 500px) {
    padding: 0px;
    margin: 0px;
  }

  @media screen and (min-width: 601px) {
    padding: 0px 20px;
  }
`;

export const SectionList = styled.div`
  display: flex;
  margin-right: 30px;

  @media screen and (min-width: 501px) {
    display: block;
    margin-right: 100px;
    margin-left: 5px;
  }
  @media screen and (min-width: 748px) {
    display: block;
    margin-right: 15px;
    margin-left: 0px;
  }
  @media screen and (min-width: 1020px) {
    display: flex;
    margin-right: 75px;
  }
`;

export const TitleUteis = styled.span`
  font-family: Montserrat;
  margin-right: 15px;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--background);

  @media screen and (min-width: 500px) {
    font-size: 14px;
    text-align: left;
    padding-left: 5px;
    width: 100px;
  }

  @media screen and (min-width: 748px) {
    font-size: 15px;
    text-align: left;
    padding-left: 5px;
    width: 100px;
  }

  @media screen and (min-width: 1020px) {
    font-size: 22px;
    line-height: 1.23;
    width: 135px;
  }
`;

export const ListAll = styled.ul`
  display: flex;
  align-items: center;
  text-decoration: none;

  @media screen and (min-width: 501px) {
    width: 150px;
    display: -webkit-box;
  }

  @media screen and (min-width: 748px) {
    width: 300px;
    padding-top: 0px;
  }

  @media screen and (min-width: 1020px) {
    width: 260px;
    padding-top: 0px;
  }
`;

export const ListItem = styled.li`
  display: flex;
  padding: 0 5px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--background);

  @media screen and (min-width: 501px) {
    font-size: 10px;
  }

  @media screen and (min-width: 601px) {
    font-size: 11px;
  }

  @media screen and (min-width: 1020px) {
    font-size: 14px;
  }
`;

export const SectionCopyright = styled.div`
  display: flex;
  padding-right: 50px;

  @media screen and (min-width: 500px) {
    width: 235px;
    margin-right: -60px;
  }

  @media screen and (min-width: 601px) {
    width: 245px;
    margin-right: 3px;
  }

  @media screen and (min-width: 1020px) {
    width: 460px;
  }
`;

export const TitleCopyright = styled.span`
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--background);

  @media screen and (min-width: 501px) {
    width: 150px;
    font-size: 10px;
  }

  @media screen and (min-width: 748px) {
    width: 178px;
    font-size: 12px;
  }

  @media screen and (min-width: 1020px) {
    font-size: 15px;
    width: 100%;
  }
`;

export const SectionSociais = styled.div`
  display: flex;

  svg {
    margin-right: 40px;
    font-size: 30px;

    @media screen and (min-width: 501px) {
      margin-right: 20px;
      font-size: 22px;
    }

    @media screen and (min-width: 748px) {
      margin-right: 25px;
      font-size: 26px;
    }

    @media screen and (min-width: 1020px) {
      margin-right: 40px;
      font-size: 30px;
    }
  }
`;

export const FooterMobile = styled.div`
  display: none;

  @media (min-width: 300px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: var(--dark-gray);
  }
`;

export const TitleSociais = styled.span`
  @media (min-width: 375px) {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: var(--background);
    margin-bottom: 10px;
  }

  @media (min-width: 320px) {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: var(--background);
    margin-bottom: 10px;
  }
`;
