import React from 'react';

import { Flex, Spinner as ChakraSpinner } from '@chakra-ui/react';

const Spinner = () => (
  <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
    <ChakraSpinner size="xl" color="var(--primary)" />
  </Flex>
);

export default React.memo(Spinner);
