import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { IText } from '~/components/UI/Inputs';

import { Button } from '~/components/ChakraComponents/';
import { Div, Row } from '~/components/Kiwistrap/Page';

export default function ChooseDateModal({ candidature, handleAdd, onClose }) {
  const [endDate, setEndDate] = useState();

  const handleSubmit = () => {
    if (!endDate || endDate.length < 10) {
      return toast.error('Por favor insira uma data final válida.');
    }

    return handleAdd(
      candidature,
      endDate
        .split('/')
        .reverse()
        .join('-')
    );
  };

  return (
    <Div width="400px" alignItems="center">
      <IText
        label="aceitar candidatura até"
        useCleave
        options={{
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        }}
        style={{ width: '330px' }}
        value={endDate}
        onChange={e => setEndDate(e.target.value)}
      />
      <Row margin="20px 0 0" justifyContent="space-evenly">
        <Button title="voltar" variant="secondary" onClick={onClose} />
        <Button title="confirmar" variant="success" onClick={handleSubmit} />
      </Row>
    </Div>
  );
}
