import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

import { Button } from '~/components/ChakraComponents/';

export const InputSearch = styled.input`
  width: 100%;
  height: 40px;

  padding: 5px;
  margin-top: 5px;

  border-radius: var(--br-sm);
  border: 2px solid var(--black);
`;

export const InputLabel = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  color: var(--primary);
`;

export default function FindUserInput({ setName }) {
  const [text, setText] = useState('');

  return (
    <Flex alignItems="flex-end">
      <Flex w="100%" flexDir="column">
        <InputLabel>buscar usuário</InputLabel>
        <InputSearch
          autoFocus
          placeholder="Ex: Cláudio Duarte"
          value={text}
          onChange={e => setText(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              setText(text);
            }
          }}
        />
      </Flex>
      <Button
        width="auto"
        height="40px"
        margin="0 0 0 10px"
        icon2={<FaSearch size={24} />}
        onClick={() => setName(text)}
      />
    </Flex>
  );
}
