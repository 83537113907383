import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--background-alt);
`;

export const Nav = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ffffff;
  padding: 6px 12px;

  @media (max-width: 1080px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1120px;
  height: 100%;
  width: 100%;
  background-color: var(--background-alt);
  margin: 0 auto;

  @media (max-width: 1080px) {
    padding: 10px 20px;
  }
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  width: 200px;
`;

export const ImgLogo = styled.img`
  width: 150px;
  /* height: 50px; */

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 150px;
  }
`;

export const ListMenu = styled.ul`
  display: flex;
  padding: 0;
`;

export const ListItem = styled.li`
  a {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--dark-gray);
    padding: 20px;
  }
`;

export const ListBtns = styled.div`
  display: flex;
  padding: 10px;

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 300px;
  }
`;

export const BtnCadastrar = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 48px;
  border-radius: var(--br-sm);
  background-color: var(--background-alt);
  font-weight: bold;
  color: var(--primary);
  border: 2px solid var(--primary);
  font-size: 16px;
  margin-right: 1rem;
  box-shadow: rgb(0 0 0 / 10%) 8px 12px 12px;
  transition: all 0.2s;

  &:hover {
    filter: brightness(0.95);
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 20px;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 150px;
    font-size: 14px;
  }
`;

export const BtnEntrar = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 48px;
  border-radius: var(--br-sm);
  background-color: var(--primary);
  font-weight: bold;
  color: var(--background-alt);
  font-size: 16px;
  box-shadow: rgb(0 0 0 / 10%) 8px 12px 12px;
  transition: all 0.2s;

  &:hover {
    filter: brightness(0.95);
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 20px;
  }

  @media (min-width: 760px) and (max-width: 1200px) {
    width: 150px;
    font-size: 14px;
  }
`;

export const NavMobile = styled.div`
  display: none;

  @media (max-width: 1080px) {
    display: block;
  }
`;

export const ContentMobile = styled.div`
  display: flex;
  align-items: center;
`;

export const ListMenuMobile = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;

  @media (max-width: 320px) {
    margin-top: 120px;
    margin-bottom: -114px;
  }
`;

export const ListItemMobile = styled.li`
  padding: 20px;

  a {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary);
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 20px;
    a {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: var(--primary);
      padding: 20px;
    }
  }
`;

export const Spacing = styled.div`
  height: ${props =>
    props.size === 375 || props.size === 414 || props.size === 768
      ? '92px'
      : '0px'};
`;

const openModal = keyframes`
  100% { right: 0%; }
`;

export const Modal = styled.div`
  display: flex !important;
  height: 100vh;
  background-color: #00000050;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  justify-content: flex-end;

  > div {
    animation: ${openModal} 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    right: -60%;
    width: 60%;
    height: 100%;
    overflow: hidden;
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    > span {
      position: absolute;
      top: 7rem;
      left: 3rem;
      font-size: 2.5rem;
      font-weight: 800;
    }
    a {
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export const Sign = styled.button`
  background-color: var(--blue-300);
  color: var(--background);
  width: 90%;
  margin: 0 5%;
  box-shadow: none;
  justify-content: left;
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: var(--br-sm);
  font-weight: 900 !important;
  font-size: 16px !important;
  padding-left: 30px;
  text-transform: uppercase;
`;
