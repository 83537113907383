import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { Doughnut } from 'react-chartjs-2';

import api from '~/services/api';

import { SubTitle, Title } from '~/components/Kiwistrap/Page';

import options from './options';

export default function HourDoughnut({ year, month }) {
  const [graphData, setGraphData] = useState();
  const [totalHours, setTotalHours] = useState();

  useEffect(() => {
    (async () => {
      const url = `/doctors/reports/myhours/graph?month=${month}&year=${year}`;

      let hoursData;

      const response = await api.get(url);
      hoursData = response.data.total;

      let seconds = Math.abs(hoursData);

      const hours = Math.floor(seconds / 3600);

      seconds -= hours * 3600;

      const minutes = Math.floor(seconds / 60);

      let formattedHours = String(hours);
      let formattedMinutes = String(minutes);

      if (hours < 10) {
        formattedHours = `0${hours}`;
      }

      if (minutes < 10) {
        formattedMinutes = `0${minutes}`;
      }

      setTotalHours(`${formattedHours}:${formattedMinutes}h`);

      const totalCalculation = Math.abs(Math.floor(hoursData / 3600));
      const hoursInMonth = 720;

      const workedHours =
        totalCalculation <= hoursInMonth
          ? Math.floor(totalCalculation)
          : Math.floor(hoursInMonth);

      const remainingHours = Math.floor(hoursInMonth - workedHours);

      // Final react-chartjs-2 object
      const graphDataObj = {
        labels: ['Horas totais', 'Horas restantes no mês'],
        datasets: [
          {
            data: [workedHours, remainingHours],
            backgroundColor: ['#fd6e36', '#f3f3f3'],
          },
        ],
      };

      return setGraphData(graphDataObj);
    })();
  }, [month, year]);

  return (
    <Flex
      flexDir="column"
      mx="20px"
      p="20px 20px 10px"
      borderRadius="4px"
      boxShadow="var(--shadow)"
      backgroundColor="var(--background-alt)"
    >
      <SubTitle padding="0 0 20px">Horas totais trabalhadas</SubTitle>
      <Flex flexDir="column" h="260px">
        <Doughnut data={graphData} options={options} />
      </Flex>
      <Title center margin="10px 0 0" color="var(--light-gray)" size={24}>
        {totalHours}
      </Title>
    </Flex>
  );
}
