import React from 'react';

import { H1 } from './styles';

export default function Title({
  size,
  color,
  width,
  center,
  margin,
  style,
  children,
}) {
  return (
    <H1
      size={size}
      color={color}
      width={width}
      textAlign={center}
      margin={margin}
      style={style}
    >
      {children}
    </H1>
  );
}
