export default function getJsonQueryParams(stringQueryParams = '') {
  return stringQueryParams
    .slice(1)
    .split('&')
    .reduce((acummulator, oneQuery) => {
      const [key, value] = oneQuery.split('=');

      const result = { ...acummulator };

      result[key] = value;

      return result;
    }, {});
}
