import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  justify-self: flex-end;
  flex: 1;
`;

export const Results = styled.div`
  box-sizing: border-box;
  width: 400px;
  position: absolute;
  margin-top: 230px;
  z-index: 10;
  display: grid;
  grid-template-rows: 40px;
  align-items: center;
  justify-items: center;
  background-color: var(--background);
  border: 2px solid var(--black);
  border-radius: var(--br-sm);
  overflow: hidden;
`;

export const Result = styled.span`
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: left;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
  color: var(--dark-gray);
  background-color: var(--background);

  &:nth-child(odd) {
    background-color: rgba(45, 183, 248, 0.1);
  }
`;

export const ContainerEspecialidades = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  color: var(--primary);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;

  p {
    margin-bottom: 10px;
  }

  input {
    max-width: 400px;
    margin-bottom: 20px;
  }
`;

export const ListContainer = styled.div`
  margin-top: 20px;
  margin-left: -40px;
`;

export const DocumentList = styled.ul`
  color: var(--light-gray);
  font-size: 15px;
  width: 100%;
`;

export const ItemList = styled.li`
  display: flex;
  width: 100%;
  background-color: rgba(45, 183, 248, 0.1);
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Icon = styled.img`
  margin-top: -5px;
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 0px 10px;
`;

export const NameLink = styled.a`
  text-decoration: none;
  color: var(--light-gray);
`;
