import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Nav } from '~/components/UI/Sections/MobileVacancyAgenda';

import { MobileContainer } from '~/components/UI/Sections/MobileVacancyAgenda';

export default function noneChoosenMobile() {
  if (window.screen.width > 1023) {
    return null;
  }

  return (
    <MobileContainer>
      <Flex
        w="100%"
        h="100%"
        pb="80px"
        mt={4}
        align="center"
        justify="center"
        textAlign="center"
      >
        Por favor selecione uma opção
      </Flex>
      <Nav />
    </MobileContainer>
  );
}
