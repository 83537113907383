import styled from 'styled-components';

export const InputLabel = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  color: var(--primary);
`;

export const InputSearch = styled.input`
  width: 400px;
  height: 40px;
  border: 2px solid var(--black);
  border-radius: var(--br-sm);
  margin: 5px 0 5px 0;
  padding: 5px;
`;
