import styled from 'styled-components';

export const Container = styled.div`
  width: 400px;
`;

export const WarningText = styled.div`
  width: 100%;
  margin-bottom: 20px;

  color: var(--red);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`;
