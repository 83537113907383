import React from 'react';

import { Container, Title } from './styles';

export default function BtnPrimary({ title, type, to, styles }) {
  return (
    <Container type={type} to={to} styles={styles}>
      <Title>{title}</Title>
    </Container>
  );
}
