import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { SubTitle } from '~/components/Kiwistrap/Page';

import { SectionTable, OpenSvg, CloseSvg, Table, Tr, Th, Td } from './styles';

export default function Info({ data }) {
  const [showInfo, setShowInfo] = useState(false);

  const contractText = (name, start, end) =>
    `${name}\n${start.slice(0, -3)}h - ${end.slice(0, -3)}h`;

  const startingHourText = start => `${start.slice(0, -3)}h`;

  const durationText = (start, end) =>
    `${end.slice(0, -6) - start.slice(0, -6)}h`;

  const availabilityText = availability => {
    if (availability === 'public') return 'Público';
    if (availability === 'linked') return 'Somente Vinculados';
    if (availability === 'private') return 'Fechada';
  };

  const honorariesText = defaultDoctorValue =>
    defaultDoctorValue.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

  return (
    <>
      <Flex h="auto" justify="flex-start" align="center">
        <SubTitle color="var(--primary)" size="16px">
          Informações Gerais
        </SubTitle>
        {showInfo ? (
          <CloseSvg onClick={() => setShowInfo(!showInfo)} />
        ) : (
          <OpenSvg onClick={() => setShowInfo(!showInfo)} />
        )}
      </Flex>
      <SectionTable display={showInfo ? 'table' : 'none'}>
        <Table>
          <Tr>
            <Th>Contrato</Th>
            <Th>Horário Inicial</Th>
            <Th>Duração</Th>
            <Th>Disponibilidade</Th>
            <Th>Honorários Médicos</Th>
          </Tr>
          {data &&
            data.map(schedule => (
              <Tr key={schedule.id}>
                <Td>
                  {contractText(
                    schedule.contract.specialty.name,
                    schedule.contract.start,
                    schedule.contract.end
                  )}
                </Td>
                <Td>{startingHourText(schedule.contract.start)}</Td>
                <Td>
                  {durationText(schedule.contract.start, schedule.contract.end)}
                </Td>
                <Td>{availabilityText(schedule.availability)}</Td>
                <Td>{honorariesText(schedule.defaultDoctorValue)}</Td>
              </Tr>
            ))}
        </Table>
      </SectionTable>
    </>
  );
}
