import { getUser } from '~/services/auth';
import { getParam } from '~/utils/url';

export default () => {
  const { hospitalId } = getParam();

  const user = getUser();

  if (!user) return null;

  let key = user.type;

  if (hospitalId) {
    key += 'hospital';
  }

  return key;
};
