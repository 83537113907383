import styled from 'styled-components';
import { Tr as TrChakra } from '@chakra-ui/react';

export const Tr = styled(TrChakra)`
  cursor: pointer;

  &:nth-child(even) {
    background-color: var(--chakra-colors-blue-200);
  }

  &:hover {
    background-color: var(--chakra-colors-blue-100);
  }
`;

export const TrH = styled(TrChakra)`
  background-color: var(--background-alt);
`;
