import React from 'react';
import moment from 'moment';
import ReactCalendar from 'react-calendar';

export default function Calendar({ getVacancies }) {
  const handleOnChange = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD');

    getVacancies(formattedDate);
  };

  return <ReactCalendar onChange={handleOnChange} calendarType="US" />;
}
