import styled from 'styled-components';

export const PurpleBackground = styled.div`
  width: 100%;

  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 15px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  background: rgb(93, 107, 217);
  background: -moz-linear-gradient(
    144deg,
    rgba(93, 107, 217, 1) 45%,
    rgba(45, 111, 217, 1) 100%
  );
  background: -webkit-linear-gradient(
    144deg,
    rgba(93, 107, 217, 1) 45%,
    rgba(45, 111, 217, 1) 100%
  );
  background: linear-gradient(
    144deg,
    rgba(93, 107, 217, 1) 45%,
    rgba(45, 111, 217, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5d6bd9",endColorstr="#2d6fd9",GradientType=1);
`;

export const DoctorAvatar = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 4px solid var(--secondary);
  background-color: var(--background-alt);
  object-fit: cover;
`;

export const DoctorName = styled.div`
  margin-left: 12px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: var(--background-alt);
  max-width: 297px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const AgendaRow = styled.div`
  width: 100%;

  margin-top: 10px;

  display: flex;
  justify-content: flex-start;

  height: ${props => props.height};
`;

export const Day = styled.div`
  width: 140px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.65;

  border-radius: 4px;

  box-shadow: var(--shadow);
  border-top: 13px solid var(--primary);
  background-color: var(--background-alt);

  color: var(--primary);
`;

export const Vacancies = styled.div`
  width: 100%;

  margin-left: 20px;

  display: grid;
  grid-gap: 5px;
  grid-template-rows: repeat(auto-fill, 70px);
`;
