import moment from 'moment';

import api from '~/services/api';
import userKey from '~/utils/userKey';
import { getParam } from '~/utils/url';

const fetchUrl = (startDate, endDate, selectedContract) => {
  const { hospitalId } = getParam();

  return {
    company: `/companies/myhospitals/companyresume?startDate=${startDate}&endDate=${endDate}`,
    contractor: `/contractors/myhospitals/contractorresume?startDate=${startDate}&endDate=${endDate}`,
    companyhospital: `/companies/myhospitals/${hospitalId}/resume?startDate=${startDate}&endDate=${endDate}&contract=${selectedContract}`,
    contractorhospital: `/contractors/myhospitals/${hospitalId}/resume?startDate=${startDate}&endDate=${endDate}&contract=${selectedContract}`,
  }[userKey()];
};

export const fetchResume = (callback, params) => {
  const { startDate, endDate, schedule, selectedContract } = params;

  let newStartDate = startDate;
  let newEndDate = endDate;

  if (schedule !== 'toDate') {
    newStartDate = moment(new Date())
      .startOf(schedule)
      .format('YYYY-MM-DD');

    newEndDate = moment(new Date())
      .endOf(schedule)
      .format('YYYY-MM-DD');
  }

  const url = fetchUrl(newStartDate, newEndDate, selectedContract);

  api.get(url).then(response => callback(response, newStartDate, newEndDate));
};
