import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';

import api from '~/services/api';

import { Label } from '~/components/UI/Text';

const Input = styled.input`
  width: 100%;
  height: 40px;

  padding: 5px;

  border-radius: var(--br-sm);

  border: 2px solid var(--black);
`;

const Results = styled.div`
  width: 100%;

  position: absolute;
  top: 62px;
  right: 0;
  z-index: 10;

  display: grid;
  align-items: center;
  justify-items: center;

  border-radius: var(--br-sm);

  overflow: hidden;

  border: 2px solid var(--black);
  background-color: var(--background);
`;

const Result = styled.span`
  cursor: pointer;

  width: 100%;
  height: 100%;

  padding: 4px;

  font-weight: 500;

  font-size: 14px;
  text-align: left;

  color: var(--dark-gray);
  background-color: var(--background);

  &:nth-child(odd) {
    background-color: rgba(45, 183, 248, 0.1);
  }
`;

export default function IText({
  required,
  specialtyName = '',
  setSpecialtyId,
  style,
  ...rest
}) {
  const [specialty, setSpecialty] = useState(specialtyName);
  const [specialties, setSpecialties] = useState([]);
  const [openResults, setOpenResults] = useState(false);

  const handleChanges = useCallback(e => {
    setSpecialty(e.target.value);

    api.get(`/allspecialties?name=${e.target.value}&limit=7`).then(response => {
      setSpecialties(response.data);
      setOpenResults(true);
    });
  }, []);

  const resultOnClick = useCallback(
    id => {
      setSpecialtyId(id);

      const input = specialties.filter(spec => {
        return spec.id === id;
      });

      setSpecialty(input[0].name);
      setOpenResults(false);
    },
    [specialties, setSpecialtyId]
  );

  const renderResults = useCallback(() => {
    if (specialties.length === 0) return null;

    return (
      <Results>
        {specialties.map(spec => (
          <Result key={spec.id} onClick={() => resultOnClick(spec.id)}>
            {spec.name}
          </Result>
        ))}
      </Results>
    );
  }, [specialties]);

  return (
    <Flex pos="relative" flexDir="column" w="100%" style={style}>
      <Label>{required ? 'Especialidade *' : 'Especialidade'}</Label>
      <Input
        required={required}
        name="specialty"
        placeholder="Ex: Clínica Médica"
        value={specialty}
        onChange={handleChanges}
        {...rest}
      />
      {openResults && renderResults()}
    </Flex>
  );
}
