import React from 'react';
import { HStack } from '@chakra-ui/react';

import { Label } from '~/components/UI/Text';
import { IRadio } from '~/components/UI/Inputs';

export default function ChooseType({ type, setType }) {
  return (
    <>
      <Label>Periodicidade</Label>
      <HStack>
        <IRadio
          label="Cobertura"
          checked={type === 'exception'}
          onChange={() => setType('exception')}
        />
        <IRadio
          label="Semanal"
          checked={type === 'weekly'}
          onChange={() => setType('weekly')}
        />
        <IRadio
          label="Quinzenal"
          checked={type === 'biweekly'}
          onChange={() => setType('biweekly')}
        />
      </HStack>
    </>
  );
}
