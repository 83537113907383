import styled from 'styled-components';

export const Span = styled.span`
  font-size: ${props => props.size || '14px'};
  font-weight: ${props => props.weight || '500'};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  color: ${props => props.color || 'var(--dark-gray)'};

  width: ${props => props.width || 'auto'};
  padding: ${props => props.padding || '0'};
  margin: ${props => props.margin || '0'};
`;
