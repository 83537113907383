import styled from 'styled-components';

export const Select = styled.select`
  width: 100%;
  height: 40px;

  padding: 5px;

  border-radius: var(--br-sm);

  border: 2px solid var(--black);
  background-color: var(--background-alt);
`;
