import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
`;
export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: var(--background);

  /* border: 1px solid blue; */
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 80px;
  width: 700px;
  height: 386px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--background-alt);

  @media (max-width: 375px) {
    width: 330px;
    padding: 0 40px;
  }
`;

export const NameInput = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--primary);
  margin-bottom: 10px;
`;
export const Title = styled.span`
  font-family: Montserrat;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  text-align: center;
  letter-spacing: normal;
  color: var(--secondary);
  margin-bottom: 20px;

  @media (max-width: 375px) {
    font-size: 20px;
  }
`;
export const Description = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark-gray);
  margin-bottom: 20px;
`;

export const SectionBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;

  @media (max-width: 375px) {
    display: flex;
    width: 100%;
    padding: 20px 0px;
    /* border: 1px solid red; */
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  background-color: var(--dark-gray);

  @media (max-width: 375px) {
    display: none;
  }

  /* border: 1px solid red; */
`;
export const SectionUteis = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 60px; */
  width: 100%;

  /* border: 1px solid red; */
`;

export const SectionList = styled.div`
  display: flex;
  margin-right: 30px;

  @media (min-width: 768px) and (max-width: 800px) {
    display: block;
  }
`;

export const TitleUteis = styled.span`
  font-family: Montserrat;
  font-size: 22px;
  margin-right: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--background);

  @media (min-width: 768px) and (max-width: 800px) {
    font-size: 15px;
    text-align: left;
    padding-left: 20px;
    width: 100px;
  }
`;

export const ListAll = styled.ul`
  display: flex;
  align-items: center;
  text-decoration: none;

  @media (min-width: 768px) and (max-width: 800px) {
    width: 300px;
    padding-top: 10px;
    padding-left: 15px;
  }
`;
export const ListItem = styled.li`
  display: flex;
  padding: 0 5px;

  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--background);
`;
export const SectionCopyright = styled.div`
  display: flex;
  margin-right: 50px;

  /* border: 1px solid blue; */
`;
export const TitleCopyright = styled.span`
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--background);
`;
export const SectionSociais = styled.div`
  display: flex;

  /* border: 1px solid black; */
`;

export const FooterMobile = styled.div`
  display: none;

  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: var(--dark-gray);
  }
`;

export const TitleSociais = styled.span`
  @media (max-width: 375px) {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: var(--background);
    margin-bottom: 10px;
  }
`;
