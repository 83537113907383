import React from 'react';

import { Text } from '~/components/Kiwistrap/Page';
import { InputContainer } from '~/components/Kiwistrap/Inputs';

import { CleaveInput, DefaultInput } from './styles';

export default function Input({
  title,
  titleColor,
  useCleave,
  options, // https://nosir.github.io/cleave.js/ For cleave.js options
  error,
  style,
  children,
  ...rest
}) {
  return (
    <InputContainer title={title} color={titleColor} style={style}>
      {useCleave ? (
        <CleaveInput options={options} {...rest} />
      ) : (
        <DefaultInput {...rest} />
      )}
      {children}
      {error && (
        <Text margin="5px 0 0" color="var(--red)">
          {error}
        </Text>
      )}
    </InputContainer>
  );
}
