import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Text, Divider } from '@chakra-ui/react';

import updateAvatar from '~/utils/api/updateAvatar';
import capitalizeString from '~/utils/capitalizeString';

const UserAvatar = styled.div`
  position: relative;

  width: 150px;
  height: 150px;

  margin-top: 20px;

  border-radius: 50%;

  border: 4px solid var(--primary);

  img {
    width: 100%;
    height: 100%;

    padding: 3px;

    object-fit: cover;

    border-radius: 50%;

    background-color: var(--background-alt);

    flex-shrink: none;
  }

  section {
    display: none;
  }

  &:hover {
    section {
      cursor: pointer;

      position: absolute;
      top: 0;
      z-index: 10;

      width: 100%;
      height: 100%;

      background-color: rgba(0, 0, 0, 0.4);

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 100px;

      font-weight: bold;

      color: var(--background-alt);

      span {
        padding-top: 25%;
      }
    }
  }
`;

const InputFile = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

const UserInfo = React.memo(({ avatar, title, subtitle, avatarUploadUrl }) => {
  const [avatarFile, setAvatarFile] = useState();

  const inputFile = useRef(null);

  useEffect(() => {
    updateAvatar(avatarFile, avatarUploadUrl);
  }, [avatarFile]);

  return (
    <Flex
      w="100%"
      flexDir="column"
      align="center"
      flexShrink="none"
      textAlign="center"
      fontWeight="semibold"
    >
      <UserAvatar onClick={() => inputFile.current.click()}>
        <InputFile
          ref={inputFile}
          onChange={e => setAvatarFile(e.target.files[0])}
        />
        <img src={avatar} alt="hospital" />
        <section>
          <span>
            Alterar
            <br />
            Avatar
          </span>
        </section>
      </UserAvatar>
      <Text mt={8} mb={2} color="gray.600" fontSize="18px">
        {capitalizeString(title)}
      </Text>
      <Text color="gray.500" fontSize="16px">
        {subtitle}
      </Text>
      <Divider my={10} color="gray.300" />
    </Flex>
  );
});

export default UserInfo;
