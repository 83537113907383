import React from 'react';
import { Stack } from '@chakra-ui/react';

export default function IRow({ children }) {
  return (
    <Stack direction={['column', 'column', 'column', 'row']} spacing={4}>
      {children}
    </Stack>
  );
}
