import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Shimmer } from '~/components/Kiwistrap/Page';

const ShimmerInfoSection = () => (
  <Flex my="2px" align="center">
    <Flex flexDir="column" w="auto" mr="20px">
      <Shimmer width="50px" height="50px" />
    </Flex>
    <Flex flexDir="column" h="80px" justify="center">
      <Shimmer width="80px" height="16px" margin="0 0 8px" />
      <Shimmer width="180px" height="16px" />
    </Flex>
  </Flex>
);

const ShimmerSection = () => (
  <Flex flexDir="column">
    <Shimmer width="200px" height="24px" />
    <ShimmerInfoSection />
    <ShimmerInfoSection />
    <ShimmerInfoSection />
    <ShimmerInfoSection />
  </Flex>
);

export default function ProfileShimmer() {
  return (
    <Flex>
      <ShimmerSection />
      <ShimmerSection />
      <ShimmerSection />
    </Flex>
  );
}
