import React from 'react';
import { Flex } from '@chakra-ui/react';

import history from '~/services/history';

import { Text } from '~/components/Kiwistrap/Page';
import { Button } from '~/components/ChakraComponents/';

import { Card, Pending } from './styles';

export default function Analyzing() {
  return (
    <Flex flexDir="column" h="80%" justify="center" align="center">
      <Card>
        <Pending />
        <Text size="16px">
          Sua documentação foi enviada com sucesso e está sobre{' '}
          <strong style={{ color: 'var(--primary)', fontWeight: 'bold' }}>
            análise
          </strong>
          .
        </Text>
        <Button
          title="voltar"
          margin="30px 0 0"
          onClick={() => history.push('/medico/profile')}
        />
      </Card>
    </Flex>
  );
}
