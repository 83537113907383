import React from 'react';

import { Text } from '@chakra-ui/react';
import isUserAllowed from '~/services/isUserAllowed';
import { Button, Divider } from '~/components/ChakraComponents';
import IsAllowed from '~/components/UI/IsAllowed';
import { Label } from '~/components/UI/Text';
import { IText, ICheck } from '~/components/UI/Inputs';
import { toast } from 'react-toastify';
import api from '~/services/api';

export default function ChooseValue({
  value,
  defaultValue,
  setValue,
  checkinAllowed,
  differentiatedValue,
  setDifferentiatedValue,
  disableChooseWorkedHours,
  disableChooseValue,
  setDisableChooseValue,
  setLoading,
  vacancy,
}) {
  const checkPermissionUser = isUserAllowed(9, 'hospitalPermissions');
  if (!checkPermissionUser) setValue(value);

  const saveValue = () => {
    const url = `/companies/myhospitals/schedules/vacancies/${vacancy.id}/realhours`;
    api
      .patch(url, {
        differentiatedValue: differentiatedValue,
        value: Number(value),
      })
      .then(response => {
        toast.success('Plantão editado com sucesso!');
        setLoading(false);
        setDisableChooseValue(true);
      })
      .catch(error => {
        toast.error('Ocorreu algum erro ao editar plantão!');
        setLoading(false);
      });
  };

  return (
    <>
      <Label>Valor Combinado</Label>
      {checkPermissionUser ? (
        <>
          <IsAllowed id={9}>
            <IText
              useCleave="true"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
              }}
              style={{ width: '125px' }}
              placeholder="Ex: 5,000"
              value={value}
              disabled={!differentiatedValue || disableChooseValue}
              onChange={e => setValue(e.target.value.replace(',', ''))}
            />
            <ICheck
              label="Valor Diferenciado 💲"
              checked={differentiatedValue}
              isDisabled={disableChooseValue}
              onChange={() => {
                const newDiffValue = !differentiatedValue;

                if (!newDiffValue) {
                  setValue(vacancy.schedule.contract.value);
                }

                setDifferentiatedValue(newDiffValue);
              }}
            />

            <Button
              title={disableChooseValue ? 'Editar Valor' : 'Salvar Valor'}
              variant={disableChooseValue ? 'secondary' : 'success'}
              onClick={() => {
                if (disableChooseValue) {
                  setDisableChooseValue(false);
                } else {
                  saveValue();
                }
              }}
            />
          </IsAllowed>
        </>
      ) : (
        <>
          <Text
            ml="8px"
            userSelect="none"
            fontWeight="semibold"
            color="var(--light-gray)"
          >
            {Number(value).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Text>
        </>
      )}
    </>
  );
}
