import React from 'react';

import { Div, SubTitle, Text } from '~/components/Kiwistrap/Page';

import { Container } from './styles';

import {
  turnText,
  hospitalText,
  addressText,
  valueText,
} from './formatInfoSectionText';

export default function InfoSection({ icon: Icon, title, info }) {
  return (
    <Container>
      <Icon />
      <Div>
        <SubTitle>{title}</SubTitle>
        <Text>{info}</Text>
      </Div>
    </Container>
  );
}

export { turnText, hospitalText, addressText, valueText };
