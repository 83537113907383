import React from 'react';
import { Flex, VStack } from '@chakra-ui/react';

import { Spinner } from '~/components/ChakraComponents';

export default function ModalContainer({
  w = '700px',
  h = 'auto',
  align = 'strech',
  loading = false,
  overflow = true,
  children,
}) {
  return (
    <Flex
      pos="relative"
      flexDir="column"
      w={w}
      h={h}
      minH="50px"
      maxW="95vw"
      maxH="90vh"
      overflowY={overflow ? (loading ? 'hidden' : 'auto') : ''}
      overflowX={overflow ? 'hidden' : ''}
    >
      {loading && (
        <Flex
          pos="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="525"
          w="100%"
          h="100%"
          align="center"
          justify="center"
          backgroundColor="var(--background)"
        >
          <Spinner />
        </Flex>
      )}
      <VStack align={align}>{children}</VStack>
    </Flex>
  );
}
