import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85vw;
  height: 80vh;
  overflow-y: scroll;
`;

export const Select = styled.select`
  margin-bottom: 20px;
  width: 400px;
  height: 40px;
  border: 2px solid var(--black);
  border-radius: var(--br-sm);
  padding: 5px;
  background: transparent;
`;

export const SectionVisualization = styled.div`
  margin-top: 20px;
  position: relative;
`;

export const VisualizationTitle = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--primary);
`;

export const VisualizationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

export const VisualizationBtns = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 12px;
  margin-right: 30px;
`;

export const Agenda = styled.div`
  margin-top: 20px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1.5%;
`;

export const DayCard = styled.div`
  position: sticky;
  top: -8px;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border-top: 7px solid var(--primary);
  background-color: var(--background-alt);
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DayCardText = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--primary);
`;
