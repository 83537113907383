import moment from 'moment';
import convertBRL from '~/utils/convertBRL';

export const turnText = vacancy => {
  const { date, startHour, endHour } = vacancy;

  const formattedDate = moment(date).format('DD/MM/YYYY');
  const formattedStart = moment(startHour).format('HH:mm');
  const formattedEnd = moment(endHour).format('HH:mm');

  return `${formattedDate} - ${formattedStart}h às ${formattedEnd}h`;
};

export const hospitalText = vacancy => {
  const {
    schedule: {
      contract: {
        hospital: { name },
      },
    },
  } = vacancy;

  return name;
};

export const addressText = vacancy => {
  const {
    schedule: {
      contract: {
        hospital: { cep, uf, city, address, street, number },
      },
    },
  } = vacancy;

  return `${cep} - ${uf}, ${city}, ${address}, ${street}, ${number}`;
};

export const valueText = vacancy => {
  const { payDate, value } = vacancy;

  return `${moment(payDate).format('DD/MM/YYYY')} - ${convertBRL(value)}`;
};
