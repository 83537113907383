import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Grid } from '@chakra-ui/react';

import api from '~/services/api';
import { weekDaysAlt as weekDays } from '~/constants/weekDays';
import { getDates, fetchSchedules } from '~/pages/hospital/VacanciesV2/utils';

import SectorCard from './WeekSectorCard';
import { VacancyCard } from '~/components/UI/Cards';
import { Spinner, Divider } from '~/components/ChakraComponents';
import { DayCard, DayCardText } from './styles';

import { useInterval } from '~/hooks/useInterval';

async function getLastUpdatedAt(url) {
  const response = await api.get(url);
  const { lastUpdatedAt } = await response.data;
  return lastUpdatedAt;
}

export default function DesktopWeekAgenda({
  baseUrl,
  baseUpdateUrl,
  cardOnClick,
}) {
  const { scheduleType, vacancyDate } = useParams();

  const [dates, setDates] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);

  const [startDate, endDate] = getDates(vacancyDate, scheduleType);
  const url = `${baseUrl}?startDate=${startDate}&endDate=${endDate}`;
  const updateUrl = `${baseUpdateUrl}?startDate=${startDate}&endDate=${endDate}`;

  const [currentLastUpdated, setCurrentLastUpdated] = useState(() =>
    getLastUpdatedAt(updateUrl)
  );

  async function checkHasUpdatesInVacancies(currentUpdated, lastUpdated) {
    const hasUpdates = (await lastUpdated) > (await currentUpdated);
    if (hasUpdates) setCurrentLastUpdated(lastUpdated);
  }

  useInterval(() => {
    checkHasUpdatesInVacancies(currentLastUpdated, getLastUpdatedAt(updateUrl));
  }, 1000 * 60 * 2); // clock: 2 minutes

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await fetchSchedules(
        url,
        (newDates, newSchedules) => {
          setDates(newDates);
          console.log(newSchedules)
          setSchedules(newSchedules);
          setLoading(false);
        },
        { isGroup: true }
      );
    };
    fetchData();
  }, [url, currentLastUpdated]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Flex my={3} pos="sticky" top="-1" zIndex="200">
            <Grid w="100%" templateColumns="120px repeat(7, 1fr)" gap="12px">
              <div />
              {dates.map((date, i) => (
                <DayCard key={date}>
                  <DayCardText>{`${weekDays[i]}`}</DayCardText>
                  <DayCardText>{`${date
                    .split('-')
                    .reverse()
                    .join('/')}`}</DayCardText>
                </DayCard>
              ))}
            </Grid>
          </Flex>
          {schedules.map((schedule, i) => (
            <React.Fragment key={schedule.id}>
              {i > 0 && <Divider mt="8px" mb="20px" color="gray.300" />}
              <Grid w="100%" templateColumns="120px repeat(7, 1fr)" gap="12px">
                <SectorCard schedule={schedule} />
                {schedule.dates.map(date => (
                  <Flex flexDir="column" key={date.day}>
                    {date.vacancies.map(vacancy => (
                      <VacancyCard
                        key={vacancy.id}
                        vacancy={vacancy}
                        onClick={() => cardOnClick(vacancy)}
                      />
                    ))}
                  </Flex>
                ))}
              </Grid>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}
