import styled from 'styled-components';

export const PurpleBackground = styled.div`
  width: 100%;

  padding: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;

  border-radius: 15px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  background: rgb(93, 107, 217);
  background: -moz-linear-gradient(
    144deg,
    rgba(93, 107, 217, 1) 45%,
    rgba(45, 111, 217, 1) 100%
  );
  background: -webkit-linear-gradient(
    144deg,
    rgba(93, 107, 217, 1) 45%,
    rgba(45, 111, 217, 1) 100%
  );
  background: linear-gradient(
    144deg,
    rgba(93, 107, 217, 1) 45%,
    rgba(45, 111, 217, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5d6bd9",endColorstr="#2d6fd9",GradientType=1);
`;

export const DoctorAvatar = styled.img`
  width: 140px;
  height: 140px;
  margin-bottom: 20px;
  border-radius: 50%;
  border: 4px solid var(--secondary);
  background-color: var(--background-alt);
  object-fit: cover;
`;

export const DoctorName = styled.div`
  margin: 0 12px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: var(--background-alt);
`;

export const DocumentComponent = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
