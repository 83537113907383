import React, { useState, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import api from '~/services/api';
import formatDate from '~/utils/formatDate';
import getFirstLastName from '~/utils/getFirstLastName';
import { getParam } from '~/utils/url';

import Section from './Section';

import Pagination from '~/components/Dashboard/Pagination';

import { Label } from '~/components/UI/Text';
import { Card, Avatar } from '~/components/ChakraComponents';

const limit = 1;

export default function HistoryModal({ vacancy, scheduleId }) {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { hospitalId } = getParam('hospitalId');

  const fetchData = () => {
    const offset = (currentPage - 1) * limit;

    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancy.id}/vacancylog?offset=${offset}&limit=${limit}`;

    api.get(url).then(response => {
      setData(response.data.data);
      setTotal(response.data.total);

      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
  }, [currentPage]);

  useEffect(() => {
    if (loading) fetchData();
  }, [loading]);

  return (
    <Flex flexDir="column" w="450px" loading={loading}>
      {data.length > 0 ? (
        data.map(log => (
          <Flex flexDir="column" h="100%">
            <Card alignItems="center" justifyContent="center" mt={2}>
              <Avatar
                src={log.usercompany.user[0].avatar}
                name={log.usercompany.user[0].name}
              />
              <Flex flexDir="column" ml={2}>
                <Label>{getFirstLastName(log.usercompany.user[0].name)}</Label>
                <Label>Editado em {formatDate(log.createdAt).fullDate()}</Label>
              </Flex>
            </Card>
            <Flex w="100%" h="100%" my={4}>
              <Flex flexDir="column" mr={4} w="100%" h="100%">
                <Label>antes</Label>
                <Section
                  doctor={log.beforeDoctor}
                  pendingDoctor={log.beforePendingDoctorName}
                  value={log.beforeValue}
                  type={log.beforeType}
                  confirmed={log.beforeConfirmed}
                  justification={log.beforeJustification}
                />
              </Flex>
              <Flex flexDir="column" w="100%" h="100%">
                <Label>depois</Label>
                <Section
                  doctor={log.afterDoctor}
                  pendingDoctor={log.afterPendingDoctorName}
                  value={log.afterValue}
                  type={log.afterType}
                  confirmed={log.afterConfirmed}
                  justification={log.afterJustification}
                />
              </Flex>
            </Flex>
          </Flex>
        ))
      ) : (
        <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
          <Text color="var(--secondary)" fontSize="xl" fontWeight="bold">
            Não houve alterações nessa vaga
          </Text>
        </Flex>
      )}
      <Pagination l={limit} t={total} onClick={setCurrentPage} />
    </Flex>
  );
}
