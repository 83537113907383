import React from 'react';
import { Flex } from '@chakra-ui/react';
import { GoCalendar } from 'react-icons/go';
import {
  FaRegChartBar,
  FaHospitalAlt,
  FaSearchPlus,
  FaBookOpen,
  FaNotesMedical,
  FaBookMedical,
} from 'react-icons/fa';

import Card from './components/Card';

import { PageLayout, CardsContainer } from './styles';

export default function Dashboard() {
  return (
    <PageLayout>
      <Flex flexDir="column" justify="center" align="center" mt="40px">
        <CardsContainer>
          <Card
            href="/medico/control"
            title="Controle de Horas"
            color="var(--secondary)"
            icon={FaRegChartBar}
          />
          <Card
            href="/medico/myvacancies"
            title="Meus Plantões"
            color="var(--red)"
            icon={GoCalendar}
          />
          <Card
            href="/medico/myhospitals"
            title="Meus Hospitais"
            color="var(--green)"
            icon={FaHospitalAlt}
          />
          <Card
            href="/medico/findvacancy"
            title="Buscar Plantão"
            color="var(--blue)"
            icon={FaSearchPlus}
          />
        </CardsContainer>
        <CardsContainer>
          <Card
            href="/medico/news"
            title="Notícias"
            color="var(--orange)"
            icon={FaBookOpen}
          />
          <Card
            href="/medico/protocols"
            title="Protocolos"
            color="var(--blue)"
            icon={FaNotesMedical}
          />
          <Card
            href="/medico/material"
            title="Material Didático"
            color="var(--primary)"
            icon={FaBookMedical}
          />
        </CardsContainer>
      </Flex>
    </PageLayout>
  );
}
