import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Container,
  Title,
  ContentPrevious,
  TextPrevious,
  Form,
  ContentForm,
  NameInput,
  InputGroup,
  FormInput,
  SectionDadosPessoais,
  TitleDadosPessoais,
  SectionDadosProfissionais,
  TitleDadosProfissionais,
  SectionEndereco,
  TitleEndereco,
  SectionBtn,
  Footer,
  SectionUteis,
  TitleUteis,
  SectionList,
  ListAll,
  ListItem,
  SectionCopyright,
  TitleCopyright,
  SectionSociais,
} from './styles';
import InputPrimary from '~/components/Site/Inputs/InputPrimary';
import BtnCadastrar from '~/components/Site/Buttons/BtnSecondary';

/**
 * Hook
 */
import { useProfissional } from '~/hooks/Site/ProfissionalContext';

require('cleave.js/dist/addons/cleave-phone.br');

export default function ImProfessional(props) {
  const [dados, setDados] = useState([]);
  const [end, setEnd] = useState([]);

  const { pathname } = props.location;

  const handleChanges = e => {
    setDados({ ...dados, [e.target.name]: e.target.value });
  };

  const {
    name,
    email,
    password,
    password_confirmed,
    phone1,
    company,
    cep,
    number,
    complement,
  } = dados;
  const { logradouro, bairro, localidade, uf } = end;

  useEffect(() => {
    const loadEnd = async () => {
      if (cep) {
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep || ''}/json/`
        );
        setEnd(data);
      }
    };
    loadEnd();
  }, [cep]);

  const { addProfissional } = useProfissional();

  const handleSubmit = async e => {
    e.preventDefault();

    addProfissional({
      name,
      email,
      password,
      password_confirmed,
      phone1,
      company,
      cep,
      logradouro,
      number,
      bairro,
      complement,
      uf,
      localidade,
    });
  };

  return (
    <Container>
      <Title>
        <h1>Profissional</h1>
      </Title>
      <ContentPrevious>
        <TextPrevious to="/cadastro">Voltar</TextPrevious>
      </ContentPrevious>
      <Form onSubmit={handleSubmit}>
        <ContentForm>
          <SectionDadosPessoais>
            <TitleDadosPessoais>Dados Pessoais</TitleDadosPessoais>
            <NameInput>NAME</NameInput>
            <InputPrimary
              name="name"
              type="text"
              value={dados.name}
              placeholder="EX: JOSÉ FRANCISCO"
              onChange={handleChanges}
              required
            />
            <NameInput>EMAIL</NameInput>
            <InputPrimary
              name="email"
              type="email"
              value={dados.email}
              placeholder="EX: entrar@plantao.com.br"
              onChange={handleChanges}
              required
            />
            <NameInput>SENHA</NameInput>
            <InputPrimary
              name="password"
              type="password"
              value={dados.password}
              placeholder="EX: ***"
              onChange={handleChanges}
              required
            />
            <NameInput>CONFIRMAÇÃO DE SENHA</NameInput>
            <InputPrimary
              name="password_confirmed"
              type="password"
              value={dados.password_confirmed}
              placeholder="EX: ***"
              onChange={handleChanges}
              required
            />
            <NameInput>TELEFONE</NameInput>
            <InputPrimary
              name="phone1"
              type="text"
              value={dados.phone1}
              placeholder="EX: (00) 00000-0000"
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              onChange={handleChanges}
              required
            />
          </SectionDadosPessoais>
          {/* <SectionDadosProfissionais>
            <TitleDadosProfissionais>
              Dados Administrativos
            </TitleDadosProfissionais>
            <NameInput>RAZÃO SOCIAL</NameInput>
            <InputPrimary
              name="company"
              type="text"
              value={dados.company}
              placeholder="EX: Consult DOC EIRELI - ME"
              onChange={handleChanges}
              required
            />
            <NameInput>CNPJ</NameInput>
            <InputPrimary
              name="cnpj"
              type="text"
              value={dados.cnpj}
              placeholder="EX: 00.000.000/0000-00"
              useCleave
              options={{
                delimiters: ['.', '.', '/', '-'],
                blocks: [2, 3, 3, 4, 2],
                uppercase: true,
              }}
              onChange={handleChanges}
            />
            <NameInput>TELEFONE</NameInput>
            <InputPrimary
              name="phone2"
              type="text"
              value={dados.phone2}
              placeholder="EX: (00) 00000-0000"
              useCleave
              options={{
                phone: true,
                phoneRegionCode: 'BR',
              }}
              onChange={handleChanges}
            />
          </SectionDadosProfissionais> */}
          <SectionEndereco>
            <TitleEndereco>Endereço</TitleEndereco>

            <NameInput>CEP</NameInput>
            <InputPrimary
              name="cep"
              type="text"
              value={dados.cep}
              placeholder="EX: 22273-098"
              useCleave
              options={{
                delimiters: ['-'],
                blocks: [5, 3],
                uppercase: true,
              }}
              onChange={handleChanges}
              required
            />
            <NameInput>RUA / AVENIDA / TRAVESSA</NameInput>
            <InputPrimary
              name="street"
              type="text"
              value={logradouro || ''}
              placeholder="EX: 123 de Oliveira 4"
              onChange={handleChanges}
              required
            />
            <InputGroup>
              <FormInput>
                <NameInput>NÚMERO</NameInput>
                <InputPrimary
                  name="number"
                  type="text"
                  value={dados.number}
                  placeholder="EX: 432"
                  onChange={handleChanges}
                  required
                />
              </FormInput>
              <FormInput>
                <NameInput>BAIRRO</NameInput>
                <InputPrimary
                  name="address"
                  type="text"
                  value={bairro || ''}
                  placeholder="EX: Jardim das Flores"
                  onChange={handleChanges}
                  required
                />
              </FormInput>
            </InputGroup>
            <NameInput>COMPLEMENTO</NameInput>
            <InputPrimary
              name="complement"
              type="text"
              value={dados.complement}
              placeholder="EX: Próx. Rua do sabão"
              onChange={handleChanges}
            />
            <InputGroup>
              <FormInput>
                <NameInput>UF</NameInput>
                <InputPrimary
                  name="uf"
                  type="text"
                  value={uf || ''}
                  placeholder="UF"
                  onChange={handleChanges}
                  required
                />
              </FormInput>
              <FormInput>
                <NameInput>CIDADE</NameInput>
                <InputPrimary
                  name="city"
                  type="text"
                  value={localidade || ''}
                  placeholder="EX:BELO HORIZONTE"
                  onChange={handleChanges}
                  required
                />
              </FormInput>
            </InputGroup>
          </SectionEndereco>
        </ContentForm>
        <SectionBtn>
          <BtnCadastrar
            title="CONCLUIR"
            type="submit"
            styles={{ width: '700px' }}
          />
        </SectionBtn>
      </Form>
      <Footer>
        <SectionUteis>
          <SectionList>
            <TitleUteis>Links Úteis</TitleUteis>
            <ListAll>
              <ListItem>
                <a href={pathname === '/sou-profissional' ? '/#home' : ''}>
                  Home
                </a>
              </ListItem>
              <ListItem>
                <a href={pathname === '/sou-profissional' ? '/#produto' : ''}>
                  Produto
                </a>
              </ListItem>
              <ListItem>
                <a href={pathname === '/sou-profissional' ? '/#contato' : ''}>
                  Contato
                </a>
              </ListItem>
              <ListItem>
                <Link to="/contato">Fale Conosco</Link>
              </ListItem>
            </ListAll>
          </SectionList>
          <SectionCopyright>
            <TitleCopyright>
              2020 - Meu Plantão - Todos os direitos reservados
            </TitleCopyright>
          </SectionCopyright>
          <SectionSociais>
            <a href="https://www.facebook.com/MeuPlantao" target="_blank">
              <FaFacebook
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a href="https://www.instagram.com/meuplantao/" target="_blank">
              <FaInstagram
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a href="#" target="_blank">
              <FaWhatsapp size={30} color="var(--background-alt)" />
            </a>
          </SectionSociais>
        </SectionUteis>
      </Footer>
    </Container>
  );
}
