import { BsGraphUp } from 'react-icons/bs';
import {
  FaNotesMedical,
  FaCalendarAlt,
  FaFileMedicalAlt,
  FaUsers,
  FaUserMd,
  FaHospitalAlt,
} from 'react-icons/fa';

import { getUser } from '~/services/auth';
import { getParam } from '~/utils/url';

export const getCompanyPages = () => {
  const pages = [
    {
      href: '/company/myhospitals',
      title: 'Meus Hospitais',
      color: 'green.400',
      icon: FaHospitalAlt,
    },
    {
      href: '/company/mydoctors',
      title: 'Meus Médicos',
      color: 'red.400',
      icon: FaUserMd,
    },
    {
      href: '/company/users',
      title: 'Usuários',
      color: 'purple.400',
      icon: FaUsers,
    },
    {
      href: '/company/spendcontrol',
      title: 'Controle de Gastos',
      color: 'cyan.400',
      icon: BsGraphUp,
    },
  ];

  return pages;
};

export const getContractorPages = () => {
  const pages = [
    {
      href: '/contractor/myhospitals',
      title: 'Meus Hospitais',
      color: 'green.400',
      icon: FaHospitalAlt,
    },
  ];

  const user = getUser();

  if (user?.allPermissions) {
    pages.push({
      href: '/contractor/users',
      title: 'Usuários',
      color: 'purple.400',
      icon: FaUsers,
    });
  }

  return pages;
};

export const getCompanyHospitalPages = () => {
  const { hospitalId } = getParam();

  const pages = [
    {
      permissionId: 2,
      title: 'Meus Contratos',
      href: `/hospital/mycontracts?hospitalId=${hospitalId}`,
      color: 'green.400',
      icon: FaFileMedicalAlt,
    },
    {
      permissionId: 6,
      title: 'Escalas',
      href: `/hospital/vacancies/schedulegroup/?hospitalId=${hospitalId}`,
      color: 'red.400',
      icon: FaCalendarAlt,
    },
    {
      permissionId: 14,
      title: 'Protocolos',
      href: `/hospital/protocols?hospitalId=${hospitalId}`,
      color: 'purple.400',
      icon: FaNotesMedical,
    },
  ];

  return pages;
};

export const getContractorHospitalPages = () => {
  const { hospitalId } = getParam();

  return [
    {
      title: 'Escalas',
      href: `/contractorhospital/vacancies/?hospitalId=${hospitalId}`,
      color: 'red.400',
      icon: FaCalendarAlt,
    },
  ];
};
