import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Flex, Grid, GridItem, Tooltip, Text } from '@chakra-ui/react';
import { FaRegMoneyBillAlt } from 'react-icons/fa';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';
import { vacancyCardBackgroundColor } from '~/utils/genStatus';
import { Spinner } from '~/components/ChakraComponents';
import formatDate from '~/utils/formatDate';
import convertBRL from '~/utils/convertBRL';
import Pagination from '~/components/Dashboard/Pagination';
import IDate from '~/components/UI/Inputs/IDate';

import { DoctorAvatar, DoctorName, PurpleBackground } from './styles';

export const limit = 10;

export default function EditDoctorModal({ doctor }) {
  const [loading, setLoading] = useState(true);
  const [vacancies, setVacancies] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const formatTodayDate = moment(new Date()).format('YYYY-MM-DD');

  const startDate = moment(`${formatTodayDate} 00:03:00`)
    .startOf('week')
    .format('YYYY-MM-DD');

  const endDate = moment(`${formatTodayDate} 00:03:00`)
    .endOf('week')
    .format('YYYY-MM-DD');

  const [newStartDate, setNewStartDate] = useState(startDate);
  const [newEndDate, setNewEndDate] = useState(endDate);

  const getVacancies = useCallback(() => {
    const offset = (currentPage - 1) * limit;
    api
      .get(`/companies/mydoctors/${doctor.doctor.id}/vacancies`, {
        params: {
          startDate: newStartDate,
          endDate: newEndDate,
          limit,
          offset,
        },
      })
      .then(data => {
        setVacancies([...data.data.data]);
        setTotal(data.data.total);
        setLoading(false);
      })
      .catch(() => {
        internalServerError();
      });
  }, [currentPage, doctor.doctor.id, newEndDate, newStartDate]);

  const clearDate = () => {
    setNewStartDate(startDate);
    setNewEndDate(endDate);
  };

  const formatCardDateInfo = data => {
    return `${formatDate(data.date).bars('DD/MM')} ${data.startHour} ~
                        ${data.endHour}`;
  };

  useEffect(() => {
    getVacancies(formatTodayDate);
  }, [currentPage, getVacancies, newEndDate, newStartDate]);

  return (
    <Flex w="500px" flexDir="column" justify="center" align="center">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <PurpleBackground>
            <DoctorAvatar src={doctor.avatar} alt={doctor.name} />
            <DoctorName>{doctor.name}</DoctorName>
          </PurpleBackground>
          <Flex w="100%" mt={5}>
            <IDate
              initialDate={newStartDate}
              required
              label="data inicial"
              onChange={d => {
                setNewStartDate(d);
              }}
            />
            <IDate
              initialDate={newEndDate}
              required
              label="data final"
              onChange={d => {
                setNewEndDate(d);
              }}
            />
          </Flex>
          <Text
            cursor="pointer"
            textDecoration="underline"
            mr="auto"
            color="var(--secondary)"
            fontWeight="semibold"
            mt={2}
            onClick={() => clearDate()}
          >
            Ver semana atual
          </Text>
          {vacancies.length ? (
            <>
              <Grid
                mt={5}
                h="fit-content"
                w="100%"
                templateColumns="repeat(2, 1fr)"
                gap={4}
              >
                {vacancies.map(data => (
                  <GridItem
                    key={data.id}
                    colSpan={1}
                    rowSpan={1}
                    bg={vacancyCardBackgroundColor(
                      data.type,
                      !data.belongsMyTeam
                    )}
                    borderRadius={4}
                    color="var(--background-alt)"
                    p={1}
                  >
                    <Flex justify="space-around" align="center">
                      <Flex>
                        <Tooltip hasArrow label={data.name} bg="gray.600">
                          <div>
                            <DoctorAvatar src={data.avatar} alt={data.name} />
                          </div>
                        </Tooltip>
                      </Flex>
                      <Flex flexDir="column">
                        <Flex>
                          <span style={{ marginRight: 10 }}>
                            {convertBRL(data.value)}
                          </span>
                          {data.inCash !== 'false' && (
                            <Tooltip hasArrow label="À vista" bg="gray.600">
                              <span>
                                <FaRegMoneyBillAlt size={20} />
                              </span>
                            </Tooltip>
                          )}
                        </Flex>
                        <Flex>{formatCardDateInfo(data)}</Flex>
                      </Flex>
                    </Flex>
                  </GridItem>
                ))}
              </Grid>
            </>
          ) : (
            <Text mt={5}>Este médico nao possui plantões neste período</Text>
          )}
        </>
      )}
      <Pagination l={limit} t={total} onClick={setCurrentPage} />
    </Flex>
  );
}
