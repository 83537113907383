import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';

import api from '~/services/api';
import { getParam } from '~/utils/url';

import phoneMask from '~/utils/phoneMask';

import { SubTitle, Title, Text } from '~/components/Kiwistrap/Page';

const Info = () => {
  const [hospital, setHospital] = useState();

  const { hospitalId } = getParam('hospitalId');

  useEffect(() => {
    api
      .get(`/companies/myhospitals/${hospitalId}`)
      .then(response => setHospital(response.data));
  }, []);

  return (
    <>
      {hospital && (
        <Flex flexDir="column" pb={2}>
          <Title margin="20px 0" size={18}>
            Adminstrativo
          </Title>
          <Flex>
            <Flex flexDir="column" w="100%">
              <SubTitle>Setor:</SubTitle>
              <Text>{hospital.profile}</Text>
            </Flex>
            <Flex flexDir="column" w="100%">
              <SubTitle>Cargo:</SubTitle>
              <Text>{`Gerência geral: ${hospital.superintendent}`}</Text>
            </Flex>
          </Flex>
          <Title margin="20px 0" size={18}>
            Contatos
          </Title>
          <Flex>
            <Flex flexDir="column" w="100%">
              <SubTitle>Telefones:</SubTitle>
              <Text>{`${phoneMask(hospital.phone1)} ${phoneMask(
                hospital.phone2
              )}`}</Text>
            </Flex>
            <Flex flexDir="column" w="100%">
              <SubTitle>Email:</SubTitle>
              <Text>{hospital.email}</Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default React.memo(Info);
