import React, { useState, useCallback } from 'react';

import {
  Radio,
  RadioGroup,
  Flex,
  Stack,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react';

import IRadio from '~/components/UI/Inputs/IRadio';

import { ModalContainer } from '~/components/UI/Modal';

import { Button } from '~/components/ChakraComponents';

import api from '~/services/api';

export default function ApproveModal({ closeModal, data, updateFunction }) {
  const format = val => `$${val}`;
  const parse = val => val.replace(/^\$/, '');
  const [value, setValue] = useState('1');
  const [price, setPrice] = useState(data.value);
  const [confirm, setConfirm] = useState(false);

  const sendData = useCallback(() => {
    api
      .put(`/companies/valueapproval/${data.id}`, {
        approvedValue: price,
      })
      .then(() => {
        updateFunction();
        closeModal();
      });
  }, []);

  return (
    <ModalContainer w="450px">
      {confirm ? (
        <Flex flexDir="column" align="center">
          <Text>Tem certeza?</Text>

          <Flex justify="space-around" mt={5} w="100%">
            <Button
              title="Não"
              variant="secondary"
              onClick={() => setConfirm(false)}
            />
            <Button title="Sim" variant="success" onClick={sendData} />
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection="column" w="100%">
          <Flex w="80%" margin="0 auto">
            <Stack direction="column" align="flex-start" flex="1">
              <IRadio
                label="Valor Completo"
                checked={value === '1'}
                onChange={() => {
                  setValue('1');
                  setPrice(data.value);
                }}
              />
              <IRadio
                label="Valor Proporcional"
                checked={value === '2'}
                onChange={() => {
                  setValue('2');
                  setPrice(data.effectiveValue);
                }}
              />
              <IRadio
                label="Valor Customizado"
                checked={value === '3'}
                onChange={() => {
                  setValue('3');
                  setPrice(0);
                }}
              />
            </Stack>
            <Flex flex="1" ml={5} justify="center" align="center">
              {value === '1' && <Text>R${price}</Text>}
              {value === '2' && <Text>R${price}</Text>}
              {value === '3' && (
                <NumberInput
                  value={format(price)}
                  onChange={valueString => setPrice(parse(valueString))}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              )}
            </Flex>
          </Flex>
          <Flex justify="space-around" mt={5}>
            <Button title="Cancelar" variant="secondary" onClick={closeModal} />
            <Button
              title="Confirmar"
              variant="success"
              onClick={() => setConfirm(true)}
            />
          </Flex>
        </Flex>
      )}
    </ModalContainer>
  );
}
