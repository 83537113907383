import styled from 'styled-components';

export const Column = styled.div`

  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;


`;


export const Row = styled.div`
  margin-bottom: 10px;

  a {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: start;

    &:hover {
      color: var(--hover-primary);
      transform: scale(1.1);
    }

    svg {
      margin-right: 5px;
    }
  }
`;
