import React from 'react';
import { Container, FileName, ModalContainer, ProgressBar } from './styles';

export default function ProgressModal({ loading = false, text = '' }) {
  React.useEffect(() => {
    localStorage.setItem('blockKeyboard', loading);
  }, [loading]);

  if (!loading) {
    return null;
  }

  return (
    <Container>
      <ModalContainer>
        <FileName>{text}</FileName>
        <ProgressBar>
          <div className="material-spinner" />
        </ProgressBar>
      </ModalContainer>
    </Container>
  );
}
