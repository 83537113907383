import {
  LandingContact,
  LandingForgotPassword,
  LandingChangeForgotPassword,
  LandingConfirmEmail,
  LandingHome,
  LandingHomev2,
  LandingImCompany,
  LandingImDoctor,
  LandingImHospital,
  LandingImProfessional,
  LandingLogin,
  LandingProduct,
  LandingSignUp,
  LandingSignUpContractor,
  FileUpload,
} from '~/pages/landing';

export default [
  { exact: true, path: '/', component: LandingHome },
  { path: '/signup/contractor', component: LandingSignUpContractor },
  { path: '/cadastro', component: LandingSignUp },
  { path: '/homev2', component: LandingHomev2 },
  { path: '/sou-empresa', component: LandingImCompany },
  { path: '/sou-profissional', component: LandingImProfessional },
  { path: '/sou-medico', component: LandingImDoctor },
  { path: '/sou-hospital', component: LandingImHospital },
  { path: '/login', component: LandingLogin },
  { path: '/contato', component: LandingContact },
  { path: '/esqueceu-senha', component: LandingForgotPassword },
  { path: '/produto', component: LandingProduct },
  // Comentar a seguinte rota depois
  {
    path: '/4x0lV+lXqItwNjB4z96vzCSN3kjvcKvXUHzbzHJu4fYç=file-upload',
    component: FileUpload,
  },
  {
    path: '/recovery-password/:userId/:userKey',
    component: LandingChangeForgotPassword,
  },
  {
    path: '/confirm-email/:userId/:userKey',
    component: LandingConfirmEmail,
  },
  { path: '/produto', component: LandingProduct },
];
