import styled from 'styled-components';

export const MobileContainer = styled.div`
  display: none;

  @media (max-width: 1023px) {
    @media print {
      display: none;
    }

    position: relative;

    width: 100%;
    height: 100%;

    padding: 8px;

    display: flex;
    flex-direction: column;
  }
`;

export const DesktopContainer = styled.div`
  display: none;

  @media print {
    display: flex;
  }

  @media (min-width: 1023px) {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
  }
`;
