import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex-wrap: ${props => (props.wrap ? props.wrap : 'nowrap')};
  flex-basis: ${props => (props.basis ? props.basis : 'auto')};
  flex-grow: ${props => (props.grow ? props.grow : '0')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};

  overflow: ${props => (props.overflow ? props.overflow : 'visible')};
  height: ${props => (props.height ? props.height : 'auto')};
  width: ${props => (props.width ? props.width : '100%')};
  padding: ${props => (props.padding ? props.padding : '0')};
  margin: ${props => (props.margin ? props.margin : '0')};

  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
`;
