export default cnpj => {
  if (!cnpj) return '';

  const cnpjArr = cnpj.split('');

  cnpjArr.splice(2, 0, '.');
  cnpjArr.splice(6, 0, '.');
  cnpjArr.splice(10, 0, '/');
  cnpjArr.splice(15, 0, '-');

  return cnpjArr.join('');
};
