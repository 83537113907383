import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import { Flex } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { Container } from 'react-bootstrap';
import { SimpleGrid } from '@chakra-ui/react';

import api from '~/services/api';
import history from '~/services/history';

import HospitalCard from './HospitalCard';
import MyHospitalsShimmer from './shimmer';

import PageLayout from '~/pages/_layouts/contractor/PageLayout';

import { Button } from '~/components/ChakraComponents/';

import { InputLabel, InputSearch } from './styles';

export default function MyHospitals() {
  const [loadingPage, setLoadingPage] = useState(true);

  const [name, setName] = useState('');
  const [hospitals, setHospitals] = useState([]);

  const searchInputRef = useRef();

  useEffect(() => {
    api.get('/contractors/myhospitals').then(response => {
      setHospitals(response.data.data);
      setLoadingPage(false);
    });
  }, []);

  const fetchData = useCallback(() => {
    api
      .get(`/contractors/myhospitals?name=${name}`)
      .then(response => setHospitals(response.data.data));
  }, [name, hospitals]);

  useEffect(() => {
    fetchData();
  }, [name]);

  return (
    <PageLayout loading={loadingPage} shimmer={MyHospitalsShimmer}>
      <Container>
        <Flex mb="20px" justify="center">
          <Flex align="flex-end" justify="center">
            <Flex flexDir="column">
              <InputLabel>buscar hospital</InputLabel>
              <InputSearch
                autoFocus
                placeholder="Ex: Hospital Coração"
                ref={searchInputRef}
                onKeyPress={e => {
                  if (e.key === 'Enter') setName(searchInputRef.current.value);
                }}
              />
            </Flex>
            <Button
              width="auto"
              height="40px"
              margin="0 0 0 10px"
              icon2={<FaSearch size={24} />}
              onClick={() => setName(searchInputRef.current.value)}
            />
          </Flex>
        </Flex>
        <SimpleGrid minChildWidth="200px" spacing={4}>
          {hospitals.map(hosp => (
            <Fragment key={hosp.id}>
              <HospitalCard
                hospital={hosp}
                // editHospital={() => handleEditHospital(hosp.id)}
                editHospital={() => {}}
                enterHospital={() => {
                  history.push(
                    `/contractorhospital/dashboard/?hospitalId=${hosp.id}`
                  );
                }}
              />
            </Fragment>
          ))}
        </SimpleGrid>
      </Container>
    </PageLayout>
  );
}
