import React from 'react';
import { Container, SignUp, Description, Knowledge, Download } from './styles';

const LandingProduct = () => {
  return (
    <Container>
      <SignUp>
        <h1>
          Tudo na ponta dos dedos <strong>RESULTADO EM TEMPO REAL</strong>
        </h1>
        <button type="button">CADASTRE-SE AGORA</button>
      </SignUp>
      <Description>
        <h2>6 Razões para usar o Meu Plantão</h2>
        <p>
          <img src="" alt="" />
          <h3>MENOS BUROCRACIA</h3>
          <span>
            Sistema fácil de usar, acessível a qualquer hora, em qualquer lugar
          </span>
        </p>
        <p>
          <img src="" alt="" />
          <h3>COMUNICAÇÃO</h3>
          <span>
            Comunique-se melhor com sua equipe e tenha uma melhor cobertura de
            escalas
          </span>
        </p>
        <p>
          <img src="" alt="" />
          <h3>SMARTPHONE</h3>
          <span>
            Ninguém mais deixará de ser avisado sobre os plantões e furos
          </span>
        </p>
        <p>
          <img src="" alt="" />
          <h3>PRODUTIVIDADE</h3>
          <span>
            Com uma organização rápida, fique livre para outras funções
          </span>
        </p>
        <p>
          <img src="" alt="" />
          <h3>ENVIO DE ALERTAS</h3>
          <span>
            Alertas de conflitos de horários e também de sobrecarga de horas
          </span>
        </p>
        <p>
          <img src="" alt="" />
          <h3>REDUÇÃO DE GASTOS</h3>
          <span>
            Economize com profissionais bem orgânicos e relatórios claros
          </span>
        </p>
      </Description>
      <Knowledge>
        <h1>CONHEÇA O SISTEMA DO MEU PLANTÃO</h1>
        <img src="" alt="" />
        <div>
          <div>
            <h3>GESTORES</h3>
            <span>
              O Meu Plantão é uma plataforma na nuvem, onde o gestor mostra a
              escala da equipe.
            </span>
          </div>
          <div>
            <h3>RESULTADOS</h3>
            <span>
              Ao final dos mês, com 1 clique, gera-se relatórios de pagamentos,
              produtividade e faltas.
            </span>
          </div>
          <div>
            <h3>PROGNÓSTICO</h3>
            <span>
              A plataforma informa sobre conflitos de horários e excesso de
              carga horária semanal.
            </span>
          </div>
          <div>
            <h3>ACOMPANHAMENTO</h3>
            <span>
              Tenha uma visão geral e controle da gestão de escalas com um
              painel claro e informações objetivas.
            </span>
          </div>
          <div>
            <h3>PROFISSIONAIS</h3>
            <span>
              Através do APP, interagem com a sala. Acompanham a agenda e fazem
              trocas entre is colegas.
            </span>
          </div>
        </div>
      </Knowledge>
      <Download>
        <img src="" alt="" />
        <div>
          <span>Baixe agora mesmo!</span>
          <span>Baixe agora mesmo!</span>
          <span>Baixe agora mesmo!</span>
        </div>
      </Download>
    </Container>
  );
};

export default LandingProduct;
