import React from 'react';
import { VStack } from '@chakra-ui/react';

import { SubTitle } from '~/components/Kiwistrap/Page';

export default function Section({ header = '', children }) {
  return (
    <VStack align="flex-start">
      <SubTitle color="var(--primary)">{header}</SubTitle>
      {children}
    </VStack>
  );
}
