import styled from 'styled-components';

export const Avatar = styled.img`
  width: 100px;
  height: 100px;

  border: 4px solid var(--primary);
  border-radius: 50%;

  flex-shrink: none;
  object-fit: cover;
`;
