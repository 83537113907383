import React from 'react';
import { Container } from 'react-bootstrap';

import { dashboardBuilder } from './actions';

import Resume from './Resume';
import Navigation from './Navigation';

export default function Dashboard() {
  return dashboardBuilder(
    <Container>
      <Resume />
      <Navigation />
    </Container>
  );
}
