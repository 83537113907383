import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

export const toDateVacancyText = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return null;
  }

  return (
    <Flex mb={2} align="center" textAlign="center">
      <Text>
        Mostrando plantões de{' '}
        {startDate
          .split('-')
          .reverse()
          .join('/')}{' '}
        {startDate !== endDate &&
          'até ' +
            endDate
              .split('-')
              .reverse()
              .join('/')}
      </Text>
    </Flex>
  );
};
