import React, { useState, useRef, useCallback, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { VStack, Flex } from '@chakra-ui/react';

import api from '~/services/api';
import history from '~/services/history';
import { getParam } from '~/utils/url';

import IsAllowed from '~/components/UI/IsAllowed';
import ScheduleGroupModal from '~/components/Dashboard/Modals/MeusPlantoes/ScheduleGroupModal';
import CreateScheduleModal from '~/components/Dashboard/Modals/MeusPlantoes/CreateScheduleModal';
import { Modal } from '~/components/UI/Modal';
import { Button } from '~/components/ChakraComponents/';

import { ScheduleGroupButton } from './styles';

export default function MenuLeft() {
  const { hospitalId } = getParam('hospitalId');
  const { groupId, scheduleType, vacancyDate } = useParams();

  const [groups, setGroups] = useState([]);

  const scheduleGroupModal = useRef(null);
  const createVacanciesModalRef = useRef(null);

  const openScheduleGroup = useCallback(() => {
    scheduleGroupModal.current.openModal();
  }, []);

  const openCreateVacancies = useCallback(() => {
    createVacanciesModalRef.current.openModal();
  }, []);

  useEffect(() => {
    const url = `/companies/myhospitals/${hospitalId}/schedulesgroup`;

    api.get(url).then(response => {
      setGroups(response.data.data);
    });
  }, []);

  return (
    <VStack align="stretch">
      <IsAllowed id={7}>
        <Flex justify="space-between">
          <Button
            title="gerenciar escalas"
            width="calc(50% - 0.25rem)"
            height="40px"
            onClick={openScheduleGroup}
          />
          <Button
            title="criar plantão"
            width="calc(50% - 0.25rem)"
            height="40px"
            onClick={openCreateVacancies}
          />
        </Flex>
      </IsAllowed>
      {groups.map(group => (
        <ScheduleGroupButton
          key={group.id}
          backgroundColor={
            +groupId === group.id ? 'var(--secondary)' : 'var(--background)'
          }
          textColor={
            +groupId === group.id ? 'var(--background)' : 'var(--secondary)'
          }
          onClick={() => {
            localStorage.setItem('groupName', group.name);

            let date = vacancyDate;
            let type = scheduleType;

            if (!date || date === 'undefined') {
              date = moment(new Date()).format('YYYY-MM-DD');
            }

            if (!type || type === 'undefined') {
              type = 'week';
            }

            history.push(
              `/hospital/vacancies/schedulegroup/${group.id}/${type}/${date}/?hospitalId=${hospitalId}`
            );
          }}
        >
          {group.name}
        </ScheduleGroupButton>
      ))}
      <Modal
        title="Criar Plantão"
        ref={createVacanciesModalRef}
        component={CreateScheduleModal}
      />
      <Modal
        title="Escalas"
        ref={scheduleGroupModal}
        component={ScheduleGroupModal}
      />
    </VStack>
  );
}
