import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: var(--modal-background);
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 8px 8px 6px 6px;

  background-clip: padding-box;
  background-color: var(--background);

  @media (max-width: 62em) {
    max-width: 98vw;
    max-height: 100vh;
  }
`;

export const Header = styled.div`
  position: relative;

  width: 100%;
  height: 50px;

  padding: 0 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px 6px 0 0;

  text-transform: capitalize;

  background-color: var(--primary);
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 600;

  text-align: center;

  color: var(--background-alt);
`;

export const CloseSvg = styled(AiOutlineClose)`
  cursor: pointer;

  position: absolute;
  right: 8px;

  font-size: 22px;
  font-weight: bold;

  color: var(--background);

  &:hover {
    color: var(--dim-gray);
  }

  @media (max-width: 62em) {
    font-size: 16px;
  }
`;
