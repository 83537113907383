import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import api from '~/services/api';
import history from '~/services/history';
import formatDate from '~/utils/formatDate';
import { getParam } from '~/utils/url';

import { ModalContainer } from '~/components/UI/Modal';

const ChooseButton = styled.div`
  width: 100%;
  min-height: 50px;

  padding: 0 4px;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-radius: var(--br-sm);

  font-size: 12px;
  font-weight: 700;

  text-align: center;
  text-transform: uppercase;

  border: 2px solid var(--black);

  color: ${props => props.textColor};
  background: ${props => props.backgroundColor};
`;

export default function Schedules({ closeModal }) {
  const { hospitalId } = getParam('hospitalId');
  const { groupId, vacancyDate } = useParams();

  const [loading, setLoading] = useState(true);

  const [groups, setGroups] = useState([]);

  const fetchData = useCallback(() => {
    const groupsUrl = `/contractors/myhospitals/${hospitalId}/contracts/groups?isActive=true`;

    api.get(groupsUrl).then(groupsResponse => {
      setGroups(groupsResponse.data.data);

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const navigateToPage = useCallback(
    id => {
      let date = formatDate().iso();

      if (vacancyDate) date = vacancyDate;

      const url = `/contractorhospital/vacancies/group/${id}/week/${date}/?hospitalId=${hospitalId}`;

      history.push(url);

      closeModal();
    },
    [vacancyDate]
  );

  return (
    <ModalContainer h="90vh" loading={loading}>
      {groups.map(group => (
        <ChooseButton
          key={group.id}
          backgroundColor={
            +groupId === group.id ? 'var(--secondary)' : 'var(--background)'
          }
          textColor={
            +groupId === group.id ? 'var(--background)' : 'var(--secondary)'
          }
          onClick={() => navigateToPage(group.id)}
        >
          {group.name}
        </ChooseButton>
      ))}
    </ModalContainer>
  );
}
