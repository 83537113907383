import React from 'react';

import {
  SvgContainer,
  RefusedSvg,
  PendingSvg,
  ExpiredSvg,
  CheckinSvg,
  CheckoutSvg,
  ConfirmedSvg,
} from './styles';

export default function IconStatus({ status }) {
  const renderStatus = (statusColor, Component) => (
    <SvgContainer statusColor={statusColor}>
      <Component />
    </SvgContainer>
  );

  const statusFactory = () =>
    ({
      refused: renderStatus('var(--red)', RefusedSvg),
      pending: renderStatus('var(--yellow)', PendingSvg),
      expired: renderStatus('var(--red)', ExpiredSvg),
      accepted: renderStatus('var(--secondary)', ConfirmedSvg),
      checkin: renderStatus('var(--secondary)', CheckinSvg),
      checkout: renderStatus('var(--green)', CheckoutSvg),
    }[status]);

  return <>{statusFactory()}</>;
}
