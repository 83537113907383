import React from 'react';
import { Row, Col } from 'react-bootstrap';

import NavCard from './NavCard';

import { getPages } from './pages';

export default function Navigation() {
  return (
    <Row>
      {getPages().map(page => (
        <Col md key={page.title}>
          <NavCard
            permissionId={page.permissionId}
            title={page.title}
            href={page.href}
            color={page.color}
            icon={page.icon}
          />
        </Col>
      ))}
    </Row>
  );
}
