import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
`;
