import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';

import Input from '~/components/Site/Inputs/InputPrimary';

import { useAuth } from '~/hooks/AuthContext';
import { Link } from 'react-router-dom';

import bg from '~/assets/login/Background.png';

import {
  Container,
  Content,
  Form,
  FormControl,
  SpanEmail,
  SpanPassword,
  SectionBtn,
  BtnLogin,
  TitleLogin,
  SectionForgot,
  ForgotPass,
  Footer,
  SectionUteis,
  TitleUteis,
  SectionList,
  ListAll,
  ListItem,
  SectionCopyright,
  TitleCopyright,
  SectionSociais,
  FooterMobile,
  TitleSociais,
} from './styles';

export default function Login(props) {
  const [dados, setDados] = useState([]);
  const [loadingLogin, setLoadingLogin] = useState(false);

  const { signIn } = useAuth();

  const handleChanges = e => {
    setDados({ ...dados, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoadingLogin(true);

    const { email, password } = dados;

    signIn({
      email,
      password,
    });

    setLoadingLogin(false);

    e.target.reset();
  };
  const { pathname } = props.location;
  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit}>
          <TitleLogin>Login</TitleLogin>
          <FormControl>
            <SpanEmail>E-MAIL</SpanEmail>
            <Input
              type="email"
              name="email"
              value={dados.email}
              placeholder="Informe o seu e-mail.."
              onChange={handleChanges}
              required
            />
          </FormControl>
          <FormControl>
            <SpanPassword>SENHA</SpanPassword>
            <Input
              type="password"
              name="password"
              value={dados.password}
              placeholder="Informe a sua senha.."
              onChange={handleChanges}
              required
            />
          </FormControl>
          <SectionForgot>
            <ForgotPass to="/esqueceu-senha">Esqueceu a sua senha?</ForgotPass>
          </SectionForgot>
          <SectionBtn>
            <BtnLogin type="submit">
              {loadingLogin ? 'CARREGANDO...' : 'ENTRAR'}
            </BtnLogin>
          </SectionBtn>
        </Form>
      </Content>
      <Footer>
        <SectionUteis>
          <SectionList>
            <TitleUteis>Links Úteis</TitleUteis>
            <ListAll>
              <ListItem>
                <a href={pathname === '/sou-empresa' ? '/#home' : ''}>Home</a>
              </ListItem>
              <ListItem>
                <a href={pathname === '/sou-empresa' ? '/#produto' : ''}>
                  Produto
                </a>
              </ListItem>
              <ListItem>
                <a href={pathname === '/sou-empresa' ? '/#contato' : ''}>
                  Contato
                </a>
              </ListItem>
              <ListItem>
                <Link to="/contato">Fale Conosco</Link>
              </ListItem>
            </ListAll>
          </SectionList>
          <SectionCopyright>
            <TitleCopyright>
              2020 - Meu Plantão - Todos os direitos reservados
            </TitleCopyright>
          </SectionCopyright>
          <SectionSociais>
            <a href="https://www.facebook.com/MeuPlantao" target="_blank">
              <FaFacebook color="var(--background-alt)" />
            </a>
            <a href="https://www.instagram.com/meuplantao/" target="_blank">
              <FaInstagram color="var(--background-alt)" />
            </a>
            <a href="#" target="_blank">
              <FaWhatsapp color="var(--background-alt)" />
            </a>
          </SectionSociais>
        </SectionUteis>
      </Footer>
      <FooterMobile>
        <TitleSociais>Siga-nos nas redes sociais</TitleSociais>
        <SectionSociais>
          <a href="https://www.facebook.com/MeuPlantao" target="_blank">
            <FaFacebook color="var(--background-alt)" />
          </a>
          <a href="https://www.instagram.com/meuplantao/" target="_blank">
            <FaInstagram color="var(--background-alt)" />
          </a>
          <a href="#" target="_blank">
            <FaWhatsapp color="var(--background-alt)" />
          </a>
        </SectionSociais>
      </FooterMobile>
    </Container>
  );
}
