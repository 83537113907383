import React, { useRef, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

import { ufOptions } from '~/constants/uf';

import { Button } from '~/components/ChakraComponents/';

import { InputLabel, InputSearch, InputSelect } from './styles';

export default function FindDoctorInput({ setCrm, setCrmUf }) {
  const crmInputRef = useRef('');
  const crmUfInputRef = useRef('');

  const updateState = useCallback(() => {
    setCrm(crmInputRef.current.value);
    setCrmUf(crmUfInputRef.current.value);
  }, []);

  const onKeyPressEnter = useCallback(e => {
    if (e.key === 'Enter') updateState();
  }, []);

  const onSearchButtonClick = useCallback(() => {
    updateState();
  }, []);

  return (
    <Flex w={['100%', '100%', '100%', '400px']} mt={[2, 2, 2, 0]}>
      <Flex align="flex-end">
        <Flex flexDir="column">
          <InputLabel>crm</InputLabel>
          <InputSearch
            autoFocus
            placeholder="Ex: 12345"
            type="number"
            pattern="[0-9]*"
            ref={crmInputRef}
            onKeyPress={onKeyPressEnter}
          />
        </Flex>
      </Flex>
      <Flex ml="10px" align="flex-end">
        <Flex flexDir="column">
          <InputLabel>crmuf</InputLabel>
          <InputSelect ref={crmUfInputRef}>
            <option value="">Todos</option>
            {ufOptions.map(uf => (
              <option key={uf.id} value={uf.value}>
                {uf.text}
              </option>
            ))}
          </InputSelect>
        </Flex>
        <Button
          width="auto"
          height="40px"
          margin="0 0 0 10px"
          icon2={<FaSearch size={24} />}
          onClick={onSearchButtonClick}
        />
      </Flex>
    </Flex>
  );
}
