import React, { useState, useCallback, useEffect } from 'react';
import { Spinner, Flex } from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';

import api from '~/services/api';
import onlyNumberHandleChange from '~/utils/numberMaskWithAscii';

import { getUser } from '~/services/auth';
import { ufOptions } from '~/constants/uf';

import DoctorCard from './DoctorCard';
import Pagination from '~/components/Dashboard/Pagination';

import { Button } from '~/components/ChakraComponents';
import { Autogrid } from '~/components/Kiwistrap/Page';
import { IText, ISelect, ISpec } from '~/components/UI/Inputs';

export const limit = 12;

export default function FindDoctor({
  getSearchUrl,
  extensions = [],
  disableProfile = false,
  disableHours = false,
  disableAlerts = false,
  noResultsDiv: NoResultsDiv = <></>,
}) {
  const user = getUser();

  const [loadingInfo, setLoadingInfo] = useState(false);
  const [total, setTotal] = useState();
  const [search, setSearch] = useState();
  const [inputs, setInputs] = useState({
    name: '',
    crm: '',
    crmUf: user.uf,
    specialty: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [preventInitialSearch, setPreventInitialSearch] = useState(true);

  const handleChanges = e => {
    if (e.target.name === 'crm') {
      return setInputs({
        ...inputs,
        [e.target.name]: onlyNumberHandleChange(e.target.value),
      });
    }
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const setSpecialtyId = id => {
    setInputs({ ...inputs, specialty: id });
  };

  const handleSearch = useCallback(() => {
    if (preventInitialSearch) return setPreventInitialSearch(false);

    setLoadingInfo(true);

    const offset = (currentPage - 1) * limit;

    const url = getSearchUrl(inputs, limit, offset, setLoadingInfo);

    api.get(url).then(response => {
      setTotal(response.data.total);
      setSearch(response.data.data);

      setLoadingInfo(false);
    });
  }, [preventInitialSearch, currentPage, inputs]);

  useEffect(() => {
    handleSearch();
  }, [currentPage]);

  return (
    <Flex w="100%" h="100%" flexDir="column" alignItems="center">
      <Autogrid childrenSize="150px">
        <IText
          label="nome"
          name="name"
          placeholder="Ex: Rogério Sérgio"
          value={inputs.name}
          onChange={handleChanges}
          onKeyPress={e => {
            if (e.key === 'Enter') handleSearch();
          }}
        />
        <ISpec
          setSpecialtyId={setSpecialtyId}
          onKeyPress={e => {
            if (e.key === 'Enter') handleSearch();
          }}
        />
        <IText
          label="crm"
          name="crm"
          placeholder="Ex: 123457"
          value={inputs.crm}
          onChange={handleChanges}
          onKeyPress={e => {
            if (e.key === 'Enter') handleSearch();
          }}
        />
        <Flex align="center">
          <ISelect
            label="uf"
            name="crmUf"
            options={ufOptions}
            value={inputs.crmUf}
            onChange={handleChanges}
          />
          <Button
            title="buscar"
            width="90px"
            height="40px"
            margin="20px 0 0 1rem"
            icon2={<AiOutlineSearch size={24} />}
            onClick={handleSearch}
          />
        </Flex>
      </Autogrid>
      {loadingInfo && <Spinner />}
      {!loadingInfo && search?.length > 0 && (
        <Autogrid childrenSize="240px" mt={4} mb={4}>
          {search.map(doctor => (
            <DoctorCard
              disableProfile={disableProfile}
              disableHours={disableHours}
              disableAlerts={disableAlerts}
              key={doctor.id}
              doctor={doctor}
              extensions={extensions}
            />
          ))}
        </Autogrid>
      )}
      {!loadingInfo && search?.length === 0 && NoResultsDiv}
      <Flex alignItems="center" justifyContent="center">
        <Pagination l={limit} t={total} onClick={setCurrentPage} />
      </Flex>
    </Flex>
  );
}
