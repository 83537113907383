import React from 'react';
import moment from 'moment';
import ReactCalendar from 'react-calendar';
import { useParams } from 'react-router-dom';

import history from '~/services/history';
import { getParam } from '~/utils/url';

export default function Calendar() {
  const { hospitalId } = getParam('hospitalId');
  const { groupId, scheduleType, vacancyDate } = useParams();

  const handleOnChange = date => {
    const formattedDate = moment(date)
      .add(-1, 'days')
      .format('YYYY-MM-DD');

    const url = `/contractorhospital/vacancies/group/${groupId}/${scheduleType}/${formattedDate}/?hospitalId=${hospitalId}`;

    history.push(url);
  };

  const getDate = () => {
    const date = new Date(vacancyDate);

    date.setDate(date.getDate() + 2);

    return date;
  };

  return (
    <ReactCalendar
      value={getDate()}
      onChange={handleOnChange}
      calendarType="US"
    />
  );
}
