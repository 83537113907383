import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import {
  AiOutlineCloseCircle,
  AiOutlineCheckCircle,
  AiOutlineUpload,
  AiOutlineInfoCircle,
} from 'react-icons/ai';
import { IoIosEye } from 'react-icons/io';
import { Avatar, Button } from '~/components/ChakraComponents';

import api from '~/services/api';
import history from '~/services/history';

import { ufOptions } from '~/constants/uf';

import { IText, ISelect } from '~/components/UI/Inputs';

export default function FileUpload() {
  const [doctor, setDoctor] = useState();
  const [addDocument, setAddDocument] = useState(false);
  const [inputs, setInputs] = useState({ crmUf: 'SP' });
  const [inputsDocuments, setInputsDocuments] = useState({
    description: '',
    name: '',
    bucketName: '',
    success: false,
  });
  const [documents, setDocuments] = useState([
    {
      description: 'RG ou CNH.',
      name: 'imgRG',
      bucketName: '',
      success: false,
      fileUrl: '',
    },
    {
      description: 'Carteirinha CRM.',
      name: 'imgCRM',
      bucketName: '',
      success: false,
      fileUrl: '',
    },
    {
      description: 'Diploma (Frente).',
      name: 'imgDegreeFront',
      bucketName: '',
      success: false,
      fileUrl: '',
    },
    {
      description: 'Diploma (Verso).',
      name: 'imgDegreeBack',
      bucketName: '',
      success: false,
      fileUrl: '',
    },
    {
      description: 'Certificado de especialização (Frente).',
      name: 'imgDegreeSpecialtyFront',
      bucketName: '',
      success: false,
      fileUrl: '',
    },
    {
      description: 'Certificado de especialização (Verso).',
      name: 'imgDegreeSpecialtyBack',
      bucketName: '',
      success: false,
      fileUrl: '',
    },
    {
      description: 'Certidão ético-profissional do conselho regional.',
      name: 'imgEthical',
      bucketName: '',
      success: false,
      fileUrl: '',
    },
    {
      description: 'Comprovante de residência.',
      name: 'imgProofOfAddress',
      bucketName: '',
      success: false,
      fileUrl: '',
    },
  ]);

  const handleCrm = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleInputDocument = e => {
    setInputsDocuments({ ...inputsDocuments, [e.target.name]: e.target.value });
  };

  const sendCrm = () => {
    const { crm, crmUf } = inputs;

    api
      .get('/useradmin/document/doctor', { params: { crm, crmUf } })
      .then(res => {
        setDoctor(res.data.doctor);

        res.data.documents.forEach(doc => {
          const findDoc = documents.find(docs => docs.name === doc.name);
          if (findDoc) {
            findDoc.success = true;
            findDoc.bucketName = doc.bucketName;
            findDoc.fileUrl = doc.fileUrl;
            return;
          }
          documents.push({
            name: doc.name,
            description: doc.name,
            bucketName: doc.bucketName,
            success: true,
            fileUrl: doc.fileUrl,
          });
        });
        setDocuments([...documents]);
      })
      .catch(() => {
        return toast.error('CRM não encontrado.');
      });
  };

  const uploadDocument = async (name, file) => {
    try {
      const data = new FormData();

      data.append('file', file);

      if (!file.name.match(/.(jpg|jpeg|png)$/i) && false) {
        return toast.error('Arquivo enviado não é do tipo imagem.');
      }

      const response = await api.post('/useradmin/document/file', data);

      const awsFile = response.data[0].filename;

      const documentsToUpdate = [...documents];

      const docIndex = documentsToUpdate.findIndex(document => {
        return document.name === name;
      });

      documentsToUpdate[docIndex].success = 'notSend';
      documentsToUpdate[docIndex].bucketName = awsFile;

      return setDocuments(documentsToUpdate);
    } catch (error) {
      return toast.error('Erro ao fazer upload, tente novamente.');
    }
  };

  const handleChange = e => {
    const docName = e.target.name;
    const docFile = e.target.files[0];

    uploadDocument(docName, docFile);
  };

  const handleDocument = e => {
    setDocuments([...documents, inputsDocuments]);
    setAddDocument(false);
  };

  const handleSubmit = async () => {
    // const forgotAny = documents.find(document => document.success === false);

    // if (forgotAny) {
    //   return toast.error('Todos os documentos são obrigatórios.');
    // }

    const filteredDocuments = [...documents];

    filteredDocuments.forEach(document => {
      delete document.success;
      delete document.fileUrl;
    });

    const apiData = {
      documents: [...filteredDocuments].filter(doc => doc.bucketName),
    };

    try {
      await api.post(`/useradmin/document/${doctor.id}`, apiData);

      toast.success('Documentação enviada com sucesso!');

      return window.location.reload();
    } catch (error) {
      return toast.error(
        'Houve um problema com os nossos servidores. Tente novamente.'
      );
    }
  };

  return (
    <div style={{ width: '100%', height: 'auto', overflow: 'auto' }}>
      <Flex p={10} align="center" justify="center" direction="column">
        <Flex justify="center">
          <Text textAlign="center">Procurar o CRM</Text>
        </Flex>
        <Flex w="60%">
          <Flex w="78%" mr="2%" minW="200px">
            <IText
              label="crm"
              type="number"
              name="crm"
              value={inputs.crm}
              onChange={handleCrm}
            />
          </Flex>
          <Flex w="20%" minW="100px">
            <ISelect
              label="uf"
              name="crmUf"
              options={ufOptions}
              value={inputs.crmUf}
              onChange={handleCrm}
            />
          </Flex>
        </Flex>
        {doctor ? (
          <Flex
            w="500px"
            p="20px"
            align="center"
            justify="center"
            direction="column"
          >
            <Flex align="center">
              <Avatar
                w="50px"
                h="50px"
                backgroundColor="transparent"
                name={doctor.user.name}
                src={doctor.user.avatar}
              />
              <Text m={5} fontWeight="800">
                {doctor.user.name}
              </Text>
            </Flex>
            {documents.map(document => (
              <Flex w="100%" align="center" justify="center">
                <label htmlFor={document.success} style={{ width: '100%' }}>
                  <Flex
                    m="5px 0"
                    p="4px 8px"
                    align="center"
                    justify="center"
                    borderRadius="4px"
                    boxShadow="var(--shadow)"
                    bgColor="var(--background-alt)"
                    cursor="pointer"
                  >
                    {document.success === true && (
                      <AiOutlineCheckCircle
                        style={{ marginRight: 20 }}
                        size={25}
                        color="var(--green)"
                      />
                    )}
                    {document.success === 'notSend' && (
                      <AiOutlineInfoCircle
                        style={{ marginRight: 20 }}
                        size={25}
                        color="var(--yellow)"
                      />
                    )}
                    {document.success === false && (
                      <AiOutlineCloseCircle
                        style={{ marginRight: 20 }}
                        size={25}
                        color="var(--red)"
                      />
                    )}
                    <Text width="100%">{document.description}</Text>
                    <AiOutlineUpload size={25} color="var(--dark-gray)" />
                    <input
                      type="file"
                      id={document.name}
                      name={document.name}
                      onChange={handleChange}
                      style={{ display: 'none' }}
                    />
                  </Flex>
                </label>
                <a
                  href={document.fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoIosEye size={30} />
                </a>
              </Flex>
            ))}
            {addDocument ? (
              <>
                <IText
                  label="Nome:"
                  name="name"
                  onChange={handleInputDocument}
                />
                <IText
                  label="Descrição:"
                  name="description"
                  onChange={handleInputDocument}
                />
                <Button
                  margin="20px 0 0"
                  title="Salvar"
                  onClick={handleDocument}
                />
              </>
            ) : (
              <Button
                title="Adicionar +"
                onClick={() => setAddDocument(true)}
              />
            )}
            <Flex margin="20px 0 0" justifyContent="space-between">
              <Button title="enviar" onClick={handleSubmit} />
            </Flex>
          </Flex>
        ) : (
          <Flex margin="20px 0 0" justifyContent="space-between">
            <Button title="Procurar" onClick={() => sendCrm()} />
          </Flex>
        )}
      </Flex>
    </div>
  );
}
