import React, { useRef, useCallback } from 'react';
import {
  FaCog as OptionsSvg,
  FaEdit as EditSvg,
  FaPlus as AddSvg,
} from 'react-icons/fa';
import { Flex, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

import { getUser } from '~/services/auth';

import EditScheduleModal from './EditScheduleModal';
import CreateVacancyModal from './CreateVacancyModal';
import { Modal } from '~/components/UI/Modal';

export default function Options({ schedule }) {
  const editScheduleModalRef = useRef(null);
  const createVacancyModalRef = useRef(null);

  const openEditSchedule = useCallback(() => {
    editScheduleModalRef.current.openModal();
  }, []);

  const openCreateVacancy = useCallback(() => {
    createVacancyModalRef.current.openModal();
  }, []);

  const user = getUser();

  if (user.type !== 'company') {
    return null;
  }

  return (
    <Flex pos="absolute" bottom={1} left={1} color="gray.700">
      <Menu>
        <MenuButton>
          <OptionsSvg size={16} color="var(--primary)" />
        </MenuButton>
        <MenuList borderColor="gray.200">
          <MenuItem onClick={openEditSchedule} icon={<EditSvg size={18} />}>
            Editar plantão
          </MenuItem>
          <MenuItem onClick={openCreateVacancy} icon={<AddSvg size={15} />}>
            Adicionar vaga
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal
        title="Editar Plantão"
        ref={editScheduleModalRef}
        component={EditScheduleModal}
        scheduleId={schedule.id}
      />
      <Modal
        title="Adicionar Vagas"
        ref={createVacancyModalRef}
        component={CreateVacancyModal}
        schedule={schedule}
      />
    </Flex>
  );
}
