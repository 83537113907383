export const selectOptionText = contract => {
  const {
    sector,
    start,
    end,
    specialty: { name },
  } = contract;

  const startHour = start.slice(0, -3);
  const endHour = end.slice(0, -3);

  return `${name} - ${sector} - ${startHour}h às ${endHour}h`;
};
