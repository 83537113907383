import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Flex } from '@chakra-ui/react';

import api from '~/services/api';

import { SubTitle } from '~/components/Kiwistrap/Page';

import options from './options';

export default function HourBars({ year, month }) {
  const [graphData, setGraphData] = useState();

  useEffect(() => {
    (async () => {
      const url = `/doctors/reports/myhours/graph?month=${month}&year=${year}`;

      let hoursData;

      const response = await api.get(url);
      hoursData = response.data.secondsByHospital;

      const hours = [];
      const companies = [];

      hoursData.forEach(hospital => {
        const workedHours = Math.abs((hospital.total / 3600).toFixed());

        if (workedHours > 0) {
          const companyName = hospital.hospital.company.name;

          const isIncluded = companies.find(company => company === companyName);

          if (!isIncluded) {
            hours.push(workedHours);
            companies.push(companyName);
          } else {
            const i = companies.findIndex(company => company === companyName);
            hours[i] = hours[i] + workedHours;
          }
        }
      });

      // Final react-chartjs-2 graph data object
      const graphDataObj = {
        datasets: [
          {
            data: hours,
            label: 'Horas',
            backgroundColor: '#2bd7f8',
          },
        ],
        labels: companies,
      };

      return setGraphData(graphDataObj);
    })();
  }, [month, year]);

  return (
    <Flex
      flexDir="column"
      padding="20px 20px 10px"
      borderRadius="4px"
      boxShadow="var(--shadow)"
      backgroundColor="var(--background-alt)"
    >
      <SubTitle padding="0 0 20px">Horas por empresa</SubTitle>
      <Flex flexDir="column" h="300px">
        <Bar data={graphData} options={options} />
      </Flex>
    </Flex>
  );
}
