import React from 'react';
import { HStack } from '@chakra-ui/react';

import { vacancyCardBackgroundColor, statusText } from '~/utils/genStatus';

import Section from './Section';
import Legend from './Legend';

import {
  Candidature,
  Container,
  Color,
  Info,
  RefusedSvg,
  PendingSvg,
  ExpiredSvg,
  ConfirmedSvg,
  CheckingSvg,
  CheckoutSvg,
  SunSvg,
  MoonSvg,
  PendingCheckingSvg,
  PendingCheckoutSvg,
  OpenChangeSvg,
  ChangeSvg,
  ObservationSvg,
} from './styles';

const VacancyLegends = () => (
  <Container>
    <HStack align="flex-start" spacing={4}>
      <Section header="Vagas">
        <Legend>
          <Color color={vacancyCardBackgroundColor('open')} />
          <Info>{statusText('open')}</Info>
        </Legend>
        <Legend>
          <Color color={vacancyCardBackgroundColor('weekly')} />
          <Info>{statusText('weekly')}</Info>
        </Legend>
        <Legend>
          <Color color={vacancyCardBackgroundColor('exception')} />
          <Info>{statusText('exception')}</Info>
        </Legend>
        {/* <Legend>
          <Color color={vacancyCardBackgroundColor('pendingDoctor')} />
          <Info>{statusText('pendingDoctor')}</Info>
        </Legend> */}
        <Legend>
          <Color color={vacancyCardBackgroundColor('cover')} />
          <Info>{statusText('cover')}</Info>
        </Legend>
      </Section>
      <Section header="Status Do Check-in">
        <Legend>
          <CheckingSvg />
          <Info>{statusText('checkin')}</Info>
        </Legend>
        <Legend>
          <CheckoutSvg />
          <Info>{statusText('checkout')}</Info>
        </Legend>
        <Legend>
          <PendingCheckingSvg />
          <Info>{statusText('pendingCheckin')}</Info>
        </Legend>
        <Legend>
          <PendingCheckoutSvg />
          <Info>{statusText('pendingCheckout')}</Info>
        </Legend>
        <Legend>
          <ExpiredSvg />
          <Info>{statusText('expired')}</Info>
        </Legend>
      </Section>
      <Section header="Checagem Pelo Médico">
        <Legend>
          <PendingSvg />
          <Info>{statusText('pending')}</Info>
        </Legend>
        <Legend>
          <RefusedSvg />
          <Info>{statusText('refused')}</Info>
        </Legend>
        <Legend>
          <ConfirmedSvg />
          <Info>{statusText('accepted')}</Info>
        </Legend>
      </Section>
      <Section header="Trocas">
        <Legend>
          <Candidature>+9</Candidature>
          <Info>
            Manifestação de
            <br />
            Interesse
          </Info>
        </Legend>
        <Legend>
          <OpenChangeSvg />
          <Info>Aberto Para Troca</Info>
        </Legend>
        <Legend>
          <ChangeSvg />
          <Info>Plantão Trocado</Info>
        </Legend>
      </Section>
      <Section header="Situação">
        <Legend>
          <ObservationSvg />
          <Info>Observações</Info>
        </Legend>
        <Legend>
          <SunSvg />
          <Info>Diurno</Info>
        </Legend>
        <Legend>
          <MoonSvg />
          <Info>Noturno</Info>
        </Legend>
      </Section>
    </HStack>
  </Container>
);

export default VacancyLegends;
