import React, { useCallback } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { toast } from 'react-toastify';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';

import { Button } from '~/components/ChakraComponents/';

export default function DeleteUserModal({ user, closeModal }) {
  const handleDelete = useCallback(() => {
    api
      .patch(`/companies/users/deactivate/${user.id}`)
      .then(() => window.location.reload())
      .catch(e => {
        if (e.response?.data?.error === 'User is company admin.') {
          return toast.error(
            'Não é possível apagar o administrador do sistema.'
          );
        }

        return internalServerError();
      });
  }, [user.id]);

  return (
    <Flex flexDir="column">
      <Text
        mb={3}
        color="red.500"
        fontSize="xl"
        fontWeight="bold"
        textAlign="center"
      >
        Essa ação não poderá ser desfeita!
      </Text>
      <Text
        fontSize="md"
        color="gray.600"
        fontWeight="semibold"
        textAlign="center"
      >
        {user.name}
      </Text>
      <Text
        fontSize="md"
        color="gray.600"
        fontWeight="semibold"
        textAlign="center"
      >
        {user.email}
      </Text>
      <Flex mt={5} justifyContent="space-between">
        <Button
          margin="0 20px 0 0"
          title="cancelar"
          variant="secondary"
          onClick={closeModal}
        />
        <Button title="excluír" variant="danger" onClick={handleDelete} />
      </Flex>
    </Flex>
  );
}
