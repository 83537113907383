import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';

import api from '~/services/api';
import phoneMask from '~/utils/phoneMask';
import defaultAvatar from '~/constants/defaultAvatar';

import { useAuth } from '~/hooks/AuthContext';

import IsAllowed from '~/components/UI/IsAllowed';

import { Button } from '~/components/ChakraComponents/';
import { UserInfo } from '~/components/UI/Layouts/';

export default function SideMenu() {
  const { signOut } = useAuth();

  const [title, setTitle] = useState('');
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [subtitle, setSubtitle] = useState('');

  useEffect(() => {
    api.get('/companies/companyprofile').then(response => {
      setTitle(response.data.name);
      setAvatar(response.data.avatar);
      setSubtitle(phoneMask(response.data.phone1));
    });
  }, []);

  return (
    <Flex
      display={['none', 'none', 'none', 'flex']}
      w="350px"
      p="20px 10px"
      flexDir="column"
      align="center"
    >
      <UserInfo
        title={title}
        avatar={avatar}
        subtitle={subtitle}
        avatarUploadUrl={'/companies/companyprofile'}
      />
      <IsAllowed id={15}>
        <Button margin="0 0 20px" title="perfil" to="/company/profile" />
      </IsAllowed>
      <Button title="sair" onClick={signOut} />
    </Flex>
  );
}
