import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { FaStreetView } from 'react-icons/fa';
import { GiSkeletalHand } from 'react-icons/gi';
import { BsPhone } from 'react-icons/bs';

import api from '~/services/api';
import history from '~/services/history';

import phoneMask from '~/utils/phoneMask';

import PageLayout from '~/pages/_layouts/doctor/PageLayout';

import { Row } from '~/components/Kiwistrap/Page';
import { Button } from '~/components/ChakraComponents/';
import { Modal } from '~/components/UI/Modal';

import InfoSection from './components/InfoSection';
import EditPasswordModal from './components/EditPasswordModal';
import DeleteAccountModal from './components/DeleteAccountModal';

export default function Profile() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [profileData, setProfileData] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.get('/doctors/myprofile');
      setProfileData(response.data);
      setLoadingPage(false);
    })();
  }, []);

  const editPasswordRef = useRef(null);
  const deleteAccountRef = useRef(null);

  const openEditPassword = useCallback(() => {
    return editPasswordRef.current.openModal();
  }, []);

  const closeEditPassword = useCallback(() => {
    return editPasswordRef.current.closeModal();
  }, []);

  const openDeleteAccount = useCallback(() => {
    return deleteAccountRef.current.openModal();
  }, []);

  const closeDeleteAccount = useCallback(() => {
    return deleteAccountRef.current.closeModal();
  }, []);

  return (
    <PageLayout title="Meu Perfil" loading={loadingPage}>
      {profileData && (
        <>
          <InfoSection
            icon={FaStreetView}
            title="Endereço"
            info={`${profileData.cep} - ${profileData.uf}, ${profileData.city}, ${profileData.address}, ${profileData.street}, ${profileData.number}`}
          />
          <InfoSection
            icon={AiOutlineMail}
            title="E-mail"
            info={profileData.user.email}
          />
          <InfoSection
            icon={BsPhone}
            title="Telefone"
            info={`${phoneMask(profileData.user.phone1)} ${phoneMask(
              profileData.user.phone2
            )}`}
          />
          <InfoSection
            icon={GiSkeletalHand}
            title="Especialidade"
            info={profileData.specialty.name}
          />
          <Row margin="20px 0">
            <Button title="documentos" mr="20px" to="/medico/documentos" />
            <Button
              title="editar perfil"
              onClick={() => history.push('/medico/editar')}
            />
            <Button
              margin="0px 20px"
              title="editar senha"
              onClick={openEditPassword}
            />
            <Button
              title="excluir conta"
              variant="danger"
              onClick={openDeleteAccount}
            />
          </Row>
          <Modal
            ref={editPasswordRef}
            title="Editar Senha"
            component={EditPasswordModal}
            onClose={closeEditPassword}
          />
          <Modal
            ref={deleteAccountRef}
            title="Excluir Conta"
            component={DeleteAccountModal}
            onClose={closeDeleteAccount}
          />
        </>
      )}
    </PageLayout>
  );
}
