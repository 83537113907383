import React, { useCallback } from 'react';
import { Tooltip } from '@chakra-ui/react';

import genStatus, { statusText } from '~/utils/genStatus';

import {
  IconContainer,
  RefusedSvg,
  PendingSvg,
  ConfirmedSvg,
} from './styles';

export default function IconStatus({ vacancy, isMonth = false }) {
  const renderIcon = useCallback(
    (statusColor, Component) => (
      <Tooltip hasArrow label={statusText(genStatus(vacancy))} bg="gray.600">
        <span>
          <IconContainer statusColor={statusColor} isMonth={isMonth}>
            <Component />
          </IconContainer>
        </span>
      </Tooltip>
    ),
    []
  );

  const iconDictionary = useCallback(() => {
    return {
      refused: renderIcon('var(--red)', RefusedSvg),
      pending: renderIcon('var(--yellow)', PendingSvg),
      accepted: renderIcon('var(--blue)', ConfirmedSvg),
    }[genStatus(vacancy)];
  }, [vacancy]);

  return <>{iconDictionary(vacancy)}</>;
}
