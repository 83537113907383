import React from 'react';
import { FaDesktop, FaUserCog, FaUsers, FaHospitalAlt } from 'react-icons/fa';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Text,
} from '@chakra-ui/react';

import { getUser } from '~/services/auth';
import { useAuth } from '~/hooks/AuthContext';

import NavItem from './NavItem';

export default function DrawerMenu({ isOpen, onClose, drawerButtonRef }) {
  const user = getUser();

  const { signOut } = useAuth();

  return (
    <Drawer
      placement="right"
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={drawerButtonRef}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <Flex w="100%" flexDir="column">
              <NavItem
                closeDrawer={onClose}
                href="/contractor/profile"
                text="Perfil"
                icon={FaUserCog}
              />
              <NavItem
                closeDrawer={onClose}
                href="/contractor/dashboard"
                text="Dashboard"
                icon={FaDesktop}
              />
              <NavItem
                closeDrawer={onClose}
                href="/contractor/myhospitals"
                text="Meus Hospitais"
                icon={FaHospitalAlt}
              />
              {user.allPermissions && (
                <NavItem
                  closeDrawer={onClose}
                  href="/contractor/users"
                  text="Usuários"
                  icon={FaUsers}
                />
              )}
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Flex
              onClick={signOut}
              as="button"
              w="148px"
              h="48px"
              px={2}
              alignItems="center"
              justifyContent="center"
              backgroundColor="var(--primary)"
              borderRadius="lg"
              _hover={{ backgroundColor: 'var(--hover-primary)' }}
            >
              <Text
                color="white"
                fontSize="12px"
                fontWeight="bold"
                textAlign="center"
                textTransform="uppercase"
              >
                Sair do site
              </Text>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
