import moment from 'moment';

import api from '~/services/api';
import userKey from '~/utils/userKey';
import { getParam } from '~/utils/url';

const apiUrl = params => {
  const { hospitalId } = getParam();
  const { startDate, endDate, contract, currentPage, limit, type } = params;

  const offset = (currentPage - 1) * limit;

  return {
    company: `/companies/myhospitals/companyresume/details?startDate=${startDate}&endDate=${endDate}&type=${type}&limit=${limit}&offset=${offset}`,
    contractor: `/contractors/myhospitals/contractorresume/details?startDate=${startDate}&endDate=${endDate}&type=${type}&limit=${limit}&offset=${offset}`,
    companyhospital: `/companies/myhospitals/${hospitalId}/resume/details?startDate=${startDate}&endDate=${endDate}&contract=${contract}&limit=${limit}&offset=${offset}&type=${type}`,
    contractorhospital: `/contractors/myhospitals/${hospitalId}/resume/details?startDate=${startDate}&endDate=${endDate}&contract=${contract}&limit=${limit}&offset=${offset}&type=${type}`,
  }[userKey()];
};

const formatResponse = response => {
  response.data.data.sort((a, b) => {
    return moment(`1996-09-13 ${b.schedule.contract.start}`).diff(
      moment(`1996-09-13 ${a.schedule.contract.start}`)
    );
  });

  response.data.data.sort((a, b) => {
    return moment(b.date).diff(moment(a.date));
  });

  return response;
};

export const fetchResumeDetails = (callback, params) => {
  api.get(apiUrl(params)).then(response => callback(formatResponse(response)));
};
