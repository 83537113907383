import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Flex, Text, VStack } from '@chakra-ui/react';

import api from '~/services/api';

import ListInvite from './ListInvite';
import FindDoctorInput from './FindDoctorInput';
import CreateInviteButton from './CreateInviteButton';

import Pagination from '~/components/Dashboard/Pagination';
import { Spinner, Divider } from '~/components/ChakraComponents/';

const limit = 12;

export default function MyDoctorsPanel() {
  const [crm, setCrm] = useState('');
  const [crmUf, setCrmUf] = useState('');
  const [total, setTotal] = useState(0);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = useCallback(() => {
    const offset = (currentPage - 1) * limit;

    const url = `/companies/mydoctors/invitelink?limit=${limit}&offset=${offset}&crm=${crm}&crmUf=${crmUf}&order=DESC`;

    api.get(url).then(response => {
      setTotal(response.data.total);
      setDoctors(response.data.data);

      setLoading(false);
    });
  }, [currentPage, crm, crmUf, limit]);

  useEffect(() => {
    fetchData();
  }, [currentPage, crm, crmUf, fetchData]);

  return (
    <>
      <VStack align="strech">
        <Flex
          flexDir={[
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'row',
          ]}
          align="flex-end"
          justify="space-between"
        >
          <FindDoctorInput setCrm={setCrm} setCrmUf={setCrmUf} />
          <CreateInviteButton fetchData={fetchData} />
        </Flex>
        {loading ? (
          <Flex
            h="65px"
            align="center"
            justify="center"
            border="1px solid var(--dim-gray)"
            borderRadius="md"
            bgColor="var(--background-alt)"
          >
            <Spinner />
          </Flex>
        ) : (
          <VStack
            py={2}
            align="strech"
            border="1px solid var(--dim-gray)"
            borderRadius="md"
            bgColor="var(--background-alt)"
            divider={<Divider />}
          >
            {doctors.length > 0 ? (
              doctors.map(doctor => (
                <ListInvite key={doctor.id} doctor={doctor} />
              ))
            ) : (
              <Text pl={4}>Nenhum convite.</Text>
            )}
          </VStack>
        )}
      </VStack>
      <Pagination l={limit} t={total} onClick={setCurrentPage} />
    </>
  );
}
