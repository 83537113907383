import styled from 'styled-components';

export const InputLabel = styled.span`
  margin-bottom: 5px;

  text-transform: uppercase;
  text-align: left;

  font-size: 15px;
  font-weight: bold;

  color: ${props => (props.color ? props.color : 'var(--primary)')};
`;
