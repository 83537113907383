import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import { IText } from '~/components/UI/Inputs';
import { Button } from '~/components/ChakraComponents';
import { ModalContainer } from '~/components/UI/Modal';

const today = moment(new Date())
  .add(1, 'days')
  .format('DD/MM/YYYY');

export default function DeleteVacancyModal({ vacancy }) {
  const [date, setDate] = useState(today);

  const { hospitalId } = getParam('hospitalId');

  const url = `/companies/myhospitals/${hospitalId}/schedules/${vacancy.schedule.id}/vacancies/${vacancy.id}`;

  const deleteToDate = () => {
    const formattedDate = date
      .split('/')
      .reverse()
      .join('-');

    const request = { toDate: formattedDate };

    api
      .delete(url, { data: request })
      .then(() => {
        toast.success('Vaga deletada com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  };

  const deleteIsToEndSchedule = () => {
    const request = { isToEndSchedule: true };

    api
      .delete(url, { data: request })
      .then(() => {
        toast.success('Vaga deletada com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  };

  return (
    <ModalContainer w="400px">
      <Flex
        mb={4}
        justify="center"
        color="var(--red)"
        fontWeight="bold"
        fontSize="lg"
      >
        <Text textAlign="center">Essa ação não poderá ser desfeita!</Text>
      </Flex>
      <Flex align="flex-end">
        <IText
          required
          label="excluír até data"
          placeholder={`Ex: ${today}`}
          useCleave
          options={{
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          }}
          value={date}
          style={{ marginRight: 20 }}
          onChange={e => setDate(e.target.value)}
        />
        <Button
          title="excluír"
          height="40px"
          variant="danger"
          onClick={deleteToDate}
        />
      </Flex>
      <Flex w="100%" align="center" flexDir="column">
        <Text my={4}>Ou</Text>
        <Button
          title="excluír todos até fim da escala"
          width="100%"
          variant="danger"
          onClick={deleteIsToEndSchedule}
        />
      </Flex>
    </ModalContainer>
  );
}
