import styled from 'styled-components';

export const DatePicker = styled.input`
  width: 155px;
  height: 40px;

  padding: 5px;

  border: 2px solid var(--black);
  border-radius: var(--br-sm);

  background-color: var(--background-alt);
`;
