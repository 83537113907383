import * as Yup from 'yup';

export const initialValues = {
  email: '',
  phone1: '',
  password: '',
  password_confirm: '',
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido.')
    .required('Campo obrigatório.'),

  phone1: Yup.string()
    .min(12, 'Telefone inválido.')
    .max(13, 'Telefone inválido.')
    .required('Campo obrigatório.'),

  password: Yup.string()
    .min(7, 'A senha deve conter no mínimo 7 caracteres.')
    .max(32, 'A senha deve conter no máximo 32 caracteres.')
    .required('Campo obrigatório.'),

  password_confirm: Yup.string()
    .min(7, 'A senha deve conter no mínimo 7 caracteres.')
    .max(32, 'A senha deve conter no máximo 32 caracteres.')
    .required('Campo obrigatório.'),
});
