import styled from 'styled-components';

export const Container = styled.div`
  display: none;
  width: 350px;
  padding: 50px 0;

  @media screen and (min-width: 1180px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const UserAvatar = styled.div`
  position: relative;
  width: 194px;
  height: 194px;
  border-radius: 100px;
  border: 5px solid var(--primary);
  object-fit: cover;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
    flex-shrink: none;
  }

  section {
    display: none;
  }

  &:hover {
    section {
      cursor: pointer;
      position: absolute;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      color: #ffff;
      font-weight: bold;
      span {
        padding-top: 130px;
      }
    }
  }

  @media (max-width: 1280px) {
    width: 170px;
    height: 170px;
  }
`;

export const UserName = styled.span`
  font-size: 26px;
  color: var(--light-gray);
  font-weight: 700;
  margin-top: 30px;
  text-align: center;
`;

export const UserEmail = styled.span`
  font-size: 16px;
  color: var(--light-gray);
  margin: 20px 0;
  font-weight: 400;
  text-align: center;
`;

export const BtnContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, auto);
  grid-gap: 20px;
`;

export const InputFile = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;
