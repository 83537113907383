import styled from 'styled-components';
import {
  FaTimes,
  FaClock,
  FaHourglassEnd,
  FaCheck,
  FaUserEdit,
  FaUserCheck,
} from 'react-icons/fa';

export const SvgContainer = styled.div`
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: none;

  border-radius: 50%;

  background-color: ${props => props.statusColor};

  svg {
    font-size: 16px;

    color: var(--background-alt);
  }
`;

export const RefusedSvg = styled(FaTimes)``;
export const PendingSvg = styled(FaClock)``;
export const ExpiredSvg = styled(FaHourglassEnd)``;
export const ConfirmedSvg = styled(FaCheck)``;
export const CheckinSvg = styled(FaUserEdit)``;
export const CheckoutSvg = styled(FaUserCheck)``;
