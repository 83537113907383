import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getDates, fetchSchedules } from '~/pages/hospital/VacanciesV2/utils';

import Nav from './Nav';
import GroupsAgenda from './GroupsAgenda';
import { Spinner } from '~/components/ChakraComponents';
import { MobileContainer } from './styles';

export default function GroupsPage({ baseUrl, calendar }) {
  const { scheduleType, vacancyDate } = useParams();

  const [dates, setDates] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    const [startDate, endDate] = getDates(vacancyDate, scheduleType);

    const url = `${baseUrl}?startDate=${startDate}&endDate=${endDate}`;

    fetchSchedules(
      url,
      (newDates, newSchedules) => {
        setDates(newDates);
        setSchedules(newSchedules);
        setLoading(false);
      },
      { isGroup: true }
    );
  };

  useEffect(() => {
    if (loading) fetchData();
  }, [loading]);

  useEffect(() => {
    setLoading(true);

    localStorage.setItem('vacancyDate', vacancyDate);
    localStorage.setItem('scheduleType', scheduleType);
  }, [scheduleType, vacancyDate]);

  return (
    <MobileContainer>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <GroupsAgenda schedules={schedules} agendaDates={dates} />
          <Nav calendar={calendar} />
        </>
      )}
    </MobileContainer>
  );
}
