import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Flex, HStack } from '@chakra-ui/react';

import { IDate } from '~/components/UI/Inputs';
import { ModalContainer } from '~/components/UI/Modal';
import { Button, Divider } from '~/components/ChakraComponents';

export default function DateModal({
  closeModal,
  setEndDate,
  setStartDate,
  setLoadingInfo,
  setResumeStatus,
}) {
  const [newEndDate, setNewEndDate] = useState();
  const [newStartDate, setNewStartDate] = useState();

  const onSubmit = () => {
    if (!newStartDate || !newEndDate) {
      return toast.error('Todos os campos são obrigatórios');
    }

    if (moment(new Date(newStartDate)).diff(new Date(newEndDate), 'days') > 0) {
      return toast.error('Data final não pode ser menor que inicial.');
    }

    setLoadingInfo(true);

    setEndDate(newEndDate);
    setStartDate(newStartDate);

    setResumeStatus('toDate');

    closeModal();
  };

  return (
    <ModalContainer w="300px">
      <HStack>
        <IDate
          required
          label="data inicial"
          onChange={date => setNewStartDate(date)}
        />
        <IDate
          required
          label="data final"
          onChange={date => setNewEndDate(date)}
        />
      </HStack>
      <Divider />
      <Flex>
        <Button width="100%" title="buscar" onClick={onSubmit} />
      </Flex>
    </ModalContainer>
  );
}
