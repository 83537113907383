import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';

import {
  ContainerWeb,
  SectionUteis,
  SectionList,
  TitleUteis,
  ListAll,
  ListItem,
  SectionCopyright,
  TitleCopyright,
  ContainerMobile,
  TitleSociais,
  SectionSociais,
} from './styles';

export default function Footer() {
  return (
    <>
      <ContainerWeb>
        <SectionUteis>
          <SectionList>
            <TitleUteis>Links Úteis</TitleUteis>
            <ListAll>
              <ListItem>
                <a href="/#home">Home</a>
              </ListItem>
              <ListItem>
                <a href="/#produto">Produto</a>
              </ListItem>
              <ListItem>
                <a href="/#contato">Contato</a>
              </ListItem>
              <ListItem>
                <Link to="/contato">Fale Conosco</Link>
              </ListItem>
            </ListAll>
          </SectionList>
          <SectionCopyright>
            <TitleCopyright>
              2020 - Meu Plantão - Todos os direitos reservados
            </TitleCopyright>
          </SectionCopyright>
          <SectionSociais>
            <a href="https://www.facebook.com/MeuPlantao" target="_blank">
              <FaFacebook
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a href="https://www.instagram.com/meuplantao/" target="_blank">
              <FaInstagram
                size={30}
                color="var(--background-alt)"
                style={{ marginRight: 40 }}
              />
            </a>
            <a href="#" target="_blank">
              <FaWhatsapp size={30} color="var(--background-alt)" />
            </a>
          </SectionSociais>
        </SectionUteis>
      </ContainerWeb>
      <ContainerMobile>
        <TitleSociais>Siga-nos nas redes sociais</TitleSociais>
        <SectionSociais>
          <a href="https://www.facebook.com/MeuPlantao" target="_blank">
            <FaFacebook
              size={30}
              color="var(--background-alt)"
              style={{ marginRight: 40 }}
            />
          </a>
          <a href="https://www.instagram.com/meuplantao/" target="_blank">
            <FaInstagram
              size={30}
              color="var(--background-alt)"
              style={{ marginRight: 40 }}
            />
          </a>
          <a href="#" target="_blank">
            <FaWhatsapp size={30} color="var(--background-alt)" />
          </a>
        </SectionSociais>
      </ContainerMobile>
    </>
  );
}
