import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { Flex, HStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FaCog } from 'react-icons/fa';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import ProgressModal from '~/components/Dashboard/Modals/ProgressModal';
import ConfigScheduleModal from './ConfigScheduleModal';

import { Label } from '~/components/UI/Text/';
import { Button, Divider } from '~/components/ChakraComponents';
import { Modal, ModalContainer } from '~/components/UI/Modal';
import {
  IText,
  IDate,
  ISelect,
  IArea,
  IRadio,
  ICheck,
} from '~/components/UI/Inputs/';
import IsAllowed from '~/components/UI/IsAllowed';

export default function CreateSchedule({ closeModal }) {
  const { hospitalId } = getParam('hospitalId');
  const [loading, setLoading] = useState(true);
  const [loadingPost, setLoadingPost] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [groups, setGroups] = useState([]);

  const [group, setGroup] = useState('');
  const [contract, setContract] = useState();
  const [daysToAlert, setDaysToAlert] = useState(0);
  const [availability, setAvailability] = useState('public');
  const [publicDescription, setPublicDescription] = useState();
  const [privateDescription, setPrivateDescription] = useState();
  const [defaultDoctorValue, setDefaultDoctorValue] = useState(0);
  const [isAutoCheckout, setIsAutoCheckout] = useState(true);
  const [isPhotoCheckin, setIsPhotoCheckin] = useState(true);
  const [isPhotoCheckout, setIsPhotoCheckout] = useState(true);
  const [isCheckinLocalization, setIsCheckinLocalization] = useState(true);
  const [isCheckoutLocalization, setIsCheckoutLocalization] = useState(true);
  const [doctorChangeFlow, setDoctorChangeFlow] = useState(true);
  const [date, setDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sunday, setSunday] = useState(0);
  const [monday, setMonday] = useState(0);
  const [tuesday, setTuesday] = useState(0);
  const [wednesday, setWednesday] = useState(0);
  const [thursday, setThursday] = useState(0);
  const [friday, setFriday] = useState(0);
  const [saturday, setSaturday] = useState(0);
  const [configautoapprove, setConfigautoapprove] = useState([]);

  const configScheduleModalRef = useRef(null);

  const openConfigSchedule = useCallback(() => {
    configScheduleModalRef.current.openModal();
  }, []);

  useEffect(() => {
    api
      .get(`/companies/myhospitals/${hospitalId}/contracts/`)
      .then(contractsResponse => {
        const filteredVacancies = contractsResponse.data.data.filter(
          vacancy => {
            return !vacancy.schedule && vacancy.isActive;
          }
        );

        filteredVacancies.sort((a, b) => {
          if (a.specialty.name < b.specialty.name) return -1;
          if (a.specialty.name > b.specialty.name) return 1;
          return 0;
        });

        const contractsArr = [
          { id: 0, value: false, text: 'Por favor selecione um plantão...' },
        ];

        filteredVacancies.forEach(vacancy => {
          const {
            id,
            sector,
            specialty: { name },
            start,
            end,
          } = vacancy;

          const startHour = start.slice(0, -3);
          const endHour = end.slice(0, -3);

          const text = `${name} - ${sector} - ${startHour}h às ${endHour}h`;

          const selectOption = {
            id,
            value: id,
            text,
          };

          return contractsArr.push(selectOption);
        });

        setContracts(contractsArr);

        api
          .get(`/companies/myhospitals/${hospitalId}/schedulesgroup`)
          .then(groupResponse => {
            groupResponse.data.data.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            });

            const groupsArr = [{ id: 0, value: false, text: 'Nenhuma' }];

            groupResponse.data.data.forEach(group => {
              const { id, name } = group;

              const selectOption = {
                id,
                value: id,
                text: name,
              };

              return groupsArr.push(selectOption);
            });

            setGroups(groupsArr);

            setLoading(false);
          });
      });
  }, []);

  const validateInputs = () => {
    const today = moment(new Date()).format('YYYY-MM-DD');

    const startEndDif = moment(date).diff(endDate, 'days');
    const todayStartDif = moment(date).diff(today, 'days');
    const startEndYearDif = moment(date).diff(endDate, 'years');

    if (!contract) {
      return toast.error('Por favor selecione um plantão.');
    }

    if (!date || date.length < 10) {
      return toast.error('Por favor insira uma data inicial válida.');
    }

    if (!endDate || endDate.length < 10) {
      return toast.error('Por favor insira uma data final válida.');
    }

    if (startEndDif > 0) {
      return toast.error('Data final não pode ser menor que a data inicial.');
    }

    if (todayStartDif < 0) {
      return toast.error('Data inicial não pode ser menor que hoje.');
    }

    if (startEndYearDif < -6) {
      return toast.error('A escala deve ter no máximo 6 anos.');
    }

    if (defaultDoctorValue < 0) {
      return toast.error('Por favor insira o valor de honorários.');
    }

    if (daysToAlert < 0) {
      return toast.error('Por favor insira os dias até emergência .');
    }

    return setLoadingPost(true);
  };

  const handleSubmit = () => {
    const request = {
      date,
      endDate,
      defaultDoctorValue,
      availability,
      publicDescription,
      privateDescription,
      contract,
      daysToAlert,
      isAutoCheckout,
      isCheckinLocalization,
      isCheckoutLocalization,
      isPhotoCheckin,
      isPhotoCheckout,
      doctorChangeFlow,
      configautoapprove: configautoapprove[0],
      vacancies: [
        {
          dayOfWeek: 0,
          qtdVacancies: Math.floor(+sunday) || 0,
        },
        {
          dayOfWeek: 1,
          qtdVacancies: Math.floor(+monday) || 0,
        },
        {
          dayOfWeek: 2,
          qtdVacancies: Math.floor(+tuesday) || 0,
        },
        {
          dayOfWeek: 3,
          qtdVacancies: Math.floor(+wednesday) || 0,
        },
        {
          dayOfWeek: 4,
          qtdVacancies: Math.floor(+thursday) || 0,
        },
        {
          dayOfWeek: 5,
          qtdVacancies: Math.floor(+friday) || 0,
        },
        {
          dayOfWeek: 6,
          qtdVacancies: Math.floor(+saturday) || 0,
        },
      ],
    };

    if (group) request.group = group;

    const url = `/companies/myhospitals/${hospitalId}/schedules`;

    api
      .post(url, request)
      .then(() => {
        toast.success('Vagas criadas com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);

    return setLoading(false);
  };

  useEffect(() => {
    if (loadingPost) handleSubmit();
  }, [loadingPost]);

  return (
    <ModalContainer loading={loading}>
      <ISelect
        required
        label="plantão"
        options={contracts}
        onChange={e => setContract(e.target.value)}
      />
      <ISelect
        label="escala"
        options={groups}
        onChange={e => setGroup(e.target.value)}
      />
      <Divider />
      <HStack>
        <IDate required label="data inicial" onChange={d => setDate(d)} />
        <IDate required label="data final" onChange={d => setEndDate(d)} />
      </HStack>
      <HStack>
        <IText
          required
          label="honorários médico"
          placeholder="R$ 1000"
          useCleave
          options={{
            numeral: true,
            delimiter: '',
          }}
          value={defaultDoctorValue}
          onChange={e => setDefaultDoctorValue(e.target.value)}
        />
        <IText
          required
          label="dias até emergência"
          placeholder="Ex: 7"
          maxLength={2}
          value={daysToAlert}
          onChange={e => setDaysToAlert(+e.target.value)}
        />
      </HStack>
      <Divider />
      <Label>Plantões por dia da semana</Label>
      <HStack>
        <IText
          required
          label="segunda"
          useCleave="true"
          options={{ numeral: true }}
          maxLength="2"
          value={monday}
          onChange={e => setMonday(e.target.value)}
        />
        <IText
          required
          label="terça"
          useCleave="true"
          options={{ numeral: true }}
          maxLength="2"
          value={tuesday}
          onChange={e => setTuesday(e.target.value)}
        />
        <IText
          required
          label="quarta"
          useCleave="true"
          options={{ numeral: true }}
          maxLength="2"
          value={wednesday}
          onChange={e => setWednesday(e.target.value)}
        />
        <IText
          required
          label="quinta"
          useCleave="true"
          options={{ numeral: true }}
          maxLength="2"
          value={thursday}
          onChange={e => setThursday(e.target.value)}
        />
      </HStack>
      <HStack>
        <IText
          required
          label="sexta"
          useCleave="true"
          options={{ numeral: true }}
          maxLength="2"
          value={friday}
          onChange={e => setFriday(e.target.value)}
        />
        <IText
          required
          label="sábado"
          useCleave="true"
          options={{ numeral: true }}
          maxLength="2"
          value={saturday}
          onChange={e => setSaturday(e.target.value)}
        />
        <IText
          required
          label="domingo"
          useCleave="true"
          options={{ numeral: true }}
          maxLength="2"
          value={sunday}
          onChange={e => setSunday(e.target.value)}
        />
      </HStack>
      <Divider />
      <IArea
        label="descrição pública"
        placeholder="Informe a descrição pública..."
        value={publicDescription}
        onChange={e => setPublicDescription(e.target.value)}
      />
      <IArea
        label="descrição administrativa"
        placeholder="Informe a descrição administrativa..."
        value={privateDescription}
        onChange={e => setPrivateDescription(e.target.value)}
      />
      <Divider />
      <Label>disponibilidade para candidaturas</Label>
      <HStack>
        <IRadio
          label="Público"
          checked={availability === 'public'}
          onChange={() => setAvailability('public')}
        />
        <IRadio
          mx="20px"
          label="Somente Vinculados"
          checked={availability === 'linked'}
          onChange={() => setAvailability('linked')}
        />
        <IRadio
          label="Fechada"
          checked={availability === 'private'}
          onChange={() => setAvailability('private')}
        />
      </HStack>
      <Divider />
      <Label>Opções Adicionais</Label>
      <Flex my={2} justify="space-between">
        <Flex w="90%" flexDir="column" align="flex-start">
          <ICheck
            label="Solicitar Localização no Checkin"
            checked={isCheckinLocalization}
            onChange={() => setIsCheckinLocalization(!isCheckinLocalization)}
          />
          <ICheck
            label="Solicitar Localização no Checkout"
            checked={isCheckoutLocalization}
            onChange={() => setIsCheckoutLocalization(!isCheckoutLocalization)}
          />
        </Flex>
        <Flex w="90%" flexDir="column" align="flex-start">
          <ICheck
            label="Solicitar Foto no Checkin"
            checked={isPhotoCheckin}
            onChange={() => setIsPhotoCheckin(!isPhotoCheckin)}
          />
          <ICheck
            label="Solicitar Foto no Checkout"
            checked={isPhotoCheckout}
            onChange={() => setIsPhotoCheckout(!isPhotoCheckout)}
          />
        </Flex>
      </Flex>
      <Flex mt="0px !important">
        <Flex w="90%" flexDir="column" alignItems="flex-start">
          <ICheck
            label="Realização de Checkout Automático"
            checked={isAutoCheckout}
            onChange={() => setIsAutoCheckout(!isAutoCheckout)}
          />
        </Flex>
        <Flex w="90%" flexDir="column" alignItems="flex-start">
          <ICheck
            label="Habilitar Troca de Médico"
            checked={doctorChangeFlow}
            onChange={() => setDoctorChangeFlow(!doctorChangeFlow)}
          />
        </Flex>
      </Flex>
      <Divider />
      <Flex pb="2px" align="center" justify="space-between">
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <IsAllowed id={26}>
          <Button
            width="auto"
            height="40px"
            icon2={<FaCog size={20} />}
            mr={14}
            onClick={openConfigSchedule}
          />
        </IsAllowed>
        <Button
          title="criar vagas"
          variant="success"
          onClick={validateInputs}
        />
      </Flex>
      <ProgressModal
        loading={loadingPost}
        text="Crianda vagas... Por favor aguarde, isso pode demorar alguns minutos."
      />
      <Modal
        title="Configuração Aprovação automático"
        ref={configScheduleModalRef}
        component={ConfigScheduleModal}
        setConfigSelected={setConfigautoapprove}
        configSelected={configautoapprove && configautoapprove}
      />
    </ModalContainer>
  );
}
