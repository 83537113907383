import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FaPlusCircle } from 'react-icons/fa';

import api from '~/services/api';
import internalServerError from '~/utils/internalServerError';
import { getParam } from '~/utils/url';

import ProgressModal from '~/components/Dashboard/Modals/ProgressModal';

import { Button } from '~/components/ChakraComponents/';
import { ModalContainer } from '~/components/UI/Modal';
import { IText, ISpec, IArea } from '~/components/UI/Inputs';

import { UploadDocBtn } from './styles';

export default function CreateProtocolModal({ closeModal }) {
  const [showProgress, setShowProgress] = useState(false);

  const [inputs, setInputs] = useState({});
  const [document, setDocument] = useState(null);

  const { hospitalId } = getParam('hospitalId');

  const handleChanges = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleUploadDocument = async upload => {
    setShowProgress(true);

    const url = `/companies/myhospitals/${hospitalId}/protocols/files`;

    const data = new FormData();

    data.append('file', upload);

    api
      .post(url, data)
      .then(response => {
        setDocument(response.data[0].filename);
        toast.success('Upload realizado com sucesso!');
      })
      .catch(() => toast.error('Falha ao fazer upload, tente novamente.'));

    setShowProgress(false);
  };

  const handleSubmit = async () => {
    if (document === null) {
      return toast.error(
        'Faça o upload do seu arquivo ou aguarde o carregamento!'
      );
    }

    const url = `/companies/myhospitals/${hospitalId}/protocols`;

    const { name, specialty, description } = inputs;

    const request = {
      name,
      specialty,
      description,
      hospital: hospitalId,
      bucketName: document,
    };

    api
      .post(url, request)
      .then(() => {
        toast.success('Protocolo criado com sucesso!');
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(internalServerError);
  };

  return (
    <ModalContainer>
      <IText
        required
        label="nome do protocolo"
        name="name"
        placeholder="Ex: Câncer de Pulmão(DDT)"
        onChange={handleChanges}
      />
      <ISpec
        setSpecialtyId={specialty => setInputs({ ...inputs, specialty })}
      />
      <IArea
        required
        label="observações do protocolo"
        name="description"
        rows="10"
        placeholder="Escreva suas observações aqui...."
        onChange={handleChanges}
      />
      <UploadDocBtn>
        <label htmlFor="upload-document">
          <FaPlusCircle size="35" color="#5d6bd9" />
          <span>Anexar documento/arquivo</span>
        </label>
        <input
          name="document"
          type="file"
          id="upload-document"
          onChange={res => handleUploadDocument(res.target.files[0])}
          required
        />
      </UploadDocBtn>
      <Flex justifyContent="space-between">
        <Button title="cancelar" variant="secondary" onClick={closeModal} />
        <Button title="criar" variant="success" onClick={handleSubmit} />
      </Flex>
      <ProgressModal loading={showProgress} text="Fazendo upload..." />
    </ModalContainer>
  );
}
