// // ⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣠⣤⣤⣴⣦⣤⣤⣄⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
// // ⠀⠀⠀⠀⠀⠀⢀⣤⣾⣿⣿⣿⣿⠿⠿⠿⠿⣿⣿⣿⣿⣶⣤⡀⠀⠀⠀⠀⠀⠀
// // ⠀⠀⠀⠀⣠⣾⣿⣿⡿⠛⠉⠀⠀⠀⠀⠀⠀⠀⠀⠉⠛⢿⣿⣿⣶⡀⠀⠀⠀⠀
// // ⠀⠀⠀⣴⣿⣿⠟⠁⠀⠀⠀⣶⣶⣶⣶⡆⠀⠀⠀⠀⠀⠀⠈⠻⣿⣿⣦⠀⠀⠀
// // ⠀⠀⣼⣿⣿⠋⠀⠀⠀⠀⠀⠛⠛⢻⣿⣿⡀⠀⠀⠀⠀⠀⠀⠀⠙⣿⣿⣧⠀⠀
// // ⠀⢸⣿⣿⠃⠀⠀⠀⠀⠀⠀⠀⠀⢀⣿⣿⣷⠀⠀⠀⠀⠀⠀⠀⠀⠸⣿⣿⡇⠀
// // ⠀⣿⣿⡿⠀⠀⠀⠀⠀⠀⠀⠀⢀⣾⣿⣿⣿⣇⠀⠀⠀⠀⠀⠀⠀⠀⣿⣿⣿⠀
// // ⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⢠⣿⣿⡟⢹⣿⣿⡆⠀⠀⠀⠀⠀⠀⠀⣹⣿⣿⠀
// // ⠀⣿⣿⣷⠀⠀⠀⠀⠀⠀⣰⣿⣿⠏⠀⠀⢻⣿⣿⡄⠀⠀⠀⠀⠀⠀⣿⣿⡿⠀
// // ⠀⢸⣿⣿⡆⠀⠀⠀⠀⣴⣿⡿⠃⠀⠀⠀⠈⢿⣿⣷⣤⣤⡆⠀⠀⣰⣿⣿⠇⠀
// // ⠀⠀⢻⣿⣿⣄⠀⠀⠾⠿⠿⠁⠀⠀⠀⠀⠀⠘⣿⣿⡿⠿⠛⠀⣰⣿⣿⡟⠀⠀
// // ⠀⠀⠀⠻⣿⣿⣧⣄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⣾⣿⣿⠏⠀⠀⠀
// // ⠀⠀⠀⠀⠈⠻⣿⣿⣷⣤⣄⡀⠀⠀⠀⠀⠀⠀⢀⣠⣴⣾⣿⣿⠟⠁⠀⠀⠀⠀
// // ⠀⠀⠀⠀⠀⠀⠈⠛⠿⣿⣿⣿⣿⣿⣶⣶⣿⣿⣿⣿⣿⠿⠋⠁⠀⠀⠀⠀⠀⠀
// // ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠉⠛⠛⠛⠛⠛⠛⠉⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
//
// λ :: array & object manipulation functions :: λ

// :: Helpers ::
export const arr = λ => Array.isArray(λ);
export const obj = λ => typeof λ === 'object';
export const keys = λ => Object.keys(λ);

// :: Constructor ::
// The base for every function from now on
// 1st -> Validates if item type is array || object
// 2st -> Executes a function based on the item type
// 3st -> If not array || object returns null preventing Logic/JSX crashes
export const constructor = (λ, arrFn, objFn) =>
  arr(λ) ? arrFn() : obj(λ) ? objFn() : null;

// :: Functions ::
// λ -> origin array || object && λ2 -> destination array || object
// i -> origin index && j -> destination index

// Deletes item in array || object
export const del = (λ, i) =>
  constructor(
    λ,
    () => λ.splice(i, 1),
    () => delete λ[i]
  );

// Puts item in array || object
export const put = (λ, e, i) =>
  constructor(
    λ,
    () => λ.splice(i, 0, e),
    () => (λ[i] = e)
  );

// Moves item from array || object to array || object
export const move = (λ, λ2, i, j) => {
  const el = constructor(
    λ,
    () => λ[i],
    () => del(λ, i)[0]
  );

  if (!el) return null;

  return constructor(
    λ2,
    () => del(λ, i) && put(λ2, el, j),
    () => put(λ2, el, j) && del(λ, i)
  );
};

// Returns the first item of array || object
export const head = λ =>
  constructor(
    λ,
    () => λ[0],
    () => λ[keys(λ)[0]]
  );

// Returns the last item of array || object
export const last = λ =>
  constructor(
    λ,
    () => λ[λ.length - 1],
    () => λ[keys(λ)[keys(λ).length - 1]]
  );

// Transforms string || object to array
export const words = (λ, s = ' ') =>
  constructor(
    λ,
    () => λ.split(s),
    () => keys(λ)
  );

// Maps through array || object
export const map = (λ, f) =>
  constructor(
    λ,
    () => λ.map((...p) => f(...p)),
    () => keys(λ).map((k, i) => f(λ[k], k, i))
  );
