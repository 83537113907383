import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  background-color: var(--background);

  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  overflow: hidden;
`;
