import styled from 'styled-components';
import { FaEdit } from 'react-icons/fa';
import { AiFillPlusCircle } from 'react-icons/ai';

export const CreateSvg = styled(AiFillPlusCircle)`
  font-size: 20px;

  cursor: pointer;
`;

export const EditSvg = styled(FaEdit)`
  font-size: 20px;

  cursor: pointer;
`;
