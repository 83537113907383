import React, { useState, useEffect } from 'react';

import Info from './Info';
import AccordionMenu from './AccordionMenu';
import CreateContractGroup from './CreateContractGroup';

import PageLayout from '~/pages/_layouts/hospital/PageLayout';
import { Spinner } from '~/components/ChakraComponents/';

export default function MyContracts() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <PageLayout>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <CreateContractGroup />
          <AccordionMenu />
          <Info />
        </>
      )}
    </PageLayout>
  );
}
