import React from 'react';

import childrenWithProps from '~/utils/childrenWithProps';

import { getUser } from '~/services/auth';

import Nav from './Nav';
import NavMobile from './NavMobile';
import SideMenu from './SideMenu';

import { Container, Content } from './styles';

const CompanyLayout = ({ children }) => {
  return (
    <Container>
      <Nav />
      <NavMobile />
      <Content>
        <SideMenu />
        {childrenWithProps(children, { user: getUser() })}
      </Content>
    </Container>
  );
};

export default CompanyLayout;
