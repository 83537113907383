import React from 'react';
import Cleave from 'cleave.js/react';
import styled from 'styled-components';
import { Flex, Text } from '@chakra-ui/react';

import { Label } from '~/components/UI/Text';

const CleaveInput = styled(Cleave)`
  width: 100%;
  height: 40px;

  padding: 5px;

  border-radius: var(--br-sm);

  border: 2px solid var(--black);
`;

const DefaultInput = styled.input`
  width: 100%;
  height: 40px;

  padding: 5px;

  border-radius: var(--br-sm);

  border: 2px solid var(--black);
`;

export default function IText({
  required,
  label = '',
  useCleave,
  options, // https://nosir.github.io/cleave.js/ For cleave.js options
  error,
  style,
  type,
  disabled,
  ...rest
}) {
  return (
    <Flex flexDir="column" w="100%" style={style}>
      <Label>{required ? `${label} *` : label}</Label>
      {useCleave ? (
        <CleaveInput disabled={disabled} required={required} options={options} {...rest} />
      ) : (
        <DefaultInput disabled={disabled} type={type} required={required} {...rest} />
      )}
      {error && (
        <Text mt="5px" color="var(--red)">
          {error}
        </Text>
      )}
    </Flex>
  );
}
