import React, { useState, useRef, useCallback, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Text, VStack, HStack } from '@chakra-ui/react';

import history from '~/services/history';
import { getUser } from '~/services/auth';
import { getParam } from '~/utils/url';
import { weekDaysAlt as weekDays } from '~/constants/weekDays';

import PDFModal from '~/pages/hospital/VacanciesV2/Pages/ScheduleGroupPage/components/PdfModalV2';
import HospitalVacancyModal from '~/components/Dashboard/Modals/MeusPlantoes/ChooseOption';
import ContractorVacancyModal from '~/pages/contractorhospitals/VacanciesV2/ChooseOption';
import { Modal } from '~/components/UI/Modal';
import { Divider } from '~/components/ChakraComponents';
import { VacancyCard } from '~/components/UI/Cards/';
import { MobileMonthAgenda } from '~/components/UI/Sections/';

const ScheduleType = () => {
  const { groupId, scheduleType, vacancyDate } = useParams();

  const { hospitalId } = getParam('hospitalId');
  const pdfModalRef = useRef(null);

  const openPdf = useCallback(() => {
    pdfModalRef.current.openModal();
  }, []);

  const navigate = type => {
    const url = `/hospital/vacancies/schedulegroup/${groupId}/${type}/${vacancyDate}/?hospitalId=${hospitalId}`;

    history.push(url);
  };

  return (
    <VStack mb={2} fontWeight="semibold">
      <Flex
        onClick={openPdf}
        w="100%"
        py="2px"
        align="center"
        justify="center"
        textAlign="center"
        color="var(--background-alt)"
        bgColor="var(--primary)"
      >
        <Text>Gerar PDF</Text>
      </Flex>
      <HStack w="100%">
        <Flex
          onClick={() => navigate('week')}
          w="100%"
          align="center"
          justify="center"
          textAlign="center"
          border="2px solid var(--black)"
          color={
            scheduleType === 'week' ? 'var(--background)' : 'var(--secondary)'
          }
          bgColor={scheduleType === 'week' ? 'var(--secondary)' : 'transparent'}
        >
          <Text>Semanal</Text>
        </Flex>
        <Flex
          onClick={() => navigate('month')}
          w="100%"
          align="center"
          justify="center"
          textAlign="center"
          border="2px solid var(--black)"
          color={
            scheduleType === 'month' ? 'var(--background)' : 'var(--secondary)'
          }
          bgColor={
            scheduleType === 'month' ? 'var(--secondary)' : 'transparent'
          }
        >
          <Text>Mensal</Text>
        </Flex>
      </HStack>
      <Modal title="Gerar Pdf" ref={pdfModalRef} component={PDFModal} />
    </VStack>
  );
};

export default function GroupsAgenda({ schedules, agendaDates }) {
  const user = getUser();

  const { scheduleType } = useParams();

  const [clickedCard, setClickedCard] = useState();

  const vacancyModalRef = useRef(null);

  const openVacancy = useCallback(() => {
    vacancyModalRef.current.openModal();
  }, []);

  const sectionLabel = useCallback(contract => {
    const { sector, start, end } = contract;

    const formattedStart = start.slice(0, -3);
    const formattedEnd = end.slice(0, -3);

    return (
      <Flex
        w="100%"
        align="center"
        justify="center"
        bgColor="gray.500"
        color="white"
        fontWeight="semibold"
      >
        <Text>
          {sector} - {formattedStart} às {formattedEnd}
        </Text>
      </Flex>
    );
  }, []);

  const vacancyModalDictionary = useCallback(() => {
    return {
      company: HospitalVacancyModal,
      contractor: ContractorVacancyModal,
    }[user.type];
  }, [user]);

  return (
    <>
      {scheduleType === 'month' ? (
        <Flex flexDir="column" w="100%" h="100%" pb="80px" overflowX="scroll">
          <ScheduleType />
          <MobileMonthAgenda
            dates={agendaDates}
            schedules={schedules}
            cardOnClick={vacancy => {
              openVacancy();
              setClickedCard(vacancy);
            }}
          />
        </Flex>
      ) : (
        <>
          <ScheduleType />
          <VStack
            w="100%"
            h="100%"
            pb="80px"
            overflowY="auto"
            divider={<Divider />}
          >
            {agendaDates.map((weekDayDate, i) => (
              <VStack key={weekDayDate} w="100%" spacing="1px">
                <Flex
                  w="100%"
                  h="40px"
                  px="4px"
                  align="center"
                  justify="space-between"
                  bgColor="gray.600"
                  color="white"
                  fontWeight="semibold"
                >
                  {weekDays[i] && (
                    <Text fontSize="md">
                      {weekDays[i]} -{' '}
                      {weekDayDate
                        .split('-')
                        .reverse()
                        .join('/')}
                    </Text>
                  )}
                </Flex>
                {schedules.map(schedule => (
                  <Fragment key={schedule.id}>
                    {sectionLabel(schedule.contract)}
                    {schedule.dates.map(date => (
                      <Fragment key={date.day}>
                        {date.day === weekDayDate &&
                          date.vacancies.map(vacancy => (
                            <VacancyCard
                              key={vacancy.id}
                              vacancy={vacancy}
                              onClick={() => {
                                openVacancy();
                                setClickedCard(vacancy);
                              }}
                            />
                          ))}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </VStack>
            ))}
          </VStack>
        </>
      )}
      <Modal
        title="Plantão"
        ref={vacancyModalRef}
        component={vacancyModalDictionary()}
        vacancy={clickedCard}
        scheduleId={clickedCard?.schedule?.id}
      />
    </>
  );
}
