import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.textarea`
  width: 100%;
  border: 2px solid var(--black);
  border-radius: var(--br-sm);
  resize: none;
  padding: 5px;
`;

export const WrapperLabel = styled.div`
  display: flex;
`;

export const Label = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 10px;
`;

export const WrapperError = styled.div``;

export const TextError = styled.span`
  font-size: 12px;
  color: red;
  margin-top: 5;
`;
