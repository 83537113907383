import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useBoolean, Flex } from '@chakra-ui/react';

import api from '~/services/api';
import { getParam } from '~/utils/url';

import PdfDocument from './PdfDocument';

import { fetchSchedules } from '~/pages/hospital/VacanciesV2/utils';

import { ModalContainer } from '~/components/UI/Modal';

export default function PhaseFinal({ options }) {
  const { groupId } = useParams();
  const { hospitalId } = getParam('hospitalId');

  const [weekDates, setWeekDates] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [companyLogo, setCompanyLogo] = useState('');
  const [loadingApi, setLoadingApi] = useBoolean(true);

  useEffect(() => {
    const startDate = moment(new Date(`${options.inputs.startDate} 00:03:01`))
      .startOf('week')
      .add(1, 'days')
      .format('YYYY-MM-DD');

    const endDate = moment(new Date(`${options.inputs.endDate} 00:03:01`))
      .endOf('week')
      .add(1, 'days')
      .format('YYYY-MM-DD');

    const url = `/companies/myhospitals/${hospitalId}/schedulesgroup/${groupId}/vacancies?startDate=${startDate}&endDate=${endDate}`;
    fetchSchedules(url, (datesArr, schedulesArr) => {
      const weeksArr = datesArr.reduce((all, one, i) => {
        const ch = Math.floor(i / 7);
        all[ch] = [].concat(all[ch] || [], one);
        return all;
      }, []);

      setWeekDates(weeksArr);
      setSchedules(schedulesArr);

      api.get('/companies/companyprofile').then(response => {
        if (options.inputs.companyLogo) {
          axios
            .get(response.data.avatar, { responseType: 'arraybuffer' })
            .then(async resp => {
              const image = btoa(
                new Uint8Array(resp.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ''
                )
              );

              const result = `data:${resp.headers[
                'content-type'
              ].toLowerCase()};base64,${image}`;

              setCompanyLogo(result);
            });
        }

        setTimeout(() => setLoadingApi.toggle(), 3000);
      });
    });
  }, []);

  const fileName = useCallback(() => {
    const start = options.inputs.startDate
      .split('-')
      .reverse()
      .join('-');

    const end = options.inputs.endDate
      .split('-')
      .reverse()
      .join('-');

    return `${localStorage.groupName}_${start}_${end}`;
  }, [options]);

  return (
    <ModalContainer w="200px" loading={loadingApi}>
      <Flex w="100%" h="100%" align="center" justify="center">
        <PDFDownloadLink
          style={{
            cursor: 'pointer',
            width: '100%',
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
            backgroundColor: '#5C69E1',
            color: 'white',
            fontSize: 12,
            fontWeight: '600',
            textTransform: 'uppercase',
          }}
          document={
            <PdfDocument
              options={options}
              companyLogo={companyLogo}
              weekDates={weekDates}
              schedules={schedules}
            />
          }
          fileName={fileName()}
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Carregando...' : 'Baixar PDF'
          }
        </PDFDownloadLink>
      </Flex>
    </ModalContainer>
  );
}
