import React, { useRef, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';

import ChooseRoutes from './ChooseRoutes';
import ChangePassword from './ChangePassword';

import EditAvatar from '~/components/EditAvatar';

import { Modal } from '~/components/UI/Modal';
import { Button } from '~/components/ChakraComponents/';

export default function EditUserModal({ user }) {
  const openChangePasswordRef = useRef(null);
  const openChooseRoutesRef = useRef(null);

  const handleOpenChangePasswordModal = useCallback(() => {
    openChangePasswordRef.current.openModal();
  }, []);

  const handleCloseChangePasswordModal = useCallback(() => {
    openChangePasswordRef.current.closeModal();
  }, []);

  const handleOpenChooseRoutesModal = useCallback(() => {
    openChooseRoutesRef.current.openModal();
  }, []);

  const handleCloseChooseRoutesModal = useCallback(() => {
    openChooseRoutesRef.current.closeModal();
  }, []);

  return (
    <Flex flexDir="column" align="center">
      <EditAvatar userId={user.id} edit />
      <Flex justify="space-between" pt="20px">
        <Button
          title="editar senha"
          margin="0 40px 0 0"
          onClick={handleOpenChangePasswordModal}
        />
        <Button
          title="editar permissões"
          onClick={handleOpenChooseRoutesModal}
        />
      </Flex>
      <Modal
        title="Editar Senha"
        ref={openChangePasswordRef}
        component={ChangePassword}
        userId={user.id}
        onClose={handleCloseChangePasswordModal}
      />
      <Modal
        title="Permissões De Acesso"
        ref={openChooseRoutesRef}
        component={ChooseRoutes}
        user={user}
        onClose={handleCloseChooseRoutesModal}
      />
    </Flex>
  );
}
