import styled from 'styled-components';

export const Container = styled.select`
  display: flex;
  width: 100%;
  height: 40px;
  border: 2px solid var(--black);
  border-radius: var(--br-sm);
  margin: 5px 0 5px 0;
  padding: 5px;
  background: transparent;
`;
