import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;

  padding: 10px 0;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #e7e7e7;

  transition: all 75ms ease-in-out;

  img {
    width: 34px;
    height: 34px;

    margin-right: 12px;

    border: 2px solid var(--primary);
    border-radius: 50%;

    transition: all 75ms ease-in-out;

    flex-shrink: none;
    object-fit: cover;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    span {
      color: var(--secondary);
    }

    img {
      border: 2px solid var(--black);
      border-radius: var(--br-sm);
    }
  }
`;
