import React, { useCallback } from 'react';
import styled from 'styled-components';
import { IoMdAlert } from 'react-icons/io';
import { Flex, HStack, Tooltip } from '@chakra-ui/react';

import MonthPendingDoctorCard from './MonthPendingDoctorCard';
import MonthDoctorCard from './MonthDoctorCard';
import MonthOpenCard from './MonthOpenCard';

import PendingDoctorCard from './PendingDoctorCard';
import DoctorCard from './DoctorCard';
import OpenCard from './OpenCard';

import IconStatus from './IconStatus';
import IconStatusCheckin from './IconStatusCheckin';

const getHoursByDate = (date) => {
  if(date){
    date = new Date(date);
    let h = String(date.getHours())
    if(h.length === 1){
      h = '0' + h;
    }
    let m = String(date.getMinutes())
    if(m.length === 1){
      m = '0' + m;
    }
    return h + ':' + m;
  }else{
    return 'HH:mm'
  }
}

const DesktopView = styled.div`
  width: 100%;

  display: none;

  &:hover {
    filter: brightness(95%);
  }

  @media (min-width: 1023px) {
    display: flex;
  }
`;

const MobileView = styled.div`
  width: 100%;
  height: 34px;

  display: none;

  @media (max-width: 1023px) {
    display: flex;
  }
`;

const DesktopWeekCard = styled.div`
  position: relative;

  width: 100%;
  height: 125px;

  margin-bottom: 12px;

  display: flex;

  border: ${props => props.highlight && '2px solid red'};
  border-radius: ${props => props.highlight && '5px'};

  @media (max-width: 1600px) {
    height: 125px;
  }
`;

const StatusContainer = styled.div`
  cursor: pointer;

  ${'' /* position: absolute; */}
  top: 0.25rem;
  left: 0.25rem;

  width: 20px;

  display: flex;
  flex-direction: column;

  @media (max-width: 1600px) {
    left: 0;
    ${'' /* padding: 0 0.5rem; */}
    justify-content: space-between;
  }
`;

const ObservationSvg = styled(IoMdAlert)`
  height: 20px;
  width: 20px;

  border-radius: 50%;


  color: var(--orange);
  background-color: var(--background-alt);
`;

export default function VacancyCard({
  highlight,
  isMonth = false,
  vacancy,
  onClick,
}) {
  const getKey = useCallback(() => {
    let key = 'open';
    if (vacancy.companyDoctor) key = 'doctor';
    if (vacancy.pendingDoctorCrm) key = 'pendingDoctor';
    return key;
  }, [vacancy]);

  const renderDesktopMonthCard = useCallback(() => {
    const component = {
      open: <MonthOpenCard vacancy={vacancy} />,
      doctor: <MonthDoctorCard vacancy={vacancy} />,
      pendingDoctor: <MonthPendingDoctorCard vacancy={vacancy} />,
    }[getKey()];

    return (
      <Flex w="100%" h="34px">
        {component}
      </Flex>
    );
  }, [vacancy]);

  const renderDesktopWeekCard = useCallback(() => {
    const component = (componentBarLateral, componentBarTop) => {
      return {
        open: <OpenCard vacancy={vacancy} />,
        doctor: (
          <DoctorCard
            vacancy={vacancy}
            componentBarLateral={componentBarLateral}
            componentBarTop={componentBarTop}
          />
        ),
        pendingDoctor: (
          <PendingDoctorCard
            vacancy={vacancy}
            componentBarLateral={componentBarLateral}
            componentBarTop={componentBarTop}
          />
        ),
      }[getKey()];
    };

    const componentBarLateral = () => {
      return (
        <StatusContainer>
          {getKey() !== 'open' && <IconStatus vacancy={vacancy} />}
          {getKey() !== 'open' && <IconStatusCheckin vacancy={vacancy} />}
          {vacancy.hasObservation && (
            <Tooltip
              hasArrow
              label={`${vacancy.countObservation} Observaç${
                vacancy.countObservation === 1 ? 'ão' : 'ões'
              }`}
              bg="gray.600"
            >
              <span>
                <ObservationSvg />
              </span>
            </Tooltip>
          )}
        </StatusContainer>
      );
    };

    const componentBarTop = () => {
      return (
        <Flex width="100%" flexDir="column" marginBottom="4px">
          <Flex width="100%" flexDir="row" justifyContent="center" color="#505050" fontSize="10px">
            <Flex flexDir="column" marginRight="4px">{getHoursByDate(vacancy.checkinDate)}</Flex>
            <Flex flexDir="column">{' - '}</Flex>
            <Flex flexDir="column" marginLeft="4px">{getHoursByDate(vacancy.checkoutDate)}</Flex>
          </Flex>
          <Flex flexDir="row" justifyContent="center" color="#505050" fontSize="10px">
            {Number(vacancy.paymentValue).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Flex>
        </Flex>
      )
    }

    return (
      <DesktopWeekCard highlight={highlight}>
        {component(componentBarLateral(), componentBarTop())}
        {/* TODO colocar obs icon no open */}
      </DesktopWeekCard>
    );
  }, [vacancy]);

  const renderMobileCard = useCallback(() => {
    const component = {
      open: <MonthOpenCard vacancy={vacancy} />,
      doctor: <MonthDoctorCard vacancy={vacancy} />,
      pendingDoctor: <MonthPendingDoctorCard vacancy={vacancy} />,
    }[getKey()];

    return (
      <Flex w="100%" h="34px">
        {component}
      </Flex>
    );
  }, [vacancy]);

  return (
    <>
      <DesktopView onClick={onClick}>
        {isMonth ? renderDesktopMonthCard() : renderDesktopWeekCard()}
      </DesktopView>
      <MobileView onClick={onClick}>{renderMobileCard()}</MobileView>
    </>
  );
}
