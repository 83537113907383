import moment from 'moment';

// Converts 13/09/1996 to 1996-09-13 and vice-versa
export const reverseDate = d => {
  d = d.split(/[^0-9]/).reverse();
  return d[0].length === 4 ? d.join('-') : d.join('/');
};

export default (d = moment(new Date()).format('YYYY-MM-DD')) => {
  let date;

  if (typeof d === 'string' || d instanceof String) {
    date = moment(new Date(d.match('/') ? reverseDate(d) : d));
  } else {
    date = moment(new Date(d));
  }

  const iso = () => date.format('YYYY-MM-DD');
  const bars = dateInput => date.format(dateInput || 'DD/MM/YYYY');
  const hours = () => date.format('HH:mm');
  const fullDate = () => date.format('DD/MM/YYYY HH:mm');

  return { iso, bars, hours, fullDate };
};
