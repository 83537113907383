import React, { createContext, useContext } from 'react';

const ColorsContext = createContext();

export function ColorsProvider({ children }) {
  const colors = [
    '#2db7f8',
    '#27be67',
    '#d64141',
    '#a64bce',
    '#5d6bd9',
    '#f1fa8c',
    '#ffb86c',
    '#ff79c6',
    '#50fa7b',
    '#bd93f9',
  ];

  function newColor(index) {
    if (colors[index]) return colors[index];

    return (
      '#' + ('00000' + ((Math.random() * (1 << 24)) | 0).toString(16)).slice(-6)
    );
  }

  return (
    <ColorsContext.Provider value={{ newColor }}>
      {children}
    </ColorsContext.Provider>
  );
}

export function useColors() {
  const context = useContext(ColorsContext);

  if (!context)
    throw new Error('useColors must be used within a ColorsProvider');

  const { newColor } = context;

  return { newColor };
}
