import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@chakra-ui/react';
import {
  FaDesktop,
  FaNotesMedical,
  FaCalendarAlt,
  FaFileMedicalAlt,
} from 'react-icons/fa';

import api from '~/services/api';
import leaveHospital from '~/utils/leaveHospital';
import MeuPlantaoLogo from '~/assets/logo/LogoWhite.png';
import { getParam } from '~/utils/url';

import IsAllowed from '~/components/UI/IsAllowed';
import { SubTitle } from '~/components/Kiwistrap/Page';

import {
  LogoLink,
  LogoImg,
  Navigation,
  PageLink,
  ExitSvg,
  NotificationsSection,
  BellContainer,
  Bell,
  NotificationMenuBackground,
  NotificationsMenu,
  NotificationsMenuItem,
} from './styles';

const Nav = () => {
  const [unread, setUnread] = useState(0);
  const [notifications, setNotifications] = useState();
  const [openNotifications, setOpenNotifications] = useState(false);

  const { hospitalId } = getParam('hospitalId');

  const fetchNotifications = useCallback(() => {
    api.get('/companies/notifications?limit=50').then(response => {
      const { data } = response.data;

      let unread = 0;

      data.forEach(notification => {
        if (!notification.read) unread += 1;
      });

      setUnread(unread);
      setNotifications(data);

      //setTimeout(() => fetchNotifications(), 300000);
    });
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const toggleNotifications = useCallback(async () => {
    setOpenNotifications(!openNotifications);

    const nonReadIds = notifications.map(notification => {
      if (!notification.read) return notification.id;
    });

    const promises = nonReadIds.map(async id => {
      return await api.patch(`/companies/notifications/read/${id}`);
    });

    await Promise.all(promises);

    fetchNotifications();
  }, [openNotifications, notifications, fetchNotifications]);

  const handleBackgroundOnClick = useCallback(e => {
    if (e.target.id === 'notifications-background') {
      setOpenNotifications(false);
    }
  }, []);

  const genUrl = s => `/hospital/${s}/?hospitalId=${hospitalId}`;
  const isSelected = s => window.location.pathname.includes(s);

  return (
    <Grid
      display={['none', 'none', 'none', 'grid']}
      w="100%"
      h="81px"
      backgroundColor="var(--background-alt)"
      templateColumns="170px auto 90px"
      alignItems="center"
    >
      <LogoLink to={genUrl('dashboard')}>
        <LogoImg src={MeuPlantaoLogo} alt="meu-plantao" />
      </LogoLink>
      <Navigation>
        <IsAllowed id={1}>
          <PageLink
            text="Dashboard"
            to={genUrl('dashboard')}
            selected={isSelected('dashboard')}
          >
            <FaDesktop />
          </PageLink>
        </IsAllowed>
        <IsAllowed id={2}>
          <PageLink
            text="Meus Contratos"
            to={genUrl('mycontracts')}
            selected={isSelected('mycontracts')}
          >
            <FaFileMedicalAlt />
          </PageLink>
        </IsAllowed>
        <IsAllowed id={6}>
          <PageLink
            text="Escalas"
            to={genUrl('vacancies/schedulegroup')}
            selected={isSelected('vacancies')}
          >
            <FaCalendarAlt />
          </PageLink>
        </IsAllowed>
        <IsAllowed id={14}>
          <PageLink
            text="Protocolos"
            to={genUrl('protocols')}
            selected={isSelected('protocols')}
          >
            <FaNotesMedical />
          </PageLink>
        </IsAllowed>
        <PageLink text="Sair do Hospital" onClick={leaveHospital}>
          <ExitSvg />
        </PageLink>
      </Navigation>
      <NotificationsSection>
        <BellContainer
          unread={unread > 0 && unread}
          onClick={toggleNotifications}
        >
          <Bell />
        </BellContainer>
        {openNotifications && (
          <NotificationMenuBackground
            id="notifications-background"
            onClick={handleBackgroundOnClick}
          >
            <NotificationsMenu>
              <SubTitle
                style={{
                  padding: 10,
                  borderBottom: '2px solid var(--secondary',
                }}
              >
                Notificações
              </SubTitle>
              {notifications &&
                notifications.map(notification => (
                  <NotificationsMenuItem key={notification.id}>
                    {notification.content}
                    <p>
                      {moment(new Date(notification.updatedAt)).format(
                        'HH:mm - DD/MM'
                      )}
                    </p>
                  </NotificationsMenuItem>
                ))}
            </NotificationsMenu>
          </NotificationMenuBackground>
        )}
      </NotificationsSection>
    </Grid>
  );
};

export default Nav;
