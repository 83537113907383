import React, { useCallback } from 'react';
import moment from 'moment';
import { Flex, Text } from '@chakra-ui/react';

import getFirstLastName from '~/utils/getFirstLastName';
import { vacancyCardBackgroundColor } from '~/utils/genStatus';

import IconStatus from './IconStatus';

import { MonthDoctorContainer } from './styles';

export default function MonthDoctorCard({ vacancy, onClick }) {
  const renderDoctorName = useCallback(() => {
    return getFirstLastName(vacancy.doctor.user.name);
  }, []);

  const renderHourText = useCallback(() => {
    const { startHour, endHour } = vacancy;

    const formattedStart = moment(new Date(startHour)).format('HH:mm');
    const formattedEnd = moment(new Date(endHour)).format('HH:mm');

    return `${formattedStart}h às ${formattedEnd}h`;
  }, []);

  return (
    <MonthDoctorContainer
      color={vacancyCardBackgroundColor(vacancy.type, !vacancy.belongsMyTeam)}
      onClick={onClick}
    >
      <Text color="#505050">{renderDoctorName()}</Text>
      <Text color="#505050">{renderHourText()}</Text>
      <Flex pos="absolute" right="4px">
        <IconStatus isMonth vacancy={vacancy} />
      </Flex>
    </MonthDoctorContainer>
  );
}
