import React, { useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FaUserMd, FaRegCalendarAlt } from 'react-icons/fa';
import { Flex, Divider, Tooltip, Text } from '@chakra-ui/react';
import { AiFillWarning, AiFillClockCircle } from 'react-icons/ai';

import Stars from './Stars';
import RateModal from './Stars/RateModal';

import DoctorHoursModal from '~/components/Dashboard/Modals/DoctorHoursModal';
import DoctorAlertsModal from '~/components/Dashboard/Modals/DoctorAlertsModal';

import { Avatar } from '~/components/ChakraComponents/';
import { Modal } from '~/components/UI/Modal';

const SvgStyles = styled.div`
  width: 100%;

  svg {
    cursor: pointer;

    &:hover {
      fill: var(--primary);
    }
  }
`;

export default function DoctorCard({ doctor, openProfile, openEdit }) {
  const rateModalRef = useRef(null);
  const doctorHoursModalRef = useRef(null);
  const doctorAlertsmodalRef = useRef(null);

  const openRate = useCallback(() => {
    rateModalRef.current.openModal();
  }, []);

  const openDoctorHours = useCallback(() => {
    doctorHoursModalRef.current.openModal();
  }, []);

  const openDoctorAlerts = useCallback(() => {
    doctorAlertsmodalRef.current.openModal();
  }, []);

  const renderDoctorName = useCallback(() => {
    const formattedName = doctor.name.split(/(\s).+\s/).join('');

    return formattedName;
  }, []);

  return (
    <Flex w="100%" h="100%" justifyContent="center">
      <Flex
        w="240px"
        h="100%"
        p={3}
        flexDir="column"
        alignItems="center"
        boxShadow="base"
        backgroundColor="white"
        borderRadius="base"
        textAlign="center"
      >
        <Avatar
          w="100px"
          h="100px"
          mb={3}
          border="4px"
          name={renderDoctorName(doctor.name)}
          src={doctor.avatar}
        />
        <Text fontSize="md" fontWeight="bold" color="gray.600">
          {renderDoctorName(doctor.name)}
        </Text>
        <Text height="40px" fontWeight="600" color="gray.500">
          {doctor.doctor.specialty.name}
        </Text>
        <Tooltip hasArrow label="Avaliar Médico" bg="gray.600">
          <Flex onClick={openRate} cursor="pointer" w="100%" mt={3}>
            <span style={{ width: '100%' }}>
              <Stars rating={doctor.doctor.rating} />
            </span>
          </Flex>
        </Tooltip>
        <SvgStyles>
          <Flex
            w="100%"
            mt={6}
            justifyContent="space-evenly"
            fontSize="3xl"
            color="gray.500"
          >
            <Tooltip hasArrow label="Horas" bg="gray.600">
              <span>
                <AiFillClockCircle onClick={openDoctorHours} />
              </span>
            </Tooltip>
            <Tooltip hasArrow label="Perfil" bg="gray.600">
              <span>
                <FaUserMd onClick={openProfile} />
              </span>
            </Tooltip>
            <Tooltip hasArrow label="Plantões" bg="gray.600">
              <span>
                <FaRegCalendarAlt onClick={openEdit} />
              </span>
            </Tooltip>
            <Tooltip hasArrow label="Alertas" bg="gray.600">
              <span>
                <AiFillWarning onClick={openDoctorAlerts} />
              </span>
            </Tooltip>
          </Flex>
        </SvgStyles>
        <Divider mt={3} mb={3} color="gray.400" />
        <Text fontWeight="600" color="gray.500">
          {`${doctor.doctor.crm}-${doctor.doctor.crmUf}`}
        </Text>
        <Modal
          title="Avaliar"
          ref={rateModalRef}
          component={RateModal}
          doctorId={doctor.doctor.id}
        />
        <Modal
          title="Horas"
          ref={doctorHoursModalRef}
          component={DoctorHoursModal}
          doctorId={doctor.doctor.id}
        />
        <Modal
          title="Alertas"
          ref={doctorAlertsmodalRef}
          component={DoctorAlertsModal}
          doctorId={doctor.doctor.id}
        />
      </Flex>
    </Flex>
  );
}
