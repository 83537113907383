// Quando eu fiz só eu e Deus sabia, agora só Deus sabe, ¯\_(ツ)_/¯
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  HStack,
  Flex,
  Text,
  Table,
  Thead,
  Th,
  Td,
  Tbody,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';

import { FiFilter } from 'react-icons/fi';
import { RiFileExcel2Fill as ExcelSvg } from 'react-icons/ri';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IconContainer } from '~/components/UI/Functionality';
import { getToken } from '~/services/auth';

import { Modal } from '~/components/UI/Modal';
import history from '~/services/history';
import { Button } from '~/components/ChakraComponents';
import DrawerFilter from '~/components/UI/DrawerFilter';
import Pagination from '~/components/Dashboard/Pagination';
import PageLayout from '~/pages/_layouts/company/PageLayout';
import api from '~/services/api';
import getFirstLastName from '~/utils/getFirstLastName';
import getQueryParams from '~/utils/getJsonQueryParams';
import queryParamsToURL from '~/utils/queryParamsToURL';
import Details from './Details';
import { Tr, TrH } from './style';

const limit = 10;
export default function ResumeDoctorReports({ location }) {
  const queryParams = getQueryParams(location.search);
  const [currentParams, setCurrentParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [columnName, setColumnName] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [total, setTotal] = useState(0);
  const [details, setDetails] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const detailsModalRef = useRef(null);

  const tableColumnName = {
    contractName: 'Contrato',
    contractSector: 'Setor',
    contractSpecialty: 'contractSpecialty',
    doctorCrm: 'doctorCrm',
    doctorName: 'Médico',
    expectedWorkHours: 'Horas Esperadas / Trabalhadas',
    id: 'id',
    vacancyApprovalDate: 'vacancyApprovalDate',
    vacancyApprovedValue: 'vacancyApprovedValue',
    vacancyCheckinAppJustification: 'vacancyCheckinAppJustification',
    vacancyCheckinDate: 'Check-in/out',
    vacancyCheckinLat: 'vacancyCheckinLat',
    vacancyCheckinLong: 'vacancyCheckinLong',
    vacancyCheckinNoAppJustification: 'vacancyCheckinNoAppJustification',
    vacancyCheckinPositionLink: 'vacancyCheckinPositionLink',
    vacancyCheckoutAppJustification: 'vacancyCheckoutAppJustification',
    vacancyCheckoutDate: 'vacancyCheckoutDate',
    vacancyCheckoutLat: 'vacancyCheckoutLat',
    vacancyCheckoutLong: 'vacancyCheckoutLong',
    vacancyCheckoutNoAppJustification: 'vacancyCheckoutNoAppJustification',
    vacancyCheckoutPositionLink: 'vacancyCheckoutPositionLink',
    vacancyCompanyJustification: 'vacancyCompanyJustification',
    vacancyDate: 'Data',
    vacancyEndHour: 'vacancyEndHour',
    vacancyFlow: 'Fluxo',
    vacancyStartHour: 'Horário',
    vacancyValue: 'Valor/Aprovado',
    valueStatus: 'Situação',
    workedHours: 'workedHours',
    distanceBetweenHospitalAndCheckinPosition:
      'distanceBetweenHospitalAndCheckinPosition',
    distanceBetweenHospitalAndCheckoutPosition:
      'distanceBetweenHospitalAndCheckoutPosition',
    hospitalName: 'hospitalName',
  };

  const deleteColumn = [
    'contractSpecialty',
    'doctorCrm',
    'vacancyApprovalDate',
    'vacancyCheckinLat',
    'vacancyCheckinLong',
    'id',
    'vacancyApprovalDate',
    'vacancyApprovedValue',
    'vacancyCheckinAppJustification',
    'vacancyCheckinNoAppJustification',
    'vacancyCheckinPositionLink',
    'vacancyCheckoutAppJustification',
    'vacancyCheckoutDate',
    'vacancyCheckoutLat',
    'vacancyCheckoutLong',
    'vacancyCheckoutNoAppJustification',
    'vacancyCheckoutPositionLink',
    'vacancyCompanyJustification',
    'vacancyEndHour',
    'workedHours',
    'distanceBetweenHospitalAndCheckinPosition',
    'distanceBetweenHospitalAndCheckoutPosition',
    'hospitalName',
  ];

  const sortColumn = [
    'vacancyDate',
    'doctorName',
    'contractName',
    'contractSector',
    'vacancyFlow',
    'valueStatus',
    'vacancyStartHour',
    'vacancyCheckinDate',
    'expectedWorkHours',
    'vacancyValue',
  ];

  const openDetails = useCallback(() => {
    detailsModalRef.current.openModal();
  }, []);

  const downloadExcel = () => {
    const token = getToken();

    const baseURL = process.env.REACT_APP_API_URL;

    const queryToDownload = location.search;

    return window.open(
      `${baseURL}/companies/reports/doctorspays/excel${queryToDownload}&token=${token}`
    );
  };

  const sendData = useCallback(
    (queryData = null) => {
      setLoading(true);
      let queryResult;
      if (queryData) {
        queryResult = queryData;
      } else {
        queryResult = queryParams;
      }

      const objIsApproved = {};
      if (
        queryResult.isApproved !== undefined &&
        queryResult.isApproved !== ''
      ) {
        objIsApproved.isApproved = queryResult.isApproved;
      }

      const offset = (currentPage - 1) * queryResult.limit || 0;

      const params = {
        ...queryResult,
        ...objIsApproved,
        limit: 10,
        offset,
      };

      setCurrentParams(params);

      const url = `companies/reports/doctorspays/v2`;

      api.get(url, { params }).then(response => {
        let cols = [];
        if (response.data.data.length) {
          cols = Object.keys(response.data.data[0])
            .map(col => {
              if (!deleteColumn.includes(tableColumnName[col])) {
                return {
                  name: tableColumnName[col],
                  position: sortColumn.indexOf(col),
                };
              }
              return null;
            })
            .filter(col => col)
            .sort((a, b) => a.position - b.position);
        }

        setLoading(false);
        setColumnName(cols);
        setData(response.data.data);
        setTotal(response.data.total);
      });
    },
    [currentPage, data]
  );

  const renewData = async () => {
    const urlResult = new URLSearchParams(
      new FormData(document.search)
    ).toString();

    history.push(`?${urlResult}`);

    sendData(getQueryParams(`?${urlResult}`));
    return false;
  };

  useEffect(() => {
    if (!firstLoad) {
      currentParams.offset = (currentPage - 1) * currentParams.limit;
      const newParams = queryParamsToURL(currentParams);
      window.history.replaceState(null, '', `${location.pathname}${newParams}`);
    }
    setFirstLoad(false);
    sendData();
  }, [currentPage]);

  return (
    <PageLayout loading={loading}>
      <Text fontWeight="bold" fontSize={20} color="var(--primary)">
        Relatórios médicos
      </Text>
      <Flex justify="space-between">
        <span>
          <br />
          {total !== 0 && (
            <span>
              Total: {total} | De:{' '}
              {queryParams.startDate
                .split('-')
                .reverse()
                .join('/')}{' '}
              Até:{' '}
              {queryParams.endDate
                .split('-')
                .reverse()
                .join('/')}
            </span>
          )}
        </span>
        <Pagination
          l={limit}
          t={total}
          onClick={setCurrentPage}
          initialCurrentPage={currentPage}
        />
        <Flex>
          <Flex pr={4} cursor="pointer" color="var(--primary)">
            <ExcelSvg size={40} onClick={downloadExcel} />
          </Flex>
          <FiFilter
            size={40}
            color="var(--primary)"
            style={{ cursor: 'pointer' }}
            onClick={onOpen}
          />
        </Flex>
      </Flex>
      <Flex overflow="auto">
        {data.length === 0 ? (
          <Flex
            w="100%"
            textAlign="center"
            flexDirection="column"
            h="100px"
            justifyContent="space-between"
            mt={10}
          >
            <Text>Não achamos plantões nessa pesquisa</Text>
            <Button
              color="primary"
              title="Limpar Busca"
              margin="0 auto"
              onClick={() => {
                history.push('/company/reports/doctorPay?offset=0&limit=10');
                sendData(getQueryParams('?offset=0&limit=10'));
              }}
            />
          </Flex>
        ) : (
          <Table
            cellSpacing="0"
            cellPadding="0"
            border="0"
            overflow="auto"
            w="100%"
          >
            <Thead>
              <TrH>
                {columnName.map(col => (
                  <Th key={col.position} fontSize={12} w="10%" fontWeight="800">
                    {col.name}
                  </Th>
                ))}
              </TrH>
            </Thead>
            <Tbody overflow="auto">
              {data.map(res => (
                <Tr
                  key={res.id}
                  onClick={() => {
                    openDetails();
                    setDetails(res);
                  }}
                >
                  {sortColumn.map(col => (
                    <Td
                      key={col}
                      fontSize={12}
                      w="10%"
                      minW={120}
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {col === 'doctorName' && (
                        <Text>
                          {getFirstLastName(res[col])} <br />
                          <span
                            style={{
                              fontSize: 10,
                              color: 'var(--light-gray)',
                            }}
                          >
                            {res.doctorCrm}
                          </span>
                        </Text>
                      )}
                      {(col === 'vacancyCheckinPositionLink' ||
                        col === 'vacancyCheckoutPositionLink') &&
                        res[col] && (
                          <a href={res[col]} target="blank">
                            <FaMapMarkerAlt color="var(--red)" size={25} />
                          </a>
                        )}
                      {col === 'vacancyStartHour' && (
                        <Text>
                          {res.vacancyStartHour} - {res.vacancyEndHour}
                        </Text>
                      )}

                      {col === 'vacancyCheckinDate' && (
                        <Text>
                          {res.vacancyCheckinDate} - {res.vacancyCheckoutDate}
                        </Text>
                      )}

                      {col === 'expectedWorkHours' && (
                        <Text>
                          {res.expectedWorkHours} / {res.workedHours}
                        </Text>
                      )}

                      {col === 'vacancyValue' && (
                        <Text>
                          {res.vacancyValue}
                          {res.vacancyApprovedValue &&
                            ` / ${res.vacancyApprovedValue}`}
                        </Text>
                      )}

                      {col === 'valueStatus' && (
                        <Text>
                          {res.valueStatus} <br />
                          {res.vacancyApprovalDate}
                        </Text>
                      )}

                      {!(
                        col === 'doctorName' ||
                        col === 'vacancyValue' ||
                        col === 'expectedWorkHours' ||
                        col === 'vacancyCheckinDate' ||
                        col === 'vacancyStartHour' ||
                        col === 'vacancyCheckinPositionLink' ||
                        col === 'vacancyCheckoutPositionLink' ||
                        col === 'valueStatus'
                      ) && res[col]}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Flex>
      <DrawerFilter
        onClose={onClose}
        isOpen={isOpen}
        location={location}
        currentParams={currentParams}
        renewData={renewData}
        inputsPermitted={[
          'startDate',
          'endDate',
          'specialties',
          'crm',
          'crmUf',
          'startValue',
          'endValue',
          'doctors',
          'hospitals',
          'cities',
          'checkin',
          'checkout',
          'inCash',
          'isApproved',
        ]}
      />
      <Modal
        title="Dados do plantão"
        ref={detailsModalRef}
        component={Details}
        data={details}
      />
    </PageLayout>
  );
}
