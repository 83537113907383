import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% {
    background-color: var(--dim-gray);
  }

  50% {
    background-color: #ccc;
  }

  100% {
    background-color: var(--dim-gray);
  }
`;

const ShimmerEffect = styled.div`
  animation: ${loadingAnimation} 1s infinite;

  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};

  margin: ${props => props.margin};
  padding: ${props => props.padding};

  border-radius: ${props => props.radius || 0};
`;

export default ShimmerEffect;
