import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from '@chakra-ui/react';

import { Label } from '~/components/UI/Text';

const TextArea = styled.textarea`
  width: 100%;

  padding: 5px;

  border-radius: var(--br-sm);

  border: 2px solid var(--black);

  resize: none;
`;

export default function ITExt({
  required,
  label = '',
  rows = 8,
  error,
  ...rest
}) {
  return (
    <Flex flexDir="column" w="100%">
      <Label>{required ? label + ' *' : label}</Label>
      <TextArea required={required} rows="8" {...rest} />
      {error && (
        <Text mt="5px" color="var(--red)">
          {error}
        </Text>
      )}
    </Flex>
  );
}
